import React, {useEffect, useRef, useState} from "react";
import CrossaPageTemplate from "components/CrossaPageTemplate.js";
import {FormattedMessage} from 'react-intl';
import RecordsPageTable from "../menuDisplay/components/RecordsPageTable";
import {
    exportAllRegistrationsInViewHandler,
    getAllFormDataRecordsHandler,
    updateFormDataRecordHandler
} from "handlers/workspaceMemberHandlers/formHandlers/FormDataHandlers";
import AdminNavbar from "../../../components/AdminNavbar";
import {Button, Form, OverlayTrigger, Tooltip} from "react-bootstrap";
import {
    addIconWithoutBg, exportIcon,
    filterIcon,
    iconX,
    iconXthinner, save,
    searchIcon,
    sortDownIcon,
    sortUpIcon
} from "../../../components/Icons";
import {FORM_ACTION, SIDEBAR_STYLE, SORT_DIRECTION} from "../../../util/Constants";
import SlidingPanel from "../../../components/SlidingPanel";
import RecordFilters from "../menuDisplay/components/RecordFilters";
import MessageComponent from "../menuDisplay/components/MessageComponent";
import {getNotifyErrorOptions, getNotifySuccessOptions} from "../../../util/Util";
import NotificationAlert from "react-notification-alert";
import RecordDetails from "../menuDisplay/components/RecordDetails";
import SaveRecordForm from "../menuDisplay/components/SaveRecordForm";

function FormDataPage(props) {
    const [state, setState] = useState({
        searchValue:"",
        filters:[],
        pageable:{},
        filterSidebarStyle: SIDEBAR_STYLE.CLOSED,
        sendMessageSidebarStyle:SIDEBAR_STYLE.CLOSED,
        recordTypeFields:[],
        chipsExpanded:false,
        selectedRecords:[],
        sendMessageAvailable:false,
        rtType:"",
        savedFilters:[],
        records:{},
        tableRecords:{},
        hiddenFields:false,
        recordRefsForFilters:[],
        recordTypeName:"",
    });

    const vidRef = useRef(null);
    const filtersRef = useRef([]);
    const searchValueRef = useRef("");
    const pageableRef = useRef({});

    useEffect(() => {
        if(vidRef.current!==props.match.params.vid || JSON.stringify(filtersRef.current) !== JSON.stringify(state.filters)  || JSON.stringify(pageableRef.current) !== JSON.stringify(state.pageable)){
            let request={};
            let newState={...state};
            if(vidRef.current!==props.match.params.vid){
                vidRef.current = props.match.params.vid;
                newState={
                    ...newState,
                    filters: [],
                    pageable: {},
                    searchValue: "",
                    filterSidebarStyle: SIDEBAR_STYLE.CLOSED
                }
                filtersRef.current = [];
                pageableRef.current = {};
            } else if(JSON.stringify(filtersRef.current) !== JSON.stringify(state.filters) || JSON.stringify(pageableRef.current) !== JSON.stringify(state.pageable)){
                request = {
                    "filters": state.filters,
                    "pageable": state.pageable,
                    "searchValue": state.searchValue
                };
                filtersRef.current = JSON.parse(JSON.stringify(newState.filters));
                pageableRef.current = JSON.parse(JSON.stringify(newState.pageable));
            }

            getAllFormDataRecordsHandler(props.match.params.vid,request).then(response=>{
                if(response.ok){
                    setState({
                        ...newState,
                        ...response.data,
                    });
                }
            });
        }
    }, [state.filters,state.pageable,props.match.params.vid]);

    useEffect(() => {
        let delayDebounceFn = null;

        if ((state.searchValue === "" || state.searchValue?.length > 2) && searchValueRef.current !== state.searchValue) {
            searchValueRef.current = state.searchValue;
            delayDebounceFn = setTimeout(async () => {
                let request = {"filters": state.filters, "pageable": state.pageable, "searchValue": state.searchValue};
                const response = await getAllFormDataRecordsHandler(props.match.params.vid, request);
                let newState = {
                    ...state,
                    ...response.data
                };
                setState(newState);
            }, 1000)
            return () => clearTimeout(delayDebounceFn)
        }
    }, [state.searchValue,props.match.params.vid])


    const handleChangeSearchInput = (e) => {
        setState({
            ...state,
            searchValue: e.target.value
        });
    }

    const handleOpenFilterSidebar = () => {
        let newColumns=[...state.columns];
        if(state.sendMessageSidebarStyle===SIDEBAR_STYLE.OPEN){
            newColumns.shift();
        }
        setState({
            ...state,
            sendMessageSidebarStyle: SIDEBAR_STYLE.CLOSED,
            filterSidebarStyle: SIDEBAR_STYLE.OPEN,
            columns:newColumns
        });
    }

    const handleDeleteFilter = (fieldId, value, isRecord) => {
        let newFilters = [...state.filters];

        let newRecordRefsForFilters = [...state.recordRefsForFilters];
        newFilters.map(filter => {
            if (filter.fieldId === fieldId) {
                ///date
                if (typeof value === "object") {
                    filter.values = filter.values.map(val => val === value ? null : val);
                } else {
                    filter.values = filter.values.filter(val => val !== value);
                }
            }
        });
        if (isRecord) {
            if (newRecordRefsForFilters.find(recordRef => recordRef.fieldId === fieldId && recordRef.record?.value === value)) {
                newRecordRefsForFilters = newRecordRefsForFilters.filter(recordRef => !(recordRef.fieldId === fieldId && recordRef.record?.value === value));
            }
        }

        newFilters = newFilters.filter(f => f.values.length > 0 && f.values.filter(val => val).length > 0);

        setState({
            ...state,
            filters: newFilters,
            recordRefsForFilters: newRecordRefsForFilters
        });
    }

    const toggleExpandChipsContainer = () => {
        setState({
            ...state,
            chipsExpanded: !state.chipsExpanded
        })
    }

    const handleSaveFilters = () =>{
        setState({
            ...state,
            savedFilters: [...state.savedFilters,state.filters]
        });
        let options=getNotifySuccessOptions("bc",<FormattedMessage id="FilterSaved" />);
        notificationAlertRef.current.notificationAlert(options);
    }

    const handleExportRecords = () =>{
        let request = {
            "filters": state.filters,
            "pageable": state.pageable,
            "searchValue": state.searchValue
        };
        exportAllRegistrationsInViewHandler(props.match.params.vid,request).then(response=>{
            if(response.ok){
                let fileName = state.recordTypeName;
                const fileURL = window.URL.createObjectURL(response.data);
                let downloadFile = document.createElement('a');
                downloadFile.href = fileURL;
                downloadFile.download = fileName + ".xlsx";
                document.body.appendChild(downloadFile);

                downloadFile.click();
                downloadFile.remove();
            }
        });
    }


    const showSearchAndFilterOptions = () => {
        return <>
            <div className={"search-and-filter-container"}>
                <Form.Group className={"search-form-group mb-0"}>
                    <span className="search-icon-container">{searchIcon}</span>
                    <Form.Control
                        placeholder={"Search..."}
                        type="text"
                        value={state.searchValue}
                        onChange={handleChangeSearchInput}
                    ></Form.Control>
                </Form.Group>

                <OverlayTrigger
                    onClick={(e) => e.preventDefault()}
                    overlay={
                        <Tooltip>
                            <FormattedMessage id="Filters"/>
                        </Tooltip>
                    }
                >
                    <a className="filter-icon" onClick={() => handleOpenFilterSidebar()}>{filterIcon}</a>
                </OverlayTrigger>
                <div className="ml-auto d-flex">
                    {state.sendMessageAvailable && <Button variant="link" onClick={handleOpenSendMessageSidebar} >
                            <span className="icon-cont">
                                {addIconWithoutBg}
                            </span>
                        <FormattedMessage id="Button.newMessage" />
                    </Button>}
                        <Button variant="link" onClick={handleExportRecords}>
                            <span className="icon-cont">
                                {exportIcon}
                            </span>
                        </Button>
                </div>
            </div>
            {state.filters.length > 0 &&
                <div className={state.chipsExpanded ? "chips-container expanded" : "chips-container"}>
                    {state.filters.map((filter, index) => {
                        return filter.values.map(val => {
                            return !val ? "" :
                                !state.recordRefsForFilters.find(ref => ref.fieldId === filter.fieldId && ref.record?.value==val)?
                                    <span
                                        key={index + Math.random()}
                                        className="chip active">{state.recordTypeFields.find(field => field.id === filter.fieldId)?.name + ": " + (typeof val === "object" ? val?.toISOString().substring(0, 10) : val)}
                                        <span className="delete-icon"
                                              onClick={() => handleDeleteFilter(filter.fieldId, val, false)}>{iconXthinner}</span>
                                    </span> :
                                    <span
                                        key={index + Math.random()}
                                        className="chip active">{state.recordTypeFields.find(field => field.id === filter.fieldId)?.name + ": " + state.recordRefsForFilters.find(ref => ref.fieldId === filter.fieldId && ref.record?.value==val)?.record.label}<span
                                        className="delete-icon"
                                        onClick={() => handleDeleteFilter(filter.fieldId, val, true)}>{iconXthinner}</span></span>
                        })
                    })}
                    <div className="top-right-col">
                        <div className="row">
                            <OverlayTrigger
                                onClick={(e) => e.preventDefault()}
                                overlay={

                                    <Tooltip>
                                        {state.chipsExpanded ? <FormattedMessage id="Collapse"/> :
                                            <FormattedMessage id="Expand"/>}
                                    </Tooltip>
                                }
                            >
                                <span onClick={() => toggleExpandChipsContainer()}
                                      className="action">{state.chipsExpanded ? sortDownIcon : sortUpIcon}</span>
                            </OverlayTrigger>

                            <OverlayTrigger
                                onClick={(e) => e.preventDefault()}
                                overlay={
                                    <Tooltip>
                                        <FormattedMessage id="Button.save"/>
                                    </Tooltip>
                                }
                            >
                             <span
                                 onClick={() => handleSaveFilters()}
                                 className="action">{save}
                             </span>
                            </OverlayTrigger>

                            <OverlayTrigger
                                onClick={(e) => e.preventDefault()}
                                overlay={
                                    <Tooltip>
                                        <FormattedMessage id="DeleteAll"/>
                                    </Tooltip>
                                }
                            >
                             <span
                                 onClick={() => handleRemoveFilters()}
                                 className="action">{iconX}
                       </span>
                            </OverlayTrigger>
                        </div>
                    </div>
                </div>}
        </>
    }
    const handleCloseFilterSidebar = () => {
        setState({
            ...state,
            filterSidebarStyle: SIDEBAR_STYLE.CLOSED
        });
    }

    const handleApplyFilters = (filters, recordRefsForFilters) => {
        setState({
            ...state,
            filters: filters,
            recordRefsForFilters: recordRefsForFilters,
            filterSidebarStyle: SIDEBAR_STYLE.CLOSED,
        });
    }

    const handleRemoveFilters = () => {
        setState({
            ...state,
            filters: [],
            recordRefsForFilters: [],
            filterSidebarStyle: SIDEBAR_STYLE.CLOSED,
        });
    }

    const handleOpenSendMessageSidebar = () => {
        let newColumns=[...state.columns];
        if(newColumns[0]?.accessor!=="selected")
            newColumns=[{
                Header: () => (<div></div>),
                accessor: "selected",
            }, ...newColumns];
        setState({
            ...state,
            sendMessageSidebarStyle: SIDEBAR_STYLE.OPEN,
            columns:newColumns
        });
    }

    const onChangeSort = (sortBy) => {
        setState({
            ...state,
            pageable: {
                ...state.pageable,
                sort: sortBy.length ? [(sortBy[0].id==="paymentOption"?"payment":sortBy[0].id) + "," + (sortBy[0].desc ? SORT_DIRECTION.DESC : SORT_DIRECTION.ASC)] : null
            },
            sendMessageSidebarStyle: SIDEBAR_STYLE.CLOSED
        });
    }

    const handleCloseSendMessageSidebar = (sendMessageSuccess) => {
        let newColumns=[...state.columns];
        if(newColumns[0]?.accessor==="selected")
            newColumns.shift();
        setState({
            ...state,
            sendMessageSidebarStyle: SIDEBAR_STYLE.CLOSED,
            columns:newColumns,
            selectedRecords: [],
        });
        if(sendMessageSuccess){
            let options = getNotifySuccessOptions("bc",<FormattedMessage id="MessageSent"/>);
            notificationAlertRef.current.notificationAlert(options);
        }
    }

    const handleErrorSendMessage = () => {
        let options = getNotifyErrorOptions("bc",<FormattedMessage id="MessageNotSent"/>);
        notificationAlertRef.current.notificationAlert(options);
    }

    const toggleSelectedRecord=(e,id)=>{
        let newSelectedRecords=[...state.selectedRecords];
        if(newSelectedRecords.find(idR=>idR===id)){
            newSelectedRecords=newSelectedRecords.filter(idR=>idR!==id);
        } else {
            newSelectedRecords.push(id);
        }
        setState({
            ...state,
            selectedRecords:newSelectedRecords
        });
    }

    const toggleAllSelectedRecords=(e)=>{
        let newSelectedRecords=[];
        if(e.target.checked) {
            state.records?.filter(record=>record.createdBy)?.map(record => {
                if (!newSelectedRecords.find(idR => idR === record.id)) {
                    newSelectedRecords.push(record.id);
                }
            });
        }
        setState({
            ...state,
            selectedRecords:newSelectedRecords
        });
    }
    const openDetailsSidebar = async (event, recordId) => {
        const selectedRecord = state.records?.find(record => record.id === recordId);
        setState({
            ...state,
            record: selectedRecord,
            detailsFormStyle: SIDEBAR_STYLE.OPEN,
            filterSidebarStyle: SIDEBAR_STYLE.CLOSED,
            sendMessageSidebarStyle: SIDEBAR_STYLE.CLOSED,
        });
    }

    const closeDetailsDrawer = () => {
        setState({
            ...state,
            detailsFormStyle: SIDEBAR_STYLE.CLOSED,
            record: null
        });
    }

    const handleUpdate = async (event, recordId) => {
        const selectedRecord = state.records.find(record => record.id === recordId);
        setState({
            ...state,
            record:selectedRecord,
            updateFormStyle: SIDEBAR_STYLE.OPEN,
            detailsFormStyle:SIDEBAR_STYLE.CLOSED,
            filterSidebarStyle: SIDEBAR_STYLE.CLOSED,
            sendMessageSidebarStyle: SIDEBAR_STYLE.CLOSED,
        });
    }

    const closeUpdateDrawer = () => {
        setState({
            ...state,
            updateFormStyle: SIDEBAR_STYLE.CLOSED,
            record: null
        });
    }

    const updateRecord = (updatedRecord) => {

            let request={
                pageable:{},
                filters: [],
                searchValue: "",
            };
            filtersRef.current=request.filters;
            searchValueRef.current=request.searchValue;
            pageableRef.current=request.pageable;
            updateFormDataRecordHandler(updatedRecord, props.match.params.vid, request).then(response => {
                if (response.ok) {
                        setState({
                            ...state,
                            ...response.data,
                            record: null,
                            updateFormStyle: SIDEBAR_STYLE.CLOSED,
                            ...request
                        });
                } else {
                    let message = response.data?.message;
                    let options = getNotifyErrorOptions("bc", message);
                    notificationAlertRef.current.notificationAlert(options);
                }
            });
    }

    const removeSavedFilter = (index) =>{
        let newSavedFilter=[...state.savedFilters];
        newSavedFilter.splice(index,1);
        setState({
            ...state,
            savedFilters: newSavedFilter
        });
    }
    const notificationAlertRef=useRef(null);
    return (
        <>
            <div className="rna-container">
                <NotificationAlert zIndex={10000} ref={notificationAlertRef}/>
            </div>
            <AdminNavbar
                title={<FormattedMessage id="Registrations"/>}
                hasBackBtn={true}
            />
            <CrossaPageTemplate
                hasBackBtn
                title={<FormattedMessage id="Registrations"/>}>
                {
                    state.columns && state.tableRecords &&
                    <>
                        {
                            showSearchAndFilterOptions()
                        }
                    <RecordsPageTable
                        columns={state.columns}
                        toggleSelectedRecord={toggleSelectedRecord}
                        toggleAllSelectedRecords={toggleAllSelectedRecords}
                        onChangeSort={onChangeSort}
                        openDetailsSidebar={openDetailsSidebar}
                        handleClickOnName={handleUpdate}
                        selectedRecords={state.selectedRecords}
                        data={state.tableRecords}
                        rtType={state.rtType}
                    />
                        </>
                }
            </CrossaPageTemplate>
            <SlidingPanel
                title={<FormattedMessage id="Filters"/>}
                currentStyle={state.filterSidebarStyle + " small"}
                closeSlidingPanel={handleCloseFilterSidebar}
                content={
                    <RecordFilters
                        applyFilters={handleApplyFilters}
                        filters={state.filters}
                        savedFilters={state.savedFilters}
                        handleRemoveFilters={handleRemoveFilters}
                        recordRefsForFilters={state.recordRefsForFilters}
                        recordTypeFields={state.recordTypeFields}
                        rtType={state.rtType}
                        removeSavedFilter={removeSavedFilter}
                    />
                }>
            </SlidingPanel>
            <SlidingPanel
                title={<FormattedMessage id="SendMessage"/>}
                currentStyle={state.sendMessageSidebarStyle + " small"}
                closeSlidingPanel={handleCloseSendMessageSidebar}
                content={
                    <MessageComponent
                        closeSlidingPanel={handleCloseSendMessageSidebar}
                        handleErrorSendMessage={handleErrorSendMessage}
                        selectedRecords={state.selectedRecords}
                        wid={props.match.params.wid}
                        vid={props.match.params.vid}
                    />
                }>
            </SlidingPanel>
            <SlidingPanel
                title={state.record?.name}
                hasBackButton={true}
                currentStyle={state.detailsFormStyle + " big"}
                closeSlidingPanel={closeDetailsDrawer}
                content={
                    state.recordTypeFields?.length && state.record &&
                    <RecordDetails
                        fieldsUpdateForm={state.recordTypeFields}
                        record={{...state.record}}
                        closeSlidingPanel={closeDetailsDrawer}
                        wid={props.match.params.wid}
                        vid={props.match.params.vid}
                        recordType={state.rtType}
                    />
                }>
            </SlidingPanel>

            <SlidingPanel
                title={<FormattedMessage id="Button.updateWithParam" values={{param: state.record?.name}}/>}
                currentStyle={state.updateFormStyle + " small"}
                hasBackButton={false}
                closeSlidingPanel={closeUpdateDrawer}
                content={
                    state.recordTypeFields?.length && state.record &&
                    <SaveRecordForm
                        action={FORM_ACTION.UPDATE}
                        fieldsUpdateForm={state.recordTypeFields}
                        record={{...state.record}}
                        saveRecord={updateRecord}
                        closeSlidingPanel={closeUpdateDrawer}
                        wid={props.match.params.wid}
                        notificationAlertRef={notificationAlertRef}
                        vid={props.match.params.vid}
                        rtType={state.rtType}
                    />
                }>
            </SlidingPanel>
        </>
    );
}

export default FormDataPage;
