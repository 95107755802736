import * as WorkspaceService from "service/WorkspaceService";
import * as WorkspaceRoleService from "service/WorkspaceRoleService";
import * as MemberService from "service/MemberService";
import * as RecordService from "service/RecordService";
const getDataForMemberHandler = async (wid, memberId) => {

    let fields = [];
    let member = {};
    let workspaceRoles = [];
    let memberVid = null;
    let ok=true;

    const responseRecordTypeAndRecordView = await WorkspaceService.findMemberRecordTypeRecordView(wid);
    const responseFormRecordType=await WorkspaceService.findFormRecordTypeRecordView(wid);
    ok=ok&&responseRecordTypeAndRecordView.ok&&responseFormRecordType.ok;
    if (responseRecordTypeAndRecordView.ok && responseFormRecordType.ok) {

        fields = responseRecordTypeAndRecordView.data.recordType.fields
            .filter(field => field.id !== "createdDate" && field.id !== "createdBy" && field.id !== "modifiedDate" && field.id !== "modifiedBy")
            .map((rt, index) => {
                if (rt.type === "Record") {
                    let recordTypeForm = responseFormRecordType.data.recordType;
                    if (recordTypeForm?.id===rt.rtid && recordTypeForm?.type === "Form") {
                        rt.displayOption = "FormLink";
                    }
                }
                return {
                    ...rt,
                    ...responseRecordTypeAndRecordView.data.recordView.fieldModes?.find(field => field.recordTypeField?.id === rt.id)?.recordViewFieldMode
                }
            });
        memberVid = responseRecordTypeAndRecordView.data.recordView.id;
        const responseAllWorkspaceRole=await WorkspaceRoleService.findAllWorkspaceRoles(wid);
        ok=ok&&responseAllWorkspaceRole.ok;
        if(responseAllWorkspaceRole.ok){
            workspaceRoles=responseAllWorkspaceRole.data;

            if(memberId){
                const responseMember=await MemberService.findMember(wid, memberId);
                ok=ok&&responseMember;
                if(responseMember.ok){
                    member=responseMember.data;
                }
            }
        }
    }

    return{
        data:{
            memberVid: memberVid,
            fields:fields,
            member:member,
            workspaceRoles:workspaceRoles
        },
        ok:ok,
    }
}

const filterRecord = (record, filterList) => {
    let newRecord = {};
    for (const property in record) {
        if (property === "id" || property === "createdBy" || property === "createdDate" || property === "modifiedBy" || property === "modifiedDate") {
            continue;
        }
        if (filterList.find(element => element.id === property).mode === "WRITE") {
            newRecord = {
                ...newRecord,
                [property]: record[property],
            }
        }
    }

    return newRecord;
}


const saveMemberHandler=async(memberId,newMember,files,fields,wid,vid)=>{
    let response;

    if (!memberId) {
        response = await MemberService.insertMember(wid, newMember);
        if (files && Object.keys(files).length > 0) {
            await uploadImages(response.data.id, files, vid);
        }

    } else {
        const filteredMember = filterRecord(newMember, fields);
        response = await MemberService.updateMember(wid, memberId, filteredMember);
        if (files && Object.keys(files).length > 0) {
            await uploadImages(response.data.id, files, vid);
        }
    }

    return{
        ok:response.ok,
        errorCode:response.data.code
    }
}

const uploadImages = async (recordId, selectedFiles, vid) => {
    let fieldsIds = [];
    let files = [];
    for (const key in selectedFiles) {
        if(Array.isArray(selectedFiles[key])){
            for(let i=0; i<selectedFiles[key].length; i++){
                fieldsIds.push(key);
                files.push(selectedFiles[key][i]);
            }
        } else {
            fieldsIds.push(key);
            files.push(selectedFiles[key]);
        }
    }
    const response = await RecordService.updateRecordFiles(recordId, vid, files, fieldsIds);
    return response;
}

const getAllRecordsWithNameContainsHandler = async (nameContains, viewId) => {

    let records=[];
    const response = await RecordService.findAllRecordsInRecordViewWithNameContains(nameContains, viewId);
    if (response.ok) {
        records=response.data.map(element => {
            return {
                "label": element.name,
                "value": element.id,
            }
        })
    }

    return{
        data:records,
        ok:response.ok,
    }
}

export {
    getDataForMemberHandler,
    saveMemberHandler,
    getAllRecordsWithNameContainsHandler
}
