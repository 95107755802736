import React, {useEffect, useState} from "react";
import {Card, Col, Form, Row} from "react-bootstrap";
import Footer from "../../../components/Footer";
import NotificationAlert from "react-notification-alert";
import CrossaPageTemplate from "../../../components/CrossaPageTemplate";
import AdminNavbar from "../../../components/AdminNavbar";
import {FormattedMessage} from "react-intl";
import { getDataForNotificationsHandler } from "handlers/workspaceMemberHandlers/settingsHandlers/NotificationsHandlers";
import { updateSelfMemberTagsHandler } from "handlers/workspaceMemberHandlers/settingsHandlers/NotificationsHandlers";
import {getNotifySuccessOptions} from "../../../util/Util";
import IndeterminateCheckbox from "../../../components/IndeterminateCheckbox";
import {INDETERMINATE_CHECKBOX} from "../../../util/Constants";

function NotificationsSettings(props) {

    const [state,setState]=useState({
        recordViewsTags:[],
        selectedTags:[],
        pushNotifications:false,
        emailNotifications:false,
        memberId:null,
        canceled:false,
    });

    useEffect(() => {
        getPageContent();
    }, [state.canceled]);

    const getPageContent=async()=>{
        getDataForNotificationsHandler(props.match.params.wid).then(response=>{
            setState({
                ...state,
                ...response.data
            });
        });
    }

    const saveSettings = async () => {
        let settings = {
            "tags": state.selectedTags,
            "pushNotifications": state.pushNotifications,
            "emailNotifications": state.emailNotifications
        };
        updateSelfMemberTagsHandler(props.match.params.wid,state.memberId,settings).then(response=>{
            if (response.ok) {
                let options=getNotifySuccessOptions("bc");
                notificationAlertRef.current.notificationAlert(options);
            }
        });
    }

    const handleChangeTag=(recordId,recordViewTagId)=>{
        if(state.selectedTags?.find(selectedTag => selectedTag.rid === recordId && selectedTag.vid === recordViewTagId)){
            setState({
                ...state,
                selectedTags: state.selectedTags.filter(selectedTag => selectedTag.rid !== recordId || selectedTag.vid !== recordViewTagId)
            });
        } else{
            setState({
                ...state,
                selectedTags: [
                    ...state.selectedTags,
                    {
                        "rid":recordId,
                        "vid":recordViewTagId
                    }
                ]
            });
        }
    }

    const handleChangeMultipleTags=(checked,recordViewTagId)=>{
        let newSelectedTags=JSON.parse(JSON.stringify(state.selectedTags));

        if(checked){
            state.recordViewsTags.map(recordViewTag=>{
                if(recordViewTag.recordView?.id===recordViewTagId){
                    recordViewTag.records?.map(record=>{
                        if(!state.selectedTags?.find(selectedTag => selectedTag.rid === record.id && selectedTag.vid === recordViewTagId)){
                            newSelectedTags.push({
                                "rid":record.id,
                                "vid":recordViewTagId
                            });
                        }
                    });
                }
            });
        } else{
            newSelectedTags=newSelectedTags.filter(selectedTag=>selectedTag.vid!==recordViewTagId);
        }
        setState({
            ...state,
            selectedTags: newSelectedTags
        });
    }

    const notificationAlertRef = React.useRef(null);


    return (
        <>

            <div className="rna-container">
                <NotificationAlert ref={notificationAlertRef}/>
            </div>
            <AdminNavbar
                title={<FormattedMessage id="Notifications"/>}
            />
            <CrossaPageTemplate>
                <Card.Title as="h4">
                    <FormattedMessage id="NotificationTopics"/>
                </Card.Title>

                {state.recordViewsTags?.length > 0 &&
                    <Card.Body className="notifications">
                        <Row>
                            {   state.recordViewsTags?.map((recordViewTags, index) => {
                                    let selectedTagsRecordView=state.selectedTags?.filter(selectedTag=>selectedTag.vid === recordViewTags.recordView.id);
                                    return <Col className="notification-col" key={index}>
                                       <Row className="title-and-checkbox-container">
                                           <IndeterminateCheckbox
                                               value={selectedTagsRecordView?.length ?
                                                   (selectedTagsRecordView.length === recordViewTags.records?.length
                                                       ?
                                                           INDETERMINATE_CHECKBOX.CHECKED
                                                       :
                                                       INDETERMINATE_CHECKBOX.INDETERMINATE)
                                                   :
                                                   INDETERMINATE_CHECKBOX.UNCHECKED}
                                               onClick={(e)=>handleChangeMultipleTags(e.target.checked,recordViewTags.recordView.id)}
                                           />
                                           <span className="title">{recordViewTags.recordView.name}</span>
                                       </Row>
                                        <Row className="badge-container clickable">
                                            {recordViewTags.records?.map((record, indexR) => {
                                                if (state.selectedTags?.find(selectedTag => selectedTag.rid === record.id && selectedTag.vid === recordViewTags.recordView.id)) {
                                                    return <span key={indexR + Math.random()}
                                                                 onClick={()=>handleChangeTag(record.id,recordViewTags.recordView.id)}
                                                                 className="badge badge-secondary">
                                                        {record.name}
                                                    </span>
                                                } else {
                                                    return <span key={indexR + Math.random()}
                                                                 onClick={()=>handleChangeTag(record.id,recordViewTags.recordView.id)}
                                                                 className="badge badge-primary">
                                                        {record.name}
                                                    </span>
                                                }
                                            })}
                                        </Row>
                                    </Col>
                                }
                            )
                        }</Row>
                </Card.Body>
                }
                <br/>

                <Card.Title as="h4">
                    <FormattedMessage id="NotificationChannels"/>
                </Card.Title>
                <Card.Subtitle as="h3"><FormattedMessage id="HowWouldYouLikeToReceiveNotifications"/></Card.Subtitle>
                <br/>

                    <Form.Group>

                     <Row>
                         <Col>
                             <label className="d-flex align-items-center">
                                 <span className="switch-label mr-5"><FormattedMessage id="EmailNotifications"/></span>
                             </label>
                         </Col>
                         <Col className="inline-switch-col">
                             <Form.Check
                                 type="switch"
                                 checked={state.emailNotifications}
                                 id={"email-notifications"}
                                 onChange={() => {
                                     setState({
                                         ...state,
                                         emailNotifications:!state.emailNotifications
                                     });
                                 }}
                             />
                         </Col>
                     </Row>
                    </Form.Group>

                    <Form.Group>
                        <Row>
                            <Col>
                                <label className="d-flex align-items-center">
                                    <span className="switch-label mr-5"><FormattedMessage id="PushNotifications"/></span>

                                </label>
                            </Col>
                            <Col className="inline-switch-col">
                                <Form.Check
                                    type="switch"
                                    checked={state.pushNotifications}
                                    id={"push-notifications"}
                                    onChange={() => {
                                        setState({
                                            ...state,
                                            pushNotifications:!state.pushNotifications
                                        });
                                    }}
                                />
                            </Col>
                        </Row>

                    </Form.Group>


                <Footer
                    save={saveSettings}
                    cancel={() =>setState({
                        ...state,
                        canceled:!state.canceled
                    })}
                    isEnable={true}/>
            </CrossaPageTemplate>
        </>
    );
}

export default NotificationsSettings;
