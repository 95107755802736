import * as Validations from "validations/validations.js"
import {VALIDATIONS} from "../../../util/Constants";

/**
 * A function which checks if there is an error when workspace admin tries to add a new recordType
 *
 * @param {RecordType} recordType
 * @param {Object} errors   Contains errors. Example: {"name":true, "description": false} => there is an error for name field
 *
 * Object containt the new errors object
 * boolean is true if Object contains a true field, otherwise false
 * @returns {[Object, boolean]}
 */
const addRecordTypeValidations = (recordType, errors) => {
    if (recordType.name.length < 3) {
        errors = {
            ...errors,
            "name": true,
            "nameMsg": "Please choose a Record Type Name"
        }
    } else if (recordType.name.length > 50) {
        errors = {
            ...errors,
            "name": true,
            "nameMsg": "Max number of characters: 50"
        }
    }

    const existErrors = Validations.checkIfError(errors);

    return [errors, existErrors];
}

/**
 * A function which checks if there is an error when workspace admin tries to add a new recordType
 *
 * @param {RecordField} recordField
 * @param {String} allRecordFields    Contains all recordField names existing in current recordType
 * @param {Object} errors   Contains errors. Example: {"name":true, "description": false} => there is an error for name field
 *
 * Object containt the new errors object
 * boolean is true if Object contains a true field, otherwise false
 * @returns {[Object, boolean]}
 */
const addRecordFieldValidations = (recordField, allRecordFields, errors) => {
    if (recordField.name.length > VALIDATIONS.MAX_LENGTH_DEFAULT || recordField.name.length < VALIDATIONS.MIN_LENGTH_DEFAULT) {
        errors = {
            ...errors,
            "name": true,
        }
    }

    /**
     * Check if the current name is already present in recordFields list
     */
    for (let i in allRecordFields) {
        if (recordField.name === allRecordFields[i]) {
            if (i == recordField.index) {
                continue;
            }
            errors = {
                ...errors,
                "unavailableName": true,
            };
        }
    }

    if (recordField.type === "") {
        errors = {
            ...errors,
            "type": true,
        }
    }

    if ((recordField.type === "Record" || recordField.type === "RecordList") && !recordField.vid) {
        errors = {
            ...errors,
            "view": true,
        }
    }

    const existErrors = Validations.checkIfError(errors);

    return [errors, existErrors];
}

export {
    addRecordFieldValidations,
    addRecordTypeValidations,
}
