import {FormattedMessage} from "react-intl";

export const defaultFieldsRegular = [
    {
        "id": "name",
        "name": "Name",
        "type": "Text",
    },
    {
        "id": "description",
        "name": "Description",
        "type": "Text",
    },
    {
        "id": "information",
        "name": "Record Information",
        "type": "GroupHeader",
    },
    {
        "id": "createdBy",
        "name": "Created By",
        "type": "Record",
    },
    {
        "id": "createdDate",
        "name": "Created Date",
        "type": "Date",
    },
    {
        "id": "modifiedBy",
        "name": "Modified By",
        "type": "Record",
    },
    {
        "id": "modifiedDate",
        "name": "Modified Date",
        "type": "Date",
    }
];

export const defaultFieldsMember = [
    {
        "id": "name",
        "name": "Name",
        "type": "Text",
    },
    {
        "id": "email",
        "name": "Email",
        "type": "Text",
    },
    {
        "id": "firstName",
        "name": "First Name",
        "type": "Text",
    },
    {
        "id": "lastName",
        "name": "Last Name",
        "type": "Text",
    },
    {
        "id": "image",
        "name": "Image",
        "type": "Image",
    },
    {
        "id": "wuid",
        "name": "Workspace User",
        "type": "WorkspaceUser",
    },
    {
        "id": "roles",
        "name": "Roles List",
        "type": "RolesList",
    },
    {
        "id": "isAdmin",
        "name": "Is Admin",
        "type": "Boolean",
    },
    {
        "id": "information",
        "name": "Record Information",
        "type": "GroupHeader",
    },
    {
        "id": "createdBy",
        "name": "Created By",
        "type": "Record",
    },
    {
        "id": "createdDate",
        "name": "Created Date",
        "type": "Date",
    },
    {
        "id": "modifiedBy",
        "name": "Modified By",
        "type": "Record",
    },
    {
        "id": "modifiedDate",
        "name": "Modified Date",
        "type": "Date",
    },
    {
        "id": "notifications",
        "name": "Notifications",
        "type": "Notifications",
    },
    {
        "id": "phone",
        "name": "Phone",
        "type": "Text",
    },
    {
        "id": "isActive",
        "name": "Is Active",
        "type": "Boolean",
    },
];

export const defaultFieldsForm = [
    {
        "id": "name",
        "name": "Name",
        "type": "Text",
    },
    {
        "id": "description",
        "name": "Description",
        "type": "Text",
    },
    {
        "id": "startDate",
        "name": "Start date",
        "type": "Date",
    },
    {
        "id": "endDate",
        "name": "End date",
        "type": "Date",
    },
    {
        "id": "maxNrOfReg",
        "name": "Max number of registrations",
        "type": "Integer",
    },
    {
        "id": "theme",
        "name": "Theme",
        "type": "Text",
    },
    {
        "id": "captcha",
        "name": "Captcha",
        "type": "Boolean",
    },
    {
        "id": "payment",
        "name": "Payment",
        "type": "Boolean",
    },
    {
        "id": "onlyLoggedUser",
        "name": "Only Logged User",
        "type": "Boolean",
    },
    {
        "id": "rtid",
        "name": "Record type ID",
        "type": "Integer",
    },
    {
        "id": "vid",
        "name": "Record view ID",
        "type": "Integer",
    },
    {
        "id": "createdBy",
        "name": "Created By",
        "type": "Record",
    },
    {
        "id": "createdDate",
        "name": "Created Date",
        "type": "Date",
    },
    {
        "id": "modifiedBy",
        "name": "Modified By",
        "type": "Record",
    },
    {
        "id": "modifiedDate",
        "name": "Modified Date",
        "type": "Date",
    }
];

export const defaultFieldsPayment = [
    {
        "id": "paymentSection",
    },
    {
        "id": "paymentProcessor",
    },
    {
        "id": "paymentPlan",
    },
    {
        "id": "paymentPlanName",
    },
    {
        "id": "planCode",
    },
    {
        "id": "months",
    },
    {
        "id": "price",
    },
    {
        "id": "currency",
    },
    {
        "id": "editablePrice",
    },
    {
        "id": "vatLevel",
    },
    {
        "id": "recurrent",
    },
    {
        "id": "billNr",
    },
    {
        "id": "paymentInfo",
    },
    {
        "id": "membershipStatus",
    },
    {
        "id": "startDate",
    },
    {
        "id": "endDate",
    },
    {
        "id": "merchantSection",
    },
    {
        "id": "merchant",
    },
    {
        "id": "merchantName",
    },
    {
        "id": "merchantAddressLine1",
    },
    {
        "id": "merchantAddressLine2",
    },
    {
        "id": "merchantPostCode",
    },
    {
        "id": "merchantCity",
    },
    {
        "id": "merchantCountry",
    },
    {
        "id": "merchantRegistrationNr",
    },
    {
        "id": "merchantVatNr",
    },
    {
        "id": "integrationKey1",
    },
    {
        "id": "integrationKey2",
    },
    {
        "id": "integrationKey3",
    },
    {
        "id": "merchantContact",
    },
    {
        "id": "clientSection",
    },
    {
        "id": "client",
    },
    {
        "id": "clientName",
    },
    {
        "id": "clientAddressLine1",
    },
    {
        "id": "clientAddressLine2",
    },
    {
        "id": "clientPostCode",
    },
    {
        "id": "clientCity",
    },
    {
        "id": "clientCountry",
    },
    {
        "id": "clientRegistrationNr",
    },
    {
        "id": "clientVatNr",
    },
    {
        "id": "clientContact",
    },
    {
        "id": "information",
    },
    {
        "id": "createdBy",
    },
    {
        "id": "createdDate",
    },
    {
        "id": "modifiedBy",
    },
    {
        "id": "modifiedDate",
    },
];

export const fieldType = [
    {
        value:"Text",
        label:<FormattedMessage id="FieldType.text" />,
    },
    {
        value:"GroupHeader",
        label:<FormattedMessage id="FieldType.groupHeader" />,
    },
    {
        value:"Integer",
        label:<FormattedMessage id="FieldType.integer" />,
    },
    {
        value:"Decimal",
        label:<FormattedMessage id="FieldType.decimal" />,
    },
    {
        value:"Currency",
        label:<FormattedMessage id="FieldType.currency" />,
    },
    {
        value:"Date",
        label:<FormattedMessage id="FieldType.date" />,
    },
    {
        value:"SingleChoice",
        label:<FormattedMessage id="FieldType.singleChoice" />,
    },
    {
        value:"MultipleChoice",
        label:<FormattedMessage id="FieldType.multipleChoice" />,
    },
    {
        value:"Record",
        label:<FormattedMessage id="FieldType.record" />,
    },
    {
        value:"RecordList",
        label:<FormattedMessage id="FieldType.recordList" />,
    },
    {
        value:"Boolean",
        label:<FormattedMessage id="FieldType.boolean" />,
    },
    {
        value:"Image",
        label:<FormattedMessage id="FieldType.image" />,
    },
    {
        value:"File",
        label:<FormattedMessage id="FieldType.file" />,
    },
    {
        value:"FileList",
        label:<FormattedMessage id="FieldType.fileList" />,
    },
];
export const displayOptions = {
    "Text": [
        {
            value:"SingleLine",
            label:<FormattedMessage id="DisplayOptions.singleLine" />
        },
        {
            value:"TextArea",
            label:<FormattedMessage id="DisplayOptions.textArea" />
        },
        {
            value:"HyperLink",
            label:<FormattedMessage id="DisplayOptions.hyperLink" />
        }
        ],
    "RecordList": [
        {
            value:"CheckboxGroup",
            label:<FormattedMessage id="DisplayOptions.checkboxGroup" />
        },
        {
            value:"MultipleChoiceList",
            label:<FormattedMessage id="DisplayOptions.multipleChoiceList" />
        },
        {
            value:"SearchSelect",
            label: <FormattedMessage id="DisplayOptions.searchSelect" />
        }
        ],
    "Record": [
        {
            value:"ComboBox",
            label:<FormattedMessage id="DisplayOptions.comboBox" />
        },
        {
            value: "RadioButtonGroup",
            label: <FormattedMessage id="DisplayOptions.radioButtonGroup"/>
        },
        {
            value:"SearchSelect",
            label: <FormattedMessage id="DisplayOptions.searchSelect" />
        }
        ]
};
