import {WIDGET_TYPE} from "../../../util/Constants";
import {useEffect, useState} from "react";
import {
    getDataForDashboardHandler
} from "../../../handlers/workspaceMemberHandlers/menuDisplayHandlers/DisplayMenuDashboardHandlers";
import AdminNavbar from "../../../components/AdminNavbar";
import React from "react";
import CrossaPageTemplate from "../../../components/CrossaPageTemplate";
import WelcomeWidget from "./components/WelcomeWidget";
import TableWidget from "./components/TableWidget";
import ChartWidget from "./components/ChartWidget";

function DisplayMenuDashboardPage(props){
    const [state, setState] = useState({
        displayMode: null,
        record: null,
        memberName:{},
        dashboard:{},
        loggedIn:false,
    });

    useEffect(async ()=>{

        const responseDataForDashboard= await getDataForDashboardHandler(props.match.params.mid);
        if(responseDataForDashboard.ok){
            setState({
                ...state,
                ...responseDataForDashboard.data,
                loggedIn: props.location.state?.loggedIn,
            });
        }

    },[props.match.params.mid,props.location.state?.loggedIn]);

    return <>
        <AdminNavbar
            title={state.dashboard?.name}
        />
        <CrossaPageTemplate>
            <div className="container-widgets">
            {state.dashboard.widgets?.map((widget,index)=>{
                return <React.Fragment key={index}>
                    {widget.type === WIDGET_TYPE.WELCOME && widget.visible && (!widget.showOnlyAfterLogin || state.loggedIn) &&
                       <WelcomeWidget widget={widget}/>
                    }
                    {widget.type === WIDGET_TYPE.TABLE && widget.visible &&
                        <TableWidget widget={widget} />
                    }
                    {(widget.type === WIDGET_TYPE.PIE_CHART || widget.type === WIDGET_TYPE.BAR_CHART || widget.type === WIDGET_TYPE.COLUMN_CHART) && widget.visible &&
                        <ChartWidget widget={widget}/>
                    }
                </React.Fragment>
            })}
            </div>
        </CrossaPageTemplate>
    </>
}

export default DisplayMenuDashboardPage