import React from "react";
import {Form} from "react-bootstrap";
import Select from "react-select";
import DateTimePicker from "react-datetime-picker";
import {
    FORM_FIELD_SINGLE_CHOICE_DISPLAY_OPTION,
    FORM_FIELD_TEXT_DISPLAY_OPTION,
    FORM_FIELD_TYPE
} from "../../../util/Constants";
import {FormattedMessage} from "react-intl";
import TextareaAutosize from "react-textarea-autosize";

function FormPreviewField(props) {
    const {
        type,
        displayOption,
        name,
        description,
        placeholder,
        defaultDate,
        timeField,
        options,
        validation,
        paymentOptions,
        currency
    } = props;

    return (
        <>
            {type === FORM_FIELD_TYPE.TEXT && displayOption === FORM_FIELD_TEXT_DISPLAY_OPTION.SINGLE_LINE && <Form.Group>
                <Form.Label
                    style={props.formSettings.theme !== "#ffffff" ? {color: props.formSettings.theme} : {color: "unset"}}>
                    {name ? name : <FormattedMessage id="Form.text"/>}</Form.Label>
                {validation?.mandatory && <Form.Label className="red">*</Form.Label>}
                <div className="form-description">
                    {description ? description : <FormattedMessage id="Form.descriptionOpt"/>}
                </div>
                <FormattedMessage id="Form.placeholderText">
                    {placeholderAux =>
                        <Form.Control
                            placeholder={placeholder ? placeholder : placeholderAux}
                            type="text"
                            readOnly={true}
                        />
                    }
                </FormattedMessage>
            </Form.Group>
            }
            {type === FORM_FIELD_TYPE.TEXT && displayOption === FORM_FIELD_TEXT_DISPLAY_OPTION.TEXT_AREA && <Form.Group>
                <Form.Label
                    style={props.formSettings.theme !== "#ffffff" ? {color: props.formSettings.theme} : {color: "unset"}}>
                    {name ? name : <FormattedMessage id="Form.textArea"/>}</Form.Label>
                {validation?.mandatory && <Form.Label className="red">*</Form.Label>}
                <div className="form-description">
                    {description ? description : <FormattedMessage id="Form.descriptionOpt"/>}
                </div>
                <FormattedMessage id="Form.placeholderText">
                    {placeholderAux =>
                        <TextareaAutosize
                            minRows={3}
                            placeholder={placeholder ? placeholder : placeholderAux}
                            className={"disabled form-control"}
                            as="textarea"
                            // style={{height: '100px'}}
                            readOnly={true}
                        />
                    }
                </FormattedMessage>
            </Form.Group>
            }
            {type === FORM_FIELD_TYPE.TEXT && displayOption === FORM_FIELD_TEXT_DISPLAY_OPTION.EMAIL && <Form.Group>
                <Form.Label
                    style={props.formSettings.theme !== "#ffffff" ? {color: props.formSettings.theme} : {color: "unset"}}>
                    {name ? name : <FormattedMessage id="Form.email"/>}</Form.Label>
                {validation?.mandatory && <Form.Label className="red">*</Form.Label>}
                <div className="form-description">
                    {description ? description :  <FormattedMessage id="Form.descriptionOpt"/>}
                </div>
                <Form.Control
                    placeholder={placeholder ? placeholder : "example@mail.com"}
                    type="email"
                    readOnly={true}

                />
            </Form.Group>
            }
            {type === FORM_FIELD_TYPE.DATE && <Form.Group>
                <Form.Label
                    style={props.formSettings.theme !== "#ffffff" ? {color: props.formSettings.theme} : {color: "unset"}}>
                    {name ? name : <FormattedMessage id="Form.date"/>}</Form.Label>
                {validation?.mandatory && <Form.Label className="red">*</Form.Label>}
                <div className="form-description">
                    {description ? description : <FormattedMessage id="Form.descriptionOpt"/>}
                </div>
                <DateTimePicker
                    maxDate={new Date("9999-12-31")}
                    disableClock={true}
                    format={timeField ? "y-MM-dd HH:mm" : "y-MM-dd"}
                    value={(defaultDate ?  new Date(defaultDate) : "")}
                    disabled={true}
                />
            </Form.Group>
            }
            {type === FORM_FIELD_TYPE.TEXT && displayOption === FORM_FIELD_TEXT_DISPLAY_OPTION.PHONE && <Form.Group>
                <Form.Label
                    style={props.formSettings.theme !== "#ffffff" ? {color: props.formSettings.theme} : {color: "unset"}}>
                    {name ? name : <FormattedMessage id="Form.phoneNumber"/> }</Form.Label>
                {validation?.mandatory && <Form.Label className="red">*</Form.Label>}
                <div className="form-description">
                    {description ? description : <FormattedMessage id="Form.descriptionOpt"/>}
                </div>
                <Form.Control
                    placeholder={placeholder ? placeholder : "+1512312321"}
                    type="tel"
                    readOnly={true}
                />
            </Form.Group>
            }
            {type === FORM_FIELD_TYPE.SINGLE_CHOICE && displayOption === FORM_FIELD_SINGLE_CHOICE_DISPLAY_OPTION.DROPDOWN && <Form.Group>
                <Form.Label
                    style={props.formSettings.theme !== "#ffffff" ? {color: props.formSettings.theme} : {color: "unset"}}>
                    {name ? name : <FormattedMessage id="Form.dropdownList"/>}</Form.Label>
                {validation?.mandatory && <Form.Label className="red">*</Form.Label>}
                <div className="form-description">
                    {description ? description : <FormattedMessage id="Form.descriptionOpt"/>}
                </div>
                <FormattedMessage id="Placeholder.select">
                    {placeholderAux =>
                        <Select
                            readOnly={true}
                            placeholder={placeholder ? placeholder : placeholderAux}
                            className="react-select primary"
                            classNamePrefix="react-select"
                            options={options?.map(option => {
                                return {
                                    "label": option,
                                    "value": option
                                }
                            })}
                        />
                    }
                </FormattedMessage>
            </Form.Group>

            }
            {type === FORM_FIELD_TYPE.SINGLE_CHOICE && displayOption === FORM_FIELD_SINGLE_CHOICE_DISPLAY_OPTION.RADIO_BUTTON_GROUP && <Form.Group controlId="formBasicCheckbox">
                <Form.Label
                    style={props.formSettings.theme !== "#ffffff" ? {color: props.formSettings.theme} : {color: "unset"}}>
                    {name ? name : <FormattedMessage id="Form.singleChoice"/>}</Form.Label>
                {validation?.mandatory && <Form.Label className="red">*</Form.Label>}
                <div className="form-description">
                    {description ? description : <FormattedMessage id="Form.descriptionOpt"/>}
                </div>
                {options?.map((option, index) => <Form.Check
                    type="radio"
                    label={option}
                    key={index}
                    readOnly={true}

                />)}
            </Form.Group>
            }
            {type === FORM_FIELD_TYPE.MULTIPLE_CHOICE && <Form.Group>
                <Form.Label
                    style={props.formSettings.theme !== "#ffffff" ? {color: props.formSettings.theme} : {color: "unset"}}>
                    {name ? name : <FormattedMessage id="Form.multipleChoice"/>}</Form.Label>
                {validation?.mandatory && <Form.Label className="red">*</Form.Label>}
                <div className="form-description">
                    {description ? description : <FormattedMessage id="Form.descriptionOpt"/>}
                </div>
                {options?.map((option, index) => <Form.Check
                    type="checkbox"
                    label={option}
                    key={ index}
                    readOnly={true}
                />)}
            </Form.Group>
            }
            {type === FORM_FIELD_TYPE.GROUP_HEADER && <Form.Group>
                <Form.Label
                    style={props.formSettings.theme !== "#ffffff" ? {color: props.formSettings.theme} : {color: "unset"}}>
                    {name ? name : <FormattedMessage id="Form.output"/>}</Form.Label>
                {validation?.mandatory && <Form.Label className="red">*</Form.Label>}
                <div className="form-description">
                    {description ? description : <FormattedMessage id="Form.descriptionOpt"/>}
                </div>
            </Form.Group>
            }
            {type === FORM_FIELD_TYPE.PAYMENT && <Form.Group>
                <div className="row ml-0 mr-0">
                    <Form.Label
                        style={props.formSettings.theme !== "#ffffff" ? {color: props.formSettings.theme} : {color: "unset"}}>
                        {name ? name : <FormattedMessage id="Form.payment"/>}</Form.Label>
                </div>
                <div className="form-description">
                    {description ? description : <FormattedMessage id="Form.descriptionOpt"/>}
                </div>
                <span className="form-description"><FormattedMessage id="Form.paymentType"/></span>
                <Select
                    className="react-select primary mb-3"
                    classNamePrefix="react-select"
                    options={
                        [{
                            "label": "Online",
                            "value": "Online"
                        }]}
                    value={{
                        "label": "Online",
                        "value": "Online"
                    }}
                />
                <span className="form-description"><FormattedMessage id="Form.paymentOptions"/></span>
                {paymentOptions?.map((option, index) =>
                    <Form.Check
                        type="radio"
                        label={option.name + " (" + (option.price / Math.pow(10, currency.digits)).toFixed(currency.digits) + " " + currency.code + ")"}
                        checked={false}
                        readOnly={true}
                        key={index}
                    />)}
            </Form.Group>}
        </>


    );
}

export default FormPreviewField;
