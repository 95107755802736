import React from "react";
import { useHistory } from "react-router-dom";
import {LAYOUT} from "../../../util/Constants";
import {FormattedMessage} from "react-intl";


const PaymentSuccess = (props) => {

    const history = useHistory();
    const wid = props.match.params.wid;
    const toHomePage = () => {
        history.push("/" + LAYOUT.WORKSPACE_MEMBER + "/" + wid + "/home");
    }

    return (
        <div className="card payment-card">
            <header>
                <img style={{width: "150px"}} src='https://www.zoniz.com/img/crossa_logo.png' alt=""/>
            </header>
            <main>
                <div className="text">
                    <p><FormattedMessage id="PaymentProcessed"/></p>
                </div>
                <div className="icon">
                    <svg width="92" height="93" viewBox="0 0 92 93" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path fillRule="evenodd" clipRule="evenodd" d="M45.9997 0.5C20.5953 0.5 0.000976562 21.0943 0.000976562 46.4987C0.000976562 71.9031 20.5953 92.4974 45.9997 92.4974C71.404 92.4974 91.9984 71.9031 91.9984 46.4987C91.9984 21.0943 71.404 0.5 45.9997 0.5ZM20.4449 51.6305L37.2203 72.0546L71.5546 30.2526L63.9097 20.945L37.2203 53.4723L28.0897 42.3229L20.4449 51.6305Z" fill="#87C860"/>
                    </svg>
                </div>
                <button onClick={() => toHomePage()} className="btn btn-primary"><FormattedMessage id="Page404.returnCrossa" /></button>
            </main>
        </div>
    );
}

export default PaymentSuccess;
