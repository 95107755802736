import * as MenuService from "service/MenuService";

const getDataForMenuHandler = async (mid) => {

    let menu = {};
    let pages = [];
    let dashboard = {};
    const response = await MenuService.findById(mid);
    if (response.ok) {
        menu = {
            "id": response.data.id,
            "name": response.data.name,
            "wid": response.data.wid,
            "role": response.data.role,
        };
        pages = [...response.data.pages];
        dashboard = response.data.dashboard;
    }

    return {
        data: {
            menu: menu,
            allPages: pages,
            dashboard: dashboard || {
                "name": "Dashboard",
                "widgets": []
            },
            widgetIndex: dashboard?.widgets?.length? 0:null
        },
        ok: response.ok
    };
}

const saveMenuHandler = async (mid, menu) => {
    let response;

    if (mid) {
        response = await MenuService.updateMenu(menu);
    } else {
        response = await MenuService.insertMenu(menu);
    }

    return {
        data: {
            message: response.data.message
        },
        ok: response.ok
    }
}
export {
    getDataForMenuHandler,
    saveMenuHandler
}
