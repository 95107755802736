import * as RecordService from "service/RecordService";
import Moment from "react-moment";
import {DATE_FORMAT} from "util/DateUtil";
import currencies from "currency-codes";
import {FormattedMessage} from "react-intl";
import EXCEPTION_MESSAGES, {RECORD_FIELD_TYPE} from "../../../util/Constants";

const prepareRecords = (records) => {
    return records.values.map(record => {
        let newObject = {}
        for (let property in record) {
            if ((records[property] === "RecordList" || records[property] === "RolesList") && record[property] !== null) {
                let nameListString = "";
                const len = record[property].length;
                record[property].forEach((recordRef, index) => {
                    nameListString += recordRef.name + (index !== len - 1 ? ", " : "");
                })
                newObject = {
                    ...newObject,
                    [property]: nameListString,
                }
            } else if (records[property] === "MultipleChoice" && record[property] !== null) {
                let nameListString = "";
                const len = record[property].length;
                record[property].forEach((option, index) => {
                    nameListString += option + (index !== len - 1 ? ", " : "");
                })
                newObject = {
                    ...newObject,
                    [property]: nameListString,
                }
            } else if (records[property] === "Record" && record[property] !== null) {
                newObject = {
                    ...newObject,
                    [property]: record[property].name
                }
            } else if (records[property] === "Date") {
                let dateField = records.recordTypeFields?.find(field => field.id === property)
                newObject = {
                    ...newObject,
                    [property]: <Moment
                        format={dateField?.timeField ? DATE_FORMAT.DEFAULT : DATE_FORMAT.DAY_MONTH_YEAR}
                        date={record[property]}
                    />
                }
            } else if (records[property] === "Boolean") {
                newObject = {
                    ...newObject,
                    [property]: record[property].toString()
                }
            } else if (records[property] === "Payment") {
                let currency = record[property].currency ? currencies.code(record[property].currency) : null;
                newObject = {
                    ...newObject,
                    [property]: record[property].paymentType === "Online" ?
                        (record[property].paymentType + " (" + record[property].paymentStatus + ")") :
                        record[property].paymentType,
                    "paymentOption": record[property].paymentOption.name + (currency ? "(" +
                        (record[property].paymentOption.price/Math.pow(10, currency.digits)).toFixed(currency.digits) +
                        " " + record[property].currency + ")" : "")
                }
            } else {
                newObject = {
                    ...newObject,
                    [property]: record[property]
                }
            }
        }
        return newObject
    });
}

const getAllFormDataRecordsHandler=async(vid,request)=>{

    let columns=[];
    let records={};
    let tableRecords={};
    let recordTypeFields=[];
    let recordTypeName="";
    let rtType="";
    let hiddenFields=false;
    const response = await RecordService.findAllRegistrationsInView(vid,request);
    if (response.ok) {
        let noOfColumns=3;
        if(response.data.recordType.fields.find(field=>field.id==="payment")){
            if(request.pageable?.sort){
                columns.push({
                    Header: () => (
                        <div><FormattedMessage id="Form.payment"/></div>),
                    accessor: "payment",
                    sorted: request.pageable.sort && request.pageable.sort[0].split(",")[0] === "payment",
                    sortedDesc: request.pageable.sort && request.pageable.sort[0].split(",")[0] === "payment" ? request.pageable.sort[0].split(",")[1] === "DESC" : null
                },{
                    Header: () => (
                        <div><FormattedMessage id="Form.paymentOption"/></div>),
                    accessor: "paymentOption",
                    sorted: request.pageable.sort && request.pageable.sort[0].split(",")[0] === "payment",
                    sortedDesc: request.pageable.sort && request.pageable.sort[0].split(",")[0] === "payment" ? request.pageable.sort[0].split(",")[1] === "DESC" : null
                })
            } else{
                columns.push({
                    Header: () => (
                        <div><FormattedMessage id="Form.payment"/></div>),
                    accessor: "payment",
                },{
                    Header: () => (
                        <div><FormattedMessage id="Form.paymentOption"/></div>),
                        accessor: "paymentOption",
                })
            }
            noOfColumns--;
        }

        response.data.recordType.fields.filter(field=>field.id!=="payment" && field.type!==RECORD_FIELD_TYPE.GROUP_HEADER).slice(0,noOfColumns).forEach(field => {
            if(request.pageable?.sort){
                columns.push({
                    Header: () => (
                        <div>{field.name}</div>),
                    accessor: field.id,
                    sorted: request.pageable.sort && request.pageable.sort[0].split(",")[0] === field.id,
                    sortedDesc: request.pageable.sort && request.pageable.sort[0].split(",")[0] === field.id ? request.pageable.sort[0].split(",")[1] === "DESC" : null
                })
            } else{
                columns.push({
                    Header: () => (
                        <div>{field.name}</div>),
                    accessor: field.id,
                })
            }
        });

        hiddenFields = response.data.recordType.fields.find(field=>field.hide);

        if(hiddenFields){
            columns.push({
                Header: () => (
                    <div><FormattedMessage id="Actions" /></div>),
                accessor: "actions",
            })
        }
        response.data.recordType.fields.forEach(field => {
            let fieldMode = response.data.recordView.fieldModes.find(fieldMode => fieldMode.id === field.id);
            recordTypeFields.push({
                ...fieldMode,
                ...field
            })
        });

        let helperObj = {};
        response.data.recordType.fields.map(field => {
            helperObj = {
                ...helperObj,
                [field.id]: field.type,
            }
        });
        records = response.data.records;
        tableRecords = prepareRecords({
            "values": response.data.records,
            "recordTypeFields": response.data.recordType.fields,
            ...helperObj
        });
        recordTypeName = response.data.recordType?.name;
        rtType = response.data.recordType?.type;
    }
    return{
        data:{
            columns:columns,
            tableRecords:tableRecords,
            records:records,
            recordTypeFields:recordTypeFields,
            sendMessageAvailable:!!records.find(record => record.createdBy),
            rtType:rtType,
            hiddenFields:hiddenFields,
            recordTypeName:recordTypeName,
        },
        ok:response.ok
    };
}

const updateFormDataRecordHandler = async (newRecord, vid, request) => {
    let data = {};
    let ok = true;
    const response = await RecordService.updateRecordByViewId(newRecord, vid);
    ok = ok && response.ok;
    if (response.ok) {

        let finalResponse = await getAllFormDataRecordsHandler(vid,request);
        ok = ok && finalResponse.ok;
        if (finalResponse.ok) {
            data = {
                ...data,
                ...finalResponse.data,
            };
        }
    } else {
        if (!!response.data.message && response.data.message !== EXCEPTION_MESSAGES.INVALID_REFRESH_TOKEN) {
            data = {
                ...data,
                message: response.data.error
            };
        }
    }

    return {
        data: data,
        ok: ok
    };
}

const getAllRegistrationsInRecordViewWithNameContainsHandler = async (nameContains, viewId) => {
    let records = [];
    const response = await RecordService.findAllRecordsInRecordViewWithNameContains(nameContains, viewId);
    if (response.ok) {
        records = response.data.map(element => {
            return {
                "label": element.name,
                "value": element.id,
            }
        });
    }
    return {
        data: {
            records: records
        },
        ok: response.ok
    };
}

const exportAllRegistrationsInViewHandler = async (vid, request) => {
    let data={};
    const response = await RecordService.exportAllRegistrationsInView(vid, request);
    if(response.ok){
        data=response.data;
    }

    return{
        data:data,
        ok:response.ok
    };
}

export{
    getAllFormDataRecordsHandler,
    updateFormDataRecordHandler,
    getAllRegistrationsInRecordViewWithNameContainsHandler,
    exportAllRegistrationsInViewHandler
};
