import React, {useEffect, useState} from "react";
// react-bootstrap components
import {addIconWithoutBg, caretIconClosed, caretIconOpened, viewIcon} from "../../../../../components/Icons";
import {Button, OverlayTrigger, Tooltip} from "react-bootstrap";
import {FormattedMessage} from "react-intl";

function TreeItem(props) {

    const [state,setState]=useState({
        expanded:false,
        isSelected:props.selected,
        children:props.children,
        title:""
    });

    useEffect(() => {
        setState({
            ...state,
            title:props.title,
            children:props.children,
            isSelected:props.selected
        })
    }, [props])

    const addView = async () => {
        props.addItem(props.index,props.rtid);
    }

    const onClickHandler = () => {
        if (state.isSelected === false && state.expanded === true) {
            setState({
                ...state,
                expanded:false
            });
        } else {
            setState({
                ...state,
                expanded:!state.expanded
            });
        }
    }

    const mainItem =
        <div className={"row " + (state.isSelected ? "selected" : "")} onClick={onClickHandler}>

            <div className="menuDiv">
                {props.depth === 1 && <span className="icon view-icon-container">{viewIcon}</span>}
                <p>{state.title}</p>
            </div>
            <div className="actions">
                {
                    props.parent ?
                        (props.delete ?
                            <OverlayTrigger
                                onClick={(e) => e.preventDefault()}
                                overlay={
                                    <Tooltip>
                                        <FormattedMessage id="Button.delete"/>
                                    </Tooltip>
                                }
                            >
                                <Button
                                    variant="danger"
                                    size="xxl"
                                    className="btn-link remove text-danger mb-0"
                                    onClick={(e) => {
                                        e.stopPropagation()
                                        props.deleteView(props.id);
                                    }}
                                >
                                    <i className="fa fa-trash"/>
                                </Button>
                            </OverlayTrigger>
                            : null)
                        :
                        <div className="caret">
                            {state.expanded ? <span>{caretIconOpened}</span> : <span>{caretIconClosed}</span>}
                        </div>
                }
            </div>
        </div>

    let result;

    if (state.expanded) {
        result = (
            <>
                <div
                    className={"tree-item row " + (state.isSelected || (state.children?.find(item => item.selected)) ? "selected  " : "")}
                    onClick={() => props.selectItem({"index": props.index, "parentName": props.parent})}>
                    {mainItem}
                </div>
                {props.parent === undefined && state.expanded ?
                    <div ref={props.propRef} className="tree-item-children">
                        {
                            state.children?.map((item, index) => {
                                    return <TreeItem
                                        key={index + item.name}
                                        index={index}
                                        title={item.name}
                                        selectItem={item.selectItem}
                                        depth={props.depth + 1}
                                        selected={item.selected}
                                        parent={props.title}
                                        delete={item.delete}
                                        deleteView={props.deleteView}
                                        id={item.id}
                                    />
                                }
                            )
                        }
                        <Button variant="link" onClick={addView}>
                            <span className="icon-cont">
                                {addIconWithoutBg}
                            </span>
                            <FormattedMessage id="Button.addView" />
                        </Button>
                    </div>
                    :
                    null
                }
            </>
        )
    } else {
        result = (
            <div
                className={"tree-item row " + (state.isSelected || (state.children?.find(item => item.selected)) ? "selected  " : "")}
                onClick={() => props.selectItem({"index": props.index, "parentName": props.parent})}>
                {mainItem}
            </div>
        );
    }

    return result;
}

export default TreeItem;
