import React, {useEffect, useState} from "react";
import {useHistory} from "react-router-dom";
import {Col, Form, Row,} from "react-bootstrap";
import Select from "react-select";
import {FormattedMessage} from "react-intl";
import AsyncSelect from "react-select/async";
import TagsInput from "views/workspaceMember/menuDisplay/components/TagsInput";
import {
    getAllRecordsWithNameContainsHandler
} from "handlers/workspaceAdminHandlers/recordTypeHandlers/AddRuleRecordTypeHandlers";
import {findRecordByIdHandler} from "handlers/workspaceAdminHandlers/recordTypeHandlers/AddRuleRecordTypeHandlers";
import {
    getRecordTypeAndRecordViewByWorkspaceIdHandler
} from "handlers/workspaceAdminHandlers/recordTypeHandlers/AddRuleRecordTypeHandlers";
import {getRecordTypeByIdHandler} from "handlers/workspaceAdminHandlers/recordTypeHandlers/SaveRecordTypeHandlers";
import DateTimePicker from "react-datetime-picker";
import {iconX} from "../../../../../components/Icons";

function ActionEditor(props) {

    const history = useHistory();

    const [state, setState] = useState({
        actions: [],
        fields: [],
        attributeCurrent: [],
        type: "",
        recordTypeMemberFields: [],
        operator: null,
        inputChoiceOptions: [],
        valueFromOptions: [], //to value, to value from record, to current member
        valueFrom: null //to value, to value from record, to current member
    });

    useEffect(async () => {
        let valueFromOptions = [];
        let valueFrom = null;

        if (props.action?.type) {
            if (props.action?.operator) {
                switch (props.action?.type) {
                    case "text":
                        for (let operator in textOperators) {
                            if (textOperators[operator].find(op => op.value === props?.action?.operator)) {
                                valueFromOptions = textOperators[operator];
                                valueFrom = textOperators[operator].find(op => op.value === props?.action?.operator);
                                break;
                            }
                        }
                        break;
                    case "integer":
                        for (let operator in integerOperators) {
                            if (integerOperators[operator].find(op => op.value === props.action.operator)) {
                                valueFromOptions = integerOperators[operator];
                                valueFrom = integerOperators[operator].find(op => op.value === props.action.operator);
                                break;
                            }
                        }
                        break;
                    case "decimal":
                    case "currency":
                        for (let operator in decimalOperators) {
                            if (decimalOperators[operator].find(op => op.value === props.action.operator)) {
                                valueFromOptions = decimalOperators[operator];
                                valueFrom = decimalOperators[operator].find(op => op.value === props.action.operator);
                                break;
                            }
                        }
                        break;
                    case "date":
                        for (let operator in dateOperators) {
                            if (dateOperators[operator].find(op => op.value === props.action.operator)) {
                                valueFromOptions = dateOperators[operator];
                                valueFrom = dateOperators[operator].find(op => op.value === props.action.operator && (props.action.attributeCurrent === "currentDate" ? op.toCurrentDate : !op.toCurrentDate));
                                break;
                            }
                        }
                        break;
                    case "singlechoice":
                        for (let operator in singleOperators) {
                            if (singleOperators[operator].find(op => op.value === props.action.operator)) {
                                valueFromOptions = singleOperators[operator];
                                valueFrom = singleOperators[operator].find(op => op.value === props.action.operator);
                                break;
                            }
                        }
                        break;
                    case "multiplechoice":
                        for (let operator in multiOperators) {
                            if (multiOperators[operator].find(op => op.value === props.action.operator)) {
                                valueFromOptions = multiOperators[operator];
                                valueFrom = multiOperators[operator].find(op => op.value === props.action.operator);
                                break;
                            }
                        }
                        break;
                    case "boolean":
                        for (let operator in booleanOperators) {
                            if (booleanOperators[operator].find(op => op.value === props.action.operator)) {
                                valueFromOptions = booleanOperators[operator];
                                valueFrom = booleanOperators[operator].find(op => op.value === props.action.operator);
                                break;
                            }
                        }
                        break;
                    case "record":
                        for (let operator in recordOperators) {
                            if (recordOperators[operator].find(op => op.value === props.action.operator)) {
                                valueFromOptions = recordOperators[operator];
                                valueFrom = recordOperators[operator].find(op => op.value === props.action.operator && (props.action.attributeCurrent === "currentMember.id" ? op.toCurrentMember : !op.toCurrentMember));
                                break;
                            }
                        }
                        break;
                    case "recordlist":
                        for (let operator in recordListOperators) {
                            if (recordListOperators[operator].find(op => op.value === props.action.operator)) {
                                valueFromOptions = recordListOperators[operator];
                                valueFrom = recordListOperators[operator].find(op => op.value === props.action.operator && (props.action.attributeCurrent === "currentMember.id" ? op.toCurrentMember : !op.toCurrentMember));
                                break;
                            }
                        }
                        break;
                }
            } else {
                switch (props.action.type) {
                    case "text":
                        valueFromOptions = textOperators[props.action?.selectedAction?.value];
                        break;
                    case "integer":
                        valueFromOptions = integerOperators[props.action?.selectedAction?.value];
                        break;
                    case "decimal":
                    case "currency":
                        valueFromOptions = decimalOperators[props.action?.selectedAction?.value];
                        break;
                    case "date":
                        valueFromOptions = dateOperators[props.action?.selectedAction?.value];
                        break;
                    case "singlechoice":
                        valueFromOptions = singleOperators[props.action?.selectedAction?.value];
                        break;
                    case "multiplechoice":
                        valueFromOptions = multiOperators[props.action?.selectedAction?.value];
                        break;
                    case "boolean":
                        valueFromOptions = booleanOperators[props.action?.selectedAction?.value];
                        break;
                    case "record":
                        valueFromOptions = recordOperators[props.action?.selectedAction?.value];
                        break;
                    case "recordlist":
                        valueFromOptions = recordListOperators[props.action?.selectedAction?.value];
                        break;
                }
            }
        }

        let fields = props.recordFields?.filter(recordField => recordField.type.toLocaleLowerCase() !== "groupheader" && recordField.type.toLocaleLowerCase() !== "workspaceuser" && recordField.type.toLocaleLowerCase() !== "notifications" &&
            recordField.type.toLocaleLowerCase() !== "image" && recordField.type.toLocaleLowerCase() !== "roleslist" && recordField.type.toLocaleLowerCase() !== "file" && recordField.type.toLocaleLowerCase() !== "filelist").map(recordField => {
            return {
                "label": recordField.name,
                "type": recordField.type.toLowerCase(),
                "value": recordField.id,
                "valueOptions": recordField.options,
                "rtid": recordField.rtid,
            }
        });

        let fieldOptionsForRecord = [];
        if (props.action.recordField && props.recordFields.length)
            fieldOptionsForRecord = await getRecordTypeById(props.recordFields.find(field => field.id === props.action.recordField).rtid);


        let inputChoiceOptions = [];
        if (props.action.type === "singlechoice" || props.action.type === "multiplechoice") {
            inputChoiceOptions = fields.find(field => field.value === props.action?.operand?.value)?.valueOptions?.map(valueOption => {
                return {
                    "value": valueOption,
                    "label": valueOption
                }
            });
        } else if(props.action.type==="boolean"){
            inputChoiceOptions=[{value: "true", label: "true"}, {value: "false", label: "false"}];
        }

        setState({
            ...state,
            actions: props.actionOptions.map(action => {
                return {
                    "label": action.label,
                    "value": action.value,
                }
            }),
            fieldOptionsForRecord: fieldOptionsForRecord,
            valueFromOptions: valueFromOptions,
            valueFrom: valueFrom,
            fields: fields,
            inputChoiceOptions: inputChoiceOptions
        });
    }, [props]);

    const promiseOptions = (inputValue, rtid,values) =>
        new Promise(resolve => {
            resolve(getAllRecordsWithNameContains(inputValue, rtid,values));
        });

    const getAllRecordsWithNameContains = async (nameContains, recordTypeId,values) => {
        return getAllRecordsWithNameContainsHandler(nameContains, recordTypeId).then(response => {
            if (response.ok) {
                if(values){
                    return response.data.records.filter(record=>!values.find(r=>r.id===record.value))
                } else {
                    return response.data.records;
                }
            }
        })
    }

    const getRecordTypeAndRecordViewByWorkspaceId = async (wid) => {
        getRecordTypeAndRecordViewByWorkspaceIdHandler(wid).then(response => {
            if (response.ok) {
                setState({
                    ...state,
                    ...response.data,
                });
            }
        });
    }

    const getRecordTypeById = async recordTypeId => {
        return getRecordTypeByIdHandler(recordTypeId).then(response => {
            if (response.ok) {
                return response.data.recordFields?.filter(field => {
                    if (field.type.toLocaleLowerCase() === props.action.type && (field.type.toLocaleLowerCase() === "multiplechoice" || field.type.toLocaleLowerCase() === "singlechoice")) {
                        return field.options.every(opt => props.recordFields.find(field => field.id === props.action.operand?.value)?.options.includes(opt));
                    } else return (field.type.toLocaleLowerCase() === props.action.type && !field.rtid) || (field.type.toLocaleLowerCase() === props.action.type && field.rtid === props.action.operand?.rtid);

                }).map(field => {
                    return {
                        label: field.name,
                        value: field.id,
                        type: field.type
                    }
                });
            }
        });
    }

    const findRecordById = async (recordId, rtid) => {
        findRecordByIdHandler(recordId, rtid).then(response => {
            if (response.ok) {
                props.changeAction({
                    ...props.action,
                    "inputValue": {
                        ...props.action.inputValue,
                        name: response.data.name
                    }
                }, props.index);
            }
        });
    }

    const handleActionChange = e => {
        let operator = "";
        if (e.value !== "SetValue") {
            operator = e.value;
        }
        let newErrors = {...props.errors, "operator": false, "inputValue": false};
        if (!!props.errors["selectedAction"]) {
            newErrors = {
                ...newErrors,
                "selectedAction": false,
            };
        }
        props.changeAction({
            ...props.action,
            "selectedAction": {
                "value": e.value,
                "label": e.label,
            },
            "operator": operator,
        }, props.index, newErrors);

    }

    const handleFieldChange = e => {
        let operator = props.action.operator;
        if (props.action?.selectedAction?.value === "SetValue") {
            operator = "";
        }
        let newErrors = {...props.errors, "operator": false, "inputValue": false};
        if (!!props.errors["operand"]) {
            newErrors = {
                ...newErrors,
                "operand": false,
            };
        }
        props.changeAction({
            ...props.action,
            "type": e.type,
            "operand": {
                "value": e.value,
                "label": e.label,
                "rtid": e.rtid
            },
            operator: operator,
            "inputValue": e.type === "boolean" ? false : ""
        }, props.index, newErrors);
    }

    const handleValueFromChange = e => {
        let newErrors = {
            ...props.errors,
            "inputValue": false,
            "attributeCurrent": false,
            "recordField": false,
            "innerField": false
        };
        if (!!props.errors["operator"]) {
            newErrors = {
                ...newErrors,
                "operator": false,
            };
        }
        let action = {
            ...props.action,
            operator: e.value
        };
        if (e.toCurrentMember) {
            action.attributeCurrent = "currentMember.id";
        } else if (e.toCurrentDate) {
            action.attributeCurrent = "currentDate";
        } else {
            action.attributeCurrent = "";
            action.inputValue = props.action.type === "boolean" ? false : ""
        }
        props.changeAction(action, props.index, newErrors);
    }

    const handleDateInputValueChange = value => {
        let newErrors = {...props.errors};
        if (!!props.errors["inputValue"]) {
            newErrors = {
                ...newErrors,
                "inputValue": false,
            };
        }
        if(value) {
            let userTimezoneOffset = new Date(value).getTimezoneOffset() * 60000;
            props.changeAction({
                ...props.action,
                "inputValue": new Date(value.getTime() - userTimezoneOffset),
            }, props.index, newErrors)
        } else {
            props.changeAction({
                ...props.action,
                "inputValue": null,
            }, props.index, newErrors)
        }
    }

    const handleInputValueChange = e => {
        let newErrors = {...props.errors};
        if (!!props.errors["inputValue"]) {
            newErrors = {
                ...newErrors,
                "inputValue": false,
            };
        }
        props.changeAction({
            ...props.action,
            "inputValue": props.action.type === "boolean" ? e.value === "true" :
                (props.action.type === "singlechoice" || props.action.type === "multiplechoice") ? e.value : e.target.value,
        }, props.index, newErrors);
    }

    const handleTagsSelect = (values) => {
        const newValues = values.map(value => {
            return props.action.inputValue?.find(tag => tag.name ? tag.name === value : tag === value);
        });
        props.changeAction({
            ...props.action,
            "inputValue": newValues.length ? newValues : ""
        }, props.index);
    }

    const handleRecordFieldChange = async (e) => {
        let newErrors = {...props.errors, "innerField": false};
        if (!!props.errors["recordField"]) {
            newErrors = {
                ...newErrors,
                "recordField": false,
            };
        }
        props.changeAction({
            ...props.action,
            "recordField": e.value,
        }, props.index, newErrors);
    }

    const handleInnerFieldChange = async (e) => {
        let newErrors = {...props.errors};
        if (!!props.errors["innerField"]) {
            newErrors = {
                ...newErrors,
                "innerField": false,
            };
        }
        props.changeAction({
            ...props.action,
            "innerField": e.value,
        }, props.index, newErrors)
    }

    const handleAttributeCurrentChange = (e) => {
        let newErrors = {...props.errors};
        if (!!props.errors["attributeCurrent"]) {
            newErrors = {
                ...newErrors,
                "attributeCurrent": false,
            };
        }
        props.changeAction({
            ...props.action,
            "attributeCurrent": "currentMember." + e.value,
        }, props.index, newErrors)
    }
    const getSelect3InputField = () => {
        let result = "";
        //value from variable field
        if (props.action.operator === "SetParam") {
            if (state.recordTypeMemberFields.length === 0) {
                const wid = history.location?.pathname.split("/")[2];
                getRecordTypeAndRecordViewByWorkspaceId(wid);
            }
            return (<>
                    <FormattedMessage id="Field">
                        {placeholder =>
                            <Select
                                className={"react-select primary " + (props.errors?.attributeCurrent ? "has-errors" : "")}
                                classNamePrefix="react-select"
                                name="type"
                                placeholder={placeholder}
                                options={state.recordTypeMemberFields.filter(recordType => (recordType.type.toLocaleLowerCase() === props.action.type && props.action.type !== "record") || (recordType.type.toLocaleLowerCase() === props.action.type && recordType.rtid === props.action.operand?.rtid))}
                                value={props.action.attributeCurrent ?
                                    {
                                        "value": props.action.attributeCurrent.substring(14),
                                        "label": state.recordTypeMemberFields.find(field => field.value === props.action.attributeCurrent.substring(14))?.label
                                    } : ""}
                                onChange={handleAttributeCurrentChange}
                                isInvalid={props.errors?.attributeCurrent}
                            />
                        }
                    </FormattedMessage>
                    {
                        props.errors?.attributeCurrent && <div className={"error-message"}>
                            <FormattedMessage id={"Validation.selectOneOption"}/>
                        </div>
                    }</>
            );
        }
        if (props.action.operator === "SetRecordInnerField") {
            return (<>
                    <FormattedMessage id="Record">
                        {placeholder =>
                            <Select
                                className={"react-select primary " + (props.errors?.recordField ? "has-errors" : "")}
                                classNamePrefix="react-select"
                                name="type"
                                placeholder={placeholder}
                                options={props.recordFields.filter(recordField => (recordField.type.toLocaleLowerCase() === "record")).map(recordField => {
                                    return {label: recordField.name, value: recordField.id};
                                })}
                                value={props.action.recordField ?
                                    {
                                        "value": props.action.recordField,
                                        "label": props.recordFields.find(field => field.id === props.action.recordField)?.name
                                    } : ""}
                                onChange={handleRecordFieldChange}
                                isInvalid={props.errors?.recordField}
                            />
                        }
                    </FormattedMessage>
                    {
                        props.errors?.recordField && <div className={"error-message"}>
                            <FormattedMessage id={"Validation.selectOneOption"}/>
                        </div>
                    }
                </>

            );
        }
        //to value from input - selected
        switch (props.action.type) {
            case "text":
                result = <>
                    <FormattedMessage id="Value" >
                        {placeholder =>
                            <Form.Control className={props.errors?.inputValue ? "has-errors" : ""}
                                          placeholder={placeholder}
                                          type="text"
                                          onChange={handleInputValueChange}
                                          isInvalid={props.errors?.inputValue}
                                          value={props.action.inputValue}
                            />
                        }
                    </FormattedMessage>
                    <Form.Control.Feedback type="invalid">
                        <FormattedMessage id={"Validation.cannotBeEmpty"}/>
                    </Form.Control.Feedback></>
                break;
            case "integer":
                result = <>
                    <FormattedMessage id="Value" >
                        {placeholder =>
                            <Form.Control className={props.errors?.inputValue ? "has-errors" : ""}
                                          placeholder={placeholder}
                                          type="number"
                                          onChange={handleInputValueChange}
                                          value={props.action.inputValue}
                                          isInvalid={props.errors?.inputValue}/>
                        }
                    </FormattedMessage>
                    <Form.Control.Feedback type="invalid">
                        <FormattedMessage id={"Validation.cannotBeEmpty"}/>
                    </Form.Control.Feedback></>
                break;
            case "decimal":
                result = <>
                    <FormattedMessage id="Value" >
                        {placeholder =>
                            <Form.Control className={props.errors?.inputValue ? "has-errors" : ""}
                                          placeholder={placeholder}
                                          type="number"
                                          onChange={handleInputValueChange}
                                          value={props.action.inputValue}
                                          isInvalid={props.errors?.inputValue}/>
                        }
                    </FormattedMessage>
                    <Form.Control.Feedback type="invalid">
                        <FormattedMessage id={"Validation.cannotBeEmpty"}/>
                    </Form.Control.Feedback></>
                break;
            case "currency":
                result = <>
                    <FormattedMessage id="Value" >
                        {placeholder =>
                            <Form.Control className={props.errors?.inputValue ? "has-errors" : ""}
                                          placeholder={placeholder}
                                          type="number"
                                          onChange={handleInputValueChange}
                                          value={props.action.inputValue}
                                          isInvalid={props.errors?.inputValue}/>
                        }
                    </FormattedMessage>
                    <Form.Control.Feedback type="invalid">
                        <FormattedMessage id={"Validation.cannotBeEmpty"}/>
                    </Form.Control.Feedback></>
                break;
            case "date":
                if (props.action.inputValue !== "currentDate") {
                    result = <>
                        <DateTimePicker
                            maxDate={new Date("9999-12-31")}
                            className={props.errors?.inputValue ? "has-errors" : ""}
                            onChange={handleDateInputValueChange}
                            format={"y-MM-dd"}
                            value={props.action.inputValue ? new Date(props.action.inputValue) : ""}
                        />
                        {
                            props.errors?.inputValue && <div className="error-message">
                                <FormattedMessage id={"Validation.cannotBeEmpty"}/>
                            </div>
                        }
                    </>
                } else {
                    result = null
                }
                break;
            case "boolean":
                result = <Select
                    className="react-select primary"
                    classNamePrefix="react-select"
                    onChange={handleInputValueChange}
                    options={state.inputChoiceOptions}
                    value={
                        props.action.inputValue == null ?
                            null :
                            {
                                "value":  props.action.inputValue.toString(),
                                "label":  props.action.inputValue.toString()
                            }
                    }
                />
                break;
            case "singlechoice":
                result = <><Select
                    className={"react-select primary " + (props.errors?.inputValue ? "has-errors" : "")}
                    classNamePrefix="react-select"
                    onChange={handleInputValueChange}
                    options={state.inputChoiceOptions}
                    value={{
                        "value": props.action.inputValue,
                        "label": props.action.inputValue
                    }}
                    isInvalid={props.errors?.inputValue}
                />{
                    props.errors?.inputValue && <div className={"error-message"}>
                        <FormattedMessage id={"Validation.selectOneOption"}/>
                    </div>
                }</>
                break;
            case "multiplechoice":
                result = <Form.Group>

                    <Select
                        className={"react-select primary " + (props.errors?.inputValue ? "has-errors" : "")}
                        classNamePrefix="react-select"
                        options={state.inputChoiceOptions.filter(option => !props.action.inputValue || !props.action.inputValue.includes(option.value))}
                        value={null}
                        onChange={event => {
                            let newErrors = {...props.errors};
                            if (!!props.errors["inputValue"]) {
                                newErrors = {
                                    ...newErrors,
                                    "inputValue": false,
                                };
                            }
                            props.changeAction({
                                ...props.action,
                                "inputValue": [...props.action.inputValue, event.value]
                            }, props.index, newErrors)
                        }}
                        isInvalid={props.errors?.inputValue}
                    />
                    {
                        !!props.action.inputValue && !!props.action.inputValue.length &&
                        <TagsInput
                            className={"bottom-tags-input"}
                            value={props.action.inputValue?.map(tag => tag)}
                            onChange={(value) => handleTagsSelect(value)}
                            tagProps={{className: "react-tagsinput-tag tag-azure"}}
                        />
                    }
                    {
                        props.errors?.inputValue && <div className={"error-message"}>
                            <FormattedMessage id={"Validation.selectAtLeastOneOption"}/>
                        </div>
                    }
                </Form.Group>
                break;
            case "record":
                result = <><AsyncSelect
                    className={"react-select primary " + (props.errors?.inputValue ? "has-errors" : "")}
                    classNamePrefix="react-select"
                    placeholder={<FormattedMessage id={"Search"}/>}
                    noOptionsMessage={({inputValue}) => !inputValue ?
                        <FormattedMessage id={"SearchInRecords"} /> :
                        <FormattedMessage id={"Placeholder.noResults"}/>}
                    onChange={event => {
                        let newErrors = {...props.errors};
                        if (!!props.errors["inputValue"]) {
                            newErrors = {
                                ...newErrors,
                                "inputValue": false,
                            };
                        }
                        props.changeAction({
                            ...props.action,
                            "inputValue": {
                                id: event.value,
                                name: event.label
                            }
                        }, props.index, newErrors);
                    }}
                    value={props.action?.inputValue && !!!props.action?.inputValue?.name ?
                        props.recordFields.length && findRecordById(props.action.inputValue.id, props.recordFields.find(recField => recField.id === props.action.operand.value).rtid) :
                        props.action?.inputValue ? {
                            "value": props.action.inputValue.id,
                            "label": props.action.inputValue.name
                        } : false}
                    isInvalid={props.errors?.inputValue}
                    loadOptions={(inputValue) => promiseOptions(inputValue, props.recordFields.find(recField => recField.id === props.action.operand.value)?.rtid)}
                />{
                    props.errors?.inputValue && <div className={"error-message"}>
                        <FormattedMessage id={"Validation.selectOneOption"}/>
                    </div>
                }</>
                break;
            case "recordlist":
                result = <Form.Group>
                    <AsyncSelect
                        className={"react-select primary " + (props.errors?.inputValue ? "has-errors" : "")}
                        classNamePrefix="react-select"
                        placeholder={<FormattedMessage id={"Search"}/>}
                        noOptionsMessage={({inputValue}) => !inputValue ?
                            <FormattedMessage id={"SearchInRecords"}/> :
                            <FormattedMessage id={"Placeholder.noResults"}/>}
                        value={null}
                        loadOptions={(inputValue) => promiseOptions(inputValue, props.recordFields.find(recField => recField.id === props.action.operand.value)?.rtid,props.action.inputValue)}
                        onChange={event => {
                                let newErrors = {...props.errors};
                                if (!!props.errors["inputValue"]) {
                                    newErrors = {
                                        ...newErrors,
                                        "inputValue": false,
                                    };
                                }
                                props.changeAction({
                                    ...props.action,
                                    "inputValue": [...props.action.inputValue, {
                                        id: event.value,
                                        name: event.label
                                    }]
                                }, props.index, newErrors)
                            }
                        }
                        isInvalid={props.errors?.inputValue}
                    />
                    {
                        !!props.action.inputValue && !!props.action.inputValue.length &&
                        <TagsInput
                            className={"bottom-tags-input"}
                            value={props.action.inputValue.map(tag => tag.name)}
                            onChange={(value) => handleTagsSelect(value)}
                            tagProps={{className: "react-tagsinput-tag tag-azure"}}
                        />
                    }
                    {
                        props.errors?.inputValue && <div className={"error-message"}>
                            <FormattedMessage id={"Validation.selectAtLeastOneOption"}/>
                        </div>
                    }
                </Form.Group>
                break;
            default:
                result = null;
        }
        return result;
    }


    return (
        <div className="action-editor">
            <Row>
                <Col className="type-col">
                    <Form.Group>
                        <FormattedMessage id="Action">
                            {placeholder =>
                                <Select
                                    className={"react-select primary text-warning " + (props.errors?.selectedAction ? "has-errors" : "")}
                                    classNamePrefix="react-select"
                                    name="type"
                                    value={props.action?.selectedAction?.value ? props.action.selectedAction : null}
                                    options={state.actions}
                                    placeholder={placeholder}
                                    onChange={handleActionChange}
                                    isInvalid={props.errors?.selectedAction}
                                />
                            }
                        </FormattedMessage>
                        {
                            props.errors?.selectedAction && <div className={"error-message"}>
                                <FormattedMessage id={"Validation.selectOneOption"}/>
                            </div>
                        }
                    </Form.Group>
                </Col>
                <Col className="type-col">
                    <Form.Group>
                        <FormattedMessage id="Field">
                            {placeholder =>
                                <Select
                                    className={"react-select primary " + (props.errors?.operand ? "has-errors" : "")}
                                    classNamePrefix="react-select"
                                    name="type"
                                    options={state.fields}
                                    value={props.action?.operand ? props.action?.operand : null}
                                    onChange={handleFieldChange}
                                    placeholder={placeholder}
                                    isInvalid={props.errors?.operand}
                                />
                            }
                        </FormattedMessage>
                        {
                            props.errors?.operand && <div className={"error-message"}>
                                <FormattedMessage id={"Validation.selectOneOption"}/>
                            </div>
                        }
                    </Form.Group>
                </Col>
                {props.action?.selectedAction?.value === "SetValue" && props.action?.operand &&
                    <Col className="type-col">
                        <Form.Group>
                            <FormattedMessage id="Operator">
                                {placeholder =>
                                    <Select
                                        className={"react-select primary text-warning " + (props.errors?.operator ? "has-errors" : "")}
                                        classNamePrefix="react-select"
                                        name="type"
                                        options={state.valueFromOptions}
                                        value={state.valueFrom}
                                        onChange={handleValueFromChange}
                                        placeholder={placeholder}
                                        isInvalid={props.errors?.operator}
                                    />
                                }
                            </FormattedMessage>
                            {
                                props.errors?.operator && <div className={"error-message"}>
                                    <FormattedMessage id={"Validation.selectOneOption"}/>
                                </div>
                            }
                        </Form.Group>
                    </Col>
                }
                {
                    ((props.action?.operator === "SetParam" && props.action?.attributeCurrent !== "currentMember.id" && props.action?.attributeCurrent !== "currentDate")) &&
                    <Col className="eqParam-col">
                        <Form.Group>
                            <FormattedMessage id="CurrentMember">
                                {value=>
                                    <Form.Control
                                        className={"text-purple"}
                                        name="name"
                                        type="text"
                                        value={value}
                                        readOnly
                                    />
                                }
                            </FormattedMessage>
                        </Form.Group>
                    </Col>
                }
                {props.action?.selectedAction?.value === "SetValue" && state.valueFrom && (props.action.operator !== "SetParam" || (props.action.attributeCurrent !== "currentMember.id" && props.action.attributeCurrent !== "currentDate")) &&
                    <Col className={props.action.type === "date" && props.action.inputValue !== "currentDate" ? "value-col bigger" : "value-col"}>
                        <Form.Group>
                            {getSelect3InputField()}
                        </Form.Group>
                    </Col>}
                {props.action?.selectedAction?.value === "SetValue" && props.action.operator === "SetRecordInnerField" &&
                    <Col className="value-col">
                        <Form.Group>
                            <FormattedMessage id="Field">
                                {placeholder =>
                                    <Select
                                        className={"react-select primary " + (props.errors?.innerField ? "has-errors" : "")}
                                        classNamePrefix="react-select"
                                        name="type"
                                        placeholder={placeholder}
                                        options={state.fieldOptionsForRecord}
                                        value={props.action.innerField ?
                                            {
                                                "value": props.action.innerField,
                                                "label": state.fieldOptionsForRecord?.find(field => field.value === props.action.innerField)?.label
                                            } : ""}
                                        onChange={handleInnerFieldChange}
                                        isInvalid={props.errors?.innerField}/>
                                }
                            </FormattedMessage>
                            {
                                props.errors?.innerField && <div className={"error-message"}>
                                    <FormattedMessage id={"Validation.selectOneOption"}/>
                                </div>
                            }
                        </Form.Group>
                    </Col>}

                {props.noActions > 1 &&

                <Col className="buttons-col rule-editor-actions">
                    <span onClick={() => props.deleteAction(props.index)} className="icon-delete">{iconX}</span>
                </Col>
                }
            </Row>
        </div>
    )

}

const textOperators = {
    "SetValue": [
        {"label": <FormattedMessage id="Operators.toValue" />, "value": "SetString"},
        {"label": <FormattedMessage id="Operators.toRecordFieldValue" />, "value": "SetRecordInnerField"},
        {"label": <FormattedMessage id="Operators.toVariableFieldValue" />, "value": "SetParam"}
    ],
};

const integerOperators = {
    "SetValue": [
        {"label": <FormattedMessage id="Operators.toValue" />, "value": "SetLong"},
        {"label": <FormattedMessage id="Operators.toRecordFieldValue" />, "value": "SetRecordInnerField"},
        {"label": <FormattedMessage id="Operators.toVariableFieldValue" />, "value": "SetParam"}
    ],
};

const decimalOperators = {
    "SetValue": [
        {"label": <FormattedMessage id="Operators.toValue" />, "value": "SetDouble"},
        {"label": <FormattedMessage id="Operators.toRecordFieldValue" />, "value": "SetRecordInnerField"},
        {"label": <FormattedMessage id="Operators.toVariableFieldValue" />, "value": "SetParam"}
    ],
};

const booleanOperators = {
    "SetValue": [
        {"label": <FormattedMessage id="Operators.toValue" />, "value": "SetBoolean"},
        {"label": <FormattedMessage id="Operators.toRecordFieldValue" />, "value": "SetRecordInnerField"},
        {"label": <FormattedMessage id="Operators.toVariableFieldValue" />, "value": "SetParam"}
    ],
};

const dateOperators = {
    "SetValue": [
        {"label": <FormattedMessage id="Operators.toValue" />, "value": "SetDate"},
        {"label": <FormattedMessage id="Operators.toRecordFieldValue" />, "value": "SetRecordInnerField"},
        {"label": <FormattedMessage id="Operators.toVariableFieldValue" />, "value": "SetParam"},
        {"label": <FormattedMessage id="Operators.toCurrentDate" />,"value": "SetParam","toCurrentDate": true}
    ],
};

const recordOperators = {
    "SetValue": [
        {"label": <FormattedMessage id="Operators.toValue" />, "value": "SetRecord"},
        {"label": <FormattedMessage id="Operators.toRecordFieldValue" />,"value": "SetRecordInnerField"},
        {"label": <FormattedMessage id="Operators.toVariableFieldValue" />, "value": "SetParam"},
        {"label": <FormattedMessage id="Operators.toCurrentMember" />,"value": "SetParam","toCurrentMember": true}
    ],
};

const recordListOperators = {
    "SetValue": [
        {"label": <FormattedMessage id="Operators.toValue" />, "value": "SetRecordList"},
        {"label": <FormattedMessage id="Operators.toRecordFieldValue" />,"value": "SetRecordInnerField"},
        {"label": <FormattedMessage id="Operators.toVariableFieldValue" />, "value": "SetParam"},
    ],
};

const singleOperators = {
    "SetValue": [
        {"label": <FormattedMessage id="Operators.toValue" />, "value": "SetString"},
        {"label": <FormattedMessage id="Operators.toRecordFieldValue" />,"value": "SetRecordInnerField"},
        {"label": <FormattedMessage id="Operators.toVariableFieldValue" />, "value": "SetParam"}
    ],
};

const multiOperators = {
    "SetValue": [
        {"label": <FormattedMessage id="Operators.toValue" />, "value": "SetList"},
        {"label": <FormattedMessage id="Operators.toRecordFieldValue" />,"value": "SetRecordInnerField"},
        {"label": <FormattedMessage id="Operators.toVariableFieldValue" />, "value": "SetParam"}
    ],
};


export default ActionEditor;
