import * as ClientConfig from "config/ClientConfig.js";
import * as StorageService from "service/StorageService.js"
import * as RestClient from "util/api/restClient"


const findAllRecordTypes = async (wid) => {

    return RestClient.handleResponse(() => {
        const finalURL = ClientConfig.URL_RESOURCE_SERVER + ClientConfig.NAMESPACE_RECORD_TYPES + "workspaces/" + wid;
        const response = fetch(finalURL, {
            method: "GET",
            headers: {
                "Authorization": "Bearer " + StorageService.getAccessToken()
            }
        });

        return response;
    });
}

const addRecordType = async recordType => {
    return RestClient.handleResponse(() => {
        const finalURL = ClientConfig.URL_RESOURCE_SERVER + ClientConfig.NAMESPACE_RECORD_TYPES;
        const response = fetch(finalURL, {
            method: "POST",
            body: JSON.stringify(recordType),
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + StorageService.getAccessToken()
            }
        });

        return response;
    });
}

const deleteRecordType = async recordTypeId => {
    return RestClient.handleResponse(() => {
        const finalURL = ClientConfig.URL_RESOURCE_SERVER + ClientConfig.NAMESPACE_RECORD_TYPES + recordTypeId;
        const response = fetch(finalURL, {
            method: "DELETE",
            headers: {
                "Authorization": "Bearer " + StorageService.getAccessToken()
            }
        });

        return response;
    });
}

const findRecordTypeById = async recordTypeId => {
    return RestClient.handleResponse(() => {
        const finalURL = ClientConfig.URL_RESOURCE_SERVER + ClientConfig.NAMESPACE_RECORD_TYPES + recordTypeId;
        const response = fetch(finalURL, {
            method: "GET",
            headers: {
                "Authorization": "Bearer " + StorageService.getAccessToken()
            }
        });

        return response;
    });
}

const findRecordTypeTypeById = async recordTypeId => {
    return RestClient.handleResponse(() => {
        const finalURL = ClientConfig.URL_RESOURCE_SERVER + ClientConfig.NAMESPACE_RECORD_TYPES + recordTypeId + "/type";
        const response = fetch(finalURL, {
            method: "GET",
            headers: {
                "Authorization": "Bearer " + StorageService.getAccessToken()
            }
        });

        return response;
    });
}

const updateRecordType = async newRecordType => {
    return RestClient.handleResponse(() => {
        const id = newRecordType.id;
        const finalURL = ClientConfig.URL_RESOURCE_SERVER + ClientConfig.NAMESPACE_RECORD_TYPES + id;
        const response = fetch(finalURL, {
            method: "PUT",
            headers: {
                "Authorization": "Bearer " + StorageService.getAccessToken(),
                "Content-Type": "application/json",
            },
            body: JSON.stringify(newRecordType),
        });

        return response;
    });
}


const findAllRecordTypesAndViews = async (wid) => {
    return RestClient.handleResponse(() => {
        const finalURL = ClientConfig.URL_RESOURCE_SERVER + ClientConfig.NAMESPACE_RECORD_TYPES + "workspaces/" + wid + "/views";
        const response = fetch(finalURL, {
            method: "GET",
            headers: {
                "Authorization": "Bearer " + StorageService.getAccessToken(),
                "Content-Type": "application/json",
            },
        });

        return response;
    });
}

export {
    findAllRecordTypes,
    findRecordTypeById,
    findRecordTypeTypeById,
    findAllRecordTypesAndViews,
    addRecordType,
    deleteRecordType,
    updateRecordType,
}
