import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import CrossaPageTemplate from "components/CrossaPageTemplate.js";
import MenuCard from "views/workspaceAdmin/menu/component/MenuCard.js";
import { FormattedMessage } from 'react-intl';
import { Card, Form } from "react-bootstrap";
import SweetAlert from 'react-bootstrap-sweetalert';
import * as Validations from "validations/workspaceAdmin/menus/MenuValidations.js";
import {changeOrderHandler, getAllMenusHandler} from "handlers/workspaceAdminHandlers/menuHandlers/AllMenusHandlers";
import { deleteMenuHandler } from "handlers/workspaceAdminHandlers/menuHandlers/AllMenusHandlers";
import SlidingPanel from "../../../components/SlidingPanel";
import AdminNavbar from "../../../components/AdminNavbar";
import NotificationAlert from "react-notification-alert";
import {getNotifyErrorOptions} from "util/Util";
import {DragDropContext, Draggable, Droppable} from "react-beautiful-dnd";
import {SIDEBAR_STYLE, VALIDATIONS} from "../../../util/Constants";

function AllMenusPage(props) {
    const history = useHistory();

    const [state, setState] = useState({
        currentStyle: SIDEBAR_STYLE.CLOSED,
        newMenuName: "",
        menus: [],
        alert: null,
        errors: {
            name: false
        }
    })
    const wid = props.match.params.wid;

    useEffect(() => {
        getPageContent();
    }, []);

    const getPageContent = async () => {
        getAllMenusHandler(wid).then(response => {
            if (response.ok) {
                setState({
                    ...state,
                    ...response.data
                })
            }
        })
    }

    const deleteAlert = (menuId) => {
        setState({
            ...state,
            alert:
                <SweetAlert
                    type="danger"
                    title=""
                    style={{ display: "block", marginTop: "-100px", fontSize: "13px" }}
                    btnSize="md"
                    confirmBtnBsStyle="danger"
                    cancelBtnBsStyle="default"
                    showCancel
                    cancelBtnText={<FormattedMessage id="Button.cancel" />}
                    confirmBtnText={<FormattedMessage id="Button.delete" />}
                    openAnim="false"
                    onConfirm={() => deleteMenu(menuId)}
                    onCancel={() => hideAlert()}
                    allowEscape
                    closeOnClickOutside
                    customClass="crossa-alert"

                > <FormattedMessage id="Alert.deleteMenu" />
                </SweetAlert>
        });
    }

    const canNotDeleteAlert = () => {
        setState({
            ...state,
            alert:
                <SweetAlert
                    type="info"
                    title=""
                    style={{ display: "block", marginTop: "-100px", fontSize: "13px" }}
                    btnSize="md"
                    confirmBtnBsStyle="info"
                    confirmBtnText={<FormattedMessage id="Button.ok" />}
                    openAnim="false"
                    onConfirm={() => hideAlert()}
                    allowEscape
                    closeOnClickOutside
                    customClass="crossa-alert"

                > <FormattedMessage id="Alert.canNotDeleteMenu" />
                </SweetAlert>
        });
    }

    const hideAlert = () => {
        setState({
            ...state,
            alert: null
        });
    }

    const deleteMenu = async menuId => {
        deleteMenuHandler(menuId,wid).then(response => {
            if (response.ok) {
                setState({
                    ...state,
                    ...response.data,
                    alert: null
                });
            }
        });
    }

    const toAddMenuPage = () => {
        const [newErrors, existErrors] = Validations.addMenuValidations(state.newMenuName, { ...state.errors });
        setState({
            ...state,
            errors: newErrors
        })
        if (existErrors) {
            let options=getNotifyErrorOptions("bc");
            notificationAlertRef.current.notificationAlert(options);
        } else {
            history.push({
                pathname: "/workspace-admin/" + props.match.params.wid + "/menus/add",
                state: { newMenuName: state.newMenuName },
            });
        }
    }

    const updateMenu = async menuId => {
        history.push({
            pathname: "/workspace-admin/" + props.match.params.wid + "/menus/" + menuId + "/edit/",
            state: {
                "menuId": menuId,
                "action": "edit",
                "wid": props.match.params.wid
            },
        });
    }

    const handleNameChange = event => {
        let newErrors={...state.errors};

        if (!!state.errors[event.target.name]) {
            newErrors={
                ...newErrors,
                [event.target.name]: false,
            };
        }

        setState({
            ...state,
            newMenuName: event.target.value,
            errors:newErrors
        });
    }


    const handleSubmit = event => {
        event.preventDefault();
        toAddMenuPage();
    }

    const closeSlidingPanel = () => {
        setState({
            ...state,
            currentStyle: SIDEBAR_STYLE.CLOSED
        })
    }

    const openSlidingPanel = () => {
        setState({
            ...state,
            currentStyle: SIDEBAR_STYLE.OPEN
        })
    }

    const onDragMenuEnd=(result)=>{
       let menuId=state.menus[result.source.index].id;
       let newIndex=result.destination?.index;
       if(newIndex) {
           changeOrderHandler(menuId, newIndex).then(response => {
               if (response.ok) {
                   response.data.menus = response.data.menus?.map(menu => state.menus.find(m => m.id === menu.id));
                   setState({
                       ...state,
                       ...response.data
                   });
               }
           });
       }
    }

    const notificationAlertRef = React.useRef(null);

    return (
        <>
            <div className="rna-container">
                <NotificationAlert ref={notificationAlertRef} />
            </div>
            <AdminNavbar
                title={<FormattedMessage id="Menus" />}
                hasRightBtn={true}
                rightBtnHasIcon={true}
                rightBtnName=<FormattedMessage id="Button.newMenu" />
                rightButtonAction={openSlidingPanel}
            />
            <CrossaPageTemplate>
                {
                    state.menus.length ?
                        <DragDropContext onDragEnd={onDragMenuEnd}>
                            <Droppable droppableId={"droppable"}>
                                {(provided)=>(
                                    <div
                                        {...provided.droppableProps}
                                        ref={provided.innerRef}
                                    >{state.menus.map((menu, index) =>{
                                        return (
                                        <Draggable
                                        key={index}
                                        draggableId={"drag"+index}
                                        index={index} >
                                    {(provided, snapshot) => (
                                        <div className={snapshot.isDragging?"dragging":""} key={index} ref={provided.innerRef}
                                             {...provided.draggableProps}
                                             {...provided.dragHandleProps}>
                                        <MenuCard
                                            key={menu + index}
                                            menu={menu}
                                            deleteMenu={deleteAlert}
                                            canNotDeleteMenu={canNotDeleteAlert}
                                            updateMenu={updateMenu}
                                            wid={props.match.params.wid}
                                        /></div>)}
                                        </Draggable>
                                        )
                                    })}{provided.placeholder}
                                    </div>
                                    )}
                            </Droppable>
                        </DragDropContext>
                        :
                        <Card>
                            <Card.Body>
                                <h4 className="text-center"><FormattedMessage id="Validation.noInformation" /></h4>
                            </Card.Body>
                        </Card>
                }

                <SlidingPanel
                    title={<FormattedMessage id="MenuName"/>}
                    currentStyle={state.currentStyle}
                    closeSlidingPanel={closeSlidingPanel}
                    content={
                        <div className="menu-name-form">
                            <CrossaPageTemplate
                                hasFooter={true}
                                closeSlidingPanel={props.closeSlidingPanel}
                                saveChanges={toAddMenuPage}
                                saveChangesBtnText={<FormattedMessage id="Button.next" />}
                            >
                                <Form onSubmit={handleSubmit}>
                                    <Form.Group>
                                        <Form.Label><FormattedMessage id="Name"/></Form.Label>
                                        <FormattedMessage id="MenuName">
                                            {placeholder =>
                                                <Form.Control
                                                    type="text"
                                                    name="name"
                                                    placeholder={placeholder}
                                                    onChange={handleNameChange}
                                                    isInvalid={state.errors.name}
                                                    className={state.errors.name && "has-errors"}
                                                />
                                            }
                                        </FormattedMessage>
                                        <Form.Control.Feedback type="invalid">
                                            <FormattedMessage id={"Validation.cannotBeEmptyAndParamMinParamMax"} values={{paramMin:VALIDATIONS.MIN_LENGTH_DEFAULT, paramMax:VALIDATIONS.MAX_LENGTH_DEFAULT}}/>
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                </Form>
                            </CrossaPageTemplate>
                        </div>
                    }
                ></SlidingPanel>
                {state.alert}
            </CrossaPageTemplate>
        </>
    );
}

export default AllMenusPage;
