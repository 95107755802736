import * as ClientConfig from "config/ClientConfig.js";
import * as StorageService from "service/StorageService.js"
import * as RestClient from "util/api/restClient"

const addView = async view => {
    return RestClient.handleResponse(() => {
        const finalURL = ClientConfig.URL_RESOURCE_SERVER + ClientConfig.NAMESPACE_VIEW;
        const response = fetch(finalURL, {
            method: "POST",
            body: JSON.stringify(view),
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + StorageService.getAccessToken()
            }
        });

        return response;
    });
}

const findAllViews = async (wid) => {
    return RestClient.handleResponse(() => {
        const finalURL = ClientConfig.URL_RESOURCE_SERVER + ClientConfig.NAMESPACE_VIEW + "workspaces/" + wid;
        const response = fetch(finalURL, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + StorageService.getAccessToken()
            }
        });

        return response;
    });
}

const findViewById = async (viewId) => {
    return RestClient.handleResponse(() => {
        const finalURL = ClientConfig.URL_RESOURCE_SERVER + ClientConfig.NAMESPACE_VIEW + viewId;
        const response = fetch(finalURL, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + StorageService.getAccessToken()
            }
        });

        return response;
    });
}

const updateView = async view => {
    return RestClient.handleResponse(() => {
        const id = view.id;
        const finalURL = ClientConfig.URL_RESOURCE_SERVER + ClientConfig.NAMESPACE_VIEW + id;
        const response = fetch(finalURL, {
            method: "PUT",
            body: JSON.stringify(view),
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + StorageService.getAccessToken()
            }
        });

        return response;
    });
}

const findAllInfo = async (wid) => {
    return RestClient.handleResponse(() => {
        const finalURL = ClientConfig.URL_RESOURCE_SERVER + ClientConfig.NAMESPACE_VIEW + "workspaces/" + wid;
        const response = fetch(finalURL, {
            method: "GET",
            headers: {
                "Authorization": "Bearer " + StorageService.getAccessToken()
            }
        });

        return response;
    });
}

const deleteView = async (id) => {
    return RestClient.handleResponse(() => {
        const finalURL = ClientConfig.URL_RESOURCE_SERVER + ClientConfig.NAMESPACE_VIEW + id;
        const response = fetch(finalURL, {
            method: "DELETE",
            headers: {
                "Authorization": "Bearer " + StorageService.getAccessToken()
            }
        });

        return response;
    });
}

export {
    addView,
    findAllViews,
    findAllInfo,
    findViewById,
    updateView,
    deleteView
}
