import * as ClientConfig from "config/ClientConfig.js";
import * as StorageService from "service/StorageService.js"
import * as RestClient from "util/api/restClient"

const findAllRecordsInView = (viewId,sort,size) => {
    return RestClient.handleResponse(() => {
        let finalURL = ClientConfig.URL_RESOURCE_SERVER + ClientConfig.NAMESPACE_RECORDS + "recordviews/" + viewId;
        if(size){
            finalURL += "?size="+size;
        }
        if(sort){
            finalURL += "&sort="+sort;
        }
        const response = fetch(finalURL, {
            method: "GET",
            headers: {
                "Authorization": "Bearer " + StorageService.getAccessToken()
            }
        });

        return response;
    });
}

const findAllRecordsInTagsViews = workspaceId => {
    return RestClient.handleResponse(() => {
        const finalURL = ClientConfig.URL_RESOURCE_SERVER + ClientConfig.NAMESPACE_RECORDS + "workspaces/" + workspaceId + "/tags";
        const response = fetch(finalURL, {
            method: "GET",
            headers: {
                "Authorization": "Bearer " + StorageService.getAccessToken()
            }
        });

        return response;
    });
}

const insertRecordByViewId = (record, viewId) => {
    return RestClient.handleResponse(() => {
        const finalURL = ClientConfig.URL_RESOURCE_SERVER + ClientConfig.NAMESPACE_RECORDS + "recordviews/" + viewId;
        const response = fetch(finalURL, {
            method: "POST",
            body: JSON.stringify(record),
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + StorageService.getAccessToken()
            }
        });
        return response;
    });
}

const updateRecordByViewId = (record, viewId) => {
    return RestClient.handleResponse(() => {
        const recordId = record.id;
        delete record.id;
        const finalURL = ClientConfig.URL_RESOURCE_SERVER + ClientConfig.NAMESPACE_RECORDS + recordId + "/recordviews/" + viewId;
        const response = fetch(finalURL, {
            method: "PUT",
            body: JSON.stringify(record),
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + StorageService.getAccessToken()
            }
        });
        return response;
    });
}

const updateRecordFiles = (recordId, viewId, files, fieldsIds) => {
    return RestClient.handleResponse(() => {
        const finalURL = ClientConfig.URL_RESOURCE_SERVER + ClientConfig.NAMESPACE_RECORDS + recordId + "/recordviews/" + viewId + "/recordfiles";
        let formData = new FormData();
        files.forEach(file => {
            formData.append("files", file);
        })
        formData.append("fieldsIds", JSON.stringify({fields: fieldsIds}));
        const response = fetch(finalURL, {
            method: "POST",
            body: formData,
            headers: {
                "Authorization": "Bearer " + StorageService.getAccessToken()
            }
        });
        return response;
    });
}

const getImage = (viewId, fileName) => {
    return RestClient.handleAuthFileResponse(() => {
        const finalURL = ClientConfig.URL_RESOURCE_SERVER + ClientConfig.NAMESPACE_RECORDS + "recordviews/" + viewId + "/image/" + fileName;
        const response = fetch(finalURL, {
            method: "GET",
            headers: {
                "Authorization": "Bearer " + StorageService.getAccessToken()
            }
        });
        return response;
    });
}

const findAllRecordsInViewWithNameContains = (nameContains, viewId, mid, pageIndex, tabIndex) => {
    return RestClient.handleResponse(() => {
        let finalURL;
        if(nameContains) {
            finalURL = ClientConfig.URL_RESOURCE_SERVER + ClientConfig.NAMESPACE_RECORDS + "recordviews/" + viewId + "/menu/" + mid +
                "/page/" + pageIndex + "/tab/" + tabIndex + "/references" + "?nameContains=" + nameContains;
        } else {
            finalURL = ClientConfig.URL_RESOURCE_SERVER + ClientConfig.NAMESPACE_RECORDS + "recordviews/" + viewId + "/menu/" + mid +
                "/page/" + pageIndex + "/tab/" + tabIndex + "/references";
        }
        const response = fetch(finalURL, {
            method: "GET",
            headers: {
                "Authorization": "Bearer " + StorageService.getAccessToken()
            }
        });

        return response;
    });
}

const findAllRecordsInRecordViewWithNameContains = (nameContains, viewId) => {
    return RestClient.handleResponse(() => {
        let finalURL;
        if(nameContains) {
            finalURL = ClientConfig.URL_RESOURCE_SERVER + ClientConfig.NAMESPACE_RECORDS + "recordviews/" + viewId + "/references" + "?nameContains=" + nameContains;
        } else {
            finalURL = ClientConfig.URL_RESOURCE_SERVER + ClientConfig.NAMESPACE_RECORDS + "recordviews/" + viewId + "/references";
        }
        const response = fetch(finalURL, {
            method: "GET",
            headers: {
                "Authorization": "Bearer " + StorageService.getAccessToken()
            }
        });

        return response;
    });
}

const findAllRecordsInRecordTypeWithNameContains = (nameContains, recordTypeId) => {
    return RestClient.handleResponse(() => {
        const finalURL = ClientConfig.URL_RESOURCE_SERVER + ClientConfig.NAMESPACE_RECORDS + "recordtypes/" + recordTypeId + "/references" + "?nameContains=" + nameContains;
        const response = fetch(finalURL, {
            method: "GET",
            headers: {
                "Authorization": "Bearer " + StorageService.getAccessToken()
            }
        });

        return response;
    });
}

const deleteRecordByViewId = (recordId, viewId) => {
    return RestClient.handleResponse(() => {
        const finalURL = ClientConfig.URL_RESOURCE_SERVER + ClientConfig.NAMESPACE_RECORDS + recordId + "/recordviews/" + viewId;
        const response = fetch(finalURL, {
            method: "DELETE",
            headers: {
                "Authorization": "Bearer " + StorageService.getAccessToken()
            }
        });
        return response;
    });
}

const findRecordInRecordType= (recordId, recordTypeId) => {
    return RestClient.handleResponse(() => {
        const finalURL = ClientConfig.URL_RESOURCE_SERVER + ClientConfig.NAMESPACE_RECORDS + recordId + "/recordtypes/" + recordTypeId;
        const response = fetch(finalURL, {
            method: "GET",
            headers: {
                "Authorization": "Bearer " + StorageService.getAccessToken()
            }
        });

        return response;
    });
}

const existRecordsByNamespace= (namespace) => {
    return RestClient.handleResponse(() => {
        const finalURL = ClientConfig.URL_RESOURCE_SERVER + ClientConfig.NAMESPACE_RECORDS + namespace + "/exist";
        const response = fetch(finalURL, {
            method: "GET",
            headers: {
                "Authorization": "Bearer " + StorageService.getAccessToken()
            }
        });

        return response;
    });
}

const findAllRecordsFilteredInView=(vid,mid,pageIndex,tabIndex,request)=>{
    return RestClient.handleResponse(() => {
        const finalURL = ClientConfig.URL_RESOURCE_SERVER + ClientConfig.NAMESPACE_RECORDS +"recordviews/"+ vid + "/menu/"+mid+"/page/"+pageIndex+"/tab/"+tabIndex;
        const response = fetch(finalURL, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + StorageService.getAccessToken()
            },
            body:JSON.stringify(request)
        });
        return response;
    });
}

const findAllRegistrationsInView=(vid,request)=>{
    return RestClient.handleResponse(() => {
        const finalURL = ClientConfig.URL_RESOURCE_SERVER + ClientConfig.NAMESPACE_RECORDS +"recordviews/"+ vid + "/registrations";
        const response = fetch(finalURL, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + StorageService.getAccessToken()
            },
            body:JSON.stringify(request)
        });
        return response;
    });
}


const exportsAllRecordsFilteredInView=(vid,mid,pageIndex,tabIndex,request)=>{
    return RestClient.handleResponseFile(() => {
        const finalURL = ClientConfig.URL_RESOURCE_SERVER + ClientConfig.NAMESPACE_RECORDS +"recordviews/"+ vid + "/menu/" +
        mid + "/page/" + pageIndex + "/tab/" + tabIndex + "/export";

        const response = fetch(finalURL, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + StorageService.getAccessToken()
            },
            body:JSON.stringify(request)
        });
        return response;
    });
}

const exportAllRegistrationsInView = (vid,request) => {
    return RestClient.handleResponseFile(() => {
        const finalURL = ClientConfig.URL_RESOURCE_SERVER + ClientConfig.NAMESPACE_RECORDS +"recordviews/"+ vid + "/registrations/export";

        const response = fetch(finalURL, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + StorageService.getAccessToken()
            },
            body:JSON.stringify(request)
        });
        return response;
    });
}
export {
    findAllRecordsInView,
    findAllRecordsInTagsViews,
    findAllRecordsInRecordTypeWithNameContains,
    findAllRecordsInViewWithNameContains,
    insertRecordByViewId,
    updateRecordByViewId,
    updateRecordFiles,
    getImage,
    deleteRecordByViewId,
    findRecordInRecordType,
    existRecordsByNamespace,
    findAllRecordsFilteredInView,
    findAllRegistrationsInView,
    findAllRecordsInRecordViewWithNameContains,
    exportsAllRecordsFilteredInView,
    exportAllRegistrationsInView
}
