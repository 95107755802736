import * as RecordTypeService from "../../../service/RecordTypeService";
import * as RecordService from "service/RecordService";
import * as WorkspaceService from "service/WorkspaceService";

const getRecordTypeByIdHandler= async recordTypeId => {
    let recordType={};
    let recordFields=[];

    const response = await RecordTypeService.findRecordTypeById(recordTypeId);
    if (response.ok) {
        recordType={
            "id": recordTypeId,
            "name": response.data.name,
            "rules": response.data.rules || [],
        };
        recordFields=response.data.fields;
    }

    return{
        data:{
            recordType:recordType,
            recordFields:recordFields
        },
        ok:response.ok,
    }
}

const updateRecordTypeHandler=async(newRecordType)=>{
    const response = await RecordTypeService.updateRecordType(newRecordType);

    return {
        ok:response.ok
    }

}

const getAllRecordsWithNameContainsHandler=async(nameContains, recordTypeId)=>{
    let records=[];
    const response = await RecordService.findAllRecordsInRecordTypeWithNameContains(nameContains, recordTypeId);
    if (response.ok) {
        records=response.data.map(element => {
            return {
                "label": element.name,
                "value": element.id,
            }
        })
    }
    return{
        data:{
            records:records
        },
        ok:response.ok
    }
}

const findRecordByIdHandler=async(recordId,rtid)=>{
    let record={};
    const response = await RecordService.findRecordInRecordType(recordId, rtid);
    if (response.ok) {
       record=response.data;
    }

    return{
        data:{
            name:record.name,
        },
        ok:response.ok
    }
}
const getRecordTypeAndRecordViewByWorkspaceIdHandler = async (wid) => {
    let recordTypeMemberFields=[];
    const response = await WorkspaceService.findMemberRecordTypeRecordView(wid);
    if (response.ok) {
        recordTypeMemberFields=response.data.recordType.fields.map(field => {
            return {
                "value": field.id,
                "label": field.name,
                "type": field.type,
                "rtid": field.rtid,
            }
        });
    }

    return{
        data:{
            recordTypeMemberFields:recordTypeMemberFields,
        },
        ok:response.ok
    }
}
export {
    getRecordTypeByIdHandler,
    updateRecordTypeHandler,
    getAllRecordsWithNameContainsHandler,
    findRecordByIdHandler,
    getRecordTypeAndRecordViewByWorkspaceIdHandler
}
