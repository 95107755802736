import {FormattedMessage} from "react-intl";
import RecordsPageTable from "./RecordsPageTable";
import React, {useEffect, useState} from "react";
import {RECORD_FIELD_TEXT_DISPLAY_OPTION, RECORD_FIELD_TYPE,} from "../../../../util/Constants";
import {
    getAllRecordsInViewHandler
} from "../../../../handlers/workspaceMemberHandlers/menuDisplayHandlers/DisplayMenuDashboardHandlers";
import Moment from "react-moment";
import {DATE_FORMAT} from "../../../../util/DateUtil";


function TableWidget(props){


    const [state,setState]=useState({
       columns:[],
       inProgress: true
    });

    useEffect(async ()=>{
        const response = await getAllRecordsInViewHandler(props.widget?.vid,props.widget?.defaultSorting?[props.widget?.sortingField+","+props.widget?.sortDirection]:null, props.widget?.rows);
        if(response.ok){
            const columns = prepareColumns(response.data.recordTypeFields)
            const records = prepareRecords(response.data.records,response.data.fieldsTypeById,response.data.recordTypeFields);
            setState({
                ...state,
                ...response.data,
                columns:columns,
                record:records,
                inProgress: false,
                error: null
            });
        }else{
            setState({
                ...state,
                error: response.data.error,
                inProgress: false
            });
        }
    },[props.widget])

    const prepareColumns=(recordTypeFields)=>{
        return recordTypeFields.filter(recordTypeField => props.widget?.displayFields.includes(recordTypeField.id) ||
            props.widget?.displayFields.includes(recordTypeField.name)).map(recordTypeField => {
            return {
                Header: () => (
                    <div>{recordTypeField.type === RECORD_FIELD_TYPE.PAYMENT ?
                        <FormattedMessage id="PaymentStatus"/> : recordTypeField.name}</div>),
                accessor: recordTypeField.id,
                sorted: props.widget?.defaultSorting && props.widget?.sortingField === recordTypeField.id,
                sortedDesc: props.widget?.defaultSorting && props.widget?.sortingField === recordTypeField.id ? props.widget?.sortDirection === "DESC" : null
            }
        });
    }
    const prepareRecords=(records,fieldsTypeById,recordTypeFields)=>{
        return records.values?.map(record => {
            if (Object.keys(fieldsTypeById).length === 0) {
                return {};
            }

            let newObject = {}
            for (let property in record) {
                if ((records[property] === RECORD_FIELD_TYPE.RECORD_LIST || records[property] === RECORD_FIELD_TYPE.ROLES_LIST) && record[property]) {
                    let nameListString = "";
                    const len = record[property].length;
                    record[property].forEach((recordRef, index) => {
                        nameListString += recordRef.name + (index !== len - 1 ? ", " : "");
                    })
                    newObject = {
                        ...newObject,
                        [property]: nameListString,
                    }
                }
                else if (records[property] === RECORD_FIELD_TYPE.MULTIPLE_CHOICE && record[property]) {
                    let nameListString = "";
                    const len = record[property].length;
                    record[property].forEach((optionSelected, index) => {
                        nameListString += optionSelected + (index !== len - 1 ? ", " : "");
                    })
                    newObject = {
                        ...newObject,
                        [property]: nameListString,
                    }
                } else if (records[property] === RECORD_FIELD_TYPE.FILE_LIST && record[property]) {
                    let nameListString = "";
                    const len = record[property].length;
                    record[property].forEach((fileSelected, index) => {
                        nameListString += fileSelected.originalFileName + (index !== len - 1 ? ", " : "");
                    })
                    newObject = {
                        ...newObject,
                        [property]: nameListString,
                    }
                } else if (records[property] === RECORD_FIELD_TYPE.FILE && record[property]) {
                    newObject = {
                        ...newObject,
                        [property]:record[property].originalFileName ,
                    }
                } else if (records[property] === RECORD_FIELD_TYPE.RECORD && record[property]) {
                    newObject = {
                        ...newObject,
                        [property]: record[property].name
                    }
                } else if (records[property] === RECORD_FIELD_TYPE.DATE) {
                    let dateField = recordTypeFields.find(field => field.id === property)
                    newObject = {
                        ...newObject,
                        [property]: record[property]?<Moment
                            format={dateField?.timeField ? DATE_FORMAT.DEFAULT : DATE_FORMAT.DAY_MONTH_YEAR}
                            date={record[property]}
                        />:""
                    }
                } else if (records[property] === RECORD_FIELD_TYPE.BOOLEAN) {
                    newObject = {
                        ...newObject,
                        [property]: record[property].toString()
                    }
                } else if (records[property] === RECORD_FIELD_TYPE.TEXT) {
                    if (recordTypeFields?.find(field => field.id === property && field.displayOption === RECORD_FIELD_TEXT_DISPLAY_OPTION.HYPER_LINK)) {
                        newObject = {
                            ...newObject,
                            [property]: <a href={record[property]} target="_blank">{record[property]}</a>
                        }
                    } else {
                        newObject = {
                            ...newObject,
                            [property]: record[property]
                        }
                    }
                } else {
                    newObject = {
                        ...newObject,
                        [property]: record[property]
                    }
                }
            }
            return {
                ...newObject,
                "name": record.name,
            }
        });
    }

    return !state.error && !state.inProgress && state.record?(
        <div className="widget table-widget">
            <p className="title">{props.widget.title}</p>
            <p className="description">{props.widget.description}</p>
            <RecordsPageTable
                columns={state.columns}
                data={state.record}
                fieldsTypeById={state.fieldsTypeById}
                fieldsOptionsColorsById={state.fieldsOptionsColorsById}
                recordTypeFields={state.recordTypeFields}
                vid={props.widget.vid}
            />
        </div>
    ):(<div></div>)

}

export default TableWidget;
