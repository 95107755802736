const URL_RESOURCE_SERVER = window.globalConfig.URL_RESOURCE_SERVER;
const URL_LOGIN = window.globalConfig.URL_LOGIN;
const URL_GET_TOKEN = window.globalConfig.URL_GET_TOKEN;

const NAMESPACE_ACTIVITY = "api/activities/";
const NAMESPACE_USERS = "api/users/";
const NAMESPACE_CATEGORY = "api/categories/";
const NAMESPACE_ADMIN = "api/users/super/";
const NAMESPACE_RECORD_TYPES = "api/recordtypes/";
const NAMESPACE_RECORD_TYPES_WORKSPACES = "api/recordtypes/workspaces/";
const NAMESPACE_RECORDS = "api/records/";
const NAMESPACE_ROLE = "api/roles/";
const NAMESPACE_WORKSPACE_ROLE = "api/roles/workspaces/";
const NAMESPACE_VIEW = "api/recordviews/";
const NAMESPACE_WORKSPACE = "api/workspaces/";
const NAMESPACE_PAYMENT= "api/payment/";
const NAMESPACE_MENU = "api/menus/";
const NAMESPACE_MEMBERS = "api/members/";
const NAMESPACE_WORKSPACE_MEMBERS = "api/members/workspaces/";
const NAMESPACE_FORMS = "api/forms/";
const NAMESPACE_WORKSPACE_FORMS = "api/forms/workspaces/";
const NAMESPACE_MESSAGES = "api/messages/";
const CLIENT_ID = window.globalConfig.CLIENT_ID;
const CLIENT_SECRET = window.globalConfig.CLIENT_SECRET;

export {
    CLIENT_ID,
    CLIENT_SECRET,
    URL_GET_TOKEN,
    URL_LOGIN,
    URL_RESOURCE_SERVER,
    NAMESPACE_ACTIVITY,
    NAMESPACE_USERS,
    NAMESPACE_CATEGORY,
    NAMESPACE_ADMIN,
    NAMESPACE_RECORD_TYPES,
    NAMESPACE_RECORD_TYPES_WORKSPACES,
    NAMESPACE_RECORDS,
    NAMESPACE_WORKSPACE_ROLE,
    NAMESPACE_WORKSPACE,
    NAMESPACE_PAYMENT,
    NAMESPACE_VIEW,
    NAMESPACE_MENU,
    NAMESPACE_ROLE,
    NAMESPACE_MEMBERS,
    NAMESPACE_WORKSPACE_MEMBERS,
    NAMESPACE_FORMS,
    NAMESPACE_WORKSPACE_FORMS,
    NAMESPACE_MESSAGES
}