import {EventBus} from "../../../../util/EventBus";
import {useEffect, useState} from "react";
import * as StorageService from "service/StorageService"
import {Card, Col, Row} from "react-bootstrap";
import {FormattedMessage} from "react-intl";
import welcomeImg from "../../../../assets/img/home-avatar.svg";

function WelcomeWidget(props) {

    const [state,setState]=useState({
        memberName:StorageService.getMemberName()
    });

    useEffect(() => {
        EventBus.on("sidebarLoaded", (data) => {
            setState({
                ...state,
                memberName:data.name
            });
        });
    }, []);

    return (
        <Row className="widget welcome-widget">
            <Card className="welcome-card">
                <Card.Body className="row">
                    <Col className="text-col">
                        <h2>{props.widget.title.replaceAll("Member_name",state.memberName).replaceAll("Workspace_name",StorageService.getItem("workspace_name"))||""}</h2>
                        <p>{props.widget.description||""}</p>
                    </Col>
                    <Col>
                        <img src={welcomeImg} alt={"."}/>
                    </Col>
                </Card.Body>
            </Card>
        </Row>
    )

}

export default WelcomeWidget;