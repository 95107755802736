import React, {useState, useEffect} from 'react';
import {Card, Form, Row, Table} from "react-bootstrap";

import {useTable} from 'react-table';
import {FormattedMessage} from "react-intl";
import {RECORD_FIELD_TYPE} from "../../../../util/Constants";

const DisplayFieldsTable = (props) => {
    const columns = props.columns;
    const [data, setData] = useState(props.data);

    useEffect(() => {
        setData(props.data);
    }, [props.data]);

    const {
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow
    } = useTable({
        columns,
        data
    })

    const addDisplayField = (displayFieldId, displayFieldType) => {
        if (displayFieldType===RECORD_FIELD_TYPE.GROUP_HEADER) {
            return;
        }
        props.changeDisplayFields(displayFieldId);
    }

    return (
<>
    <Row>
        <Card.Header>
            <Card.Title as="h3"><FormattedMessage id="FieldsDisplayed"/></Card.Title>
        </Card.Header>

    </Row>
    {props.error && <div className={"error-message"}>
        <FormattedMessage id={"Validation.selectAtLeastOneField"}/>
    </div> }
        <div className="table-container no-border">

            <Table className="table-hover">
                <thead>
                {headerGroups.map(headerGroup => (
                    <tr {...headerGroup.getHeaderGroupProps()}>
                        {headerGroup.headers.map(column => (
                            <th {...column.getHeaderProps()}>{column.render('Header')}</th>
                        ))}
                    </tr>
                ))}
                </thead>
                <tbody {...getTableBodyProps()}>

                {rows.map(row => {
                    prepareRow(row)
                    const isSelected = props.displayFields.includes(row.values.name.id) || props.displayFields.includes(row.values.name.name);
                    return (
                        <tr {...row.getRowProps()}
                        onClick={(e) => {
                            e.preventDefault();
                            addDisplayField(props.displayFields.includes(row.values.name.name) ?
                                row.values.name.name : row.values.name.id, row.values.type.name)
                        }}>
                            {row.cells.map((cell, index) => {
                                if (cell.column.id === "display") {
                                    return <td key={index}>
                                        <Form.Check
                                            style={{visibility: cell.row.values.type.name === RECORD_FIELD_TYPE.GROUP_HEADER ? "hidden" : ""}}
                                        >
                                            <Form.Check.Label>
                                                <Form.Check.Input
                                                    checked={cell.row.values.type.name === RECORD_FIELD_TYPE.GROUP_HEADER ? true : isSelected}
                                                    defaultValue=""
                                                    type="checkbox"
                                                    onChange={() => {
                                                    }}
                                                ></Form.Check.Input>
                                                <span className="form-check-sign"></span>
                                            </Form.Check.Label>
                                        </Form.Check>
                                    </td>
                                }else if(cell.column.id === "type"){
                                    return <td key={index}>
                                        <FormattedMessage id={"FieldType."+cell.value.name?.charAt(0).toLowerCase() + cell.value.name?.slice(1)} />
                                    </td>
                                } else {
                                    return <td {...cell.getCellProps()} key={index}>{cell.value.name}</td>
                                }
                            })}
                        </tr>
                    )
                })}
                </tbody>
            </Table>
        </div>
</>
    )
}
export default DisplayFieldsTable;
