import React from "react";
import ueLogo from '../assets/img/ue_logo.png';

const UeLogos = () => {

    return (
        <div className="ue-logos">
            <div className="external-link">
                <p>
                    Proiect cofinanţat din FC/FEDR/FSE prin Programul Operational
                    Competitivitate. Conţinutul acestui material nu reprezintă în mod
                    obligatoriu poziţia oficială a
                    Uniunii Europene sau a Guvernului României

                </p>
            </div>
            <img src={ueLogo} alt="european union logo"/>
                <div className="external-link">
                    <a href="http://www.fonduri-ue.ro/" target="_blank" rel="noopener">
                        Pentru informaţii detaliate despre celelalte programe cofinanțate de
                        Uniunea Europeană, vă invităm să vizitaţi www.fonduri-ue.ro
                    </a>
                </div>
        </div>
    )
}

export default UeLogos;
