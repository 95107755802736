import * as WorkspaceService from "service/WorkspaceService";
import * as FormService from "service/FormService";
import currencies from "currency-codes";

const getDataForFormBuilderHandler = async (wid, formId, leftAndCenterColFields) => {

    let currency = {};
    let merchantId = null;
    let secretApiKey = null;
    let formSettings = {};
    let newLeftAndCenterColFields = leftAndCenterColFields;
    let isSettingsPageVisible = false;
    let isEditorVisible = true;
    let ok = true;

    const responsePayment = await WorkspaceService.getFormPaymentSetup(wid);
    ok = ok && responsePayment.ok;
    if (responsePayment.ok) {
        if (responsePayment.data?.currency) {
            currency = currencies.code(responsePayment.data.currency);
            merchantId = responsePayment.data?.merchantId;
            secretApiKey = responsePayment.data?.secretApiKey;
        }
    }
    if (formId) {
        const responseFindForm = await FormService.findFormWithRecordType(wid, formId);
        ok = ok && responseFindForm.ok;
        if (responseFindForm.ok) {
            let milis = Date.now();
            newLeftAndCenterColFields[1].formFields = responseFindForm.data.recordType.fields
                .filter(field => field.id !== "createdBy" && field.id !== "createdDate")
                .map((field, index) => {
                    return {
                        ...field,
                        draggableId: ("dest" + milis) + index,
                    }
                });
            formSettings = responseFindForm.data.form;
            return {
                data: {
                    currency: currency,
                    merchantId: merchantId,
                    secretApiKey: secretApiKey,
                    formSettings: formSettings,
                    initFormSettings:formSettings,
                    initFormFields:newLeftAndCenterColFields[1].formFields,
                    leftAndCenterColFields: newLeftAndCenterColFields,
                    isEditorVisible: isEditorVisible,
                    isSettingsPageVisible: isSettingsPageVisible
                },
                ok: ok
            };
        }
    } else {
        isEditorVisible = false;
        isSettingsPageVisible = true;
        return {
            data: {
                currency: currency,
                merchantId: merchantId,
                secretApiKey: secretApiKey,
                leftAndCenterColFields: newLeftAndCenterColFields,
                isEditorVisible: isEditorVisible,
                isSettingsPageVisible: isSettingsPageVisible
            },
            ok: ok
        };
    }
}

export {
    getDataForFormBuilderHandler,
};
