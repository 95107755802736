import React, {useMemo} from 'react';
import {Button, Form, OverlayTrigger, Tooltip} from "react-bootstrap";
import {useTable, useSortBy} from 'react-table';
import { useSticky } from "react-table-sticky";
import * as ClientConfig from "../../../../config/ClientConfig";
import {FormattedMessage} from "react-intl";
import {editIcon, sortDownIcon, sortUpIcon, tagIcon, trashIcon} from "../../../../components/Icons";
import {INDETERMINATE_CHECKBOX, PAYMENT_STATUS, RECORD_FIELD_TYPE, RECORD_TYPE_TYPE} from "../../../../util/Constants";
import IndeterminateCheckbox from "../../../../components/IndeterminateCheckbox";
const RecordsPageTable = (props) => {


    //TODO: 1. ADD PAGINATION

    const columns = useMemo(() => props.columns ? props.columns : [], [props]);
    const data = useMemo(() => props.data ? props.data : [], [props]);
    const fieldsTypeById = useMemo(() => props.fieldsTypeById, [props])
    const vid = useMemo(() => props.vid, [props])

    const {
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow,
    } = useTable({
        columns,
        data,
        manualSorting: true,
    },useSortBy,
        useSticky)

    const columnHeaderClick = async (column) => {
        if(props.onChangeSort) {
            if (column.sorted) {
                if (column.sortedDesc) {
                    props.onChangeSort([]);
                } else {
                    props.onChangeSort([{id: column.id, desc: true}]);
                }
            } else {
                props.onChangeSort([{id: column.id, desc: false}]);
            }
        }
    };

    const openEditFormOrSidebar = (event, id) => {
        if (props.rtType === RECORD_TYPE_TYPE.FORM) {
            props.editForm(event, id);
        }else{
            props.handleClickOnName(event,id);
        }
    }

    const openDetailsSidebarOrFormPreview=(event,id)=>{
        event.preventDefault();
        if (props.rtType === RECORD_TYPE_TYPE.FORM) {
            props.handleShowFormPreview(event, id);
        }else{
            props.openDetailsSidebar(event, id)
        }
    }

    const renderCell = (cell, row, indexRow) => {
        if (cell.column.id === "name") {
            return cell.value
        } else if (cell.column.id === "description") {
            return <p className="description-text">
                {cell.render('Cell')}
            </p>
        } else if (fieldsTypeById && fieldsTypeById[cell.column.id] === RECORD_FIELD_TYPE.PAYMENT) {
            return cell.value? cell.value.paymentStatus===PAYMENT_STATUS.COMPLETE?
                <span className="payment-status-success"><p><FormattedMessage id="PaymentStatus.paid"/></p></span>
                :
                <span className="payment-status-danger"><p><FormattedMessage id="PaymentStatus.notPaid"/></p></span>:""
        } else if (fieldsTypeById && fieldsTypeById[cell.column.id] === RECORD_FIELD_TYPE.IMAGE) {
            return cell.value && <div className="img-container">
                <img
                    src={ClientConfig.URL_RESOURCE_SERVER + ClientConfig.NAMESPACE_RECORDS + "images/recordviews/" + vid + "/image/" + cell.value} alt={"."}/>
            </div>
        } else if (data[indexRow].invalidFields?.includes(cell.column.id)) {
            return <p>
                <OverlayTrigger
                    onClick={(e) => e.preventDefault()}
                    overlay={
                        <Tooltip>
                            <FormattedMessage id="Validation.invalidData"/>
                        </Tooltip>
                    }
                >
                    <i className='fa fa-info-circle text-danger mt-1'/>
                </OverlayTrigger>{cell.render('Cell')}
            </p>
        } else if (fieldsTypeById && fieldsTypeById[cell.column.id] === RECORD_FIELD_TYPE.SINGLE_CHOICE && props.fieldsOptionsColorsById[cell.column.id]) {
            return !!!props.fieldsOptionsColorsById[cell.column.id][cell.value] ? <p>{cell.render('Cell')}</p> :
                <span className="status"
                      style={{background: props.fieldsOptionsColorsById[cell.column.id][cell.value]}}>
                    <p> {cell.render('Cell')}</p>
                        </span>
        } else {
            return <p>{cell.render('Cell')}</p>
        }
    }
    return (

        <div className="table-responsive">
            <div className="table table-fixed  table-bigboy sticky" >
                        <div className="header">
                            {headerGroups.map(headerGroup => (
                                <div className="tr"
                                     {...headerGroup.getHeaderGroupProps()}>
                                    {headerGroup.headers.map(column => (
                                        column.id==="selected"?
                                            <div className="th checkbox-row" key="checkbox-row">
                                                <div className={"d-inline-flex"}>
                                                    <IndeterminateCheckbox
                                                        value={props.selectedRecords?.length ?
                                                            (props.selectedRecords.length === data.filter(record=>props.rtType===RECORD_TYPE_TYPE.FORM_DATA?record.createdBy:true)?.length
                                                                ?
                                                                INDETERMINATE_CHECKBOX.CHECKED
                                                                :
                                                                INDETERMINATE_CHECKBOX.INDETERMINATE)
                                                            :
                                                            INDETERMINATE_CHECKBOX.UNCHECKED}
                                                        onClick={props.toggleAllSelectedRecords}
                                                    />
                                                </div>
                                            </div>
                                            :
                                        <div className="th"
                                             {...column.getHeaderProps(column.getSortByToggleProps())} onClick={()=>columnHeaderClick(column)}>
                                            <div className={"d-inline-flex"}>
                                                {column.render('Header')}
                                                <span>
                                        {
                                            column.sorted ?
                                                column.sortedDesc ?
                                                    <span className="ml-2">{sortDownIcon}</span>
                                                    : <span className="ml-2">{sortUpIcon}</span>
                                                : ''
                                        }
                                        </span>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            ))}
                        </div>
                        <div className="body" {...getTableBodyProps()}>
                            {
                                rows.map((row, indexRow) => {
                                    prepareRow(row)
                                    return (
                                        <div className={props.selectedRecords?.find(id=>id===row.original.id) ? "tr active" : "tr"}  id={row.original.id} {...row.getRowProps()}>
                                            {row.cells.map((cell, index) => {
                                                if (cell.column.id === "selected") {
                                                    return props.rtType!==RECORD_TYPE_TYPE.FORM_DATA || row.original.createdBy?<div className="td checkbox-row" key={index}>
                                                                <div className="checkbox-container">
                                                                    <Form.Group>
                                                                    <Form.Check className="checkbox-inline">
                                                                        <Form.Check.Label>
                                                                            <Form.Check.Input
                                                                                type="checkbox"
                                                                                checked={props.selectedRecords?.find(id=>id===row.original.id)}
                                                                                onChange={e=>props.toggleSelectedRecord(e,row.original.id)}
                                                                            ></Form.Check.Input>
                                                                            <span className="form-check-sign"></span>
                                                                        </Form.Check.Label>
                                                                    </Form.Check>
                                                                    </Form.Group>
                                                                </div>
                                                            </div>:
                                                            <div className="td checkbox-row" key={index} {...cell.getCellProps()}>
                                                            </div>
                                                }
                                                    if (cell.column.id === "actions") {
                                                        return <div className="td" key={index} {...cell.getCellProps()}>
                                                            <div className="actions-right">
                                                                {(props.rtType!==RECORD_TYPE_TYPE.PAYMENT || (row.original.paymentInfo && row.original.paymentInfo.paymentStatus!=="COMPLETE")) && <OverlayTrigger
                                                                    onClick={(e) => e.preventDefault()}
                                                                    overlay={
                                                                        <Tooltip>
                                                                            <FormattedMessage id="Button.editNoParams" />
                                                                        </Tooltip>
                                                                    }
                                                                >
                                                                    <Button
                                                                        variant="success"
                                                                        size="sm"
                                                                        className="btn-link edit text-success"
                                                                        onClick={(event) =>openEditFormOrSidebar(event, row.original.id)}
                                                                    >
                                                                        <span className="edit-btn">{editIcon}</span>
                                                                    </Button>
                                                                </OverlayTrigger>}
                                                                {props.rtType !== RECORD_TYPE_TYPE.FORM && props.rtType !== RECORD_TYPE_TYPE.FORM_DATA && <OverlayTrigger
                                                                    onClick={(e) => e.preventDefault()}
                                                                    overlay={
                                                                        <Tooltip>
                                                                            <FormattedMessage id="Button.delete"/>
                                                                        </Tooltip>
                                                                    }
                                                                >
                                                                    <Button
                                                                        variant="info"
                                                                        size="sm"
                                                                        className="btn-link remove text-danger"
                                                                        onClick={(event) => props.deleteRecord(event, row.original.id)}
                                                                    >
                                                                        <span className="trash-btn">{trashIcon}</span>
                                                                    </Button>
                                                                </OverlayTrigger>}
                                                            </div>
                                                        </div>
                                                    } else if (cell.column.id === "hasTags") {
                                                        if (cell.value) {
                                                            return <div className="td single-icon-td" key={index} {...cell.getCellProps()}><span
                                                                className="tag-icon">{tagIcon}</span></div>
                                                        } else {
                                                            return <div className="td single-icon-td" key={index} {...cell.getCellProps()}></div>
                                                        }
                                                    } else if (cell.column.id === "paymentAction") {
                                                        return <div className="td" key={index} {...cell.getCellProps()}>
                                                            {row.original.paymentInfo && row.original.paymentInfo.paymentStatus!=="COMPLETE"&&<div className="actions-right">
                                                                <Button variant="primary"
                                                                        size="sm"
                                                                        onClick={()=>props.generatePaymentLink(row.original.id)}
                                                                >
                                                                    <FormattedMessage id="PayNow"/>
                                                                </Button>
                                                            </div>}
                                                        </div>
                                                    } else {
                                                        let displayOption = props.recordTypeFields?.find(record => record.id === cell.column.id)?.displayOption?.toLowerCase();
                                                        return  <div key={index} className={(cell.column.id+"-td") +" td " + (displayOption ? displayOption : "")} {...cell.getCellProps()}>
                                                            {index === 0 &&  props.openDetailsSidebar?
                                                                <a href={"#"} onClick={event => openDetailsSidebarOrFormPreview(event,row.original.id)}>{renderCell(cell,row,indexRow) ? renderCell(cell,row,indexRow) :

                                                                    <OverlayTrigger
                                                                        onClick={(e) => e.preventDefault()}
                                                                        overlay={
                                                                            <Tooltip>
                                                                                <FormattedMessage id={"paramIsEmpty"} values={{param:  headerGroups[0].headers[0].id.toUpperCase()}}/>
                                                                            </Tooltip>
                                                                        }
                                                                    >
                                                                        <i className='fa fa-info-circle special'/>
                                                                    </OverlayTrigger>
                                                                    }</a>
                                                                :
                                                                renderCell(cell,row,indexRow)
                                                            }
                                                        </div>
                                                    }
                                                })}
                                        </div>
                                    )
                                })}
                        </div>
                    </div>
            {!!!data.length && <h4 style={{textAlign: "center"}}><FormattedMessage id="Validation.noRecordsYet"/></h4>}
        </div>
    )
}
export default RecordsPageTable;
