import React, {useEffect, useRef, useState} from "react";
import { useHistory } from "react-router-dom";
import Footer from "components/Footer.js"

import { FormattedMessage } from 'react-intl';
import { Button, Card, Col, Row,OverlayTrigger,Tooltip } from "react-bootstrap";
import ConditionEditor from "./components/conditions/ConditionEditor";
import ActionEditor from "./components/actions/ActionEditor";
import * as QueryBuilder from "util/query/queryBuilder.js"
import { getRecordTypeByIdHandler } from "handlers/workspaceAdminHandlers/recordTypeHandlers/EditRuleRecordTypeHandlers";
import { saveRuleHandler } from "handlers/workspaceAdminHandlers/recordTypeHandlers/EditRuleRecordTypeHandlers";
import AdminNavbar from "components/AdminNavbar";
import CrossaPageTemplate from "components/CrossaPageTemplate";
import {addIconWithoutBg} from "../../../components/Icons";
import SweetAlert from "react-bootstrap-sweetalert";
import {addRuleValidations} from "../../../validations/workspaceAdmin/recordType/RuleValidations";
import {getNotifyErrorOptions} from "../../../util/Util";
import NotificationAlert from "react-notification-alert";
import OperatorComponent from "./components/OperatorComponent";


function EditRuleRecordTypePage(props) {

    const history = useHistory();
    const wid = props.match.params.wid;
    const recordTypeId = props.match.params.rid;
    const ruleIndex = props.match.params.ruleid;

    const [state, setState] = useState({
        conditions: [],
        actions: [],
        isActive:false,
        isDefault:false,
        recordFields: [],
        recordType: {},
        alert:null,
        errors:{
            conditions:{},
            actions:{},
        }
    });

    useEffect(() => {
        getPageContent();
    }, [wid,props.match.params.rid,ruleIndex]);

    const getPageContent = async () => {
        if (recordTypeId) {
            getRecordTypeByIdHandler(recordTypeId, ruleIndex).then(response => {
                if(response.data.isDefault){
                    history.push("/workspace-admin/"+wid+"/record-types/"+recordTypeId+"/rules/")
                } else {
                    setState({
                        ...state,
                        ...response.data
                    });
                }
            });
        }
    }

    const addCondition = () => {
        let newConditions;
        let newCondition = {}
        if (state.conditions.length > 0) {
            const operator = { "operator": "And" }
            newConditions = [...state.conditions, operator, newCondition];
        } else {
            newConditions = [...state.conditions, newCondition];
        }
        setState({
            ...state,
            conditions: newConditions,
        });
    }

    const addAction = () => {
        let newAction = {}
        setState({
            ...state,
            actions: [
                ...state.actions,
                newAction
            ],
        });
    }

    const deleteCondition = id => {
        const newConditions = state.conditions.filter((condition, index) => index !== id && (id === 0 ? index !== (id + 1) : index !== (id - 1)));
        if (newConditions.length === 0) {
            newConditions.push({});
        }
        setState({
            ...state,
            conditions: newConditions,
        });
    }

    const deleteAction = id => {
        const newActions = state.actions.filter((action, index) => index !== id);
        setState({
            ...state,
            actions: newActions,
        });
    }

    const changeCondition = (condition, index,errors) => {
        let newErrors={...state.errors};
        if(errors){
            newErrors={
                ...newErrors,
                conditions: {
                    ...newErrors.conditions,
                    [index]:errors
                }
            }
        }
        setState({
            ...state,
            conditions: [...state.conditions.slice(0, index), condition, ...state.conditions.slice(index + 1)],
            errors:newErrors
        });
    }

    const changeAction = (action, index,errors) => {
        let newErrors={...state.errors};
        if(errors){
            newErrors={
                ...newErrors,
                actions: {
                    ...newErrors.actions,
                    [index]:errors
                }
            }
        }
        setState({
            ...state,
            actions: [...state.actions.slice(0, index), action, ...state.actions.slice(index + 1)],
            errors: newErrors
        });
    }

    const renderConditions = () => {
        return state.conditions.map((condition, index) =>
            index % 2 === 0 ?
                <React.Fragment key={index}>
                    <ConditionEditor
                        key={index}
                        noConditions={state.conditions.length}
                        index={index}
                        condition={condition}
                        conditionOptions={conditionOptions}
                        deleteCondition={deleteCondition}
                        addCondition={addCondition}
                        changeCondition={changeCondition}
                        recordFields={state.recordFields}
                        lastCondition={index + 1 === state.conditions.length}
                        operatorComponent={(index + 1) !== state.conditions.length ? state.conditions[index + 1] : null}
                        errors={state.errors.conditions[index] || {}}
                    />
                    {
                        (index + 1) !== state.conditions.length &&
                        <OperatorComponent key={index + 1} operator={state.conditions[index + 1].operator}
                                           index={index + 1} changeCondition={changeCondition}/>
                    }
                </React.Fragment>
                :
                <React.Fragment key={index} />);
    }

    const renderActions = () => {
        return state.actions.map((action, index) =>
            <ActionEditor
                key={index}
                noActions={state.actions.length}
                index={index}
                action={action}
                actionOptions={actionOptions}
                deleteAction={deleteAction}
                addAction={addAction}
                changeAction={changeAction}
                recordFields={state.recordFields}
                lastAction={index + 1 === state.actions.length}
                operatorComponent={(index + 1) !== state.actions.length ? state.actions[index + 1] : null}
                errors={state.errors.actions[index] ||{}}
            />
        );
    }

    const deleteAlert = () => {
        setState({
            ...state,
            alert:
                <SweetAlert
                    type="danger"
                    title=""
                    style={{ display: "block", marginTop: "-100px", fontSize: "13px" }}
                    btnSize="md"
                    confirmBtnBsStyle="danger"
                    cancelBtnBsStyle="default"
                    showCancel
                    cancelBtnText={<FormattedMessage id="Button.cancel" />}
                    confirmBtnText={<FormattedMessage id="Button.delete" />}
                    openAnim="false"
                    onConfirm={() => deleteRule()}
                    onCancel={() => hideAlert()}
                    allowEscape
                    closeOnClickOutside
                    customClass="crossa-alert"

                > <FormattedMessage id="Alert.deleteItem" values={{ param: <FormattedMessage id="Rule" /> }} />
                </SweetAlert>
        });
    }

    const hideAlert = () => {
        setState({
            ...state,
            alert:null
        });
    }

    const deleteRule = () => {

        let newRules = [...state.recordType.rules];

        if (ruleIndex > -1) { // only splice array when item is found
            newRules.splice(ruleIndex, 1); // 2nd parameter means remove one item only
        }
        let newRecordType = {
            id: state.recordType.id,
            rules: newRules
        };
        saveRuleHandler(newRecordType).then(response => {
            if (response.ok) {
                history.goBack();
            }
        });
    }

    const saveRule = async () => {
        const [newErrors, existsErrors] = addRuleValidations({
            condition: state.conditions,
            actions: state.actions
        }, {...state.errors});

        setState({
            ...state,
            errors: newErrors
        });
        if (existsErrors) {
            let options = getNotifyErrorOptions("bc");
            notificationAlertRef.current.notificationAlert(options);
            return;
        }

        let newRecordType = {
            id: state.recordType.id,
            rules: [...state.recordType.rules]
        };
        newRecordType.rules[ruleIndex] = {
            "condition": JSON.stringify(QueryBuilder.conditionsToQuery(state.conditions)),
            "actions": JSON.stringify(QueryBuilder.actionsToQuery(state.actions)),
            "isActive": state.isActive
        };

        saveRuleHandler(newRecordType).then(response => {
            if (response.ok) {
                history.goBack();
            }
        });

    }

    const conditionOptions = [
        { label: <FormattedMessage id="IsNew" values={{param:state.recordType?.name}}/>, value: "IsNew" },
        { label: <FormattedMessage id="IsChanged" />, value: "IsChanged" },
    ];

    const actionOptions = [
        { label: <FormattedMessage id="Action.show" />, value: "Show" },
        { label: <FormattedMessage id="Action.hide" />, value: "Hide" },
        { label: <FormattedMessage id="Action.makeReadonly" />, value: "MakeReadonly" },
        { label: <FormattedMessage id="Action.makeEditable" />, value: "MakeEditable" },
        { label: <FormattedMessage id="Action.makeMandatory" />, value: "MakeMandatory" },
        { label: <FormattedMessage id="Action.removeMandatory" />, value: "RemoveMandatory" },
        { label: <FormattedMessage id="Action.setValueOf" />, value: "SetValue" },
        { label: <FormattedMessage id="Action.clearValueOf" />, value: "Clear" }
    ];

    const notificationAlertRef=useRef(null);

    return (
        <>
            <div className="rna-container">
                <NotificationAlert ref={notificationAlertRef} />
            </div>
            <AdminNavbar
                title={<FormattedMessage id="EditRule" values={{ param: (parseInt(ruleIndex,10)+1) }} />}
                hasBackBtn={true}
            />
            <CrossaPageTemplate>
                <Row >
                    <Col className={"text-right"}>
                        <OverlayTrigger
                            onClick={(e) => e.preventDefault()}
                            overlay={
                                <Tooltip>
                                    <FormattedMessage id="Button.delete" />
                                </Tooltip>
                            }
                        >
                            <Button
                                variant="danger"
                                size="sm"
                                className="btn-link remove text-danger"
                                onClick={deleteAlert}
                            >
                                <i className="fa fa-trash" />
                            </Button>
                        </OverlayTrigger></Col>
                </Row>
                <Row>
                    <Col md={12}>
                        <Card.Header>
                            <Card.Title >
                                <FormattedMessage id="Conditions" />
                            </Card.Title>
                        </Card.Header>
                        <Card.Body className="rule-record-type-page pt-0">
                            {
                                renderConditions()

                            }
                            <Button variant="link" onClick={addCondition}>
                            <span className="icon-cont">
                                {addIconWithoutBg}
                            </span>
                                <FormattedMessage id="NewCondition" />
                            </Button>
                        </Card.Body>
                        <hr></hr>
                        <Card.Header>
                            <Card.Title>
                                <FormattedMessage id="Actions" />
                            </Card.Title>
                        </Card.Header>
                        <Card.Body className="rule-record-type-page pt-0">
                            {
                                renderActions()
                            }
                            <Button variant="link" onClick={addAction}>
                            <span className="icon-cont">
                                {addIconWithoutBg}
                            </span>
                                <FormattedMessage id="NewAction" />
                            </Button>
                        </Card.Body>
                    </Col>
                </Row>

                {state.alert}
                <Footer
                    save={saveRule}
                    cancel={() => history.goBack()}
                    isEnable={true} />

            </CrossaPageTemplate>
        </>
    )
}

export default EditRuleRecordTypePage;
