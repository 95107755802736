import * as WorkspaceService from "service/WorkspaceService";
import * as ViewService from "../../../service/ViewsService";

import {
    clientSetupFields,
    merchantSetupFields,
    paymentSetupFields
} from "../../../views/workspaceAdmin/settings/utils/PaymentFields";

const getFormPaymentSetupByWorkspaceIdHandler=async(wid)=>{
    let formPaymentSetup={};
    const response = await WorkspaceService.getFormPaymentSetup(wid);
    if (response.ok) {
        formPaymentSetup=response.data;
    }

    return{
        data:{
            formPaymentSetup: formPaymentSetup,
        },
        ok:response.ok
    }
}


const saveFormPaymentSetupHandler=async(wid, formPaymentSetup)=>{

    const response = await WorkspaceService.updateFormPaymentSetup(wid, formPaymentSetup);

    return {
        ok:response.ok
    }
}

const savePaymentSetupHandler=async(wid, paymentSetup)=>{

    const response = await WorkspaceService.updatePaymentSetup(wid, paymentSetup);

    return {
        ok:response.ok
    }
}


const getPaymentSetupByWorkspaceIdHandler=async(wid)=>{
    let paymentSetup={};
    let paymentProcessorOptions=[{label:"Paylike",value:"paylike"}];
    const response = await WorkspaceService.getPaymentSetup(wid);
    if (response.ok) {
        paymentSetup=response.data;
    }

    return{
        data:{
            paymentSetup: paymentSetup,
            paymentSetupOptions:{
                paymentProcessor:paymentProcessorOptions,
            }
        },
        ok:response.ok
    }
}

const getDataForPaymentSetup=async (wid)=>{

    let views=[];
    let paymentSetup={};
    let merchantSetup={};
    let clientSetup={};
    let initPaymentSetup={};
    let paymentProcessorOptions=[{label:"Paylike",value:"paylike"}];
    let viewOptions=[];
    let paymentSetupOptions={};
    let merchantSetupOptions={};
    let clientSetupOptions={};
    paymentSetupFields.map(field=>{
        if(field.id!=="paymentView"){
            paymentSetupOptions[field.id]=[];
        }
    });
    merchantSetupFields.map(field=>{
        merchantSetupOptions[field.id]=[];
    });
    clientSetupFields.map(field=>{
        clientSetupOptions[field.id]=[];
    })
    const response = await ViewService.findAllViews(wid);
    if (response.ok) {
        views=response.data;
        response.data.map(view => {
                viewOptions.push({
                    "label": view.name,
                    "value": view.id,
                });
        });
    }

    const responsePayment = await WorkspaceService.getPaymentSetup(wid);
    if (responsePayment.ok) {
        initPaymentSetup=responsePayment.data;
            paymentSetupFields.map(field=>{
                paymentSetup[field.id]=initPaymentSetup[field.id];
                if( initPaymentSetup["paymentPlanView"]) {
                    if (field.id !== "paymentView" && field.id !== "paymentProcessor" && field.id !== "paymentPlanView") {
                        let view = views.find(view => view.id === initPaymentSetup["paymentPlanView"]);

                        let fieldOptions=[];
                        view.fieldModes?.map(fieldMode => {
                            if (fieldMode.recordTypeField.type === field.type) {
                                fieldOptions.push({
                                    label: fieldMode.recordTypeField.name,
                                    value: fieldMode.recordTypeField.id
                                });
                            }
                        });

                        paymentSetupOptions={
                            ...paymentSetupOptions,
                            [field.id]:fieldOptions
                        }
                    }
                }
            });

            paymentSetup["paymentView"]="Payment";
            merchantSetupFields.map(field=>{
                merchantSetup[field.id]=initPaymentSetup[field.id];
                if( initPaymentSetup["merchantView"]) {
                    if (field.id !== "merchantView") {
                        let view = views.find(view => view.id === initPaymentSetup["merchantView"]);

                        let fieldOptions=[];
                        view.fieldModes?.map(fieldMode => {
                            if (fieldMode.recordTypeField.type === field.type) {
                                fieldOptions.push({
                                    label: fieldMode.recordTypeField.name,
                                    value: fieldMode.recordTypeField.id
                                });
                            }
                        });
                        merchantSetupOptions={
                            ...merchantSetupOptions,
                            [field.id]:fieldOptions
                        }
                    }
                }
            });

        clientSetupFields.map(field=>{
            clientSetup[field.id]=initPaymentSetup[field.id];
            if( initPaymentSetup["clientView"]) {
                if (field.id !== "clientView") {
                    let view = views.find(view => view.id === initPaymentSetup["clientView"]);

                    let fieldOptions=[];
                    view.fieldModes?.map(fieldMode => {
                        if (fieldMode.recordTypeField.type === field.type) {
                            fieldOptions.push({
                                label: fieldMode.recordTypeField.name,
                                value: fieldMode.recordTypeField.id
                            });
                        }
                    });
                    clientSetupOptions={
                        ...clientSetupOptions,
                        [field.id]:fieldOptions
                    }
                }
            }
        });
    }
    return{
        data:{
            views:views,
            paymentSetup: paymentSetup,
            merchantSetup:merchantSetup,
            clientSetup:clientSetup,
            initAllPaymentSetup:initPaymentSetup,
            paymentSetupOptions:{
                ...paymentSetupOptions,
                paymentProcessor:paymentProcessorOptions,
                paymentPlanView:viewOptions,
            },
            merchantSetupOptions:{
                ...merchantSetupOptions,
                merchantView:viewOptions
            },
            clientSetupOptions:{
                ...clientSetupOptions,
                clientView:viewOptions
            }
        },
        ok:responsePayment.ok&&response.ok
    }
}

export {
    getFormPaymentSetupByWorkspaceIdHandler,
    getPaymentSetupByWorkspaceIdHandler,
    saveFormPaymentSetupHandler,
    getDataForPaymentSetup,
    savePaymentSetupHandler
}
