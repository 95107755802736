import React, {useContext} from "react";
import {SORT_DIRECTION} from "../../../../util/Constants";

const TabsContext = React.createContext();
const TabsUpdateContext = React.createContext();
const SingleTabUpdateContext = React.createContext();

export const useTabs = () => useContext(TabsContext);
export const useTabsUpdate = () => useContext(TabsUpdateContext);
export const useTabChange = () => useContext(SingleTabUpdateContext);

function TabContext(props) {
    const setTabs = props.setTabs2;


    const updateTabs = (newTabs,newErrors,newIndex) => {
        setTabs(newTabs,newErrors,newIndex);
    }

    const changeTabName = (tabIndex, newTabName,pageIndex) => {
        const newTabs = [...props.tabs];
        newTabs[tabIndex] = {...newTabs[tabIndex], "name": newTabName};
        let newErrors=props.errors;
        if(newErrors.pages?.[pageIndex]?.tabs?.[tabIndex]?.name){
            newErrors.pages[pageIndex].tabs[tabIndex].name=false;
        }
        setTabs(newTabs,newErrors);
    }

    const changeTabDisplayMode = (tabIndex, newTabDisplayMode,pageIndex) => {
        const newTabs = [...props.tabs];
        newTabs[tabIndex] = {...newTabs[tabIndex], "displayMode": newTabDisplayMode};
        let newErrors=props.errors;
        if(newErrors.pages?.[pageIndex]?.tabs?.[tabIndex]?.displayMode){
            newErrors.pages[pageIndex].tabs[tabIndex].displayMode=false;
        }
        setTabs(newTabs,newErrors);
    }

    const changeTabDetailedViewMode = (tabIndex, newTabDetailedViewMode,pageIndex) => {
        const newTabs = [...props.tabs];
        newTabs[tabIndex] = {...newTabs[tabIndex], "detailedViewMode": newTabDetailedViewMode};
        let newErrors=props.errors;
        if(newErrors.pages?.[pageIndex]?.tabs?.[tabIndex]?.detailedViewMode){
            newErrors.pages[pageIndex].tabs[tabIndex].detailedViewMode=false;
        }
        setTabs(newTabs,newErrors);
    }

    const changeTabView = (tabIndex, newTabView,pageIndex) => {
        const newTabs = [...props.tabs];
        newTabs[tabIndex] = {...newTabs[tabIndex], "vid": newTabView, "displayFields": []};
        let newErrors=props.errors;
        if(newErrors.pages?.[pageIndex]?.tabs?.[tabIndex]?.vid){
            newErrors.pages[pageIndex].tabs[tabIndex].vid=false;
        }
        setTabs(newTabs,newErrors)
    }

    const changeTabEnableEdit = (index, newTabEnableEdit) => {
        const newTabs = [...props.tabs];
        newTabs[index] = {...newTabs[index], "enableEditAction": newTabEnableEdit};
        setTabs(newTabs)
    }

    const changeTabEnableFilters = (index, newTabEnableFilters) => {
        const newTabs = [...props.tabs];
        newTabs[index] = {...newTabs[index], "enableFilters": newTabEnableFilters};
        setTabs(newTabs)
    }

    const changeTabEnableDefaultSorting = (tabIndex, newTabEnableSorting,pageIndex) => {
        const newTabs = [...props.tabs];
        newTabs[tabIndex] = {...newTabs[tabIndex], "enableDefaultSorting": newTabEnableSorting,"sortDirection":SORT_DIRECTION.ASC};
        let newErrors=props.errors;
        if(newErrors.pages?.[pageIndex]?.tabs?.[tabIndex]?.sortingField){
            newErrors.pages[pageIndex].tabs[tabIndex].sortingField=false;
        }
        setTabs(newTabs,newErrors)
    }

    const changeTabSortingField = (tabIndex, newTabSortingField, pageIndex) => {
        const newTabs = [...props.tabs];
        newTabs[tabIndex] = {...newTabs[tabIndex], "sortingField": newTabSortingField};
        let newErrors=props.errors;
        if(newErrors.pages?.[pageIndex]?.tabs?.[tabIndex]?.sortingField){
            newErrors.pages[pageIndex].tabs[tabIndex].sortingField=false;
        }
        setTabs(newTabs,newErrors)
    }

    const changeTabSortDirection = (index, newTabSortDirection) => {
        const newTabs = [...props.tabs];
        newTabs[index] = {...newTabs[index], "sortDirection": newTabSortDirection};
        setTabs(newTabs)
    }

    const changeDisplayFields = (tabIndex, newDisplayFieldId,pageIndex) => {
        const newTabs = [...props.tabs];
        const displayFields = [...newTabs[tabIndex].displayFields];
        const displayFieldIndex = displayFields.findIndex(element => element === newDisplayFieldId);
        if (displayFieldIndex !== -1) {
            displayFields.splice(displayFieldIndex, 1);
        } else {
            displayFields.push(newDisplayFieldId);
        }
        newTabs[tabIndex] = {...newTabs[tabIndex], "displayFields": [...displayFields]};
        let newErrors=props.errors;
        if(newErrors.pages?.[pageIndex]?.tabs?.[tabIndex]?.displayFields){
            newErrors.pages[pageIndex].tabs[tabIndex].displayFields=false;
        }
        setTabs(newTabs,newErrors);
    }

    const changeTabEnableExport = (index, newTabEnableExport) => {
        const newTabs = [...props.tabs];
        newTabs[index] = {...newTabs[index], "enableExport": newTabEnableExport};
        setTabs(newTabs)
    }

    const changeTabMessageComponent = (index, newTabMessageComponent,pageIndex) => {
        const newTabs = [...props.tabs];
        newTabs[index] = {...newTabs[index], "enableMessages": newTabMessageComponent,"messageEmailField":"","messageBtnName":""};
        let newErrors=props.errors;
        if(newErrors.pages?.[pageIndex]?.tabs?.[index]?.messageEmailField){
            newErrors.pages[pageIndex].tabs[index].messageEmailField=false;
        }
        if(newErrors.pages?.[pageIndex]?.tabs?.[index]?.messageBtnName){
            newErrors.pages[pageIndex].tabs[index].messageBtnName=false;
        }
        setTabs(newTabs,newErrors)
    }

    const changeTabButtonMessageName = (index, newButtonMessageName, pageIndex) => {
        const newTabs = [...props.tabs];
        newTabs[index] = {...newTabs[index], "messageBtnName": newButtonMessageName};
        let newErrors=props.errors;
        if(newErrors.pages?.[pageIndex]?.tabs?.[index]?.messageBtnName){
            newErrors.pages[pageIndex].tabs[index].messageBtnName=false;
        }
        setTabs(newTabs,newErrors)
    }

    const changeTabMessageEmailField= (tabIndex, newTabMessageEmailField, pageIndex) => {
        const newTabs = [...props.tabs];
        newTabs[tabIndex] = {...newTabs[tabIndex], "messageEmailField": newTabMessageEmailField};
        let newErrors=props.errors;
        if(newErrors.pages?.[pageIndex]?.tabs?.[tabIndex]?.messageEmailField){
            newErrors.pages[pageIndex].tabs[tabIndex].messageEmailField=false;
        }
        setTabs(newTabs,newErrors)
    }

    const clearDisplayFields = (index) => {
        const newTabs = [...props.tabs];
        newTabs[index] = {...newTabs[index], "displayFields": []};
        setTabs(newTabs);
    }

    const deleteTab = (tabIndex,pageIndex) => {
        const newTabs = [...props.tabs];
        newTabs.splice(tabIndex, 1);
        let newErrors=props.errors;
        if(newErrors.pages?.[pageIndex]?.tabs?.[tabIndex]){
            newErrors.pages[pageIndex].tabs[tabIndex]=null;
        }
        setTabs(newTabs,newErrors,0);
    }

    const changeTabFunctions = {
        "changeTabName": changeTabName,
        "changeTabDisplayMode": changeTabDisplayMode,
        "changeTabDetailedViewMode": changeTabDetailedViewMode,
        "changeTabView": changeTabView,
        "changeTabEnableEdit": changeTabEnableEdit,
        "changeTabEnableFilters": changeTabEnableFilters,
        "changeTabEnableDefaultSorting": changeTabEnableDefaultSorting,
        "changeTabSortingField": changeTabSortingField,
        "changeTabSortDirection": changeTabSortDirection,
        "changeDisplayFields": changeDisplayFields,
        "changeTabEnableExport":changeTabEnableExport,
        "changeTabMessageComponent":changeTabMessageComponent,
        "changeTabButtonMessageName":changeTabButtonMessageName,
        "changeTabMessageEmailField":changeTabMessageEmailField,
        "clearDisplayFields": clearDisplayFields,
        "deleteTab": deleteTab
    }

    return (
        <TabsUpdateContext.Provider value={updateTabs}>
            <TabsContext.Provider value={props.tabs}>
                <SingleTabUpdateContext.Provider value={changeTabFunctions}>
                    {props.children}
                </SingleTabUpdateContext.Provider>
            </TabsContext.Provider>
        </TabsUpdateContext.Provider>
    );
}

export default TabContext;