import { Col } from "react-bootstrap";
import {useEffect, useState} from "react";
import {
    getAllRecordsInViewHandler
} from "../../../../handlers/workspaceMemberHandlers/menuDisplayHandlers/DisplayMenuDashboardHandlers";
import {RECORD_FIELD_TYPE, WIDGET_OPERATOR, WIDGET_TYPE} from "../../../../util/Constants";
import {Bar, Pie} from "react-chartjs-2";
import {
    Chart as ChartJS,
    ArcElement,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    SubTitle,
    Tooltip,
    Legend,
} from 'chart.js';

ChartJS.register(
    ArcElement,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
    SubTitle
);

function ChartWidget(props) {

    const [state, setState] = useState({
        labels: [],
        series: [],
        inProgress: true
    })
    useEffect(async () => {
        const response = await getAllRecordsInViewHandler(props.widget?.vid, props.widget?.defaultSorting ? [props.widget?.sortingField + "," + props.widget?.sortDirection] : null, props.widget?.rows);
        if (response.ok) {
            let records = response.data.records.values;

            let data = {};
            let labels = [];
            if (props.widget.type === WIDGET_TYPE.PIE_CHART || props.widget?.operator === WIDGET_OPERATOR.ROW_COUNT) {
                [data, labels] = getDataForRowCountChart(response.data.fieldsTypeById, records);
            } else if (props.widget?.operator === WIDGET_OPERATOR.SUM) {
                [data, labels] = getDataForSumChart(response.data.fieldsTypeById, records);
            } else if (props.widget?.operator === WIDGET_OPERATOR.AVERAGE) {
                [data, labels] = getDataForAverageChart(response.data.fieldsTypeById, records);
            } else if (props.widget?.operator === WIDGET_OPERATOR.MIN) {
                [data, labels] = getDataForMinChart(response.data.fieldsTypeById, records);
            } else if (props.widget?.operator === WIDGET_OPERATOR.MAX) {
                [data, labels] = getDataForMaxChart(response.data.fieldsTypeById, records);
            }

            setState({
                ...state,
                labels: labels,
                series: Object.values(data),
                error: null,
                inProgress: false
            });
        }else{
            setState({
                ...state,
                error: response.data.error,
                inProgress: false
            })
        }
    }, [props.widget])

    const getDataForRowCountChart = (fieldsTypeById, records) => {
        let data = {};
        let labels = [];
        if (fieldsTypeById[props.widget?.groupingField] === RECORD_FIELD_TYPE.RECORD) {
            records.map(record => {
                if (record[props.widget?.groupingField]) {
                    if (data[record[props.widget?.groupingField].id]) {
                        data[record[props.widget?.groupingField].id]++;
                    } else {
                        data[record[props.widget?.groupingField].id] = 1;
                        labels.push(record[props.widget?.groupingField].name);
                    }
                }
            });
        } else {
            records.map(record => {
                if (record[props.widget?.groupingField]) {
                    if (data[record[props.widget?.groupingField]]) {
                        data[record[props.widget?.groupingField]]++;
                    } else {
                        data[record[props.widget?.groupingField]] = 1;
                    }
                }
            });
            labels = Object.keys(data);
        }
        return [data, labels];
    }
    const getDataForSumChart = (fieldsTypeById, records) => {
        let data = {};
        let labels = [];
        if (fieldsTypeById[props.widget?.groupingField] === RECORD_FIELD_TYPE.RECORD) {
            records.map(record => {
                if (record[props.widget?.groupingField]) {
                    if (data[record[props.widget?.groupingField].id]) {
                        data[record[props.widget?.groupingField].id] += (record[props.widget?.operatorField] || 0);
                    } else {
                        data[record[props.widget?.groupingField].id] = (record[props.widget?.operatorField] || 0);
                        labels.push(record[props.widget?.groupingField].name);
                    }
                }
            });
        } else {
            records.map(record => {
                if (record[props.widget?.groupingField]) {
                    if (data[record[props.widget?.groupingField]]) {
                        data[record[props.widget?.groupingField]] += record[props.widget?.operatorField];
                    } else {
                        data[record[props.widget?.groupingField]] = record[props.widget?.operatorField];
                    }
                }
            });
            labels = Object.keys(data);
        }
        return [data, labels];
    }
    const getDataForAverageChart = (fieldsTypeById, records) => {
        let cnt = {};
        let data = {};
        let labels = [];
        if (fieldsTypeById[props.widget?.groupingField] === RECORD_FIELD_TYPE.RECORD) {
            records.map(record => {
                if (record[props.widget?.groupingField]) {
                    if (data[record[props.widget?.groupingField].id]) {
                        data[record[props.widget?.groupingField].id] += (record[props.widget?.operatorField] || 0);
                        cnt[record[props.widget?.groupingField].id]++;
                    } else {
                        data[record[props.widget?.groupingField].id] = (record[props.widget?.operatorField] || 0);
                        cnt[record[props.widget?.groupingField].id] = 1;
                        labels.push(record[props.widget?.groupingField].name);
                    }
                }
            });
        } else {
            records.map(record => {
                if (record[props.widget?.groupingField]) {
                    if (data[record[props.widget?.groupingField]]) {
                        data[record[props.widget?.groupingField]] += record[props.widget?.operatorField];
                        cnt[record[props.widget?.groupingField]]++;
                    } else {
                        data[record[props.widget?.groupingField]] = record[props.widget?.operatorField];
                        cnt[record[props.widget?.groupingField]] = 1;
                    }
                }
            });
            labels = Object.keys(data);
        }
        Object.keys(cnt).map(key => data[key] = data[key] / cnt[key]);
        return [data, labels];
    }
    const getDataForMinChart = (fieldsTypeById, records) => {
        let data = {};
        let labels = [];
        if (fieldsTypeById[props.widget?.groupingField] === RECORD_FIELD_TYPE.RECORD) {
            records.map(record => {
                if (record[props.widget?.groupingField]) {
                    if (data[record[props.widget?.groupingField].id]) {
                        if (record[props.widget?.operatorField] && record[props.widget?.operatorField] < data[record[props.widget?.groupingField].id])
                            data[record[props.widget?.groupingField].id] = record[props.widget?.operatorField];
                    } else {
                        if (record[props.widget?.operatorField]) {
                            data[record[props.widget?.groupingField].id] = record[props.widget?.operatorField];
                        }
                        labels.push(record[props.widget?.groupingField].name);
                    }
                }
            });
        } else {
            records.map(record => {
                if (record[props.widget?.groupingField]) {
                    if (data[record[props.widget?.groupingField]]) {
                        if (record[props.widget?.operatorField] && record[props.widget?.operatorField] < data[record[props.widget?.groupingField]])
                            data[record[props.widget?.groupingField]] = record[props.widget?.operatorField];
                    } else {
                        if (record[props.widget?.operatorField]) {
                            data[record[props.widget?.groupingField]] = record[props.widget?.operatorField];
                        }
                    }
                }
            });
            labels = Object.keys(data);
        }
        return [data, labels];
    }
    const getDataForMaxChart = (fieldsTypeById, records) => {
        let data = {};
        let labels = [];
        if (fieldsTypeById[props.widget?.groupingField] === RECORD_FIELD_TYPE.RECORD) {
            records.map(record => {
                if (record[props.widget?.groupingField]) {
                    if (data[record[props.widget?.groupingField].id]) {
                        if (record[props.widget?.operatorField] && record[props.widget?.operatorField] > data[record[props.widget?.groupingField].id])
                            data[record[props.widget?.groupingField].id] = record[props.widget?.operatorField];
                    } else {
                        if (record[props.widget?.operatorField]) {
                            data[record[props.widget?.groupingField].id] = record[props.widget?.operatorField];
                        }
                        labels.push(record[props.widget?.groupingField].name);
                    }
                }
            });
        } else {
            records.map(record => {
                if (record[props.widget?.groupingField]) {
                    if (data[record[props.widget?.groupingField]]) {
                        if (record[props.widget?.operatorField] && record[props.widget?.operatorField] > data[record[props.widget?.groupingField]])
                            data[record[props.widget?.groupingField]] = record[props.widget?.operatorField];
                    } else {
                        if (record[props.widget?.operatorField]) {
                            data[record[props.widget?.groupingField]] = record[props.widget?.operatorField];
                        }
                    }
                }
            });
            labels = Object.keys(data);
        }
        return [data, labels];
    }


    const optionsPie = {
        responsive: true,
        maintainAspectRatio: false,
        layout: {
            padding: 20
        },
        plugins: {
            legend: {
                position: 'right',
                fullSize: false,
                maxHeight: 50,
                labels: {
                    usePointStyle: true,
                    pointStyle: 'rect',
                    font: {
                        size: 15
                    }
                },
                onClick: () => {
                }
            },
            title: {
                display: true,
                align: 'start',
                font: {
                    size: 16,
                    weight:600,
                },
                color:"#625B71",
                text: props.widget?.title,
            },
            subtitle: {
                display: true,
                align: 'start',
                font: {
                    size: 13,
                },
                color: "#888BA1",
                padding:{
                    bottom:10
                },
                text: props.widget?.description
            }
        }
    };
    const optionsBar = {
        indexAxis: 'y',
        responsive: true,
        maintainAspectRatio: false,
        scales: {
            y: {
                grid: {
                    display: false
                }
            }
        },
        layout: {
            padding: 20
        },
        plugins: {
            legend: {
                display: false,
            },
            title: {
                display: true,
                align: 'start',
                font: {
                    size: 16,
                    weight:600,
                },
                color:"#625B71",
                text: props.widget?.title,
            },
            subtitle: {
                display: true,
                align: 'start',
                font: {
                    size: 13,
                },
                color: "#888BA1",
                padding:{
                    bottom:10
                },
                text: props.widget?.description
            }
        }
    };
    const optionsColumn = {
        indexAxis: 'x',
        responsive: true,
        maintainAspectRatio: false,
        scales: {
            x: {
                grid: {
                    display: false
                }
            }
        },
        layout: {
            padding: 20
        },
        plugins: {
            legend: {
                display: false,
            },
            title: {
                display: true,
                align: 'start',
                font: {
                    size: 16,
                    weight:600,
                },
                color:"#625B71",
                text: props.widget?.title,
            },
            subtitle: {
                display: true,
                align: 'start',
                font: {
                    size: 13,
                },
                color: "#888BA1",
                padding:{
                    bottom:10
                },
                text: props.widget?.description
            }
        }
    };

    return !state.error && !state.inProgress?  (
        <div className="widget chart-widget">
            {props.widget.type === WIDGET_TYPE.PIE_CHART ?
                <Pie data={{
                    labels: state.labels,
                    datasets: [
                        {
                            data: state.series,
                            backgroundColor: ['#46386B','#5B4A8D', '#725EAB', '#9080BC', '#ADA1CE', '#CAC3DF', '#E8E4F1',
                                '#0070A3', '#0093D6', '#0AB2FF', '#3DC2FF', '#70D2FF', '#A3E2FF', '#D6F2FF',
                                '#4A6E35', '#629146', '#7AAF5A', '#96C07C', '#B1D19F', '#CDE1C1', '#E9F2E3'
                            ],
                        }]
                        }}
                     options={optionsPie}
                />
                :
                state.labels.length?
                    <Bar data={{
                        labels: state.labels,
                        datasets: [
                            {
                                barThickness: 10,
                                minBarLength: 1,
                                data: state.series,
                                backgroundColor: '#CCECFB',
                            }]
                            }}
                         options={props.widget.type === WIDGET_TYPE.BAR_CHART ? optionsBar : optionsColumn}
                         height={props.widget.type === WIDGET_TYPE.BAR_CHART?20*state.labels.length:"auto"}
                         width={props.widget.type === WIDGET_TYPE.COLUMN_CHART?20*state.labels.length:"auto"}
                />:""
            }
        </div>
    )
    : (<div></div>)

}

export default ChartWidget;
