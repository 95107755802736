import React from "react";
import {Droppable} from "react-beautiful-dnd";
import FormField from "./FormField";
import {Form} from "react-bootstrap";
import Select from "react-select";
import {FORM_FIELD_TYPE} from "../../../util/Constants";
import {FormattedMessage} from "react-intl";

function Column(props) {
    const {droppableId, list, type, currency, errors} = props;
    const paymentField = list.find(val => val.type === FORM_FIELD_TYPE.PAYMENT);

    return (
        <>
        <Droppable droppableId={droppableId} type={type}>
            {provided => (
                <div className="droppable-container" {...provided.droppableProps} ref={provided.innerRef}>
                    {paymentField &&
                    <div
                        className={"clickable-form-group-container payment-form-group-container" + (props.selectedId === paymentField.id && props.rightPanelOpened? " selected" : "")}

                        onClick={() => {
                            props.handleFormFieldClick ? props.handleFormFieldClick(paymentField) : ""}
                        }
                    >
                        <Form.Group>
                            <div className="row ml-0 mr-0">
                                <Form.Label>{paymentField.name ? paymentField.name : <FormattedMessage id="Form.payment"/>}</Form.Label>
                            </div>
                            <div className="form-description">
                                {paymentField.description ? paymentField.description : <FormattedMessage id="Form.descriptionOpt"/>}
                            </div>
                            <span className="form-description"><FormattedMessage id="Form.paymentType"/></span>
                            <Select
                                className="react-select primary mb-3"
                                classNamePrefix="react-select"
                                options={
                                    paymentField.otherPayments && paymentField.otherPaymentsLabel ?
                                        [{
                                            "label": "Online",
                                            "value": "Online"
                                        }, {
                                            "label": paymentField.otherPaymentsLabel,
                                            "value": paymentField.otherPaymentsLabel
                                        }] :
                                        [{
                                            "label": "Online",
                                            "value": "Online"
                                        }]}
                                value={{
                                    "label": "Online",
                                    "value": "Online"
                                }}
                            />
                            <span className="form-description"><FormattedMessage id="Form.paymentOptions"/></span>
                            {errors.paymentField && <div className={"error-message"}><FormattedMessage id={"Validation.addAtLeastOneOption"}/></div>}
                            {paymentField.paymentOptions?.map((option, index) =>
                                <Form.Check
                                    type="radio"
                                    label={option.name + " (" + (option.price / Math.pow(10, currency.digits)).toFixed(currency.digits) + " " + currency.code + ")"}
                                    checked={false}
                                    readOnly={true}
                                    key={index}
                                />)}
                        </Form.Group>
                    </div>}
                    {list.filter(val => val.type !== "Payment").map((val, index) => {
                        return (
                            <FormField {...val} draggableId={val.draggableId} selectedDraggableId={props.selectedDraggableId} rightPanelOpened={props.rightPanelOpened} handleFormFieldClick={props.handleFormFieldClick}  handleFormFieldDelete={props.handleFormFieldDelete} key={val.draggableId} index={index}/>
                        );
                    })}
                    {provided.placeholder}
                </div>

            )}
        </Droppable>

        </>
    );
}

export default Column;
