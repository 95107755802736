

export const paymentSetupFields = [
    {
        "id":"paymentView",
        "name":"Payment View",
    },
    {
        "id": "paymentProcessor",
        "name": "Payment Processor",
        "type": "SingleChoice",
    },
    {
        "id": "paymentPlanView",
        "name": "Payment Plan view",
        "type": "Record",
    },
    {
        "id": "paymentPlanNameField",
        "name": "Payment Plan name",
        "type": "Text",
    },
    {
        "id": "paymentPlanCodeField",
        "name": "Payment Plan code",
        "type": "Text",
    },
    {
        "id": "monthsField",
        "name": "Months",
        "type": "Integer",
    },
    {
        "id": "priceField",
        "name": "Price",
        "type": "Currency",
    },
    {
        "id": "editablePriceField",
        "name": "Editable Price",
        "type": "Boolean",
    },
    {
        "id": "vatLevelField",
        "name": "VAT Level",
        "type": "Decimal",
    },
    {
        "id": "recurrentField",
        "name": "Recurrent",
        "type": "Boolean",
    },
];

export const merchantSetupFields = [
    {
        "id": "merchantView",
        "name": "Merchant view",
        "type": "Record",
    },
    {
        "name":"Billing details",
        "type":"Group",
    },
    {
        "id": "merchantNameField",
        "name": "Merchant Name",
        "type": "Text",
    },
    {
        "id": "merchantAddressLine1Field",
        "name": "Merchant Address Line 1",
        "type": "Text",
    },
    {
        "id": "merchantAddressLine2Field",
        "name": "Merchant Address Line 2",
        "type": "Text",
    },
    {
        "id": "merchantPostCodeField",
        "name": "Merchant Post Code",
        "type": "Text",
    },
    {
        "id": "merchantCityField",
        "name": "Merchant City",
        "type": "Text",
    },
    {
        "id": "merchantCountryField",
        "name": "Merchant Country",
        "type": "Text",
    },
    {
        "id": "merchantRegistrationNrField",
        "name": "Merchant Registration Nr.",
        "type": "Text",
    },
    {
        "id": "merchantVatNrField",
        "name": "Merchant VAT Nr.",
        "type": "Text",
    },
    {
        "id": "merchantContactField",
        "name": "Merchant contact mail",
        "type": "Text",
    },
    {
        "id": "merchantInvoiceSerial",
        "name": "Merchant invoice serial",
        "type": "Text",
    },
    {
        "id": "merchantInvoiceNextNumber",
        "name": "Merchant invoice next number",
        "type": "Integer",
    },
    {
        "id": "merchantIntegrationKey1Field",
        "name": "Merchant Integration Key 1",
        "type": "Text",
    },
    {
        "id": "merchantIntegrationKey2Field",
        "name": "Merchant Integration Key 2",
        "type": "Text",
    },
    {
        "id": "merchantIntegrationKey3Field",
        "name": "Merchant Integration Key 3",
        "type": "Text",
    },
];

export const clientSetupFields = [
    {
        "id": "clientView",
        "name": "Client view",
        "type": "Record",
    },
    {
        "id": "clientNameField",
        "name": "Client Name",
        "type": "Text",
    },
    {
        "id": "clientAddressLine1Field",
        "name": "Client Address Line 1",
        "type": "Text",
    },
    {
        "id": "clientAddressLine2Field",
        "name": "Client Address Line 2",
        "type": "Text",
    },
    {
        "id": "clientPostCodeField",
        "name": "Client Post Code",
        "type": "Text",
    },
    {
        "id": "clientCityField",
        "name": "Client City",
        "type": "Text",
    },
    {
        "id": "clientCountryField",
        "name": "Client Country",
        "type": "Text",
    },
    {
        "id": "clientRegistrationNrField",
        "name": "Client Registration Nr.",
        "type": "Text",
    },
    {
        "id": "clientVatNrField",
        "name": "Client VAT Nr.",
        "type": "Text",
    },
    {
        "id": "clientContactField",
        "name": "Client contact mail",
        "type": "Text",
    },
];