import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";

import CrossaPageTemplate from "components/CrossaPageTemplate.js";
import CrossaTable from "components/CrossaTable.js"
import * as Util from "util/Util.js";
import SweetAlert from 'react-bootstrap-sweetalert';
import { FormattedMessage } from 'react-intl';
import { getAllMembersHandler } from "handlers/workspaceAdminHandlers/membersHandlers/AllMembersHandlers";
import { deleteMemberHandler } from "handlers/workspaceAdminHandlers/membersHandlers/AllMembersHandlers";
import AdminNavbar from "../../../components/AdminNavbar";

function AllMembersPage(props) {
    const [state, setState] = useState({
        members: [],
        workspaceRoles: [],
        selfMemberId: null,
        alert: null
    });

    const history = useHistory();
    const wid = props.match.params.wid;

    useEffect(() => {
        getPageContent();
    }, []);


    const getPageContent = async () => {
        getAllMembersHandler(wid).then(response => {
            if (response.ok) {
                setState({
                    ...state,
                    ...response.data
                })
            }
        })
    }

    const deleteMember = async (wid, id) => {

        deleteMemberHandler(wid, id, state.selfMemberId).then(response => {
            if (response.ok) {
                if (id === state.selfMemberId) {
                    Util.logout(history);
                } else {
                    setState({
                        ...state,
                        ...response.data,
                        alert: null
                    })
                }
            }
        })
    }

    const deleteAlert = (id) => {
        setState({
            ...state,
            alert:
                <SweetAlert
                    type="danger"
                    title=""
                    style={{ display: "block", marginTop: "-100px", fontSize: "13px" }}
                    btnSize="md"
                    confirmBtnBsStyle="danger"
                    cancelBtnBsStyle="default"
                    showCancel
                    cancelBtnText={<FormattedMessage id="Button.cancel" />}
                    confirmBtnText={<FormattedMessage id="Button.delete" />}
                    openAnim="false"
                    onConfirm={() => deleteMember(wid, id)}
                    onCancel={() => hideAlert()}
                    allowEscape
                    closeOnClickOutside
                    customClass="crossa-alert"

                > <FormattedMessage id="Alert.deleteMember" />
                </SweetAlert>
        });
    }


    const hideAlert = () => {
        setState({
            ...state,
            alert:null
        });
    }

    const toAddMemberPage = () => {
        history.push("/workspace-admin/" + props.match.params.wid + "/members/add");
    }

    const toEditMemberPage = memberId => {
        history.push("/workspace-admin/" + props.match.params.wid + "/members/" + memberId + "/edit");
    }

    const columns = [
        {
            Header: () => (
                <div>#</div>),
            accessor: "index",
        },
        {
            Header: () => (
                <div><FormattedMessage id="Name"/></div>),
            accessor: "name",
        },
        {
            Header: () => (
                <div><FormattedMessage id="Email"/></div>),
            accessor: "email",
        },
        {
            Header: () => (
                <div><FormattedMessage id="Roles"/></div>),
            accessor: "roles",
        },
        {
            Header: () => (
                <div><FormattedMessage id="Actions"/></div>),
            accessor: "actions",
            sticky:"right"
        },
    ];

    const createRow = (prop,index) => {
        return {
            index: index + 1,
            id: prop.id,
            name: prop.name,
            email: prop.email,
            roles: prop.roles ? prop.roles?.filter(role => state.workspaceRoles.find(r => r.id === role.id)).map(role => role.name).join(", ") : [],
        }
    }


    return (
        <>
            {state.alert}
            <AdminNavbar
                title={<FormattedMessage id="Members" />}
                hasRightBtn={true}
                rightBtnHasIcon={true}
                rightBtnName={<FormattedMessage id="Button.addMember" />}
                rightButtonAction={toAddMemberPage}
            />
            <CrossaPageTemplate>
            <CrossaTable
                columns={columns}
                data={state.members.map((element,index) => createRow(element,index))}
                handleEdit={toEditMemberPage}
                handleDelete={deleteAlert}
            />
            </CrossaPageTemplate>
        </>

    );
}

export default AllMembersPage;
