import * as ClientConfig from "config/ClientConfig.js";
import * as StorageService from "service/StorageService.js";
import * as RestClient from "util/api/restClient"

const addWorkspace = async workspace => {
    return RestClient.handleResponse(() => {
        const finalURL = ClientConfig.URL_RESOURCE_SERVER + ClientConfig.NAMESPACE_WORKSPACE;
        const response = fetch(finalURL, {
            method: "POST",
            body: JSON.stringify(workspace),
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + StorageService.getAccessToken()
            }
        });

        return response;
    });
}

const updateWorkspace = (workspace) => {
    return RestClient.handleResponse(() => {
        const finalURL = ClientConfig.URL_RESOURCE_SERVER + ClientConfig.NAMESPACE_WORKSPACE + workspace.id;
        const response = fetch(finalURL, {
            method: "PUT",
            body: JSON.stringify(workspace),
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + StorageService.getAccessToken()
            }
        });

        return response;
    });
}

const findAllWorkspaces = async () => {
    return RestClient.handleResponse(() => {
        const finalURL = ClientConfig.URL_RESOURCE_SERVER + ClientConfig.NAMESPACE_WORKSPACE;
        const response = fetch(finalURL, {
            method: "GET",
            headers: {
                "Authorization": "Bearer " + StorageService.getAccessToken()
            }
        });

        return response;
    });
}


const deleteWorkspaceById = async workspaceId => {
    return RestClient.handleResponse(() => {
        const finalURL = ClientConfig.URL_RESOURCE_SERVER + ClientConfig.NAMESPACE_WORKSPACE + workspaceId;
        const response = fetch(finalURL, {
            method: "DELETE",
            headers: {
                "Authorization": "Bearer " + StorageService.getAccessToken()
            }
        });

        return response;
    });
}

const findUserWorkspaces = async () => {
    return RestClient.handleResponse(() => {
        const finalURL = ClientConfig.URL_RESOURCE_SERVER + ClientConfig.NAMESPACE_WORKSPACE + "me";
        const response = fetch(finalURL, {
            method: "GET",
            headers: {
                "Authorization": "Bearer " + StorageService.getAccessToken()
            }
        });

        return response;
    });
}

const findMemberRecordTypeRecordView = async (workspaceId) => {

    return RestClient.handleResponse(() => {
        const finalURL = ClientConfig.URL_RESOURCE_SERVER + ClientConfig.NAMESPACE_WORKSPACE + workspaceId + "/member";
        const response = fetch(finalURL, {
            method: "GET",
            headers: {
                "Authorization": "Bearer " + StorageService.getAccessToken()
            }
        });

        return response;
    });
}

const findWorkspaceById = (workspaceId) => {
    return RestClient.handleResponse(() => {
        const finalURL = ClientConfig.URL_RESOURCE_SERVER + ClientConfig.NAMESPACE_WORKSPACE + workspaceId;
        const response = fetch(finalURL, {
            method: "GET",
            headers: {
                "Authorization": "Bearer " + StorageService.getAccessToken()
            }
        });

        return response;
    });
}

const getFormPaymentSetup = async (workspaceId) => {
    return RestClient.handleResponse(() => {
        const finalURL = ClientConfig.URL_RESOURCE_SERVER + ClientConfig.NAMESPACE_WORKSPACE + workspaceId + "/formpayment";
        const response = fetch(finalURL, {
            method: "GET",
            headers: {
                "Authorization": "Bearer " + StorageService.getAccessToken()
            }
        });
        return response;
    });
}

const updateFormPaymentSetup = async (workspaceId, formPaymentSetup) => {
    return RestClient.handleResponse(() => {
        const finalURL = ClientConfig.URL_RESOURCE_SERVER + ClientConfig.NAMESPACE_WORKSPACE + workspaceId + "/formpayment";
        const response = fetch(finalURL, {
            method: "PUT",
            body: JSON.stringify(formPaymentSetup),
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + StorageService.getAccessToken()
            }
        });

        return response;
    });
}

const getPaymentSetup = async (workspaceId) => {
    return RestClient.handleResponse(() => {
        const finalURL = ClientConfig.URL_RESOURCE_SERVER + ClientConfig.NAMESPACE_WORKSPACE + workspaceId + "/payment";
        const response = fetch(finalURL, {
            method: "GET",
            headers: {
                "Authorization": "Bearer " + StorageService.getAccessToken()
            }
        });
        return response;
    });
}

const updatePaymentSetup = async (workspaceId, paymentSetup) => {
    return RestClient.handleResponse(() => {
        const finalURL = ClientConfig.URL_RESOURCE_SERVER + ClientConfig.NAMESPACE_WORKSPACE + workspaceId + "/payment";
        const response = fetch(finalURL, {
            method: "PUT",
            body: JSON.stringify(paymentSetup),
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + StorageService.getAccessToken()
            }
        });

        return response;
    });
}

const findFormRecordTypeRecordView=(workspaceId) =>{
    return RestClient.handleResponse(() => {
        const finalURL = ClientConfig.URL_RESOURCE_SERVER + ClientConfig.NAMESPACE_WORKSPACE + workspaceId + "/formpayment";
        const response = fetch(finalURL, {
            method: "GET",
            headers: {
                "Authorization": "Bearer " + StorageService.getAccessToken()
            }
        });
        return response;
    });
}

export {
    addWorkspace,
    findAllWorkspaces,
    findMemberRecordTypeRecordView,
    findWorkspaceById,
    findUserWorkspaces,
    deleteWorkspaceById,
    updateWorkspace,
    getFormPaymentSetup,
    updateFormPaymentSetup,
    findFormRecordTypeRecordView,
    getPaymentSetup,
    updatePaymentSetup
}
