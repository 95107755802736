import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";

import CrossaPageTemplate from "components/CrossaPageTemplate.js";
import CrossaTable from "components/CrossaTable.js"
import { FORM_ACTION } from "util/Constants.js";
import SweetAlert from 'react-bootstrap-sweetalert';
import { FormattedMessage } from 'react-intl';
import Moment from 'react-moment';
import { DATE_FORMAT } from "util/DateUtil";
import { getAllUsersHandler, deleteUserHandler } from "handlers/adminHandlers/userHandlers/AllUsersHandlers";
import AdminNavbar from "../../../components/AdminNavbar";

function AllUsersPage() {

    const [state, setState] = useState({
        users: [],
        alert: null
    })
    const history = useHistory();

    useEffect(() => {
        getPageContent();
    }, []);

    const getPageContent = async () => {
        getAllUsersHandler().then(response => {
            if (response.ok) {
                setState({
                    ...state,
                    ...response.data
                })
            }
        })
    }

    const onDeleteUser = async id => {
        deleteUserHandler(id).then(response => {
            if (response.ok) {
                setState({
                    ...state,
                    ...response.data,
                    alert:null
                })
            }
        })
    }

    const deleteAlert = (id) => {
        setState({
            ...state,
            alert:
                <SweetAlert
                    type="danger"
                    title=""
                    style={{ display: "block", marginTop: "-100px", fontSize: "13px" }}
                    btnSize="md"
                    confirmBtnBsStyle="danger"
                    cancelBtnBsStyle="default"
                    showCancel
                    cancelBtnText={<FormattedMessage id="Button.cancel" />}
                    confirmBtnText={<FormattedMessage id="Button.delete" />}
                    openAnim="false"
                    onConfirm={() => onDeleteUser(id)}
                    onCancel={() => hideAlert()}
                    allowEscape
                    closeOnClickOutside
                    customClass="crossa-alert"

                > <FormattedMessage id="Alert.deleteUser" />
                </SweetAlert>
        });
    }

    const hideAlert = () => {
        setState({
            ...state,
            alert:null
        });
    }

    const toEditUserPage = userId => {
        history.push({
            pathname: "/admin/users/" + userId + "/edit",
            state: {
                "userId": userId,
                "action": FORM_ACTION.EDIT,
            },
        });
    }

    const toAddUserPage = () => {
        history.push({
            pathname: "/admin/users/add",
            state: {
                "action": FORM_ACTION.INSERT,
            },
        });
    }

    const columns = [
        {
            Header: () => (
                <div>#</div>),
            accessor: "index",
        },
        {
            Header: () => (
                <div><FormattedMessage id="Name"/></div>),
            accessor: "name",
        },
        {
            Header: () => (
                <div><FormattedMessage id="Email"/></div>),
            accessor: "email",
        },
        {
            Header: () => (
                <div><FormattedMessage id="CreatedBy"/></div>),
            accessor: "createdBy",
        },
        {
            Header: () => (
                <div><FormattedMessage id="LastLogin"/></div>),
            accessor: "lastLogin",
        },
        {
            Header: () => (
                <div><FormattedMessage id="Actions"/></div>),
            accessor: "actions",
            sticky:"right"
        },
    ];


    const createRow = (prop,index) => {
        return {
            index:index+1,
            id:prop.id,
            name: prop.name,
            email: prop.email,
            createdBy: prop.createdBy.name,
            lastLogin: prop.lastLogin != null ?
                <Moment
                    format={DATE_FORMAT.DEFAULT}
                    date={prop.lastLogin}
                />
                : <FormattedMessage id="Never"/>,
        }
    };

    return (
        <>
            {state.alert}
            <AdminNavbar
                title={<FormattedMessage id="Users" />}
                hasRightBtn={true}
                rightBtnHasIcon={true}
                rightBtnName={<FormattedMessage id="Button.addUser" />}
                rightButtonAction={toAddUserPage}
            />
            <CrossaPageTemplate>
                <CrossaTable
                    columns={columns}
                    data={state.users.map((user,index) => createRow(user,index))}
                    handleEdit={toEditUserPage}
                    handleDelete={deleteAlert}
                />
            </CrossaPageTemplate>
        </>
    );
}

export default AllUsersPage;
