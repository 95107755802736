import * as MemberService from "service/MemberService";
import * as WorkspaceRoleService from "service/WorkspaceRoleService";
import * as StorageService from "service/StorageService.js";

const isSuperAdmin = () => {
    if (StorageService.getIsSuperAdmin() !== null) {
        if (StorageService.getIsSuperAdmin() === "true") {
            return true;
        }
    }
    return false;
}

const getAllMembersHandler = async (wid) => {

    let selfMemberId = null;
    let members = [];
    let workspaceRoles = [];
    let ok = true;

    if (!isSuperAdmin()) {
        const responseSelfMember = await MemberService.findMemberMe(wid);
        ok = ok && responseSelfMember.ok;
        if (responseSelfMember.ok) {
            selfMemberId = responseSelfMember.data.id;
        }
    }

    const responseAllMembers = await MemberService.findAllMembers(wid);
    if (responseAllMembers.ok) {
        members = responseAllMembers.data.records;
    }
    const responseWorkspaceRoles = await WorkspaceRoleService.findAllWorkspaceRoles(wid);
    if (responseWorkspaceRoles) {
        workspaceRoles = responseWorkspaceRoles.data;
    }

    return {
        data: {
            members: members,
            selfMemberId: selfMemberId,
            workspaceRoles: workspaceRoles
        },
        ok: ok && responseAllMembers.ok && responseWorkspaceRoles.ok
    }
}

const deleteMemberHandler = async (wid, id, selfMemberId) => {

    let members = [];
    const responseDelete = await MemberService.deleteMember(wid, id);
    let ok = responseDelete.ok;
    if (responseDelete.ok) {
        if (id !== selfMemberId) {
            const responseAllMembers = await MemberService.findAllMembers(wid);
            ok = ok && responseAllMembers.ok;
            if (responseAllMembers.ok) {
                members = responseAllMembers.data.records;
            }
        }
    }
    return {
        data: {
            members: members,
        },
        ok: ok
    }
}

export {
    getAllMembersHandler,
    deleteMemberHandler
}
