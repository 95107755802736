import React from "react";
import {FormattedMessage} from 'react-intl';

const EmptyRuleComponent = () => {

    return (
        <div className="info-message neutral">
            <FormattedMessage id="Validation.noRulesYet"/>
        </div>
    )
}

export default EmptyRuleComponent;