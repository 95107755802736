import * as ClientConfig from "config/ClientConfig.js";
import * as StorageService from "service/StorageService.js"

function createBodyforLogin(params) {
    let formBody = [];
    for (let property in params) {
        let encodedKey = encodeURIComponent(property);
        let encodedValue = encodeURIComponent(params[property]);
        formBody.push(encodedKey + "=" + encodedValue);
    }
    formBody = formBody.join("&");

    return formBody;
}

function getAuthorization() {
    const string = ClientConfig.CLIENT_ID + ":" + ClientConfig.CLIENT_SECRET;
    return "Basic " + btoa(string);
}

const login = async (username, password) => {
    const finalURL = ClientConfig.URL_LOGIN + ClientConfig.URL_GET_TOKEN;
    const params = {
        username: username,
        password: password,
        grant_type: "password"
    };
    const requestBody = createBodyforLogin(params);

    return fetch(finalURL, {
        method: "POST",
        body: requestBody,
        headers: {
            "Content-Type": "application/x-www-form-urlencoded",
            "Authorization": getAuthorization()
        }
    })
        .then(async (response) => {
            if (response.ok) {
                return response.json().then(
                    responseJson => {
                        return {
                            ok: true,
                            data: responseJson,
                        }
                    }
                )
            }
            return {
                ok: false,
                message: "LoginError.BadCredentials"
            }
        });
}

const logout = () => {
    StorageService.clearStorage();
}

export {
    logout,
    login,
    createBodyforLogin,
    getAuthorization,
}
