/**
 * A function which checks if there is a true value in errors object
 *
 * @param {Object} errors   Contains errors. Example: {"name":true, "description": false} => there is an error for name field
 */
const checkIfError = errors => {
    for (const error in errors) {
        if(typeof errors[error]==="object"){
            for(const innerError in errors[error]){
                if(checkIfError(errors[error])===true){
                    return true;
                }
            }
        } else if (errors[error] === true) {
            return true;
        }
    }

    return false;
}

export {
    checkIfError
}