import * as ClientConfig from "config/ClientConfig.js";
import * as StorageService from "service/StorageService.js";
import * as RestClient from "util/api/restClient"

const findAllUsers = async () => {
    return RestClient.handleResponse(() => {
        const finalURL = ClientConfig.URL_RESOURCE_SERVER + "api/users";
        const response = fetch(finalURL, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + StorageService.getAccessToken()
            }
        });
        return response;
    });
}

const createUser = (user) => {
    return RestClient.handleResponse(() => {
        const finalURL = ClientConfig.URL_RESOURCE_SERVER + ClientConfig.NAMESPACE_USERS;
        const response = fetch(finalURL, {
            method: "POST",
            body: JSON.stringify(user),
            headers: {
                "Authorization": "Bearer " + StorageService.getAccessToken(),
                "Content-Type": "application/json"
            }
        });

        return response;
    });
}

const findUser = (id) => {
    return RestClient.handleResponse(() => {
        const finalURL = ClientConfig.URL_RESOURCE_SERVER + ClientConfig.NAMESPACE_USERS + id;
        const response = fetch(finalURL, {
            method: "GET",
            headers: {
                "Authorization": "Bearer " + StorageService.getAccessToken(),
                "Content-Type": "application/json"
            }
        });

        return response;
    });
}


const updateUser = (user) => {
    return RestClient.handleResponse(() => {
        const finalURL = ClientConfig.URL_RESOURCE_SERVER + ClientConfig.NAMESPACE_USERS + user.id;
        const response = fetch(finalURL, {
            method: "PUT",
            body: JSON.stringify(user),
            headers: {
                "Authorization": "Bearer " + StorageService.getAccessToken(),
                "Content-Type": "application/json"
            },
        });

        return response;
    });
}


const deleteUser = (id) => {
    return RestClient.handleResponse(() => {
        const finalURL = ClientConfig.URL_RESOURCE_SERVER + ClientConfig.NAMESPACE_USERS + id;
        const response = fetch(finalURL, {
            method: "DELETE",
            headers: {
                "Authorization": "Bearer " + StorageService.getAccessToken()
            }
        });

        return response;
    });
}

const findSelf = async () => {
    return RestClient.handleResponse(() => {
        const finalURL = ClientConfig.URL_RESOURCE_SERVER + ClientConfig.NAMESPACE_USERS + "me";
        const response = fetch(finalURL, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + StorageService.getAccessToken()
            }
        });
        return response;
    });
}

const updateSelf = (user) => {
    return RestClient.handleResponse(() => {
        const finalURL = ClientConfig.URL_RESOURCE_SERVER + ClientConfig.NAMESPACE_USERS + "me";
        const response = fetch(finalURL, {
            method: "PUT",
            body: JSON.stringify(user),
            headers: {
                "Authorization": "Bearer " + StorageService.getAccessToken(),
                "Content-Type": "application/json"
            }
        });

        return response;
    });
}

const changePassword = (password) => {
    return RestClient.handleResponse(() => {
        const finalURL = ClientConfig.URL_RESOURCE_SERVER + ClientConfig.NAMESPACE_USERS + "password";
        const response = fetch(finalURL, {
            method: "PUT",
            body: JSON.stringify(password),
            headers: {
                "Authorization": "Bearer " + StorageService.getAccessToken(),
                "Content-Type": "application/json"
            }
        });

        return response;
    });
}

const updateLastLogin = async () => {
    return RestClient.handleResponse(() => {
        const finalURL = ClientConfig.URL_RESOURCE_SERVER + ClientConfig.NAMESPACE_USERS + "login";
        const response = fetch(finalURL, {
            method: "PUT",
            body: JSON.stringify({}),
            headers: {
                "Authorization": "Bearer " + StorageService.getAccessToken(),
                "Content-Type": "application/json"
            }
        });

        return response;
    });
}

export {
    findAllUsers,
    createUser,
    updateUser,
    deleteUser,
    findUser,
    findSelf,
    updateSelf,
    changePassword,
    updateLastLogin
}
