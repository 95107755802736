import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import CrossaPageTemplate from "components/CrossaPageTemplate.js";
import * as Validations from "validations/admin/user/UserValidations.js";
import Footer from "components/Footer.js"
import {CODE_ERROR, FORM_ACTION, VALIDATIONS} from "util/Constants.js";
import { FormattedMessage } from 'react-intl';
import { Form, } from "react-bootstrap";
import { getUserByIdHandler, saveUserHandler } from "handlers/adminHandlers/userHandlers/SaveUserHandler";
import AdminNavbar from "../../../components/AdminNavbar";
import NotificationAlert from "react-notification-alert";
import {getNotifyErrorOptions} from "util/Util";
import SweetAlert from "react-bootstrap-sweetalert";
function SaveUserPage(props) {
    const history = useHistory();
    const information = props.location.state || null;

    const [state, setState] = useState({
        user: {
            "email": "",
            "name": "",
            "roles": [],
        },
        errors: {
            "name": false,
            "description": false,
        },
        alert:null
    });

    useEffect(() => {
        const userId = props.match.params.uid;

        if (information == null) {
            history.push("/admin/users");
            return;
        }
        if (information?.action === FORM_ACTION.EDIT) {
            if (!userId) {
                history.push("/admin/users");
                return;
            }

            getPageContent(userId);
        }
    }, []);


    const getPageContent = async (userId) => {
        getUserByIdHandler(userId).then(response => {
            if (response.ok) {
                setState({
                    ...state,
                    ...response.data
                });
            }
        })
    }

    const hideAlert = () => {
        setState({
            ...state,
            alert: null
        });
    }

    const handleInputChange = event => {

        let user = {
            ...state.user,
            [event.target.name]: event.target.value,
        }

        let newErrors = { ...state.errors };
        if (!!state.errors[event.target.name]) {
            newErrors = {
                ...newErrors,
                [event.target.name]: false,
            }
        }
        setState({
            ...state,
            user: user,
            errors: newErrors
        });

    }


    const handleSubmit = event => {
        event.preventDefault();
    }

    const saveUser = async () => {

        const finalUser = {
            ...state.user,
            "roles": ["crossa_default"]
        }

        const [newErrors, existErrors] = Validations.addUserValidations(state.user, { ...state.errors });
        setState({
            ...state,
            errors: newErrors
        });

        if (existErrors) {
            let options=getNotifyErrorOptions("bc");
            notificationAlertRef.current.notificationAlert(options);
            return;
        }

        saveUserHandler(finalUser, information.action).then(response => {
            if (response.ok) {
                history.push("/admin/users");
            } else{
                if(response.errorCode===CODE_ERROR.DUPLICATE_USER){
                    showAlert("Validation.userExists");
                }
            }
        })
    }

    const showAlert = (message) => {
        setState({
            ...state,
            alert: <SweetAlert
                type="danger"
                title=""
                style={{ display: "block", marginTop: "-100px", fontSize: "13px" }}
                btnSize="md"
                confirmBtnBsStyle="default"
                confirmBtnText={<FormattedMessage id="Button.ok" />}
                openAnim="false"
                onCancel={() => hideAlert()}
                onConfirm={() => hideAlert()}
                allowEscape
                closeOnClickOutside
                customClass="crossa-alert"
            >  <FormattedMessage id={message}/>
            </SweetAlert>
        });
    }

    const notificationAlertRef = React.useRef(null);

    return (
        <>
            <div className="rna-container">
                <NotificationAlert ref={notificationAlertRef} />
            </div>
            {state.alert}
            <AdminNavbar
                title={<FormattedMessage id={information?.action === FORM_ACTION.EDIT ? "EditUser" : "NewUser"} />}
                hasBackBtn={true}
            />
            <CrossaPageTemplate>
                <Form onSubmit={handleSubmit}>
                    <Form.Group>
                        <label><FormattedMessage id="Name"/></label>

                        <FormattedMessage id="Name">
                            {placeholder =>
                                <Form.Control
                                    name="name"
                                    placeholder={placeholder}
                                    type="text"
                                    value={state.user.name || ""}
                                    onChange={handleInputChange}
                                    isInvalid={state.errors.name}
                                    className={state.errors.name && "has-errors"}

                                />
                            }
                        </FormattedMessage>
                        <Form.Control.Feedback type="invalid">
                            <FormattedMessage id="Validation.cannotBeEmptyAndParamMinParamMax" values={{ paramMin: VALIDATIONS.MIN_LENGTH_DEFAULT, paramMax: VALIDATIONS.MAX_LENGTH_DEFAULT }} />
                        </Form.Control.Feedback>
                    </Form.Group>


                    {
                        information?.action === FORM_ACTION.EDIT ?
                            null :
                            <Form.Group>
                                <label>Email</label>
                                <Form.Control
                                    name="email"
                                    placeholder="Email"
                                    type="email"
                                    value={state.user.email || ""}
                                    onChange={handleInputChange}
                                    isInvalid={state.errors.email}
                                    className={state.errors.email && "has-errors"}
                                />
                                <Form.Control.Feedback type="invalid">
                                    <FormattedMessage id={"Validation.emailInvalid"}/>
                                </Form.Control.Feedback>
                            </Form.Group>

                    }
                </Form>
            </CrossaPageTemplate>
            <Footer
                save={saveUser}
                cancel={() => history.goBack()}
                isEnable={true} />
        </>
    );
}

export default SaveUserPage;
