import React, {useEffect, useState} from "react";
import Select from 'react-select'
import CrossaPageTemplate from "components/CrossaPageTemplate.js";

import { FormattedMessage } from 'react-intl';
import { Form } from "react-bootstrap";
import * as Validations from "validations/workspaceAdmin/role/RoleValidations.js";
import {getNotifyErrorOptions} from "util/Util";
import {VALIDATIONS} from "../../../../util/Constants";

function RoleEditor(props) {

    const initialState = {
        menuOptions: [],
        role: {
            "name": "",
            "description": "",
            "mid": "",
            "menuName": "",
        },
        errors: {
            "name": false,
            "description": false,
            "menu":false,
        }
    }
    const [state, setState] = useState(initialState);

    const roleId = props.role?.id;
    const wid = props.wid;

    useEffect(() => {
        if(props.role) {
            setState({
                ...state,
                errors: initialState.errors,
                role: props.role,
                menuOptions: props.menuOptions
            })
        } else {
            setState({
                ...initialState,
                role: {
                    ...state.role,
                    mid: props.stateFromProps?.mid ? props.menuOptions.find(menu => menu.mid === props.stateFromProps.value)?.value : "",
                    menuName: props.stateFromProps?.mid ? props.menuOptions.find(menu => menu.mid === props.stateFromProps.value)?.label : ""
                },
                menuOptions: props.menuOptions
            })
        }

    }, [props]);

    const saveWorkspaceRole = async () => {
        const finalRole = {
            ...state.role,
            "wid": wid,
        }

        const [newErrors, existErrors] = Validations.addRoleValidations(state.role, { ...state.errors });

        setState({
            ...state,
            errors: newErrors
        })
        if (existErrors) {
            let options=getNotifyErrorOptions("bc");
            props.notificationAlertRef.current.notificationAlert(options);
            return;
        }
        props.setSaveDisabled(true);
        props.saveWorkspaceRole(roleId, finalRole, finalRole);
    }

    const handleInput = event => {

        let newErrors = { ...state.errors };

        if (!!state.errors[event.target.name]) {
            newErrors = {
                ...newErrors,
                [event.target.name]: false,
            };
        }
        setState({
            ...state,
            role: {
                ...state.role,
                [event.target.name]: event.target.value
            },
            errors: newErrors
        });
    }


    const handleSelect = (event) => {
        let newErrors = { ...state.errors };

        if (!!state.errors["menuName"]) {
            newErrors = {
                ...newErrors,
                ["menuName"]: false,
            };
        }
        setState({
            ...state,
            role:{
                ...state.role,
                "menuName": event.label,
                "mid": event.value
            },
            errors:newErrors
        });
    }

    return (
        <CrossaPageTemplate
            hasFooter={true}
            saveDisabled={props.saveDisabled}
            closeSlidingPanel={props.closeSlidingPanel}
            saveChanges={saveWorkspaceRole}
        >
            <Form.Group>
                <label><FormattedMessage id="Name" /></label>
                <FormattedMessage id="Name">
                    {placeholder =>
                        <Form.Control
                            name="name"
                            placeholder={placeholder}
                            value={state.role.name || ""}
                            type="text"
                            onChange={handleInput}
                            isInvalid={state.errors.name}
                            className={state.errors.name && "has-errors"}
                        ></Form.Control>
                    }
                </FormattedMessage>
                <Form.Control.Feedback type="invalid">
                    <FormattedMessage id={"Validation.cannotBeEmptyAndParamMinParamMax"} values={{paramMin:VALIDATIONS.MIN_LENGTH_DEFAULT, paramMax:VALIDATIONS.MAX_LENGTH_DEFAULT}}/>
                </Form.Control.Feedback>
            </Form.Group>
            <Form.Group>
                <label><FormattedMessage id="Description" /></label>
                <FormattedMessage id="Description">
                    {placeholder =>
                        <Form.Control
                            as="textarea"
                            name="description"
                            placeholder={placeholder}
                            value={state.role.description || ""}
                            type="text"
                            onChange={handleInput}
                            isInvalid={state.errors.description}
                            className={state.errors.description && "has-errors"}
                        ></Form.Control>
                    }
                </FormattedMessage>
                <Form.Control.Feedback type="invalid">
                    <FormattedMessage id={"Validation.cannotBeEmptyAndParamMin"} values={{paramMin:VALIDATIONS.MIN_LENGTH_DEFAULT}}/>
                </Form.Control.Feedback>
            </Form.Group>
            <Form.Group>
                <label><FormattedMessage id="Menu" /></label>
                <FormattedMessage id="Menu">
                    {placeholder =>
                        <Select onChange={handleSelect}
                                className={state.errors.menuName ? "react-select primary has-errors" : "react-select primary"}
                                classNamePrefix="react-select"
                                options={state.menuOptions}
                                value={state.role.menuName && state.role.mid ? {
                                    label: state.role.menuName,
                                    value: state.role.mid
                                } : ""}
                                placeholder={placeholder}
                                noOptionsMessage={() => <FormattedMessage id="Placeholder.noOptions"/>}
                        />
                    }
                </FormattedMessage>
                {
                    state.errors.menuName && <div className={"error-message"}>
                        <FormattedMessage id={"Validation.selectOneOption"}/>
                    </div>
                }
            </Form.Group>
        </CrossaPageTemplate>
    );
}

export default RoleEditor;
