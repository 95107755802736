import React, {useContext} from "react";
import {SORT_DIRECTION, WIDGET_OPERATOR} from "../../../../util/Constants";

const WidgetsContext = React.createContext();
const WidgetsUpdateContext = React.createContext();
const SingleWidgetUpdateContext = React.createContext();

export const useWidgets = () => useContext(WidgetsContext);
export const useWidgetsUpdate = () => useContext(WidgetsUpdateContext);
export const useWidgetChange = () => useContext(SingleWidgetUpdateContext);


function WidgetContext(props){

    const updateWidgets = (newWidgets,newErrors,newIndex) => {
        props.setWidgets(newWidgets,newErrors,newIndex);
    }

    const changeWidgetTitle = (widgetIndex, newWidgetTitle) => {
        const newWidgets = [...props.widgets];
        newWidgets[widgetIndex] = {...newWidgets[widgetIndex], "title": newWidgetTitle};
        let newErrors=props.errors;
        if(newErrors.dashboard?.widgets?.[widgetIndex]?.title){
            newErrors.dashboard.widgets[widgetIndex].title=false;
        }
        props.setWidgets(newWidgets,newErrors);
    }

    const changeWidgetDescription = (widgetIndex, newWidgetDescription) => {
        const newWidgets = [...props.widgets];
        newWidgets[widgetIndex] = {...newWidgets[widgetIndex], "description": newWidgetDescription};
        let newErrors=props.errors;
        if(newErrors.dashboard?.widgets?.[widgetIndex]?.description){
            newErrors.dashboard.widgets[widgetIndex].description=false;
        }
        props.setWidgets(newWidgets,newErrors);
    }

    const changeWidgetType = (widgetIndex, newWidgetType) => {
        const newWidgets = [...props.widgets];
        newWidgets[widgetIndex] = {...newWidgets[widgetIndex],
            "type": newWidgetType,
            "vid":null,
            "displayFields": [],
            "groupingField":null,
            "operatorField":null,
            "operator":null,
            "defaultSorting":null,
            "sortingField":null,
            "sortDirection":null
        };
        let newErrors=props.errors;
        if(newErrors.dashboard?.widgets?.[widgetIndex]){
            newErrors.dashboard.widgets[widgetIndex]={};
        }
        props.setWidgets(newWidgets,newErrors);
    }

    const changeShowWidget = (widgetIndex, showWidget) => {
        const newWidgets = [...props.widgets];
        newWidgets[widgetIndex] = {...newWidgets[widgetIndex], "visible": showWidget};
        props.setWidgets(newWidgets);
    }

    const changeShowAfterLoginWidget = (widgetIndex, showAfterLoginWidget) => {
        const newWidgets = [...props.widgets];
        newWidgets[widgetIndex] = {...newWidgets[widgetIndex], "showOnlyAfterLogin": showAfterLoginWidget};
        props.setWidgets(newWidgets);
    }

    const changeWidgetView = (widgetIndex, newWidgetView) => {
        const newWidgets = [...props.widgets];
        newWidgets[widgetIndex] = {...newWidgets[widgetIndex],
            "vid": newWidgetView,
            "displayFields": [],
            "groupingField":null,
            "operatorField":null,
            "operator":null
        };
        let newErrors=props.errors;
        if(newErrors.dashboard?.widgets?.[widgetIndex]?.vid){
            newErrors.dashboard.widgets[widgetIndex].vid=false;
        }
        props.setWidgets(newWidgets,newErrors)
    }

    const changeRowsShown = (widgetIndex, newWidgetRowsShown) => {
        const newWidgets = [...props.widgets];
        newWidgets[widgetIndex] = {...newWidgets[widgetIndex], "rows": newWidgetRowsShown};
        let newErrors=props.errors;
        if(newErrors.dashboard?.widgets?.[widgetIndex]?.rows){
            newErrors.dashboard.widgets[widgetIndex].rows=false;
        }
        props.setWidgets(newWidgets,newErrors);
    }

    const changeDisplayFields = (widgetIndex, newDisplayFieldId) => {
        const newWidgets = [...props.widgets];
        const displayFields = [...newWidgets[widgetIndex].displayFields];
        const displayFieldIndex = displayFields.findIndex(element => element === newDisplayFieldId);
        if (displayFieldIndex !== -1) {
            displayFields.splice(displayFieldIndex, 1);
        } else {
            displayFields.push(newDisplayFieldId);
        }
        newWidgets[widgetIndex] = {...newWidgets[widgetIndex], "displayFields": [...displayFields]};
        let newErrors=props.errors;
        if(newErrors.dashboard?.widgets?.[widgetIndex]?.displayFields){
            newErrors.dashboard.widgets[widgetIndex].displayFields=false;
        }
        props.setWidgets(newWidgets,newErrors);
    }

    const changeWidgetEnableDefaultSorting = (widgetIndex, newWidgetEnableSorting) => {
        const newWidgets = [...props.widgets];
        newWidgets[widgetIndex] = {...newWidgets[widgetIndex], "defaultSorting": newWidgetEnableSorting,"sortDirection":SORT_DIRECTION.ASC};
        let newErrors=props.errors;
        if(newErrors.dashboard?.widgets?.[widgetIndex]?.sortingField){
            newErrors.dashboard.widgets[widgetIndex].sortingField=false;
        }
        props.setWidgets(newWidgets,newErrors)
    }

    const changeWidgetSortingField = (widgetIndex, newWidgetSortingField) => {
        const newWidgets = [...props.widgets];
        newWidgets[widgetIndex] = {...newWidgets[widgetIndex], "sortingField": newWidgetSortingField};
        let newErrors=props.errors;
        if(newErrors.dashboard?.widgets?.[widgetIndex]?.sortingField){
            newErrors.dashboard.widgets[widgetIndex].sortingField=false;
        }
        props.setWidgets(newWidgets,newErrors)
    }

    const changeWidgetSortDirection = (index, newWidgetSortDirection) => {
        const newWidgets = [...props.widgets];
        newWidgets[index] = {...newWidgets[index], "sortDirection": newWidgetSortDirection};
        props.setWidgets(newWidgets)
    }

    const changeWidgetGroupingField = (widgetIndex, newWidgetGroupingField) => {
        const newWidgets = [...props.widgets];
        newWidgets[widgetIndex] = {...newWidgets[widgetIndex], "groupingField": newWidgetGroupingField};
        let newErrors=props.errors;
        if(newErrors.dashboard?.widgets?.[widgetIndex]?.groupingField){
            newErrors.dashboard.widgets[widgetIndex].groupingField=false;
        }
        props.setWidgets(newWidgets,newErrors)
    }

    const changeWidgetOperatorField = (widgetIndex, newWidgetOperatorField) => {
        const newWidgets = [...props.widgets];
        newWidgets[widgetIndex] = {...newWidgets[widgetIndex], "operatorField": newWidgetOperatorField};
        let newErrors=props.errors;
        if(newErrors.dashboard?.widgets?.[widgetIndex]?.operatorField){
            newErrors.dashboard.widgets[widgetIndex].operatorField=false;
        }
        props.setWidgets(newWidgets,newErrors)
    }

    const changeWidgetOperator = (widgetIndex, newWidgetOperator) => {
        const newWidgets = [...props.widgets];
        newWidgets[widgetIndex] = {...newWidgets[widgetIndex], "operator": newWidgetOperator};
        if(newWidgetOperator===WIDGET_OPERATOR.ROW_COUNT){
            newWidgets[widgetIndex] = {...newWidgets[widgetIndex], "operatorField":""};
        }
        let newErrors=props.errors;
        if(newErrors.dashboard?.widgets?.[widgetIndex]?.operator){
            newErrors.dashboard.widgets[widgetIndex].operator=false;
        }
        if(newErrors.dashboard?.widgets?.[widgetIndex]?.operatorField){
            newErrors.dashboard.widgets[widgetIndex].operatorField=false;
        }
        props.setWidgets(newWidgets,newErrors)
    }

    const deleteWidget = (widgetIndex) => {
        const newWidgets = [...props.widgets];
        newWidgets.splice(widgetIndex, 1);
        let newErrors=props.errors;
        if(newErrors?.dashboard?.widgets?.[widgetIndex]){
            newErrors.dashboard.widgets[widgetIndex]=null;
        }
        props.setWidgets(newWidgets,newErrors,null);
    }

    const changeWidgetFunctions = {
        "changeWidgetType": changeWidgetType,
        "changeShowWidget": changeShowWidget,
        "changeWidgetTitle": changeWidgetTitle,
        "changeWidgetDescription": changeWidgetDescription,
        "changeShowAfterLoginWidget": changeShowAfterLoginWidget,
        "changeWidgetView": changeWidgetView,
        "changeRowsShown": changeRowsShown,
        "changeDisplayFields": changeDisplayFields,
        "changeWidgetEnableDefaultSorting": changeWidgetEnableDefaultSorting,
        "changeWidgetSortingField": changeWidgetSortingField,
        "changeWidgetSortDirection": changeWidgetSortDirection,
        "changeWidgetGroupingField": changeWidgetGroupingField,
        "changeWidgetOperatorField": changeWidgetOperatorField,
        "changeWidgetOperator": changeWidgetOperator,
        "deleteWidget": deleteWidget,
    };

    return (
        <WidgetsUpdateContext.Provider value={updateWidgets}>
            <WidgetsContext.Provider value={props.widgets}>
                <SingleWidgetUpdateContext.Provider value={changeWidgetFunctions}>
                    {props.children}
                </SingleWidgetUpdateContext.Provider>
            </WidgetsContext.Provider>
        </WidgetsUpdateContext.Provider>
    );
}
export default WidgetContext;