import * as Auth from "../util/auth/auth";
import * as StorageService from "../service/StorageService.js";
import * as UserService from "../service/UserService.js";
import * as MenuService from "../service/MenuService";

const selectUserWorkspaces = (workspaces) => {
    const ret = {
        workspaceId: null,
        workspaceName: "",
        workspaces: []
    };
    let workspaceId = null;
    let name = "";

    if (workspaces.length > 0) {
        workspaceId = workspaces[0].id;
        workspaces.forEach((entry) => {
            if (entry.id === workspaceId) {
                name = entry.name;
            }
        });
        ret.workspaceName = workspaces[0].name;
        ret.workspaceId = workspaceId;
        ret.workspaces = workspaces;
    } else {
        console.log("No workspaces found for selected user");
    }

    return ret;
}

const loginHandler=async(user)=>{
    let userWorkspaces={};
    const response = await Auth.login(user.username, user.password);
    if (response.ok) {
        StorageService.setAccessToken(response.data.access_token)
        StorageService.setRefreshToken(response.data.refresh_token)
        StorageService.setUsername(user.username)

        const updateLastLogResponse = await UserService.updateLastLogin();
        const isActiveMap= {};
        updateLastLogResponse.data.workspaces.forEach(w => {
            isActiveMap[w.id] = w.isActive
        });
        StorageService.setIsActive(isActiveMap);
        userWorkspaces = selectUserWorkspaces(updateLastLogResponse.data.workspaces);
        const userUpdated = updateLastLogResponse.data.user;
        StorageService.setIsSuperAdmin(userUpdated.roles.includes("crossa_admin"));
    }

    return{
        ok:response.ok,
        data:{
            userWorkspaces:userWorkspaces
        },
        message:response.message
    };
}

const getMemberMenuHandler = async (wid) =>{
    const response = await MenuService.findMemberMenuForMe(wid);
    let menus;
    if (response.ok) {
        menus = response.data;
    } else {
        menus = [];
    }

    return {
        data:{
            menus:menus,
        },
        ok:response.ok
    }
}

export {
    loginHandler,
    getMemberMenuHandler
};
