import * as Validations from "validations/validations.js"

const addLoginValidations = (user, errors) => {


    if (user.username.length < 1) {
        errors = {
            ...errors,
            "username": true,
        }
    }
    if (user.password.length < 1) {
        errors = {
            ...errors,
            "password": true,
        }
    }

    if (errors.errorMessage != null) {
        errors = {
            ...errors,
            "message": errors.errorMessage,
        }
    }

    const existErrors = Validations.checkIfError(errors);

    return [errors, existErrors];
}

export {
    addLoginValidations
}
