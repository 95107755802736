
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { RECORD_TYPE_TYPE } from "util/Constants";
import { FormattedMessage } from 'react-intl';
import { Button, Card, Col, Row, } from "react-bootstrap";
import RuleCard from "./components/rules/RuleCard.js";
import { addIconFullBg } from "../../../components/Icons";
import {
 getRecordTypeAndMemberRecordTypeByIdHandler,
} from "handlers/workspaceAdminHandlers/recordTypeHandlers/RulesRecordTypeHandlers.js";
import { saveRulesHandler } from "handlers/workspaceAdminHandlers/recordTypeHandlers/RulesRecordTypeHandlers.js";
import AdminNavbar from "components/AdminNavbar.js";
import CrossaPageTemplate from "components/CrossaPageTemplate.js";

function RulesRecordTypePage(props) {
    const history = useHistory();
    const wid = props.match.params.wid;
    const recordTypeId = props.match.params.rid;

    const [state, setState] = useState({
        rules: [],
        recordType: {
            "name": "",
            "description": "",
            "type": RECORD_TYPE_TYPE.REGULAR,
        },
        recordFieldsOfRecordTypeRef:[],
        currentMemberFields:{},
        errors: {
            "name": false,
            "description": false,
        },
    });

    useEffect(() => {
        getPageContent();
    }, [props.match.params.rid]);

    const getPageContent = async () => {
        getRecordTypeAndMemberRecordTypeByIdHandler(recordTypeId,wid).then(response => {
            setState({
                ...state,
                ...response.data,
            });
        });

    }

    const handleIsActiveChange = (ruleIndex) => {
        let newRules = [...state.rules];
        newRules[ruleIndex].isActive=!state.rules[ruleIndex].isActive;

        let newRecordType = {
            id: state.recordType.id,
            rules: [...state.recordType.rules]
        };

        newRecordType.rules[ruleIndex].isActive=newRules[ruleIndex].isActive;

        saveRulesHandler(newRecordType).then(response => {
            if (response.ok) {
               setState({
                   ...state,
                   recordType: {
                       ...state.recordType,
                       ...newRecordType
                   },
                   rules: newRules
               });
            }
        });
    }

    const editRule = (ruleIndex) => {
        history.push({
            pathname: "/workspace-admin/" + props.match.params.wid + "/record-types/" + recordTypeId + "/rules/" + ruleIndex + "/edit"
        });
    }

    const toAddRuleRecordTypePage = () => {
        history.push({
            pathname: "/workspace-admin/" + props.match.params.wid + "/record-types/" + recordTypeId + "/rules/add"
        });
    }

    return (
        <>
        <AdminNavbar
                title={<FormattedMessage id="Rules" />}
                hasBackBtn={true}
            />
           <CrossaPageTemplate>
            <Row className="top-row">
                <Col className="actions">
                    <Button
                        onClick={toAddRuleRecordTypePage}
                    >
                        <span className="icon">
                            {addIconFullBg}
                        </span>
                        <FormattedMessage id="Button.addRule" />
                    </Button>
                </Col>
            </Row>


            <Row>
                <Col md={12}>
                    <div className="stacked-form">
                        <Row>
                            <Col md={12}>
                                {state.rules?.length ?
                                    state.rules?.map((rule, index) => {
                                        return <RuleCard key={index}
                                                         wid={wid}
                                                         index={index}
                                                         isDefault={rule.isDefault}
                                                         rule={rule}
                                                         name={<FormattedMessage id="RuleWithParam" values={{param: (index + 1)}} />}
                                                         recordType={state.recordType}
                                                         editRule={editRule}
                                                         recordFieldsOfRecordTypeRef={state.recordFieldsOfRecordTypeRef}
                                                         currentMemberFields={state.currentMemberFields}
                                                         handleIsActiveChange={handleIsActiveChange}/>
                                    })
                                    :
                                    <Card>
                                        <Card.Body>
                                            <h4 className="text-center"><FormattedMessage id="Validation.noInformation" /></h4>
                                        </Card.Body>
                                    </Card>
                                }
                            </Col>
                        </Row>
                    </div>
                </Col>
            </Row>

        </CrossaPageTemplate>
        </>
    );


}

export default RulesRecordTypePage;
