import React from "react";
import {Route, Switch} from "react-router-dom";
import Sidebar from "components/Sidebar.js";
import routes from "routes/WorkspaceAdminRoutes.js";
import {CONTEXT} from "util/Constants";

// react-bootstrap components


function WorkspaceAdminLayout() {


    const getRoutes = (routes) => {
        return routes.map((prop, key) => {
            if (prop.collapse) {
                return getRoutes(prop.views);
            }
            return (
                <Route
                    path={prop.layout + prop.path}
                    key={key}
                    component={prop.component}
                    exact
                />
            );
        });
    };


    return (

        <div className="wrapper">
            <Sidebar
                isVisible={true}
                routes={routes}
                context={CONTEXT.WORKSPACE_ADMIN}

            />
            <div className="main-panel">

                <div className="content">
                    <Switch>{getRoutes(routes)}</Switch>
                </div>
                <div
                    className="close-layer"
                    onClick={() =>
                        document.documentElement.classList.toggle("nav-open")
                    }
                />
            </div>
        </div>
    );
}


export default WorkspaceAdminLayout;
