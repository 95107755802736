import React from "react";
import {Col} from "react-bootstrap";
import {leftArrow} from "./Icons";

const SlidingPanel = (props) => {

    return (
        <aside className={"sliding-panel " + props.currentStyle}>
            <header className="sliding-panel-header">
                {props.hasBackButton &&
                <span onClick={() =>props.closeSlidingPanel()} className="back-icon-sliding-panel">{leftArrow}</span>}
                <Col className="title-col pl-0">
                    <h3>{props.title}</h3>
                </Col>
                {!props.hasBackButton && <Col className="btn-col">
                    <a
                        className="sliding-panel-btn close-btn"
                        onClick={(e) => props.closeSlidingPanel()}
                    >&times;
                    </a>
                </Col>}
            </header>
            <main className="sliding-panel-content">
                {props.content}
            </main>
        </aside>
    )
}

export default SlidingPanel;
