import * as Validations from "validations/validations.js"

const addViewValidations = (view,rules, errors) => {
    if (view.name==="") {
        errors = {
            ...errors,
            "name": true,
        }
    }
    if(view.rtid==null){
        errors = {
            ...errors,
            "rtid": true,
        }
    }
    if(rules.length){
        rules.map((rule,index)=>{
            if(index%2===0) {
                if (!rule.operand) {
                    errors = {
                        ...errors,
                        "rules": {
                            ...errors.rules,
                            [index]: {
                                ...errors.rules[index],
                                "operand": true,
                            }
                        },
                    }
                }
                if (!rule.operatorValue) {
                    errors = {
                        ...errors,
                        "rules": {
                            ...errors.rules,
                            [index]: {
                                ...errors.rules[index],
                                "operatorValue": true,
                            }
                        },
                    }
                }
                if (!rule.operator) {
                    errors = {
                        ...errors,
                        "rules": {
                            ...errors.rules,
                            [index]: {
                                ...errors.rules[index],
                                "operator": true,
                            }
                        },
                    }
                }
                if (rule.select3==null || rule.select3==="") {
                    errors = {
                        ...errors,
                        "rules": {
                            ...errors.rules,
                            [index]: {
                                ...errors.rules[index],
                                "select3": true,
                            }
                        },
                    }
                }
            }
        });
    }

    const existErrors = Validations.checkIfError(errors);

    return [errors, existErrors];
}

export {
    addViewValidations
}
