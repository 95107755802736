import * as MemberService from "../service/MemberService";
import * as StorageService from "../service/StorageService";
import * as MenuService from "../service/MenuService";
import * as WorkspaceService from "../service/WorkspaceService";

const menuToNav = (wmm,wid) => {
    const menuNav = wmm.map(menu => {

        let views=menu.dashboard?.visible ? [{
            path: "/" + wid + "/menus/" + menu.id + "/dashboard",
            name: menu.dashboard.name,
            icon: null,
            layout: "/workspace-member",
            isVisible: true,
            isPage: true,
            isDynamicMenu: true,
        }]:[];
        return {
            hasSubmenus: true,
            path: "/" + wid + "/menus/" + menu.id,
            name: menu.name,
            icon: null,
            layout: "/workspace-member",
            isVisible: true,
            isPage: false,
            isDynamicMenu: true,
            views: [...views,...menu.pages.map((page, index) => {
                return {
                    path: "/" + wid + "/menus/" + menu.id + "/page/" + index+"/tab/0",
                    name: page.name,
                    icon: null,
                    layout: "/workspace-member",
                    isVisible: true,
                    isPage: true,
                    isDynamicMenu: true,
                }
            })]
        }
    });
    return menuNav;
}

const isSuperAdmin = () => {
    return StorageService.getIsSuperAdmin() !== null && StorageService.getIsSuperAdmin() === "true";
}

// this creates the intial dynamicMenusCollapseState of this component based on the collapse routes
// that it gets through routes prop
const getInitialCollapseStates = (routes) => {
    let states = {};
    let findMenuWithSubmenus=false;
    routes.forEach((route,key) => {
        if (route.hasSubmenus && !findMenuWithSubmenus) {
            states[key] = true;
            findMenuWithSubmenus=true;
        } else{
            states[key]=false;
        }
    });
    return states;
};

const getWorkspacesDataHandler = async (wid,isWorkspaceAdminPath,routes) => {
    let data={};
    let responseCurrentMemberOk=false;
    let responseWorkspaceOk=false;
    let responseMenuOk=false;
    const responseWorkspaces = await WorkspaceService.findUserWorkspaces();
    const ret = {
        workspaceId: null,
        workspaceName: "",
        workspaces: []
    };
    let workspaceId = null;
    let name = "";
    let memberVid = null;

    if (responseWorkspaces.ok) {
       responseWorkspaceOk = responseWorkspaces.ok;
        const isActiveMap= {};
        responseWorkspaces.data.forEach(w => {
            isActiveMap[w.id] = w.isActive
        });
        StorageService.setIsActive(isActiveMap);
        if (responseWorkspaces.data.length > 0) {
            const id = wid;
            workspaceId = id || responseWorkspaces.data[0].id;
            responseWorkspaces.data.forEach((entry) => {
                if (entry.id === workspaceId) {
                    name = entry.name;
                    memberVid = entry.memberVid;
                }
            });
            ret.workspaceName = id ? name : responseWorkspaces.data[0].name;
            ret.memberVid = id ? memberVid : responseWorkspaces.data[0].memberVid;
            ret.workspaceId = workspaceId;
            ret.workspaces = responseWorkspaces.data;

            data={
                ...data,
                isWorkspaceAdmin: isSuperAdmin(),
                dynamicMenusCollapseState: getInitialCollapseStates(routes),
                userWorkspaces: ret.workspaces,
                workspaceInfo: {
                    id: ret.workspaceId,
                    name: ret.workspaceName,
                    memberVid: ret.memberVid
                }
            };

            if(!isSuperAdmin()){
                const responseCurrentMember =  await MemberService.findMemberMe(ret.workspaceId);
                responseCurrentMemberOk = responseCurrentMember.ok;
                if(responseCurrentMember.ok){
                    data.memberName = responseCurrentMember.data?.name;
                    data.memberImage = responseCurrentMember.data?.image;
                    data.isAdmin = responseCurrentMember.data?.isAdmin;
                    data.isWorkspaceAdmin = responseCurrentMember.data?.isAdmin;
                    data.isActive = responseCurrentMember.data?.isActive;
                }

                if (!isWorkspaceAdminPath) {
                    if(typeof data.isActive === "undefined" || data.isActive === null || data.isActive){// is active
                        const responseMenu = await MenuService.findMemberMenuForMe(ret.workspaceId);
                        responseMenuOk = responseMenu.ok;
                        if (responseMenu.ok) {
                            let menus=menuToNav(responseMenu.data,ret.workspaceId);
                            data.finalRoutes= menus;
                            data.dynamicMenusCollapseState= getInitialCollapseStates(menus);
                        } else {
                            data.finalRoutes = [];
                            data.dynamicMenusCollapseState= getInitialCollapseStates([]);
                        }
                    }else{// inactive member in this workspace
                        // TODO: set message
                        data.finalRoutes = [];
                        data.dynamicMenusCollapseState= getInitialCollapseStates([]);
                    }
                }
            }
        }
    }

    return {
        data: {...ret,...data},
        responseCurrentMemberOk: responseCurrentMemberOk,
        responseWorkspaceOk:responseWorkspaceOk,
        responseMenuOk:responseMenuOk
    }
}

const getFirstMenuHandler = async (wid) =>{
    const responseMenu = await MenuService.findMemberMenuForMe(wid);
    if (responseMenu.ok) {
        let menuId=responseMenu.data[0]?.id;
        if(menuId && responseMenu.data[0].dashboard && responseMenu.data[0].dashboard.visible)
            return "/menus/"+menuId+"/dashboard";
        else if(menuId && responseMenu.data[0].pages)
            return "/menus/"+menuId+"/page/0/tab/0";
        else return null
    }
}

export{
    getWorkspacesDataHandler,
    getFirstMenuHandler
}
