import React, {useMemo} from 'react';
import {Button, OverlayTrigger, Tooltip} from "react-bootstrap";
import {useTable} from 'react-table';
import * as ClientConfig from "../../../../config/ClientConfig";
import {FormattedMessage} from "react-intl";
import {editIcon, noImageIcon, tagIcon, trashIcon} from "../../../../components/Icons";
import {PAYMENT_STATUS, RECORD_FIELD_TYPE, RECORD_TYPE_TYPE} from "../../../../util/Constants";

const MenuDisplayTiles = (props) => {

    const columns = useMemo(() => props.columns, [props]);
    const data = useMemo(() => props.data, [props]);
    const fieldsTypeById = useMemo(() => props.fieldsTypeById, [props])
    const vid = useMemo(() => props.vid, [props])

    const {
        headerGroups,
        rows,
        prepareRow
    } = useTable({
        columns,
        data
    })


    const openEditFormOrSidebar = (event, id) => {
        event.stopPropagation();
        if (props.rtType === RECORD_TYPE_TYPE.FORM) {
            props.editForm(event, id);
        } else {
            props.handleClickOnName(event, id);
        }
    }

    const openDetailsSidebarOrFormPreview = (event, id) => {
        event.stopPropagation();
        if (props.rtType === RECORD_TYPE_TYPE.FORM) {
            props.handleShowFormPreview(event, id);
        } else {
            props.openDetailsSidebar(event, id)
        }
    }
    return (
        <div className="dynamic-cards-container row">
            {
                rows.map((row, indexRow) => {
                    prepareRow(row)
                    return (
                        <div key={indexRow} onClick={event => openDetailsSidebarOrFormPreview(event, row.original.id)}
                             className="dynamic-menu-card" id={row.original.id} {...row.getRowProps()}>
                            {
                                row.cells.map((cell, index) => {
                                    if (index === 0 && fieldsTypeById && fieldsTypeById[cell.column.id] === RECORD_FIELD_TYPE.TEXT && cell.column.id !== "description") {
                                        let createdDateCell = row.cells.find(cell => cell.column.id === "createdDate");
                                        if (createdDateCell) {
                                            return <div className="row title-row" key={index}>
                                                <h3>{cell.value || "-"}</h3>
                                                <div className="created-date" key={index}>
                                                    {createdDateCell.value}
                                                </div>
                                            </div>
                                        } else {
                                            return <div className="row title-row" key={index}>
                                                <h3>{cell.value || "-"}</h3>
                                            </div>
                                        }
                                    } else if ((index === 0 || index === 1) && fieldsTypeById && fieldsTypeById[cell.column.id] === RECORD_FIELD_TYPE.TEXT) {
                                        return <div className="row description-row" key={index}>
                                            {cell.value || "-"}
                                        </div>
                                    } else if (cell.column.id === "createdDate" &&
                                        (!fieldsTypeById || fieldsTypeById[row.cells[0]?.column.id] !== RECORD_FIELD_TYPE.TEXT ||
                                        row.cells[0]?.column.id === "description")) {
                                        return <div className="created-date" key={index}>
                                            {cell.value}
                                        </div>
                                    } else if (fieldsTypeById && (fieldsTypeById[cell.column.id] === RECORD_FIELD_TYPE.RECORD_LIST || fieldsTypeById[cell.column.id] === RECORD_FIELD_TYPE.RECORD)) {
                                        if (props.recordTypeFields.find(field => field.id === cell.column.id)?.useRecordsAsTags) {
                                            return <div className="row" key={index}>
                                                {cell.value && cell.value.length > 0 ?
                                                    <div className="badge-container">
                                                        {cell.value.map((tag, index) => {
                                                            return <span
                                                                key={index + Math.random()}
                                                                className={"badge" + (tag.enabledNotification ? " badge-secondary" : " badge-primary")}>
                                                                {tag.name}
                                                            </span>
                                                        })}
                                                    </div> : "-"}
                                            </div>
                                        } else
                                            return <div className="row" key={index}>
                                                <span className="label">
                                                {props.recordTypeFields.find(field => field.id === cell.column.id).name + ":"}
                                                    </span>
                                                &nbsp;
                                                <span className="value"> {cell.value || "-"}
                                                    </span>
                                            </div>
                                    } else if (data[indexRow].invalidFields?.includes(cell.column.id)) {
                                        return <span key={index}>
                                                    <span className="label">{props.recordTypeFields.find(field => field.id === cell.column.id).name + ":"}</span>
                                                    &nbsp;
                                                    <OverlayTrigger
                                                        onClick={(e) => e.preventDefault()}
                                                        overlay={
                                                            <Tooltip>
                                                                <FormattedMessage id="Validation.invalidData"/>
                                                            </Tooltip>
                                                        }
                                                    >
                                                        <i className='fa fa-info-circle text-danger mt-1'/>
                                                    </OverlayTrigger>
                                                    <span className="value">{cell.render('Cell')}</span>
                                                </span>
                                    } else if (fieldsTypeById && fieldsTypeById[cell.column.id] === RECORD_FIELD_TYPE.SINGLE_CHOICE && props.fieldsOptionsColorsById[cell.column.id]) {
                                        return <div className={"row"} key={index} {...cell.getCellProps()} >
                                            {!!!props.fieldsOptionsColorsById[cell.column.id][cell.value] ?
                                                <span>
                                                    <span className="label">
                                                        {props.recordTypeFields.find(field => field.id === cell.column.id).name + ":"}
                                                    </span>
                                                    &nbsp;
                                                    <span className="value">
                                                         {cell.value || "-"}
                                                    </span>

                                                </span> :
                                                <span>
                                                    <span className="label">
                                                        {props.recordTypeFields.find(field => field.id === cell.column.id).name + ":"}
                                                    </span>
                                                    &nbsp;
                                                    <span className="status"
                                                          style={{
                                                              color: "white",
                                                              justifyContent: "center",
                                                              borderRadius: "8px",
                                                              fontSize: "12px",
                                                              padding: "2px 9px",
                                                              display: "inline-block",
                                                              background: props.fieldsOptionsColorsById[cell.column.id][cell.value]
                                                          }}>
                                                    {cell.render('Cell')}
                                                    </span></span>
                                            }
                                        </div>
                                    } else if (cell.column.id === "theme") {
                                        return <div className="row" key={index} {...cell.getCellProps()}>
                                            <span
                                                className="label">{props.recordTypeFields.find(field => field.id === cell.column.id).name + ":"}</span>
                                            &nbsp;
                                            <span className="value">{cell.value ?
                                                <OverlayTrigger
                                                    onClick={(e) => e.preventDefault()}
                                                    overlay={<Tooltip>{cell.value.toUpperCase()}</Tooltip>}>
                                                    <div className="color-value-inner" style={{backgroundColor: cell.value}}></div>
                                                </OverlayTrigger>
                                                :  "-"}
                                            </span>
                                        </div>
                                    } else if (cell.column.id === "hasTags") {
                                        return <div key={index}></div>
                                    } else if (cell.column.id === "paymentAction") {
                                        return <div key={index}></div>
                                    } else if (cell.column.id === "actions") {
                                        return <div className="row actions-row" key={index}>
                                            {cell.row.values?.hasTags &&
                                                <div className="single-icon-td" key={index}>
                                                    <OverlayTrigger
                                                        onClick={(e) => e.preventDefault()}
                                                        overlay={
                                                            <Tooltip>
                                                                <FormattedMessage id="Tags"/>
                                                            </Tooltip>
                                                        }
                                                    >
                                                        <span className="tag-icon">{tagIcon}</span>
                                                    </OverlayTrigger>
                                                </div>
                                            }
                                            {data[indexRow]?.paymentAvailable &&
                                                <Button variant="primary"
                                                        size="sm"
                                                        className=" ml-auto"
                                                        onClick={e=>{e.stopPropagation();props.generatePaymentLink(row.original.id)}}
                                                >
                                                    <FormattedMessage id="PayNow"/>
                                                </Button>}
                                            {cell.column.enableEditAction && <>
                                            {(props.rtType!==RECORD_TYPE_TYPE.PAYMENT || (row.original.paymentInfo && row.original.paymentInfo.paymentStatus!=="COMPLETE")) &&
                                                <OverlayTrigger
                                                    onClick={(e) => e.preventDefault()}
                                                    overlay={
                                                        <Tooltip>
                                                            <FormattedMessage id="Button.editNoParams"/>
                                                        </Tooltip>
                                                    }
                                                >
                                                    <a
                                                        className={data[indexRow]?.paymentAvailable?"edit text-success":"ml-auto edit text-success"}
                                                        onClick={(event) => openEditFormOrSidebar(event, row.original.id)}
                                                    >
                                                        <span className="edit-btn">{editIcon}</span>
                                                    </a>
                                                </OverlayTrigger>}
                                                {props.rtType !== RECORD_TYPE_TYPE.FORM && <OverlayTrigger
                                                    onClick={(e) => e.preventDefault()}
                                                    overlay={
                                                        <Tooltip>
                                                            <FormattedMessage id="Button.delete"/>
                                                        </Tooltip>
                                                    }
                                                >
                                                    <a
                                                        className={(!data[indexRow]?.paymentAvailable && (props.rtType==RECORD_TYPE_TYPE.PAYMENT && (!row.original.paymentInfo || row.original.paymentInfo.paymentStatus==="COMPLETE")))?"ml-auto text-danger":"ml-3 text-danger"}
                                                        onClick={(event) => {
                                                            event.stopPropagation();
                                                            props.deleteRecord(event, row.original.id)
                                                        }}
                                                    >
                                                        <span className="trash-btn">{trashIcon}</span>
                                                    </a>
                                                </OverlayTrigger>}</>}

                                        </div>
                                    } else if (fieldsTypeById && fieldsTypeById[cell.column.id] === RECORD_FIELD_TYPE.IMAGE) {
                                        return <div className="row" key={index} {...cell.getCellProps()}>
                                            {cell.value ? <div className="img-container">
                                                    <img
                                                        src={ClientConfig.URL_RESOURCE_SERVER + ClientConfig.NAMESPACE_RECORDS + "images/recordviews/" + vid + "/image/" + cell.value} alt={"."}/>
                                                </div>
                                                : <div className="no-photo-container">{noImageIcon}</div>}

                                        </div>
                                    } else if (fieldsTypeById && fieldsTypeById[cell.column.id] === RECORD_FIELD_TYPE.PAYMENT) {
                                        return <div className="row" key={index} {...cell.getCellProps()}>
                                             <span
                                                 className="label"><FormattedMessage id="PaymentStatus"/></span>
                                            :&nbsp;
                                            <span className="value">{cell.value?
                                                cell.value.paymentStatus===PAYMENT_STATUS.COMPLETE?
                                                    <span className="payment-status-success"><FormattedMessage id="PaymentStatus.paid"/></span>
                                                    :
                                                    <span className="payment-status-danger"> <FormattedMessage id="PaymentStatus.notPaid"/></span>
                                            :
                                            "-"}
                                            </span>
                                        </div>
                                    } else {
                                        let header = headerGroups[0]?.headers?.find(column => column.id === cell.column.id);
                                        return <div className="row" key={index} {...cell.getCellProps()}>
                                            <span
                                                className="label">{(props.recordTypeFields.find(field => field.id === cell.column.id)?.name || header.render("Header"))}</span>
                                            :&nbsp;
                                            <span className="value">{cell.value || "-"}</span>
                                        </div>
                                    }
                                })
                            }
                        </div>
                    )
                })}

            {!!!data.length && <h4 style={{textAlign: "center"}}><FormattedMessage id="Validation.noRecordsYet"/></h4>}
        </div>
    )
}
export default MenuDisplayTiles;
