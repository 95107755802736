import * as Validations from "validations/validations.js"

const saveFormValidations = (formSettings, formFields, errors) => {
    if (!!!formSettings.name) {
        errors = {
            ...errors,
            "name": true,
        }
    }
    if (!!!formSettings.description) {
        errors = {
            ...errors,
            "description": true,
        }
    }
    if (!!!formSettings.startDate) {
        errors = {
            ...errors,
            "startDate": true,
        }
    }
    if (!!!formSettings.endDate) {
        errors = {
            ...errors,
            "endDate": true,
        }
    }
    if (!!!formSettings.maxNrOfReg || formSettings.maxNrOfReg <= 0) {
        errors = {
            ...errors,
            "maxNrOfReg": true,
        }
    }
    if(formFields) {
        let paymentField = formFields.find(field => field.id === "payment");
        if(paymentField) {
            if(!!!paymentField.paymentOptions || paymentField.paymentOptions.length === 0) {
                errors = {
                    ...errors,
                    "paymentField": true,
                }
            }
        }
    }

    const existErrors = Validations.checkIfError(errors);

    return [errors, existErrors];
}

const saveFormFieldValidations = (fieldType,formField,validations, errors) => {
    if (fieldType === "Text") {
        if(formField.validation.characterLimit && !!!formField.validation.limit){
            errors = {
                ...errors,
                "characterLimitField": true
            };
        }
    }
    if(fieldType==="Date"){
        if(validations.defaultDateSwitch && !formField.defaultDate){
            errors = {
                ...errors,
                "defaultDateField": true
            };
        }
    }
    if(fieldType==="Dropdown") {
        if (validations.preSelectedOptionSwitch && !formField.defaultOption) {
            errors = {
                ...errors,
                "preSelectedOptionField": true
            };
        }
    }
    if(fieldType==="SingleChoice" || fieldType==="MultipleChoice" || fieldType==="Dropdown"){
        formField.options.map((option,index)=>{
            if(!option){
                errors = {
                    ...errors,
                    "options": {
                        ...errors.options,
                        [index]:true
                    }
                };
            }
        })
    }
    if(fieldType==="Payment"){
        if(formField.otherPayments && !formField.otherPaymentsLabel){
            errors = {
                ...errors,
                "otherPaymentsField": true
            };
        }
        formField.paymentOptions.map((option,index)=>{
            if(!option.name || !option.price || option.price<0){
                errors = {
                    ...errors,
                    "paymentOptions": {
                        ...errors.paymentOptions,
                        [index]:{
                            name:!option.name,
                            price:!option.price || option.price<0
                        }
                    }
                };
            }
        })
    }
    const existErrors = Validations.checkIfError(errors);

    return [errors, existErrors];
}

export {
    saveFormValidations,
    saveFormFieldValidations
}
