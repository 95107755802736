import * as UserService from "service/UserService";

const getAllUsersHandler = async () => {
    const response = await UserService.findAllUsers();

    let allUsers = [];

    if (response.ok) {
        allUsers = response.data;
    }
    return {
        data: {
            users: allUsers,
        },
        ok:response.ok
    }
}

const deleteUserHandler = async (id) => {

    let allUsers = [];

    let ok = true;
    const responseDelete = await UserService.deleteUser(id);
    ok = ok && responseDelete.ok;
    if (responseDelete.ok) {
        const responseFindAll = await UserService.findAllUsers();
        ok = ok && responseFindAll.ok;
        if (responseFindAll.ok) {
            allUsers = responseFindAll.data;
        }
    }
    return {
        data: {
            users: allUsers,
        },
        ok: ok
    }
}

export {
    getAllUsersHandler,
    deleteUserHandler
}
