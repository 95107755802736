import * as ClientConfig from "config/ClientConfig.js";
import * as StorageService from "service/StorageService.js"
import * as RestClient from "util/api/restClient.js";

const findAllMembers = wid => {
    return RestClient.handleResponse(() => {
        const finalURL = ClientConfig.URL_RESOURCE_SERVER + ClientConfig.NAMESPACE_WORKSPACE_MEMBERS + wid;
        // const finalURL = ClientConfig.URL_RESOURCE_SERVER + ClientConfig.NAMESPACE_WORKSPACE_MEMBERS + wid+"?page=1&size=3";
        const response = fetch(finalURL, {
            method: "GET",
            headers: {
                "Authorization": "Bearer " + StorageService.getAccessToken()
            }
        });

        return response;
    });
}

const findMember = (wid, id) => {
    return RestClient.handleResponse(() => {
        const finalURL = ClientConfig.URL_RESOURCE_SERVER + ClientConfig.NAMESPACE_MEMBERS + "workspaces/" + wid + "/" + id;
        const response = fetch(finalURL, {
            method: "GET",
            headers: {
                "Authorization": "Bearer " + StorageService.getAccessToken(),
                "Content-Type": "application/json",
            },
        });

        return response;
    });
}

const findMemberMe = async (wid) => {
    return RestClient.handleResponse(() => {
        const finalURL = ClientConfig.URL_RESOURCE_SERVER + ClientConfig.NAMESPACE_WORKSPACE_MEMBERS + wid + "/me";
        const response = fetch(finalURL, {
            method: "GET",
            headers: {
                "Authorization": "Bearer " + StorageService.getAccessToken()
            }
        });

        return response;
    });
}

const insertMember = (wid, member) => {
    return RestClient.handleResponse(() => {
        const finalURL = ClientConfig.URL_RESOURCE_SERVER + ClientConfig.NAMESPACE_WORKSPACE_MEMBERS + wid;
        const response = fetch(finalURL, {
            method: "POST",
            body: JSON.stringify(member),
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + StorageService.getAccessToken()
            }
        });

        return response;
    });
}

const updateMember = (wid, id, member) => {
    return RestClient.handleResponse(() => {
        const finalURL = ClientConfig.URL_RESOURCE_SERVER + ClientConfig.NAMESPACE_WORKSPACE_MEMBERS + wid + "/" + id;
        const response = fetch(finalURL, {
            method: "PUT",
            body: JSON.stringify(member),
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + StorageService.getAccessToken()
            }
        });

        return response;
    });
}

const updateSelfMemberTags = (wid, id, notificationsSettings) => {
    return RestClient.handleResponse(() => {
        const finalURL = ClientConfig.URL_RESOURCE_SERVER + ClientConfig.NAMESPACE_WORKSPACE_MEMBERS + wid + "/" + id + "/tags";
        const response = fetch(finalURL, {
            method: "PUT",
            body: JSON.stringify(notificationsSettings),
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + StorageService.getAccessToken()
            }
        });

        return response;
    });
}

const deleteMember = (wid, id) => {
    return RestClient.handleResponse(() => {
        const finalURL = ClientConfig.URL_RESOURCE_SERVER + ClientConfig.NAMESPACE_WORKSPACE_MEMBERS + wid + "/" + id;
        const response = fetch(finalURL, {
            method: "DELETE",
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + StorageService.getAccessToken()
            }
        });

        return response;
    });
}

export {
    findMember,
    findMemberMe,
    findAllMembers,
    insertMember,
    updateMember,
    updateSelfMemberTags,
    deleteMember

}
