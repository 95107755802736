import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import CrossaTable from "components/CrossaTable.js"
import SweetAlert from 'react-bootstrap-sweetalert';
import { FormattedMessage } from 'react-intl';

import CrossaPageTemplate from "../../../components/CrossaPageTemplate";
import { EventBus } from "../../../util/EventBus";
import { getAllWorkspacesHandler } from "handlers/adminHandlers/workspaceHandlers/AllWorkspacesHandlers";
import { deleteWorkspaceHandler } from "handlers/adminHandlers/workspaceHandlers/AllWorkspacesHandlers";
import AdminNavbar from "../../../components/AdminNavbar";

function AllWorkspacesPage() {

    const [state, setState] = useState({
        workspaces: [],
        alert: null
    });

    const history = useHistory();

    useEffect(() => {
        getPageContent();
    }, []);


    const getPageContent = async () => {
        getAllWorkspacesHandler().then(response => {
            if (response.ok) {
                setState({
                    ...state,
                    ...response.data
                });
            }
        })
    }

    const toAddWorkspace = () => {
        history.push({
            pathname: "/admin/workspaces/add",
        });
    }

    const toEditWorkspace = (wid) => {
        history.push("/admin/workspaces/" + wid + "/edit");
    }

    const deleteAlert = (id) => {
        setState({
            ...state,
            alert:
                <SweetAlert
                    type="danger"
                    title=""
                    style={{ display: "block", marginTop: "-100px", fontSize: "13px" }}
                    btnSize="md"
                    confirmBtnBsStyle="danger"
                    cancelBtnBsStyle="default"
                    showCancel
                    cancelBtnText={<FormattedMessage id="Button.cancel" />}
                    confirmBtnText={<FormattedMessage id="Button.delete" />}
                    openAnim="false"
                    onConfirm={() => deleteWorkspace(id)}
                    onCancel={() => hideAlert()}
                    allowEscape
                    closeOnClickOutside
                    customClass="crossa-alert"
                >
                    <FormattedMessage id="Alert.deleteWorkspace" />
                </SweetAlert>
        });
    }

    const hideAlert = () => {
        setState({
            ...state,
            alert: null
        });
    }

    const deleteWorkspace = async id => {
        deleteWorkspaceHandler(id).then(response => {
            if (response.ok) {
                EventBus.dispatch("workspaceDeleted");
                setState({
                    ...state,
                    ...response.data,
                    alert:null
                })

            }
        })
    }

    const createRow = (prop,index) => {
        return {
            index:index+1,
            id:prop.id,
            name: prop.name,
            description: prop.description,
        }
    }

    const columns = [
        {
            Header: () => (
                <div>#</div>),
            accessor: "index",
        },
        {
            Header: () => (
                <div><FormattedMessage id="Name"/></div>),
            accessor: "name",
        },
        {
            Header: () => (
                <div><FormattedMessage id="Description"/></div>),
            accessor: "description",
        },
        {
            Header: () => (
                <div><FormattedMessage id="Actions"/></div>),
            accessor: "actions",
            sticky:"right"
        },
    ];

    return (
        <>
            {state.alert}
            <AdminNavbar
                title={<FormattedMessage id="Workspaces" />}
                hasRightBtn={true}
                rightBtnHasIcon={true}
                rightBtnName={<FormattedMessage id="Button.addWorkspace" />}
                rightButtonAction={toAddWorkspace}
            />
        <CrossaPageTemplate>
            <CrossaTable
                columns={columns}
                data={state.workspaces.map((workspace,index) => createRow(workspace,index))}
                handleEdit={toEditWorkspace}
                handleDelete={deleteAlert}
            />
        </CrossaPageTemplate>
        </>

    );
}

export default AllWorkspacesPage;
