export const formatDate = (date) => {
    let d = new Date(date),
        month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = d.getFullYear(),
        hours = '' + d.getHours(),
        minutes = '' + d.getMinutes();

    if (month.length < 2)
        month = '0' + month;
    if (day.length < 2)
        day = '0' + day;
    if (hours.length < 2)
        hours = '0' + hours
    if (minutes.length < 2)
        minutes = '0' + minutes

    return [year, month, day].join('-') + "T" + [hours, minutes].join(":");
}

export const DATE_FORMAT = {
    DEFAULT: "DD MMM YYYY, HH:mm",
    DAY_MONTH_YEAR: "DD MMM YYYY",
    HOUR_MINUTES: "HH:mm"
}