import * as RestClient from "../util/api/restClient";
import * as ClientConfig from "../config/ClientConfig";
import * as StorageService from "./StorageService";

const sendMessage = async (message,wid,vid) => {
    return RestClient.handleResponse(() => {
        const finalURL = ClientConfig.URL_RESOURCE_SERVER + ClientConfig.NAMESPACE_MESSAGES + "workspaces/" + wid + "/views/" + vid;
        const response = fetch(finalURL, {
            method: "POST",
            body: JSON.stringify(message),
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + StorageService.getAccessToken()
            }
        });

        return response;
    });
}

export {
    sendMessage
}