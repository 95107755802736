import {Button, Card, Col, Form, Row, Tab, Tabs} from "react-bootstrap";
import {FormattedMessage} from "react-intl";
import React, {useEffect, useState} from "react";
import {clientSetupFields, merchantSetupFields, paymentSetupFields} from "../utils/PaymentFields";
import Select from "react-select";
import {
    getDataForPaymentSetup,
    savePaymentSetupHandler
} from "../../../../handlers/workspaceAdminHandlers/settingsHandlers/PaymentDetailsHandlers";
import NotificationAlert from "react-notification-alert";
import {getNotifySuccessOptions} from "../../../../util/Util";


function PaymentSetup(props) {

    const paymentSetupTabs=[
        {
            name: <FormattedMessage id="PaymentSetup.paymentSetup" />,
            fields:paymentSetupFields,
            id:"paymentSetup"
        },
        {
            name: <FormattedMessage id="PaymentSetup.merchantSetup" />,
            fields:merchantSetupFields,
            id:"merchantSetup"
        },
        {
            name: <FormattedMessage id="PaymentSetup.clientSetup" />,
            fields:clientSetupFields,
            id:"clientSetup"
        },
        {
            name: <FormattedMessage id="PaymentSetup.paymentMailTemplate" />,
        }
    ];

    const [state,setState]=useState({
        initAllPaymentSetup:{},
        paymentSetup:{},
        paymentSetupOptions:{},
        merchantSetup:{},
        merchantSetupOptions:{},
        clientSetup:{},
        clientSetupOptions:{},
        selectedTab:0,
        views:[],
    });

    useEffect(()=>{
        getDataForPaymentSetup(props.match.params.wid).then(response=>{
            if (response.ok) {
                setState({
                    ...state,
                    ...response.data
                });
            }
        })
    },[]);

    const handleChangePaymentTabs=(k)=> {
        let newState = {...state};
        let selectedTab = paymentSetupTabs[state.selectedTab];

        let newSetup = {...state[selectedTab.id]};
        selectedTab.fields?.map(field => {
            newSetup[field.id] = state.initAllPaymentSetup[field.id];
            if(selectedTab.id==="paymentSetup" && state.initAllPaymentSetup["paymentPlanView"]) {
                if (field.id !== "paymentView" && field.id !== "paymentProcessor" && field.id !== "paymentPlanView") {
                    let view = state.views.find(view => view.id === state.initAllPaymentSetup["paymentPlanView"]);

                    let fieldOptions = [];
                    view.fieldModes?.map(fieldMode => {
                        if (fieldMode.recordTypeField.type === field.type) {
                            fieldOptions.push({
                                label: fieldMode.recordTypeField.name,
                                value: fieldMode.recordTypeField.id
                            });
                        }
                    });

                    newState = {
                        ...newState,
                        paymentSetupOptions: {
                            ...newState.paymentSetupOptions,
                            [field.id]: fieldOptions
                        }
                    }
                }
            }
            newSetup["paymentView"] = "Payment";
            if(selectedTab.id==="merchantSetup" && state.initAllPaymentSetup["merchantView"]) {
                if (field.id !== "merchantView") {
                    let view = state.views.find(view => view.id === state.initAllPaymentSetup["merchantView"]);
                    let fieldOptions=[];
                    view.fieldModes?.map(fieldMode => {
                        if (fieldMode.recordTypeField.type === field.type) {
                            fieldOptions.push({
                                label: fieldMode.recordTypeField.name,
                                value: fieldMode.recordTypeField.id
                            });
                        }
                    });
                    newState={
                        ...newState,
                        merchantSetupOptions: {
                            ...newState.merchantSetupOptions,
                            [field.id]:fieldOptions
                        }
                    }
                }
            }
            if(selectedTab.id === "clientSetup" && state.initAllPaymentSetup["clientView"]) {
                if (field.id !== "clientView") {
                    let view = state.views.find(view => view.id === state.initAllPaymentSetup["clientView"]);

                    let fieldOptions=[];
                    view.fieldModes?.map(fieldMode => {
                        if (fieldMode.recordTypeField.type === field.type) {
                            fieldOptions.push({
                                label: fieldMode.recordTypeField.name,
                                value: fieldMode.recordTypeField.id
                            });
                        }
                    });
                    newState={
                        ...newState,
                        clientSetupOptions: {
                            ...newState.clientSetupOptions,
                            [field.id]:fieldOptions
                        }
                    }
                }
            }
        });

        setState({
            ...newState,
            selectedTab: k,
            [selectedTab.id]: newSetup
        });
    }

    const handlePaymentSetupChange=(id,option)=>{
        let paymentSetupOptions={...state.paymentSetupOptions};
        let paymentSetup={...state.paymentSetup};
        if(id==="paymentPlanView"){
            paymentSetupFields.map(field=>{
                if(field.id!=="paymentView" && field.id!=="paymentProcessor" && field.id!=="paymentPlanView"){
                    paymentSetup[field.id]=null;
                    let view=state.views.find(view=>view.id===option.value);
                    let fieldOptions=[];
                    view.fieldModes?.map(fieldMode=>{
                        if(fieldMode.recordTypeField.type===field.type){
                            fieldOptions.push({label:fieldMode.recordTypeField.name,value:fieldMode.recordTypeField.id});
                        }
                    });
                    paymentSetupOptions={
                        ...paymentSetupOptions,
                        [field.id]:fieldOptions
                    };
                }
            })
        }
        setState({
            ...state,
            paymentSetup:{
                ...paymentSetup,
                [id]:option.value
            },
            paymentSetupOptions: paymentSetupOptions
        });
    }

    const handleMerchantSetupChange=(id,option)=>{
        let merchantSetupOptions={...state.merchantSetupOptions};
        let merchantSetup={...state.merchantSetup};
        if(id==="merchantView"){
            merchantSetupFields.map(field=>{
                if(field.id && field.id!=="merchantView"){
                    merchantSetup[field.id]=null;
                    let view=state.views.find(view=>view.id===option.value);
                    let fieldOptions=[];
                    view.fieldModes?.map(fieldMode=>{
                        if(fieldMode.recordTypeField.type===field.type){
                            fieldOptions.push({label:fieldMode.recordTypeField.name,value:fieldMode.recordTypeField.id});
                        }
                    });
                    merchantSetupOptions={
                        ...merchantSetupOptions,
                        [field.id]:fieldOptions
                    };
                }
            })
        }
        setState({
            ...state,
            merchantSetup:{
                ...merchantSetup,
                [id]:option.value
            },
            merchantSetupOptions: merchantSetupOptions
        });
    }

    const handleClientSetupChange=(id,option)=>{
        let clientSetupOptions={...state.clientSetupOptions};
        let clientSetup={...state.clientSetup};
        if(id==="clientView"){
            clientSetupFields.map(field=>{
                if(field.id!=="clientView"){
                    clientSetup[field.id]=null;
                    let view=state.views.find(view=>view.id===option.value);
                    let fieldOptions=[];
                    view.fieldModes?.map(fieldMode=>{
                        if(fieldMode.recordTypeField.type===field.type){
                            fieldOptions.push({label:fieldMode.recordTypeField.name,value:fieldMode.recordTypeField.id});
                        }
                    });
                    clientSetupOptions={
                        ...clientSetupOptions,
                        [field.id]:fieldOptions
                    };
                }
            })
        }
        setState({
            ...state,
            clientSetup:{
                ...clientSetup,
                [id]:option.value
            },
            clientSetupOptions: clientSetupOptions
        });
    }

    const savePaymentDetails=()=>{
        let paymentSetup={
            ...state.initAllPaymentSetup,
            ...state[paymentSetupTabs[state.selectedTab].id]
        };

        savePaymentSetupHandler(props.match.params.wid,paymentSetup).then(response=>{
            if(response.ok){
                setState({
                    ...state,
                    initAllPaymentSetup: {
                        ...paymentSetup
                    }
                });
                let options = getNotifySuccessOptions("bc");
                notificationAlertRef.current.notificationAlert(options);
            }
        })
    }

    const notificationAlertRef = React.useRef(null);

    return (
        <>
            <div className="rna-container">
                <NotificationAlert ref={notificationAlertRef}/>
            </div>
            <Row>
                <Col className="title">
                    <Card.Title as="h3"><FormattedMessage id="PaymentSetup"/></Card.Title>
                </Col>
            </Row>
            <Row>
                <Col>
                <div className="crossa-tabs-container">
                    <Tabs
                        className="crossa-tabs"
                        activeKey={state.selectedTab}
                        onSelect={(k) => handleChangePaymentTabs(k)}

                    >
                        {paymentSetupTabs.map((tab,index)=>{
                            return  <Tab
                                className="crossa-tab-pane"
                                key={index}
                                eventKey={index}
                                title={tab.name}
                            >
                                {tab.fields?.map((field,indexField) => {
                                    return <Row className="payment-setup-fields-container" key={indexField+field.id}>
                                        {field.type === "Group" ?
                                            <Col className="form-bold-group">
                                                <Form.Group>
                                                    <Form.Label>
                                                        {field.name}
                                                    </Form.Label>
                                                </Form.Group>
                                            </Col>
                                            :
                                            <Col>
                                                <Form.Group>
                                                    <Form.Label>
                                                        {field.name}:
                                                    </Form.Label>
                                                </Form.Group>
                                            </Col>
                                        }
                                        <Col>
                                            {tab.id==="paymentSetup" && <Form.Group>
                                                {field.id!=="paymentView"?<>
                                                <FormattedMessage id="Placeholder.select">
                                                    {placeholder =>
                                                        <Select
                                                            className={"react-select primary"}
                                                            classNamePrefix="react-select"
                                                            options={state.paymentSetupOptions[field.id]}
                                                            onChange={e=>handlePaymentSetupChange(field.id,e)}
                                                            name={field.id}
                                                            value={state.paymentSetupOptions[field.id]?.find(option=>option.value===state.paymentSetup[field.id]) || ""}
                                                            placeholder={placeholder}
                                                        />
                                                    }
                                                </FormattedMessage>
                                                </>:
                                                    <Form.Label>
                                                        {state.paymentSetup["paymentView"]}
                                                    </Form.Label>}
                                            </Form.Group>}
                                            {tab.id==="merchantSetup" && <Form.Group>
                                                {field.type!=="Group" &&
                                                <FormattedMessage id="Placeholder.select">
                                                    {placeholder =>
                                                        <Select
                                                            className={"react-select primary"}
                                                            classNamePrefix="react-select"
                                                            options={state.merchantSetupOptions[field.id]}
                                                            onChange={e=>handleMerchantSetupChange(field.id,e)}
                                                            name={field.id}
                                                            value={state.merchantSetupOptions[field.id]?.find(option=>option.value===state.merchantSetup[field.id]) || ""}
                                                            placeholder={placeholder}
                                                        />
                                                    }
                                                </FormattedMessage>}
                                            </Form.Group>}
                                            {tab.id==="clientSetup" && <Form.Group>
                                                <FormattedMessage id="Placeholder.select">
                                                    {placeholder =>
                                                        <Select
                                                            className={"react-select primary"}
                                                            classNamePrefix="react-select"
                                                            options={state.clientSetupOptions[field.id]}
                                                            onChange={e=>handleClientSetupChange(field.id,e)}
                                                            name={field.id}
                                                            value={state.clientSetupOptions[field.id]?.find(option=>option.value===state.clientSetup[field.id]) || ""}
                                                            placeholder={placeholder}
                                                        />
                                                    }
                                                </FormattedMessage>
                                            </Form.Group>}
                                        </Col>
                                    </Row>

                                })
                                }
                                <Row className="payment-setup-fields-container">
                                   <Col className="text-right">
                                       <Button
                                           variant="success"
                                           onClick={savePaymentDetails}
                                       >
                                           <FormattedMessage id="Button.save"/>
                                       </Button>
                                   </Col>
                                </Row>

                            </Tab>
                        }
                        )}
                    </Tabs>
                </div>
                </Col>
            </Row>

        </>
    );
}

export default PaymentSetup;