import React from "react";
import {FormattedMessage} from 'react-intl';
import {
    Form,
    Card, Row, Col
} from "react-bootstrap";

function MenuTitleComponent(props) {

    return (
        <>
            <div className="menu-title-component">

                <Card.Body>

                        <Card.Title as="h3"><FormattedMessage id="MenuPageName"/></Card.Title>

                    <Form.Group>
                        <Form.Label><FormattedMessage id="Name"/></Form.Label>
                        <FormattedMessage id="Name">
                            {placeholder =>
                                <Form.Control
                                    className={props.errors.pages?.[props.pageIndex]?.name ? "has-errors" : ""}
                                    placeholder={placeholder}
                                    type="text"
                                    value={"" || props.name}
                                    onChange={props.handleNameChange}
                                    isInvalid={props.errors.pages?.[props.pageIndex]?.name}
                                />
                            }
                        </FormattedMessage>
                        <Form.Control.Feedback type={"invalid"}>
                            <FormattedMessage id={"Validation.cannotBeEmpty"}/>
                        </Form.Control.Feedback>
                    </Form.Group>
                    {props.dashboard &&
                        <Form.Group>
                            <Row>
                                <Col>
                                    <FormattedMessage id="ShowMenuPage"/>
                                </Col>
                                <Col className="inline-switch-col">
                                    <Form.Check
                                        className="ml-3"
                                        type="switch"
                                        id={"show-menu-page-switch"}
                                        checked={props.visible || false}
                                        onChange={props.handleVisibleChange}
                                    />
                                </Col>

                            </Row>
                        </Form.Group>}
                </Card.Body>
            </div>
        </>
    )
}

export default MenuTitleComponent;
