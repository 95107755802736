import React from "react";
import {Button} from "react-bootstrap";
import {FormattedMessage} from 'react-intl';

const Footer = props => {

    return (
        <footer className="footer">
            <Button
                variant="default"
                hidden={!props.isEnable}
                onClick={() => props.isEnable && props.cancel()}
            >
                <FormattedMessage id="Button.cancel"/>
            </Button>

            <Button
                variant="success"
                hidden={!props.isEnable}
                disabled={props.saveDisabled}
                onClick={() => props.isEnable && props.save()}
            >
                <FormattedMessage id="Button.save"/>
            </Button>

        </footer>
    )
}

export default Footer;
