import React, {useEffect, useState} from "react";
import TreeItem from "views/workspaceAdmin/view/components/TreeMenu/TreeItem.js";

function TreeMenu(props) {
    const [treeItems, setTreeItems] = useState(props.data)

    /**
     * This function is used to set state to current value when props.data is updated
     */
    useEffect(() => {
        setTreeItems(props.data);
    }, [props.data]);

    return (
        <div className="tree-menu">
            {
                treeItems.map((item, index) => <TreeItem
                    key={index}
                    index={index}
                    title={item.name}
                    children={item.views}
                    selectItem={item.selectItem}
                    addItem={item.addItem}
                    selected={item.selected}
                    depth={1}
                    propRef={props.propRef}
                    rtid={item.id}
                    wid={item.wid}
                    deleteView={props.deleteView}
                />)
            }
        </div>
    );
}

export default TreeMenu;
