import React, {useEffect, useRef, useState} from "react";
import AsyncSelect from 'react-select/async';
import Select from 'react-select'
import TextareaAutosize from 'react-textarea-autosize';

import CrossaPageTemplate from "components/CrossaPageTemplate.js";

import DateTimePicker from 'react-datetime-picker';
import TagsInput from "views/workspaceMember/menuDisplay/components/TagsInput.js";
import {FormattedMessage} from 'react-intl';
import {Button, Col, Form, Row} from "react-bootstrap";
import * as Validations from "validations/workspaceMember/RecordValidations";
import {getNotifyErrorOptions} from "util/Util";
import {editIcon, fileIcon, iconX, iconXthinner, uploadFileIcon, uploadImageIcon} from "../../../../components/Icons";
import {
    findRecordByIdHandler,
    getAllRecordsWithNameContainsHandler, getFormIdHashHandler
} from "handlers/workspaceMemberHandlers/menuDisplayHandlers/DisplayMenuHandlers";
import * as ClientConfig from "../../../../config/ClientConfig";
import Cropper from "components/Cropper";
import {
    FORM_ACTION,
    RECORD_FIELD_MODE,
    RECORD_FIELD_RECORD_DISPLAY_OPTION,
    RECORD_FIELD_RECORD_LIST_DISPLAY_OPTION,
    RECORD_FIELD_TEXT_DISPLAY_OPTION,
    RECORD_FIELD_TYPE, RECORD_TYPE_TYPE
} from "../../../../util/Constants";
import currencies from "currency-codes";
import {
    getAllRegistrationsInRecordViewWithNameContainsHandler
} from "../../../../handlers/workspaceMemberHandlers/formHandlers/FormDataHandlers";

function SaveRecordForm(props) {

    // const fieldsUpdateForm = useMemo(() => props.fieldsUpdateForm || null, [JSON.stringify(props.fieldsUpdateForm)]);
    const imageInputsFileRef = useRef([]);

    const [state, setState] = useState({
        record: {},
        linkRecordToForm: {},
        allRecordRefs: {},
        selectedFiles: {},
        errors: {},
        formHash: {},
        cropperShow:false,
        selectedImageForCropper:null
    });

    const recordRef = useRef(null);
    const fieldsUpdateFormRef = useRef(null);


    useEffect(async() => {
        let newState = {...state};
        let viewIdArray = [];
        let formArray = [];
        let newErrors = {};

        if (JSON.stringify(recordRef.current) !== JSON.stringify(props.record)) {
            let newRecord = {...newState.record};
            for (let key in props.record) {
                let field = props.fieldsUpdateForm.find(field => field.id === key);
                if (field && field.type === RECORD_FIELD_TYPE.SINGLE_CHOICE && field.mode === RECORD_FIELD_MODE.WRITE && field.options && !field.options.includes(props.record[key])) {
                    newRecord[key] = null;
                } else if (field && field.type === RECORD_FIELD_TYPE.MULTIPLE_CHOICE && field.mode === RECORD_FIELD_MODE.WRITE && field.options) {
                    newRecord[key] = props.record[key].filter(option => field.options.includes(option))
                } else {
                    newRecord[key] = props.record[key];
                    if(field && props.rtType===RECORD_TYPE_TYPE.PAYMENT && (field.id==="client" || field.id==="merchant" || field.id==="paymentPlan")){
                        [newRecord,newErrors]=await setDefaultPaymentFields(props.record[field.id].id,field.id,newRecord,newErrors);
                    }
                }
            }
            newState = {
                ...newState,
                record: newRecord,
                selectedFiles: {}
            };
            recordRef.current = JSON.parse(JSON.stringify(props.record));
        }


        if (JSON.stringify(fieldsUpdateFormRef.current) !== JSON.stringify(props.fieldsUpdateForm)) {
            props.fieldsUpdateForm.forEach(field => {
                newErrors[field.id] = false;
                if (field.type !== RECORD_FIELD_TYPE.RECORD && field.type !== RECORD_FIELD_TYPE.RECORD_LIST) {
                    return;
                }
                if (field.displayOption === "FormLink") {
                    newState.linkRecordToForm[field.id] = !!(newState.record[field.id] && state.linkRecordToForm[field.id] !== false);
                    if(newState.record[field.id]) {
                        formArray.push({
                            "fieldId": field.id,
                            "rtid": field.rtid,
                            "id": newState.record[field.id].id
                        })
                    }
                } else {
                    if (!field.vid || viewIdArray.includes(field.vid) || !field.mode || field.mode === RECORD_FIELD_MODE.HIDE) {
                        return;
                    }
                    viewIdArray.push(field.vid);
                }
            });
            const tempVariable = await createAllRecordRefsObject(viewIdArray);
            const formHashObj = await createFormHashObject(formArray);
            newState = {
                ...newState,
                errors: newErrors,
                allRecordRefs: tempVariable,
                formHash: formHashObj
            };
            fieldsUpdateFormRef.current = JSON.parse(JSON.stringify(props.fieldsUpdateForm));
        }
        setState(newState);
    }, [props.fieldsUpdateForm, props.record]);

    const createAllRecordRefsObject = async (viewIdArray) => {
        let viewIdRecordsObject = {};
        const lengthOfViewIdArray = viewIdArray.length;
        for (let i = 0; i < lengthOfViewIdArray; ++i) {
            const records = await getAllRecordsWithNameContains(null, viewIdArray[i]);
            viewIdRecordsObject = {
                ...viewIdRecordsObject,
                [viewIdArray[i]]: records
            };
        }
        return viewIdRecordsObject;
    }

    const createFormHashObject = async (forms) => {
        let formHashObj = {};
        for (let i = 0; i < forms.length; i++) {
            formHashObj[forms[i].fieldId] = await getFormIdHash(props.wid, forms[i].id);
        }

        return formHashObj;
    }

    const handleDateChange = async (fieldId, value) => {
        let newErrors = {...state.errors};
        if (!!state.errors[fieldId]) {
            newErrors = {
                ...newErrors,
                [fieldId]: false,
            };
        }
        let auxRecord = {
            ...state.record,
            [fieldId]: value ? new Date(value) : null,
        };
        await executeScriptWithParam(auxRecord, newErrors);
    }

    const handleInputChange = async (event, type) => {
        let newErrors = {...state.errors};
        if (!!state.errors[event.target.name]) {
            newErrors = {
                ...newErrors,
                [event.target.name]: false,
            };
        }
        let auxRecord
        switch (type) {
            case RECORD_FIELD_TYPE.BOOLEAN:
                auxRecord = {
                    ...state.record,
                    [event.target.name]: event.target.checked,
                };
                await executeScriptWithParam(auxRecord, newErrors);
                break;
            case RECORD_FIELD_TYPE.SINGLE_CHOICE:
                auxRecord = {
                    ...state.record,
                    [event.target.name]: event.value,
                };

                await executeScriptWithParam(auxRecord, newErrors);
                break;
            case RECORD_FIELD_TYPE.MULTIPLE_CHOICE:
                let newValues;
                if (state.record && state.record[event.target.name]) {
                    newValues = state.record[event.target.name];
                } else {
                    newValues = [];
                }
                newValues.push(event.value);
                let recordAux = {
                    ...state.record,
                    [event.target.name]: newValues,
                };

                await executeScriptWithParam(recordAux, newErrors);
                break;
            default:
                setState({
                    ...state,
                    record: {
                        ...state.record,
                        [event.target.name]: event.target.value,
                    },
                    errors: newErrors
                });
        }
    }

    const executeScript = async () => {
        if (props.executeScript) {
            let isNew = false;
            if (props.action === FORM_ACTION.INSERT) {
                isNew = true;
            }
            let [response, _] = await props.executeScript(isNew, state.record);
            setState({
                ...state,
                record: response.record
            });
            delete response.record;

            props.setInitRecordFields(response);
        } else {
            setState({
                ...state,
                record: state.record
            });
        }
    }

    const executeScriptWithParam = async (record, errors) => {
        if (props.executeScript) {
            let isNew = false;
            if (props.action === FORM_ACTION.INSERT) {
                isNew = true;
            }

            let [response, newErrors] = await props.executeScript(isNew, record);
            let newRecord = response.record;

            setState({
                ...state,
                record: newRecord,
                errors: {
                    ...errors,
                    ...newErrors
                }
            });
            delete response.record;
            props.setInitRecordFields(response);
        } else {
            setState({
                ...state,
                record: record,
            });
        }
    }


    const recordToSelectElement = record => {
        if (!record) {
            return null;
        }
        return {
            "value": record.id,
            "label": record.name
        };
    }

    const checkIfContains = (list, element) => {
        return list.map(el => el.id).includes(element.value);
    }

    const setCroppedImage=async(file)=>{
        let newErrors = {...state.errors};
        if (!!state.errors[state.selectedImageForCropper?.fieldId]) {
            newErrors = {
                ...newErrors,
                [state.selectedImageForCropper?.fieldId]: false,
            };
        }
        setState({
            ...state,
            selectedFiles: {
                ...state.selectedFiles,
                [state.selectedImageForCropper?.fieldId]: {
                    url: URL.createObjectURL(file),
                    file: file,
                }
            },
            cropperShow: false,
            errors: newErrors
        });
    }

    const onSelectImage = e => {
        if (e.target.files && e.target.files.length === 1) {
            setState({
                ...state,
                cropperShow: true,
                selectedImageForCropper: {
                    fieldId:e.target.name,
                    picture:{
                        url: URL.createObjectURL(e.target.files[0]),
                        file: e.target.files[0],
                    }
                }
            });
        }
    }

    const onSelectFile = e => {
        let newErrors = {...state.errors};
        if (!!state.errors[e.target.name]) {
            newErrors = {
                ...newErrors,
                [e.target.name]: false,
            };
        }

        if (e.target.files && e.target.files.length === 1) {
            setState({
                ...state,
                selectedFiles: {
                    ...state.selectedFiles,
                    [e.target.name]: {
                        url: URL.createObjectURL(e.target.files[0]),
                        file: e.target.files[0],
                    }
                },
                errors: newErrors
            });
        }
    }

    const onSelectMultipleFiles = e => {
        let newErrors = {...state.errors};
        if (!!state.errors[e.target.name]) {
            newErrors = {
                ...newErrors,
                [e.target.name]: false,
            };
        }

        let newState={...state};

        if (e.target.files && e.target.files.length) {
            for (let i=0; i<e.target.files.length; i++) {
                newState.selectedFiles={...newState.selectedFiles,[e.target.name]:[
                        ...(newState.selectedFiles[e.target.name]||[]),{
                            url: URL.createObjectURL(e.target.files[i]),
                            file: e.target.files[i],
                        }
                    ]};
            }
        }
        setState({
            ...newState,
            errors: newErrors
        });
    }

    const handleForm = (field) => {
        if(props.editFormLink && props.createFormLink) {
            if (state.record[field.id]) {
                props.editFormLink(field, state.record[field.id]?.id, props.action);
            } else {
                props.createFormLink(field, props.action);
            }
        }
    }

    const deleteSelectedFile = (fieldId,index) =>{
        let newSelectedFiles={...state.selectedFiles};
        if(index || index===0){
            newSelectedFiles[fieldId].splice(index,1);
            if(!newSelectedFiles[fieldId].length){
                delete newSelectedFiles[fieldId];
            }
        } else{
            delete newSelectedFiles[fieldId];
        }

        setState({
            ...state,
            selectedFiles: newSelectedFiles
        });
    }

    const deleteExistentFile = (fieldId,index) =>{
        let record={...state.record};
        if(index || index===0){
            record[fieldId].splice(index,1);
            if(!record[fieldId].length){
                record[fieldId]=null;
            }
        } else{
            record[fieldId]=null;
        }

        setState({
            ...state,
            record: record
        });
    }
    /*
      action is UPDATE or INSERT
      if INSERT then all READ moode fields will be ignored
    */
    const fieldModeToFormInput = (field, index) => {

        if(props.rtType===RECORD_TYPE_TYPE.FORM_DATA){
            if(field.hide){
                field.mode=RECORD_FIELD_MODE.WRITE;
            } else {
                field.mode=RECORD_FIELD_MODE.READ;
            }
        }

        if(field.id==="price" && state.record["editablePrice"]){
            field.mode="WRITE";
        }

        if (field.id === "information" || field.id === "createdBy" || field.id === "createdDate" || field.id === "modifiedBy" ||
            field.id === "modifiedDate" || !field.mode || field.mode === RECORD_FIELD_MODE.HIDE) {
            return;
        }

        const readOnly = field.mode === RECORD_FIELD_MODE.READ;
        if (field.type === RECORD_FIELD_TYPE.TEXT) {
            if (field.displayOption === RECORD_FIELD_TEXT_DISPLAY_OPTION.TEXT_AREA) {
                return (
                    <Form.Group className={(!readOnly ? "" : "disabled")+" text-area-form-group with-autosize"} key={index + field}>
                        <Form.Label key={index + field + 1}>{field.name}</Form.Label>
                        <div className="textarea">
                            <TextareaAutosize
                                minRows={3}
                                className={state.errors[field.id] ? " has-errors" : ""}
                                as="textarea"
                                style={{height: '100px'}}
                                key={index + field + 2}
                                readOnly={readOnly}
                                onChange={event => handleInputChange(event, field.type)}
                                onBlur={executeScript}
                                value={(state.record && state.record[field.id]) || ""}
                                name={field.id}
                            />
                        </div>


                        <Form.Control.Feedback type="invalid">
                            {field.id === "email" ? <FormattedMessage id={"Validation.emailInvalid"}/> :
                                <FormattedMessage id={"Validation.cannotBeEmpty"}/>}
                        </Form.Control.Feedback>
                    </Form.Group>
                );
            }

            // displayOption: "TextArea"
            return (
                <Form.Group key={index + field} className={!readOnly ? "" : "disabled"}>
                    <Form.Label key={index + field + 1}>{field.name}</Form.Label>
                    <Form.Control
                        className={state.errors[field.id] ? " has-errors" : ""}
                        key={index + field + 2}
                        readOnly={readOnly}
                        onChange={event => handleInputChange(event, field.type)}
                        onBlur={executeScript}
                        value={(state.record && state.record[field.id]) || ""}
                        type="text"
                        isInvalid={state.errors[field.id]}
                        name={field.id}/>
                    <Form.Control.Feedback type="invalid">
                        {field.id === "email" ? <FormattedMessage id={"Validation.emailInvalid"}/> :
                            <FormattedMessage id={"Validation.cannotBeEmpty"}/>}
                    </Form.Control.Feedback>
                </Form.Group>
            );
        } else if (field.type === RECORD_FIELD_TYPE.DATE) {
            return (
                <Form.Group key={index + field + 1} className={!readOnly ? "" : "disabled"}>
                    <Form.Label key={index + field + 2}>{field.name}</Form.Label>
                    <DateTimePicker
                        className={state.errors[field.id] ? "has-errors" : ""}
                        maxDate={new Date("9999-12-31")}
                        disableClock={true}
                        onChange={value => handleDateChange(field.id, value)}
                        format={field.timeField ? "y-MM-dd HH:mm" : "y-MM-dd"}
                        value={state.record && state.record[field.id] ? new Date(state.record[field.id]) : ""}
                        disabled={readOnly}
                    />
                    {
                        state.errors[field.id] && <div className="error-message">
                            <FormattedMessage id={"Validation.cannotBeEmpty"}/>
                        </div>
                    }
                </Form.Group>);
        } else if (field.type === RECORD_FIELD_TYPE.GROUP_HEADER) {
            return (
                <h4 className={index === 0 ? "group-header no-border" : "group-header"} key={index + field}>{field.name}</h4>
            );
        } else if (field.type === RECORD_FIELD_TYPE.INTEGER) {
            return (<Form.Group key={index + field + 1} className={!readOnly ? "" : "disabled"}>
                <Form.Label key={index + field + 2}>{field.name}</Form.Label>
                <Form.Control
                    className={state.errors[field.id]?" has-errors":""}
                    key={index + field + 3}
                    readOnly={readOnly}
                    onChange={event => handleInputChange(event, field.type)}
                    onBlur={executeScript}
                    value={(state.record && state.record[field.id]) || ""}
                    type="number"
                    isInvalid={state.errors[field.id]}
                    name={field.id}/>
                <Form.Control.Feedback type="invalid">
                    <FormattedMessage id={"Validation.cannotBeEmpty"}/>
                </Form.Control.Feedback>
            </Form.Group>);
        } else if (field.type === RECORD_FIELD_TYPE.DECIMAL) {
            return (<Form.Group key={index + field + 1} className={!readOnly ? "" : "disabled"}>
                <Form.Label key={index + field + 2}>{field.name}</Form.Label>
                <Form.Control
                    className={state.errors[field.id]?" has-errors":""}
                    key={index + field + 3}
                    readOnly={readOnly}
                    onChange={event => handleInputChange(event, field.type)}
                    onBlur={executeScript}
                    value={(state.record && state.record[field.id]) || ""}
                    type="number"
                    isInvalid={state.errors[field.id]}
                    name={field.id}/>
                <Form.Control.Feedback type="invalid">
                    <FormattedMessage id={"Validation.cannotBeEmpty"}/>
                </Form.Control.Feedback>
            </Form.Group>);
        } else if (field.type === RECORD_FIELD_TYPE.BOOLEAN) {
            return (
                <Form.Group key={index + field + 1} className={!readOnly ? "" : "disabled"}>
                    <Row>
                        <Col>
                            <label className="d-flex align-items-center">
                                <span className="switch-label mr-5">{field.name}</span>
                            </label>
                        </Col>
                        <Col className="inline-switch-col">
                            <Form.Check
                                className={"ml-3 " + (state.errors[field.id]?" has-errors":"")}
                                type="switch"
                                id={"record-switch"+field.id+props.action}
                                name={field.id}
                                checked={(state.record && state.record[field.id]) || false}
                                onChange={event => {
                                    readOnly ? "" : handleInputChange(event, field.type)
                                }}
                            />
                        </Col>
                    </Row>
                </Form.Group>
            );
        } else if (field.type === RECORD_FIELD_TYPE.CURRENCY) {
            return (
                <Form.Group key={index + field + 1} className={!readOnly ? "" : "disabled"}>
                    <Form.Label key={index + field + 2}>{field.name} ({field.currency})</Form.Label>
                    <Form.Control
                        className={state.errors[field.id] ? " has-errors" : ""}
                        key={index + field + 3}
                        readOnly={readOnly}
                        onChange={event => handleInputChange(event, field.type)}
                        onBlur={executeScript}
                        value={(state.record && state.record[field.id]) || ""}
                        type="number"
                        isInvalid={state.errors[field.id]}
                        name={field.id}
                    />
                    <Form.Control.Feedback type="invalid">
                        <FormattedMessage id={"Validation.cannotBeEmpty"}/>
                    </Form.Control.Feedback>
                </Form.Group>
            );
        } else if (field.type === RECORD_FIELD_TYPE.ROLES_LIST) {
            return (
                <Form.Group key={index + field + 1} className={!readOnly ? "" : "disabled"}>
                    <Form.Label key={index + field + 1}>{field.name}</Form.Label>
                    {
                        !!state.record && !!state.record[field.id] && !!state.record[field.id].length &&
                        <TagsInput
                            value={state.record[field.id].map(tag => tag.name)}
                            onChange={(value) => handleTagsSelect(value, field.id)}
                            tagProps={{ className: "react-tagsinput-tag tag-azure" }}
                        />
                    }
                    <FormattedMessage id="Placeholder.select">
                        {placeholder =>
                            <Select
                                className={"react-select primary" + (state.errors[field.id] ? " has-errors" : "")}
                                classNamePrefix="react-select"
                                options={props.roles?.filter(role => !state.record[field.id]?.find(r => r.id === role.value))}
                                onChange={event => handleAddRecordToRecordList(event, field.id)}
                                placeholder={placeholder}
                            />
                        }
                    </FormattedMessage>
                    {
                        state.errors[field.id] && <div className={"error-message"}>
                            <FormattedMessage id={"Validation.selectAtLeastOneOption"}/>
                        </div>
                    }
                </Form.Group>
            )
        } else if (field.type === RECORD_FIELD_TYPE.RECORD) {
            if (readOnly) {
                if (field.displayOption === RECORD_FIELD_RECORD_DISPLAY_OPTION.FORM_LINK) {
                    if (state.record[field.id]) {
                        return (
                            <Form.Group key={index + field + 1} className="mt-auto">
                                <a href={state.formHash[field.id] ? ClientConfig.URL_RESOURCE_SERVER + "api/form/" +
                                    props.wid + "/" + state.record[field.id].id + "/" + state.formHash[field.id] + ".html" : ""} target="_blank">
                                    <Button className="mb-0">
                                        {state.record[field.id].name}
                                    </Button>
                                </a>
                            </Form.Group>
                        );
                    }
                    return <React.Fragment key={index + field + 1}></React.Fragment>;
                }
                return (
                    <Form.Group key={index + field + 1} className={!readOnly ? "" : "disabled"}>
                        <Form.Label key={index + field + 1}>{field.name}</Form.Label>
                        <Form.Control
                            className={state.errors[field.id] ? " has-errors" : ""}
                            key={index + field + 2}
                            readOnly
                            value={(!!state.record && state.record[field.id]?.name) || ""}
                            type="text"
                            isInvalid={state.errors[field.id]}
                            name={field.id}/>
                        <Form.Control.Feedback type="invalid">
                            <FormattedMessage id={"Validation.selectOneOption"}/>
                        </Form.Control.Feedback>
                    </Form.Group>
                )
            }
            if (field.displayOption === RECORD_FIELD_RECORD_DISPLAY_OPTION.FORM_LINK) {
                return (
                    <Form.Group key={index + field + 1} className={!readOnly ? "" : "disabled"}>
                        <Row>
                            <Col>
                                <label className="d-flex align-items-center">
                                    <span className="switch-label mr-5">{field.name}</span>
                                </label>
                            </Col>
                            <Col className="inline-switch-col">
                                <Form.Check
                                    className={state.errors[field.id] ? " has-errors" : ""}
                                    type="switch"
                                    checked={state.linkRecordToForm && state.linkRecordToForm[field.id] || ""}
                                    id={"record-switch"+field.id+props.action}
                                    onChange={() => {
                                        setState({
                                            ...state,
                                            linkRecordToForm: {
                                                ...state.linkRecordToForm,
                                                [field.id]: !state.linkRecordToForm[field.id]
                                            }
                                        })
                                    }}
                                    name={field.id}
                                />
                            </Col>
                        </Row>
                        {
                            state.linkRecordToForm[field.id] &&
                            <Button className="mb-0" onClick={() => handleForm(field)}>
                                {state.record[field.id] ? "Edit form" : "Create form"}
                            </Button>
                        }
                    </Form.Group>

                );
            } else if (field.displayOption === RECORD_FIELD_RECORD_DISPLAY_OPTION.COMBO_BOX) {
                return (
                    <Form.Group key={index + field + 1} className={!readOnly ? "" : "disabled"}>
                        <Form.Label key={index + field + 2}>{field.name}</Form.Label>
                        <FormattedMessage id="Placeholder.select">
                            {placeholder =>
                                <Select
                                    className={"react-select primary" + (state.errors[field.id] ? " has-errors" : "")}
                                    classNamePrefix="react-select"
                                    value={!!state.record && recordToSelectElement(state.record[field.id])}
                                    options={state.allRecordRefs[field.vid]}
                                    onChange={event => handleAddRecordRef(event, field.id)}
                                    isDisabled={readOnly}
                                    isInvalid={state.errors[field.id]}
                                    isClearable
                                    placeholder={placeholder}
                                />
                            }
                        </FormattedMessage>
                        {
                            state.errors[field.id] && <div className={"error-message"}>
                                <FormattedMessage id={"Validation.selectOneOption"}/>
                            </div>
                        }
                    </Form.Group>
                );
            } else if (field.displayOption === RECORD_FIELD_RECORD_DISPLAY_OPTION.RADIO_BUTTON_GROUP) {
                return (
                    <Form.Group key={index + field + 1} className={!readOnly ? "" : "disabled"}>
                        <Form.Label key={index + field + 2}>{field.name}</Form.Label>
                        {
                            state.record[field.id] && !state.allRecordRefs[field.vid]?.find(recordRef => recordRef.value === state.record[field.id].id) &&
                            <div className="radio-container">
                                <Form.Check>
                                    <Form.Check.Label>
                                        <Form.Check.Input
                                            type="radio"
                                            checked={(!!state.record && state.record[field.id]) ? (state.record[field.id].id === state.record[field.id].id) : false}
                                            onChange={() => readOnly ? "" : handleAddRecordRef({
                                                "label": state.record[field.id].name,
                                                "value": state.record[field.id].id
                                            }, field.id)}
                                        ></Form.Check.Input>
                                        {state.record[field.id].name}
                                    </Form.Check.Label>
                                </Form.Check>
                            </div>

                        }
                        {
                            state.allRecordRefs[field.vid] && state.allRecordRefs[field.vid].map((recordRef, index) => {
                                return (
                                    <div key={index} className="radio-container">
                                        <Form.Check>
                                            <Form.Check.Label>
                                                <Form.Check.Input
                                                    key={index}
                                                    type="radio"
                                                    checked={(!!state.record && state.record[field.id]) ? (state.record[field.id].id === recordRef.value) : false}
                                                    onChange={() => readOnly ? "" : handleAddRecordRef(recordRef, field.id)}>
                                                </Form.Check.Input>
                                                {/* <span className="form-check-sign"></span> */}
                                                {recordRef.label}
                                            </Form.Check.Label>
                                        </Form.Check>
                                    </div>
                                )
                            })
                        }
                        {
                            state.errors[field.id] && <div className={"error-message"}>
                                <FormattedMessage id={"Validation.selectOneOption"}/>
                            </div>
                        }
                    </Form.Group>

                );
            } else if (field.displayOption === RECORD_FIELD_RECORD_DISPLAY_OPTION.SEARCH_SELECT) {
                return (
                    <Form.Group key={index + field + 1} className={!readOnly ? "" : "disabled"}>
                        <Form.Label key={index + field + 2}>{field.name}</Form.Label>
                        <FormattedMessage id="Search">
                            {placeholder =>
                                <AsyncSelect
                                    isDisabled={readOnly}
                                    className={"react-select primary" + (state.errors[field.id] ? " has-errors" : "")}
                                    classNamePrefix="react-select"
                                    placeholder={placeholder}
                                    noOptionsMessage={({inputValue}) => !inputValue ?
                                        <FormattedMessage id={"SearchIn"} values={{param: field.name}}/> :
                                        <FormattedMessage id={"Placeholder.noResults"}/>}
                                    value={!!state.record && recordToSelectElement(state.record[field.id])}
                                    onChange={event => handleAddRecordRef(event, field.id)}
                                    loadOptions={(inputValue) => promiseOptions(inputValue, field.vid)}
                                    isInvalid={state.errors[field.id]}
                                    isClearable
                                />
                            }
                        </FormattedMessage>
                        {
                            state.errors[field.id] && <div className={"error-message"}>
                                <FormattedMessage id={"Validation.selectOneOption"}/>
                            </div>
                        }
                    </Form.Group>
                );
            }
        } else if (field.type === RECORD_FIELD_TYPE.RECORD_LIST) {
            if (field.displayOption === RECORD_FIELD_RECORD_LIST_DISPLAY_OPTION.MULTIPLE_CHOICE_LIST) {
                return (
                    <Form.Group key={index + field + 1} className={!readOnly ? "" : "disabled"}>
                        <Form.Label key={index + field + 2}>{field.name}</Form.Label>
                        {
                            !!state.record && !!state.record[field.id] && !!state.record[field.id].length &&
                            <TagsInput
                                value={state.record[field.id].map(tag => tag.name)}
                                onChange={(value) => handleTagsSelect(value, field.id)}
                                tagProps={{className: "react-tagsinput-tag tag-azure"}}
                            />
                        }
                        <FormattedMessage id="Placeholder.select">
                            {placeholder =>
                                <Select
                                    className={"react-select primary" + (state.errors[field.id] ? " has-errors" : "")}
                                    classNamePrefix="react-select"
                                    options={state.allRecordRefs[field.vid]?.filter(record => !state.record[field.id]?.find(r => r.id === record.value))}
                                    value={null}
                                    isDisabled={readOnly}
                                    isInvalid={state.errors[field.id]}
                                    onChange={event => handleAddRecordToRecordList(event, field.id)}
                                    placeholder={placeholder}
                                />
                            }
                        </FormattedMessage>
                        {
                            state.errors[field.id] && <div className={"error-message"}>
                                <FormattedMessage id={"Validation.selectAtLeastOneOption"}/>
                            </div>
                        }
                    </Form.Group>
                );
            } else if (field.displayOption === RECORD_FIELD_RECORD_LIST_DISPLAY_OPTION.CHECKBOX_GROUP) {
                return (
                    <Form.Group key={index + field + 1} className={!readOnly ? "" : "disabled"}>
                        <Form.Label key={index + field + 2}>{field.name}</Form.Label>
                        {
                            state.record[field.id] && state.record[field.id].filter(recordRefSelected => !state.allRecordRefs[field.vid]?.find(recordRef => recordRef.value === recordRefSelected.id)).map(recordRef => {
                                return (
                                    <div key={index} className="checkbox-container">
                                        <Form.Check className="checkbox-inline" key={index + field + 1}>
                                            <Form.Check.Label>
                                                <Form.Check.Input
                                                    className={state.errors[field.id]?" has-errors":""}
                                                    type="checkbox"
                                                    checked={(!!state.record && state.record[field.id]) ? checkIfContains(state.record[field.id], {
                                                        "label": recordRef.name,
                                                        "value": recordRef.id
                                                    }) : false}
                                                    onChange={() => readOnly ? "" : handleAddRecordToRecordListCheckBox({
                                                        "label": recordRef.name,
                                                        "value": recordRef.id
                                                    }, field.id)}
                                                ></Form.Check.Input>
                                                <span className="form-check-sign"></span>{recordRef.name}
                                            </Form.Check.Label>
                                        </Form.Check>
                                    </div>
                                )
                            })
                        }
                        {
                            state.allRecordRefs[field.vid] && state.allRecordRefs[field.vid].map((recordRef, index) => {
                                return (
                                    <div key={index} className="checkbox-container">
                                        <Form.Check className="checkbox-inline" key={index + field + 1}>
                                            <Form.Check.Label>
                                                <Form.Check.Input
                                                    className={state.errors[field.id]?" has-errors":""}
                                                    type="checkbox"
                                                    checked={(!!state.record && state.record[field.id]) ? checkIfContains(state.record[field.id], recordRef) : false}
                                                    onChange={() => readOnly ? "" : handleAddRecordToRecordListCheckBox(recordRef, field.id)}
                                                ></Form.Check.Input>
                                                <span className="form-check-sign"></span>{recordRef.label}
                                            </Form.Check.Label>
                                        </Form.Check>
                                    </div>
                                )
                            })
                        }
                        {
                            state.errors[field.id] && <div className={"error-message"}>
                                <FormattedMessage id={"Validation.selectAtLeastOneOption"}/>
                            </div>
                        }
                    </Form.Group>
                );
            } else if (field.displayOption === RECORD_FIELD_RECORD_LIST_DISPLAY_OPTION.SEARCH_SELECT) {
                return (
                    <Form.Group key={index + field + 1} className={!readOnly ? "" : "disabled"}>
                        <Form.Label key={index + field + 1}>{field.name}</Form.Label>
                        {
                            !!state.record && !!state.record[field.id] && !!state.record[field.id].length &&
                            <TagsInput
                                value={state.record[field.id].map(tag => tag.name)}
                                onChange={(value) => handleTagsSelect(value, field.id)}
                                tagProps={{className: "react-tagsinput-tag tag-azure"}}
                            />
                        }
                        <FormattedMessage id="Search">
                            {placeholder =>
                                <AsyncSelect
                                    className={"react-select primary" + (state.errors[field.id] ? " has-errors" : "")}
                                    classNamePrefix="react-select"
                                    placeholder={placeholder}
                                    noOptionsMessage={({inputValue}) => !inputValue ?
                                        <FormattedMessage id={"SearchIn"} values={{param: field.name}}/> :
                                        <FormattedMessage id={"Placeholder.noResults"}/>}
                                    isDisabled={readOnly}
                                    value={null}
                                    loadOptions={(inputValue) => promiseOptions(inputValue, field.vid, field.id)}
                                    isInvalid={state.errors[field.id]}
                                    onChange={event => handleAddRecordToRecordList(event, field.id)}
                                />
                            }
                        </FormattedMessage>
                        {
                            state.errors[field.id] && <div className={"error-message"}>
                                <FormattedMessage id={"Validation.selectAtLeastOneOption"}/>
                            </div>
                        }
                    </Form.Group>
                );
            }
        } else if (field.type === RECORD_FIELD_TYPE.SINGLE_CHOICE) {
            return (
                <Form.Group key={index + field + 1} className={!readOnly ? "" : "disabled"}>
                    <Form.Label key={index + field + 1}>{field.name}</Form.Label>
                    <FormattedMessage id="Placeholder.select">
                        {placeholder =>
                            <Select
                                key={index + field + 2}
                                name={field.id}
                                isDisabled={readOnly}
                                className={"react-select primary" + (state.errors[field.id] ? " has-errors" : "")}
                                classNamePrefix="react-select"
                                onChange={event => {
                                    event.target = {}
                                    event.target.name = field.id
                                    handleInputChange(event, field.type)
                                }}
                                value={field.options?.filter(option => state.record && option === state.record[field.id]).map(option => {
                                    return {
                                        value: option,
                                        label: option
                                    }
                                })}
                                options={field.options?.map(option => {
                                    return {
                                        value: option,
                                        label: option
                                    }
                                })}
                                isInvalid={state.errors[field.id]}
                                placeholder={placeholder}
                            />
                        }
                    </FormattedMessage>
                    {
                        state.errors[field.id] && <div className={"error-message"}>
                            <FormattedMessage id={"Validation.selectOneOption"}/>
                        </div>
                    }
                </Form.Group>
            );
        } else if (field.type === RECORD_FIELD_TYPE.MULTIPLE_CHOICE) {
            return (
                <Form.Group key={index + field + 1} className={!readOnly ? "" : "disabled"}>
                    <Form.Label key={index + field + 1}>{field.name}</Form.Label>
                    <TagsInput
                        value={field.options?.filter(option => state.record && state.record[field.id] && state.record[field.id].includes(option))}
                        onChange={(value) => handleMultipleChoiceSelect(value, field.id)}
                        tagProps={{className: "react-tagsinput-tag tag-azure"}}
                    />
                    <FormattedMessage id="Placeholder.select">
                        {placeholder =>
                            <Select
                                key={index + field + 2}
                                className={"react-select primary" + (state.errors[field.id] ? " has-errors" : "")}
                                classNamePrefix="react-select"
                                options={field.options?.map(option => {
                                    return {
                                        value: option,
                                        label: option
                                    }
                                })?.filter(option => !state.record[field.id]?.find(r => r === option.value))}
                                onChange={event => {
                                    event.target = {}
                                    event.target.name = field.id
                                    handleInputChange(event, field.type)
                                }}
                                value={null}
                                isDisabled={readOnly}
                                isInvalid={state.errors[field.id]}
                                placeholder={placeholder}
                            />
                        }
                    </FormattedMessage>
                    {
                        state.errors[field.id] && <div className={"error-message"}>
                            <FormattedMessage id={"Validation.selectAtLeastOneOption"}/>
                        </div>
                    }
                </Form.Group>
            );
        } else if (field.type === RECORD_FIELD_TYPE.IMAGE) {
            return (
                <Form.Group key={index + field} className={!readOnly ? "" : "disabled"}>

                    <Form.Label key={index + field + 1}>{field.name}</Form.Label>
                    <div className={"upload-file-container"+(state.errors[field.id]?" has-errors":"")} onClick={() => clickInputFile(imageInputsFileRef.current[index])}>

                        <Form.Control
                            className="hidden-input-file"
                            key={index + field + 2}
                            onChange={onSelectImage}
                            type="file"
                            ref={el => imageInputsFileRef.current[index] = el}
                            name={field.id}/>


                        {!!!state.selectedFiles[field.id] &&
                            <>
                                <div className="row upload-image-icon">
                                    {state.record[field.id]?
                                        <>
                                            <img src={ClientConfig.URL_RESOURCE_SERVER + ClientConfig.NAMESPACE_RECORDS + "images/recordviews/" + props.vid + "/image/" + state.record[field.id]} alt={"."}/>
                                            <span className="edit-btn">{editIcon}</span></>
                                        : uploadImageIcon
                                    }
                                </div>
                                { !!! state.record[field.id] && <div className="row upload-text-and-btn">
                                    <FormattedMessage id="DropYourImageHereOr"/>
                                </div>}
                            </>
                        }
                        {state.selectedFiles[field.id] &&
                            <>
                                <img src={state.selectedFiles[field.id]?.url} alt="selected image"/>
                            <div className="selected-file-name">
                                {state.selectedFiles[field.id]?.file?.name}
                            </div>
                                <span className="edit-btn">{editIcon}</span>
                            </>
                        }
                    </div>
                    {
                    state.errors[field.id] && <div className={"error-message"}>
                        <FormattedMessage id={"Validation.cannotBeEmpty"}/>
                    </div>
                }
                </Form.Group>
            );
        } else if (field.type === RECORD_FIELD_TYPE.FILE) {
            return (
                <Form.Group key={index + field} className={!readOnly ? "" : "disabled"}>
                    <Form.Label key={index + field + 1}>{field.name}</Form.Label>
                    <div className={"upload-file-container"+(state.errors[field.id]?" has-errors":"")} onClick={() => clickInputFile(imageInputsFileRef.current[index])}>

                        <Form.Control
                            className="hidden-input-file"
                            key={index + field + 2}
                            onChange={onSelectFile}
                            type="file"
                            ref={el => imageInputsFileRef.current[index] = el}
                            name={field.id}/>


                        {!!!state.selectedFiles[field.id] &&
                            <>
                                    {state.record[field.id]?
                                        <div className="file-and-text-container">
                                            <div className="file-container">
                                                {fileIcon}
                                                <i className="delete-file-icon" onClick={(e)=>{e.stopPropagation();deleteExistentFile(field.id)}}>{iconXthinner}</i>
                                            </div>
                                            <div className="text-container">{state.record[field.id]?.originalFileName}</div>
                                            </div>
                                        : uploadFileIcon
                                    }
                                { !!! state.record[field.id] && <div className="row upload-text-and-btn">
                                    <FormattedMessage id="DropYourFileHereOr"/>
                                </div>}
                            </>
                        }
                        {state.selectedFiles[field.id] &&
                            <div className="file-and-text-container">
                               <div className="file-container">
                                   {fileIcon}
                                   <i className="delete-file-icon" onClick={(e)=>{e.stopPropagation();deleteSelectedFile(field.id)}}>{iconXthinner}</i>

                               </div>
                                <div className="selected-file-name">
                                    {state.selectedFiles[field.id]?.file?.name}
                                </div>
                            </div>
                        }
                    </div>
                    {
                        state.errors[field.id] && <div className={"error-message"}>
                            <FormattedMessage id={"Validation.cannotBeEmpty"}/>
                        </div>
                    }
                </Form.Group>
            );
        } else if (field.type === RECORD_FIELD_TYPE.FILE_LIST) {
        return (
            <Form.Group key={index + field} className={!readOnly ? "multi" : "multi disabled"}>
                <Form.Label key={index + field + 1}>{field.name}</Form.Label>
                <div className={"upload-file-container"+(state.errors[field.id]?" has-errors":"")} onClick={() => clickInputFile(imageInputsFileRef.current[index])}>

                    <Form.Control
                        className="hidden-input-file"
                        key={index + field + 2}
                        onChange={onSelectMultipleFiles}
                        type="file"
                        multiple
                        ref={el => imageInputsFileRef.current[index] = el}
                        name={field.id}/>


                    {!!!state.selectedFiles[field.id] &&
                        <>
                        <span className={!state.record[field.id] ? "file-container add-new centered": "file-container add-new"}>
                            {uploadFileIcon}
                            { !state.record[field.id] && <div className="row upload-text-and-btn">
                                <FormattedMessage id="DropYourFilesHereOr"/>
                            </div>}
                        </span>
                            {state.record[field.id] &&
                                state.record[field.id].map((file,index)=>{
                                    return  <div className="file-and-text-container" key={file?.fileName+index}>
                                                <div className="file-container">
                                                    {fileIcon}
                                                    <i className="delete-file-icon" onClick={(e)=>{e.stopPropagation();deleteExistentFile(field.id,index)}}>{iconXthinner}</i>
                                                </div>
                                                <div className="text-container">{file?.originalFileName}</div>
                                    </div>
                                })
                            }

                        </>
                    }
                    {state.selectedFiles[field.id] &&
                        <>
                            {uploadFileIcon}
                            {state.record[field.id] &&
                                state.record[field.id].map((file,index)=>{
                                    return  <div className="file-and-text-container" key={file?.fileName+index}>
                                        <div className="file-container">
                                            {fileIcon}
                                            <i className="delete-file-icon" onClick={(e)=>{e.stopPropagation();deleteExistentFile(field.id,index)}}>{iconXthinner}</i>
                                        </div>
                                        <div className="selected-file-name">{file?.originalFileName}</div>
                                    </div>
                                })
                            }
                            {state.selectedFiles[field.id].map((file,index)=>{
                                return <div className="file-and-text-container" key={index}>
                                <div className="file-container">
                                    {fileIcon}
                                    <i className="delete-file-icon" onClick={(e)=>{e.stopPropagation();deleteSelectedFile(field.id,index)}}>{iconXthinner}</i>
                                </div>
                                <div className="selected-file-name">
                                    {file.file?.name}
                                </div></div>
                            })}
                        </>
                    }
                </div>
                {
                    state.errors[field.id] && <div className={"error-message"}>
                        <FormattedMessage id={"Validation.cannotBeEmpty"}/>
                    </div>
                }
            </Form.Group>
        );
    }
    }

    const clickInputFile = (file) => {
        file.click();
    }

    const setDefaultPaymentFields=async (recordRefId, name, auxRecord,errors) =>{
        let newRecord={...auxRecord};
        let newErrors={...errors};
        if(name==="paymentPlan"){
            let response = await findRecordByIdHandler(recordRefId, props.fieldsUpdateForm.find(field=>field.id===name)?.rtid);
            if (response.ok) {
                let field=props.fieldsUpdateForm.find(field=>field.id==="price");
                let currency = field.currency ? currencies.code(field.currency) : null;
                let price = (response.data[props.workspacePaymentSetup["priceField"]] / Math.pow(10, currency.digits)).toFixed(currency.digits);

                newRecord = {
                    ...newRecord,
                    "planCode": response.data[props.workspacePaymentSetup["paymentPlanCodeField"]],
                    "paymentPlanName":response.data[props.workspacePaymentSetup["paymentPlanNameField"]],
                    "price":price,
                    "months":response.data[props.workspacePaymentSetup["monthsField"]],
                    "editablePrice":response.data[props.workspacePaymentSetup["editablePriceField"]],
                    "vatLevel":response.data[props.workspacePaymentSetup["vatLevelField"]],
                    "recurrent":response.data[props.workspacePaymentSetup["recurrentField"]],
                };

                newErrors={
                    ...newErrors,
                    "planCode": false,
                    "paymentPlanName":false,
                    "price":false,
                    "months":false,
                    "editablePrice":false,
                    "vatLevel":false,
                    "recurrent":false,
                }
            }
        }
        if(name==="merchant"){
            let response = await findRecordByIdHandler(recordRefId, props.fieldsUpdateForm.find(field=>field.id===name)?.rtid);
            if (response.ok) {
                newRecord = {
                    ...newRecord,
                    "merchantName": response.data[props.workspacePaymentSetup["merchantNameField"]],
                    "merchantAddressLine1":response.data[props.workspacePaymentSetup["merchantAddressLine1Field"]],
                    "merchantAddressLine2":response.data[props.workspacePaymentSetup["merchantAddressLine2Field"]],
                    "merchantPostCode":response.data[props.workspacePaymentSetup["merchantPostCodeField"]],
                    "merchantCity":response.data[props.workspacePaymentSetup["merchantCityField"]],
                    "merchantCountry":response.data[props.workspacePaymentSetup["merchantCountryField"]],
                    "merchantRegistrationNr":response.data[props.workspacePaymentSetup["merchantRegistrationNrField"]],
                    "merchantVatNr":response.data[props.workspacePaymentSetup["merchantVatNrField"]],
                    "merchantContact":response.data[props.workspacePaymentSetup["merchantContactField"]],
                    "integrationKey1":response.data[props.workspacePaymentSetup["merchantIntegrationKey1Field"]],
                    "integrationKey2":response.data[props.workspacePaymentSetup["merchantIntegrationKey2Field"]],
                    "integrationKey3":response.data[props.workspacePaymentSetup["merchantIntegrationKey3Field"]],
                };

                newErrors = {
                    ...newErrors,
                    "merchantName":false,
                    "merchantAddressLine1":false,
                    "merchantAddressLine2":false,
                    "merchantPostCode":false,
                    "merchantCity":false,
                    "merchantCountry":false,
                    "merchantRegistrationNr":false,
                    "merchantVatNr":false,
                    "merchantContact":false,
                    "integrationKey1":false,
                    "integrationKey2":false,
                    "integrationKey3":false,
                };
            }
        }

        if(name==="client"){
            let response = await findRecordByIdHandler(recordRefId, props.fieldsUpdateForm.find(field=>field.id===name)?.rtid);
            if (response.ok) {
                newRecord = {
                    ...newRecord,
                    "clientName": response.data[props.workspacePaymentSetup["clientNameField"]],
                    "clientAddressLine1":response.data[props.workspacePaymentSetup["clientAddressLine1Field"]],
                    "clientAddressLine2":response.data[props.workspacePaymentSetup["clientAddressLine2Field"]],
                    "clientPostCode":response.data[props.workspacePaymentSetup["clientPostCodeField"]],
                    "clientCity":response.data[props.workspacePaymentSetup["clientCityField"]],
                    "clientCountry":response.data[props.workspacePaymentSetup["clientCountryField"]],
                    "clientRegistrationNr":response.data[props.workspacePaymentSetup["clientRegistrationNrField"]],
                    "clientVatNr":response.data[props.workspacePaymentSetup["clientVatNrField"]],
                    "clientContact":response.data[props.workspacePaymentSetup["clientContactField"]],
                };
                newErrors = {
                    ...newErrors,
                    "clientName": false,
                    "clientAddressLine1":false,
                    "clientAddressLine2":false,
                    "clientPostCode":false,
                    "clientCity":false,
                    "clientCountry":false,
                    "clientRegistrationNr":false,
                    "clientVatNr":false,
                    "clientContact":false,
                };
            }
        }
        return [newRecord,newErrors];
    }

    const handleAddRecordRef = async (recordRef, name) => {
        let newErrors = {...state.errors};
        if (!!state.errors[name]) {
            newErrors = {
                ...newErrors,
                [name]: false,
            };
        }
        let auxRecord;
        if(!recordRef){
            auxRecord = {
                ...state.record,
                [name]: ""
            };
        } else {
            auxRecord = {
                ...state.record,
                [name]: {
                    "id": recordRef.value,
                    "name": recordRef.label,
                }
            };
        }

        if(props.rtType===RECORD_TYPE_TYPE.PAYMENT){
            [auxRecord,newErrors]=await setDefaultPaymentFields(recordRef.value,name,auxRecord,newErrors);
        }

        await executeScriptWithParam(auxRecord, newErrors);

    }

    const handleAddRecordToRecordListCheckBox = async (recordRef, name) => {
        let newErrors = {...state.errors};
        if (!!state.errors[name]) {
            newErrors = {
                ...newErrors,
                [name]: false,
            };
        }
        const record = {
            "id": recordRef.value,
            "name": recordRef.label,
        };

        let recordAux;

        if (state.record && state.record[name]) {
            const recordsList = state.record[name];
            const index = recordsList.findIndex(tag => tag.id === record.id);
            if (index > -1) {
                recordsList.splice(index, 1);
                recordAux = {
                    ...state.record,
                    [name]: recordsList
                };
            } else {
                recordAux = {
                    ...state.record,
                    [name]: [...recordsList, record]
                };
            }
        } else {
            recordAux = {
                ...state.record,
                [name]: [record]
            };
        }

        await executeScriptWithParam(recordAux, newErrors);
    }

    const handleAddRecordToRecordList = async (recordRef, name) => {
        let newErrors = {...state.errors};
        if (!!state.errors[name]) {
            newErrors = {
                ...newErrors,
                [name]: false,
            };
        }
        const record = {
            "id": recordRef.value,
            "name": recordRef.label,
        };
        let recordAux;
        if (state.record && state.record[name]) {
            const recordsList = state.record[name];
            recordAux = {
                ...state.record,
                [name]: [...recordsList, record]
            };
        } else {
            recordAux = {
                ...state.record,
                [name]: [record]
            };
        }

        await executeScriptWithParam(recordAux, newErrors);

    }

    const handleMultipleChoiceSelect = async (values, name) => {
        let newErrors = {...state.errors};
        if (!!state.errors[name]) {
            newErrors = {
                ...newErrors,
                [name]: false,
            };
        }
        const newValues = values.map(value => {
            return state.record[name].find(option => option === value);
        });

        let auxRecord = {
            ...state.record,
            [name]: newValues,
        };


        await executeScriptWithParam(auxRecord, newErrors);
    }

    const handleTagsSelect = async (values, name) => {
        let newErrors = {...state.errors};
        if (!!state.errors[name]) {
            newErrors = {
                ...newErrors,
                [name]: false,
            };
        }
        const newValues = values.map(value => {
            return state.record[name].find(tag => tag.name === value);
        });
        let auxRecord = {
            ...state.record,
            [name]: newValues,
        };

        await executeScriptWithParam(auxRecord, newErrors);
    }

    const getAllRecordsWithNameContains = async (nameContains, viewId,fieldId) => {
        if(props.rtType===RECORD_TYPE_TYPE.FORM_DATA) {
            return getAllRegistrationsInRecordViewWithNameContainsHandler(nameContains, viewId).then(response => {
                if (response.ok) {
                    if (fieldId) {
                        return response.data.records?.filter(record => !state.record[fieldId]?.find(r => r.id === record.value))
                    } else {
                        return response.data.records;
                    }
                }
            });
        } else {
            return getAllRecordsWithNameContainsHandler(nameContains, viewId, props.mid, props.pageIndex, props.tabIndex).then(response => {
                if (response.ok) {
                    if (fieldId) {
                        return response.data.records?.filter(record => !state.record[fieldId]?.find(r => r.id === record.value))
                    } else {
                        return response.data.records;
                    }
                }
            });
        }
    }

    const getFormIdHash = async (wid, rid) => {
        return getFormIdHashHandler(wid, rid).then(response => {
            if (response.ok) {
                return response.data;
            }
        });
    }

    const promiseOptions = (inputValue,viewId, fieldId) =>
        new Promise(resolve => {
            resolve(getAllRecordsWithNameContains(inputValue,viewId, fieldId));
        });

    const saveRecord = () => {
        if(props.rtType===RECORD_TYPE_TYPE.PAYMENT) {
            for (const key in props.workspacePaymentSetup) {
                if (!props.workspacePaymentSetup[key]) {
                    let options = getNotifyErrorOptions("bc", <FormattedMessage id="Validation.paymentNotCompleted"/>);
                    props.notificationAlertRef.current.notificationAlert(options);
                    return;
                }
            }
        }
        let newRecord = {...state.record}
        props.fieldsUpdateForm.forEach(field => {
            if(state.record && field.type === RECORD_FIELD_TYPE.RECORD && field.displayOption === RECORD_FIELD_RECORD_DISPLAY_OPTION.FORM_LINK && state.linkRecordToForm[field.id] === false) {
                newRecord[field.id] = null
            }
            if (state.record && field.type === RECORD_FIELD_TYPE.BOOLEAN && field.mode === RECORD_FIELD_MODE.WRITE && !!!state.record[field.id]) {
                newRecord[field.id] = false
            } else if (state.record && field.id === "createdBy" || field.id === "createdDate" || field.id === "modifiedBy" || field.id === "modifiedDate") {
                delete newRecord[field.id];
            }
            if(state.record&& field.type===RECORD_FIELD_TYPE.IMAGE && state.selectedFiles[field.id]){
                newRecord[field.id]="";
            } else if(state.record&& field.type===RECORD_FIELD_TYPE.FILE && state.selectedFiles[field.id]){
                newRecord[field.id]={
                    fileName:"",
                    originalFileName:""
                };
            } else if(state.record && field.type===RECORD_FIELD_TYPE.FILE_LIST && state.selectedFiles[field.id] && !state.record[field.id]?.length){
                newRecord[field.id]=[{
                    fileName:"",
                    originalFileName:""
                }];
            }
            if(state.record && field.type===RECORD_FIELD_TYPE.CURRENCY ){
                let currency = field.currency ? currencies.code(field.currency) : null;
                newRecord[field.id]=(state.record[field.id]*Math.pow(10,currency.digits)).toFixed(0);
            }
        });
        const [newErrors, existErrors] = Validations.addRecordValidations(props.fieldsUpdateForm, newRecord, state.selectedFiles,{...state.errors});
        setState({
            ...state,
            errors: newErrors
        });
        if (existErrors) {
            let options=getNotifyErrorOptions("bc");
            props.notificationAlertRef.current.notificationAlert(options);

            return;
        }
        props.setSaveDisabled(true);
        let selectedFilesObj = {};

        for (const property in state.selectedFiles) {
            if(Array.isArray(state.selectedFiles[property])){
                selectedFilesObj[property] = state.selectedFiles[property].map(file=>file.file);
            } else{
                selectedFilesObj[property] = state.selectedFiles[property].file;
            }
        }

        props.saveRecord(newRecord, selectedFilesObj);
    }

    return (
        <div className="save-record-form">
            <CrossaPageTemplate
                hasFooter={true}
                saveDisabled={props.saveDisabled}
                saveChanges={saveRecord}
                saveChangesBtnText={<FormattedMessage id="Button.save"/>}
                closeSlidingPanel={props.closeSlidingPanel}
            >

                <Cropper
                    show={state.cropperShow}
                    onHide={() => setState({...state,cropperShow:false})}
                    image={state.selectedImageForCropper?.picture}
                    setCroppedImage={setCroppedImage}
                />
                {
                    props.fieldsUpdateForm.map((field, index) => {
                        return fieldModeToFormInput(field, index);
                    })
                }
            </CrossaPageTemplate>
        </div>
    );
}

export default SaveRecordForm;
