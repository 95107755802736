import React, {useState} from "react";
import {useTable} from "react-table";
import {RECORD_FIELD_TYPE} from "../../../../util/Constants";
import {DragDropContext, Draggable, Droppable} from "react-beautiful-dnd";
import {FormattedMessage} from "react-intl";

function FieldsComponent({columns, data, changeData}) {

    const [state, setState] = useState({
        dragStart:false,
        currentDraggedField:false

    })

    const {
        getTableProps, // table props from react-table
        getTableBodyProps, // table body props from react-table
        headerGroups, // headerGroups, if your table has groupings
        rows, // rows for the table based on the data passed
        prepareRow // Prepare the row (this function needs to be called for each row before getting the row props)
    } = useTable({
        columns,
        data
    });

    const onDragRow=(result)=>{
        if(!result.destination){
            return;
        }
        changeData(result.source.index,result.destination.index);
        changeDragStart();
    }

    const changeDragStart=(e)=>{
        setState({
            ...state,
            dragStart:!state.dragStart,
            currentDraggedField:e?.source.index
        })
    }

    return (
        <DragDropContext onDragStart={(e) => changeDragStart(e)} onDragEnd={onDragRow}>
            <Droppable droppableId="droppable">
            {(provided) => (
                <table {...getTableProps()} {...provided.dragHandleProps} ref={provided.innerRef}
                       className="table table-with-group-headers table-fixed table-fixed-width draggable"
                       id="table"
                >
                    <thead>
                    {headerGroups.map((headerGroup, index) => (
                        <tr className="empty" key={index} {...headerGroup.getHeaderGroupProps()}>
                            {headerGroup.headers.map(column => (
                                <th {...column.getHeaderProps()}>{column.render("Header")}</th>
                            ))}
                        </tr>
                    ))}
                    </thead>
                    <tbody {...getTableBodyProps()}>
                    {rows.map((row, i) => {
                        prepareRow(row);
                        let hasBorders = false;
                        for (let j = 0; j <= i; j++) {
                            if (rows[j].cells[1]?.value === RECORD_FIELD_TYPE.GROUP_HEADER) {
                                hasBorders = true;
                                break;
                            }
                        }
                        return (<React.Fragment key={i}>
                            {row.cells[1].value === "GroupHeader" && <tr className="no-borders empty" draggable={false}>
                                {
                                    row.cells.map((cell,indexCell) =>
                                        <td key={indexCell}>&nbsp;</td>
                                    )
                                }
                            </tr>}
                            <Draggable
                                key={i}
                                draggableId={"drag" + i}
                                index={i}>
                                {(provided, snapshot) => {
                                    return (row.cells[1].value === "GroupHeader" ?
                                        <tr {...row.getRowProps()}
                                            {...provided.draggableProps}
                                            {...provided.dragHandleProps}
                                            ref={provided.innerRef}
                                            id={row.original.id}
                                            key={i + "" + row}
                                            className={(!state.dragStart ? "" : "no-borders") + (state.currentDraggedField === i ? " dragging" : "")}
                                        >
                                            {row.cells.map(cell => {
                                                return <td {...cell.getCellProps()}
                                                           style={{fontWeight: "bold"}}>
                                                    {cell.column.id!=="type"?
                                                        cell.render("Cell", {
                                                            dragHandleProps: provided.dragHandleProps,
                                                            isSomethingDragging: snapshot.isDraggingOver
                                                        })
                                                        :
                                                        <FormattedMessage id={"FieldType."+row.cells[1].value?.charAt(0).toLowerCase() + row.cells[1].value?.slice(1)} />}
                                                </td>;
                                            })}
                                        </tr>
                                        :
                                        <tr {...row.getRowProps()}
                                            id={row.original.id}
                                            ref={provided.innerRef}
                                            {...provided.draggableProps}
                                            {...provided.dragHandleProps}
                                            className={((hasBorders && !state.dragStart) ? "" : "no-borders ") + (state.currentDraggedField === i ? " dragging" : "")}
                                            key={i + "" + row}
                                        >
                                            {row.cells.map(cell => {
                                                return <td {...cell.getCellProps()}>
                                                    {cell.column.id!=="type"?
                                                        cell.render("Cell", {
                                                            dragHandleProps: provided.dragHandleProps,
                                                            isSomethingDragging: snapshot.isDraggingOver
                                                        })
                                                        :
                                                        <FormattedMessage id={"FieldType."+row.cells[1].value?.charAt(0).toLowerCase() + row.cells[1].value?.slice(1)} />}
                                                </td>;
                                            })}
                                        </tr>)
                                }}
                            </Draggable>
                        </React.Fragment>)
                    })}
                    {provided.placeholder}
                    </tbody>
                </table>
            )}
        </Droppable>
        </DragDropContext>
    );
}


export default FieldsComponent;
