import React from "react";
import {Draggable} from "react-beautiful-dnd";
import {Button, Form, OverlayTrigger, Tooltip} from "react-bootstrap";
import Select from "react-select";
import {FormattedMessage} from "react-intl";
import DateTimePicker from "react-datetime-picker";
import {
    FORM_FIELD_SINGLE_CHOICE_DISPLAY_OPTION,
    FORM_FIELD_TEXT_DISPLAY_OPTION,
    FORM_FIELD_TYPE,
} from "../../../util/Constants";
import TextareaAutosize from "react-textarea-autosize";
import {hideIcon} from "../../../components/Icons";

function FormField(props) {
    const {
        draggableId,
        index,
        type,
        displayOption,
        name,
        description,
        placeholder,
        defaultDate,
        timeField,
        options,
        defaultOption,
        hide,
        validation,
        handleFormFieldClick
    } = props;

    const getDefaultFieldName = () => {
        if (type === FORM_FIELD_TYPE.TEXT && displayOption === FORM_FIELD_TEXT_DISPLAY_OPTION.SINGLE_LINE) {
            return <FormattedMessage id="Form.text"/>;
        } else if (type === FORM_FIELD_TYPE.TEXT && displayOption === FORM_FIELD_TEXT_DISPLAY_OPTION.TEXT_AREA) {
            return <FormattedMessage id="Form.textArea"/>;
        } else if (type === FORM_FIELD_TYPE.TEXT && displayOption === FORM_FIELD_TEXT_DISPLAY_OPTION.EMAIL) {
            return <FormattedMessage id="Form.email"/>;
        } else if (type === FORM_FIELD_TYPE.DATE) {
            return <FormattedMessage id="Form.date"/>;
        } else if (type === FORM_FIELD_TYPE.TEXT && displayOption === FORM_FIELD_TEXT_DISPLAY_OPTION.PHONE) {
            return <FormattedMessage id="Form.phoneNumber"/>;
        } else if (type === FORM_FIELD_TYPE.SINGLE_CHOICE && displayOption === FORM_FIELD_SINGLE_CHOICE_DISPLAY_OPTION.DROPDOWN) {
            return <FormattedMessage id="Form.dropdownList"/>;
        } else if (type === FORM_FIELD_TYPE.SINGLE_CHOICE && displayOption === FORM_FIELD_SINGLE_CHOICE_DISPLAY_OPTION.RADIO_BUTTON_GROUP) {
            return <FormattedMessage id="Form.singleChoice"/>;
        } else if (type === FORM_FIELD_TYPE.MULTIPLE_CHOICE) {
            return <FormattedMessage id="Form.multipleChoice"/>;
        } else if (type === FORM_FIELD_TYPE.GROUP_HEADER) {
            return <FormattedMessage id="Form.output"/>;
        }
    }

    return (
        <Draggable draggableId={draggableId} index={index} type="TASK">
            {provided => (
                <div
                    className={"clickable-form-group-container" + (props.draggableId === props.selectedDraggableId && props.rightPanelOpened? " selected" : "")}
                    onClick={() => handleFormFieldClick ? handleFormFieldClick(props) : ""}
                    ref={provided.innerRef}
                    {...provided.draggableProps}
                    {...provided.dragHandleProps}
                >
                    <Form.Group>

                        <div className="row ml-0 mr-0">
                            <Form.Label>{name ? name : getDefaultFieldName()}</Form.Label>
                            {validation?.mandatory && <Form.Label className="red">*</Form.Label>}
                            {hide===true &&
                                <Button
                                    variant="danger"
                                    size="xxl"
                                    className="btn-link remove text-danger mb-0 ml-auto pt-0"
                                    disabled
                                    onClick={(e) => props.handleFormFieldDelete(e, props)}
                                >
                                    <span>{hideIcon}</span>
                                </Button>}
                            {draggableId && !!!draggableId.startsWith("Def-") &&
                                <OverlayTrigger
                                    onClick={(e) => e.preventDefault()}
                                    overlay={
                                        <Tooltip>
                                            <FormattedMessage id="Button.delete"/>
                                        </Tooltip>
                                    }
                                >
                                    <Button
                                        variant="danger"
                                        size="xxl"
                                        className={"btn-link remove text-danger mb-0 pt-0" + (hide?"":" ml-auto")}
                                        onClick={(e) => props.handleFormFieldDelete(e, props)}
                                    >
                                        <i className="fa fa-trash"/>
                                    </Button>
                                </OverlayTrigger>
                            }
                        </div>
                        <div className="form-description">
                            {description ? description : <FormattedMessage id="Form.descriptionOpt"/>}
                        </div>
                        {type === FORM_FIELD_TYPE.TEXT && displayOption === FORM_FIELD_TEXT_DISPLAY_OPTION.SINGLE_LINE &&
                            <FormattedMessage id="Form.placeholderText">
                                {placeholderAux =>
                                    <Form.Control
                                        placeholder={placeholder ? placeholder : placeholderAux}
                                        type="text"
                                        readOnly={true}
                                    />
                                }
                            </FormattedMessage>
                        }
                        {type === FORM_FIELD_TYPE.TEXT && displayOption === FORM_FIELD_TEXT_DISPLAY_OPTION.TEXT_AREA &&
                            <FormattedMessage id="Form.placeholderText">
                                {placeholderAux =>
                                    <TextareaAutosize
                                        minRows={3}
                                        placeholder={placeholder ? placeholder : placeholderAux}
                                        className={"disabled form-control"}
                                        as="textarea"
                                        // style={{height: '100px'}}
                                        readOnly={true}
                                    />
                                }
                            </FormattedMessage>
                        }
                        {type === FORM_FIELD_TYPE.TEXT && displayOption === FORM_FIELD_TEXT_DISPLAY_OPTION.EMAIL &&
                            <Form.Control
                                placeholder={placeholder ? placeholder : "example@mail.com"}
                                type="email"
                                readOnly={true}
                            />
                        }
                        {type === FORM_FIELD_TYPE.DATE &&
                            <DateTimePicker
                                maxDate={new Date("9999-12-31")}
                                disableClock={true}
                                format={timeField ? "y-MM-dd HH:mm" : "y-MM-dd"}
                                value={(defaultDate ?  new Date(defaultDate) : "")}
                                disabled={true}
                            />
                        }
                        {type === FORM_FIELD_TYPE.TEXT && displayOption === FORM_FIELD_TEXT_DISPLAY_OPTION.PHONE &&
                            <Form.Control
                                placeholder={placeholder ? placeholder : "+1512312321"}
                                type="tel"
                                readOnly={true}
                            />
                        }
                        {type === FORM_FIELD_TYPE.SINGLE_CHOICE && displayOption === FORM_FIELD_SINGLE_CHOICE_DISPLAY_OPTION.DROPDOWN &&
                            <FormattedMessage id="Placeholder.select">
                                {placeholderAux =>
                                    <Select
                                        className="react-select primary"
                                        classNamePrefix="react-select"
                                        placeholder={placeholder ? placeholder : placeholderAux}
                                        options={options?.map(option => {
                                            return {
                                                "label": option,
                                                "value": option
                                            }
                                        })}
                                        value={defaultOption ?
                                            {
                                                "label": defaultOption,
                                                "value": defaultOption
                                            } : ""}
                                    />
                                }
                            </FormattedMessage>
                        }
                        {type === FORM_FIELD_TYPE.SINGLE_CHOICE && displayOption === FORM_FIELD_SINGLE_CHOICE_DISPLAY_OPTION.RADIO_BUTTON_GROUP && options?.map((option, index) =>
                            <Form.Check
                                type="radio"
                                label={option}
                                checked={false}
                                readOnly={true}
                                key={draggableId + "." + index}
                            />)}
                        {type === FORM_FIELD_TYPE.MULTIPLE_CHOICE && options?.map((option, index) => <Form.Check
                            type="checkbox"
                            label={option}
                            checked={false}
                            readOnly={true}
                            key={draggableId + "." + index}
                        />)}
                    </Form.Group>
                </div>

            )}
        </Draggable>
    );
}

export default FormField;
