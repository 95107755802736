import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import SaveMemberForm from "views/workspaceAdmin/members/components/SaveMemberForm.js";
import { getDataForMemberHandler } from "handlers/workspaceAdminHandlers/membersHandlers/SaveMemberHandler";
import { saveMemberHandler } from "handlers/workspaceAdminHandlers/membersHandlers/SaveMemberHandler";
import AdminNavbar from "../../../components/AdminNavbar";
import {FormattedMessage} from "react-intl";
import {getNotifySuccessOptions} from "util/Util";
import NotificationAlert from "react-notification-alert";
import {CODE_ERROR, FORM_ACTION, RECORD_FIELD_TYPE} from "../../../util/Constants";
import SweetAlert from "react-bootstrap-sweetalert";

// react-bootstrap components

function SaveMember(props) {
    const history = useHistory();
    const [state, setState] = useState({
        fields: [],
        member: {},
        workspaceRoles: [],
        memberVid: null,
        alert:null,
    });
    const [saveDisabled,setSaveDisabled] = useState(false);
    const wid = props.match.params.wid;
    const memberId = props.match.params.mid;


    useEffect(() => {
        getPageContent();
    }, [wid, memberId])

    const getPageContent = async () => {
        getDataForMemberHandler(wid, memberId).then(response => {
            if (response.ok) {
                setState({
                    ...state,
                    ...response.data
                })
            }
        })
    }

    const showAlert = (message) => {
        setState({
            ...state,
            alert: <SweetAlert
                type="danger"
                title=""
                style={{ display: "block", marginTop: "-100px", fontSize: "13px" }}
                btnSize="md"
                confirmBtnBsStyle="default"
                confirmBtnText={<FormattedMessage id="Button.ok" />}
                openAnim="false"
                onCancel={() => hideAlert()}
                onConfirm={() => hideAlert()}
                allowEscape
                closeOnClickOutside
                customClass="crossa-alert"
            >  <FormattedMessage id={message}/>
            </SweetAlert>
        });
    }


    const hideAlert = () => {
        setState({
            ...state,
            alert: null
        });
    }

    const saveMember = async (newMember, files) => {
        saveMemberHandler(memberId, newMember, files, state.fields, wid, state.memberVid).then(response => {
            if (response.ok) {
                if(memberId){
                    let options=getNotifySuccessOptions("bc");
                    notificationAlertRef.current.notificationAlert(options);
                } else {
                    history.push("/workspace-admin/" + wid + "/members");
                }
            } else{
                if(response.errorCode===CODE_ERROR.INVALID_WORKSPACE_USER){
                    showAlert("Validation.memberExists");
                }
            }
            setSaveDisabled(false);
        })
    }
    const notificationAlertRef = React.useRef(null);

    return (
            <>
                <div className="rna-container">
                    <NotificationAlert ref={notificationAlertRef} />
                </div>
                {state.alert}
                <AdminNavbar
                    title={
                        (state.member && Object.keys(state.member).length !== 0) ? <FormattedMessage id="EditMember" /> :
                            <FormattedMessage id="NewMember" />
                    }
                    hasBackBtn={true}
                />


            <SaveMemberForm
                title={""}
                action={memberId?FORM_ACTION.UPDATE:FORM_ACTION.INSERT}
                fields={state.fields?.filter(field => field.type !== RECORD_FIELD_TYPE.NOTIFICATIONS)}
                record={state.member}
                saveRecord={saveMember}
                notificationAlertRef={notificationAlertRef}
                roles={state.workspaceRoles.map(role => {
                    return {
                        "value": role.id,
                        "label": role.name
                    }
                })}
                vid={state.memberVid}
                saveDisabled={saveDisabled}
                setSaveDisabled={setSaveDisabled}
            />
            </>
    );
}

export default SaveMember;
