import React, {useEffect, useState} from "react";
import {useHistory} from "react-router-dom";
import FieldsComponent from "views/workspaceAdmin/recordType/components/FieldsComponent.js"
import RecordFieldForm from "./RecordFieldForm.js";
import * as Validations from "validations/workspaceAdmin/recordType/RecordTypeValidations.js";
import Footer from "components/Footer.js"
import {FORM_ACTION, RECORD_FIELD_TYPE, RECORD_TYPE_TYPE, SIDEBAR_STYLE, VALIDATIONS} from "util/Constants";
import {
    defaultFieldsRegular,
    defaultFieldsMember,
    defaultFieldsForm,
    defaultFieldsPayment,
    displayOptions,
    fieldType
} from "views/workspaceAdmin/recordType/utils/RecordTypeField.js";
import {FormattedMessage} from 'react-intl';
import {Button, Card, Col, Form, OverlayTrigger, Row, Tooltip,} from "react-bootstrap";
import {editIcon, iconX} from "../../../components/Icons";
import {getRecordTypeByIdHandler} from "handlers/workspaceAdminHandlers/recordTypeHandlers/SaveRecordTypeHandlers.js";
import {saveRecordTypeHandler} from "handlers/workspaceAdminHandlers/recordTypeHandlers/SaveRecordTypeHandlers.js";
import SlidingPanel from "../../../components/SlidingPanel";
import CrossaPageTemplate from "../../../components/CrossaPageTemplate";
import AdminNavbar from "../../../components/AdminNavbar";
import NotificationAlert from "react-notification-alert";
import {getNotifyErrorOptions, getNotifySuccessOptions} from "util/Util";

function SaveRecordTypePage(props) {
    const history = useHistory();
    const wid = props.match.params.wid;
    const recordTypeId = props.match.params.rid;

    const [state, setState] = useState({
        recordFields: [],
        hiddenRecordFields:[],
        recordField: {
            "name": "",
            "type": "",
            "displayOption": "",
            "validation": {
                "mandatory": false,
            },
            "options": null,
            "optionsColors": null,
            "timeField": false
        },
        errors: {
            "name": false,
            "nameMsg": "",
            "description": false
        },
        recordType: {
            "name": "",
            "description": "",
            "type": RECORD_TYPE_TYPE.REGULAR,
        },
        action: "",
        currentStyle: SIDEBAR_STYLE.CLOSED,
    });

    const [saveDisabled, setSaveDisabled] = useState(false);

    useEffect(() => {
        getPageContent();
    }, [recordTypeId]);

    const getPageContent = async () => {
        if (recordTypeId) {
            getRecordTypeByIdHandler(recordTypeId).then(response => {
                if (response.ok) {
                    setState({
                        ...state,
                        ...response.data,
                    });
                }
            });
        } else {
            setState({
                ...state,
                recordFields: defaultFieldsRegular,
            });
        }
    }

    const editRecordField = (recordField, index) => {
        setState({
            ...state,
            recordField: {
                "id": recordField.id,
                "name": recordField.name,
                "type": recordField.type,
                "vid": recordField.vid,
                "displayOption": recordField.displayOption,
                "validation": recordField.validation,
                "index": index,
                "currency": recordField.currency,
                "options": recordField.options,
                "optionsColors": recordField.optionsColors ? recordField.optionsColors : recordField.options?.map(() => ""),
                "timeField": recordField.timeField
            },
            action: FORM_ACTION.EDIT,
            currentStyle: SIDEBAR_STYLE.OPEN,
        });
    }

    const changeData = (sourceIndex,destinationIndex) => {
        const newData = Array.from(state.recordFields);
        const [removed] = newData.splice(sourceIndex, 1);
        newData.splice(destinationIndex, 0, removed);

        setState({
            ...state,
            recordFields: newData,
        });
    }

    const addRecordField = newRecordField => {
        let newRecordFields;
        if (newRecordField.hasOwnProperty("index")) {
            newRecordFields = [...state.recordFields];
            newRecordFields[newRecordField.index] = newRecordField;
        } else {
            newRecordFields = [...state.recordFields, newRecordField];
        }
        setState({
            ...state,
            recordFields: newRecordFields,
            currentStyle: SIDEBAR_STYLE.CLOSED,
        });
    }

    const closeSlidingPanel = () => {
        setState({
            ...state,
            currentStyle: SIDEBAR_STYLE.CLOSED,
            recordField: {
                "name": "",
                "type": "",
                "displayOption": "",
                "validation": {
                    "mandatory": false,
                },
                "currency": "",
                "options": null,
                "optionsColors": null,
                "timeField": false,
            },
        });
    }

    const deleteRecordField = (recordFieldName) => {
        const newRecordFields = state.recordFields.filter(element => element.name !== recordFieldName);
        setState({
            ...state,
            recordFields: newRecordFields,
        });
    }

    const handleInput = event => {
        let newErrors = {...state.errors,};

        if (!!state.errors[event.target.name]) {
            newErrors = {
                ...newErrors,
                [event.target.name]: false,
            };
        }
        setState({
            ...state,
            recordType: {
                ...state.recordType,
                [event.target.name]: event.target.value,
            },
            errors: newErrors,
        });
    }

    const handleSubmit = event => {
        event.preventDefault();
    }

    const saveRecordType = async () => {
        const newRecordType = {
            ...state.recordType,
            "fields": [...state.recordFields,...state.hiddenRecordFields],
            "wid": wid,
        };

        const [newErrors, existErrors] = Validations.addRecordTypeValidations(state.recordType, {...state.errors});
        setState({
            ...state,
            errors: newErrors,
        });

        if (existErrors) {
            let options = getNotifyErrorOptions("bc");
            notificationAlertRef.current.notificationAlert(options);
            return;
        }

        setSaveDisabled(true);
        saveRecordTypeHandler(recordTypeId, newRecordType).then(response => {
            if (response.ok) {
                if(recordTypeId){
                    let options = getNotifySuccessOptions("bc");
                    notificationAlertRef.current.notificationAlert(options);
                } else {
                    history.push("/workspace-admin/" + wid + "/record-types/");
                }
            }
            setSaveDisabled(false);
        });
    }

    const columns = [
        {
            Header: <FormattedMessage id="Name"/>,
            accessor: "name"
        },
        {
            Header: <FormattedMessage id="RecordFieldType"/>,
            accessor: "type"
        },
        {
            Header: <FormattedMessage id="Actions"/>,
            accessor: "actions"
        },
    ];

    const isDeleteButtonVisible = fieldId => {
        let idList = [];
        if (state.recordType.type === RECORD_TYPE_TYPE.REGULAR) {
            idList = defaultFieldsRegular.map(field => field.id);
        } else if (state.recordType.type === RECORD_TYPE_TYPE.MEMBER) {
            idList = defaultFieldsMember.map(field => field.id);
        } else if (state.recordType.type === RECORD_TYPE_TYPE.FORM) {
            idList = defaultFieldsForm.map(field => field.id);
        } else if (state.recordType.type === RECORD_TYPE_TYPE.FORM_DATA) {
            idList = defaultFieldsForm.map(field => field.id);
        } else if (state.recordType.type === RECORD_TYPE_TYPE.PAYMENT) {
            idList = defaultFieldsPayment.map(field => field.id);
        }
        return !idList.includes(fieldId);
    }

    const createRow = (prop, index) => {
        const isDeleteButtonHidden = !!isDeleteButtonVisible(prop.id);

        return {
            ...prop,
            actions: (

                <div className="actions-right">
                    <Row>
                        <div style={{ width: "100%", display: "flex", justifyContent: "flex-end", }}>
                            <OverlayTrigger
                                onClick={(e) => e.preventDefault()}
                                overlay={
                                    <Tooltip>
                                        <FormattedMessage id="Button.editNoParams" />
                                    </Tooltip>
                                }
                            >
                                <Button
                                    variant="warning"
                                    size="sm"
                                    className="text-warning btn-link edit"
                                    onClick={() => editRecordField(prop, index)}
                                >
                                    <span>{editIcon}</span>
                                </Button>
                            </OverlayTrigger>

                            <OverlayTrigger
                                onClick={(e) => e.preventDefault()}
                                overlay={
                                    <Tooltip>
                                        <FormattedMessage id="Button.delete" />
                                    </Tooltip>
                                }
                            >
                                <div style={!isDeleteButtonHidden ? { visibility: "hidden" } : {}}>
                                    <Button
                                        variant="danger"
                                        size="sm"
                                        className="btn-link remove text-danger"
                                        onClick={isDeleteButtonHidden ? () => deleteRecordField(prop.name) : null}
                                    >
                                        <span>{iconX}</span>

                                    </Button>
                                </div>
                            </OverlayTrigger>
                        </div>
                    </Row>
                </div>
            ),
        }
    }

    const createNewRecordField = () => {
        setState({
            ...state,
            recordField: {
                "name": "",
                "type": "",
            },
            action: FORM_ACTION.INSERT,
            currentStyle: SIDEBAR_STYLE.OPEN
        });
    }
    const notificationAlertRef = React.useRef(null);

    return (
        <>
            <div className="rna-container">
                <NotificationAlert ref={notificationAlertRef} />
            </div>
            <AdminNavbar
                title={recordTypeId ?
                    <FormattedMessage id="EditRecordType" />
                    :
                    <FormattedMessage id="NewRecordType" />
                }
                hasBackBtn={true}
                hasRightBtn={true}
                rightBtnHasIcon={true}
                rightBtnName= <FormattedMessage id="Button.addRecordField"/>
                rightButtonAction={() => createNewRecordField()}
            />
            <CrossaPageTemplate>
                <Row>
                    <SlidingPanel
                        title={<FormattedMessage id="RecordField"/>}
                        currentStyle={state.currentStyle}
                        closeSlidingPanel={closeSlidingPanel}
                        content={
                            <RecordFieldForm
                                addRecordField={addRecordField}
                                recordField={state.recordField}
                                fieldTypes={fieldType}
                                displayOptions={displayOptions}
                                allRecordFields={state.recordFields.map(recordField => recordField.name)}
                                closeSlidingPanel={closeSlidingPanel}
                                currentStyle={state.currentStyle}
                                wid={wid}
                                notificationAlertRef={notificationAlertRef}
                                editType={state.recordField.type !== RECORD_FIELD_TYPE.PAYMENT && (state.action === FORM_ACTION.INSERT || !state.recordType.hasRecords)}
                            />
                        }>
                    </SlidingPanel>
                    <Col md={12}>
                        <div className="stacked-form">
                            <Row>
                                <Col md={12}>

                                    <Card.Body>
                                        <Form onSubmit={handleSubmit}>
                                            <Form.Group>
                                                <label><FormattedMessage id="Name" /></label>
                                                <FormattedMessage id="Name" >
                                                    {placeholder =>
                                                        <Form.Control
                                                            name="name"
                                                            placeholder={placeholder}
                                                            type="text"
                                                            value={state.recordType.name || ""}
                                                            onChange={handleInput}
                                                            isInvalid={state.errors.name}
                                                            className={state.errors.name && "has-errors"}
                                                        />
                                                    }
                                                </FormattedMessage>
                                                <Form.Control.Feedback type="invalid">
                                                    <FormattedMessage id={"Validation.cannotBeEmptyAndParamMinParamMax"}
                                                                      values={{
                                                                          paramMin: VALIDATIONS.MIN_LENGTH_DEFAULT,
                                                                          paramMax: VALIDATIONS.MAX_LENGTH_DEFAULT
                                                                      }}/>
                                                </Form.Control.Feedback>
                                            </Form.Group>
                                            <Form.Group>
                                                <label><FormattedMessage id="Description" /></label>
                                                <FormattedMessage id="Description" >
                                                    {placeholder =>
                                                        <Form.Control
                                                            as="textarea"
                                                            name="description"
                                                            placeholder={placeholder}
                                                            type="text"
                                                            value={state.recordType.description}
                                                            onChange={handleInput}
                                                            className={state.errors.description & "has-errors"}
                                                            isInvalid={state.errors.description}
                                                        />
                                                    }
                                                </FormattedMessage>
                                                <Form.Control.Feedback type="invalid">
                                                    <FormattedMessage id={"Validation.cannotBeEmpty"}/>
                                                </Form.Control.Feedback>
                                            </Form.Group>
                                        </Form>
                                        <Row className="top-row">
                                            <Col className="title"><h4><FormattedMessage id="RecordFields" /></h4></Col>
                                        </Row>
                                        <FieldsComponent
                                            columns={columns}
                                            data={state.recordFields.map((prop, index) => createRow(prop, index))}
                                            changeData={changeData}
                                        />
                                    </Card.Body>
                                </Col>
                            </Row>
                        </div>
                    </Col>
                </Row>

                <Footer
                    save={saveRecordType}
                    saveDisabled={saveDisabled}
                    cancel={() => history.goBack()}
                    isEnable={true} />
            </CrossaPageTemplate>
        </>


    );
}

export default SaveRecordTypePage;
