
import * as Validations from "validations/validations.js"
import {RECORD_FIELD_TYPE} from "../../util/Constants";

const addRecordValidations = (fields, record, files, errors) => {
    let newErrors = {};
    fields.forEach(field => {
        if(field.id==="email"){
            if(!record[field.id] || !record[field.id].match(/^([A-Za-z0-9._]+)([@])([A-Za-z0-9]+)([.])([A-Za-z]{2,4})$/)){
                newErrors[field.id] = true;
            }
        } else if(field.validation?.mandatory && (field.type===RECORD_FIELD_TYPE.IMAGE || field.type===RECORD_FIELD_TYPE.FILE || field.type===RECORD_FIELD_TYPE.FILE_LIST)){
            if(!files[field.id] && !record[field.id]){
                newErrors[field.id] = true;
            }
        } else if (field.validation?.mandatory && (!record[field.id] || record[field.id].length === 0)) {
            newErrors[field.id] = true;
        }
    })
    errors = {
        ...errors,
        ...newErrors
    }
    const existErrors = Validations.checkIfError(errors);

    return [errors, existErrors];
}

export {
    addRecordValidations,
}
