const getItem = (key) => sessionStorage.getItem(key);

const setItem = (key, value) => sessionStorage.setItem(key, value);

const clearStorage = () => {
    sessionStorage.clear();
    localStorage.clear();
}

const removeItem = key => sessionStorage.removeItem(key);

const getMemberName = () => localStorage.getItem("member_name");
const setMemberName = (value) => localStorage.setItem("member_name", value);

const getUsername = () => localStorage.getItem("username");
const setUsername = (value) => localStorage.setItem("username", value);

const getAccessToken = () => localStorage.getItem("access_token");
const setAccessToken = (value) => localStorage.setItem("access_token", value);

const getRefreshToken = () => localStorage.getItem("refresh_token");
const setRefreshToken = (value) => localStorage.setItem("refresh_token", value);

const getIsSuperAdmin = () => localStorage.getItem("isSuperAdmin");
const setIsSuperAdmin = (value) => localStorage.setItem("isSuperAdmin", value);


const getIsActive = (wid) => (localStorage.getItem("is_active"))?JSON.parse(localStorage.getItem("is_active"))[wid]:null;
const setIsActive = (value) => localStorage.setItem("is_active", JSON.stringify(value));


const isLoggedIn = () => {
    if (localStorage.getItem("username") !== null) {
        sessionStorage.removeItem("redirect");
        return true;
    }
    sessionStorage.setItem("redirect", window.location.hash);
    return false;
}

export {
    clearStorage,
    getItem,
    isLoggedIn,
    removeItem,
    setItem,
    getUsername,
    setUsername,
    getRefreshToken,
    setRefreshToken,
    getAccessToken,
    setAccessToken,
    getIsSuperAdmin,
    setIsSuperAdmin,
    setMemberName,
    getMemberName,
    getIsActive,
    setIsActive
}
