import React, {useEffect, useState} from "react";

import {
    Row,
    Card,
    Col,

} from "react-bootstrap";
import CrossaPageTemplate from "../../components/CrossaPageTemplate";
import AdminNavbar from "../../components/AdminNavbar";
import {FormattedMessage} from "react-intl";
import * as StorageService from "../../service/StorageService";
import {EventBus} from "../../util/EventBus";
import welcomeImg from '../../assets/img/home-avatar.svg';
function Home() {

    let workspaceName = StorageService.getItem("workspace_name");
    let memberName = StorageService.getMemberName();
    let memberEmail = StorageService.getUsername();

    const [name, setName] = useState(memberName);

    useEffect(() => {
        EventBus.on("sidebarLoaded", (data) => {
            setName(data.name)
        });
    }, [])

    return (
        <>
            <AdminNavbar
                title={<FormattedMessage id="Home" />}
            />
        <CrossaPageTemplate>
            <Row>

                <Card className="welcome-card">
                    <Card.Body className="row">
                        <Col className="text-col">
                            <h2>{name ? name : memberEmail}, <FormattedMessage id="WelcomeTo"/> {workspaceName}! 🔑 </h2>
                            <p>
                                <FormattedMessage id="YouAreCurrentlyLoggedAs"/>
                                &nbsp;
                                <span className="role"><FormattedMessage id="WorkspaceAdmin"/></span>
                            </p>
                        </Col>
                        <Col>
                            <img src={welcomeImg} alt={"."}/>
                        </Col>

                    </Card.Body>

                </Card>
            </Row>
        </CrossaPageTemplate>
        </>

    );
}

export default Home;
