import {Button, Col, Form, OverlayTrigger, Row, Tooltip} from "react-bootstrap";
import {FormattedMessage} from "react-intl";
import React, {useEffect, useMemo, useState} from "react";
import {useWidgetChange, useWidgets} from "../context/WidgetsContext";
import {
    RECORD_FIELD_MODE,
    RECORD_FIELD_TYPE,
    SORT_DIRECTION, WIDGET_OPERATOR,
    WIDGET_TYPE
} from "../../../../util/Constants";
import Select from "react-select";
import {useAllViews} from "../context/ViewProvider";
import DisplayFieldsTable from "./DisplayFieldsTable";
import {arrowDownwardIcon, arrowUpwardIcon} from "../../../../components/Icons";
import {widgetOperatorOptions, widgetRowsOptions, widgetTypeOptions} from "../utils/MenuConstants";
import PreviewComponent from "./PreviewComponent";

function WidgetContent(props) {
    const widgetErrors = useMemo(() => props.errors?.dashboard?.widgets?.[props.widgetIndex], [props.errors,props.widgetIndex])

    const widgets = useWidgets();
    const widgetChange = useWidgetChange();
    const allViews = useAllViews();
    const allViewOptions = allViews.map((view, index) => {
        return {
            "label": view.name,
            "value": index,
            "id": view.id
        }
    });
    const [currentViewOption, setCurrentViewOption] = useState(props.viewId);
    const [viewIndex, setViewIndex] = useState(allViewOptions.findIndex(view => view.id === props.viewId) || -1);

    useEffect(() => {
        const currentView = allViewOptions.find(view => view.id === props.viewId);
        if (!!!currentView) {
            setCurrentViewOption(false);
            return;
        }
        setViewIndex(allViewOptions.findIndex(view => view.id === props.viewId));
        setCurrentViewOption(currentView);
    }, [props.viewId, allViews.length]);

    const handleWidgetTypeChange = event => {
        widgetChange.changeWidgetType(props.widgetIndex, event.value);
    }

    const handleShowWidget = event => {
        widgetChange.changeShowWidget(props.widgetIndex, event.target.checked);
    }

    const handleTitleInputChange = event => {
        widgetChange.changeWidgetTitle(props.widgetIndex, event.target.value);
    }

    const handleDescriptionInputChange = event => {
        widgetChange.changeWidgetDescription(props.widgetIndex, event.target.value);
    }

    const handleShowAfterLoginWidget = event => {
        widgetChange.changeShowAfterLoginWidget(props.widgetIndex, event.target.checked);
    }

    const handleViewChange = event => {
        setViewIndex(event.value);
        widgetChange.changeWidgetView(props.widgetIndex, event.id);
    }

    const handleRowsShownInputChange = event => {
        widgetChange.changeRowsShown(props.widgetIndex, event.value);
    }

    const handleEnableDefaultSorting = event => {
        widgetChange.changeWidgetEnableDefaultSorting(props.widgetIndex, event.target.checked);
    }

    const handleSortingFieldChange = event => {
        widgetChange.changeWidgetSortingField(props.widgetIndex, event.value);
    }

    const handleSortDirectionChange = () => {
        if(props.widgetInfo.sortDirection === SORT_DIRECTION.DESC) {
            widgetChange.changeWidgetSortDirection(props.widgetIndex, SORT_DIRECTION.ASC);
        } else {
            widgetChange.changeWidgetSortDirection(props.widgetIndex, SORT_DIRECTION.DESC);
        }
    }

    const handleGroupingFieldChange = event => {
        widgetChange.changeWidgetGroupingField(props.widgetIndex, event.value);
    }

    const handleOperatorFieldChange = event => {
        widgetChange.changeWidgetOperatorField(props.widgetIndex, event.value);
    }

    const handleOperatorChange = event => {
        widgetChange.changeWidgetOperator(props.widgetIndex, event.value);
    }

    const handleDeleteWidget = () => {
        widgetChange.deleteWidget(props.widgetIndex)
    }

    const columns = [
        {
            Header: <FormattedMessage id="Name"/>,
            accessor: "name",
            headerClassName: "crossATableColumn"
        },
        {
            Header: <FormattedMessage id="Type"/>,
            accessor: "type",
            headerClassName: "crossATableColumn"
        },
        {
            Header: <FormattedMessage id="Display"/>,
            accessor: "display",
            headerClassName: "crossATableColumn"
        },
        {
            Header: <FormattedMessage id="Operator"/>,
            accessor: "mode",
            headerClassName: "crossATableColumn"
        },
    ];

    const modeOptions = [
        {
            "label": <FormattedMessage id="Read"/>,
            "value": RECORD_FIELD_MODE.READ,
        },
        {
            "label": <FormattedMessage id="Write"/>,
            "value": RECORD_FIELD_MODE.WRITE,
        },
        {
            "label": <FormattedMessage id="Hide"/>,
            "value": RECORD_FIELD_MODE.HIDE,
        }];

    return (
        <div>
            <Form.Group>
                <Row>
                    <Col>
                        <FormattedMessage id="ShowWidget"/>
                    </Col>
                    <Col className="inline-switch-col">
                        <Form.Check
                            className="ml-3"
                            type="switch"
                            id={"show-widget-switch"+props.widgetIndex}
                            checked={props.widgetInfo.visible || false}
                            onChange={handleShowWidget}
                        />
                    </Col>

                </Row>
            </Form.Group>
            <Form.Group>
                <Form.Label><FormattedMessage id="WidgetType"/></Form.Label>
                <Select
                    className={"react-select primary"+ (widgetErrors?.type?" has-errors":"")}
                    classNamePrefix="react-select"
                    onChange={handleWidgetTypeChange}
                    options={widgetTypeOptions}
                    value={widgetTypeOptions.find(widget=>widget.value===props.widgetInfo.type)}
                />
                {widgetErrors?.type && <div className={"error-message"}>
                    <FormattedMessage id={"Validation.selectOneOption"}/>
                </div> }
            </Form.Group>
            <Form.Group>
                <Form.Label><FormattedMessage id="Title"/></Form.Label>
                <FormattedMessage id={props.widgetInfo?.type===WIDGET_TYPE.WELCOME?"WelcomePlaceholder":"Title"}>
                    {placeholder =>
                        <Form.Control
                            className={widgetErrors?.title ? "has-errors" : ""}
                            onChange={handleTitleInputChange}
                            placeholder={placeholder}
                            type="text"
                            value={"" || props.widgetInfo.title}
                            isInvalid={widgetErrors?.title}
                        />
                    }
                </FormattedMessage>
                <Form.Control.Feedback type={"invalid"}>
                    <FormattedMessage id={"Validation.cannotBeEmpty"}/>
                </Form.Control.Feedback>
            </Form.Group>
            <Form.Group>
                <Form.Label><FormattedMessage id="Description"/></Form.Label>
                <FormattedMessage id="CheckDashboard">
                    {placeholder =>
                        <Form.Control
                            className={widgetErrors?.description ? "has-errors" : ""}
                            onChange={handleDescriptionInputChange}
                            placeholder={placeholder}
                            type="text"
                            value={"" || props.widgetInfo.description}
                            isInvalid={widgetErrors?.description}
                        />
                    }
                </FormattedMessage>
                <Form.Control.Feedback type={"invalid"}>
                    <FormattedMessage id={"Validation.cannotBeEmpty"}/>
                </Form.Control.Feedback>
            </Form.Group>

            {props.widgetInfo.type && props.widgetInfo.type!==WIDGET_TYPE.WELCOME && <>
                <Form.Group>
                    <Form.Label><FormattedMessage id="View"/></Form.Label>
                    <FormattedMessage id="Placeholder.select">
                        {placeholder =>
                            <Select
                                className={"react-select primary"+ (widgetErrors?.vid?" has-errors":"")}
                                classNamePrefix="react-select"
                                options={allViewOptions}
                                onChange={handleViewChange}
                                value={currentViewOption}
                                placeholder={placeholder}
                            />
                        }
                    </FormattedMessage>
                    {widgetErrors?.vid && <div className={"error-message"}>
                        <FormattedMessage id={"Validation.selectOneOption"}/>
                    </div> }
                </Form.Group>
            </>}

            {props.widgetInfo.type === WIDGET_TYPE.TABLE && <>
                <Form.Group>
                    <Form.Label><FormattedMessage id="RowsShown"/></Form.Label>
                    <FormattedMessage id="RowsShown">
                        {placeholder =>
                            <Select
                                className={"react-select primary" + (widgetErrors?.rows ? " has-errors" : "")}
                                classNamePrefix="react-select"
                                options={widgetRowsOptions}
                                onChange={handleRowsShownInputChange}
                                value={widgetRowsOptions.find(widget=>widget.value===props.widgetInfo.rows)}
                                placeholder={placeholder}
                            />
                        }
                    </FormattedMessage>
                    {widgetErrors?.rows && <div className={"error-message"}>
                        <FormattedMessage id={"Validation.selectOneOption"}/>
                    </div> }
                </Form.Group>
                <Form.Group className="display-fields-container">
                    {
                        viewIndex !== -1 && allViews.length &&
                        <DisplayFieldsTable
                            data={allViews[viewIndex].fieldModes?.filter(field => field.recordTypeField.type !== RECORD_FIELD_TYPE.NOTIFICATIONS).map(field => {
                                return {
                                    "name": {
                                        "id": field.recordTypeField.id,
                                        "name": field.recordTypeField.name,
                                        "type": "value"
                                    },
                                    "type": {
                                        "name": field.recordTypeField.type,
                                        "type": "type"
                                    },
                                    "mode": {
                                        "name": modeOptions.find(mode=>mode.value===field.recordViewFieldMode.mode)?.label,
                                        "type": "type"
                                    }
                                }
                            })}
                            columns={columns}
                            displayFields={widgets[props.widgetIndex]?.displayFields}
                            changeDisplayFields={(displayFieldId)=>widgetChange.changeDisplayFields(props.widgetIndex,displayFieldId)}
                            error={widgetErrors?.displayFields}
                        />
                    }
                </Form.Group>
                <Form.Group>
                    <Row>
                        <Col>
                            <FormattedMessage id="DefaultSorting"/>
                        </Col>
                        <Col className="inline-switch-col">
                            <Form.Check
                                className="ml-3"
                                type="switch"
                                id={"enable-default-sorting-switch" + props.widgetIndex}
                                checked={props.widgetInfo.defaultSorting || false}
                                onChange={handleEnableDefaultSorting}
                            />
                        </Col>
                    </Row>
                    {
                        props.widgetInfo.defaultSorting &&
                        <>
                            <Form.Label><FormattedMessage id="SortingField"/></Form.Label>
                            <Row>
                                <Col>
                                    <FormattedMessage id="Placeholder.select">
                                        {placeholder =>
                                            <Select
                                                className={"react-select primary" + (widgetErrors?.sortingField ? " has-errors" : "")}
                                                classNamePrefix="react-select"
                                                options={allViews[viewIndex]?.fieldModes?.filter(field => field.recordTypeField.type !== RECORD_FIELD_TYPE.NOTIFICATIONS).map(field => {
                                                    return {
                                                        "label": field.recordTypeField.name,
                                                        "value": field.recordTypeField.id
                                                    }
                                                })}
                                                onChange={handleSortingFieldChange}
                                                value={allViews[viewIndex]?.fieldModes?.find(field => field.recordTypeField.id === props.widgetInfo.sortingField)?.recordTypeField.name ? {
                                                    "label": allViews[viewIndex]?.fieldModes?.find(field => field.recordTypeField.id === props.widgetInfo.sortingField)?.recordTypeField.name,
                                                    "value": allViews[viewIndex]?.fieldModes?.find(field => field.recordTypeField.id === props.widgetInfo.sortingField)?.recordTypeField.id
                                                } : ""}
                                                placeholder={placeholder}
                                            />
                                        }
                                    </FormattedMessage>
                                    {widgetErrors?.sortingField && <div className={"error-message"}>
                                        <FormattedMessage id={"Validation.selectOneOption"}/>
                                    </div> }
                                </Col>

                                <div className="text-right d-flex align-items-center" onClick={handleSortDirectionChange}>
                                    <OverlayTrigger
                                        onClick={(e) => e.preventDefault()}
                                        overlay={
                                            <Tooltip>
                                                <FormattedMessage id={props.widgetInfo.sortDirection === SORT_DIRECTION.DESC ? "Descending" : "Ascending"}/>
                                            </Tooltip>
                                        }
                                    >
                                        {props.widgetInfo.sortDirection === SORT_DIRECTION.DESC ? arrowDownwardIcon : arrowUpwardIcon}
                                    </OverlayTrigger>
                                </div>
                            </Row>
                        </>
                    }
                </Form.Group>
            </>}
            {(props.widgetInfo.type === WIDGET_TYPE.PIE_CHART || props.widgetInfo.type === WIDGET_TYPE.COLUMN_CHART || props.widgetInfo.type === WIDGET_TYPE.BAR_CHART) &&
                <Form.Group>
                    <Form.Label><FormattedMessage id={props.widgetInfo.type === WIDGET_TYPE.PIE_CHART?"Field":"PrimaryField"}/></Form.Label>
                    <FormattedMessage id="Placeholder.select">
                        {placeholder =>
                            <Select
                                className={"react-select primary" + (widgetErrors?.groupingField ? " has-errors" : "")}
                                classNamePrefix="react-select"
                                options={allViews[viewIndex]?.fieldModes?.filter(field => field.recordTypeField.type === RECORD_FIELD_TYPE.SINGLE_CHOICE || field.recordTypeField.type === RECORD_FIELD_TYPE.RECORD).map(field => {
                                    return {
                                        "label": field.recordTypeField.name,
                                        "value": field.recordTypeField.id
                                    }
                                })}
                                onChange={handleGroupingFieldChange}
                                value={allViews[viewIndex]?.fieldModes?.find(field => field.recordTypeField.id === props.widgetInfo.groupingField)?.recordTypeField.name ? {
                                    "label": allViews[viewIndex]?.fieldModes?.find(field => field.recordTypeField.id === props.widgetInfo.groupingField)?.recordTypeField.name,
                                    "value": allViews[viewIndex]?.fieldModes?.find(field => field.recordTypeField.id === props.widgetInfo.groupingField)?.recordTypeField.id
                                } : ""}
                                placeholder={placeholder}
                            />
                        }
                    </FormattedMessage>
                    {widgetErrors?.groupingField && <div className={"error-message"}>
                        <FormattedMessage id={"Validation.selectOneOption"}/>
                    </div>}
                </Form.Group>
            }
            {( props.widgetInfo.type === WIDGET_TYPE.COLUMN_CHART || props.widgetInfo.type === WIDGET_TYPE.BAR_CHART) && <>
                <Form.Group>
                    <Row>
                        <Col>
                            <Form.Group>
                                <Form.Label><FormattedMessage id="Operator"/></Form.Label>
                                <FormattedMessage id="Placeholder.select">
                                    {placeholder =>
                                        <Select
                                            className={"react-select primary" + (widgetErrors?.operator ? " has-errors" : "")}
                                            classNamePrefix="react-select"
                                            options={widgetOperatorOptions}
                                            onChange={handleOperatorChange}
                                            value={widgetOperatorOptions.find(operator=>operator.value===props.widgetInfo.operator)}
                                            placeholder={placeholder}
                                        />
                                    }
                                </FormattedMessage>
                                {widgetErrors?.operator && <div className={"error-message"}>
                                    <FormattedMessage id={"Validation.selectOneOption"}/>
                                </div>}
                            </Form.Group>
                        </Col>
                        <Col>
                            <Form.Group className={props.widgetInfo.operator===WIDGET_OPERATOR.ROW_COUNT?"disabled":""}>
                                <Form.Label><FormattedMessage id="SecondaryField"/></Form.Label>
                                <FormattedMessage id="Placeholder.select">
                                    {placeholder =>
                                        <Select
                                            className={"react-select primary" + (widgetErrors?.operatorField ? " has-errors" : "")}
                                            classNamePrefix="react-select"
                                            options={allViews[viewIndex]?.fieldModes?.filter(field => field.recordTypeField.type === RECORD_FIELD_TYPE.INTEGER || field.recordTypeField.type === RECORD_FIELD_TYPE.DECIMAL || field.recordTypeField.type === RECORD_FIELD_TYPE.CURRENCY).map(field => {
                                                return {
                                                    "label": field.recordTypeField.name,
                                                    "value": field.recordTypeField.id
                                                }
                                            })}
                                            onChange={handleOperatorFieldChange}
                                            value={allViews[viewIndex]?.fieldModes?.find(field => field.recordTypeField.id === props.widgetInfo.operatorField)?.recordTypeField.name ? {
                                                "label": allViews[viewIndex]?.fieldModes?.find(field => field.recordTypeField.id === props.widgetInfo.operatorField)?.recordTypeField.name,
                                                "value": allViews[viewIndex]?.fieldModes?.find(field => field.recordTypeField.id === props.widgetInfo.operatorField)?.recordTypeField.id
                                            } : ""}
                                            placeholder={placeholder}
                                        />
                                    }
                                </FormattedMessage>
                                {widgetErrors?.operatorField && <div className={"error-message"}>
                                    <FormattedMessage id={"Validation.selectOneOption"}/>
                                </div>}
                            </Form.Group>
                        </Col>

                    </Row>
                </Form.Group>
            </> }
            {props.widgetInfo.type && <PreviewComponent widgetInfo={props.widgetInfo}/>}
            {props.widgetInfo.type === WIDGET_TYPE.WELCOME &&
                <Form.Group>
                    <Row>
                        <Col>
                            <FormattedMessage id="ShowAfterLogin"/>
                        </Col>
                        <Col className="inline-switch-col">
                            <Form.Check
                                className="ml-3"
                                type="switch"
                                id={"show-after-login-switch" + props.widgetIndex}
                                checked={props.widgetInfo.showOnlyAfterLogin || false}
                                onChange={handleShowAfterLoginWidget}
                            />
                        </Col>

                    </Row>
                </Form.Group>}
                <Button
                    variant="danger"
                    className="mt-3"
                    onClick={() => handleDeleteWidget()}
                >
                    <i className="fa fa-trash"/>
                    <FormattedMessage id="DeleteWidget"/>
                </Button>
        </div>
    )

}

export default WidgetContent;