import * as Validations from "validations/validations.js"
import {VALIDATIONS} from "../../../util/Constants";

const addUserValidations = (user, errors) => {
    if (!user.email || !user.email.match(/^([A-Za-z0-9._]+)([@])([A-Za-z0-9]+)([.])([A-Za-z]{2,4})$/)) {
        errors = {
            ...errors,
            "email": true,
        }
    }
    if (user.name.length > VALIDATIONS.MAX_LENGTH_DEFAULT || user.name.length < VALIDATIONS.MIN_LENGTH_DEFAULT) {
        errors = {
            ...errors,
            "name": true,
        }
    }

    if (user.roles === "" || user.roles.length < 0) {
        errors = {
            ...errors,
            "roles": true,
        }
    }

    const existErrors = Validations.checkIfError(errors);

    return [errors, existErrors];
}

export {
    addUserValidations
}
