import React from "react";
import { useHistory } from "react-router-dom";
import {LAYOUT} from "../../../util/Constants";
import {FormattedMessage} from "react-intl";


const PaymentError = (props) => {

    const history = useHistory();
    const wid = props.match.params.wid;
    const toHomePage = () => {
        history.push("/" + LAYOUT.WORKSPACE_MEMBER + "/" + wid + "/home");
    }

    return (
        <div className="card payment-card">
            <header>
                <img style={{width: "150px"}} src='https://www.zoniz.com/img/crossa_logo.png' alt=""/>
            </header>
            <main>
                <div className="text">
                    <p><FormattedMessage id="PaymentNotProcessed" /></p>
                    <p><FormattedMessage id="PaymentTryAgain" /></p>
                </div>
                <div className="icon">
                    <svg className="icon x-icon" width="100" height="100" viewBox="0 0 100 100" fill="none"
                         xmlns="http://www.w3.org/2000/svg">
                        <path fillRule="evenodd" clipRule="evenodd"
                              d="M50 0C22.3858 0 0 22.3858 0 50C0 77.6142 22.3858 100 50 100C77.6142 100 100 77.6142 100 50C100 22.3858 77.6142 0 50 0ZM23.9137 68.731L31.2718 76.0891L50.0014 57.3595L68.731 76.0891L76.0891 68.731L57.3595 50.0014L76.0891 31.2718L68.731 23.9137L50.0014 42.6434L31.2718 23.9137L23.9137 31.2718L42.6434 50.0014L23.9137 68.731Z"
                              fill="#E4437D"/>
                    </svg>
                </div>
                <button onClick={() => toHomePage()} className="btn btn-primary"><FormattedMessage id="Page404.returnCrossa" /></button>
            </main>
        </div>
    );
}

export default PaymentError;
