import {Button, Modal} from "react-bootstrap";
import React, {useRef} from "react";
import ReactCropper from "react-cropper";
import "cropperjs/dist/cropper.css";
import {FormattedMessage} from "react-intl";


function Cropper(props) {

    const cropperRef = useRef(null);

    const cropImage = async () => {
        const imageElement = cropperRef?.current;
        const cropper = imageElement?.cropper;
        cropper.getCroppedCanvas()?.toBlob(function (blob) {
            let f = new File([blob], props.image?.file.name);
            props.setCroppedImage(f);
        });
    }

    return (
        <Modal
            {...props}
            size="lg"
            backdrop="static"
            centered
        >
            <Modal.Header closeButton>
            </Modal.Header>
            <Modal.Body>
                <div>
                    <ReactCropper
                        style={{height: 400, width: "100%"}}
                        aspectRatio={props.aspectRatio ? props.aspectRatio : 16 / 9}
                        preview=".img-preview"
                        guides={false}
                        src={props.image?.url}
                        ref={cropperRef}
                        viewMode={0}
                        dragMode="move"
                    /></div>
            </Modal.Body>
            <Modal.Footer>
                <Button variant={"default"}
                        onClick={props.onHide}
                >
                    <FormattedMessage id="Button.cancel"/>
                </Button>
                <Button variant={"primary"}
                        onClick={cropImage}
                ><FormattedMessage id="Button.crop"/>
                </Button>
            </Modal.Footer>
        </Modal>)

}

export default Cropper;