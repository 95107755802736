import React, { useState } from "react";
import {Badge, Button, Card, Col, Form, OverlayTrigger, Row, Tooltip,} from "react-bootstrap";
import { FormattedMessage } from 'react-intl';

import { findRecordByIdHandler } from "handlers/workspaceAdminHandlers/recordTypeHandlers/RulesRecordTypeHandlers";
import {editIcon} from "../../../../../components/Icons";

function RuleCard(props) {

    const [state,setState]=useState({
        records:[]
    });

    const displayCondition = (condition, index) => {
        let stateOperator = "";
        if (condition?.type && condition?.operator) {
            switch (condition?.type) {
                case "text":
                    for (let operator in textOperatorsCondition) {
                        if (textOperatorsCondition[operator].find(op => op.value === condition?.operator)) {
                            stateOperator = operatorsCondition[condition?.type].find(op => op.value === operator && (typeof op.isEmpty !== "undefined" ? op.isEmpty === condition?.inputValue : true));
                            break;
                        }
                    }
                    break;
                case "integer":
                    for (let operator in integerOperatorsCondition) {
                        if (integerOperatorsCondition[operator].find(op => op.value === condition?.operator)) {
                            stateOperator = operatorsCondition[condition?.type].find(op => op.value === operator && (typeof op.isEmpty !== "undefined" ? op.isEmpty === condition?.inputValue : true));
                            break;
                        }
                    }
                    break;
                case "decimal":
                case "currency":
                    for (let operator in decimalOperatorsCondition) {
                        if (decimalOperatorsCondition[operator].find(op => op.value === condition?.operator)) {
                            stateOperator = operatorsCondition[condition?.type].find(op => op.value === operator && (typeof op.isEmpty !== "undefined" ? op.isEmpty === condition?.inputValue : true));
                            break;
                        }
                    }
                    break;
                case "date":
                    for (let operator in dateOperatorsCondition) {
                        if (dateOperatorsCondition[operator].find(op => op.value === condition?.operator)) {
                            stateOperator = operatorsCondition[condition?.type].find(op => op.value === operator && (typeof op.isEmpty !== "undefined" ? op.isEmpty === condition?.inputValue : true));
                            break;
                        }
                    }
                    break;
                case "singlechoice":
                    for (let operator in singleOperatorsCondition) {
                        if (singleOperatorsCondition[operator].find(op => op.value === condition?.operator)) {
                            stateOperator = operatorsCondition[condition?.type].find(op => op.value === operator && (typeof op.isEmpty !== "undefined" ? op.isEmpty === condition?.inputValue : true));
                            break;
                        }
                    }
                    break;
                case "multiplechoice":
                    for (let operator in multiOperatorsCondition) {
                        if (multiOperatorsCondition[operator].find(op => op.value === condition?.operator)) {
                            stateOperator = operatorsCondition[condition?.type].find(op => op.value === operator && (typeof op.isEmpty !== "undefined" ? op.isEmpty === condition?.inputValue : true));
                            break;
                        }
                    }
                    break;
                case "boolean":
                    for (let operator in booleanOperatorsCondition) {
                        if (booleanOperatorsCondition[operator].find(op => op.value === condition?.operator)) {
                            stateOperator = operatorsCondition[condition?.type].find(op => op.value === operator);
                            break;
                        }
                    }
                    break;
                case "record":
                    for (let operator in recordOperatorsCondition) {
                        if (recordOperatorsCondition[operator].find(op => op.value === condition?.operator)) {
                            stateOperator = operatorsCondition[condition?.type].find(op => op.value === operator && (typeof op.isEmpty !== "undefined" ? op.isEmpty === condition?.inputValue : true));
                            break;
                        }
                    }
                    break;
                case "recordlist":
                    for (let operator in recordListOperatorsCondition) {
                        if (recordListOperatorsCondition[operator].find(op => op.value === condition?.operator)) {
                            stateOperator = operatorsCondition[condition?.type].find(op => op.value === operator && (typeof op.isEmpty !== "undefined" ? op.isEmpty === condition?.inputValue : true));
                            break;
                        }
                    }
                    break;
            }
        }

        if (condition.operator === "IsNew") {
            return <span key={index}> <Badge variant="light"> <span className="text-warning"><FormattedMessage id="IsNew" values={{param:props.recordType?.name}}/></span> </Badge></span>
        } else if (stateOperator?.value === "IsEmpty") {
            return <span key={index}> <Badge variant="light">{condition.operand?.label} <span className="text-warning">{stateOperator?.label}</span></Badge> </span>
        } else {
            if (condition.eqRecord === true) {
                if (props.recordType.fields) {
                    let record = state.records.find(r => r.id === condition.inputValue);
                    if (!record)
                        findRecordById(condition.inputValue, props.recordType.fields?.find(recField => recField.id === condition.operand.value).rtid);
                    return <span key={index}> <Badge variant="light">{condition.operand?.label} <span className="text-warning">{stateOperator?.label}</span> {record?.name} </Badge></span>
                }
            } else {
                return <span key={index}><Badge variant="light"> {condition.operand?.label} <span className="text-warning">{stateOperator?.label}</span> {condition.inputValue.toString()}</Badge> </span>
            }
        }
    }

    const findRecordById = async (recordId, rtid) => {
        findRecordByIdHandler(recordId,rtid).then(response=>{
            if (response.ok) {
                setState({
                    ...state,
                    records:[
                        ...state.records,
                        ...response.data.records]
                });
            }
        });
    }

    const displayAction = (action) => {

        let stateOperator = "";
        if (action?.type && action?.operator) {
            switch (action?.type) {
                case "text":
                    for (let operator in textOperatorsAction) {
                        if (textOperatorsAction[operator].find(op => op.value === action?.operator)) {
                            stateOperator = actionOptions.find(op => op.value === operator);
                            break;
                        }
                    }
                    break;
                case "integer":
                    for (let operator in integerOperatorsAction) {
                        if (integerOperatorsAction[operator].find(op => op.value === action?.operator)) {
                            stateOperator = actionOptions.find(op => op.value === operator);
                            break;
                        }
                    }
                    break;
                case "decimal":
                case "currency":
                    for (let operator in decimalOperatorsAction) {
                        if (decimalOperatorsAction[operator].find(op => op.value === action?.operator)) {
                            stateOperator = actionOptions.find(op => op.value === operator);
                            break;
                        }
                    }
                    break;
                case "date":
                    for (let operator in dateOperatorsAction) {
                        if (dateOperatorsAction[operator].find(op => op.value === action?.operator)) {
                            stateOperator = actionOptions.find(op => op.value === operator);
                            break;
                        }
                    }
                    break;
                case "singlechoice":
                    for (let operator in singleOperatorsAction) {
                        if (singleOperatorsAction[operator].find(op => op.value === action?.operator)) {
                            stateOperator = actionOptions.find(op => op.value === operator);
                            break;
                        }
                    }
                    break;
                case "multiplechoice":
                    for (let operator in multiOperatorsAction) {
                        if (multiOperatorsAction[operator].find(op => op.value === action?.operator)) {
                            stateOperator = actionOptions.find(op => op.value === operator);
                            break;
                        }
                    }
                    break;
                case "boolean":
                    for (let operator in booleanOperatorsAction) {
                        if (booleanOperatorsAction[operator].find(op => op.value === action?.operator)) {
                            stateOperator = actionOptions.find(op => op.value === operator);
                            break;
                        }
                    }
                    break;
                case "record":
                    for (let operator in recordOperatorsAction) {
                        if (recordOperatorsAction[operator].find(op => op.value === action?.operator)) {
                            stateOperator = actionOptions.find(op => op.value === operator);
                            break;
                        }
                    }
                    break;
                case "recordlist":
                    for (let operator in recordListOperatorsAction) {
                        if (recordListOperatorsAction[operator].find(op => op.value === action?.operator)) {
                            stateOperator = actionOptions.find(op => op.value === operator);
                            break;
                        }
                    }
                    break;
            }
        } else {
            stateOperator = actionOptions.find(option => option.value === action.operator);
        }

        if (action.operator === "SetRecordInnerField") {
            if (props.recordType.fields?.find(field => field.id === action.recordField)?.rtid) {
                let id = props.recordType.fields?.find(field => field.id === action.recordField).rtid;
                let recordType = props.recordFieldsOfRecordTypeRef.find(record => record.id === id);
                if (recordType)
                    return <>
                        <Badge variant="light"><span className="text-warning">{stateOperator?.label}</span> {action.operand?.label} <span className="text-warning"><FormattedMessage id="Operator.to" /></span> {props.recordType?.fields?.find(field => field.id === action.recordField)?.name+"."+recordType?.fields?.find(field => field.id === action.innerField)?.name}</Badge>
                    </>

            }
        } else if (action.operator === "SetRecord") {
            if (props.recordType.fields) {
                return <>
                    <Badge variant="light"><span className="text-warning">{stateOperator?.label}</span> {action.operand?.label} <span className="text-warning"><FormattedMessage id="Operator.to" /></span> {action.inputValue?.name}</Badge>
                </>
            }
        } else if (action.operator === "SetRecordList") {
            if (props.recordType.fields) {
                return <>
                    <Badge variant="light"><span className="text-warning">{stateOperator?.label}</span> {action.operand?.label} <span className="text-warning"><FormattedMessage id="Operator.to" /></span> {action.inputValue?.length && ("[" + action.inputValue.map(val => val.name) + "]")}</Badge>
                </>
            }
        } else if (action.operator === "SetList") {
            if (props.recordType.fields) {
                return <>
                    <Badge variant="light"><span className="text-warning">{stateOperator?.label}</span> {action.operand?.label} <span className="text-warning"><FormattedMessage id="Operator.to" /></span> {action.inputValue?.length && ("[" + action.inputValue.map(val => val) + "]")}</Badge>
                </>
            }
        } else if (action.operator === "SetParam") {
            let value;
            if (action.attributeCurrent === "currentMember.id") {
                value = action.attributeCurrent.substring(0, 13);
            } else if (action.attributeCurrent === "currentDate") {
                value = action.attributeCurrent;
            }  else {
                let field=props.currentMemberFields?.find(field=>field.id===action.attributeCurrent.substring(14))?.name;
                value = action.attributeCurrent.substring(0,13)+"."+field;
            }
            return <>
                <Badge variant="light"><span className="text-warning">{stateOperator?.label}</span> {action.operand?.label} <span className="text-warning"><FormattedMessage id="Operator.to" /></span> {value}</Badge>
            </>
        } else
            return <>
                <Badge variant="light"><span className="text-warning">{stateOperator?.label}</span> {action.operand?.label} {action.inputValue && <><span className="text-warning"><FormattedMessage id="Operator.to" /></span> {action.inputValue?.toString()}</>}</Badge>
            </>
    }

    const editRule = () => {
        props.editRule(props.index);
    }

    const handleIsActiveChange=()=>{
        props.handleIsActiveChange(props.index);
    }

    return (
        <>
            <Card className="rule-card">
                <Card.Body>
                    <Card.Title as="h3">{props.name}</Card.Title>
                    <Row>
                        <Col>
                            <div className="badge-container condition-component">
                                <FormattedMessage id="If" /> {props.rule?.condition?.map((condition, index) => {
                                    if (index % 2 === 0)
                                        return displayCondition(condition, index);
                                    else
                                        return <FormattedMessage key={index} id={"Operator." + condition.operator?.toLowerCase()} />
                                })}
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <div className="badge-container action-component">
                                <FormattedMessage id="Then" /> {props.rule?.actions?.map((action, index) => {
                                    return <span key={index}>
                                        {displayAction(action)}
                                        {(index + 1 < props.rule.actions.length) && <FormattedMessage id="Operator.and" />}
                                    </span>
                                })}
                            </div>
                        </Col>
                    </Row>
                    {!props.isDefault && <div className="actions row align-items-center">

                            <OverlayTrigger
                                onClick={(e) => e.preventDefault()}
                                overlay={
                                    <Tooltip>
                                        <FormattedMessage id="Button.editNoParams" />
                                    </Tooltip>
                                }
                            >
                                <Button
                                    variant="warning"
                                    size="sm"
                                    className="text-success btn-link"
                                    onClick={editRule}
                                >
                                    <span>{editIcon}</span>
                                </Button>
                            </OverlayTrigger>


                        <Form.Check
                            className="ml-3"
                            type="switch"
                            id={"crossa-pre-selected-option-switch"+props.index}
                            checked={props.rule.isActive}
                            onChange={handleIsActiveChange}
                        />
                    </div>}
                </Card.Body>
            </Card>
        </>)


}
const operatorsCondition = {
    "text": [
        {"label": <FormattedMessage id="Operators.is" />, "value": "Eq"},
        {"label": <FormattedMessage id="Operators.isNot" />, "value": "Ne"},
        {"label": <FormattedMessage id="Operators.contains" />, "value": "Contains"},
        {"label": <FormattedMessage id="Operators.notContains" />, "value": "NotContains"},
        {"label": <FormattedMessage id="Operators.isEmpty" />, "value": "IsEmpty", "isEmpty": true},
        {"label": <FormattedMessage id="Operators.isNotEmpty" />, "value": "IsEmpty", "isEmpty": false}
    ],
    "integer": [
        {"label": <FormattedMessage id="Operators.is" />, "value": "Eq"},
        {"label": <FormattedMessage id="Operators.isNot" />, "value": "Ne"},
        {"label": <FormattedMessage id="Operators.gt" />, "value": "Gt"},
        {"label": <FormattedMessage id="Operators.gte" />, "value": "Gte"},
        {"label": <FormattedMessage id="Operators.lt" />, "value": "Lt"},
        {"label": <FormattedMessage id="Operators.lte" />, "value": "Lte"},
        {"label": <FormattedMessage id="Operators.isEmpty" />, "value": "IsEmpty", "isEmpty": true},
        {"label": <FormattedMessage id="Operators.isNotEmpty" />, "value": "IsEmpty", "isEmpty": false}
    ],
    "decimal": [
        {"label": <FormattedMessage id="Operators.is" />, "value": "Eq"},
        {"label": <FormattedMessage id="Operators.isNot" />, "value": "Ne"},
        {"label": <FormattedMessage id="Operators.gt" />, "value": "Gt"},
        {"label": <FormattedMessage id="Operators.gte" />, "value": "Gte"},
        {"label": <FormattedMessage id="Operators.lt" />, "value": "Lt"},
        {"label": <FormattedMessage id="Operators.lte" />, "value": "Lte"},
        {"label": <FormattedMessage id="Operators.isEmpty" />, "value": "IsEmpty", "isEmpty": true},
        {"label": <FormattedMessage id="Operators.isNotEmpty" />, "value": "IsEmpty", "isEmpty": false}
    ],
    "boolean": [
        {"label": <FormattedMessage id="Operators.is" />, "value": "Eq"},
        { "label": <FormattedMessage id="IsNew" />, "value": "IsNew" }
    ],
    "date": [
        {"label": <FormattedMessage id="Operators.is" />, "value": "Eq"},
        {"label": <FormattedMessage id="Operators.isNot" />, "value": "Ne"},
        {"label": <FormattedMessage id="Operators.after" />, "value": "Gt"},
        {"label": <FormattedMessage id="Operators.afterOrSame" />, "value": "Gte"},
        {"label": <FormattedMessage id="Operators.before" />, "value": "Lt"},
        {"label": <FormattedMessage id="Operators.beforeOrSame" />, "value": "Lte"},
        {"label": <FormattedMessage id="Operators.isEmpty" />, "value": "IsEmpty", "isEmpty": true},
        {"label": <FormattedMessage id="Operators.isNotEmpty" />, "value": "IsEmpty", "isEmpty": false}
    ],
    "currency": [
        {"label": <FormattedMessage id="Operators.is" />, "value": "Eq"},
        {"label": <FormattedMessage id="Operators.isNot" />, "value": "Ne"},
        {"label": <FormattedMessage id="Operators.gt" />, "value": "Gt"},
        {"label": <FormattedMessage id="Operators.gte" />, "value": "Gte"},
        {"label": <FormattedMessage id="Operators.lt" />, "value": "Lt"},
        {"label": <FormattedMessage id="Operators.lte" />, "value": "Lte"},
        {"label": <FormattedMessage id="Operators.isEmpty" />, "value": "IsEmpty", "isEmpty": true},
        {"label": <FormattedMessage id="Operators.isNotEmpty" />, "value": "IsEmpty", "isEmpty": false}
    ],
    "record": [
        {"label": <FormattedMessage id="Operators.is" />, "value": "Eq"},
        {"label": <FormattedMessage id="Operators.isNot" />, "value": "Ne"},
        {"label": <FormattedMessage id="Operators.isEmpty" />, "value": "IsEmpty", "isEmpty": true},
        {"label": <FormattedMessage id="Operators.isNotEmpty" />, "value": "IsEmpty", "isEmpty": false}
    ],
    "recordlist": [
        {"label": <FormattedMessage id="Operators.contains" />, "value": "Eq"},
        {"label": <FormattedMessage id="Operators.notContains" />, "value": "Ne"},
        {"label": <FormattedMessage id="Operators.isEmpty" />, "value": "IsEmpty", "isEmpty": true},
        {"label": <FormattedMessage id="Operators.isNotEmpty" />, "value": "IsEmpty", "isEmpty": false}
    ],
    "singlechoice": [
        {"label": <FormattedMessage id="Operators.is" />, "value": "Eq"},
        {"label": <FormattedMessage id="Operators.isNot" />, "value": "Ne"},
        {"label": <FormattedMessage id="Operators.isEmpty" />, "value": "IsEmpty", "isEmpty": true},
        {"label": <FormattedMessage id="Operators.isNotEmpty" />, "value": "IsEmpty", "isEmpty": false}
    ],
    "multiplechoice": [
        {"label": <FormattedMessage id="Operators.contains" />, "value": "Eq"},
        {"label": <FormattedMessage id="Operators.notContains" />, "value": "Ne"},
        {"label": <FormattedMessage id="Operators.isEmpty" />, "value": "IsEmpty", "isEmpty": true},
        {"label": <FormattedMessage id="Operators.isNotEmpty" />, "value": "IsEmpty", "isEmpty": false}
    ]
};

const actionOptions = [
    { label: <FormattedMessage id="Action.show" />, value: "Show" },
    { label: <FormattedMessage id="Action.hide" />, value: "Hide" },
    { label: <FormattedMessage id="Action.makeReadonly" />, value: "MakeReadonly" },
    { label: <FormattedMessage id="Action.makeEditable" />, value: "MakeEditable" },
    { label: <FormattedMessage id="Action.makeMandatory" />, value: "MakeMandatory" },
    { label: <FormattedMessage id="Action.removeMandatory" />, value: "RemoveMandatory" },
    { label: <FormattedMessage id="Action.setValueOf" />, value: "SetValue" },
    { label: <FormattedMessage id="Action.clearValueOf" />, value: "Clear" }
];



const textOperatorsCondition = {
    "Eq": [{"label": <FormattedMessage id="Operators.value" />, "value": "EqString"}],
    "Ne": [{"label": <FormattedMessage id="Operators.value" />, "value": "NeString"}],
    "Contains": [{"label": <FormattedMessage id="Operators.value" />, "value": "Contains"}],
    "NotContains": [{"label": <FormattedMessage id="Operators.value" />, "value": "NotContains"}],
    "IsEmpty": [{"label": <FormattedMessage id="Operators.value" />, "value": "IsEmptyString"}]
};

const integerOperatorsCondition = {
    "Eq": [{"label": <FormattedMessage id="Operators.value" />, "value": "EqLong"}],
    "Ne": [{"label": <FormattedMessage id="Operators.value" />, "value": "NeLong"}],
    "Gt": [{"label": <FormattedMessage id="Operators.value" />, "value": "GtLong"}],
    "Gte": [{"label": <FormattedMessage id="Operators.value" />, "value": "GteLong"}],
    "Lt": [{"label": <FormattedMessage id="Operators.value" />, "value": "LtLong"}],
    "Lte": [{"label": <FormattedMessage id="Operators.value" />, "value": "LteLong"}],
    "IsEmpty": [{"label": <FormattedMessage id="Operators.value" />, "value": "IsEmptyLong"}]
};

const decimalOperatorsCondition = {
    "Eq": [{"label": <FormattedMessage id="Operators.value" />, "value": "EqDouble"}],
    "Ne": [{"label": <FormattedMessage id="Operators.value" />, "value": "NeDouble"}],
    "Gt": [{"label": <FormattedMessage id="Operators.value" />, "value": "GtDouble"}],
    "Gte": [{"label": <FormattedMessage id="Operators.value" />, "value": "GteDouble"}],
    "Lt": [{"label": <FormattedMessage id="Operators.value" />, "value": "LtDouble"}],
    "Lte": [{"label": <FormattedMessage id="Operators.value" />, "value": "LteDouble"}],
    "IsEmpty": [{"label": <FormattedMessage id="Operators.value" />, "value": "IsEmptyDouble"}]
};

const booleanOperatorsCondition = {
    "Eq": [{"label": <FormattedMessage id="Operators.value" />, "value": "EqBoolean"}],
    "IsNew": [{ "label": <FormattedMessage id="Operators.value" />, "value": "IsNew" }],
};

const dateOperatorsCondition = {
    "Eq": [{"label": <FormattedMessage id="Operators.value" />, "value": "EqDate"}],
    "Ne": [{"label": <FormattedMessage id="Operators.value" />, "value": "NeDate"}],
    "Gt": [{"label": <FormattedMessage id="Operators.value" />, "value": "GtDate"}],
    "Gte": [{"label": <FormattedMessage id="Operators.value" />, "value": "GteDate"}],
    "Lt": [{"label": <FormattedMessage id="Operators.value" />, "value": "LtDate"}],
    "Lte": [{"label": <FormattedMessage id="Operators.value" />, "value": "LteDate"}],
    "IsEmpty": [{"label": <FormattedMessage id="Operators.value" />, "value": "IsEmptyDate"}]
};

const recordOperatorsCondition = {
    "Eq": [{"label": <FormattedMessage id="Operators.value" />, "value": "EqRecord"}],
    "Ne": [{"label": <FormattedMessage id="Operators.value" />, "value": "NeRecord"}],
    "IsEmpty": [{"label": <FormattedMessage id="Operators.value" />, "value": "IsEmptyRecord"}]
};

const recordListOperatorsCondition = {
    "Eq": [{"label": <FormattedMessage id="Operators.value" />, "value": "EqRecord"}],
    "Ne": [{"label": <FormattedMessage id="Operators.value" />, "value": "NeRecord"}],
    "IsEmpty": [{"label": <FormattedMessage id="Operators.value" />, "value": "IsEmptyList"}]
};

const singleOperatorsCondition = {
    "Eq": [{"label": <FormattedMessage id="Operators.value" />, "value": "EqString"}],
    "Ne": [{"label": <FormattedMessage id="Operators.value" />, "value": "NeString"}],
    "IsEmpty": [{"label": <FormattedMessage id="Operators.value" />, "value": "IsEmptyString"}]
}

const multiOperatorsCondition = {
    "Eq": [{"label": <FormattedMessage id="Operators.value" />, "value": "Contains"}],
    "Ne": [{"label": <FormattedMessage id="Operators.value" />, "value": "NotContains"}],
    "IsEmpty": [{"label": <FormattedMessage id="Operators.value" />, "value": "IsEmptyList"}]
};

const textOperatorsAction = {
    "SetValue": [
        {"label": <FormattedMessage id="Operators.toValue" />, "value": "SetString"},
        {"label": <FormattedMessage id="Operators.toRecordFieldValue" />, "value": "SetRecordInnerField"},
        {"label": <FormattedMessage id="Operators.toVariableFieldValue" />, "value": "SetParam"}
    ],
};

const integerOperatorsAction = {
    "SetValue": [
        {"label": <FormattedMessage id="Operators.toValue" />, "value": "SetLong"},
        {"label": <FormattedMessage id="Operators.toRecordFieldValue" />, "value": "SetRecordInnerField"},
        {"label": <FormattedMessage id="Operators.toVariableFieldValue" />, "value": "SetParam"}
    ],
};

const decimalOperatorsAction = {
    "SetValue": [
        {"label": <FormattedMessage id="Operators.toValue" />, "value": "SetDouble"},
        {"label": <FormattedMessage id="Operators.toRecordFieldValue" />, "value": "SetRecordInnerField"},
        {"label": <FormattedMessage id="Operators.toVariableFieldValue" />, "value": "SetParam"}
    ],
};

const booleanOperatorsAction = {
    "SetValue": [
        {"label": <FormattedMessage id="Operators.toValue" />, "value": "SetBoolean"},
        {"label": <FormattedMessage id="Operators.toRecordFieldValue" />, "value": "SetRecordInnerField"},
        {"label": <FormattedMessage id="Operators.toVariableFieldValue" />, "value": "SetParam"}
    ],
};

const dateOperatorsAction = {
    "SetValue": [
        {"label": <FormattedMessage id="Operators.toValue" />, "value": "SetDate"},
        {"label": <FormattedMessage id="Operators.toRecordFieldValue" />, "value": "SetRecordInnerField"},
        {"label": <FormattedMessage id="Operators.toVariableFieldValue" />, "value": "SetParam"},
        {"label": <FormattedMessage id="Operators.toCurrentDate" />,"value": "SetParam","toCurrentDate": true}
    ],
};

const recordOperatorsAction = {
    "SetValue": [
        {"label": <FormattedMessage id="Operators.toValue" />, "value": "SetRecord"},
        {"label": <FormattedMessage id="Operators.toRecordFieldValue" />,"value": "SetRecordInnerField"},
        {"label": <FormattedMessage id="Operators.toVariableFieldValue" />, "value": "SetParam"},
        {"label": <FormattedMessage id="Operators.toCurrentMember" />,"value": "SetParam","toCurrentMember": true}
    ],
};

const recordListOperatorsAction = {
    "SetValue": [
        {"label": <FormattedMessage id="Operators.toValue" />, "value": "SetRecordList"},
        {"label": <FormattedMessage id="Operators.toRecordFieldValue" />,"value": "SetRecordInnerField"},
        {"label": <FormattedMessage id="Operators.toVariableFieldValue" />, "value": "SetParam"},
    ],
};

const singleOperatorsAction = {
    "SetValue": [
        {"label": <FormattedMessage id="Operators.toValue" />, "value": "SetString"},
        {"label": <FormattedMessage id="Operators.toRecordFieldValue" />,"value": "SetRecordInnerField"},
        {"label": <FormattedMessage id="Operators.toVariableFieldValue" />, "value": "SetParam"}
    ],
};

const multiOperatorsAction = {
    "SetValue": [
        {"label": <FormattedMessage id="Operators.toValue" />, "value": "SetList"},
        {"label": <FormattedMessage id="Operators.toRecordFieldValue" />,"value": "SetRecordInnerField"},
        {"label": <FormattedMessage id="Operators.toVariableFieldValue" />, "value": "SetParam"}
    ],
};

export default RuleCard;
