import AdminNavbar from "../../../components/AdminNavbar";
import {FormattedMessage} from "react-intl";
import CrossaPageTemplate from "../../../components/CrossaPageTemplate";
import {Col, Row} from "react-bootstrap";
import React, {useState} from "react";

import {
    formPaymentSettingsIcon,
    paymentSettingsIcon,
    workspacePaymentSettingsIcon
} from "../../../components/Icons";
import FormPaymentSetup from "./components/FormPaymentSetup";
import PaymentSetup from "./components/PaymentSetup";


function SettingsPage(props) {

    const settingsPages=[
        {
            title:<FormattedMessage id="WorkspaceSettings"/>,
            icon:workspacePaymentSettingsIcon,
            component:<></>
        },
        {
            title:<FormattedMessage id="PaymentSetup"/>,
            icon:paymentSettingsIcon,
            component: <PaymentSetup {...props}/>
        },
        {
            title:<FormattedMessage id="FormPaymentSetup"/>,
            icon:formPaymentSettingsIcon,
            link:"/form-payment",
            component: <FormPaymentSetup {...props}/>
        },
    ];

    const [state,setState]=useState({
        activePage:null,
    });

    const selectPage=(index)=>{
        setState({
            ...state,
            activePage: index
        });
    }

    return (
        <>
            <AdminNavbar
                title={<FormattedMessage id="Sidebar.settings"/>}
            />
            <CrossaPageTemplate>
                <Row>
                    <Col className="left-component" md={4}>
                        <div className="simple-menu">
                            {settingsPages.map((page, index) => {
                                return (
                                    <div
                                        className="tree-item"
                                        key={index}>
                                        <div
                                            className={"row " + (index === state.activePage ? "selected" : "")}
                                            onClick={() => {
                                                selectPage(index)
                                            }}>
                                            <div className="menuDiv">

                                                <p>
                                                    <span className="icon view-icon-container">{page.icon}</span>
                                                    {page.title}</p>
                                            </div>
                                        </div>
                                    </div>
                                )
                            })}
                        </div>
                    </Col>
                    <Col md={8}>
                        {settingsPages[state.activePage]?.component}
                    </Col>
                </Row>
            </CrossaPageTemplate>
        </>
    );
}

export default SettingsPage