import React, {useEffect, useState, useRef} from "react";
import CrossaPageTemplate from "components/CrossaPageTemplate.js";
import {Button, Form, OverlayTrigger, Tooltip} from "react-bootstrap";
import {
    getFormIdHashHandler
} from "handlers/workspaceMemberHandlers/menuDisplayHandlers/DisplayMenuHandlers";
import * as ClientConfig from "../../../../config/ClientConfig";
import Moment from "react-moment";
import {DATE_FORMAT} from "../../../../util/DateUtil";
import {
    PAYMENT_STATUS,
    RECORD_FIELD_MODE,
    RECORD_FIELD_RECORD_DISPLAY_OPTION,
    RECORD_FIELD_TEXT_DISPLAY_OPTION,
    RECORD_FIELD_TYPE, RECORD_TYPE_TYPE
} from "../../../../util/Constants";
import {FormattedMessage} from "react-intl";
import currencies from "currency-codes";
import {fileIcon} from "../../../../components/Icons";

const RecordDetails = (props) => {

    const [state, setState] = useState({
        record: props.record || null,
        linkRecordToForm: {},
        errors: {},
        formHash: {}
    });

    const recordRef = useRef(null);
    const fieldsUpdateFormRef = useRef(null);

    useEffect(async () => {
        let newState = {...state};
        let viewIdArray = [];
        let formArray = [];
        let newErrors = {};

        if (JSON.stringify(recordRef.current) !== JSON.stringify(props.record)) {
            newState = {
                ...newState,
                record: props.record,
            };
            recordRef.current = JSON.parse(JSON.stringify(props.record));
        }

        if (JSON.stringify(fieldsUpdateFormRef.current) !== JSON.stringify(props.fieldsUpdateForm)) {
            props.fieldsUpdateForm.forEach(field => {
                newErrors[field.id] = false;
                if (field.type !== RECORD_FIELD_TYPE.RECORD && field.type !== RECORD_FIELD_TYPE.RECORD_LIST) {
                    return;
                }
                if (field.displayOption === RECORD_FIELD_RECORD_DISPLAY_OPTION.FORM_LINK) {
                    newState.linkRecordToForm[field.id] = !!(newState.record[field.id] && state.linkRecordToForm[field.id] !== false);
                    if (newState.record[field.id]) {
                        formArray.push({
                            "fieldId": field.id,
                            "rtid": field.rtid,
                            "id": newState.record[field.id].id
                        })
                    }
                } else {
                    if (!field.vid || viewIdArray.includes(field.vid) || !field.mode || field.mode === RECORD_FIELD_MODE.HIDE) {
                        return;
                    }
                    viewIdArray.push(field.vid);
                }
            });

            const formHashObj = await createFormHashObject(formArray);
            newState = {
                ...newState,
                errors: newErrors,
                formHash: formHashObj
            };
            fieldsUpdateFormRef.current = JSON.parse(JSON.stringify(props.fieldsUpdateForm));
        }
        setState(newState);
    }, [props.fieldsUpdateForm, props.record]);


    const createFormHashObject = async (forms) => {
        let formHashObj = {};
        for (let i = 0; i < forms.length; i++) {
            formHashObj[forms[i].fieldId] = await getFormIdHash(props.wid, forms[i].id);
        }

        return formHashObj;
    }

    const fieldModeToContent = (field, index) => {
        if (!field.mode || field.mode === RECORD_FIELD_MODE.HIDE || field.type === RECORD_FIELD_TYPE.NOTIFICATIONS) {
            return <React.Fragment key={index + field}/>;
        }

        if (field.type === RECORD_FIELD_TYPE.DATE) {
            return (
                <Form.Group key={index + field}>
                    <Form.Label>{field.name}</Form.Label>
                    <p>{(state.record && state.record[field.id]) ? <Moment
                        format={field?.timeField ? DATE_FORMAT.DEFAULT : DATE_FORMAT.DAY_MONTH_YEAR}
                        date={state.record[field.id]}
                    />:"-" }</p>

                </Form.Group>
            );
        } else if (field.type === RECORD_FIELD_TYPE.BOOLEAN) {
            return (
                <Form.Group key={index + field}>
                    <Form.Label>{field.name}</Form.Label>
                    <p>{(state.record && state.record[field.id]) ? state.record[field.id].toString() : "false"}</p>
                </Form.Group>
            );
        } else if (field.type === RECORD_FIELD_TYPE.GROUP_HEADER) {
            return (
                <Form.Group key={index + field} className={index === 0 ? "group-header no-border" : "group-header"}>
                    <h4 key={index + field}>{field.name}</h4>
                </Form.Group>
            );
        } else if (field.type === RECORD_FIELD_TYPE.CURRENCY) {
            return (
                <Form.Group key={index + field}>
                    <Form.Label>{field.name} ({field.currency})</Form.Label>
                    <p>{(state.record && state.record[field.id]) || "-"}</p>
                </Form.Group>
            );
        } else if (field.type === RECORD_FIELD_TYPE.RECORD) {
            if(state.formHash[field.id]) {
                return (
                    <Form.Group key={index + field}>
                        <Form.Label>{field.name}</Form.Label>
                        <p>{state.formHash[field.id]?<a href={ClientConfig.URL_RESOURCE_SERVER + "api/form/" +
                            props.wid + "/" + state.record[field.id].id + "/" + state.formHash[field.id].hash + ".html"} target="_blank">
                            <Button className="mb-0">
                                {state.record[field.id].name}
                            </Button>
                        </a>:"-"}</p>
                    </Form.Group>
                );
            } else {
                return (
                    <Form.Group key={index + field}>
                        <Form.Label>{field.name}</Form.Label>
                        <p>{(state.record && state.record[field.id]?.name) || "-"}</p>
                    </Form.Group>
                )
            }
        } else if(field.type===RECORD_FIELD_TYPE.RECORD_LIST || field.type ===RECORD_FIELD_TYPE.ROLES_LIST){
            return (
                <Form.Group key={index + field}>
                    <Form.Label>{field.name}</Form.Label>
                    <p>{(state.record && state.record[field.id]?.length && state.record[field.id].map(tag => tag.name).join(", ")) || "-"}</p>
                </Form.Group>
            )
        } else if (field.type === RECORD_FIELD_TYPE.SINGLE_CHOICE) {
            let optionIndex=field.options?.findIndex(option => option === state.record[field.id]);
            return (
                <Form.Group key={index + field}>
                    <Form.Label>{field.name}</Form.Label>
                    {optionIndex !== -1 ?
                        field.optionsColors?.[optionIndex] ?
                            <p> <span className="status" style={{
                                color: "white",
                                justifyContent: "center",
                                borderRadius: "8px",
                                fontSize: "12px",
                                padding: "2px 9px",
                                display: "inline-block",
                                background: field.optionsColors?.[optionIndex]
                            }}>{(state.record && field.options[optionIndex])}</span></p>
                            : <p>{(state.record && field.options[optionIndex])}</p>
                        :
                        !state.record[field.id] ?
                            <p>-</p>
                            :
                            <p><OverlayTrigger
                                onClick={(e) => e.preventDefault()}
                                overlay={
                                    <Tooltip>
                                        <FormattedMessage id="Validation.invalidData"/>
                                    </Tooltip>
                                }
                            ><i className='fa fa-info-circle text-danger mt-1'/></OverlayTrigger>{state.record[field.id]}
                            </p>
                    }
                </Form.Group>
            )
        } else if (field.type === RECORD_FIELD_TYPE.MULTIPLE_CHOICE) {
            return (
                <Form.Group key={index + field}>
                    <Form.Label>{field.name}</Form.Label>
                    <p>{(state.record && field.options?.filter(option => state.record[field.id] && state.record[field.id].includes(option)).join(", ")) || "-"}</p>
                </Form.Group>
            )
        } else if (field.type === RECORD_FIELD_TYPE.IMAGE) {
            return (
                <Form.Group className="image-form-group" key={index + field}>
                    <Form.Label>{field.name}</Form.Label>
                    {state.record[field.id] ?
                        <div className="img-container">
                            <img src={ClientConfig.URL_RESOURCE_SERVER + ClientConfig.NAMESPACE_RECORDS + "images/recordviews/" + props.vid + "/image/" + state.record[field.id]} alt={"."}/>
                        </div>
                        :<p>-</p>
                    }
                </Form.Group>
            );
        } else if (field.type === RECORD_FIELD_TYPE.FILE) {
            return (
                <Form.Group className="file-form-group" key={index + field}>
                    <Form.Label>{field.name}</Form.Label>
                    {state.record[field.id] ?
                        <div className="grouped-files-container single" key={state.record[field.id]?.fileName+index}>
                            <a className="file-container" href={ClientConfig.URL_RESOURCE_SERVER + ClientConfig.NAMESPACE_RECORDS + "files/recordviews/" + props.vid + "/file/" + state.record[field.id]?.fileName} target="_blank">
                                <span className="blue-icon">
                                    {fileIcon}
                                </span>
                                <p className="text-container">{state.record[field.id]?.originalFileName}</p>
                            </a>

                        </div>
                        :<p>-</p>
                    }
                </Form.Group>
            );
        } else if (field.type === RECORD_FIELD_TYPE.FILE_LIST) {
            return (
                <Form.Group className="file-form-group" key={index + field}>
                    <Form.Label>{field.name}</Form.Label>
                    {state.record[field.id] ?
                        <div className="grouped-files-container">
                            {
                                state.record[field.id].map((file,index)=>{
                                    return (
                                        <a key={file?.fileName+index} className="file-container" href={ClientConfig.URL_RESOURCE_SERVER + ClientConfig.NAMESPACE_RECORDS + "files/recordviews/" + props.vid + "/file/" + file.fileName} target="_blank" >
                                    <span className="blue-icon">
                                        {fileIcon}
                                    </span>
                                            <p className="text-container">{file?.originalFileName}</p>
                                        </a>
                                        )
                                })
                            }
                        </div>

                        :<p>-</p>
                    }
                </Form.Group>
            );
        } else if (props.recordType===RECORD_TYPE_TYPE.PAYMENT && field.type === RECORD_FIELD_TYPE.PAYMENT) {
            return (
                <Form.Group key={index + field}>
                    <Form.Label><FormattedMessage id="PaymentStatus"/></Form.Label>
                    {state.record[field.id] ?
                        <p>
                            {state.record[field.id].paymentStatus?state.record[field.id].paymentStatus===PAYMENT_STATUS.COMPLETE?<span className="payment-status-success"><FormattedMessage id="PaymentStatus.paid"/></span>: <span className="payment-status-danger"><FormattedMessage id="PaymentStatus.notPaid"/></span>:<span>-</span>}
                        </p>
                        :<p><FormattedMessage id="PaymentStatus.notPaid"/></p>
                    }
                </Form.Group>
            );
        } else if (props.recordType===RECORD_TYPE_TYPE.FORM_DATA && field.type === RECORD_FIELD_TYPE.PAYMENT) {
            let currency = state.record[field.id].currency ? currencies.code(state.record[field.id].currency) : null;
            return (
                <React.Fragment key={index + field}>
                    <Form.Group key={index + field + "type"}>
                        <Form.Label><FormattedMessage id="Form.payment"/></Form.Label>
                        {state.record[field.id] ?
                            <p>{state.record[field.id]?.paymentType?state.record[field.id].paymentType === "Online" ?
                                (state.record[field.id].paymentType + " (" + state.record[field.id].paymentStatus + ")") :
                                state.record[field.id].paymentType:"-"}
                            </p>
                            :<p>-</p>
                        }
                    </Form.Group>
                    <Form.Group key={index + field + "option"}>
                        <Form.Label><FormattedMessage id="Form.paymentOption"/></Form.Label>
                        {state.record[field.id] ?
                            <p>{state.record[field.id].paymentOption?state.record[field.id].paymentOption.name + (currency ? "(" +
                                (state.record[field.id].paymentOption.price/Math.pow(10, currency.digits)).toFixed(currency.digits) +
                                " " + state.record[field.id].currency + ")" : ""):"-"}
                            </p>
                            :<p>-</p>
                        }
                    </Form.Group>
                </React.Fragment>
            );
        } else if (field.type === RECORD_FIELD_TYPE.TEXT) {
            return (
                <Form.Group className={field.displayOption === RECORD_FIELD_TEXT_DISPLAY_OPTION.TEXT_AREA ? "text-area-form-group" : ""} key={index + field}>
                    <Form.Label>{field.name}</Form.Label>
                    {field.displayOption === RECORD_FIELD_TEXT_DISPLAY_OPTION.HYPER_LINK ?
                        <><p></p><a href={state.record[field.id]} target="_blank">{(state.record && state.record[field.id]) || "-"}</a></> : <p>{(state.record && state.record[field.id]) || "-"}</p>}
{/*fixme remove extra <p> tag*/}
                </Form.Group>
            );
        }else{
            return (
                <Form.Group key={index + field}>
                    <Form.Label>{field.name}</Form.Label>
                    <p>{(state.record && state.record[field.id]) || "-"}</p>
                </Form.Group>
            );
        }
    }

    const getFormIdHash = async (wid, rid) => {
        return getFormIdHashHandler(wid, rid).then(response => {
            if (response.ok) {
                return response.data;
            }
        });
    }

    return (
        <CrossaPageTemplate
            hasFooter={false}
        >
            <div className="centered-sliding-content">
                {
                    props.fieldsUpdateForm.map((field, index) => {
                        return fieldModeToContent(field, index);
                    })
                }
                {props.recordType === RECORD_TYPE_TYPE.PAYMENT && <>
                    <Form.Group className="group-header no-border"/>
                    <Form.Group>
                        <div className="actions-right">
                            <Button variant="primary"
                                    size="sm"
                                    onClick={()=> props.getInvoice(props.wid, props.record.id)}
                            >
                                <FormattedMessage id="Invoice"/>
                            </Button>
                        </div>
                    </Form.Group>
                    <Form.Group>
                        {
                            props.paymentAvailable && props.record.paymentInfo && props.fieldsUpdateForm.find(field => field.id === "paymentInfo")?.type === RECORD_FIELD_TYPE.PAYMENT && props.record.paymentInfo.paymentStatus !== "COMPLETE" &&
                            <div className="actions-right">
                                <Button variant="primary"
                                        size="sm"
                                        onClick={() => props.generatePaymentLink(props.record.id)}
                                >
                                    <FormattedMessage id="PayNow"/>
                                </Button>
                            </div>}
                    </Form.Group>
                </>}
            </div>
        </CrossaPageTemplate>
    );
}

export default RecordDetails;
