import React, {useState} from "react";
import {Form} from "react-bootstrap";
import Select from "react-select";
import {RECORD_FIELD_MODE, RECORD_FIELD_TYPE} from "../../../../util/Constants";
import {FormattedMessage} from "react-intl";

const ViewTableRow = props => {
    const [currentOption, setCurrentOption] = useState(0);

    const options = [{
        "label": <FormattedMessage id="Read"/>,
        "value": RECORD_FIELD_MODE.READ,
        "color": "green",
        "index": 0,
    },
        {
            "label": <FormattedMessage id="Write"/>,
            "value": RECORD_FIELD_MODE.WRITE,
            "color": "green",
            "index": 1,
        },
        {
            "label": <FormattedMessage id="Hide"/>,
            "value": RECORD_FIELD_MODE.HIDE,
            "color": "green",
            "index": 2,
        }];

    const getIndexByValue = value => {
        return options.findIndex(option => option.value === value);
    }

    let style = {color: "black"};
    let type;

    if (props.type === RECORD_FIELD_TYPE.GROUP_HEADER) {
        style = {...style, fontWeight: "bold"};
        type = {
            label:<FormattedMessage id="FieldType.groupHeader" />,
            value:"GroupHeader"
        };
    } else{
        type = {
            label:<FormattedMessage id={"FieldType."+props.type.charAt(0).toLowerCase() + props.type.slice(1)} />,
            value:props.type
        };
    }



    return (

        <tr className={!!!props.borders ? "no-borders" : ""}>
            <td>
                <Form.Label className={type.value === "GroupHeader"? "groupHeader": ""} style={style}>{props.name}</Form.Label>
            </td>
            <td>
                <Form.Label style={style}>{type.label}</Form.Label>
            </td>
            <td>
                <Form.Group className="mb-0">
                    <FormattedMessage id="Mode">
                        {placeholder =>
                            <Select
                                onChange={e => {
                                    props.handleSelectChange(e.value, props.id)
                                    setCurrentOption(e.index);
                                }}
                                className="react-select primary"
                                classNamePrefix="react-select"
                                name="type"
                                value={(props.mode ? options[getIndexByValue(props.mode)] : null) || options[currentOption]}
                                options={options}
                                placeholder={placeholder}
                            />
                        }
                    </FormattedMessage>
                </Form.Group>
            </td>
        </tr>

    )
}

export default ViewTableRow;
