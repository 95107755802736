import AllWorkspacesPage from "views/superAdmin/workspace/AllWorkspacesPage.js";
import AddWorkspace from "views/superAdmin/workspace/AddWorkspacePage.js";

import AllUsersPage from "views/superAdmin/user/AllUsersPage.js";
import SaveUserPage from "views/superAdmin/user/SaveUserPage.js";
import {FormattedMessage} from "react-intl";

const superAdminRoutes = [
    {
        path: "/workspaces",
        name: <FormattedMessage id="Workspaces"/>,
        icon: null,
        component: AllWorkspacesPage,
        layout: "/admin",
        isVisible: true,
    },


    {
        path: "/workspaces/add",
        name: "Workspace",
        icon: null,
        component: AddWorkspace,
        layout: "/admin",
    },

    {
        path: "/workspaces/:wid/edit",
        name: "EditWorkspace",
        icon: null,
        component: AddWorkspace,
        layout: "/admin",
    },


    {
        path: "/users",
        name: <FormattedMessage id="Users"/>,
        icon: null,
        component: AllUsersPage,
        layout: "/admin",
        isVisible: true,
    },
    {
        path: "/users/add",
        name: "SaveUserPage",
        icon: null,
        component: SaveUserPage,
        layout: "/admin",
    },
    {
        path: "/users/:uid/edit",
        name: "Users",
        component: SaveUserPage,
        layout: "/admin",
    }
];

export default superAdminRoutes;
