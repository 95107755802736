import React, {useEffect, useState} from "react";
import {useHistory} from "react-router-dom";
import CrossaTable from "components/CrossaTable.js"
import {FormattedMessage} from 'react-intl';
import SweetAlert from 'react-bootstrap-sweetalert';
import CrossaPageTemplate from "../../../components/CrossaPageTemplate";
import {getAllRecordTypesHandler} from "handlers/workspaceAdminHandlers/recordTypeHandlers/AllRecordTypesHandlers";
import {deleteRecordTypeHandler} from "handlers/workspaceAdminHandlers/recordTypeHandlers/AllRecordTypesHandlers";
import AdminNavbar from "../../../components/AdminNavbar";

function AllRecordTypesPage(props) {

    const history = useHistory();

    const [state, setState] = useState({
        recordTypes: [],
        alert: null,
    });

    useEffect(() => {
        getPageContent();
    }, []);

    const getPageContent = async () => {
        getAllRecordTypesHandler(props.match.params.wid).then(response => {
            if (response.ok) {
                setState({
                    ...state,
                    ...response.data
                });
            }
        });
    }

    const errorAlert = () => {

        setState({
            ...state,
            alert: <SweetAlert
                type="danger"
                title=""
                style={{display: "block", marginTop: "-100px", fontSize: "13px"}}
                btnSize="md"
                confirmBtnBsStyle="default"
                confirmBtnText={<FormattedMessage id="Button.ok"/>}
                openAnim="false"
                onCancel={() => hideAlert()}
                onConfirm={() => hideAlert()}
                allowEscape
                closeOnClickOutside
                customClass="crossa-alert"
            >  {<FormattedMessage id="DeleteRecordTypeError"/>}
            </SweetAlert>
        });
    }

    const deleteAlert = (id) => {
        setState({
            ...state,
            alert: <SweetAlert
                type="danger"
                title=""
                style={{display: "block", marginTop: "-100px", fontSize: "13px"}}
                btnSize="md"
                confirmBtnBsStyle="danger"
                cancelBtnBsStyle="default"
                showCancel
                cancelBtnText={<FormattedMessage id="Button.cancel"/>}
                confirmBtnText={<FormattedMessage id="Button.delete"/>}
                openAnim="false"
                onConfirm={() => deleteRecordType(id)}
                onCancel={() => hideAlert()}
                allowEscape
                closeOnClickOutside
                customClass="crossa-alert"

            > <FormattedMessage id="Alert.deleteRecordType" />
            </SweetAlert>
        });
    }

    const hideAlert = () => {
        setState({
            ...state,
            alert: null
        });
    };

    const deleteRecordType = async id => {
        deleteRecordTypeHandler(id, props.match.params.wid).then(response => {
            if (response.ok) {
                setState({
                    ...state,
                    ...response.data,
                    alert: null
                });
            } else if (response.error.endsWith(" cannot be deleted - records of this type already exist")) {
                errorAlert();
            }
        });
    }

    const toEditRecordTypePage = recordTypeId => {
        history.push({
            pathname: "/workspace-admin/" + props.match.params.wid + "/record-types/" + recordTypeId + "/edit/",
        });
    }

    const toEditRecordTypeRules = recordTypeId => {
        history.push({
            pathname: "/workspace-admin/" + props.match.params.wid + "/record-types/" + recordTypeId + "/rules/",
        });
    }

    const toAddRecordTypePage = () => {
        history.push({
            pathname: "/workspace-admin/" + props.match.params.wid + "/record-types/add"
        });
    }

    const createRow = (prop,index) => {
        return {
            index:index+1,
            id:prop.id,
            type:prop.type,
            name: prop.name,
            description: prop.description,
        }
    }

    const columns = [
        {
            Header: () => (
                <div>#</div>),
            accessor: "index",
        },
        {
            Header: () => (
                <div><FormattedMessage id="Name"/></div>),
            accessor: "name",
        },
        {
            Header: () => (
                <div><FormattedMessage id="Description"/></div>),
            accessor: "description",
        },
        {
            Header: () => (
                <div><FormattedMessage id="Actions"/></div>),
            accessor: "actions",
            sticky:"right"
        },
    ];


    return (
        <>
            {state.alert}
            <AdminNavbar
                title={<FormattedMessage id="RecordTypes"/>}
                hasRightBtn={true}
                rightBtnHasIcon={true}
                rightBtnName={<FormattedMessage id="Button.addRecordType" />}
                rightButtonAction={toAddRecordTypePage}
            />
            <CrossaPageTemplate>
                <CrossaTable
                    columns={columns}
                    data={state.recordTypes.map((element,index) => createRow(element,index))}
                    handleEdit={toEditRecordTypePage}
                    handleDelete={deleteAlert}
                    handleEditRules={toEditRecordTypeRules}
                />
            </CrossaPageTemplate>
        </>
    );
}

export default AllRecordTypesPage;
