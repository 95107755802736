/*!

=========================================================
* Light Bootstrap Dashboard PRO React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/light-bootstrap-dashboard-pro-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
import ReactDOM from "react-dom";
import {HashRouter, Redirect, Route, Switch} from "react-router-dom";
import "@fortawesome/fontawesome-free/css/all.min.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "assets/lib/scss/light-bootstrap-dashboard-pro-react.scss?v=2.0.0";
import "assets/css/main.css";
import PrivateRoute from "routes/PrivateRoute.js";
import PublicLayout from "layouts/PublicLayout.js";
import SuperAdminLayout from "layouts/SuperAdminLayout.js";
import WorkspaceMemberLayout from "layouts/WorkspaceMemberLayout.js";
import WorkspaceAdminLayout from "layouts/WorkspaceAdminLayout.js";
import {IntlProvider} from "react-intl";
// import en from "react-intl/locale-data/en";
// import ro from "react-intl/locale-data/ro";
import localeData from "i18n/data.json";
import Page404 from "./views/Page404";
import LoginPage from "./views/LoginPage";

// addLocaleData([...en, ...ro]);
require('@formatjs/intl-pluralrules/polyfill');
require('@formatjs/intl-pluralrules/locale-data/en');
require('@formatjs/intl-pluralrules/locale-data/ro');
require('@formatjs/intl-relativetimeformat/polyfill');
require('@formatjs/intl-relativetimeformat/locale-data/de');

let language =
  (navigator.languages && navigator.languages[0]) ||
  navigator.language ||
  navigator.userLanguage;

// Split locales with a region code
let languageWithoutRegionCode = language.toLowerCase().split(/[_-]+/)[0];

// console.log("Detected language "+languageWithoutRegionCode);
if (localeData[languageWithoutRegionCode] === undefined) {
  languageWithoutRegionCode = localeData.defaultLang;
}

// console.log("Use language "+languageWithoutRegionCode);
// Try full locale, try locale without region code, fallback to 'en'
const messages = localeData[languageWithoutRegionCode];
// console.log(messages);
// console.log(languageWithoutRegionCode);

document.documentElement.lang = languageWithoutRegionCode;

ReactDOM.render(
  <IntlProvider locale={languageWithoutRegionCode} messages={messages}>
    <HashRouter>
      <Switch>

        <Route exact path="/" render={(props) => <LoginPage  {...props} />} />

        <Route exact path="/404" render={(props) => <PublicLayout hasFooter={false} {...props} />} />

        <Route path="/auth" render={(props) => <PublicLayout hasFooter={true} {...props} />} />

        <PrivateRoute path="/admin" render={(props) => <SuperAdminLayout {...props} />} />

        <PrivateRoute path="/workspace-admin" render={(props) => <WorkspaceAdminLayout {...props} />} />

        <PrivateRoute path="/workspace-member" render={(props) => <WorkspaceMemberLayout {...props} />}/>

        <Route path="*" render={(props) => <Page404 {...props} />} />


      </Switch>
    </HashRouter>
  </IntlProvider>
  , document.getElementById('root'));
