import {useEffect, useRef} from "react";
import {INDETERMINATE_CHECKBOX} from "../util/Constants";
import {notificationsIconChecked, notificationsIconIndeterminate, notificationsIconUnchecked} from "./Icons";

function IndeterminateCheckbox(props) {
    const { value, ...otherProps } = props
    const checkRef = useRef();

    useEffect(() => {
        checkRef.current.checked = value === 1
        checkRef.current.indeterminate = value === -1
    }, [value])



    return (
        <div className={"fake-input"}>
            <input
                className="indeterminate-checkbox"
                type="checkbox"
                onClick={props.onClick}
                ref={checkRef}
                {...otherProps}
            />
            {props.value === INDETERMINATE_CHECKBOX.CHECKED && <i>{notificationsIconChecked}</i>}
            {props.value === INDETERMINATE_CHECKBOX.UNCHECKED && <i>{notificationsIconUnchecked}</i>}
            {props.value === INDETERMINATE_CHECKBOX.INDETERMINATE && <i>{notificationsIconIndeterminate}</i>}
        </div>

    )
}

export default IndeterminateCheckbox;
