import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import Footer from "components/Footer.js"
import * as Validations from "validations/admin/workspace/WorkspaceValidations.js";
import { FormattedMessage } from 'react-intl';
import { Card, Col, Form, Row, } from "react-bootstrap";
import { EventBus } from "util/EventBus.js";
import { getWorkspaceByIdHandler } from "handlers/adminHandlers/workspaceHandlers/AddWorkspaceHandlers";
import { saveWorkspaceHandler } from "handlers/adminHandlers/workspaceHandlers/AddWorkspaceHandlers";
import CrossaPageTemplate from "../../../components/CrossaPageTemplate";
import AdminNavbar from "../../../components/AdminNavbar";
import {getNotifyErrorOptions} from "util/Util";
import NotificationAlert from "react-notification-alert";
import {VALIDATIONS} from "../../../util/Constants";

function AddWorkspacePage(props) {

    const history = useHistory();
    const [state, setState] = useState({
        workspace: {
            "name": "",
            "description": "",
        },
        errors: {
            "name": false,
            "description": false
        }
    })

    const wid = +props.match.params.wid;

    useEffect(() => {
        if (wid) {
            getPageContent();
        }
    }, []);

    const getPageContent = async () => {
        if (wid) {
            getWorkspaceByIdHandler(wid).then(response => {
                if (response.ok) {
                    setState({
                        ...state,
                        ...response.data
                    });
                }
            })
        }
    }

    const handleInput = event => {
        let newErrors={...state.errors};

        if (!!state.errors[event.target.name]) {
            newErrors={
                ...newErrors,
                [event.target.name]: false,
            };
        }

        setState({
            ...state,
            workspace: {
                ...state.workspace,
                [event.target.name]: event.target.value,
            },
            errors:newErrors
        });
    }

    const handleSubmit = event => {
        event.preventDefault();
    }


    const saveWorkspace = async () => {

        const [newErrors, existErrors] = Validations.addWorkspaceValidations(state.workspace, { ...state.errors });
        setState({
            ...state,
            errors:newErrors
        });

        if (existErrors) {
            let options=getNotifyErrorOptions("bc");
            notificationAlertRef.current.notificationAlert(options);
            return;
        }

        saveWorkspaceHandler(wid, state.workspace).then(response => {
            if (response.ok) {
                history.push({
                    pathname: "/admin/workspaces",
                });
                if(!wid) {
                    EventBus.dispatch("workspaceAdded");
                }
            }
        })
    }
    const notificationAlertRef = React.useRef(null);

    return (
        <>
            <div className="rna-container">
                <NotificationAlert ref={notificationAlertRef} />
            </div>
            <AdminNavbar
                title={<FormattedMessage id={wid ? "EditWorkspace" : "NewWorkspace"} />}
                hasBackBtn={true}
            />
        <CrossaPageTemplate>
            <Row>
                <Col md={12}>
                    <Card className="stacked-form">
                        <Card.Body>
                            <Form onSubmit={handleSubmit}>
                                <Form.Group>
                                    <label>
                                        <FormattedMessage id="Title" />
                                    </label>
                                    <FormattedMessage id="Title">
                                        {placeholder=>
                                            <Form.Control
                                                name="name"
                                                placeholder={placeholder}
                                                type="text"
                                                onChange={handleInput}
                                                value={state.workspace.name || ""}
                                                isInvalid={state.errors.name}
                                                className={state.errors.name && "has-errors"}
                                            />
                                        }
                                    </FormattedMessage>
                                    <Form.Control.Feedback type="invalid">
                                        <FormattedMessage id={"Validation.cannotBeEmptyAndParamMin"} values={{ paramMin:VALIDATIONS.MIN_LENGTH_DEFAULT}}/>
                                    </Form.Control.Feedback>
                                </Form.Group>
                                <Form.Group>
                                    <label>
                                        <FormattedMessage id="Description" />
                                    </label>
                                    <FormattedMessage id="Description">
                                        {placeholder =>
                                            <Form.Control
                                                name="description"
                                                placeholder={placeholder}
                                                type="text"
                                                onChange={handleInput}
                                                value={state.workspace.description || ""}
                                                isInvalid={state.errors.description}
                                                className={state.errors.description && "has-errors"}
                                            />
                                        }
                                    </FormattedMessage>

                                    <Form.Control.Feedback type="invalid">
                                        <FormattedMessage id={"Validation.cannotBeEmptyAndParamMin"} values={{ paramMin:VALIDATIONS.MIN_LENGTH_DEFAULT}}/>
                                    </Form.Control.Feedback>
                                </Form.Group>

                            </Form>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
            <Footer
                save={saveWorkspace}
                cancel={() => history.goBack()}
                isEnable={true} />

        </CrossaPageTemplate>
        </>
    );
}

export default AddWorkspacePage;
