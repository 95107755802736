import * as RestClient from "../util/api/restClient";
import * as ClientConfig from "../config/ClientConfig";
import * as StorageService from "./StorageService";
import {getLocate} from "../util/Util";


const createPaymentLink=(vid,paymentRecordId)=>{
    return RestClient.handleResponse(() => {
        const finalURL = ClientConfig.URL_RESOURCE_SERVER + ClientConfig.NAMESPACE_PAYMENT + "initiate/" + vid + "/" + paymentRecordId+"?locale="+getLocate();
        const response = fetch(finalURL, {
            method: "POST",
            headers: {
                "Authorization": "Bearer " + StorageService.getAccessToken()
            }
        });

        return response;
    });
}

const getInvoice=(wid,paymentRecordId)=>{
    return RestClient.handleResponseFile(() => {
        const finalURL = ClientConfig.URL_RESOURCE_SERVER + ClientConfig.NAMESPACE_PAYMENT + "export/" + wid + "/" + paymentRecordId;
        const response = fetch(finalURL, {
            method: "GET",
            headers: {
                "Content-Type": "application/pdf",
                "Authorization": "Bearer " + StorageService.getAccessToken()
            }
        });
        return response;
    });
}

export {
    createPaymentLink,
    getInvoice
}