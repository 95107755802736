import React from 'react';
import {Route, Redirect} from "react-router-dom";
import * as StorageService from "service/StorageService.js"

function PrivateRoute({render, ...rest}) {
    return (
        <Route
            {...rest}
            render={({location,...props}) =>
                StorageService.isLoggedIn() ? (
                    render(props)
                ) : (
                    <Redirect
                        to={{
                            pathname: "/auth/login-page",
                            state: {from: location}
                        }}
                    />
                )
            }
        />
    );
}

export default PrivateRoute;
