import {Card, Col, Form, Row} from "react-bootstrap";
import {FormattedMessage} from "react-intl";
import Select from "react-select";
import currencies from "currency-codes";
import React, {useEffect, useState} from "react";
import {useHistory} from "react-router-dom";
import {
    getFormPaymentSetupByWorkspaceIdHandler,
    saveFormPaymentSetupHandler
} from "../../../../handlers/workspaceAdminHandlers/settingsHandlers/PaymentDetailsHandlers";
import {getNotifySuccessOptions} from "../../../../util/Util";
import NotificationAlert from "react-notification-alert";
import Footer from "../../../../components/Footer";


function FormPaymentSetup(props) {

    const history = useHistory();

    const wid = props.match.params.wid;

    const [state, setState] = useState({
        formPaymentSetup: {
            "company": "",
            "address": "",
            "city": "",
            "county": "",
            "country": "",
            "regNr": "",
            "vatNr": "",
            "bank": "",
            "iban": "",
            "currency": "",
            "secretApiKey": "",
            "merchantId": ""
        },
        errors: {
            "company": false,
            "address": false,
            "city": false,
            "county": false,
            "country": false,
            "regNr": false,
            "vatNr": false,
            "bank": false,
            "iban": false,
            "currency": false,
            "secretApiKey": false,
            "merchantId": false
        }
    })

    useEffect(() => {
        getPageContent();
    }, []);


    const getPageContent = async () => {
        getFormPaymentSetupByWorkspaceIdHandler(wid).then(response => {
            if (response.ok) {
                setState({
                    ...state,
                    ...response.data
                });
            }
        })
    }

    const handleInputChange = event => {
        let newErrors = {...state.errors};

        if (!!state.errors[event.target.name]) {
            newErrors = {
                ...newErrors,
                [event.target.name]: false,
            };
        }
        setState({
            ...state,
            formPaymentSetup: {
                ...state.formPaymentSetup,
                [event.target.name]: event.target.value,
            },
            errors: newErrors
        });

    }

    const handleCountryChange = event => {
        let newErrors = {...state.errors};

        if (!!state.errors.country) {
            newErrors = {
                ...newErrors,
                "country": false,
            };
        }
        setState({
            ...state,
            formPaymentSetup: {
                ...state.formPaymentSetup,
                "country": event.value,
            },
            errors: newErrors
        });
    }

    const handleCurrencyChange = event => {
        let newErrors = {...state.errors};

        if (!!state.errors.currency) {
            newErrors = {
                ...newErrors,
                "currency": false,
            };
        }
        setState({
            ...state,
            formPaymentSetup: {
                ...state.formPaymentSetup,
                "currency": event.value,
            },
            errors: newErrors
        });
    }

    const handleSubmit = event => {
        event.preventDefault();
    }

    const saveFormPaymentDetails = async () => {

        // const [newErrors, existErrors] = Validations.addUserValidations(user, {...errors});
        // setErrors(newErrors);
        //
        // if (existErrors) {
        //     return;
        // }

        saveFormPaymentSetupHandler(wid, state.formPaymentSetup).then(response => {
            if (response.ok) {
                let options = getNotifySuccessOptions("bc");
                notificationAlertRef.current.notificationAlert(options);
            }
        })
    }

    const notificationAlertRef = React.useRef(null);

    return (
        <>
            <div className="rna-container">
                <NotificationAlert ref={notificationAlertRef}/>
            </div>
            <Row>
                <Col className="title">
                    <Card.Title as="h3"><FormattedMessage id="FormPaymentSetup"/></Card.Title>
                </Col>
            </Row>
            <Row>
                <Col>
                    <Form onSubmit={handleSubmit}>
                        <Form.Group>
                            <label><FormattedMessage id="Payment.company"/></label>
                            <FormattedMessage id="Payment.company">
                                {placeholder =>
                                    <Form.Control
                                        name="company"
                                        type="text"
                                        value={state.formPaymentSetup.company || ""}
                                        onChange={handleInputChange}
                                        isInvalid={state.errors.company}
                                        className={state.errors.company && "has-errors"}
                                        placeholder={placeholder}
                                    />
                                }
                            </FormattedMessage>
                            <Form.Control.Feedback type="invalid">
                                <FormattedMessage id={"Validation.cannotBeEmpty"}/>
                            </Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group>
                            <label><FormattedMessage id="Payment.address"/></label>
                            <FormattedMessage id="Payment.address">
                                {placeholder =>
                                    <Form.Control
                                        name="address"
                                        type="text"
                                        value={state.formPaymentSetup.address || ""}
                                        onChange={handleInputChange}
                                        isInvalid={state.errors.address}
                                        className={state.errors.address && "has-errors"}
                                        placeholder={placeholder}
                                    />
                                }
                            </FormattedMessage>
                            <Form.Control.Feedback type="invalid">
                                <FormattedMessage id={"Validation.cannotBeEmpty"}/>
                            </Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group>
                            <label><FormattedMessage id="Payment.city"/></label>
                            <FormattedMessage id="Payment.city">
                                {placeholder =>
                                    <Form.Control
                                        name="city"
                                        type="text"
                                        value={state.formPaymentSetup.city || ""}
                                        onChange={handleInputChange}
                                        isInvalid={state.errors.city}
                                        className={state.errors.city && "has-errors"}
                                        placeholder={placeholder}
                                    />
                                }
                            </FormattedMessage>
                            <Form.Control.Feedback type="invalid">
                                <FormattedMessage id={"Validation.cannotBeEmpty"}/>
                            </Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group>
                            <label><FormattedMessage id="Payment.county"/></label>
                            <FormattedMessage id="Payment.county">
                                {placeholder =>
                                    <Form.Control
                                        name="county"
                                        type="text"
                                        value={state.formPaymentSetup.county || ""}
                                        onChange={handleInputChange}
                                        isInvalid={state.errors.county}
                                        className={state.errors.county && "has-errors"}
                                        placeholder={placeholder}
                                    />
                                }
                            </FormattedMessage>
                            <Form.Control.Feedback type="invalid">
                                <FormattedMessage id={"Validation.cannotBeEmpty"}/>
                            </Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group>
                            <label><FormattedMessage id="Payment.country"/></label>
                            <FormattedMessage id="Placeholder.select">
                                {placeholder =>
                                    <Select
                                        onChange={handleCountryChange}
                                        className="react-select primary"
                                        classNamePrefix="react-select"
                                        name="country"
                                        value={state.formPaymentSetup.country ?
                                            {
                                                "value": state.formPaymentSetup.country,
                                                "label": state.formPaymentSetup.country
                                            } : ""}
                                        options={currencies.countries().map(country => {
                                            return {
                                                "value": country,
                                                "label": country
                                            }
                                        })}
                                        placeholder={placeholder}
                                    />
                                }
                            </FormattedMessage>
                            <Form.Control.Feedback type="invalid">
                                <FormattedMessage id={"Validation.selectOneOption"}/>
                            </Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group>
                            <label><FormattedMessage id="Payment.regNr"/></label>
                            <FormattedMessage id="Payment.regNr">
                                {placeholder =>
                                    <Form.Control
                                        name="regNr"
                                        type="text"
                                        value={state.formPaymentSetup.regNr || ""}
                                        onChange={handleInputChange}
                                        isInvalid={state.errors.regNr}
                                        placeholder={placeholder}
                                    />
                                }
                            </FormattedMessage>
                            <Form.Control.Feedback type="invalid">
                                <FormattedMessage id={"Validation.cannotBeEmpty"}/>
                            </Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group>
                            <label><FormattedMessage id="Payment.VAT"/></label>
                            <FormattedMessage id="Payment.VAT">
                                {placeholder =>
                                    <Form.Control
                                        name="vatNr"
                                        type="text"
                                        value={state.formPaymentSetup.vatNr || ""}
                                        onChange={handleInputChange}
                                        isInvalid={state.errors.vatNr}
                                        className={state.errors.vatNr && "has-errors"}
                                        placeholder={placeholder}
                                    />
                                }
                            </FormattedMessage>
                            <Form.Control.Feedback type="invalid">
                                <FormattedMessage id={"Validation.cannotBeEmpty"}/>
                            </Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group>
                            <label><FormattedMessage id="Payment.bank"/></label>
                            <FormattedMessage id="Payment.bank">
                                {placeholder =>
                                    <Form.Control
                                        name="bank"
                                        type="text"
                                        value={state.formPaymentSetup.bank || ""}
                                        onChange={handleInputChange}
                                        isInvalid={state.errors.bank}
                                        className={state.errors.bank && "has-errors"}
                                        placeholder={placeholder}
                                    />
                                }
                            </FormattedMessage>
                            <Form.Control.Feedback type="invalid">
                                <FormattedMessage id={"Validation.cannotBeEmpty"}/>
                            </Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group>
                            <label><FormattedMessage id="Payment.IBAN"/></label>
                            <FormattedMessage id="Payment.IBAN">
                                {placeholder =>
                                    <Form.Control
                                        name="iban"
                                        type="text"
                                        value={state.formPaymentSetup.iban || ""}
                                        onChange={handleInputChange}
                                        isInvalid={state.errors.iban}
                                        className={state.errors.iban && "has-errors"}
                                        placeholder={placeholder}
                                    />
                                }
                            </FormattedMessage>
                            <Form.Control.Feedback type="invalid">
                                <FormattedMessage id={"Validation.cannotBeEmpty"}/>
                            </Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group>
                            <label><FormattedMessage id="Payment.currency"/></label>
                            <FormattedMessage id="Placeholder.select">
                                {placeholder =>
                                    <Select
                                        onChange={handleCurrencyChange}
                                        className="react-select primary"
                                        classNamePrefix="react-select"
                                        name="country"
                                        value={state.formPaymentSetup.currency ?
                                            {
                                                "value": state.formPaymentSetup.currency,
                                                "label": state.formPaymentSetup.currency
                                            } : ""}
                                        options={currencies.codes().map(code => {
                                            return {
                                                "value": code,
                                                "label": code
                                            }
                                        })}
                                        placeholder={placeholder}
                                    />
                                }
                            </FormattedMessage>
                            <Form.Control.Feedback type="invalid">
                                <FormattedMessage id={"Validation.selectOneOption"}/>
                            </Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group>
                            <label><FormattedMessage id="Payment.secretApiKey"/></label>
                            <FormattedMessage id="Payment.secretApiKey">
                                {placeholder =>
                                    <Form.Control
                                        name="secretApiKey"
                                        type="text"
                                        value={state.formPaymentSetup.secretApiKey || ""}
                                        onChange={handleInputChange}
                                        isInvalid={state.errors.secretApiKey}
                                        className={state.errors.secretApiKey && "has-errors"}
                                        placeholder={placeholder}
                                    />
                                }
                            </FormattedMessage>
                            <Form.Control.Feedback type="invalid">
                                <FormattedMessage id={"Validation.cannotBeEmpty"}/>
                            </Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group>
                            <label><FormattedMessage id="Payment.merchantId"/></label>
                            <FormattedMessage id="Payment.merchantId">
                                {placeholder =>
                                    <Form.Control
                                        name="merchantId"
                                        type="text"
                                        value={state.formPaymentSetup.merchantId || ""}
                                        onChange={handleInputChange}
                                        isInvalid={state.errors.merchantId}
                                        className={state.errors.merchantId && "has-errors"}
                                        placeholder={placeholder}
                                    />
                                }
                            </FormattedMessage>
                            <Form.Control.Feedback type="invalid">
                                <FormattedMessage id={"Validation.cannotBeEmpty"}/>
                            </Form.Control.Feedback>
                        </Form.Group>
                    </Form>
                </Col>
            </Row>
            <Footer
                save={saveFormPaymentDetails}
                cancel={() => history.goBack()}
                isEnable={true} />
        </>
    );
}

export default FormPaymentSetup;