import * as ClientConfig from "config/ClientConfig.js";
import * as StorageService from "service/StorageService.js";
import * as RestClient from "util/api/restClient"

const addWorkspaceRole = async workspaceRole => {
    return RestClient.handleResponse(() => {
        const finalURL = ClientConfig.URL_RESOURCE_SERVER + ClientConfig.NAMESPACE_ROLE;
        const response = fetch(finalURL, {
            method: "POST",
            body: JSON.stringify(workspaceRole),
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + StorageService.getAccessToken()
            }
        });

        return response;
    });
}

const findAllWorkspaceRoles = (wid) => {
    return RestClient.handleResponse(() => {
        const finalURL = ClientConfig.URL_RESOURCE_SERVER + ClientConfig.NAMESPACE_WORKSPACE_ROLE + wid;
        const response = fetch(finalURL, {
            method: "GET",
            headers: {
                "Authorization": "Bearer " + StorageService.getAccessToken()
            }
        });

        return response;
    });
}

const deleteWorkspaceRoleById = async workspaceRoleId => {
    return RestClient.handleResponse(() => {
        const finalURL = ClientConfig.URL_RESOURCE_SERVER + ClientConfig.NAMESPACE_ROLE + workspaceRoleId;
        const response = fetch(finalURL, {
            method: "DELETE",
            headers: {
                "Authorization": "Bearer " + StorageService.getAccessToken()
            }
        });

        return response;
    });
}

const findWorkspaceRoleById = async workspaceRoleId => {
    return RestClient.handleResponse(() => {
        const finalURL = ClientConfig.URL_RESOURCE_SERVER + ClientConfig.NAMESPACE_ROLE + workspaceRoleId;
        const response = fetch(finalURL, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + StorageService.getAccessToken()
            }
        });

        return response;
    });
}


const updateWorkspaceRole = async newWorkspaceRole => {
    return RestClient.handleResponse(() => {
        const workspaceRoleId = newWorkspaceRole.id;
        const finalURL = ClientConfig.URL_RESOURCE_SERVER + ClientConfig.NAMESPACE_ROLE + workspaceRoleId;
        const response = fetch(finalURL, {
            method: "PUT",
            headers: {
                "Authorization": "Bearer " + StorageService.getAccessToken(),
                "Content-Type": "application/json",
            },
            body: JSON.stringify(newWorkspaceRole),
        });

        return response;
    });
}

export {
    addWorkspaceRole,
    findAllWorkspaceRoles,
    deleteWorkspaceRoleById,
    findWorkspaceRoleById,
    updateWorkspaceRole,
}
