import * as WorkspaceService from "service/WorkspaceService";


const getWorkspaceByIdHandler = async workspaceId => {
    let workspace = null;

    const response = await WorkspaceService.findWorkspaceById(workspaceId);

    if (response.ok) {
        workspace = {
            "id": workspaceId,
            "name": response.data.name,
            "description": response.data.description,
        }
    }

    return {
        data: {
            workspace: workspace
        },
        ok: response.ok
    }
}

const saveWorkspaceHandler = async (wid, workspace) => {

    let response;
    if (wid) {
        response = await WorkspaceService.updateWorkspace(workspace);
    } else {
        response = await WorkspaceService.addWorkspace(workspace);
    }

    return {
        ok: response.ok
    }
}
export {
    getWorkspaceByIdHandler,
    saveWorkspaceHandler
}
