import * as ClientConfig from "config/ClientConfig.js";
import * as StorageService from "service/StorageService.js"
import * as RestClient from "util/api/restClient.js";

const insertMenu = async menu => {
    return RestClient.handleResponse(() => {
        const finalURL = ClientConfig.URL_RESOURCE_SERVER + ClientConfig.NAMESPACE_MENU;
        const response = fetch(finalURL, {
            method: "POST",
            body: JSON.stringify(menu),
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + StorageService.getAccessToken()
            }
        });

        return response;
    });
}

const updateMenu = async menu => {
    return RestClient.handleResponse(() => {
        const finalURL = ClientConfig.URL_RESOURCE_SERVER + ClientConfig.NAMESPACE_MENU + menu.id;
        const response = fetch(finalURL, {
            method: "PUT",
            body: JSON.stringify(menu),
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + StorageService.getAccessToken()
            }
        });

        return response;
    });
}

const findAllMenus = (wid) => {
    return RestClient.handleResponse(() => {
        const finalURL = ClientConfig.URL_RESOURCE_SERVER + ClientConfig.NAMESPACE_MENU + "roles/workspaces/" + wid;
        const response = fetch(finalURL, {
            method: "GET",
            headers: {
                "Authorization": "Bearer " + StorageService.getAccessToken()
            }
        });

        return response;
    });
}

const deleteMenu = menuId => {
    return RestClient.handleResponse(() => {
        const finalURL = ClientConfig.URL_RESOURCE_SERVER + ClientConfig.NAMESPACE_MENU + menuId;
        const response = fetch(finalURL, {
            method: "DELETE",
            headers: {
                "Authorization": "Bearer " + StorageService.getAccessToken()
            }
        });

        return response;
    });
}

const findById = menuId => {
    return RestClient.handleResponse(() => {
        const finalURL = ClientConfig.URL_RESOURCE_SERVER + ClientConfig.NAMESPACE_MENU + menuId;
        const response = fetch(finalURL, {
            method: "GET",
            headers: {
                "Authorization": "Bearer " + StorageService.getAccessToken()
            }
        });

        return response;
    });
}

const findMemberMenuForMe = wid => {
    return RestClient.handleResponse(() => {
        const finalURL = ClientConfig.URL_RESOURCE_SERVER + ClientConfig.NAMESPACE_MENU + "pages/workspaces/" + wid;
        const response = fetch(finalURL, {
            method: "GET",
            headers: {
                "Authorization": "Bearer " + StorageService.getAccessToken()
            }
        });

        return response;
    });
}

const changeOrder=(menuId,newIndex)=>{
    return RestClient.handleResponse(()=>{
        const finalURL=ClientConfig.URL_RESOURCE_SERVER+ClientConfig.NAMESPACE_MENU+menuId+"/order/"+newIndex;
        const response = fetch(finalURL, {
            method: "PUT",
            headers: {
                "Authorization": "Bearer " + StorageService.getAccessToken()
            }
        });
        return response;
    });
}

const findMemberTabFilters = (mid,pageName,tabName) =>{
    return RestClient.handleResponse(()=>{
        const finalURL=ClientConfig.URL_RESOURCE_SERVER+ClientConfig.NAMESPACE_MENU+mid+"/page/"+pageName+"/tab/"+tabName+"/filters";
        const response = fetch(finalURL, {
            method: "GET",
            headers: {
                "Authorization": "Bearer " + StorageService.getAccessToken()
            }
        });
        return response;
    });
}

const upsertMemberTabFilters = async (mid,pageName,tabName,filter) => {
    return RestClient.handleResponse(()=>{
        const finalURL=ClientConfig.URL_RESOURCE_SERVER+ClientConfig.NAMESPACE_MENU+mid+"/page/"+pageName+"/tab/"+tabName+"/filters";
        const response = fetch(finalURL, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + StorageService.getAccessToken()
            },
            body:JSON.stringify(filter)
        });
        return response;
    });
}

export {
    deleteMenu,
    findAllMenus,
    findById,
    insertMenu,
    updateMenu,
    findMemberMenuForMe,
    changeOrder,
    findMemberTabFilters,
    upsertMemberTabFilters
}
