import * as Validations from "validations/validations.js"

const addWorkspaceValidations = (workspace, errors) => {
    if (workspace.name.length < 3) {
        errors = {
            ...errors,
            "name": true,
        }
    }
    if (workspace.description.length < 3) {
        errors = {
            ...errors,
            "description": true,
        }
    }

    const existErrors = Validations.checkIfError(errors);

    return [errors, existErrors];
}

export {
    addWorkspaceValidations
}
