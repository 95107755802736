import DisplayMenuPage from "views/workspaceMember/menuDisplay/DisplayMenuPage.js"
import LoginPage from "views/LoginPage.js";
import Home from "views/workspaceMember/Home.js";
import FormDataPage from "../views/workspaceMember/form/FormDataPage";
import NotificationsSettings from "../views/workspaceMember/settings/Notifications";
import PaymentSuccess from "../views/workspaceMember/payment-response/PaymentSuccess";
import PaymentError from "../views/workspaceMember/payment-response/PaymentError";
import DisplayMenuDashboardPage from "../views/workspaceMember/menuDisplay/DisplayMenuDashboardPage";

const workspaceMemberRoutes = [
    {
        path: "/:wid/home",
        name: "Home",
        icon: null,
        component: Home,
        layout: "/workspace-member",
    },
    {
        path: "/:wid/menus/:mid/page/:pid/tab/:tabid",
        name: "DisplayMenuPage",
        icon: null,
        component: DisplayMenuPage,
        layout: "/workspace-member",
    },
    {
        path: "/:wid/menus/:mid/dashboard",
        name: "DisplayMenuDashboardPage",
        icon: null,
        component: DisplayMenuDashboardPage,
        layout: "/workspace-member",
    },
    {
        path: "/:wid/forms/:vid",
        name: "FormDataPage",
        icon: null,
        component: FormDataPage,
        layout: "/workspace-member",
    },
    {
        path: "/:wid/notifications",
        name: "NotificationsPage",
        icon: null,
        component: NotificationsSettings,
        layout: "/workspace-member",
    },
    {
        path: "/:wid/payment/success",
        name: "PaymentSuccess",
        icon: null,
        component: PaymentSuccess,
        layout: "/workspace-member",
    },
    {
        path: "/:wid/payment/error",
        name: "PaymentError",
        icon: null,
        component: PaymentError,
        layout: "/workspace-member",
    },
    {
        collapse: true,
        path: "/pages",
        name: "Pages",
        state: "openPages",
        icon: null,
        views: [
            {
                path: "/login-page",
                layout: "/auth",
                name: "Login Page",
                mini: "LP",
                component: LoginPage,
            },
        ],
    },
];

export default workspaceMemberRoutes;
