import React, {useEffect, useRef, useState} from "react";
import { Link, useHistory, useLocation } from "react-router-dom";
import * as StorageService from "service/StorageService.js";
import EXCEPTION_MESSAGES, { CONTEXT, LAYOUT } from "util/Constants";
import * as Util from "util/Util.js";
import { EventBus } from "util/EventBus.js";
import { FormattedMessage, injectIntl } from 'react-intl';
// react-bootstrap components
import { Collapse, Form, Nav, Tab, } from "react-bootstrap";
import SweetAlert from "react-bootstrap-sweetalert";
import * as UserService from "../service/UserService";
import {iconForMenus, lockIcon, notificationIcon, powerIcon, workspaceAdminIcon, workspaceMemberIcon} from "./Icons";
import * as ClientConfig from "../config/ClientConfig";
import defaultAvatarIcon from "../assets/img/default-avatar.png";
import {getFirstMenuHandler, getWorkspacesDataHandler} from "../handlers/SidebarHandlers";

function Sidebar(props) {
    const routes = props.routes;
    const image = props.image;
    const background = props.background;
    const context = props.context;
    const history = useHistory();
    let location = useLocation();
    // let context = useContext(Context);
    const [state, setState] = useState({
        dynamicMenusCollapseState: {},
        userCollapseState: false,
        userWorkspaces: [],
        workspaceCollapseState: false,
        switchRoleCollapseState: false,
        finalRoutes: routes,
        isWorkspaceAdmin: false,
        workspaceInfo: { id: null, name: "" , memberVid: null},
        memberImage: "",
        changedContext:false,
        workspacesChanged:false,
    });

    const [alert, setAlert] = useState(null)
    let currentPassword = "";
    let newPassword = "";
    let confirmPassword = "";

    const workspaceInfoIdRef=useRef(null);


    useEffect(async () => {
        let wid = getId();
        if (workspaceInfoIdRef.current !== null && workspaceInfoIdRef.current !== getId() && wid !== null) {
            let workspace = state.userWorkspaces?.find(workspace => workspace.id === wid);
            await enterWorkspace(workspace.id, workspace.name, workspace.memberVid);
        }
    }, [history.location.pathname])


    useEffect(() => {
        const eventHandler = () => {
            setState({
                ...state,
                workspacesChanged: true
            });
        }

        EventBus.on("workspaceDeleted", eventHandler);
        EventBus.on("workspaceAdded", eventHandler);

        return function cleanup() {
            EventBus.remove("workspaceAdded", eventHandler);
            EventBus.remove("workspaceDeleted", eventHandler);
        };
    }, []);

    useEffect(async () => {
        let newState={...state};
        let wid;
        if(state.workspaceInfo.id){
            wid=state.workspaceInfo.id
        } else{
            wid=getId();
        }

        if((state.changedContext || state.redirect) &&  StorageService.getIsSuperAdmin() === "true"){
            history.push("/workspace-admin/" + wid + "/home");
        } else if ((state.redirect && state.isAdmin && context === CONTEXT.WORKSPACE_ADMIN) || (state.changedContext &&  context === CONTEXT.WORKSPACE_MEMBER)) {
            history.push("/workspace-admin/" + wid + "/home");
        } else if ((state.redirect && context===CONTEXT.WORKSPACE_MEMBER) || (state.changedContext && context ===CONTEXT.WORKSPACE_ADMIN)) {
            const path = await getFirstMenuHandler(wid);
            if (!path) {
                history.push("/workspace-member/" + wid + "/home");
            } else {
                history.push("/workspace-member/" + wid + path);
            }
        }

        if(workspaceInfoIdRef.current!==wid || (wid===null && context===CONTEXT.SUPER_ADMIN) || state.workspacesChanged) {
            const response = await getWorkspacesDataHandler(wid, !(location.pathname.indexOf("/workspace-member") >= 0), routes);
            newState={
                ...newState,
                ...response.data,
                workspacesChanged: false,
            };
            if (response.responseWorkspaceOk) {
                workspaceInfoIdRef.current = response.data.workspaceId;
            }
            if (response.responseCurrentMemberOk) {
                StorageService.setMemberName(response.data.memberName);
                EventBus.dispatch("sidebarLoaded", {name: response.data.memberName});
            } else {
                EventBus.dispatch("sidebarLoaded", {
                    email: StorageService.getUsername()
                });
            }
        }
        newState={
            ...newState,
            changedContext: false,
            redirect:false,
        }
        setState(newState);
    }, [state.workspaceInfo.id, state.changedContext, state.workspacesChanged]);

    const logout = event => {
        event.preventDefault();
        Util.logout(history);
    }

    const isSuperAdmin = () => {
        return StorageService.getIsSuperAdmin() !== null && StorageService.getIsSuperAdmin() === "true";
    }

    const changeContext = (changeContext) => {
        if (changeContext === CONTEXT.SUPER_ADMIN) {
            history.push("/" + LAYOUT.ADMIN + "/workspaces");
        }
        else if (changeContext === CONTEXT.WORKSPACE_MEMBER) {
            setState({
                ...state,
                changedContext:true
            });
        } else {
            setState({
                ...state,
                isWorkspaceAdmin: true,
                changedContext:true,
            })
        }
    }

    const getMainPage = () => {
        if (context === CONTEXT.SUPER_ADMIN) {
            history.push("/admin/workspaces");
        } else if (context === CONTEXT.WORKSPACE_ADMIN) {
            history.push("/workspace-admin/" + state.workspaceInfo.id + "/home");
        } else if (context === CONTEXT.WORKSPACE_MEMBER) {
            if(!state.finalRoutes[0]){
                history.push("/workspace-member/" + state.workspaceInfo.id + "/home");
            } else if(state.finalRoutes[0].views[0]?.path){
                history.push("/workspace-member" + state.finalRoutes[0]?.views[0]?.path);
            }
        }
    }


    const getId = () => {
        let workspaceId = null;
        if (history.location.pathname.startsWith("/" + LAYOUT.ADMIN)) {
            return null;
        } else if (history.location.pathname.startsWith("/" + LAYOUT.WORKSPACE_MEMBER)) {
            const id = +history.location.pathname.split("/")[2];
            workspaceId = id;
        } else {
            const id = +history.location.pathname.split("/")[2];
            workspaceId = id;
        }
        return workspaceId || null;
    }

    const enterWorkspace = async (id, name, memberVid) => {
        StorageService.setItem("workspace_name", name);
        StorageService.setItem("workspace_id", id);
        setState({
            ...state,
            isWorkspaceAdmin: isSuperAdmin() || state.isWorkspaceAdmin,
            workspaceCollapseState: false,
            workspaceInfo: {
                "id": id,
                "name": name,
                "memberVid": memberVid
            },
            redirect:true
        });
    }

    const onClickMenu = (key) => {
        const states = {
            ...state.dynamicMenusCollapseState
        };
        states[key] = !state.dynamicMenusCollapseState[key];
        setState({
            ...state,
            dynamicMenusCollapseState: states
        })
    };


    // this function creates the links and collapses that appear in the sidebar (left menu)
    const createLinks = (routes, workspaceIdFinal) => {
        let linkRoutes = routes;
        return linkRoutes.map((linkRoute, key) => {

            if (linkRoute.redirect || !!!linkRoute.isVisible) {
                return null;
            }
            const linkPath = workspaceIdFinal ? linkRoute.path.replace("\:wid", workspaceIdFinal) : linkRoute.path;
            if (linkRoute.hasSubmenus) {
                return (
                    <Nav.Item
                        className={activeRoute(linkRoute, linkPath)}
                        as="li"
                        key={key}
                    >
                        <Nav.Link
                            className={state.dynamicMenusCollapseState[key] ? "collapsed" : ""}
                            data-toggle="collapse"
                            onClick={(e) => {
                                e.preventDefault();
                                onClickMenu(key);
                            }}
                            aria-expanded={state.dynamicMenusCollapseState[key]}
                        >
                            {/*<i className={linkRoute.icon}></i>*/}
                            <i>{iconForMenus}</i>
                            <p>
                                {linkRoute.name} <b className="caret"></b>
                            </p>
                        </Nav.Link>
                        <Collapse in={state.dynamicMenusCollapseState[key]}>
                            <div>
                                <Nav as="ul">{createLinks(linkRoute.views, workspaceIdFinal)}</Nav>
                            </div>
                        </Collapse>
                    </Nav.Item>
                );
            } else
                return (
                    <Nav.Item
                        className={activeRoute(linkRoute, linkPath)}
                        key={key}
                        as="li"
                    >
                        <Nav.Link className={linkRoute.isDynamicMenu? "dynamic-nav-link" : "static-nav-link"} to={linkRoute.layout + linkPath} as={Link}>
                            {linkRoute.icon ? (
                                <>
                                    <i className={linkRoute.icon} />
                                    <p>{linkRoute.isDynamicMenu ? linkRoute.name :
                                        <FormattedMessage id={linkRoute.name} />}</p>
                                </>
                            ) : (
                                <>
                                    <span className="sidebar-mini">{linkRoute.mini}</span>
                                    <span className="sidebar-normal">{linkRoute.name}</span>
                                </>
                            )}
                        </Nav.Link>
                    </Nav.Item>
                );
        });
    };
    // verifies if routeName is the one active (in browser input)
    const activeRoute = (linkRoute, linkPath) => {
        if (linkRoute.isDynamicMenu) {
            if (linkRoute.isPage) {
                return location.pathname.startsWith(linkRoute.layout + linkRoute.path.substring(0, linkRoute.path.length - 1)) ? "active" : "";
            }
        } else {
            return location.pathname.startsWith(linkRoute.layout + linkPath) ? "active" : "";
        }
    };

    const handlePasswordInput = (event, input) => {
        if (input === "currentPassword") {
            currentPassword = event.target.value;
        } else if (input === "newPassword") {
            newPassword = event.target.value;
        } else if (input === "confirmPassword") {
            confirmPassword = event.target.value;
        }
    }

    const showChangePassword = () => {
        setAlert(
            <SweetAlert
            style={{ display: "block", marginTop: "-100px" }}
            title={<FormattedMessage id={"Sidebar.changePassword"}/>}
            onConfirm={() => changePassword()}
            onCancel={() => hideAlert()}
            confirmBtnBsStyle="success"
            cancelBtnBsStyle="default"
            confirmBtnText={<FormattedMessage id={"Sidebar.changePassword"}/>}
            cancelBtnText={<FormattedMessage id={"Button.cancel"}/>}
            btnSize={"sm"}
            showCancel>
                <br/>
        <Form.Group>
            <Form.Label>
                <FormattedMessage id="ResetPassword.currentPassword"/>
            </Form.Label>
            <Form.Control
                type="password"
                onChange={(e) => handlePasswordInput(e, "currentPassword")}
            />
        </Form.Group>
                <Form.Group>
                    <Form.Label>
                        <FormattedMessage id="ResetPassword.newPassword"/>
                    </Form.Label>
                    <Form.Control
                        type="password"
                        onChange={(e) => handlePasswordInput(e, "newPassword")}
                    />
                </Form.Group>
                <Form.Group>
                    <Form.Label>
                        <FormattedMessage id="ResetPassword.confirmPassword"/>
                    </Form.Label>
                    <Form.Control
                        type="password"
                        onChange={(e) => handlePasswordInput(e, "confirmPassword")}
                    />
                </Form.Group>
                <br/>

        </SweetAlert>);
    }

    const changePassword = async () => {
        const password = {
            "oldPassword": currentPassword,
            "newPassword": newPassword,
            "confirmPassword": confirmPassword
        };
        const response = await UserService.changePassword(password);

        if (response.ok) {
            setAlert(
                <SweetAlert
                    success
                    style={{ display: "block", marginTop: "-100px" }}
                    title={<FormattedMessage id={"Alert.passwordChangedTitle"}/>}
                    onConfirm={() => hideAlert()}
                    onCancel={() => hideAlert()}
                    confirmBtnBsStyle="info"
                >
                    <FormattedMessage id="Alert.passwordChanged"/>
                </SweetAlert>
            );
        } else {
            if (!!response.data.message && response.data.message === EXCEPTION_MESSAGES.INVALID_REFRESH_TOKEN) {
                Util.logout(history);
            } else {
                console.error("ERROR:", response);
            }
        }
    }

    const hideAlert = () => {
        setAlert(null);
        newPassword = "";
        currentPassword = "";
        confirmPassword = "";
    };

    return (
        <>
            {alert}
            <div className="sidebar" data-color={background} data-image={image}>
                <div className="sidebar-wrapper">
                    <div className="logo">
                        <a href={"#"} onClick={(e)=>{e.preventDefault();getMainPage()}} className="logo-link"> <img className="logo-img"
                            src={require("assets/img/logo_with_text_transparent.svg").default}
                            alt="account icon" />
                        </a>
                    </div>


                    <Tab.Container
                        defaultActiveKey={context}
                        activeKey={context}
                    >

                        {
                            context === CONTEXT.WORKSPACE_MEMBER || context === CONTEXT.WORKSPACE_ADMIN ?
                                <div className="workspace">

                                    <div className="photo">
                                        <i className="fa fa-folder" aria-hidden="true"></i>
                                    </div>
                                    <div className="info">
                                        <a
                                            className={state.workspaceCollapseState ? "collapsed" : ""}
                                            data-toggle="collapse"
                                            href="#"
                                            onClick={(e) => {
                                                e.preventDefault();
                                                setState({
                                                    ...state,
                                                    workspaceCollapseState: !state.workspaceCollapseState,
                                                })
                                            }}
                                            aria-expanded={state.workspaceCollapseState}
                                        >

                                            <span>{state.workspaceInfo.name}</span> <b className="caret"></b>
                                        </a>

                                        <Collapse in={state.workspaceCollapseState}>
                                            <div>
                                                <Nav as="ul">
                                                    {state.userWorkspaces.map((workspace) =>
                                                        <li key={workspace.id}>
                                                            <a onClick={() => enterWorkspace(workspace.id, workspace.name, workspace.memberVid)}
                                                                className="workspace-link">
                                                                <span className="sidebar-mini">
                                                                    <i className="fa fa-arrow-right" aria-hidden="true"></i>
                                                                </span>
                                                                <span className="sidebar-normal">{workspace.name}</span>
                                                            </a>
                                                        </li>
                                                    )}

                                                </Nav>
                                            </div>
                                        </Collapse>
                                    </div>
                                </div>
                                :
                                null
                        }

                        <Tab.Content>

                            <Nav as="ul">
                                {createLinks(state.finalRoutes, state.workspaceInfo.id)}
                            </Nav>



                        </Tab.Content>
                    </Tab.Container>
                    <div className="bottom-group">
                        {
                            (context === CONTEXT.WORKSPACE_ADMIN || state.isWorkspaceAdmin || StorageService.getIsSuperAdmin() === "true") &&
                            <div className="switch-role">
                                <div className="photo">
                                    {context === CONTEXT.WORKSPACE_MEMBER ?
                                        <i>{workspaceMemberIcon}</i>
                                        :
                                        <i>{workspaceAdminIcon}</i>
                                    }


                                </div>
                                <div className="info">
                                    <a
                                        className={state.switchRoleCollapseState ? "collapsed" : ""}
                                        data-toggle="collapse"
                                        href="#"
                                        onClick={(e) => {
                                            e.preventDefault();
                                            setState({
                                                ...state,
                                                switchRoleCollapseState: !state.switchRoleCollapseState,
                                            })
                                        }}
                                        aria-expanded={state.switchRoleCollapseState}
                                    >
                                        <span>
                                            <FormattedMessage id={context}/>
                                            {context !== CONTEXT.WORKSPACE_MEMBER || state.isWorkspaceAdmin ?
                                                state.userWorkspaces.length > 0 && <b className="caret"></b>
                                                : null}
                                        </span>

                                    </a>
                                    <Collapse in={state.switchRoleCollapseState}>
                                        <div>
                                            <Nav as="ul">

                                                {context !== CONTEXT.SUPER_ADMIN && StorageService.getIsSuperAdmin() === "true" ?

                                                    <li>
                                                        <Nav.Link className="p-0"
                                                                  onClick={() => changeContext(CONTEXT.SUPER_ADMIN)}
                                                                  eventKey={CONTEXT.SUPER_ADMIN}>
                                                            <i className="fa fa-arrow-right" aria-hidden="true"></i>
                                                            <FormattedMessage id={CONTEXT.SUPER_ADMIN}/>
                                                        </Nav.Link>
                                                    </li>
                                                    : null
                                                }


                                                {context !== CONTEXT.WORKSPACE_MEMBER && StorageService.getIsSuperAdmin() === "false" ?

                                                    <li>
                                                        <Nav.Link className="p-0"
                                                                  onClick={() => changeContext(CONTEXT.WORKSPACE_MEMBER)}
                                                                  eventKey={CONTEXT.WORKSPACE_MEMBER}>

                                                            <i className="fa fa-arrow-right" aria-hidden="true"></i>


                                                            <FormattedMessage id={CONTEXT.WORKSPACE_MEMBER}/>
                                                        </Nav.Link>
                                                    </li>
                                                    : null
                                                }
                                                {state.isWorkspaceAdmin && context !== CONTEXT.WORKSPACE_ADMIN && state.userWorkspaces.length > 0 ?
                                                    <li>
                                                        <Nav.Link className="p-0"
                                                                  onClick={() => changeContext(CONTEXT.WORKSPACE_ADMIN)}
                                                                  eventKey={CONTEXT.WORKSPACE_ADMIN}>
                                                            <i className="fa fa-arrow-right" aria-hidden="true"></i>
                                                            <FormattedMessage id={CONTEXT.WORKSPACE_ADMIN}/>
                                                        </Nav.Link>
                                                    </li>
                                                    : null
                                                }

                                            </Nav>
                                        </div>
                                    </Collapse>
                                </div>
                            </div>
                        }
                        <div className="user">
                            <div className="photo">
                                {state.memberImage ?
                                    <img src={ClientConfig.URL_RESOURCE_SERVER + ClientConfig.NAMESPACE_RECORDS + "images/recordviews/" +
                                        state.workspaceInfo.memberVid + "/image/" + state.memberImage}/>
                                    : <img src={defaultAvatarIcon}/>
                                }
                            </div>
                            <div className="info">
                                <a
                                    className={state.userCollapseState ? "collapsed" : ""}
                                    data-qa="profile"
                                    data-toggle="collapse"
                                    href="#"
                                    onClick={(e) => {
                                        e.preventDefault();
                                        setState({
                                            ...state,
                                            userCollapseState: !state.userCollapseState,
                                        })
                                    }}
                                    aria-expanded={state.userCollapseState}
                                >
                                <span>
                                    {StorageService.getUsername()} <b className="caret"></b>
                                </span>
                                </a>

                                <Collapse in={state.userCollapseState}>
                                    <div>
                                        <Nav as="ul">
                                            {!isSuperAdmin() && <li className="nav-item">
                                                <a
                                                    className="nav-link static-nav-link"
                                                    data-qa="notifications"
                                                    onClick={() => history.push("/" + LAYOUT.WORKSPACE_MEMBER + "/" + state.workspaceInfo.id + "/notifications")}>
                                                    <i>{notificationIcon}</i>
                                                    <p><FormattedMessage id="Sidebar.notifications"/></p>
                                                </a>

                                            </li>
                                            }
                                            {!isSuperAdmin() && <li className="nav-item">
                                                <a
                                                    className="nav-link static-nav-link"
                                                    data-qa="change-password"
                                                    onClick={() => showChangePassword()}>
                                                    <i>{lockIcon}</i>
                                                    <p><FormattedMessage id="Sidebar.changePassword"/></p>
                                                </a>

                                            </li>
                                            }
                                            <li className="nav-item">
                                                <a
                                                    className="nav-link static-nav-link"
                                                    data-qa="logout"
                                                    href="#"
                                                    onClick={event => logout(event)}>

                                                    <i>{powerIcon}</i>
                                                    <p><FormattedMessage id="Sidebar.logout"/></p>
                                                </a>

                                            </li>
                                        </Nav>
                                    </div>
                                </Collapse>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </>
    );
}

export default injectIntl(Sidebar);
