import React from "react";
import {Form} from "react-bootstrap";
import phoneFrame from "assets/img/phone-frame.png";
import FormPreviewField from "./FormPreviewField";

function FormBuilderPreview(props) {
    return (
        <div className={props.isPhoneViewVisible ? "form-builder-inner form-builder-phone-preview" : "form-builder form-builder-desktop-preview"}>
            <div className="form-builder-body"
                 style={props.formSettings?.theme === "#745193" ? {background: "#E3C6FA"} : {background: "unset"}}>
                <div className="col left-col">
                </div>
                <div
                    style={props.isPhoneViewVisible ? {backgroundImage: "url(" + phoneFrame + ")"} : {backgroundImage: "none"}}
                    className="col center-col">
                    <div className="title" style={props.formSettings?.theme === "#745193" ?
                        {borderBottom: "2px solid #745193"} : {borderBottom: "2px solid #000"}}>
                        <Form.Group
                            className="not-editable">
                            <Form.Control
                                type="text"
                                readOnly={true}
                                value={props.formTitle}
                            />
                        </Form.Group>
                    </div>

                    <div className="fields-wrapper full-width">

                        {props.leftAndCenterColFields[1].formFields.map((field, index) => {
                            if(!field.hide){
                                return <FormPreviewField
                                        key={index}
                                        {...field}
                                        formSettings={props.formSettings}
                                        currency={props.currency}
                                    />
                            } else {
                                return <></>
                            }
                        })}
                    </div>

                </div>

                <div className="col right-col">
                </div>

            </div>
        </div>
    );
}

export default FormBuilderPreview;
