import React, {useEffect, useRef, useState} from "react";
import {useHistory} from "react-router-dom";

import RecordsPageTable from "views/workspaceMember/menuDisplay/components/RecordsPageTable.js";
import CrossaPageTemplate from "components/CrossaPageTemplate.js";
import SaveRecordForm from "views/workspaceMember/menuDisplay/components/SaveRecordForm.js";
import {Button, Form, OverlayTrigger, Tab, Tabs, Tooltip,} from "react-bootstrap";
import Moment from 'react-moment';
import {DATE_FORMAT} from "util/DateUtil";
import {FormattedMessage} from "react-intl";
import * as ClientConfig from "../../../config/ClientConfig";
import FormBuilder from "../form/FormBuilder";
import SweetAlert from "react-bootstrap-sweetalert";
import NotificationAlert from "react-notification-alert";
import SlidingPanel from "../../../components/SlidingPanel";
import {
    createPaymentLinkHandler, exportsAllRecordsFilteredInViewHandler,
    findMemberAndWorkspaceRolesHandler,
    findRecordByIdHandler,
    getAllRecordsFilteredInViewHandler,
    getInvoiceHandler,
    getMemberTabFiltersHandler,
    upsertMemberTabFiltersHandler
} from "handlers/workspaceMemberHandlers/menuDisplayHandlers/DisplayMenuHandlers";
import {getDataForMenuHandler} from "handlers/workspaceMemberHandlers/menuDisplayHandlers/DisplayMenuHandlers";
import {deleteRecordHandler} from "handlers/workspaceMemberHandlers/menuDisplayHandlers/DisplayMenuHandlers";
import {insertRecordHandler} from "handlers/workspaceMemberHandlers/menuDisplayHandlers/DisplayMenuHandlers";
import {updateRecordHandler} from "handlers/workspaceMemberHandlers/menuDisplayHandlers/DisplayMenuHandlers";
import {saveFormBuilderHandler} from "handlers/workspaceMemberHandlers/menuDisplayHandlers/DisplayMenuHandlers";
import {getFieldsByViewId} from "handlers/workspaceMemberHandlers/menuDisplayHandlers/DisplayMenuHandlers";
import AdminNavbar from "../../../components/AdminNavbar";
import MenuDisplayTiles from "./components/MenuDisplayTiles";
import {getNotifyErrorOptions, getNotifySuccessOptions} from "util/Util";
import {
    addIconWithoutBg, exportIcon,
    filterIcon,
    iconX,
    iconXthinner, save,
    searchIcon,
    sortDownIcon,
    sortUpIcon
} from "../../../components/Icons";
import RecordFilters from "./components/RecordFilters";
import RecordDetails from "./components/RecordDetails";
import {
    DETAILED_VIEW_MODE, FORM_ACTION,
    FORM_SIZE,
    RECORDS_DISPLAY_MODE,
    RECORD_FIELD_MODE, RECORD_FIELD_TEXT_DISPLAY_OPTION, RECORD_FIELD_TYPE, RECORD_TYPE_TYPE,
    SIDEBAR_STYLE, SORT_DIRECTION, CODE_ERROR
} from "../../../util/Constants";
import currencies from "currency-codes";
import MessageComponent from "./components/MessageComponent";

function DisplayMenuPage(props) {
    const history = useHistory();

    const pageIndex = props.match.params.pid;
    const notificationAlertRef = useRef(null);
    const [state, setState] = useState({
        alert: null,
        formCurrentStyle: {
            insertFormStyle: SIDEBAR_STYLE.CLOSED,
            updateFormStyle: SIDEBAR_STYLE.CLOSED,
            detailsFormStyle:SIDEBAR_STYLE.CLOSED,
            formSize: FORM_SIZE.SMALL
        },
        displayMode: null,
        record: null,
        initRecordTypeFields: [],
        initInsertRecordFields: [],
        workspaceRoles:[],
        showFormBuilder: false,
        formId: null,
        loadedPage: false,
        memberTags: [],
        currentMember:{},
        selectedTab: {
            record: [],
            columns: []
        },
        records: {
            values: [],
        },
        recordTypeFields: [],
        insertRecordFields: [],
        fieldsTypeById: {},
        fieldsOptionsColorsById: {},
        script: null,
        allPages: {
            mid: null,
            pages: []
        },
        showInsertRecordForm: false,
        filterSidebarStyle: SIDEBAR_STYLE.CLOSED,
        sendMessageSidebarStyle: SIDEBAR_STYLE.CLOSED,
        showUpdateRecordForm: false,
        showDetailsRecordForm:false,
        showFilterSidebar: false,
        formField: null,
        setFormLink: null,
        filters: [],

        recordRefsForFilters: [],
        searchValue: "",
        pageable: {},
        chipsExpanded: false,
        showFormPreview:false,
        paymentAvailable:false,
        workspacePaymentSetup:{},
        selectedRecordsForSendMessage:[],
        savedFilters:[],
        selectedSavedFilter:null,
    });
    const [loadedPage, setLoadedPage] = useState(false);
    const [saveDisabled,setSaveDisabled] = useState(false);
    let recordRefsForScript=[];

    const widRef = useRef(null);
    const pidRef = useRef(null);
    const midRef = useRef(null);
    const tabidRef = useRef(null);
    const recordsRef = useRef(null);
    const filtersRef = useRef([]);
    const searchValueRef = useRef("");
    const pageableRef = useRef({});


    useEffect(async () => {
        let newState = {
            ...state
        };

        if (widRef.current !== props.match.params.wid) {
            setLoadedPage(false);
            const responseMember = await findMemberAndWorkspaceRolesHandler(props.match.params.wid);
            if (responseMember.ok) {
                newState = {
                    ...newState,
                    ...responseMember.data
                };
                widRef.current = props.match.params.wid;
            }else{
                // history.push("/404");
            }
        }

        if (pidRef.current !== props.match.params.pid || midRef.current !== props.match.params.mid || tabidRef.current !== props.match.params.tabid) {
            if (tabidRef.current === props.match.params.tabid) {
                setLoadedPage(false);
            }
            let request = {};
            const responseDataForMenu = await getDataForMenuHandler(props.match.params.mid, props.match.params.tabid, pageIndex, newState.allPages.mid, newState.allPages,request);
            if (responseDataForMenu.ok) {
                let formSize;
                let displayMode;
                if (newState.allPages.mid !== props.match.params.mid) {
                    formSize = responseDataForMenu.data.allPages.pages[pageIndex]?.tabs[props.match.params.tabid]?.detailedViewMode === DETAILED_VIEW_MODE.FULL_PAGE? FORM_SIZE.BIG : FORM_SIZE.SMALL;
                    displayMode = responseDataForMenu.data.allPages?.pages[pageIndex]?.tabs[props.match.params.tabid]?.displayMode;
                } else {
                    formSize = newState.allPages.pages[pageIndex]?.tabs[props.match.params.tabid]?.detailedViewMode === DETAILED_VIEW_MODE.FULL_PAGE ? FORM_SIZE.BIG : FORM_SIZE.SMALL;
                    displayMode = newState.allPages?.pages[pageIndex]?.tabs[props.match.params.tabid]?.displayMode;
                }

                newState = {
                    ...newState,
                    ...responseDataForMenu.data,
                    formCurrentStyle: {
                        ...newState.formCurrentStyle,
                        insertFormStyle: SIDEBAR_STYLE.CLOSED,
                        updateFormStyle: SIDEBAR_STYLE.CLOSED,
                        detailsFormStyle: SIDEBAR_STYLE.CLOSED,
                        formSize: formSize,
                    },
                    filterSidebarStyle: SIDEBAR_STYLE.CLOSED,
                    sendMessageSidebarStyle: SIDEBAR_STYLE.CLOSED,
                    filters: [],
                    searchValue: "",
                    record: null,
                    displayMode: displayMode,
                };
                tabidRef.current = props.match.params.tabid;
                pidRef.current = props.match.params.pid;
                midRef.current = props.match.params.mid;
                pageableRef.current={...newState.pageable};
            }
        }
        if (recordsRef.current != newState.records) {
            let columns = prepareColumns(newState.allPages.pages[pageIndex]?.tabs[props.match.params.tabid], newState.allPages, newState.recordTypeFields,newState.pageable,newState.displayMode,newState.paymentAvailable);
            let newRecord = prepareRecords(newState.records, newState.fieldsTypeById, newState.recordTypeFields, newState.memberTags,newState.displayMode,newState.paymentAvailable && newState.allPages.pages[pageIndex]?.tabs[props.match.params.tabid]?.rtType === RECORD_TYPE_TYPE.PAYMENT);
            newState = {
                ...newState,
                selectedTab: {
                    columns: columns,
                    record: newRecord
                },
            };
            recordsRef.current = newState.records;
        }

        if (JSON.stringify(filtersRef.current) !== JSON.stringify(newState.filters) || JSON.stringify(pageableRef.current) !== JSON.stringify(newState.pageable)) {
            let request = {
                "filters": newState.filters,
                "pageable": newState.pageable,
                "searchValue": newState.searchValue
            };
            const response = await getAllRecordsFilteredInViewHandler(newState.allPages.pages[pageIndex]?.tabs[props.match.params.tabid]?.vid, props.match.params.mid, props.match.params.pid, props.match.params.tabid, request,newState.allPages?.pages[pageIndex]?.tabs[props.match.params.tabid]?.enableEditAction);
            newState = {
                ...newState,
                ...response.data
            };
            filtersRef.current = JSON.parse(JSON.stringify(newState.filters));
            pageableRef.current = JSON.parse(JSON.stringify(newState.pageable));
        }

        setState(newState);
        setLoadedPage(true);

    }, [props.match.params.wid, pageIndex, props.match.params.mid, props.match.params.tabid, state.records, state.filters, state.pageable]);

    useEffect(() => {
        let delayDebounceFn = null;
        if (tabidRef.current !== props.match.params.tabid) {
            return () => clearTimeout(delayDebounceFn)
        }

        if ((state.searchValue === "" || state.searchValue?.length > 2) && searchValueRef.current !== state.searchValue) {
            searchValueRef.current = state.searchValue;
            delayDebounceFn = setTimeout(async () => {
                //"pageable": {"page": 0,"size": 3,"sort":["name,DESC"]}
                let request = {"filters": state.filters, "pageable": state.pageable, "searchValue": state.searchValue};

                const response = await getAllRecordsFilteredInViewHandler(state.allPages.pages[pageIndex]?.tabs[props.match.params.tabid]?.vid, props.match.params.mid, props.match.params.pid, props.match.params.tabid, request, state.allPages?.pages[pageIndex]?.tabs[props.match.params.tabid]?.enableEditAction);
                let newState = {
                    ...state,
                    ...response.data
                };
                setState(newState);
            }, 1000)
            return () => clearTimeout(delayDebounceFn)
        }
    }, [state.searchValue,props.match.params.tabid])

    const showRecordForm = async () => {
        let auxRecord = {};
        let [response,_] = await executeScript(true, auxRecord);
        if(state.allPages?.pages[pageIndex]?.tabs[props.match.params.tabid]?.rtType===RECORD_TYPE_TYPE.PAYMENT){
            response.record["paymentProcessor"]=response.insertRecordFields.find(field=>field.id==="paymentProcessor")?.options[0];
        }

        if(response.record)
        setState({
            ...state,
            ...response,
            formCurrentStyle: {
                ...state.formCurrentStyle,
                insertFormStyle: SIDEBAR_STYLE.OPEN,
                updateFormStyle: SIDEBAR_STYLE.CLOSED,
                detailsFormStyle: SIDEBAR_STYLE.CLOSED
            },
            filterSidebarStyle: SIDEBAR_STYLE.CLOSED,
            sendMessageSidebarStyle: SIDEBAR_STYLE.CLOSED,
        });
    }

    const editForm = async (event, formId) => {
        event.preventDefault();
        setState({
            ...state,
            formId: formId,
            showFormBuilder: true
        });
    }

    const handleDeleteRecord = async (event, recordId) => {
        event.preventDefault();
        setState({
            ...state,
            alert: <SweetAlert
                type="danger"
                title=""
                style={{display: "block", marginTop: "-100px", fontSize: "13px"}}
                btnSize="md"
                confirmBtnBsStyle="danger"
                cancelBtnBsStyle="default"
                showCancel
                cancelBtnText={<FormattedMessage id="Button.cancel"/>}
                confirmBtnText={<FormattedMessage id="Button.delete"/>}
                openAnim="false"
                onConfirm={() => onDeleteRecord(recordId)}
                onCancel={() => hideAlert()}
                allowEscape
                closeOnClickOutside
                customClass="crossa-alert"

            > <FormattedMessage id="Alert.deleteRecord" />
            </SweetAlert>
        });
    }

    const hideAlert = () => {
        setState({
            ...state,
            alert: null
        });
    }

    const onDeleteRecord = async (recordId) => {
        let request={
            pageable:{
                sort:state.allPages.pages[pageIndex]?.tabs[props.match.params.tabid].enableDefaultSorting?[state.allPages.pages[pageIndex]?.tabs[props.match.params.tabid].sortingField+","+state.allPages.pages[pageIndex]?.tabs[props.match.params.tabid].sortDirection]:null
            },
            filters: [],
            searchValue: "",
        }
        filtersRef.current=request.filters;
        searchValueRef.current=request.searchValue;
        pageableRef.current=request.pageable;
        deleteRecordHandler(recordId, state.allPages.pages[pageIndex].tabs[props.match.params.tabid].vid,props.match.params.mid, props.match.params.pid, props.match.params.tabid, request,state.allPages.pages[pageIndex]?.tabs[props.match.params.tabid]?.enableEditAction).then(response => {
            if (response.ok) {
                setState({...state, ...response.data, alert: null,...request});
            }
        });
    }

    const handleClickOnName = async (event, recordId) => {
        const selectedRecord = state.records.values.find(record => record.id === recordId);
        let [response,_] = await executeScript(false, selectedRecord);
        setState({
            ...state,
            ...response,
            formCurrentStyle: {
                ...state.formCurrentStyle,
                insertFormStyle: SIDEBAR_STYLE.CLOSED,
                updateFormStyle: SIDEBAR_STYLE.OPEN,
                detailsFormStyle: SIDEBAR_STYLE.CLOSED
            },
            filterSidebarStyle: SIDEBAR_STYLE.CLOSED,
            sendMessageSidebarStyle: SIDEBAR_STYLE.CLOSED,
        });
    }

    const openDetailsSidebar = async (event, recordId) => {
        const selectedRecord = state.records.values.find(record => record.id === recordId);
        setState({
            ...state,
            record: selectedRecord,
            formCurrentStyle: {
                ...state.formCurrentStyle,
                insertFormStyle: SIDEBAR_STYLE.CLOSED,
                updateFormStyle: SIDEBAR_STYLE.CLOSED,
                detailsFormStyle: SIDEBAR_STYLE.OPEN
            },
            filterSidebarStyle: SIDEBAR_STYLE.CLOSED,
            sendMessageSidebarStyle: SIDEBAR_STYLE.CLOSED,
        });
    }
    const showAlert = (message) => {
        setState({
            ...state,
            alert: <SweetAlert
                type="danger"
                title=""
                style={{ display: "block", marginTop: "-100px", fontSize: "13px", zIndex:"99999" }}
                btnSize="md"
                confirmBtnBsStyle="default"
                confirmBtnText={<FormattedMessage id="Button.ok" />}
                openAnim="false"
                onCancel={() => hideAlert()}
                onConfirm={() => hideAlert()}
                allowEscape
                closeOnClickOutside
                customClass="crossa-alert m"
            >  <FormattedMessage id={message}/>
            </SweetAlert>
        });
    }

    const insertRecord = async (record, files) => {
        let request={
            pageable:{
                sort:state.allPages.pages[pageIndex]?.tabs[props.match.params.tabid].enableDefaultSorting?[state.allPages.pages[pageIndex]?.tabs[props.match.params.tabid].sortingField+","+state.allPages.pages[pageIndex]?.tabs[props.match.params.tabid].sortDirection]:null
            },
            filters: [],
            searchValue: "",
        }
        filtersRef.current=request.filters;
        searchValueRef.current=request.searchValue;
        pageableRef.current=request.pageable;

        let filterList={};
        state.insertRecordFields.forEach(fieldInfo => {
            filterList = {
                ...filterList,
                [fieldInfo.id]: fieldInfo.mode
            };
        })

        const newRecord = filterRecord(record, filterList);

        insertRecordHandler(newRecord, files, state.allPages.pages[pageIndex].addActionVid, state.allPages.pages[pageIndex].tabs[props.match.params.tabid].vid,props.match.params.mid, props.match.params.pid, props.match.params.tabid, request,state.allPages.pages[pageIndex]?.tabs[props.match.params.tabid]?.enableEditAction).then(response => {
            if (response.ok) {
                setState({
                    ...state,
                    ...response.data,
                    formCurrentStyle: {
                        ...state.formCurrentStyle,
                        insertFormStyle: SIDEBAR_STYLE.CLOSED,
                        updateFormStyle: SIDEBAR_STYLE.CLOSED
                    },
                   ...request
                });
            } else {
                if(response.errorCode===CODE_ERROR.INVALID_WORKSPACE_USER){
                    showAlert("Validation.memberExists");
                } else {
                    let message = response.data?.message;
                    let options = getNotifyErrorOptions("bc", message);
                    notificationAlertRef.current.notificationAlert(options);
                }
            }
            setSaveDisabled(false);
        });
    }

    const filterRecord = (record, filterList) => {
        let newRecord = {"id": record.id};
        for (const property in record) {
            if (filterList[property] === RECORD_FIELD_MODE.WRITE) {
                newRecord = {
                    ...newRecord,
                    [property]: record[property],
                }
            }
        }
        return newRecord;
    }

    const updateRecord = async (updatedRecord, files) => {
        let filterList = {}
        state.recordTypeFields.forEach(fieldInfo => {
            filterList = {
                ...filterList,
                [fieldInfo.id]: fieldInfo.mode
            };
        })

        const newRecord = filterRecord(updatedRecord, filterList);

        let request={
            pageable:{
                sort:state.allPages.pages[pageIndex]?.tabs[props.match.params.tabid].enableDefaultSorting?[state.allPages.pages[pageIndex]?.tabs[props.match.params.tabid].sortingField+","+state.allPages.pages[pageIndex]?.tabs[props.match.params.tabid].sortDirection]:null
            },
            filters: [],
            searchValue: "",
        }
        filtersRef.current=request.filters;
        searchValueRef.current=request.searchValue;
        pageableRef.current=request.pageable;
        updateRecordHandler(newRecord, state.allPages.pages[pageIndex].tabs[props.match.params.tabid].vid, files,props.match.params.mid, props.match.params.pid, props.match.params.tabid, request,state.allPages.pages[pageIndex]?.tabs[props.match.params.tabid]?.enableEditAction).then(response => {
            if (response.ok) {
                if (state.formCurrentStyle.formSize === FORM_SIZE.BIG) {
                    setState({
                        ...state,
                        ...response.data,
                        ...request
                    });
                    let options = getNotifySuccessOptions("bc");
                    notificationAlertRef.current.notificationAlert(options);
                } else {
                    setState({
                        ...state,
                        ...response.data,
                        record: null,
                        formCurrentStyle: {
                            ...state.formCurrentStyle,
                            insertFormStyle: SIDEBAR_STYLE.CLOSED,
                            updateFormStyle: SIDEBAR_STYLE.CLOSED
                        },
                        ...request
                    });
                }
            } else {
                let message = response.data?.message;
                let options = getNotifyErrorOptions("bc", message);
                notificationAlertRef.current.notificationAlert(options);
            }
            setSaveDisabled(false);
        });
    }

    const closeUpdateDrawer = () => {
        setState({
            ...state,
            formCurrentStyle: {
                ...state.formCurrentStyle,
                insertFormStyle: SIDEBAR_STYLE.CLOSED,
                updateFormStyle: SIDEBAR_STYLE.CLOSED
            },
            record: null
        });
    }

    const closeDetailsDrawer = () => {
        setState({
            ...state,
            formCurrentStyle: {
                ...state.formCurrentStyle,
                insertFormStyle: SIDEBAR_STYLE.CLOSED,
                updateFormStyle: SIDEBAR_STYLE.CLOSED,
                detailsFormStyle: SIDEBAR_STYLE.CLOSED,
            },
            record: null
        });
    }

    const closeInsertDrawer = () => {
        setState({
            ...state,
            formCurrentStyle: {
                ...state.formCurrentStyle,
                insertFormStyle: SIDEBAR_STYLE.CLOSED,
                updateFormStyle: SIDEBAR_STYLE.CLOSED
            },
            record: null
        });
    }


    const prepareRecords = (records, fieldsTypeById, recordTypeFields, memberTags,displayMode,paymentAvailable) => {

        return records.values?.map(record => {
            if (Object.keys(fieldsTypeById).length === 0) {
                return {};
            }

            let paymentButtonAvailable=false;
            let newObject = {}
            let hasTags = false;
            let invalidFields = [];
            for (let property in record) {
                if (property === "registrations") {
                    newObject = {
                        ...newObject,
                        [property]: <a href={"#"}
                                       onClick={(e) => {
                                           e.preventDefault()
                                           history.push("/workspace-member/" + props.match.params.wid + "/forms/" + record.vid)
                                       }}>{record[property]}</a>,
                        "link":
                            <a target="_blank" href="#" onClick={(e) => {e.stopPropagation();window.open(ClientConfig.URL_RESOURCE_SERVER + "api/form/" + props.match.params.wid + "/" + record.id + "/" + record.hash + ".html", "_blank")}}
                                  >Link</a>
                    }
                } else if ((records[property] === RECORD_FIELD_TYPE.RECORD_LIST || records[property] === RECORD_FIELD_TYPE.ROLES_LIST) && record[property] && (!recordTypeFields.find(field=>field.id===property)?.useRecordsAsTags|| displayMode===RECORDS_DISPLAY_MODE.TABLE)) {
                    let nameListString = "";
                    const len = record[property].length;
                    record[property].forEach((recordRef, index) => {
                        nameListString += recordRef.name + (index !== len - 1 ? ", " : "");
                    })
                    newObject = {
                        ...newObject,
                        [property]: nameListString,
                    }
                    if (records[property] === RECORD_FIELD_TYPE.RECORD_LIST) {
                        let recordListField = recordTypeFields.find(field => field.id === property);
                        if (memberTags.find(tag => tag.vid === recordListField.vid && record[property].find(recordRef => recordRef.id === tag.rid))) {
                            hasTags = true;
                        }
                    }
                }  else if ((records[property] === RECORD_FIELD_TYPE.RECORD_LIST)  && displayMode===RECORDS_DISPLAY_MODE.TILES && recordTypeFields.find(field=>field.id===property)?.useRecordsAsTags) {
                    let recordListField = recordTypeFields.find(field => field.id === property);
                    let recordListTags=[];
                    record[property].forEach((recordRef) => {
                        if (memberTags.find(tag => tag.vid === recordListField.vid && recordRef.id === tag.rid)) {
                            recordListTags.push({"name":recordRef.name,"enabledNotification":true});
                            hasTags=true;
                        } else{
                            recordListTags.push({"name":recordRef.name,"enabledNotification":false});
                        }
                    });
                    recordListTags.sort((a, b) => {return (a.enabledNotification === b.enabledNotification)? 0 : a.enabledNotification? -1 : 1});
                    newObject = {
                        ...newObject,
                        [property]: recordListTags,
                    }
                } else if (records[property] === RECORD_FIELD_TYPE.MULTIPLE_CHOICE && record[property]) {
                    let multipleChoiceField = recordTypeFields.find(field => field.id === property);
                    let nameListString = "";
                    const len = record[property].length;
                    record[property].forEach((optionSelected, index) => {
                        nameListString += optionSelected + (index !== len - 1 ? ", " : "");
                    })
                    newObject = {
                        ...newObject,
                        [property]: nameListString,
                    }
                    if (!record[property]?.every(r => multipleChoiceField.options.includes(r)))
                        invalidFields.push(property);
                } else if (records[property] === RECORD_FIELD_TYPE.FILE_LIST && record[property]) {
                    let nameListString = "";
                    const len = record[property].length;
                    record[property].forEach((fileSelected, index) => {
                        nameListString += fileSelected.originalFileName + (index !== len - 1 ? ", " : "");
                    })
                    newObject = {
                        ...newObject,
                        [property]: nameListString,
                    }
                } else if (records[property] === RECORD_FIELD_TYPE.FILE && record[property]) {
                    newObject = {
                        ...newObject,
                        [property]:record[property].originalFileName ,
                    }
                } else if (records[property] === RECORD_FIELD_TYPE.SINGLE_CHOICE && record[property]) {
                    let singleChoiceField = recordTypeFields.find(field => field.id === property);
                    newObject = {
                        ...newObject,
                        [property]: record[property]
                    };

                    if (!singleChoiceField.options.includes(record[property]))
                        invalidFields.push(property);
                } else if (records[property] === RECORD_FIELD_TYPE.RECORD && record[property] && (!recordTypeFields.find(field=>field.id===property)?.useRecordsAsTags||displayMode===RECORDS_DISPLAY_MODE.TABLE)) {
                    let recordField = recordTypeFields.find(field => field.id === property);
                    newObject = {
                        ...newObject,
                        [property]: record[property].name
                    }
                    if (memberTags.find(tag => tag.vid === recordField.vid && record[property].id === tag.rid)) {
                        hasTags = true;
                    }
                } else if (records[property] === RECORD_FIELD_TYPE.RECORD && record[property] && displayMode===RECORDS_DISPLAY_MODE.TILES && recordTypeFields.find(field=>field.id===property)?.useRecordsAsTags) {
                    let recordField = recordTypeFields.find(field => field.id === property);
                    let recordTags=[];
                    if (memberTags.find(tag => tag.vid === recordField.vid && record[property].id === tag.rid)) {
                        recordTags.push({"name":record[property].name,"enabledNotification":true});
                        hasTags=true;
                    } else{
                        recordTags.push({"name":record[property].name,"enabledNotification":false});
                    }
                    newObject = {
                        ...newObject,
                        [property]: recordTags,
                    }
                } else if (records[property] === RECORD_FIELD_TYPE.DATE) {
                    let dateField = recordTypeFields.find(field => field.id === property)
                    newObject = {
                        ...newObject,
                        [property]: record[property]?<Moment
                            format={dateField?.timeField ? DATE_FORMAT.DEFAULT : DATE_FORMAT.DAY_MONTH_YEAR}
                            date={record[property]}
                        />:""
                    }
                } else if (records[property] === RECORD_FIELD_TYPE.BOOLEAN) {
                    newObject = {
                        ...newObject,
                        [property]: record[property].toString()
                    }
                } else if (records[property] === RECORD_FIELD_TYPE.TEXT) {
                    if (recordTypeFields?.find(field => field.id === property && field.displayOption === RECORD_FIELD_TEXT_DISPLAY_OPTION.HYPER_LINK)) {
                        newObject = {
                            ...newObject,
                            [property]: <a href={record[property]} target="_blank">{record[property]}</a>
                        }
                    } else {
                        newObject = {
                            ...newObject,
                            [property]: record[property]
                        }
                    }
                } else if (records[property] === RECORD_FIELD_TYPE.PAYMENT) {
                      paymentButtonAvailable = paymentAvailable && record[property]?.paymentStatus!=="COMPLETE";
                        newObject = {
                            ...newObject,
                            [property]: record[property]
                        }
                } else {
                    newObject = {
                        ...newObject,
                        [property]: record[property]
                    }
                }
            }
            return {
                ...newObject,
                "name": record.name,
                "hasTags": hasTags,
                "invalidFields": invalidFields,
                "paymentAvailable":paymentButtonAvailable
            }
        });
    }

    const exitFormBuilder = (initFormSettings,initFormFields,invalidPayment) => {
      if(invalidPayment && state.formId) {
          saveFormBuilder({...initFormSettings,payment:false},initFormFields)
      } else {
          setState({
              ...state,
              formId: null,
              showFormBuilder: false,
              formCurrentStyle: {
                  ...state.formCurrentStyle,
                  insertFormStyle: state.showInsertRecordForm ? SIDEBAR_STYLE.OPEN : SIDEBAR_STYLE.CLOSED,
                  updateFormStyle: state.showUpdateRecordForm ? SIDEBAR_STYLE.OPEN : SIDEBAR_STYLE.CLOSED
              },
              showInsertRecordForm: false,
              showUpdateRecordForm: false
          });
      }
    }

    const saveFormBuilder = async (formSettings, formFields) => {
        formFields.forEach(formField => {
            delete formField.draggableId;
            delete formField.handleFormFieldClick;
            delete formField.handleFormFieldDelete;
        });

        let record;
        if (state.formId) {
            let filterList = {}
            if (state.showUpdateRecordForm || state.showInsertRecordForm) {
                const response = await getFieldsByViewId(state.formField.vid);
                response.data.forEach(fieldInfo => {
                    filterList = {
                        ...filterList,
                        [fieldInfo.id]: fieldInfo.mode
                    };
                })
            } else {
                state.recordTypeFields.forEach(fieldInfo => {
                    filterList = {
                        ...filterList,
                        [fieldInfo.id]: fieldInfo.mode
                    };
                })
            }
            record = filterRecord(formSettings, filterList);

        } else {
            record = formSettings;
        }
        saveFormBuilderHandler(record, formFields, state.formId, state.allPages.pages[pageIndex].addActionVid,
            state.allPages.pages[pageIndex].tabs[props.match.params.tabid].vid,
            state.showInsertRecordForm || state.showUpdateRecordForm ? state.formField.vid : null).then(response => {
            if (response.ok) {
                let newState = {
                    ...state,
                    ...response.data,
                    formCurrentStyle: {
                        ...state.formCurrentStyle,
                        insertFormStyle: state.showInsertRecordForm ? SIDEBAR_STYLE.OPEN : SIDEBAR_STYLE.CLOSED,
                        updateFormStyle: state.showUpdateRecordForm ? SIDEBAR_STYLE.OPEN : SIDEBAR_STYLE.CLOSED
                    },
                    showInsertRecordForm: false,
                    showUpdateRecordForm: false
                }
                if (newState.setFormLink) {
                    newState.setFormLink.fieldId = state.formField.id;
                    newState.record[state.formField.id] = {
                        "name": newState.setFormLink.formName,
                        "id": newState.setFormLink.formId
                    }
                }
                newState.setFormLink = null;
                setState(newState);
            }
        });
    }

    const prepareColumns = (tab, allPages, recordTypeFields,pageable,displayMode,paymentAvailable) => {
        if(displayMode === RECORDS_DISPLAY_MODE.TILES){
            let columns=[];
            if(tab?.displayFields.includes("name")){
                let recordTypeField=recordTypeFields.find(field=>field.id==="name");
                if(recordTypeField){
                    columns.push({
                        Header: () => (
                            <div>{recordTypeField.name}</div>),
                        accessor: recordTypeField.id,
                    });
                }
            } else{
                for(let i=0; i<tab?.displayFields.length; i++){
                    let recordTypeField=recordTypeFields.find(recordField=>recordField.id===tab.displayFields[i]);
                    if(recordTypeField?.type===RECORD_FIELD_TYPE.TEXT && recordTypeField.id!=="description"){
                        columns.push({
                            Header: () => (
                                <div>{recordTypeField.name}</div>),
                            accessor: recordTypeField.id,
                        });
                        break;
                    }
                }
            }
            if(tab?.displayFields.includes("description")){
                let recordTypeField=recordTypeFields.find(field=>field.id==="description");
                if(recordTypeField){
                    columns.push({
                        Header: () => (
                            <div>{recordTypeField.name}</div>),
                        accessor: recordTypeField.id,
                    });
                }
            } else{
                for(let i=0; i<tab?.displayFields.length; i++){
                    let recordTypeField=recordTypeFields.find(recordField=>recordField.id===tab.displayFields[i]);
                    if(recordTypeField?.type===RECORD_FIELD_TYPE.TEXT &&  !columns.find(col=>col.accessor===recordTypeField.id)){
                        columns.push({
                            Header: () => (
                                <div>{recordTypeField.name}</div>),
                            accessor: recordTypeField.id,
                        });
                        break;
                    }
                }
            }
            if(tab?.displayFields.includes("createdDate")){
                let recordTypeField=recordTypeFields.find(field=>field.id==="createdDate");
                if(recordTypeField){
                    columns.push({
                        Header: () => (
                            <div>{recordTypeField.name}</div>),
                        accessor: recordTypeField.id,
                    });
                }
            }
            columns=[...columns,...recordTypeFields.filter(recordTypeField => (tab?.displayFields.includes(recordTypeField.id) ||
                tab?.displayFields.includes(recordTypeField.name)) && !columns.find(col=>col.accessor===recordTypeField.id)).map(recordTypeField => {
                    return {
                            Header: () => (
                                <div>{recordTypeField.name}</div>),
                            accessor: recordTypeField.id,
                        }
            })];
            columns.push({
                Header: () => (<div></div>),
                accessor: "hasTags"
            });
            if (tab?.rtType === RECORD_TYPE_TYPE.FORM) {
                columns.push({
                    Header: () => (<div><FormattedMessage id="Registrations"/></div>),
                    accessor: "registrations"
                });
                columns.push({
                    Header: () => (<div>Link</div>),
                    accessor: "link"
                });
            }
            columns.push({
                    Header: () => (<div><FormattedMessage id="Actions"/></div>),
                    accessor: "actions",
                    enableEditAction:allPages.pages[pageIndex]?.tabs[props.match.params.tabid]?.enableEditAction
                });
            return columns;
        } else {
            const columns = recordTypeFields.filter(recordTypeField => tab?.displayFields.includes(recordTypeField.id) ||
                tab?.displayFields.includes(recordTypeField.name)).map(recordTypeField => {
                return {
                    Header: () => (
                        <div>{recordTypeField.type===RECORD_FIELD_TYPE.PAYMENT?<FormattedMessage id="PaymentStatus"/>:recordTypeField.name}</div>),
                    accessor: recordTypeField.id,
                    sorted: pageable.sort && pageable.sort[0].split(",")[0] === recordTypeField.id,
                    sortedDesc: pageable.sort && pageable.sort[0].split(",")[0] === recordTypeField.id ? pageable.sort[0].split(",")[1] === "DESC" : null
                }
            });
            columns.splice(1, 0, {
                Header: () => (<div></div>),
                accessor: "hasTags"
            })
            if (tab?.rtType === RECORD_TYPE_TYPE.FORM) {
                columns.push({
                    Header: () => (<div><FormattedMessage id="Registrations"/></div>),
                    accessor: "registrations"
                });
                columns.push({
                    Header: () => (<div>Link</div>),
                    accessor: "link"
                });
            }
            if (allPages.pages[pageIndex]?.tabs[props.match.params.tabid]?.enableEditAction) {
                columns.push({
                    Header: () => (<div><FormattedMessage id="Actions"/></div>),
                    accessor: "actions",
                    sticky:"right"
                });
            }
            if (tab?.rtType === RECORD_TYPE_TYPE.PAYMENT && paymentAvailable) {
                columns.push({
                    Header: () => (<div></div>),
                    accessor: "paymentAction"
                });
            }
            return columns;
        }
    }

    const handleChangeTab = (key) => {
        if (key !== props.match.params.tabid) {
            setState({
                ...state,
                selectedTab: {
                    columns: [],
                    record: []
                },
            });
            history.replace("/workspace-member/" + props.match.params.wid + "/menus/" + props.match.params.mid + "/page/" + pageIndex + "/tab/" + key);
        }
    }

    const executeScript = async (isNew, record) => {
        let newRecordTypeFields;
        if (isNew) {
            newRecordTypeFields = JSON.parse(JSON.stringify(state.initInsertRecordFields));
        } else {
            newRecordTypeFields = JSON.parse(JSON.stringify(state.initRecordTypeFields));
        }

        let recordForScript = {...record};
        let newRecord = {
            ...record,
        };
        const p = {
            "isNew": isNew,
            ...recordForScript
        };
        let newErrors={};
        try {
            const r = eval("var f=function(p){" + state.script + "}; f(p);");
            for (let key in r?.show) {
                newRecordTypeFields.find(field => field.id === key).mode = r.show[key] === true ? RECORD_FIELD_MODE.READ : RECORD_FIELD_MODE.HIDE;
            }
            for (let key in r?.readonly) {
                newRecordTypeFields.find(field => field.id === key).mode = r.readonly[key] === true ? RECORD_FIELD_MODE.READ : RECORD_FIELD_MODE.WRITE;
            }
            for (let key in r?.mandatory) {
                newRecordTypeFields.find(field => field.id === key).validation = {
                    ...newRecordTypeFields.find(field => field.id === key).validation,
                    mandatory: r.mandatory[key]
                };
            }
            for (let key in r?.clear) {
                newRecord[key] = null;
            }

            let recordFields = {};
            if (isNew) {
                recordFields.insertRecordFields = newRecordTypeFields;
            } else {
                recordFields.recordTypeFields = newRecordTypeFields;
            }

            let updatedRecord = {};

            for (let key in r?.set) {
                if (r.set[key]?.field && r.set[key]?.innerField) { //value from record
                    let field=newRecordTypeFields.find(field => field.id === r.set[key].field);
                    if (field?.rtid) {
                        let fieldSet= newRecordTypeFields.find(field => field.id === key);
                        if(record[r.set[key].field] && r.set[key].field !== "modifiedBy" ) {
                            let recordRef=recordRefsForScript.find(ref=>ref.id===record[r.set[key].field].id && ref.rtid===field?.rtid);
                            let price;
                            if(recordRef){
                                if (fieldSet?.type === RECORD_FIELD_TYPE.CURRENCY) {
                                    let currency = fieldSet.currency ? currencies.code(fieldSet.currency) : null;
                                    price = (recordRef[r.set[key].innerField] / Math.pow(10, currency.digits)).toFixed(currency.digits);
                                    updatedRecord[key] = price;
                                } else{
                                    updatedRecord[key] = recordRef[r.set[key].innerField];
                                }
                            } else {
                                let response = await findRecordByIdHandler(record[r.set[key].field].id, field.rtid);
                                if (response.ok) {
                                    if (fieldSet?.type === RECORD_FIELD_TYPE.CURRENCY) {
                                        let currency = field.currency ? currencies.code(field.currency) : null;
                                        price = (response.data[r.set[key].innerField] / Math.pow(10, currency.digits)).toFixed(currency.digits);
                                        updatedRecord[key] = price;
                                    } else
                                        updatedRecord[key] = response.data[r.set[key].innerField];
                                        recordRefsForScript.push({
                                            ...response.data,
                                            "rtid":field.rtid
                                        });
                                }
                            }
                        } else if((r.set[key].field==="createdBy" || r.set[key].field === "modifiedBy") && state.currentMember){
                            updatedRecord[key] = state.currentMember[r.set[key].innerField];
                        }
                    }
                } else {
                    updatedRecord[key] = r.set[key];
                }
                newErrors[key]=false;
            }
            newRecord = {
                ...newRecord,
                ...updatedRecord
            };
            return [{
                record: newRecord,
                ...recordFields
            },newErrors];
        } catch (err) {
            console.log("Error", err);
            return [{record: newRecord,},newErrors];
        }
    }

    const setInitRecordFields = async (initRecordFields) => {
        setState({
            ...state,
            ...initRecordFields
        });
    }

    const createFormLink = (field, action) => {
        setState({
            ...state,
            showFormBuilder: true,
            formCurrentStyle: {
                ...state.formCurrentStyle,
                updateFormStyle: SIDEBAR_STYLE.CLOSED,
                insertFormStyle: SIDEBAR_STYLE.CLOSED
            },
            showInsertRecordForm: action === FORM_ACTION.INSERT,
            showUpdateRecordForm: action === FORM_ACTION.UPDATE,
            formField: field,
            setFormLink: null
        })
    }

    const editFormLink = (field, formId, action) => {
        setState({
            ...state,
            formId: formId,
            showFormBuilder: true,
            formCurrentStyle: {
                ...state.formCurrentStyle,
                updateFormStyle: SIDEBAR_STYLE.CLOSED,
                insertFormStyle: SIDEBAR_STYLE.CLOSED
            },
            showInsertRecordForm: action === FORM_ACTION.INSERT,
            showUpdateRecordForm: action === FORM_ACTION.UPDATE,
            setFormLink: null,
            formField: field,
        })
    }

    const handleOpenFilterSidebar = async () => {
        let newState={...state};
        state.allPages.pages[pageIndex].tabs[props.match.params.tabid]?.vid
        const response = await getMemberTabFiltersHandler(props.match.params.mid,state.allPages.pages[pageIndex].name,state.allPages.pages[pageIndex].tabs[props.match.params.tabid].name);
        if(response.ok){
            newState={
                ...newState,
                savedFilters: response.data.tabFilters,
                tab:response.data
            }
        } else {
            if(response.errorCode===CODE_ERROR.INVALID_MEMBER_TAB_FILTER){
                newState={
                    ...newState,
                    savedFilters: []
                };
            }
        }
        newState={
            ...newState,
            filterSidebarStyle: SIDEBAR_STYLE.OPEN,
            formCurrentStyle: {
                ...state.formCurrentStyle,
                insertFormStyle: SIDEBAR_STYLE.CLOSED,
                updateFormStyle: SIDEBAR_STYLE.CLOSED
            }
        };

        setState(newState);
    }

    const handleCloseFilterSidebar = () => {
        setState({
            ...state,
            filterSidebarStyle: SIDEBAR_STYLE.CLOSED
        });
    }

    const handleApplyFilters = (filters, recordRefsForFilters,selectedSavedFilter) => {
        setState({
            ...state,
            filters: filters,
            recordRefsForFilters: recordRefsForFilters,
            filterSidebarStyle: SIDEBAR_STYLE.CLOSED,
            selectedSavedFilter: selectedSavedFilter,
        });
    }

    const handleRemoveFilters = () => {
        setState({
            ...state,
            filters: [],
            recordRefsForFilters: [],
            filterSidebarStyle: SIDEBAR_STYLE.CLOSED,
            selectedSavedFilter: null,
        });
    }

    const handleChangeSearchInput = (e) => {
        setState({
            ...state,
            searchValue: e.target.value
        });
    }

    const handleDeleteFilter = (fieldId, value, isRecord) => {
        let newFilters = [...state.filters];

        let newRecordRefsForFilters = [...state.recordRefsForFilters];
        newFilters.map(filter => {
            if (filter.fieldId === fieldId) {
                ///date
                if (typeof value === "object") {
                    filter.values = filter.values.map(val => val === value ? null : val);
                } else {
                    filter.values = filter.values.filter(val => val !== value);
                }
            }
        });
        if (isRecord){
            if (newRecordRefsForFilters.find(recordRef => recordRef.fieldId === fieldId && recordRef.record?.value === value)) {
                newRecordRefsForFilters = newRecordRefsForFilters.filter(recordRef => !(recordRef.fieldId === fieldId && recordRef.record?.value === value));
            }
        }
        newFilters = newFilters.filter(f => f.values.length > 0 && f.values.filter(val => val).length > 0);
        setState({
            ...state,
            filters: newFilters,
            recordRefsForFilters: newRecordRefsForFilters,
            selectedSavedFilter: false
        });
    }

    const toggleExpandChipsContainer = () => {
        setState({
            ...state,
            chipsExpanded: !state.chipsExpanded
        })
    }

    const handleShowFormPreview=(e,formId)=>{
        setState({
            ...state,
            showFormPreview: true,
            formId:formId
        });
    }

    const handleExportRecords = () =>{
        let request = {
            "filters": state.filters,
            "pageable": state.pageable,
            "searchValue": state.searchValue
        };
        exportsAllRecordsFilteredInViewHandler(state.allPages.pages[pageIndex]?.tabs[props.match.params.tabid]?.vid,props.match.params.mid,pageIndex,props.match.params.tabid,request).then(response=>{
            if(response.ok){
                let fileName = state.allPages.pages[pageIndex]?.tabs[props.match.params.tabid].name;
                const fileURL = window.URL.createObjectURL(response.data);
                let downloadFile = document.createElement('a');
                downloadFile.href = fileURL;
                downloadFile.download = fileName + ".xlsx";
                document.body.appendChild(downloadFile);

                downloadFile.click();
                downloadFile.remove();
            }
        });
    }

    const showRecordsActions = () => {
        let tab=state.allPages.pages[pageIndex]?.tabs[props.match.params.tabid];
        return <>
            <div className={"search-and-filter-container"}>
                {tab.enableFilters &&
                    <>
                        <Form.Group className={"search-form-group mb-0"}>
                            <span className="search-icon-container">{searchIcon}</span>
                            <Form.Control
                                placeholder={"Search " + tab?.name}
                                type="text"
                                value={state.searchValue}
                                onChange={handleChangeSearchInput}
                            ></Form.Control>
                        </Form.Group>

                        <OverlayTrigger
                            onClick={(e) => e.preventDefault()}
                            overlay={
                                <Tooltip>
                                    <FormattedMessage id="Filters"/>
                                </Tooltip>
                            }
                        >
                            <a className="filter-icon" onClick={() => handleOpenFilterSidebar()}>{filterIcon}</a>
                        </OverlayTrigger>
                    </>
                }
                <div className="ml-auto d-flex">
                    {tab?.enableMessages &&
                        <Button variant="link" onClick={handleOpenSendMessageSidebar}>
                            <span className="icon-cont">
                                {addIconWithoutBg}
                            </span>
                            <FormattedMessage id="Button.newMessage"/>
                        </Button>}
                    {tab?.enableExport &&
                        <Button variant="link" onClick={handleExportRecords}>
                            <span className="icon-cont">
                                {exportIcon}
                            </span>
                        </Button>}
                </div>
            </div>
            {tab.enableFilters && state.filters.length > 0 &&
                <div className={state.chipsExpanded ? "chips-container expanded" : "chips-container"}>
                    {state.filters.map((filter, index) => {
                        return filter.values.map(val => {
                            return !val ? "" :
                                !state.recordRefsForFilters.find(ref => ref.fieldId === filter.fieldId && ref.record?.value==val)?
                                    <span
                                        key={index + Math.random()}
                                        className="chip active">{state.recordTypeFields.find(field => field.id === filter.fieldId)?.name + ": " + (typeof val === "object" ? val?.toISOString().substring(0, 10) : val)}
                                        <span className="delete-icon"
                                              onClick={() => handleDeleteFilter(filter.fieldId, val, false)}>{iconXthinner}</span>
                                    </span> :
                                    <span
                                        key={index + Math.random()}
                                        className="chip active">{state.recordTypeFields.find(field => field.id === filter.fieldId)?.name + ": " + state.recordRefsForFilters.find(ref => ref.fieldId === filter.fieldId && ref.record?.value==val)?.record.label}<span
                                        className="delete-icon"
                                        onClick={() => handleDeleteFilter(filter.fieldId, val, true)}>{iconXthinner}</span></span>
                        })
                    })}
                    <div className="top-right-col">
                        <div className="row">
                            <OverlayTrigger
                                onClick={(e) => e.preventDefault()}
                                overlay={

                                    <Tooltip>
                                        {state.chipsExpanded ? <FormattedMessage id="Collapse"/> :
                                            <FormattedMessage id="Expand"/>}
                                    </Tooltip>
                                }
                            >
                                <span onClick={() => toggleExpandChipsContainer()}
                                      className="action">{state.chipsExpanded ? sortDownIcon : sortUpIcon}</span>
                            </OverlayTrigger>

                            <OverlayTrigger
                                onClick={(e) => e.preventDefault()}
                                overlay={
                                    <Tooltip>
                                        <FormattedMessage id="Button.save"/>
                                    </Tooltip>
                                }
                            >
                             <span
                                 onClick={() => handleSaveFilters()}
                                 className="action">{save}
                             </span>
                            </OverlayTrigger>

                            <OverlayTrigger
                                onClick={(e) => e.preventDefault()}
                                overlay={
                                    <Tooltip>
                                        <FormattedMessage id="DeleteAll"/>
                                    </Tooltip>
                                }
                            >
                             <span
                                 onClick={() => handleRemoveFilters()}
                                 className="action">{iconX}
                       </span>
                            </OverlayTrigger>


                        </div>

                    </div>
                </div>}
        </>
    }

    const showRecordFormOrFormBuilder = () => {
        state.allPages.pages[pageIndex]?.addActionViewType === "Form" ?
            setState({
                ...state,
                showFormBuilder: true
            }) :
            showRecordForm();
    }

    const onChangeSort = (sortBy) => {
        setState({
            ...state,
            pageable: {
                ...state.pageable,
                sort: sortBy.length ? [sortBy[0].id + "," + (sortBy[0].desc ? SORT_DIRECTION.DESC : SORT_DIRECTION.ASC)] : null
            },
            sendMessageSidebarStyle: SIDEBAR_STYLE.CLOSED
        });
    }

    const generatePaymentLink=(id)=>{

        createPaymentLinkHandler(state.allPages.pages[pageIndex]?.tabs[props.match.params.tabid]?.vid,id).then(response=>{
            if(response.ok){
                window.location=response.data;
            }
        });
    }

    const getInvoice=(wid, recordId)=>{
       getInvoiceHandler(wid,recordId).then(response=>{
            if(response.ok){
                let fileName="invoice-"+state.selectedTab.record?.find(record=>record.id===recordId)?.billNr;
                const fileURL = window.URL.createObjectURL(response.data);
                let downloadInvoice = document.createElement('a');
                downloadInvoice.href = fileURL;
                downloadInvoice.download = fileName + ".pdf";
                document.body.appendChild(downloadInvoice);

                downloadInvoice.click();
                downloadInvoice.remove();
            }
        });
    }

    const handleOpenSendMessageSidebar = () => {
        let newColumns=[...state.selectedTab.columns];
        if(newColumns[0]?.accessor!=="selected")
            newColumns=[{
                Header: () => (<div></div>),
                accessor: "selected",
            }, ...newColumns];
        setState({
            ...state,
            sendMessageSidebarStyle: SIDEBAR_STYLE.OPEN,
            selectedTab: {
                ...state.selectedTab,
                columns: newColumns
            }
        });
    }

    const handleCloseSendMessageSidebar = (sendMessageSuccess) => {
        let newColumns=[...state.selectedTab.columns];
        if(newColumns[0]?.accessor==="selected")
            newColumns.shift();
        setState({
            ...state,
            sendMessageSidebarStyle: SIDEBAR_STYLE.CLOSED,
            selectedTab: {
                ...state.selectedTab,
                columns: newColumns
            },
            selectedRecordsForSendMessage: [],
        });
        if(sendMessageSuccess){
            let options = getNotifySuccessOptions("bc",<FormattedMessage id="MessageSent"/>);
            notificationAlertRef.current.notificationAlert(options);
        }
    }

    const handleErrorSendMessage = () => {
        let options = getNotifyErrorOptions("bc",<FormattedMessage id="MessageNotSent"/>);
        notificationAlertRef.current.notificationAlert(options);
    }

    const toggleSelectedRecord=(e,id)=>{
        let newSelectedRecords=[...state.selectedRecordsForSendMessage];
        if(newSelectedRecords.find(idR=>idR===id)){
            newSelectedRecords=newSelectedRecords.filter(idR=>idR!==id);
        } else {
            newSelectedRecords.push(id);
        }
        setState({
            ...state,
            selectedRecordsForSendMessage:newSelectedRecords
        });
    }

    const toggleAllSelectedRecords=(e)=>{
        let newSelectedRecords=[];
        if(e.target.checked) {
            state.selectedTab.record?.map(record => {
                if (!newSelectedRecords.find(idR => idR === record.id)) {
                    newSelectedRecords.push(record.id);
                }
            });
        }
        setState({
            ...state,
            selectedRecordsForSendMessage:newSelectedRecords
        });
    }

    const handleSaveFilters = async () =>{

        const tab={
            ...state.tab,
            tabFilters:[
                ...state.savedFilters,
                {
                    fieldFilters:JSON.parse(JSON.stringify(state.filters))
                }]
        }
        const response = await upsertMemberTabFiltersHandler(props.match.params.mid,state.allPages.pages[pageIndex].name,state.allPages.pages[pageIndex].tabs[props.match.params.tabid].name,tab);
        if(response.ok){
            setState({
                ...state,
                savedFilters: response.data.tabFilters
            });
            let options=getNotifySuccessOptions("bc",<FormattedMessage id="FilterSaved" />);
            notificationAlertRef.current.notificationAlert(options);
        }
    }

    const removeSavedFilter = async (index) =>{
        let newSavedFilter=[...state.savedFilters];
        newSavedFilter.splice(index,1);
        setState({
            ...state,
            savedFilters: newSavedFilter
        });
        const tab={
            ...state.tab,
            tabFilters:newSavedFilter
        }
        const response = await upsertMemberTabFiltersHandler(props.match.params.mid,state.allPages.pages[pageIndex].name,state.allPages.pages[pageIndex].tabs[props.match.params.tabid].name,tab);
        if(response.ok){
            setState({
                ...state,
                tab:response.data,
                savedFilters: response.data.tabFilters
            });
        }
    }

    const selectSavedFilter = (index)=>{
        setState({
            ...state,
            selectedSavedFilter: index,
        });
    }

    return (
        <>
            <div className="rna-container">
                <NotificationAlert zIndex={10000} ref={notificationAlertRef}/>
            </div>
           <div className="highest-zindex"> {state.alert}</div>
            <AdminNavbar
                title={state.allPages.pages[pageIndex]?.name}
                hasRightBtn={state.allPages.pages[pageIndex]?.enableAddAction}
                rightBtnHasIcon={true}
                rightBtnName={state.allPages.pages[pageIndex]?.addActionName}
                rightButtonAction={() => showRecordFormOrFormBuilder()}
            />
            {
                state.allPages.pages.length && loadedPage &&
                <CrossaPageTemplate>
                    {state.allPages.pages[pageIndex]?.tabs?.length > 1 &&
                    <div className="crossa-tabs-container">

                        <Tabs
                            className="crossa-tabs"
                            activeKey={props.match.params.tabid}
                            onSelect={(k) => handleChangeTab(k)}
                        >

                            {

                                state.allPages.pages[pageIndex]?.tabs?.map((tab, index) => {

                                    return (
                                        <Tab
                                            className="crossa-tab-pane"
                                            key={index + tab}
                                            eventKey={index}
                                            title={tab.name}
                                        >
                                            {parseInt(props.match.params.tabid) === index && state.displayMode === RECORDS_DISPLAY_MODE.TABLE &&
                                            <>
                                            {showRecordsActions()}
                                                <RecordsPageTable
                                                    key={index}
                                                    columns={state.selectedTab.columns}
                                                    data={state.selectedTab.record}
                                                    fieldsTypeById={state.fieldsTypeById}
                                                    fieldsOptionsColorsById={state.fieldsOptionsColorsById}
                                                    recordTypeFields={state.recordTypeFields}
                                                    vid={tab.vid}
                                                    deleteRecord={handleDeleteRecord}
                                                    handleClickOnName={handleClickOnName}
                                                    openDetailsSidebar={openDetailsSidebar}
                                                    generatePaymentLink={generatePaymentLink}
                                                    editForm={editForm}
                                                    enableEditAction={tab.enableEditAction}
                                                    rtType={tab.rtType}
                                                    onChangeSort={onChangeSort}
                                                    handleShowFormPreview={handleShowFormPreview}
                                                    toggleSelectedRecord={toggleSelectedRecord}
                                                    toggleAllSelectedRecords={toggleAllSelectedRecords}
                                                    selectedRecords={state.selectedRecordsForSendMessage}
                                                />
                                            </>}

                                            {parseInt(props.match.params.tabid) === index && state.displayMode === RECORDS_DISPLAY_MODE.TILES &&
                                                <>
                                                {showRecordsActions()}
                                                    <MenuDisplayTiles
                                                        recordTypeFields={state.recordTypeFields}
                                                        key={index}
                                                        columns={state.selectedTab.columns}
                                                        data={state.selectedTab.record}
                                                        fieldsTypeById={state.fieldsTypeById}
                                                        fieldsOptionsColorsById={state.fieldsOptionsColorsById}
                                                        vid={tab.vid}
                                                        deleteRecord={handleDeleteRecord}
                                                        handleClickOnName={handleClickOnName}
                                                        openDetailsSidebar={openDetailsSidebar}
                                                        generatePaymentLink={generatePaymentLink}
                                                        editForm={editForm}
                                                        enableEditAction={tab.enableEditAction}
                                                        rtType={tab.rtType}
                                                        handleShowFormPreview={handleShowFormPreview}
                                                    />
                                                </>}
                                        </Tab>
                                    );
                                })
                            }
                        </Tabs>
                    </div>}
                    {
                        state.allPages.pages[pageIndex]?.tabs?.length === 1 && state.allPages.pages[pageIndex]?.tabs?.map((tab, index) => {

                            if (state.displayMode === RECORDS_DISPLAY_MODE.TABLE) {
                                return (
                                    <React.Fragment key={index}>
                                        {showRecordsActions()}
                                        <RecordsPageTable
                                            key={index}
                                            columns={state.selectedTab.columns}
                                            data={state.selectedTab.record}
                                            fieldsTypeById={state.fieldsTypeById}
                                            fieldsOptionsColorsById={state.fieldsOptionsColorsById}
                                            vid={state.allPages.pages[pageIndex].tabs[props.match.params.tabid]?.vid}
                                            recordTypeFields={state.recordTypeFields}
                                            deleteRecord={handleDeleteRecord}
                                            handleClickOnName={handleClickOnName}
                                            openDetailsSidebar={openDetailsSidebar}
                                            generatePaymentLink={generatePaymentLink}
                                            editForm={editForm}
                                            enableEditAction={state.allPages?.pages[pageIndex]?.tabs[props.match.params.tabid]?.enableEditAction}
                                            rtType={state.allPages?.pages[pageIndex]?.tabs[props.match.params.tabid]?.rtType}
                                            onChangeSort={onChangeSort}
                                            handleShowFormPreview={handleShowFormPreview}
                                            toggleSelectedRecord={toggleSelectedRecord}
                                            toggleAllSelectedRecords={toggleAllSelectedRecords}
                                            selectedRecords={state.selectedRecordsForSendMessage}
                                        /></React.Fragment>
                                );
                            } else if (state.displayMode === RECORDS_DISPLAY_MODE.TILES) {
                                return (
                                    <React.Fragment key={index}>
                                        {showRecordsActions()}
                                        <MenuDisplayTiles
                                            recordTypeFields={state.recordTypeFields}
                                            key={index}
                                            columns={state.selectedTab.columns}
                                            data={state.selectedTab.record}
                                            fieldsTypeById={state.fieldsTypeById}
                                            fieldsOptionsColorsById={state.fieldsOptionsColorsById}
                                            vid={state.allPages.pages[pageIndex].tabs[props.match.params.tabid]?.vid}
                                            deleteRecord={handleDeleteRecord}
                                            handleClickOnName={handleClickOnName}
                                            openDetailsSidebar={openDetailsSidebar}
                                            generatePaymentLink={generatePaymentLink}
                                            editForm={editForm}
                                            enableEditAction={state.allPages?.pages[pageIndex]?.tabs[props.match.params.tabid]?.enableEditAction}
                                            rtType={state.allPages?.pages[pageIndex]?.tabs[props.match.params.tabid]?.rtType}
                                            handleShowFormPreview={handleShowFormPreview}
                                        />
                                    </React.Fragment>
                                )
                            } else {
                                return (
                                    <div key={index}><FormattedMessage id="Validation.noRecordsYet"/></div>
                                )
                            }
                        })
                    }
                </CrossaPageTemplate>
            }

            <SlidingPanel
                title={<FormattedMessage id="Button.updateWithParam" values={{param: state.record?.name}}/>}
                currentStyle={state.formCurrentStyle.updateFormStyle + " " + state.formCurrentStyle.formSize}
                hasBackButton={state.formCurrentStyle.formSize===FORM_SIZE.BIG}
                closeSlidingPanel={closeUpdateDrawer}
                content={
                    state.recordTypeFields?.length && state.record &&
                    <SaveRecordForm
                        executeScript={executeScript}
                        action={FORM_ACTION.UPDATE}
                        fieldsUpdateForm={state.recordTypeFields}
                        record={{...state.record}}
                        roles={state.workspaceRoles.map(role => {
                            return {
                                "value": role.id,
                                "label": role.name
                            }
                        })}
                        saveRecord={updateRecord}
                        setInitRecordFields={setInitRecordFields}
                        closeSlidingPanel={closeInsertDrawer}
                        createFormLink={createFormLink}
                        editFormLink={editFormLink}
                        setFormLink={state.setFormLink}
                        wid={props.match.params.wid}
                        notificationAlertRef={notificationAlertRef}
                        vid={state.allPages.pages[pageIndex]?.tabs[props.match.params.tabid]?.vid}
                        enableEditAction={state.allPages?.pages[pageIndex]?.tabs[props.match.params.tabid]?.enableEditAction}
                        mid={props.match.params.mid}
                        pageIndex={pageIndex}
                        tabIndex={props.match.params.tabid}
                        rtType={state.allPages?.pages[pageIndex]?.tabs[props.match.params.tabid]?.rtType}
                        workspacePaymentSetup={state.workspacePaymentSetup}
                        saveDisabled={saveDisabled}
                        setSaveDisabled={setSaveDisabled}
                    />
                }>
            </SlidingPanel>

            <SlidingPanel
                title={<FormattedMessage id="AddData"/>}
                currentStyle={state.formCurrentStyle.insertFormStyle + " " + state.formCurrentStyle.formSize}
                hasBackButton={state.formCurrentStyle.formSize===FORM_SIZE.BIG}
                closeSlidingPanel={closeInsertDrawer}
                content={state.insertRecordFields?.length && state.record &&
                <SaveRecordForm
                    executeScript={executeScript}
                    action={FORM_ACTION.INSERT}
                    fieldsUpdateForm={state.insertRecordFields}
                    record={{...state.record}}
                    roles={state.workspaceRoles.map(role => {
                        return {
                            "value": role.id,
                            "label": role.name
                        }
                    })}
                    saveRecord={insertRecord}
                    setInitRecordFields={setInitRecordFields}
                    closeSlidingPanel={closeInsertDrawer}
                    createFormLink={createFormLink}
                    editFormLink={editFormLink}
                    setFormLink={state.setFormLink}
                    wid={props.match.params.wid}
                    notificationAlertRef={notificationAlertRef}
                    vid={state.allPages.pages[pageIndex]?.tabs[props.match.params.tabid]?.vid}
                    mid={props.match.params.mid}
                    pageIndex={pageIndex}
                    tabIndex={props.match.params.tabid}
                    rtType={state.allPages?.pages[pageIndex]?.tabs[props.match.params.tabid]?.rtType}
                    workspacePaymentSetup={state.workspacePaymentSetup}
                    saveDisabled={saveDisabled}
                    setSaveDisabled={setSaveDisabled}
                />
                }>
            </SlidingPanel>
            <SlidingPanel
                title={state.record?.name}
                hasBackButton={true}
                currentStyle={state.formCurrentStyle.detailsFormStyle + " big"}
                closeSlidingPanel={closeDetailsDrawer}
                content={
                    state.recordTypeFields?.length && state.record &&
                    <RecordDetails
                        fieldsUpdateForm={state.recordTypeFields}
                        record={{...state.record}}
                        closeSlidingPanel={closeDetailsDrawer}
                        wid={props.match.params.wid}
                        vid={state.allPages.pages[pageIndex]?.tabs[props.match.params.tabid]?.vid}
                        mid={props.match.params.mid}
                        pageIndex={pageIndex}
                        tabIndex={props.match.params.tabid}
                        paymentAvailable={state.paymentAvailable}
                        recordType={state.allPages.pages[pageIndex]?.tabs[props.match.params.tabid]?.rtType}
                        getInvoice={getInvoice}
                        generatePaymentLink={generatePaymentLink}
                    />
                }>
            </SlidingPanel>

            <SlidingPanel
                title={<FormattedMessage id="Filters"/>}
                currentStyle={state.filterSidebarStyle + " small"}
                closeSlidingPanel={handleCloseFilterSidebar}
                content={
                    <RecordFilters
                        applyFilters={handleApplyFilters}
                        recordTypeFields={state.recordTypeFields}
                        filters={state.filters}
                        savedFilters={state.savedFilters}
                        handleRemoveFilters={handleRemoveFilters}
                        recordRefsForFilters={state.recordRefsForFilters}
                        mid={props.match.params.mid}
                        pageIndex={pageIndex}
                        tabIndex={props.match.params.tabid}
                        removeSavedFilter={removeSavedFilter}
                        selectedSavedFilter={state.selectedSavedFilter}
                        selectSavedFilter={selectSavedFilter}
                    />
                }>
            </SlidingPanel>

            <SlidingPanel
                title={<FormattedMessage id="SendMessage"/>}
                currentStyle={state.sendMessageSidebarStyle + " small"}
                closeSlidingPanel={handleCloseSendMessageSidebar}
                content={
                    <MessageComponent
                        closeSlidingPanel={handleCloseSendMessageSidebar}
                        handleErrorSendMessage={handleErrorSendMessage}
                        selectedRecords={state.selectedRecordsForSendMessage}
                        emailField={state.allPages.pages[pageIndex]?.tabs[props.match.params.tabid]?.messageEmailField}
                        wid={props.match.params.wid}
                        vid={state.allPages.pages[pageIndex]?.tabs[props.match.params.tabid]?.vid}
                    />
                }>
            </SlidingPanel>

            {state.showFormBuilder &&
            <FormBuilder
                exitFormBuilder={exitFormBuilder}
                saveFormBuilder={saveFormBuilder}
                wid={props.match.params.wid}
                formId={state.formId}
            />}
            {state.showFormPreview &&
                <FormBuilder
                    exitFormBuilder={exitFormBuilder}
                    saveFormBuilder={saveFormBuilder}
                    wid={props.match.params.wid}
                    formId={state.formId}
                    showOnlyPreview={true}
                    exitPreview={()=>setState({...state,showFormPreview:false})}
                />}

        </>
    );
}

export default DisplayMenuPage;
