const addRuleValidations = (rule, errors) => {
    let existErrors=false;
    rule.condition.map((condition,index)=>{
        if(index%2===0){
            if(!condition.selectedCondition){
                errors = {
                    ...errors,
                    "conditions": {
                        ...errors.conditions,
                        [index]: {
                           ...errors.conditions[index],
                            "selectedCondition":true,
                        }
                    },
                }
                existErrors=true;
            } else if (condition.selectedCondition.value!=="IsNew"){
                if(!condition.operand){
                    errors = {
                        ...errors,
                        "conditions": {
                            ...errors.conditions,
                            [index]: {
                                ...errors.conditions[index],
                                "operand":true,
                            }
                        },
                    }
                    existErrors=true;
                } else if(!condition.operatorValue){
                    errors = {
                        ...errors,
                        "conditions": {
                            ...errors.conditions,
                            [index]: {
                                ...errors.conditions[index],
                                "operatorValue":true,
                            }
                        },
                    }
                    existErrors=true;
                } else if(!condition.operator){
                    errors = {
                        ...errors,
                        "conditions": {
                            ...errors.conditions,
                            [index]: {
                                ...errors.conditions[index],
                                "operator":true,
                            }
                        },
                    }
                    existErrors=true;
                } else if(typeof condition.inputValue === undefined || condition.inputValue===""){
                    errors = {
                        ...errors,
                        "conditions": {
                            ...errors.conditions,
                            [index]: {
                                ...errors.conditions[index],
                                "inputValue":true,
                            }
                        },
                    }
                    existErrors=true;
                }
            }
        }
    });

    rule.actions.map((action,index)=>{
            if (!action.selectedAction) {
                errors = {
                    ...errors,
                    "actions": {
                        ...errors.actions,
                        [index]: {
                            ...errors.actions[index],
                            "selectedAction": true,
                        }
                    },
                }
                existErrors = true;
            }
            if (!action.operand) {
                errors = {
                    ...errors,
                    "actions": {
                        ...errors.actions,
                        [index]: {
                            ...errors.actions[index],
                            "operand": true,
                        }
                    },
                }
                existErrors = true;
            } else if (action.selectedAction.value === "SetValue" && !action.operator) {
                errors = {
                    ...errors,
                    "actions": {
                        ...errors.actions,
                        [index]: {
                            ...errors.actions[index],
                            "operator": true,
                        }
                    },
                }
                existErrors = true;
            } else if (action.selectedAction.value === "SetValue" && action.operator === "SetRecordInnerField"){
                if(!action.recordField){
                    errors = {
                        ...errors,
                        "actions": {
                            ...errors.actions,
                            [index]: {
                                ...errors.actions[index],
                                "recordField": true,
                            }
                        },
                    }
                    existErrors = true;
                }else if (action.operator === "SetRecordInnerField" && !action.innerField) {
                    errors = {
                        ...errors,
                        "actions": {
                            ...errors.actions,
                            [index]: {
                                ...errors.actions[index],
                                "innerField": true,
                            }
                        },
                    }
                    existErrors = true;
                }
            } else if(action.selectedAction.value === "SetValue"&&action.operator==="SetParam") {
                if(!action.attributeCurrent) {
                    errors = {
                        ...errors,
                        "actions": {
                            ...errors.actions,
                            [index]: {
                                ...errors.actions[index],
                                "attributeCurrent": true,
                            }
                        },
                    }
                    existErrors = true;
                }
            } else if(action.selectedAction.value === "SetValue"&&!action.inputValue){
                errors = {
                    ...errors,
                    "actions": {
                        ...errors.actions,
                        [index]: {
                            ...errors.actions[index],
                            "inputValue": true,
                        }
                    },
                }
                existErrors = true;
            }
    });
    return [errors, existErrors];
}

export {
    addRuleValidations
}
