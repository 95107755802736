import React from "react";
import {Container, Form, OverlayTrigger, Tooltip} from "react-bootstrap";
import theme1 from "assets/img/theme1.png";
import theme3 from "assets/img/theme3.png";
import DateTimePicker from "react-datetime-picker";
import {FormattedMessage} from "react-intl";


function FormBuilderSettings(props) {
    const handleOnChangeDate = (value, input) => {
        if (input === "validFrom") {
            props.changeFormSettings("startDate", value ? new Date(value) : null);
        } else if (input === "validTo") {
            props.changeFormSettings("endDate", value ? new Date(value) : null);
        }
    }

    const handleOnChange = (e, input) => {
        if (input === "name") {
            props.changeFormSettings("name", e.target.value);
        } else if (input === "description") {
            props.changeFormSettings("description", e.target.value);
        } else if (input === "nrOfSubmissions") {
            props.changeFormSettings("maxNrOfReg", e.target.value);
        } else if (input === "paymentSectionSwitch") {
            props.changeFormSettings("payment", e.target.checked);
        } else if (input === "sendEmailAfterSubmissionSwitch") {
            props.changeFormSettings("sendEmailAfterSubmission",e.target.checked);
        } else if (input === "requireCaptchaSwitch") {
            props.changeFormSettings("captcha", e.target.checked);
        } else if (input === "onlyLoggedUserSwitch") {
            props.changeFormSettings("onlyLoggedUser", e.target.checked);
        }
    }

    const handleOnChangeTheme = theme => {
        props.changeFormSettings("theme", theme);
    }

    return (
        <Container fluid className="form-builder-inner settings-form-builder">
            <div className="form-builder-body">
                <div className="col left-col"></div>
                <div className="col center-col">
                    <div className="title">
                        <p><FormattedMessage id="Form.formSettings"/></p>
                    </div>
                    <div className="form-settings-fields-wrapper">

                        {/* ------------------------NAME-------------------------*/}
                        <Form.Group>
                            <Form.Label><FormattedMessage id="Form.name"/></Form.Label>
                            <Form.Control
                                type="text"
                                value={props.formSettings.name}
                                isInvalid={props.errors.name}
                                onChange={(e) => handleOnChange(e, "name")}
                                className={props.errors.name && "has-errors"}
                            />
                            <Form.Control.Feedback type="invalid">
                                <FormattedMessage id={"Validation.cannotBeEmpty"}/>
                            </Form.Control.Feedback>
                        </Form.Group>
                        {/* ------------------------NAME-------------------------*/}

                        {/* ------------------------DESCRIPTION-------------------------*/}
                        <Form.Group>
                            <Form.Label><FormattedMessage id="Form.description"/></Form.Label>
                            <div className="form-description">
                                <FormattedMessage id="Form.descriptionSettings" />
                            </div>
                            <Form.Control
                                type="text"
                                onChange={(e) => handleOnChange(e, "description")}
                                value={props.formSettings.description}
                                isInvalid={props.errors.description}
                                className={props.errors.description && "has-errors"}
                            />
                            <Form.Control.Feedback type="invalid">
                                <FormattedMessage id={"Validation.cannotBeEmpty"}/>
                            </Form.Control.Feedback>
                        </Form.Group>
                        {/* ------------------------DESCRIPTION-------------------------*/}


                        {/* ------------------------VALID FROM / TO-------------------------*/}
                        <div className="row m-0 inline-inputs date-picker-row">
                            <Form.Group>
                                <Form.Label><FormattedMessage id="Form.validFrom"/> </Form.Label>
                                <DateTimePicker
                                    maxDate={new Date("9999-12-31")}
                                    onChange={(value) => handleOnChangeDate(value, "validFrom")}
                                    format={"y-MM-dd"}
                                    value={props.formSettings.startDate ? new Date(props.formSettings.startDate) : ""}
                                    className={props.errors.startDate? "has-errors" : ""}
                                />
                                {
                                    props.errors.startDate && <div className="error-message">
                                        <FormattedMessage id={"Validation.cannotBeEmpty"}/>
                                    </div>
                                }
                            </Form.Group>
                            <Form.Group>
                                <Form.Label><FormattedMessage id="Form.validTo"/></Form.Label>
                                <DateTimePicker
                                    maxDate={new Date("9999-12-31")}
                                    onChange={(value) => handleOnChangeDate(value, "validTo")}
                                    format={"y-MM-dd"}
                                    value={props.formSettings.endDate ? new Date(props.formSettings.endDate) : ""}
                                    className={props.errors.endDate ? "has-errors" : ""}
                                />
                                {
                                    props.errors.endDate && <div className="error-message">
                                        <FormattedMessage id={"Validation.cannotBeEmpty"}/>
                                    </div>
                                }
                            </Form.Group>
                        </div>
                        {/* ------------------------VALID FROM / TO-------------------------*/}


                        {/* ------------------------NR OF SUBMISSIONS-------------------------*/}
                        <Form.Group>
                            <Form.Label><FormattedMessage id="Form.noSubmissions"/></Form.Label>
                            <div className="form-description">
                                <FormattedMessage id="Form.noSubmissionsDescription"/>
                            </div>
                            <Form.Control
                                type="number"
                                onChange={(e) => handleOnChange(e, "nrOfSubmissions")}
                                value={props.formSettings.maxNrOfReg}
                                isInvalid={props.errors.maxNrOfReg}
                                className={props.errors.maxNrOfReg && "has-errors"}
                            />
                            <Form.Control.Feedback type="invalid">
                                <FormattedMessage id={"Validation.cannotBeEmpty"}/>
                            </Form.Control.Feedback>
                        </Form.Group>
                        {/* ------------------------NR OF SUBMISSIONS-------------------------*/}


                        {/* ------------------------PAYMENT SECTION SWITCH-------------------------*/}


                        {props.currency?.digits && props.merchantId && props.secretApiKey?
                            <Form.Group>
                                <label className="d-flex align-items-center">
                                    <span className="switch-label mr-auto"><FormattedMessage id="Form.paymentSection"/></span>
                                    <Form.Check
                                        onChange={(e) => handleOnChange(e, "paymentSectionSwitch")}
                                        type="switch"
                                        id="crossa-payment-section-switch"
                                        checked={props.formSettings.payment}
                                    />
                                </label>
                            </Form.Group>
                        :
                            <OverlayTrigger

                                onClick={(e) => e.preventDefault()}
                                placement="top-end"
                                overlay={
                                    <Tooltip>
                                        <FormattedMessage id={"Validation.paymentNotCompleted"}/>
                                    </Tooltip>
                                }
                            >
                                <Form.Group className="disabled">
                                    <label className="d-flex align-items-center">
                                        <span className="switch-label mr-auto"><FormattedMessage id="Form.paymentSection"/></span>
                                        <Form.Check
                                            type="switch"
                                            checked={false}
                                            readOnly={true}
                                        />
                                    </label>
                                </Form.Group>
                            </OverlayTrigger>
                        }


                        {/* ------------------------ONLINE PAYMENT SWITCH-------------------------*/}


                        {/* ------------------------SEND EMAIL AFTER SUBMISSION SWITCH-------------------------*/}
                        <Form.Group>
                            <label className="d-flex align-items-center">
                                <span className="switch-label mr-auto"><FormattedMessage id="Form.emailAfterSubmission"/></span>
                                <Form.Check
                                    onChange={(e) => handleOnChange(e, "sendEmailAfterSubmissionSwitch")}
                                    type="switch"
                                    id="crossa-send-email-after-submission-switch"
                                    checked={props.formSettings.sendEmailAfterSubmission}
                                />
                            </label>
                        </Form.Group>
                        {/* ------------------------REQUIRE CAPTCHA SWITCH-------------------------*/}


                        {/* ------------------------SEND EMAIL AFTER SUBMISSION SWITCH-------------------------*/}
                        <Form.Group>
                            <label className="d-flex align-items-center">
                                <span className="switch-label mr-auto"><FormattedMessage id="Form.requireCaptcha"/></span>
                                <Form.Check
                                    onChange={(e) => handleOnChange(e, "requireCaptchaSwitch")}
                                    type="switch"
                                    id="crossa-require-captcha-switch"
                                    checked={props.formSettings.captcha}
                                />
                            </label>
                        </Form.Group>
                        {/* ------------------------REQUIRE CAPTCHA SWITCH-------------------------*/}


                        {/* ------------------------ONLY LOGGED USER SWITCH-------------------------*/}
                        <Form.Group>
                            <label className="d-flex align-items-center">
                                <span className="switch-label mr-auto"><FormattedMessage id="Form.onlyLoggedUsers"/></span>
                                <Form.Check
                                    onChange={(e) => handleOnChange(e, "onlyLoggedUserSwitch")}
                                    type="switch"
                                    id="crossa-only-logged-users-switch"
                                    checked={props.formSettings.onlyLoggedUser}
                                />
                            </label>
                        </Form.Group>
                        {/* ------------------------ONLY LOGGED USER SWITCH-------------------------*/}


                        {/* ------------------------THEMES-------------------------*/}

                        <Form.Group className="full-width">
                            <Form.Label><FormattedMessage id="Form.themes"/></Form.Label>
                            <div className="form-description">
                                <FormattedMessage id="Form.theme"/> <br/> <FormattedMessage id="Form.changesBackground"/>

                            </div>
                            <div className="row m-0">
                                <div onClick={() => handleOnChangeTheme("#ffffff")}
                                     className={props.formSettings.theme === "#ffffff" ? "theme-card theme1 active" : "theme-card theme1"}>
                                    <div className="theme-card-img">
                                        <img src={theme1} alt="Default Theme"/>
                                    </div>
                                    <div className="theme-card-label">
                                        <FormattedMessage id="Form.defaultTheme"/>
                                    </div>
                                </div>

                                <div onClick={() => handleOnChangeTheme("#745193")}
                                     className={props.formSettings.theme === "#745193" ? "theme-card theme3 active" : "theme-card theme3"}>
                                    <div className="theme-card-img">
                                        <img src={theme3} alt="Berry Purple"/>
                                    </div>
                                    <div className="theme-card-label">
                                        Berry Purple
                                    </div>
                                </div>

                                {/*<div*/}
                                {/*    // onClick={() => handleOnChangeTheme("#c2b594")}*/}
                                {/*     className={props.formSettings.theme === "#c2b594" ? "theme-card theme2 active" : "theme-card theme2"}>*/}
                                {/*    <div className="theme-card-img">*/}
                                {/*        <img src={theme2} alt="Quite Khaki"/>*/}
                                {/*    </div>*/}
                                {/*    <div className="theme-card-label">*/}
                                {/*        Quite Khaki*/}
                                {/*    </div>*/}
                                {/*</div>*/}
                            </div>
                        </Form.Group>

                        {/* ------------------------THEMES-------------------------*/}


                    </div>
                </div>
                <div className="col right-col"></div>

            </div>

        </Container>
    );
}

export default FormBuilderSettings;
