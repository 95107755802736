import React, {useEffect, useState} from "react";
import CrossaPageTemplate from "components/CrossaPageTemplate.js";
import CrossaTable from "components/CrossaTable.js"
import SweetAlert from 'react-bootstrap-sweetalert';
import {FormattedMessage} from 'react-intl';
// react-bootstrap components
import {
    getAllWorkspaceRoleHandler
} from "handlers/workspaceAdminHandlers/workspaceRoleHandlers/AllWorkspaceRolesHandlers";
import {
    deleteWorkspaceRoleHandler
} from "handlers/workspaceAdminHandlers/workspaceRoleHandlers/AllWorkspaceRolesHandlers";
import {
    saveWorkspaceRoleHandler
} from "../../../handlers/workspaceAdminHandlers/workspaceRoleHandlers/AllWorkspaceRolesHandlers";
import RoleEditor from "./components/RoleEditor";
import SlidingPanel from "../../../components/SlidingPanel";
import AdminNavbar from "../../../components/AdminNavbar";
import {getNotifySuccessOptions} from "util/Util";
import NotificationAlert from "react-notification-alert";
import {SIDEBAR_STYLE} from "../../../util/Constants";

function AllWorkspaceRoles(props) {

    const [state, setState] = useState({
        workspaceRoles: [],
        alert: null,
        selectedRole: null,
        formCurrentStyle: SIDEBAR_STYLE.CLOSED,
        menuOptions: []
    })

    const [saveDisabled, setSaveDisabled] = useState(false);
    const wid = props.match.params.wid;
    const stateFromProps  = props.location.state || null;


    useEffect(() => {
        getPageContent();
    }, []);


    const getPageContent = () => {
        getAllWorkspaceRoleHandler(wid).then(response => {
            if (response.ok) {
                setState({
                    ...state,
                    workspaceRoles: response.data.workspaceRoles,
                    menuOptions: response.data.menuOptions,
                    formCurrentStyle: stateFromProps!== null && stateFromProps.formCurrentStyle === "open" ? SIDEBAR_STYLE.OPEN : SIDEBAR_STYLE.CLOSED
                })
            }
        })
    }

    const deleteAlert = (id) => {
        setState({
            ...state,
            alert: <SweetAlert
                type="danger"
                title=""
                style={{display: "block", marginTop: "-100px", fontSize: "13px"}}
                btnSize="md"
                confirmBtnBsStyle="danger"
                cancelBtnBsStyle="default"
                showCancel
                cancelBtnText={<FormattedMessage id="Button.cancel"/>}
                confirmBtnText={<FormattedMessage id="Button.delete"/>}
                openAnim="false"
                onConfirm={() => onDeleteWorkspaceRole(id)}
                onCancel={() => hideAlert()}
                allowEscape
                closeOnClickOutside
                customClass="crossa-alert"

            > <FormattedMessage id="Alert.deleteRole" />
            </SweetAlert>
        });
    }

    const hideAlert = () => {
        setState({
            ...state,
            alert: null
        });
    }

    const onDeleteWorkspaceRole = async id => {
        deleteWorkspaceRoleHandler(id, wid).then(response => {
            if (response.ok) {
                setState({
                    ...state,
                    ...response.data,
                    alert: null
                });
            }
        })
    }

    const saveWorkspaceRole = async (roleId, finalRole, finalRoleOnUpdatePage) => {
        saveWorkspaceRoleHandler(roleId, finalRoleOnUpdatePage, finalRole, wid).then(response => {
            if (response.ok) {
                if(roleId){
                    let options=getNotifySuccessOptions("bc");
                    notificationAlertRef.current.notificationAlert(options);
                    setState({
                        ...state,
                        workspaceRoles: response.data.workspaceRoles,
                        menuOptions: response.data.menuOptions,
                    });
                } else {
                    setState({
                        ...state,
                        workspaceRoles: response.data.workspaceRoles,
                        menuOptions: response.data.menuOptions,
                        selectedRole: null,
                        formCurrentStyle: SIDEBAR_STYLE.CLOSED
                    });
                }
                setSaveDisabled(false);
            }
        })
    }


    const closeSlidingPanel = () => {
        setState({
            ...state,
            selectedRole: null,
            formCurrentStyle: SIDEBAR_STYLE.CLOSED
        })
    }

    const editRole=(id)=>{
        setState({
            ...state,
            selectedRole: state.workspaceRoles.find(role=>role.id===id),
            formCurrentStyle: SIDEBAR_STYLE.OPEN
        });
    }

    const createRow = (prop,index) => {
        return {
            index:index+1,
            id:prop.id,
            name: prop.name,
            description: prop.description,
            menu: prop.menuName,
        };
    }



    const columns = [
        {
            Header: () => (
                <div>#</div>),
            accessor: "index",
        },
        {
            Header: () => (
                <div><FormattedMessage id="Name"/></div>),
            accessor: "name",
        },
        {
            Header: () => (
                <div><FormattedMessage id="Description"/></div>),
            accessor: "description",
        },
        {
            Header: () => (
                <div><FormattedMessage id="Menu"/></div>),
            accessor: "menu",
        },
        {
            Header: () => (
                <div><FormattedMessage id="Actions"/></div>),
            accessor: "actions",
            sticky:"right"
        },
    ];
    const notificationAlertRef = React.useRef(null);

    return (
        <>
            <div className="rna-container">
                <NotificationAlert ref={notificationAlertRef} />
            </div>
            {state.alert}
            <AdminNavbar
                title={<FormattedMessage id="Roles"/>}
                hasRightBtn={true}
                rightBtnHasIcon={true}
                rightBtnName={<FormattedMessage id="Button.addRole" />}
                rightButtonAction={() => setState({
                    ...state,
                    selectedRole: null,
                    formCurrentStyle: SIDEBAR_STYLE.OPEN
                })}
            />
        <CrossaPageTemplate>
            <CrossaTable
                columns={columns}
                data={state.workspaceRoles.map((element,index) => createRow(element,index))}
                handleEdit={editRole}
                handleDelete={deleteAlert}
            />
        </CrossaPageTemplate>

        <SlidingPanel
            title={state.selectedRole? (<FormattedMessage id="EditRole"/>): (<FormattedMessage id="NewRole"/>)}
            currentStyle={state.formCurrentStyle}
            closeSlidingPanel={closeSlidingPanel}
            content={
                <div className="role-editor-form">
                    <RoleEditor
                        hasFooter={true}
                        closeSlidingPanel={closeSlidingPanel}
                        saveWorkspaceRole={saveWorkspaceRole}
                        role={state.selectedRole}
                        wid={wid}
                        menuOptions={state.menuOptions}
                        notificationAlertRef={notificationAlertRef}
                        stateFromProps={stateFromProps}
                        saveDisabled={saveDisabled}
                        setSaveDisabled={setSaveDisabled}
                    />
                </div>
            }>
        </SlidingPanel>
        </>

    );
}

export default AllWorkspaceRoles;
