import React from "react";

const rulesToQuery = rules => {
    if(rules.length === 0) {
        return null;
    }
    let currentGroup = [];
    let allGroups = [];
    let i = 0;
    while(i < rules.length) {
        currentGroup.push(ruleToQueryFormat(rules[i]))
        if(rules.length === i+1) {
            allGroups.push(currentGroup);
            break;
        }
        if(rules[i+1].operator === "Or") {
            allGroups.push(currentGroup);
            currentGroup = [];
        }
        i = i + 2;
    }

    if(allGroups.length === 1) {
        return {
            "type": "And",
            "operands": allGroups[0]
        }
    } else {
        return {
            "type": "Or",
            "operands": allGroups.map(group => {
                if(group.length > 1) {
                    return {
                        "type": "And",
                        "operands": group
                    }
                } else {
                    return group[0];
                }
            })
        }
    }
}

const dbFieldTypeToRuleType = fieldType => {
    switch (fieldType) {
        case "Date":
            return "date";
        case "Decimal":
            return "decimal"
        case "Integer":
            return "integer"
        case "Currency":
            return "currency";
        case "Record":
            return "record"
        case "Boolean":
            return "boolean"
        case "Image":
            return "image"
        case "SingleChoice":
            return "singlechoice"
        case "MultipleChoice":
            return "multiplechoice"
        case "RecordList":
            return "recordlist"
        case "RolesList":
            return "roleslist"
        default:
            return "text";
    }
}
const ruleToQueryFormat = rule => {
    let result={};
    if (rule.operator.charAt(0).toUpperCase() + rule.operator.slice(1) === "EqParamDate" ||
        rule.operator.charAt(0).toUpperCase() + rule.operator.slice(1) === "NeParamDate" ||
        rule.operator.charAt(0).toUpperCase() + rule.operator.slice(1) === "GtParamDate" ||
        rule.operator.charAt(0).toUpperCase() + rule.operator.slice(1) === "GteParamDate" ||
        rule.operator.charAt(0).toUpperCase() + rule.operator.slice(1) === "LtParamDate" ||
        rule.operator.charAt(0).toUpperCase() + rule.operator.slice(1) === "LteParamDate"){
        rule.operator=rule.operator.slice(0,-4);
    }
     if (rule.operator.charAt(0).toUpperCase() + rule.operator.slice(1) === "EqDate" ||rule.operator.charAt(0).toUpperCase() + rule.operator.slice(1) === "EqParamDate") {
         result={
             ...result,
             timezone:Intl.DateTimeFormat().resolvedOptions().timeZone
         }
    }
    if (rule.operator.charAt(0).toUpperCase() + rule.operator.slice(1) === "BetweenLong" ||
        rule.operator.charAt(0).toUpperCase() + rule.operator.slice(1) === "BetweenDouble" ||
        rule.operator.charAt(0).toUpperCase() + rule.operator.slice(1) === "BetweenString" ||
        rule.operator.charAt(0).toUpperCase() + rule.operator.slice(1) === "BetweenDate") {
        result ={
            ...result,
            "type": rule.operator.charAt(0).toUpperCase() + rule.operator.slice(1),
            "name": rule.operand.value,
            "beginValue": rule.type === "date" ? new Date(rule.select3).toISOString() : rule.select3,
            "endValue": rule.type === "date" ? new Date(rule.select4).toISOString() : rule.select4,
            "eqRecord": rule.eqRecord
        }
    } else if (rule.operator.charAt(0).toUpperCase() + rule.operator.slice(1) === "EqParam"||
        rule.operator.charAt(0).toUpperCase() + rule.operator.slice(1) === "NeParam"||
        rule.operator.charAt(0).toUpperCase() + rule.operator.slice(1) === "GtParam" ||
        rule.operator.charAt(0).toUpperCase() + rule.operator.slice(1) === "GteParam" ||
        rule.operator.charAt(0).toUpperCase() + rule.operator.slice(1) === "LtParam" ||
        rule.operator.charAt(0).toUpperCase() + rule.operator.slice(1) === "LteParam") {
        result= {
            ...result,
            "type": rule.operator.charAt(0).toUpperCase() + rule.operator.slice(1),
            "name": rule.operand.value,
            "value": rule.select3,
            "eqRecord": rule.eqRecord
        }
    } else {
        result= {
            ...result,
            "type": rule.operator.charAt(0).toUpperCase() + rule.operator.slice(1),
            "name": rule.operand.value,
            "value": rule.type === "date" ? new Date(rule.select3).toISOString() : rule.select3,
            "eqRecord": rule.eqRecord
        }
    }
    return result;
}
const conditionsToQuery = conditions => {
    if (conditions.length === 0) {
        return null;
    }

    let currentGroup = [];
    let allGroups = [];
    let i = 0;
    while(i < conditions.length) {
        currentGroup.push(conditionToQueryFormat(conditions[i]))
        if(conditions.length === i+1) {
            allGroups.push(currentGroup);
            break;
        }
        if(conditions[i+1].operator === "Or") {
            allGroups.push(currentGroup);
            currentGroup = [];
        }
        i = i + 2;
    }

    if(allGroups.length === 1) {
        return {
            "type": "And",
            "operands": allGroups[0]
        }
    } else {
        return {
            "type": "Or",
            "operands": allGroups.map(group => {
                if(group.length > 1) {
                    return {
                        "type": "And",
                        "operands": group
                    }
                } else {
                    return group[0];
                }
            })
        }
    }
}

const conditionToQueryFormat = condition => {
    if (condition.operator.charAt(0).toUpperCase() + condition.operator.slice(1) === "GtParam" ||
        condition.operator.charAt(0).toUpperCase() + condition.operator.slice(1) === "GteParam" ||
        condition.operator.charAt(0).toUpperCase() + condition.operator.slice(1) === "LtParam" ||
        condition.operator.charAt(0).toUpperCase() + condition.operator.slice(1) === "LteParam") {
        return {
            "type": condition.operator.charAt(0).toUpperCase() + condition.operator.slice(1),
            "name": condition.operand.value,
            "value": condition.select3,
            "eqRecord": condition.eqRecord
        }
    } else if (condition.operator.charAt(0).toUpperCase() + condition.operator.slice(1)=== "IsNew") {
        return {
            "type":  condition.operator.charAt(0).toUpperCase() + condition.operator.slice(1),
            "value": true
        }
    } else {
        return {
            "type": condition.operator.charAt(0).toUpperCase() + condition.operator.slice(1),
            "name": condition.operand?.value,
            "value": condition.type === "date" && (condition.operator.charAt(0).toUpperCase() + condition.operator.slice(1) !== "IsEmptyDate") ? new Date(condition.inputValue).toISOString() : condition.inputValue,
            "eqRecord": condition.eqRecord
        }
    }
}

const actionsToQuery = actions => {
    if (actions.length > 0) {
        let actionsQuery = [];
        actions.map(action => {
            actionsQuery.push(actionToQueryFormat(action));
        })
        return actionsQuery;
    } else {
        return null;
    }
}

const actionToQueryFormat = action => {
    if (action.operator.charAt(0).toUpperCase() + action.operator.slice(1) === "Show" ||
        action.operator.charAt(0).toUpperCase() + action.operator.slice(1) === "Hide" ||
        action.operator.charAt(0).toUpperCase() + action.operator.slice(1) === "Clear" ||
        action.operator.charAt(0).toUpperCase() + action.operator.slice(1) === "RemoveMandatory" ||
        action.operator.charAt(0).toUpperCase() + action.operator.slice(1) === "MakeReadonly" ||
        action.operator.charAt(0).toUpperCase() + action.operator.slice(1) === "MakeMandatory" ||
        action.operator.charAt(0).toUpperCase() + action.operator.slice(1) === "MakeEditable") {
        return {
            "type": action.operator.charAt(0).toUpperCase() + action.operator.slice(1),
            "name": action.operand.value,
        }
    } else if (action.operator.charAt(0).toUpperCase() + action.operator.slice(1) === "SetParam") {
        return {
            "type": action.operator.charAt(0).toUpperCase() + action.operator.slice(1),
            "name": action.operand?.value,
            "value": action.attributeCurrent
        }
    } else if (action.operator.charAt(0).toUpperCase() + action.operator.slice(1) === "SetRecordInnerField") {
        return {
            "type": action.operator.charAt(0).toUpperCase() + action.operator.slice(1),
            "name": action.operand?.value,
            "value": {
                field: action.recordField,
                innerField: action.innerField,
            }
        }
    } else {
        return {
            "type": action.operator.charAt(0).toUpperCase() + action.operator.slice(1),
            "name": action.operand?.value,
            "value": action.type === "date" ? new Date(action.inputValue).toISOString() : action.inputValue,
        }
    }
}

const queryToRules = (operands, fieldsMap, operator, hasOrOperator, firstOperand) => {
    const rules = [];
    for (let i = 0; i < operands.length; ++i) {
        if(typeof(operands[i].name) !== "undefined" && typeof(fieldsMap[operands[i].name]) === "undefined"){
            console.error("queryToConditions field "+operands[i].name+" is missing in record type", operands[i]);
            continue;
        }
        if (operands[i].type === "BetweenLong" || operands[i].type === "BetweenDouble" ||
            operands[i].type === "BetweenString" || operands[i].type === "BetweenDate") {
            rules.push({
                "operator": operands[i].type,
                "operatorValue":operands[i].type,
                "select3": fieldsMap[operands[i].name].type === "Date" ? operands[i].beginValue.split('T')[0] : operands[i].beginValue,
                "select4": fieldsMap[operands[i].name].type === "Date" ? operands[i].endValue.split('T')[0] : operands[i].endValue,
                "operand": {
                    "label": fieldsMap[operands[i].name].name,
                    "value": operands[i].name
                },
                "eqRecord": operands[i].eqRecord,
                "type": dbFieldTypeToRuleType(fieldsMap[operands[i].name].type)
            });
        } else if (operands[i].type === "EqParam"||operands[i].type === "NeParam" || operands[i].type === "GtParam" || operands[i].type === "GteParam" ||
            operands[i].type === "LtParam" || operands[i].type === "LteParam") {
            if(operands[i].value==="currentDate"){
                operands[i].type=operands[i].type+"Date";
            }
            rules.push({
                "operator": operands[i].type,
                "operatorValue":operands[i].type,
                "select3": operands[i].value,
                "operand": {
                    "label": fieldsMap[operands[i].name].name,
                    "value": operands[i].name
                },
                "eqRecord": operands[i].eqRecord,
                "type": dbFieldTypeToRuleType(fieldsMap[operands[i].name].type)
            });
        } else if (operands[i].type === "And" || operands[i].type === "Or") {
            rules.push(...queryToRules(operands[i].operands, fieldsMap, operands[i].type, hasOrOperator, false))
        } else {
            rules.push({
                "operator": operands[i].type,
                "operatorValue":operands[i].type,
                "select3": fieldsMap[operands[i].name].type === "Date" ? operands[i].value.split('T')[0] : operands[i].value,
                "operand": {
                    "label": fieldsMap[operands[i].name].name,
                    "value": operands[i].name
                },
                "eqRecord": operands[i].eqRecord,
                "type": dbFieldTypeToRuleType(fieldsMap[operands[i].name].type)
            });
        }

        if(i+1 !== operands.length) {
            if(firstOperand && hasOrOperator) {
                rules.push({
                    "operator": "Or"
                });
            } else {
                rules.push({
                    "operator": operator
                });
            }
        }
    }
    return rules;
}

const queryToConditions = (operands, fieldsMap, operator, hasOrOperator, firstOperand) => {
    const conditions = [];
    for (let i = 0; i < operands.length; ++i) {
        if(typeof(operands[i].name) !== "undefined" && typeof(fieldsMap[operands[i].name]) === "undefined"){
            console.error("queryToConditions field "+operands[i].name+" is missing in record type", operands[i]);
            continue;
        }
        if (operands[i].type === "GtParam" || operands[i].type === "GteParam" ||
            operands[i].type === "LtParam" || operands[i].type === "LteParam") {
            conditions.push({
                "operator": operands[i].type,
                "operatorValue":operands[i].type,
                "select3": operands[i].value,
                "operand": {
                    "label": fieldsMap[operands[i].name].name,
                    "value": operands[i].name
                },
                "eqRecord": operands[i].eqRecord,
                "type": dbFieldTypeToRuleType(fieldsMap[operands[i].name].type),
                "selectedCondition": {
                    "value": "IsChanged",
                }
            });
        } else if (operands[i].type === "IsNew") {
            conditions.push({
                "operator": operands[i].type,
                "operatorValue":operands[i].type,
                "inputValue": operands[i].value,
                "type": "boolean",
                "selectedCondition": {
                    "value": operands[i].type,
                }

            })
        } else if (operands[i].type === "And" || operands[i].type === "Or") {
            conditions.push(...queryToConditions(operands[i].operands, fieldsMap, operands[i].type,hasOrOperator,false))
        } else {
            conditions.push({
                "operator": operands[i].type,
                "operatorValue":operands[i].type,
                "inputValue": fieldsMap[operands[i].name]?.type === "Date" && operands[i].type!=="IsEmptyDate" ? operands[i].value.split('T')[0] : operands[i].value,
                "operand": {
                    "label": fieldsMap[operands[i].name].name,
                    "value": operands[i].name
                },
                "eqRecord": operands[i].eqRecord,
                "type": dbFieldTypeToRuleType(fieldsMap[operands[i].name].type),
                "selectedCondition": {
                    "value": "IsChanged",
                }
            });
        }
        if(i+1 !== operands.length) {
            if(firstOperand && hasOrOperator) {
                conditions.push({
                    "operator": "Or"
                });
            } else {
                conditions.push({
                    "operator": operator
                });
            }
        }
    }
    return conditions;
}

const queryToActions = (operands, fieldsMap, operator) => {
    const actions = [];
    for (let i = 0; i < operands.length; ++i) {
        if(typeof(operands[i].name) !== "undefined" && typeof(fieldsMap[operands[i].name]) === "undefined"){
            console.error("queryToActions field "+operands[i].name+" is missing in record type", operands[i]);
            continue;
        }
        if (operands[i].type === "Hide" || operands[i].type === "Show" ||
            operands[i].type === "Clear" || operands[i].type === "RemoveMandatory" ||
            operands[i].type === "MakeReadonly" || operands[i].type === "MakeMandatory" ||
            operands[i].type === "MakeEditable") {
            actions.push({
                "operator": operands[i].type,
                "operand": {
                    "label": fieldsMap[operands[i].name].name,
                    "value": operands[i].name
                },
                "selectedAction": {
                    "value": operands[i].type,
                }
            });
        } else if (operands[i].type === "SetRecordInnerField") {
            actions.push({
                "operator": operands[i].type,
                "operand": {
                    "label": fieldsMap[operands[i].name].name,
                    "value": operands[i].name
                },
                "recordField": operands[i].value?.field,
                "innerField": operands[i].value?.innerField,
                "type": dbFieldTypeToRuleType(fieldsMap[operands[i].name].type),
                "selectedAction": {
                    "value": "SetValue"
                }
            });
        } else if (operands[i].type === "SetParam") {
            actions.push({
                "operator": operands[i].type,
                "attributeCurrent": fieldsMap[operands[i].name]?.type === "Date" ? operands[i].value.split('T')[0] : operands[i].value,
                "operand": {
                    "label": fieldsMap[operands[i].name].name,
                    "value": operands[i].name
                },
                "type": dbFieldTypeToRuleType(fieldsMap[operands[i].name].type),
                "selectedAction": {
                    "value": "SetValue",
                }
            });
        } else {
            actions.push({
                "operator": operands[i].type,
                "inputValue": fieldsMap[operands[i].name]?.type === "Date" ? operands[i].value.split('T')[0] : operands[i].value,
                "operand": {
                    "label": fieldsMap[operands[i].name].name,
                    "value": operands[i].name
                },
                "type": dbFieldTypeToRuleType(fieldsMap[operands[i].name].type),
                "selectedAction": {
                    "value": "SetValue",
                }
            });
        }
    }
    return actions;
}
export {
    rulesToQuery,
    queryToRules,
    conditionsToQuery,
    actionsToQuery,
    queryToConditions,
    queryToActions
}