import * as WorkspaceService from "service/WorkspaceService";
import * as RecordTypeService from "service/RecordTypeService";
import * as ViewService from "service/ViewsService";
const getRecordsTypeAndViewsHandler=async(wid)=>{
    let workspace;
    let treeItems=[];

    const workspaceResponse = await WorkspaceService.findUserWorkspaces();

    if(workspaceResponse.ok) {
        workspace = workspaceResponse.data.find(w => w.id === parseInt(wid));
    }
    const response = await RecordTypeService.findAllRecordTypesAndViews(wid);
    if (response.ok) {
        treeItems=response.data.map(item => {
            return {
                ...item,
                "views": item.views.map(view => {
                    return {
                        ...view,
                        "type": item.type,
                        "delete": workspace && view.id !== workspace.memberVid && view.id !== workspace.formVid,
                        "selected": false,
                    }
                }),
                "selected": false,
            }
        });
    }

    return{
        data:{
            treeItems:treeItems,
        },
        ok:workspaceResponse.ok&&response.ok
    }
}

const saveViewHandler=async(newView)=>{
    let response=null;
    let id;
    if(newView.id===-1){
        delete newView.id;
        response = await ViewService.addView(newView);
        id=response.data.id;
    } else{
        response = await ViewService.updateView(newView);
    }

    return {
        ok:response.ok,
        data:{
            id:id
        }
    };
}

const deleteViewHandler=async(id)=>{
    const response = await ViewService.deleteView(id);
    return{
        ok:response.ok
    }
}

export {
    getRecordsTypeAndViewsHandler,
    saveViewHandler,
    deleteViewHandler

}
