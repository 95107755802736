import {Button, Card, Col, Nav, Row, Tab} from "react-bootstrap";
import {FormattedMessage} from "react-intl";
import {addIconFullBg} from "../../../../components/Icons";
import {DragDropContext, Draggable, Droppable} from "react-beautiful-dnd";
import {checkIfError} from "../../../../validations/validations";
import React from "react";
import {useWidgets, useWidgetsUpdate} from "../context/WidgetsContext";
import {WIDGET_TYPE} from "../../../../util/Constants";
import WidgetContent from "./WidgetContent";
import {widgetTypeOptions} from "../utils/MenuConstants";


function WidgetsComponent(props) {

    const widgets = useWidgets();
    const changeWidgets = useWidgetsUpdate();

    const handleAddNewWidget=()=> {
        changeWidgets([...widgets, {
            "type":WIDGET_TYPE.WELCOME,
            "visible":true,
        }],{},widgets.length);
    }

    const onDragWidget=(result)=>{
        if(!result.destination){
            return;
        }

        const newData = Array.from(widgets);
        const [removed] = newData.splice(result.source.index, 1);
        newData.splice(result.destination.index, 0, removed);

        let newErrors={...props.errors};
        let newWidgetErrors= newErrors.dashboard?.widgets;

        if(newWidgetErrors && result.source.index<result.destination.index) {
            let widgetErrorsSourceIndex=newWidgetErrors[result.source.index];
            for (let index = result.source.index; index <result.destination.index; index++){
                newWidgetErrors[index]=newWidgetErrors[index+1];
            }
            newWidgetErrors[result.destination.index]=widgetErrorsSourceIndex;
        }

        if(newWidgetErrors &&  result.source.index>result.destination.index) {
            let widgetErrorsSourceIndex=newWidgetErrors[result.source.index];
            for (let index = result.source.index; index >result.destination.index; index--){
                newWidgetErrors[index]=newWidgetErrors[index-1];
            }
            newWidgetErrors[result.destination.index]=widgetErrorsSourceIndex;
        }

        newErrors={
            ...newErrors,
            dashboard: {
                ...newErrors.dashboard,
                widgets: newWidgetErrors
            }
        };

        let newCurrentWidgetIndex=props.widgetIndex;
        if(result.source.index===props.widgetIndex){
            newCurrentWidgetIndex=result.destination.index;
        } else if(result.source.index<newCurrentWidgetIndex && newCurrentWidgetIndex<=result.destination.index){
            newCurrentWidgetIndex--;
        } else if(result.destination.index<=newCurrentWidgetIndex && newCurrentWidgetIndex<result.source.index){
            newCurrentWidgetIndex++;
        }

        changeWidgets(newData,newErrors,newCurrentWidgetIndex);
    }

    return  (
        <div className="tab-component">
        <Row>
            <Col md={12}>
                <Card.Header className="row m-0">
                    <Card.Title as="h3">
                        <FormattedMessage id="Widgets"/>
                    </Card.Title>
                    <Button className="ml-auto"
                            onClick={handleAddNewWidget}
                    >
                        <span className="icon">
                            {addIconFullBg}
                        </span>
                        <FormattedMessage id="Button.newWidget" />
                    </Button>
                </Card.Header>
            </Col>
        </Row>
        <Card.Body>
            <div className="crossa-tabs-container">
                <DragDropContext onDragEnd={onDragWidget}>
                    <Droppable droppableId="droppable" direction="horizontal">
                        {(provided) => (
                            <Nav className="crossa-tabs crossa-scrollable-tabs" variant={"tabs"}
                                 activeKey={props.widgetIndex} ref={provided.innerRef}
                                 {...provided.droppableProps}>
                                {widgets.map((widget, index) => {
                                    return <Draggable key={index} draggableId={index + "drag"} index={index}>
                                        {(provided) => (
                                            <Nav.Link ref={provided.innerRef}
                                                      {...provided.draggableProps}
                                                      {...provided.dragHandleProps}
                                                      eventKey={index}
                                                      active={index == props.widgetIndex}
                                                      className={checkIfError(props.errors.dashboard?.widgets?.[index]) ? "has-errors" : ""}
                                                      onSelect={props.setWidgetIndex}>{widget.type!==WIDGET_TYPE.WELCOME && widget.title? widget.title : widgetTypeOptions.find(widgetType=>widgetType.value===widget.type)?.label}</Nav.Link>
                                        )}
                                    </Draggable>
                                })}
                                {provided.placeholder}
                            </Nav>
                        )}</Droppable>
                </DragDropContext>
                <Tab.Content>
                    {widgets.map((widget, index) => {
                        return (
                            <Tab.Pane
                                className="crossa-tab-pane"
                                eventKey={index}
                                active={index === props.widgetIndex}
                                key={index}>
                                <WidgetContent
                                    widgetIndex={index}
                                    widgetInfo={widget}
                                    viewId={widget.vid}
                                    errors={props.errors}
                                />
                            </Tab.Pane>
                        )
                    })
                    }
                </Tab.Content>
            </div>
        </Card.Body>
    </div>
    )
}

export default WidgetsComponent;