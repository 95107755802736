import * as RecordTypeService from "service/RecordTypeService.js";
import * as QueryBuilder from "util/query/queryBuilder.js";
import * as RecordService from "service/RecordService";
import * as WorkspaceService from "service/WorkspaceService";
import React from "react";

const getRecordTypeAndMemberRecordTypeByIdHandler = async (recordTypeId,wid) => {

    let rules = [];
    let recordType = {};
    let currentMemberFields={};
    let recordFieldsOfRecordTypeRef=[];
    let recordsRefId=[];
    const response = await RecordTypeService.findRecordTypeById(recordTypeId);
    if (response.ok) {
        let fieldsMap = [];
        for (let i = 0; i < response.data.fields.length; ++i) {
            fieldsMap[response.data.fields[i].id] =
            {
                ...response.data.fields[i]
            };
        }
        rules = response.data.rules?.map(rule => {
            return {
                condition: QueryBuilder.queryToConditions([JSON.parse(rule.condition)], fieldsMap, null,JSON.parse(rule.condition)?.type === "Or", true),
                actions: QueryBuilder.queryToActions(JSON.parse(rule?.actions), fieldsMap, null),
                isActive:rule.isActive==undefined?true:rule.isActive,
                isDefault:rule.isDefault,
            }
        });

        recordType = {
            "id": recordTypeId,
            "name": response.data.name,
            "description": response.data.description,
            "type": response.data.type,
            "fields": response.data.fields,
            "rules": response.data.rules,
        };

        const responseMemberRecordType = await WorkspaceService.findMemberRecordTypeRecordView(wid);
        if (responseMemberRecordType.ok) {
            currentMemberFields=responseMemberRecordType.data.recordType.fields;
        }

        rules.map(rule => {
            if (rule.actions) {
                rule.actions.map(action => {
                    if (action.operator === "SetRecordInnerField") {
                        if (recordType.fields?.find(field => field.id === action.recordField)?.rtid) {
                            let id = recordType.fields?.find(field => field.id === action.recordField).rtid;
                            if (!recordsRefId.find(refId => refId === id)) {
                                recordsRefId.push(id);
                            }
                        }
                    }
                    return;
                });
            }
            return;
        });
        const promises=recordsRefId.map(id => {
            return new Promise(async(resolve) => {
                const responseRecordRef = await getRecordFieldsOfRecordTypeByIdHandler(id)
                if (responseRecordRef.ok) {
                    resolve(responseRecordRef.data.recordFieldsOfRecordType);
                }
            });
        });
        recordFieldsOfRecordTypeRef=await Promise.all(promises);
    }

    return {
        data: {
            recordType: recordType,
            rules: rules,
            currentMemberFields:currentMemberFields,
            recordFieldsOfRecordTypeRef:recordFieldsOfRecordTypeRef
        },
        ok: response.ok
    };
}

const saveRulesHandler=async(recordType)=>{
    const response = await RecordTypeService.updateRecordType(recordType);

    return{
        ok:response.ok
    };
}

const getRecordFieldsOfRecordTypeByIdHandler=async(recordTypeId)=>{
    let recordFieldsOfRecordType={};
    const response = await RecordTypeService.findRecordTypeById(recordTypeId);
    if (response.ok) {
        recordFieldsOfRecordType={
             id: recordTypeId,
                fields: response.data?.fields?.map(field => {
                    return {
                        name: field.name,
                        id: field.id,
                        type: field.type
                    }
                })
        };
    }
    return{
        data:{
            recordFieldsOfRecordType:recordFieldsOfRecordType
        },
        ok:response.ok
    };
}

const findRecordByIdHandler=async (recordId,rtid)=>{
    let records=[];
    const response = await RecordService.findRecordInRecordType(recordId, rtid);
    if (response.ok) {
        records=[
            {
                id: recordId,
                name: response.data.name
            }
        ];
    }
    return {
        data:{
            records:records
        },
        ok:response.ok
    };
}

export{
    getRecordTypeAndMemberRecordTypeByIdHandler,
    saveRulesHandler,
    getRecordFieldsOfRecordTypeByIdHandler,
    findRecordByIdHandler,
};
