import LoginPage from "views/LoginPage.js";
import Page404 from "../../views/Page404";


const publicRoutes = [
    {
        path: "/login-page",
        layout: "/auth",
        name: "Login Page",
        component: LoginPage,
    },
    {
        path: "",
        layout: "/404",
        name: "404 Not Found",
        component: Page404,
    }
];

export default publicRoutes;
