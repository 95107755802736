import React from "react";
import {Button, Navbar} from "react-bootstrap";
import {useHistory} from "react-router-dom";
import {addIconFullBg, leftArrow} from "./Icons";

function AdminNavbar(props) {
    const history = useHistory();

    return (
        <>

            <Navbar className="navbar-fixed" expand="lg">
                    <div className="navbar-wrapper">
                        <div className="left-col">
                            {props.hasBackBtn && <span onClick={() => history.goBack()} className="back-icon-container">{leftArrow}</span>}
                            {props.title && <h3>{props.title}</h3>}
                            <div className="navbar-minimize">

                                <Button
                                    className="btn-fill btn-round btn-icon d-block d-lg-none bg-dark border-dark"
                                    variant="dark"
                                    onClick={() =>
                                        document.documentElement.classList.toggle("nav-open")
                                    }
                                >
                                    <i className="fas fa-ellipsis-v visible-on-sidebar-regular"></i>
                                    <i className="fas fa-bars visible-on-sidebar-mini"></i>
                                </Button>
                            </div>
                        </div>
                        {props.hasRightBtn &&
                        <div className="right-col">
                            <Button
                                onClick={props.rightButtonAction}
                                variant={props.buttonVariant || "primary"}
                            >{props.rightBtnHasIcon ?
                                <span className="icon">
                            {addIconFullBg}
                            </span> : null}
                                {props.rightBtnName}
                            </Button>
                        </div>}
                    </div>
            </Navbar>
        </>
    );
}

export default AdminNavbar;
