import * as RestClient from "../util/api/restClient";
import * as ClientConfig from "../config/ClientConfig";
import * as StorageService from "./StorageService";

const findFormWithRecordType = (wid, formId) => {
    return RestClient.handleResponse(() => {
        const finalURL = ClientConfig.URL_RESOURCE_SERVER + ClientConfig.NAMESPACE_WORKSPACE_FORMS + wid + "/" + formId;
        const response = fetch(finalURL, {
            method: "GET",
            headers: {
                "Authorization": "Bearer " + StorageService.getAccessToken()
            }
        });

        return response;
    });
}

const findRecordType = recordTypeId => {
    return RestClient.handleResponse(() => {
        const finalURL = ClientConfig.URL_RESOURCE_SERVER + ClientConfig.NAMESPACE_FORMS + "recordtypes/" + recordTypeId;
        const response = fetch(finalURL, {
            method: "GET",
            headers: {
                "Authorization": "Bearer " + StorageService.getAccessToken()
            }
        });

        return response;
    });
}

const updateRecordType = async newRecordType => {
    return RestClient.handleResponse(() => {
        const id = newRecordType.id;
        const finalURL = ClientConfig.URL_RESOURCE_SERVER + ClientConfig.NAMESPACE_FORMS + "recordtypes/" + id;
        const response = fetch(finalURL, {
            method: "PUT",
            headers: {
                "Authorization": "Bearer " + StorageService.getAccessToken(),
                "Content-Type": "application/json",
            },
            body: JSON.stringify(newRecordType),
        });

        return response;
    });
}

const getFormIdHash = (workspaceId, formId) => {
    return RestClient.handleResponse(() => {
        const finalURL = ClientConfig.URL_RESOURCE_SERVER + ClientConfig.NAMESPACE_WORKSPACE_FORMS + workspaceId + "/" + formId + "/hash";
        const response = fetch(finalURL, {
            method: "GET",
            headers: {
                "Authorization": "Bearer " + StorageService.getAccessToken()
            }
        });

        return response;
    });
}

export {
    findFormWithRecordType,
    findRecordType,
    updateRecordType,
    getFormIdHash
}