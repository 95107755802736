import React from "react";

// react-bootstrap components
import {
  Container,
} from "react-bootstrap";

function FooterForLogin() {
  return (
    <>
      <footer className="footer login-footer fixed-bottom">
        <Container>
          <nav>
            {/*<ul className="footer-menu d-flex justify-content-center">*/}

            {/*  <li>*/}
            {/*    <a className="m-0" href="#" onClick={(e) => e.preventDefault()}>*/}
            {/*      Terms and conditions*/}
            {/*    </a>*/}
            {/*  </li>*/}
            {/*  <li>*/}
            {/*    <a className="m-0" href="#" onClick={(e) => e.preventDefault()}>*/}
            {/*      Data privacy*/}
            {/*    </a>*/}
            {/*  </li>*/}

            {/*</ul>*/}
            <p className="copyright text-center m-0">
              © {new Date().getFullYear()}{" "}
              <a href="https://www.gebs.ro/">GEBS</a>
            </p>
          </nav>
        </Container>
      </footer>
    </>
  );
}

export default FooterForLogin;
