
import * as RecordTypeService from "service/RecordTypeService";
import * as ViewService from "service/ViewsService";
const getRecordTypesHandler=async(wid,rtid)=>{
    let recordTypes=[];
    let options=[];
    let recordTypeIndex=null;
    let recordFields=[];
    const response = await RecordTypeService.findAllRecordTypes(wid);
    if (response.ok) {
        recordTypes=response.data;
        options=response.data.map((data, index) => {
            return {
                "value": index,
                "label": data.name,
            }
        });
        if (rtid) {
            let index = response.data.findIndex(rt => rt.id === rtid)
            recordTypeIndex=index;
            const newRecordFields = response.data[index]?.fields;
            recordFields=newRecordFields.map(recordField => {
                return {
                    recordViewFieldMode:{
                        id:recordField.id,
                        mode:"READ"
                    },
                    recordTypeField:{
                        ...recordField
                    }
                }

            });
        }
    }
    return{
        data:{
            recordTypes:recordTypes,
            options:options,
            recordTypeIndex:recordTypeIndex,
            recordFields:recordFields
        },
        ok:response.ok
    }
}

const addViewHandler=async(newView)=>{
    const response = await ViewService.addView(newView);

    return{
        ok:response.ok
    }
}

export{
    getRecordTypesHandler,
    addViewHandler
}
