import CrossaPageTemplate from "../../../../components/CrossaPageTemplate";
import {FormattedMessage} from "react-intl";
import React, {useEffect, useState} from "react";
import {Card, Col, Collapse, Form, OverlayTrigger, Row, Tab, Tabs, Tooltip} from "react-bootstrap";
import {
    getAllRecordsWithNameContainsHandler
} from "handlers/workspaceMemberHandlers/menuDisplayHandlers/DisplayMenuHandlers";
import DateTimePicker from "react-datetime-picker";
import {FILTERS, RECORD_FIELD_MODE, RECORD_FIELD_TYPE, RECORD_TYPE_TYPE} from "../../../../util/Constants";
import {sortDownIcon, sortUpIcon, trashIcon} from "../../../../components/Icons";
import {
    getAllRegistrationsInRecordViewWithNameContainsHandler
} from "../../../../handlers/workspaceMemberHandlers/formHandlers/FormDataHandlers";

function RecordFilters(props) {
    const [state, setState] = useState({
        allRecordRefs: {},
        expandablePanel: null,
        filters: [],
        savedFilters: [],
        recordRefsForFilters: [],
        chipsExpandedSavedFilters: {},
        selectedSavedFilter: null,
        filtersTabOpen: FILTERS.ALL_FILTERS,
    });

    const toggleExpandablePanel = (event, value) => {
        event.preventDefault();
        if (state.expandablePanel === value) {
            setState({
                ...state,
                expandablePanel: null
            });
        } else {
            setState({
                ...state,
                expandablePanel: value
            });
        }
    };

    useEffect(async () => {
        let viewIdArray = [];
        props.recordTypeFields.map(field => {
            if (!field.vid || viewIdArray.includes(field.vid) || field.mode === RECORD_FIELD_MODE.HIDE) {
                return;
            }
            viewIdArray.push(field.vid);
        });

        for (const key in state.allRecordRefs) {
            viewIdArray = viewIdArray.filter(id => !state.allRecordRefs[id]);
        }

        const tempVariable = await createAllRecordRefsObject(viewIdArray);
        setState({
            ...state,
            filters: props.filters,
            savedFilters: props.savedFilters,
            recordRefsForFilters: props.recordRefsForFilters,
            allRecordRefs: {...state.allRecordRefs, ...tempVariable},
        });
    }, [props.filters, props.savedFilters, props.recordRefsForFilters]);

    const createAllRecordRefsObject = async (viewIdArray) => {
        let viewIdRecordsObject = {};
        const lengthOfViewIdArray = viewIdArray.length;
        for (let i = 0; i < lengthOfViewIdArray; ++i) {
            const records = await getAllRecordsWithNameContains(null, viewIdArray[i]);
            viewIdRecordsObject = {
                ...viewIdRecordsObject,
                [viewIdArray[i]]: records
            };
        }
        return viewIdRecordsObject;
    }
    const getAllRecordsWithNameContains = async (nameContains, viewId) => {
        if (props.rtType === RECORD_TYPE_TYPE.FORM_DATA) {
            return getAllRegistrationsInRecordViewWithNameContainsHandler(nameContains, viewId).then(response => {
                if (response.ok) {
                    return response.data.records;
                }
            });
        } else {
            return getAllRecordsWithNameContainsHandler(nameContains, viewId, props.mid, props.pageIndex, props.tabIndex).then(response => {
                if (response.ok) {
                    return response.data.records;
                }
            });
        }
    }

    const handleChangeFilterOptions = (fieldId, record, fieldType) => {
        let newFilters = [...state.filters];
        let newRecordRefsForFilters = [...state.recordRefsForFilters];
        let exists = typeof newFilters.find(filter => filter.fieldId === fieldId) !== "undefined";
        if (exists) {
            newFilters.map(filter => {
                if (filter.fieldId === fieldId) {
                    if (filter.values.find(id => id == record.value)) {
                        filter.values = filter.values.filter(val => val != record.value);
                    } else {
                        filter.values.push(record.value);
                    }
                }
            });
            if (fieldType === RECORD_FIELD_TYPE.RECORD || fieldType === RECORD_FIELD_TYPE.RECORD_LIST) {
                if (newRecordRefsForFilters.find(recordRef => recordRef.fieldId === fieldId && recordRef.record?.value == record.value)) {
                    newRecordRefsForFilters = newRecordRefsForFilters.filter(recordRef => !(recordRef.fieldId === fieldId && recordRef.record?.value == record.value));
                } else {
                    newRecordRefsForFilters.push({fieldId: fieldId, record: record})
                }
            }
        } else {
            newFilters.push({fieldId: fieldId, values: [record.value]});
            if (fieldType === RECORD_FIELD_TYPE.RECORD || fieldType === RECORD_FIELD_TYPE.RECORD_LIST)
                newRecordRefsForFilters.push({fieldId: fieldId, record: record});
        }
        newFilters = newFilters.filter(f => f.values.length > 0);
        setState({
            ...state,
            filters: newFilters,
            recordRefsForFilters: newRecordRefsForFilters
        });
    }

    const handleChangeFilterDate = (fieldId, startDate, endDate) => {
        let newFilters = [...state.filters];
        let exists = typeof newFilters.find(filter => filter.fieldId === fieldId) !== "undefined";
        if (exists) {
            newFilters.map(filter => {
                if (filter.fieldId === fieldId) {
                    if (startDate === "") {
                        filter.values[0] = null;
                    } else if (startDate) {
                        let userTimezoneOffset = new Date(startDate).getTimezoneOffset() * 60000;
                        filter.values[0] = new Date(startDate.getTime() - userTimezoneOffset);
                    }
                    if (endDate === "") {
                        filter.values[1] = null;
                    } else if (endDate) {
                        let userTimezoneOffset = new Date(endDate).getTimezoneOffset() * 60000;
                        filter.values[1] = new Date(endDate.getTime() - userTimezoneOffset);
                    }
                }
            });
        } else {

            let finalStartDate;
            let finalEndDate;
            if (startDate) {
                let userTimezoneOffset = new Date(startDate).getTimezoneOffset() * 60000;
                finalStartDate = new Date(startDate.getTime() - userTimezoneOffset);
            } else {
                finalStartDate = null;
            }
            if (endDate) {
                let userTimezoneOffset = new Date(endDate).getTimezoneOffset() * 60000;
                finalEndDate = new Date(endDate.getTime() - userTimezoneOffset);
            } else {
                finalEndDate = null;
            }
            newFilters.push({fieldId: fieldId, values: [finalStartDate, finalEndDate]});
        }
        newFilters = newFilters.filter(f => f.values.length > 0 && f.values.filter(val => val).length > 0);
        setState({
            ...state,
            filters: newFilters
        })
    }

    const handleApplyFilters = () => {
        if (state.filtersTabOpen === FILTERS.SAVED_FILTERS && (props.selectedSavedFilter || props.selectedSavedFilter === 0)) {
            let newRecordRefsForFilters = [];
            let newFilters = state.savedFilters[props.selectedSavedFilter].fieldFilters?.map(filter => {
                filter.values = filter.values.map(value => {
                    if (props.recordTypeFields.find(field => field.id === filter.fieldId).type === RECORD_FIELD_TYPE.DATE) {
                        value = value ? new Date(new Date(value).getTime() - (new Date(value).getTimezoneOffset() * 60000)) : value;
                    }
                    let recordRef = state.allRecordRefs[props.recordTypeFields.find(field => field.id === filter.fieldId)?.vid]?.find(record => record.value == value);
                    newRecordRefsForFilters.push({fieldId: filter.fieldId, record: recordRef});
                    return value;
                });
                return filter;
            });
            setState({
                ...state,
                recordRefsForFilters: newRecordRefsForFilters
            });
            props.applyFilters(newFilters, newRecordRefsForFilters, props.selectedSavedFilter);
        } else if (state.filtersTabOpen === FILTERS.ALL_FILTERS) {
            props.applyFilters(state.filters, state.recordRefsForFilters, null);
        }
    }

    const selectSavedFilter = (index) => {
        props.selectSavedFilter(index);
    }

    const fieldModeToFilter = (field, index) => {
        let noFiltersOnField = state.filters.find(f => f.fieldId === field.id)?.values.filter(value => !!value).length || 0;
        if (field.mode !== RECORD_FIELD_MODE.HIDE) {
            switch (field.type) {
                case RECORD_FIELD_TYPE.RECORD:
                case RECORD_FIELD_TYPE.RECORD_LIST:
                    return <Card key={index}>
                        <Card.Header>
                            <Card.Title as="h4">
                                <a
                                    data-toggle="collapse"
                                    aria-expanded={state.expandablePanel === field.id}
                                    onClick={(e) => toggleExpandablePanel(e, field.id)}
                                >
                                <span className="left-col">
                                    {field.name}
                                </span>
                                    <span className="right-col">
                                      {noFiltersOnField !== 0 ? noFiltersOnField : ""}<b className="caret"></b>
                                </span>

                                </a>
                            </Card.Title>
                        </Card.Header>
                        <Collapse
                            className="card-collapse"
                            in={state.expandablePanel === field.id}
                        >
                            <Card.Body>
                                {state.allRecordRefs[field.vid]?.map((record, index) => {
                                    return <Row key={index}
                                                onClick={() => handleChangeFilterOptions(field.id, record, field.type)}>
                                        <Col className="left-col">{record.label}</Col>
                                        <Col className="right-col"><Form.Group>
                                            <Form.Check>
                                                <Form.Check.Label>
                                                    <Form.Check.Input type="checkbox"
                                                                      checked={state.filters?.find(f => f.fieldId === field.id)?.values.find(rid => rid == record.value) || false}
                                                                      onChange={() => handleChangeFilterOptions(field.id, record, field.type)}>
                                                    </Form.Check.Input>
                                                    <span className="form-check-sign"></span>
                                                </Form.Check.Label>
                                            </Form.Check></Form.Group></Col>
                                    </Row>
                                })}
                            </Card.Body>
                        </Collapse>
                    </Card>
                case RECORD_FIELD_TYPE.SINGLE_CHOICE:
                case RECORD_FIELD_TYPE.MULTIPLE_CHOICE:
                    return <Card key={index}>
                        <Card.Header>
                            <Card.Title as="h4">
                                <a
                                    data-toggle="collapse"
                                    aria-expanded={state.expandablePanel === field.id}
                                    onClick={(e) => toggleExpandablePanel(e, field.id)}
                                >
                                <span className="left-col">
                                     <span>{field.name}</span>
                                </span>
                                    <span className="right-col">
                                    {noFiltersOnField !== 0 ? <span>{noFiltersOnField}</span> : ""}<b
                                        className="caret"></b>
                                </span>

                                </a>
                            </Card.Title>
                        </Card.Header>
                        <Collapse
                            className="card-collapse"
                            in={state.expandablePanel === field.id}
                        >
                            <Card.Body>
                                {field.options?.map((option, index) => {
                                    return <Row key={index}
                                                onClick={() => handleChangeFilterOptions(field.id, {value: option}, field.type)}>
                                        <Col className="left-col">{option}</Col>
                                        <Col className="right-col"><Form.Group>
                                            <Form.Check>
                                                <Form.Check.Label>
                                                    <Form.Check.Input type="checkbox"
                                                                      checked={state.filters?.find(f => f.fieldId === field.id)?.values.includes(option) || false}
                                                                      onChange={() => handleChangeFilterOptions(field.id, {value: option}, field.type)}>
                                                    </Form.Check.Input>
                                                    <span className="form-check-sign"></span>
                                                </Form.Check.Label>
                                            </Form.Check></Form.Group></Col>
                                    </Row>
                                })}
                            </Card.Body>
                        </Collapse>
                    </Card>
                case RECORD_FIELD_TYPE.DATE:
                    return <Card className="date-card" key={index}>
                        <Card.Header>
                            <Card.Title as="h4">
                                <a
                                    data-toggle="collapse"
                                    aria-expanded={state.expandablePanel === field.id}
                                    onClick={(e) => toggleExpandablePanel(e, field.id)}
                                >
                                <span className="left-col">
                                    {field.name}
                                </span>
                                    <span className="right-col">
                                    {noFiltersOnField !== 0 ? noFiltersOnField : ""}<b className="caret"></b>
                                </span>

                                </a>
                            </Card.Title>
                        </Card.Header>
                        <Collapse
                            className="card-collapse"
                            in={state.expandablePanel === field.id}
                        >
                            <Card.Body>
                                <Row>
                                    <Col className="pl-0">
                                        <Form.Group>
                                            <Form.Label>From:</Form.Label>
                                            <DateTimePicker
                                                maxDate={new Date("9999-12-31")}
                                                onChange={(value) => handleChangeFilterDate(field.id, value ? value : "", null)}
                                                format={"y-MM-dd"}
                                                value={state.filters && state.filters?.find(f => f.fieldId === field.id)?.values[0] ?
                                                    new Date(state.filters?.find(f => f.fieldId === field.id)?.values[0]) : ""}
                                            />
                                        </Form.Group>
                                    </Col>
                                    <Col className="pr-0">
                                        <Form.Group>
                                            <Form.Label>To:</Form.Label>
                                            <DateTimePicker
                                                maxDate={new Date("9999-12-31")}
                                                onChange={(value) => handleChangeFilterDate(field.id, null, value ? value : "")}
                                                format={"y-MM-dd"}
                                                value={state.filters && state.filters?.find(f => f.fieldId === field.id)?.values[1] ?
                                                    new Date(state.filters?.find(f => f.fieldId === field.id)?.values[1]) : ""}
                                            />
                                        </Form.Group>
                                    </Col>
                                </Row>
                            </Card.Body>
                        </Collapse>
                    </Card>
            }
        }
    }

    const toggleExpandChipsContainer = (index) => {
        setState({
            ...state,
            chipsExpandedSavedFilters: {
                [index]: !state.chipsExpandedSavedFilters[index]
            }
        })
    }

    const handleChangeFiltersTab = (tab) => {
        setState({
            ...state,
            filtersTabOpen: tab,
        });
    }

    const handleRemoveSavedFilter = (index) => {
        if (index === props.selectedSavedFilter) {
            setState({
                ...state,
                selectedSavedFilter: null,
                recordRefsForFilters: [],
            });
        }
        props.removeSavedFilter(index)
    }

    return (
        <div className="record-filters">
            <CrossaPageTemplate
                hasFooter={true}
                saveChanges={handleApplyFilters}
                saveChangesBtnText={<FormattedMessage id="Button.apply"/>}
                cancelBtnText={<FormattedMessage id="Button.removeFilters"/>}
                closeSlidingPanel={props.handleRemoveFilters}
            >
                <div className="crossa-tabs-container">
                    <Tabs
                        activeKey={state.filtersTabOpen}
                        id="uncontrolled-tab-example"
                        className="crossa-tabs"
                        onSelect={handleChangeFiltersTab}
                    >
                        <Tab eventKey={FILTERS.ALL_FILTERS} title={<FormattedMessage id="AllFilters"/>}>
                            <div className="accordions filters-accordion" id="accordion">
                                {
                                    props.recordTypeFields.map((field, index) => {
                                        return fieldModeToFilter(field, index);
                                    })
                                }
                            </div>
                        </Tab>
                        {props.rtType !== RECORD_TYPE_TYPE.FORM_DATA &&
                            <Tab eventKey={FILTERS.SAVED_FILTERS} title={<FormattedMessage id="SavedFilters"/>}>
                                {state.savedFilters?.map((filters, indexSavedFilter) => {
                                    return <div
                                        key={indexSavedFilter}
                                        className={(state.chipsExpandedSavedFilters[indexSavedFilter] ? "expanded " : "") + (props.selectedSavedFilter === indexSavedFilter ? "selected " : "") + "chips-container selectable-chips"}
                                        onClick={() => selectSavedFilter(indexSavedFilter)}
                                    >
                                        {filters.fieldFilters?.map((filter, index) => {
                                            return filter.values.map(val => {
                                                let recordRef = state.allRecordRefs[props.recordTypeFields.find(field => field.id === filter.fieldId)?.vid]?.find(record => record.value == val);
                                                return !val ? "" :
                                                    !recordRef ?
                                                        props.recordTypeFields.find(field => field.id === filter.fieldId)?.type === RECORD_FIELD_TYPE.DATE ?
                                                            <span
                                                                key={index + Math.random()}
                                                                className="chip active">{props.recordTypeFields.find(field => field.id === filter.fieldId)?.name + ": " + new Date(new Date(val).getTime() - (new Date(val).getTimezoneOffset() * 60000))?.toISOString().substring(0, 10)}
                                                            </span>
                                                            :
                                                            <span
                                                                key={index + Math.random()}
                                                                className="chip active">{props.recordTypeFields.find(field => field.id === filter.fieldId)?.name + ": " + (typeof val === "object" ? val?.toISOString().substring(0, 10) : val)}
                                                            </span>
                                                            :
                                                            <span
                                                            key={index + Math.random()}
                                                            className="chip active">{props.recordTypeFields.find(field => field.id === filter.fieldId)?.name + ": " + recordRef.label}
                                                            </span>
                                            })
                                        })}
                                        <div className="top-right-col">
                                            <div className="row">
                                                <OverlayTrigger
                                                    onClick={(e) => e.preventDefault()}
                                                    overlay={

                                                        <Tooltip>
                                                            {state.chipsExpandedSavedFilters[indexSavedFilter] ?
                                                                <FormattedMessage id="Collapse"/> :
                                                                <FormattedMessage id="Expand"/>}
                                                        </Tooltip>
                                                    }
                                                >
                                        <span onClick={(e) => {
                                            e.stopPropagation();
                                            toggleExpandChipsContainer(indexSavedFilter)
                                        }}
                                              className="action">{state.chipsExpandedSavedFilters[indexSavedFilter] ? sortDownIcon : sortUpIcon}</span>
                                                </OverlayTrigger>


                                                <OverlayTrigger
                                                    onClick={(e) => e.preventDefault()}
                                                    overlay={
                                                        <Tooltip>
                                                            <FormattedMessage id="Button.delete"/>
                                                        </Tooltip>
                                                    }
                                                >
                                              <span
                                                  onClick={(e) => {
                                                      e.stopPropagation();
                                                      handleRemoveSavedFilter(indexSavedFilter)
                                                  }}
                                                  className="action">{trashIcon}
                                        </span>
                                                </OverlayTrigger>
                                            </div>

                                        </div>

                                    </div>
                                })}
                            </Tab>
                        }
                    </Tabs>
                </div>
            </CrossaPageTemplate>
        </div>
    )
}

export default RecordFilters;
