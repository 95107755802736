import React, {useEffect, useState} from "react";
// react-bootstrap components
import {Card, Col, Row,} from "react-bootstrap";
import CrossaPageTemplate from "../../components/CrossaPageTemplate";
import AdminNavbar from "../../components/AdminNavbar";
import {FormattedMessage} from "react-intl";
import * as StorageService from "../../service/StorageService";
import {EventBus} from "../../util/EventBus";
import welcomeImg from "../../assets/img/home-avatar.svg";

function Home() {

    let workspaceName = StorageService.getItem("workspace_name");
    let workspaceId = StorageService.getItem("workspace_id");
    let memberName = StorageService.getMemberName();
    let memberEmail = StorageService.getUsername();

    const [name, setName] = useState(memberName);

    useEffect(() => {
        EventBus.on("sidebarLoaded", (data) => {
            setName(data.name);
        });
    }, [])



    return (
        <>
            <AdminNavbar
                title={<FormattedMessage id="Home"/>}
            />
            <CrossaPageTemplate>
                <Row>
                    <Card className="welcome-card">
                        <Card.Body className="row">
                            <Col className="text-col">
                                {
                                    (typeof StorageService.getIsActive(workspaceId) === "undefined" || StorageService.getIsActive(workspaceId) === null || StorageService.getIsActive(workspaceId)) ?
                                    <>
                                            <h2>{name ? name : memberEmail}, <FormattedMessage
                                            id="WelcomeTo"/> {workspaceName}! 👨 </h2>
                                            <p>
                                                <FormattedMessage id="YouAreCurrentlyLoggedAs"/>
                                                &nbsp;
                                                <span className="role"><FormattedMessage id="WorkspaceMember"/></span>
                                            </p>
                                        </>


                                        : <div className="danger">

                                            <FormattedMessage
                                                id="YouAreInactive"/> {workspaceName}

                                        </div>
                                }


                            </Col>
                            <Col>
                                <img src={welcomeImg} alt={"."}/>
                            </Col>
                        </Card.Body>
                    </Card>
                </Row>
            </CrossaPageTemplate>
        </>

    );
}

export default Home;
