import React, {useEffect, useRef, useState} from "react";
import Select from 'react-select'
import AsyncSelect from 'react-select/async';
import CrossaPageTemplate from "components/CrossaPageTemplate.js";
import TagsInput from "views/workspaceMember/menuDisplay/components/TagsInput.js";
import { Col, Container, Form, Row} from "react-bootstrap";
import { FormattedMessage } from "react-intl";
import Footer from "../../../../components/Footer.js";
import { useHistory } from "react-router-dom";
import { getAllRecordsWithNameContainsHandler } from "handlers/workspaceAdminHandlers/membersHandlers/SaveMemberHandler.js";
import DateTimePicker from "react-datetime-picker";
import {
    CROPPER_ASPECT_RATIO,
    FORM_ACTION,
    RECORD_FIELD_MODE, RECORD_FIELD_RECORD_DISPLAY_OPTION, RECORD_FIELD_RECORD_LIST_DISPLAY_OPTION,
    RECORD_FIELD_TEXT_DISPLAY_OPTION,
    RECORD_FIELD_TYPE
} from "../../../../util/Constants";
import * as ClientConfig from "../../../../config/ClientConfig";
import {editIcon, fileIcon, iconXthinner, uploadFileIcon, uploadImageIcon} from "../../../../components/Icons";
import Cropper from "../../../../components/Cropper";
import {getNotifyErrorOptions} from "../../../../util/Util";
import * as Validations from "validations/workspaceAdmin/member/MemberValidations";
import {
    findRecordByIdHandler
} from "../../../../handlers/workspaceMemberHandlers/menuDisplayHandlers/DisplayMenuHandlers";


function SaveMemberForm(props) {
    const imageInputsFileRef = useRef([]);
    const history = useHistory();
    const [state, setState] = useState({
        record: null,
        linkRecordToForm: {},
        allRecordRefs: {},
        selectedFiles: {},
        errors: {},
        formHash: {},
        cropperShow:false,
        selectedImageForCropper:null,
        isProfileImage:false
    });

    const recordRef = useRef(null);
    const fieldsRef = useRef(null);

    useEffect(async() => {
        let newState = {...state};
        let viewIdArray = [];
        let formArray = [];
        let newErrors = {};

        if(props.fields.some(f => f.id == "isActive") && 
            typeof(props.record["isActive"]) === "undefined" || props.record["isActive"] == null){
            newState = {
                ...newState,
                record: {
                    "isActive":true
                },            
            };
        }

        if (JSON.stringify(recordRef.current) !== JSON.stringify(props.record)) {
            let newRecord = {...newState.record};
            for (let key in props.record) {
                let field = props.fields.find(field => field.id === key);
                if (field && field.type === RECORD_FIELD_TYPE.SINGLE_CHOICE && field.mode === RECORD_FIELD_MODE.WRITE && field.options && !field.options.includes(props.record[key])) {
                    newRecord[key] = null;
                } else if (field && field.type === RECORD_FIELD_TYPE.MULTIPLE_CHOICE && field.mode === RECORD_FIELD_MODE.WRITE && field.options) {
                    newRecord[key] = props.record[key].filter(option => field.options.includes(option))
                } else {
                    newRecord[key] = props.record[key];
                }
            }
            newRecord.roles = newRecord?.roles?.filter(role => props.roles?.find(r => r.value === role.id))

            newState = {
                ...newState,
                record: newRecord,
                selectedFiles: {}
            };
            recordRef.current = JSON.parse(JSON.stringify(props.record));
        }


        if (JSON.stringify(fieldsRef.current) !== JSON.stringify(props.fields)) {
            props.fields.forEach(field => {
                newErrors[field.id] = false;
                if (field.type !== RECORD_FIELD_TYPE.RECORD && field.type !== RECORD_FIELD_TYPE.RECORD_LIST) {
                    return;
                }
                // if (field.displayOption === "FormLink") {
                //     newState.linkRecordToForm[field.id] = !!(newState.record[field.id] && state.linkRecordToForm[field.id] !== false);
                //     if(newState.record[field.id]) {
                //         formArray.push({
                //             "fieldId": field.id,
                //             "rtid": field.rtid,
                //             "id": newState.record[field.id].id
                //         })
                //     }
                // } else {
                    if (!field.vid || viewIdArray.includes(field.vid) || !field.mode || field.mode === RECORD_FIELD_MODE.HIDE) {
                        return;
                    }
                    viewIdArray.push(field.vid);
                // }
            });
            const tempVariable = await createAllRecordRefsObject(viewIdArray);
            const formHashObj = await createFormHashObject(formArray);
            newState = {
                ...newState,
                errors: newErrors,
                allRecordRefs: tempVariable,
                formHash: formHashObj
            };
            fieldsRef.current = JSON.parse(JSON.stringify(props.fields));
        }
        setState(newState);
    }, [props.fields, props.record]);

    const createAllRecordRefsObject = async (viewIdArray) => {
        let viewIdRecordsObject = {};
        const lengthOfViewIdArray = viewIdArray.length;
        for (let i = 0; i < lengthOfViewIdArray; ++i) {
            const records = await getAllRecordsWithNameContains(null, viewIdArray[i]);
            viewIdRecordsObject = {
                ...viewIdRecordsObject,
                [viewIdArray[i]]: records
            };
        }
        return viewIdRecordsObject;
    }

    const createFormHashObject = async (forms) => {
        let formHashObj = {};
        for (let i = 0; i < forms.length; i++) {
            let form = await findRecordById(forms[i].id, forms[i].rtid);
            formHashObj[forms[i].fieldId] = form.hash;
        }

        return formHashObj;
    }

    const findRecordById = async (id, rtid) => {
        return findRecordByIdHandler(id, rtid).then(response => {
            if (response.ok) {
                return response.data;
            }
        });
    }
    const handleDateChange = (fieldId, value) => {
        let newErrors = {...state.errors};
        if (!!state.errors[fieldId]) {
            newErrors = {
                ...newErrors,
                [fieldId]: false,
            };
        }
        setState({
            ...state,
            record: {
                ...state.record,
                [fieldId]: value ? new Date(value) : null
            },
            errors: newErrors
        })
    }

    const handleInputChange = (event, type) => {
        let newErrors = {...state.errors};
        if (!!state.errors[event.target.name]) {
            newErrors = {
                ...newErrors,
                [event.target.name]: false,
            };
        }
        let record
        switch (type) {
            case RECORD_FIELD_TYPE.BOOLEAN:
                record = {
                    ...state.record,
                    [event.target.name]: event.target.checked,
                };
                break;
            case RECORD_FIELD_TYPE.SINGLE_CHOICE:
                record = {
                    ...state.record,
                    [event.target.name]: event.value,
                };
                break;
            case RECORD_FIELD_TYPE.MULTIPLE_CHOICE:
                let newValues;
                if (state.record && state.record[event.target.name]) {
                    newValues = state.record[event.target.name];
                } else {
                    newValues = [];
                }
                newValues.push(event.value);
                record = {
                    ...state.record,
                    [event.target.name]: newValues,
                };
                break;
            default:
                record= {
                    ...state.record,
                    [event.target.name]: event.target.value,
                }
        }
        setState({
            ...state,
            record: record,
            errors: newErrors
        });
    }

    const recordToSelectElement = record => {
        if (!record) {
            return null;
        }
        return {
            "value": record.id,
            "label": record.name
        }
    }

    const clickInputFile = (file) => {
        file.click();
    }

    const setCroppedImage=async(file)=>{
        let newErrors = {...state.errors};
        if (!!state.errors[state.selectedImageForCropper?.fieldId]) {
            newErrors = {
                ...newErrors,
                [state.selectedImageForCropper?.fieldId]: false,
            };
        }
        setState({
            ...state,
            selectedFiles: {
                ...state.selectedFiles,
                [state.selectedImageForCropper?.fieldId]: {
                    url: URL.createObjectURL(file),
                    file: file,
                }
            },
            isProfileImage: false,
            cropperShow: false,
            errors: newErrors
        })
    }

    const onSelectImage = e => {
        if (e.target.files && e.target.files.length === 1) {
            setState({
                ...state,
                cropperShow: true,
                isProfileImage: e.target.name === "image",
                selectedImageForCropper: {
                    fieldId:e.target.name,
                    picture:{
                        url: URL.createObjectURL(e.target.files[0]),
                        file: e.target.files[0],
                    }
                }
            });
        }
    }
    const onSelectFile = e => {
        let newErrors = {...state.errors};
        if (!!state.errors[e.target.name]) {
            newErrors = {
                ...newErrors,
                [e.target.name]: false,
            };
        }

        if (e.target.files && e.target.files.length === 1) {
            setState({
                ...state,
                selectedFiles: {
                    ...state.selectedFiles,
                    [e.target.name]: {
                        url: URL.createObjectURL(e.target.files[0]),
                        file: e.target.files[0],
                    }
                },
                errors: newErrors
            });
        }
    }

    const onSelectMultipleFiles = e => {
        let newErrors = {...state.errors};
        if (!!state.errors[e.target.name]) {
            newErrors = {
                ...newErrors,
                [e.target.name]: false,
            };
        }

        let newState={...state};

        if (e.target.files && e.target.files.length) {
            for (let i=0; i<e.target.files.length; i++) {
                newState.selectedFiles={...newState.selectedFiles,[e.target.name]:[
                        ...(newState.selectedFiles[e.target.name]||[]),{
                            url: URL.createObjectURL(e.target.files[i]),
                            file: e.target.files[i],
                        }
                    ]};
            }
        }
        setState({
            ...newState,
            errors: newErrors
        });
    }

    const deleteSelectedFile = (fieldId,index) =>{
        let newSelectedFiles={...state.selectedFiles};
        if(index || index===0){
            newSelectedFiles[fieldId].splice(index,1);
            if(!newSelectedFiles[fieldId].length){
                delete newSelectedFiles[fieldId];
            }
        } else{
            delete newSelectedFiles[fieldId];
        }

        setState({
            ...state,
            selectedFiles: newSelectedFiles
        });
    }

    const deleteExistentFile = (fieldId,index) => {
        let record = {...state.record};
        if (index || index === 0) {
            record[fieldId].splice(index, 1);
            if (!record[fieldId].length) {
                record[fieldId] = null;
            }
        } else {
            record[fieldId] = null;
        }

        setState({
            ...state,
            record: record
        });
    }

    const checkIfContains = (list, element) => {
        return list.map(el => el.id).includes(element.value);
    }

    const handleAddRecordToRecordListCheckBox = async (recordRef, name) => {
        let newErrors = {...state.errors};
        if (!!state.errors[name]) {
            newErrors = {
                ...newErrors,
                [name]: false,
            };
        }
        const record = {
            "id": recordRef.value,
            "name": recordRef.label,
        };

        let recordAux;

        if (state.record && state.record[name]) {
            const recordsList = state.record[name];
            const index = recordsList.findIndex(tag => tag.id === record.id);
            if (index > -1) {
                recordsList.splice(index, 1);
                recordAux = {
                    ...state.record,
                    [name]: recordsList
                };
            } else {
                recordAux = {
                    ...state.record,
                    [name]: [...recordsList, record]
                };
            }
        } else {
            recordAux = {
                ...state.record,
                [name]: [record]
            };
        }
        setState({
            ...state,
            record: recordAux,
            errors: newErrors
        });
    }

    /*
      action is UPDATE or INSERT
      if INSERT then all READ mode fields will be ignored
    */
    const fieldModeToFormInput = (field, index, action) => {
        if (field.id === "information" || field.id === "createdBy" || field.id === "createdDate" || field.id === "modifiedBy" ||
            field.id === "modifiedDate" || !field.mode || field.mode === RECORD_FIELD_MODE.HIDE) {
            return;
        }
        if (action === FORM_ACTION.INSERT && field.mode === RECORD_FIELD_MODE.READ) {
            return;
        }
        const readOnly = ((state.record && state.record.id && field.id === "email") || field.id === "createdBy" || field.id === "createdDate" ||
            field.id === "modifiedBy" || field.id === "modifiedDate") ? true : field.mode === RECORD_FIELD_MODE.READ;
        if (field.type === RECORD_FIELD_TYPE.TEXT) {
            if (field.displayOption === RECORD_FIELD_TEXT_DISPLAY_OPTION.TEXT_AREA) {
                return (
                    <Form.Group className={"text-area-form-group "+(!readOnly ? "" : "disabled")} key={index + field}>
                        <Form.Label key={index + field + 1}>{field.name}</Form.Label>
                        <Form.Control
                            className={state.errors[field.id]?" has-errors":""}
                            as="textarea"
                            style={{height: '100px'}}
                            isInvalid={state.errors[field.id]}
                            key={index + field + 2}
                            readOnly={readOnly}
                            onChange={event => handleInputChange(event, field.type)}
                            value={(state.record && state.record[field.id]) || ""}
                            name={field.id}
                        />
                        <Form.Control.Feedback type="invalid">
                            {field.id==="email"?<FormattedMessage id={"Validation.emailInvalid"}/>:<FormattedMessage id={"Validation.cannotBeEmpty"}/>}
                        </Form.Control.Feedback>
                    </Form.Group>
                );
            }
            return (
                <Form.Group key={index + field} className={!readOnly ? "" : "disabled"}>
                    <Form.Label key={index + field + 1} className={!readOnly ? "" : "disabled"}>{field.name}</Form.Label>
                    <Form.Control
                        className={state.errors[field.id]?" has-errors":""}
                        key={index + field + 2}
                        readOnly={readOnly}
                        onChange={event => handleInputChange(event, field.type)}
                        placeholder={field.name}
                        value={(state.record && state.record[field.id]) || ""}
                        type="text"
                        isInvalid={state.errors[field.id]}
                        name={field.id}/>
                    <Form.Control.Feedback type="invalid">
                        {field.id==="email"?<FormattedMessage id={"Validation.emailInvalid"}/>:<FormattedMessage id={"Validation.cannotBeEmpty"}/>}
                    </Form.Control.Feedback>
                </Form.Group>
            );
        } else if (field.type === RECORD_FIELD_TYPE.DATE) {
            return (
                <Form.Group key={index + field + 1} className={!readOnly ? "" : "disabled"}>
                    <Form.Label key={index + field + 2}>{field.name}</Form.Label>
                    <DateTimePicker
                        className={state.errors[field.id]?" has-errors":""}
                        maxDate={new Date("9999-12-31")}
                        disableClock={true}
                        onChange={value => handleDateChange(field.id, value)}
                        format={field.timeField ? "y-MM-dd HH:mm" : "y-MM-dd"}
                        value={state.record && state.record[field.id] ? new Date(state.record[field.id]) : ""}
                        disabled={readOnly}
                    />
                    {
                        state.errors[field.id] && <div className="error-message">
                            <FormattedMessage id={"Validation.cannotBeEmpty"}/>
                        </div>
                    }
                </Form.Group>
            )
        } else if (field.type === RECORD_FIELD_TYPE.BOOLEAN) {
            return (
                <Form.Group key={index + field + 1} className={!readOnly ? "" : "disabled"}>
                    <Row>
                        <Col>
                            <label className="d-flex align-items-center">
                                <span className="switch-label mr-5">{field.name}</span>
                            </label>
                        </Col>
                        <Col className="inline-switch-col">
                            <Form.Check
                                className={"ml-3 " + (state.errors[field.id]?" has-errors":"")}
                                type="switch"
                                id={"record-switch-" + field.id}
                                name={field.id}
                                checked={(state.record && state.record[field.id]) || (typeof state.record[field.id] === "undefined"?(field.id === "isActive"?true:false):state.record[field.id])}
                                //defaultChecked={(state.record && state.record[field.id]) || (field.id === "isActive"?true:false)}
                                onChange={event =>  handleInputChange(event, field.type)}
                            />
                        </Col>
                    </Row>
                </Form.Group>
            );
        } else if (field.type === RECORD_FIELD_TYPE.GROUP_HEADER) {
            return (
                <h4 className={index === 0 ? "group-header no-border" : "group-header"} key={index + field}>{field.name}</h4>
            )
        } else if (field.type === RECORD_FIELD_TYPE.INTEGER) {
            return (<Form.Group key={index + field + 1} className={!readOnly ? "" : "disabled"}>
                <Form.Label key={index + field + 2}>{field.name}</Form.Label>
                <Form.Control
                    className={state.errors[field.id]?" has-errors":""}
                    key={index + field + 3}
                    readOnly={readOnly}
                    onChange={event => handleInputChange(event, field.type)}
                    value={(state.record && state.record[field.id]) || ""}
                    type="number"
                    isInvalid={state.errors[field.id]}
                    name={field.id}/>
                <Form.Control.Feedback type="invalid">
                    <FormattedMessage id={"Validation.cannotBeEmpty"}/>
                </Form.Control.Feedback>
            </Form.Group>);
        } else if (field.type === RECORD_FIELD_TYPE.DECIMAL) {
            return (<Form.Group key={index + field + 1} className={!readOnly ? "" : "disabled"}>
                <Form.Label key={index + field + 2}>{field.name}</Form.Label>
                <Form.Control
                    className={state.errors[field.id]?" has-errors":""}
                    key={index + field + 3}
                    readOnly={readOnly}
                    onChange={event => handleInputChange(event, field.type)}
                    value={(state.record && state.record[field.id]) || ""}
                    type="number"
                    isInvalid={state.errors[field.id]}
                    name={field.id}/>
                <Form.Control.Feedback type="invalid">
                    <FormattedMessage id={"Validation.cannotBeEmpty"}/>
                </Form.Control.Feedback>
            </Form.Group>);
        } else if (field.type === RECORD_FIELD_TYPE.CURRENCY) {
            return (
                <Form.Group key={index + field + 1} className={!readOnly ? "" : "disabled"}>
                    <Form.Label key={index + field + 2}>{field.name} ({field.currency})</Form.Label>
                    <Form.Control
                        className={state.errors[field.id]?" has-errors":""}
                        key={index + field + 3}
                        readOnly={readOnly}
                        onChange={event => handleInputChange(event, field.type)}
                        value={(state.record && state.record[field.id]) || ""}
                        type="number"
                        isInvalid={state.errors[field.id]}
                        name={field.id}
                    />
                    <Form.Control.Feedback type="invalid">
                        <FormattedMessage id={"Validation.cannotBeEmpty"}/>
                    </Form.Control.Feedback>
                </Form.Group>
            );
        } else if (field.type === RECORD_FIELD_TYPE.RECORD) {
            if (readOnly) {
                // if (field.displayOption === RECORD_FIELD_RECORD_DISPLAY_OPTION.FORM_LINK) {
                //     if (state.record[field.id]) {
                //         return (
                //             <Form.Group className="mt-auto">
                //                 <a href={state.formHash[field.id] ? ClientConfig.URL_RESOURCE_SERVER + "api/form/" +
                //                     props.wid + "/" + state.record[field.id].id + "/" + state.formHash[field.id] + ".html" : ""}>
                //                     <Button className="mb-0">
                //                         {field.name}
                //                     </Button>
                //                 </a>
                //             </Form.Group>
                //         );
                //     }
                //     return <></>;
                // }
                return (
                    <Form.Group key={index + field + 1} className={!readOnly ? "" : "disabled"}>
                        <Form.Label key={index + field + 1}>{field.name}</Form.Label>
                        <Form.Control
                            key={index + field + 2}
                            readOnly
                            value={(!!state.record && state.record[field.id]?.name) || ""}
                            type="text"
                            isInvalid={state.errors[field.id]}
                            name={field.id}/>
                        <Form.Control.Feedback type="invalid">
                            <FormattedMessage id={"Validation.selectOneOption"}/>
                        </Form.Control.Feedback>
                    </Form.Group>
                )
            }
            // if (field.displayOption === RECORD_FIELD_RECORD_DISPLAY_OPTION.FORM_LINK) {
            //     return (
            //         <Form.Group>
            //             <Form.Check>
            //                 <Form.Check.Label>
            //                     <Form.Check.Input
            //                         className={!readOnly ? "" : "disabled"}
            //                         checked={state.linkRecordToForm && state.linkRecordToForm[field.id] || ""}
            //                         defaultValue=""
            //                         type="checkbox"
            //                         onChange={() => {
            //                             setState({
            //                                 ...state,
            //                                 linkRecordToForm: {
            //                                     ...state.linkRecordToForm,
            //                                     [field.id]: !state.linkRecordToForm[field.id]
            //                                 }
            //                             })
            //                         }}
            //                         name={field.id}
            //                     ></Form.Check.Input>
            //                     <span className="form-check-sign"></span>
            //                     {field.name}
            //                 </Form.Check.Label>
            //             </Form.Check>
            //             {
            //                 state.linkRecordToForm[field.id] &&
            //                 <Button className="mb-0" onClick={() => handleForm(field)}>
            //                     {state.record[field.id] ? "Edit form" : "Create form"}
            //                 </Button>
            //             }
            //         </Form.Group>
            //
            //     );
            // } else
                if (field.displayOption === RECORD_FIELD_RECORD_DISPLAY_OPTION.COMBO_BOX) {
                return (
                    <Form.Group key={index + field + 1} className={!readOnly ? "" : "disabled"}>
                        <Form.Label key={index + field + 2}>{field.name}</Form.Label>
                        <FormattedMessage id="Placeholder.select">
                            {placeholder =>
                                <Select
                                    className={"react-select primary" + (state.errors[field.id] ? " has-errors" : "")}
                                    classNamePrefix="react-select"
                                    value={!!state.record && recordToSelectElement(state.record[field.id])}
                                    options={state.allRecordRefs[field.vid]}
                                    onChange={event => handleAddRecordRef(event, field.id)}
                                    isDisabled={readOnly}
                                    isInvalid={state.errors[field.id]}
                                    isClearable
                                    placeholder={placeholder}
                                />
                            }
                        </FormattedMessage>
                        {
                            state.errors[field.id] && <div className={"error-message"}>
                                <FormattedMessage id={"Validation.selectOneOption"}/>
                            </div>
                        }
                    </Form.Group>
                );
            } else if (field.displayOption === RECORD_FIELD_RECORD_DISPLAY_OPTION.RADIO_BUTTON_GROUP) {
                return (

                    <Form.Group key={index + field + 1} className={!readOnly ? "" : "disabled"}>
                        <Form.Label key={index + field + 2}>{field.name}</Form.Label>
                        {
                            state.record[field.id] && !state.allRecordRefs[field.vid]?.find(recordRef => recordRef.value === state.record[field.id].id) &&
                            <div className="radio-container">
                                <Form.Check>
                                    <Form.Check.Label>
                                        <Form.Check.Input
                                            type="radio"
                                            checked={(!!state.record && state.record[field.id]) ?(state.record[field.id].id === state.record[field.id].id) : false}
                                            onChange={() => readOnly ? "" : handleAddRecordRef({
                                                "label": state.record[field.id].name,
                                                "value": state.record[field.id].id
                                            }, field.id)}
                                        ></Form.Check.Input>
                                        {state.record[field.id].name}
                                    </Form.Check.Label>
                                </Form.Check>
                            </div>

                        }
                        {
                            state.allRecordRefs[field.vid] && state.allRecordRefs[field.vid].map((recordRef, index) => {
                                return (
                                    <div className="radio-container">
                                        <Form.Check>
                                            <Form.Check.Label>
                                                <Form.Check.Input
                                                    key={index}
                                                    type="radio"
                                                    checked={(!!state.record && state.record[field.id]) ? (state.record[field.id].id === recordRef.value) : false}
                                                    onChange={() => readOnly ? "" : handleAddRecordRef(recordRef, field.id)}>
                                                </Form.Check.Input>
                                                {/* <span className="form-check-sign"></span> */}
                                                {recordRef.label}
                                            </Form.Check.Label>
                                        </Form.Check>
                                    </div>
                                )
                            })
                        }
                        {
                            state.errors[field.id] && <div className={"error-message"}>
                                <FormattedMessage id={"Validation.selectOneOption"}/>
                            </div>
                        }
                    </Form.Group>

                );
            } else if (field.displayOption === RECORD_FIELD_RECORD_DISPLAY_OPTION.SEARCH_SELECT) {
                return (
                    <Form.Group key={index + field + 1} className={!readOnly ? "" : "disabled"}>
                        <Form.Label key={index + field + 2}>{field.name}</Form.Label>
                        <FormattedMessage id="Search">
                            {placeholder =>
                                <AsyncSelect
                                    isDisabled={readOnly}
                                    className={"react-select primary" + (state.errors[field.id] ? " has-errors" : "")}
                                    classNamePrefix="react-select"
                                    placeholder={placeholder}
                                    noOptionsMessage={({inputValue}) => !inputValue ?
                                        <FormattedMessage id={"SearchIn"} values={{param: field.name}}/> :
                                        <FormattedMessage id={"Placeholder.noResults"}/>}
                                    value={!!state.record && recordToSelectElement(state.record[field.id])}
                                    onChange={event => handleAddRecordRef(event, field.id)}
                                    loadOptions={(inputValue) => promiseOptions(inputValue, field.vid)}
                                    isInvalid={state.errors[field.id]}
                                    isClearable
                                />
                            }
                        </FormattedMessage>
                        {
                            state.errors[field.id] && <div className={"error-message"}>
                                <FormattedMessage id={"Validation.selectOneOption"}/>
                            </div>
                        }
                    </Form.Group>
                );
            }
        } else if (field.type === RECORD_FIELD_TYPE.RECORD_LIST) {
            if (field.displayOption === RECORD_FIELD_RECORD_LIST_DISPLAY_OPTION.MULTIPLE_CHOICE_LIST) {
                return (
                    <Form.Group key={index + field + 1} className={!readOnly ? "" : "disabled"}>
                        <Form.Label key={index + field + 2}>{field.name}</Form.Label>
                        {
                            !!state.record && !!state.record[field.id] && !!state.record[field.id].length &&
                            <TagsInput
                                value={state.record[field.id].map(tag => tag.name)}
                                onChange={(value) => handleTagsSelect(value, field.id)}
                                tagProps={{className: "react-tagsinput-tag tag-azure"}}
                            />
                        }
                        <FormattedMessage id="Placeholder.select">
                            {placeholder =>
                                <Select
                                    className={"react-select primary" + (state.errors[field.id] ? " has-errors" : "")}
                                    classNamePrefix="react-select"
                                    options={state.allRecordRefs[field.vid]?.filter(record => !state.record[field.id]?.find(r => r.id === record.value))}
                                    value={null}
                                    isDisabled={readOnly}
                                    isInvalid={state.errors[field.id]}
                                    onChange={event => handleAddRecordToRecordList(event, field.id)}
                                    placeholder={placeholder}
                                />
                            }
                        </FormattedMessage>
                        {
                            state.errors[field.id] && <div className={"error-message"}>
                                <FormattedMessage id={"Validation.selectAtLeastOneOption"}/>
                            </div>
                        }
                    </Form.Group>
                );
            } else if (field.displayOption === RECORD_FIELD_RECORD_LIST_DISPLAY_OPTION.CHECKBOX_GROUP) {
                return (
                    <Form.Group key={index + field + 1} className={!readOnly ? "" : "disabled"}>
                        <Form.Label key={index + field + 2}>{field.name}</Form.Label>
                        {
                            state.record[field.id] && state.record[field.id].filter(recordRefSelected => !state.allRecordRefs[field.vid]?.find(recordRef => recordRef.value === recordRefSelected.id)).map(recordRef => {
                                return (
                                    <div className="checkbox-container">
                                        <Form.Check className="checkbox-inline" key={index + field + 1}>
                                            <Form.Check.Label>
                                                <Form.Check.Input
                                                    type="checkbox"
                                                    checked={(!!state.record && state.record[field.id]) ? checkIfContains(state.record[field.id], {
                                                        "label": recordRef.name,
                                                        "value": recordRef.id
                                                    }) : false}
                                                    onChange={() => readOnly ? "" : handleAddRecordToRecordListCheckBox({
                                                        "label": recordRef.name,
                                                        "value": recordRef.id
                                                    }, field.id)}
                                                ></Form.Check.Input>
                                                <span className="form-check-sign"></span>{recordRef.name}
                                            </Form.Check.Label>
                                        </Form.Check>
                                    </div>
                                )
                            })
                        }
                        {
                            state.allRecordRefs[field.vid] && state.allRecordRefs[field.vid].map((recordRef, index) => {
                                return (
                                    <div className="checkbox-container">
                                        <Form.Check className="checkbox-inline" key={index + field + 1}>
                                            <Form.Check.Label>
                                                <Form.Check.Input
                                                    type="checkbox"
                                                    checked={(!!state.record && state.record[field.id]) ? checkIfContains(state.record[field.id], recordRef) : false}
                                                    onChange={() => readOnly ? "" : handleAddRecordToRecordListCheckBox(recordRef, field.id)}
                                                ></Form.Check.Input>
                                                <span className="form-check-sign"></span>{recordRef.label}
                                            </Form.Check.Label>
                                        </Form.Check>
                                    </div>
                                )
                            })
                        }
                        {
                            state.errors[field.id] && <div className={"error-message"}>
                                <FormattedMessage id={"Validation.selectAtLeastOneOption"}/>
                            </div>
                        }
                    </Form.Group>
                );
            } else if (field.displayOption === RECORD_FIELD_RECORD_LIST_DISPLAY_OPTION.SEARCH_SELECT) {
                return (
                    <Form.Group key={index + field + 1} className={!readOnly ? "" : "disabled"}>
                        <Form.Label key={index + field + 1}>{field.name}</Form.Label>
                        {
                            !!state.record && !!state.record[field.id] && !!state.record[field.id].length &&
                            <TagsInput
                                value={state.record[field.id].map(tag => tag.name)}
                                onChange={(value) => handleTagsSelect(value, field.id)}
                                tagProps={{className: "react-tagsinput-tag tag-azure"}}
                            />
                        }
                        <FormattedMessage id="Search">
                            {placeholder =>
                                <AsyncSelect
                                    className={"react-select primary" + (state.errors[field.id] ? " has-errors" : "")}
                                    classNamePrefix="react-select"
                                    placeholder={placeholder}
                                    noOptionsMessage={({inputValue}) => !inputValue ?
                                        <FormattedMessage id={"SearchIn"} values={{param: field.name}}/> :
                                        <FormattedMessage id={"Placeholder.noResults"}/>}
                                    isDisabled={readOnly}
                                    value={null}
                                    loadOptions={(inputValue) => promiseOptions(inputValue, field.vid, field.id)}
                                    isInvalid={state.errors[field.id]}
                                    onChange={event => handleAddRecordToRecordList(event, field.id)}
                                />
                            }
                        </FormattedMessage>
                        {
                            state.errors[field.id] && <div className={"error-message"}>
                                <FormattedMessage id={"Validation.selectAtLeastOneOption"}/>
                            </div>
                        }
                    </Form.Group>
                );
            }
        } else if (field.type === RECORD_FIELD_TYPE.ROLES_LIST) {
            return (
                <Form.Group key={index + field + 1} className={!readOnly ? "" : "disabled"}>
                    <Form.Label key={index + field + 1}>{field.name}</Form.Label>
                    {
                        !!state.record && !!state.record[field.id] && !!state.record[field.id].length &&
                        <TagsInput
                            value={state.record[field.id].map(tag => tag.name)}
                            onChange={(value) => handleTagsSelect(value, field.id)}
                            tagProps={{ className: "react-tagsinput-tag tag-azure" }}
                        />
                    }
                    <FormattedMessage id="Placeholder.select">
                        {placeholder =>
                            <Select
                                className={"react-select primary" + (state.errors[field.id] ? " has-errors" : "")}
                                classNamePrefix="react-select"
                                options={props.roles?.filter(role => !state.record[field.id]?.find(r => r.id === role.value))}
                                onChange={event => handleAddRecordToRecordList(event, field.id)}
                                placeholder={placeholder}
                            />
                        }
                    </FormattedMessage>
                    {
                        state.errors[field.id] && <div className={"error-message"}>
                            <FormattedMessage id={"Validation.selectAtLeastOneOption"}/>
                        </div>
                    }
                </Form.Group>
            )
        } else if (field.type === RECORD_FIELD_TYPE.SINGLE_CHOICE) {
            return (
                <Form.Group key={index + field + 1} className={!readOnly ? "" : "disabled"}>
                    <Form.Label key={index + field + 1}>{field.name}</Form.Label>
                    <FormattedMessage id="Placeholder.select">
                        {placeholder =>
                            <Select
                                key={index + field + 2}
                                name={field.id}
                                isDisabled={readOnly}
                                className={"react-select primary" + (state.errors[field.id] ? " has-errors" : "")}
                                classNamePrefix="react-select"
                                onChange={event => {
                                    event.target = {}
                                    event.target.name = field.id
                                    handleInputChange(event, field.type)
                                }}
                                value={field.options?.filter(option => state.record && option === state.record[field.id]).map(option => {
                                    return {
                                        value: option,
                                        label: option
                                    }
                                })}
                                options={field.options?.map(option => {
                                    return {
                                        value: option,
                                        label: option
                                    }
                                })}
                                isInvalid={state.errors[field.id]}
                                placeholder={placeholder}
                            />
                        }
                    </FormattedMessage>
                    {
                        state.errors[field.id] && <div className={"error-message"}>
                            <FormattedMessage id={"Validation.selectOneOption"}/>
                        </div>
                    }
                </Form.Group>
            );
        } else if (field.type === RECORD_FIELD_TYPE.MULTIPLE_CHOICE) {
            return (
                <Form.Group key={index + field + 1} className={!readOnly ? "" : "disabled"}>
                    <Form.Label key={index + field + 1}>{field.name}</Form.Label>
                    <TagsInput
                        value={field.options?.filter(option => state.record && state.record[field.id] && state.record[field.id].includes(option))}
                        onChange={(value) => handleMultipleChoiceSelect(value, field.id)}
                        tagProps={{className: "react-tagsinput-tag tag-azure"}}
                    />
                    <FormattedMessage id="Placeholder.select">
                        {placeholder =>
                            <Select
                                key={index + field + 2}
                                className={"react-select primary" + (state.errors[field.id] ? " has-errors" : "")}
                                classNamePrefix="react-select"
                                options={field.options?.map(option => {
                                    return {
                                        value: option,
                                        label: option
                                    }
                                }).filter(option => !state.record[field.id]?.find(r => r === option.value))}
                                onChange={event => {
                                    event.target = {}
                                    event.target.name = field.id
                                    handleInputChange(event, field.type)
                                }}
                                value={null}
                                isDisabled={readOnly}
                                isInvalid={state.errors[field.id]}
                                placeholder={placeholder}
                            />
                        }
                    </FormattedMessage>
                    {
                        state.errors[field.id] && <div className={"error-message"}>
                            <FormattedMessage id={"Validation.selectAtLeastOneOption"}/>
                        </div>
                    }
                </Form.Group>
            );
        } else if (field.type === RECORD_FIELD_TYPE.IMAGE) {
            return (
                <Form.Group key={index + field} className={!readOnly ? "" : "disabled"}>

                    <Form.Label key={index + field + 1}>{field.name}</Form.Label>
                    <div className={"upload-file-container"+(state.errors[field.id]?" has-errors":"")} onClick={() => clickInputFile(imageInputsFileRef.current[index])}>

                        <Form.Control
                            className="hidden-input-file"
                            key={index + field + 2}
                            onChange={onSelectImage}
                            type="file"
                            ref={el => imageInputsFileRef.current[index] = el}
                            name={field.id}/>


                        {!!!state.selectedFiles[field.id] &&
                            <>
                                <div className="row upload-image-icon">
                                    {state.record[field.id]?
                                        <>
                                            <img src={ClientConfig.URL_RESOURCE_SERVER + ClientConfig.NAMESPACE_RECORDS + "images/recordviews/" + props.vid + "/image/" + state.record[field.id]} alt={"."}/>
                                            <span className="edit-btn">{editIcon}</span></>
                                        : uploadImageIcon
                                    }
                                </div>
                                { !!! state.record[field.id] && <div className="row upload-text-and-btn">
                                    <FormattedMessage id="DropYourImageHereOr"/>
                                    {/*<button className="btn btn-neutral">*/}
                                    {/*    <FormattedMessage id="Browse"/>*/}
                                    {/*</button>*/}

                                </div>}
                            </>
                        }
                        {state.selectedFiles[field.id] &&
                            <>
                                <img src={state.selectedFiles[field.id]?.url} alt="selected image"/>
                                <div className="selected-file-name">
                                    {state.selectedFiles[field.id]?.file?.name}
                                </div>
                                <span className="edit-btn">{editIcon}</span>
                            </>
                        }
                    </div>
                    {state.errors[field.id] && <div className={"error-message"}>
                        <FormattedMessage id={"Validation.cannotBeEmpty"}/>
                    </div> }
                </Form.Group>
            );
        } else if (field.type === RECORD_FIELD_TYPE.FILE) {
            return (
                <Form.Group key={index + field} className={!readOnly ? "" : "disabled"}>
                    <Form.Label key={index + field + 1}>{field.name}</Form.Label>
                    <div className={"upload-file-container"+(state.errors[field.id]?" has-errors":"")} onClick={() => clickInputFile(imageInputsFileRef.current[index])}>

                        <Form.Control
                            className="hidden-input-file"
                            key={index + field + 2}
                            onChange={onSelectFile}
                            type="file"
                            ref={el => imageInputsFileRef.current[index] = el}
                            name={field.id}/>


                        {!!!state.selectedFiles[field.id] &&
                            <>
                                {state.record[field.id]?
                                    <div className="file-and-text-container">
                                        <div className="file-container">
                                            {fileIcon}
                                            <i className="delete-file-icon" onClick={(e)=>{e.stopPropagation();deleteExistentFile(field.id)}}>{iconXthinner}</i>
                                        </div>
                                        <div className="text-container">{state.record[field.id]?.originalFileName}</div>
                                    </div>
                                    : uploadFileIcon
                                }
                                { !!! state.record[field.id] && <div className="row upload-text-and-btn">
                                    <FormattedMessage id="DropYourFileHereOr"/>
                                </div>}
                            </>
                        }
                        {state.selectedFiles[field.id] &&
                            <div className="file-and-text-container">
                                <div className="file-container">
                                    {fileIcon}
                                    <i className="delete-file-icon" onClick={(e)=>{e.stopPropagation();deleteSelectedFile(field.id)}}>{iconXthinner}</i>

                                </div>
                                <div className="selected-file-name">
                                    {state.selectedFiles[field.id]?.file?.name}
                                </div>
                            </div>
                        }
                    </div>
                    {
                        state.errors[field.id] && <div className={"error-message"}>
                            <FormattedMessage id={"Validation.cannotBeEmpty"}/>
                        </div>
                    }
                </Form.Group>
            );
        } else if (field.type === RECORD_FIELD_TYPE.FILE_LIST) {
            return (
                <Form.Group key={index + field} className={!readOnly ? "multi" : "multi disabled"}>
                    <Form.Label key={index + field + 1}>{field.name}</Form.Label>
                    <div className={"upload-file-container"+(state.errors[field.id]?" has-errors":"")} onClick={() => clickInputFile(imageInputsFileRef.current[index])}>

                        <Form.Control
                            className="hidden-input-file"
                            key={index + field + 2}
                            onChange={onSelectMultipleFiles}
                            type="file"
                            multiple
                            ref={el => imageInputsFileRef.current[index] = el}
                            name={field.id}/>


                        {!!!state.selectedFiles[field.id] &&
                            <>
                        <span className={!state.record[field.id] ? "file-container add-new centered": "file-container add-new"}>
                            {uploadFileIcon}
                            { !state.record[field.id] && <div className="row upload-text-and-btn">
                                <FormattedMessage id="DropYourFilesHereOr"/>
                            </div>}
                        </span>
                                {state.record[field.id] &&
                                    state.record[field.id].map((file,index)=>{
                                        return  <div className="file-and-text-container" key={file?.fileName+index}>
                                            <div className="file-container">
                                                {fileIcon}
                                                <i className="delete-file-icon" onClick={(e)=>{e.stopPropagation();deleteExistentFile(field.id,index)}}>{iconXthinner}</i>
                                            </div>
                                            <div className="text-container">{file?.originalFileName}</div>
                                        </div>
                                    })
                                }

                            </>
                        }
                        {state.selectedFiles[field.id] &&
                            <>
                                {uploadFileIcon}
                                {state.record[field.id] &&
                                    state.record[field.id].map((file,index)=>{
                                        return  <div className="file-and-text-container" key={file?.fileName+index}>
                                            <div className="file-container">
                                                {fileIcon}
                                                <i className="delete-file-icon" onClick={(e)=>{e.stopPropagation();deleteExistentFile(field.id,index)}}>{iconXthinner}</i>
                                            </div>
                                            <div>{file?.originalFileName}</div>
                                        </div>
                                    })
                                }
                                {state.selectedFiles[field.id].map((file,index)=>{
                                    return <div className="file-and-text-container" key={index}>
                                        <div className="file-container">
                                            {fileIcon}
                                            <i className="delete-file-icon" onClick={(e)=>{e.stopPropagation();deleteSelectedFile(field.id,index)}}>{iconXthinner}</i>
                                        </div>
                                        <div className="selected-file-name">
                                            {file.file?.name}
                                        </div></div>
                                })}
                            </>
                        }
                    </div>
                    {
                        state.errors[field.id] && <div className={"error-message"}>
                            <FormattedMessage id={"Validation.cannotBeEmpty"}/>
                        </div>
                    }
                </Form.Group>
            );
        }
    }

    const handleMultipleChoiceSelect = async (values, name) => {
        let newErrors = {...state.errors};
        if (!!state.errors[name]) {
            newErrors = {
                ...newErrors,
                [name]: false,
            };
        }
        const newValues = values.map(value => {
            return state.record[name].find(option => option === value);
        });

        setState({
            ...state,
            record: {
                ...state.record,
                [name]: newValues,
            },
            errors: newErrors
        });
    }

    const handleAddRecordRef = (recordRef, name) => {
        let newErrors = {...state.errors};
        if (!!state.errors[name]) {
            newErrors = {
                ...newErrors,
                [name]: false,
            };
        }
        let newRecord;
        if(!recordRef){
            newRecord = {
                ...state.record,
                [name]: ""
            };
        } else {
            newRecord = {
                ...state.record,
                [name]: {
                    "id": recordRef.value,
                    "name": recordRef.label,
                }
            };
        }

        setState({
            ...state,
            record: newRecord,
            errors: newErrors
        });
    }

    const handleAddRecordToRecordList = (recordRef, name) => {
        let newErrors = {...state.errors};
        if (!!state.errors[name]) {
            newErrors = {
                ...newErrors,
                [name]: false,
            };
        }
        const newRecord = {
            "id": recordRef.value,
            "name": recordRef.label,
        }
        if (state.record && state.record[name]) {
            const recordsList = state.record[name];
            setState({
                ...state,
                record: {
                    ...state.record,
                    [name]: [...recordsList, newRecord]
                },
                errors: newErrors
            });
        } else {
            setState({
                ...state,
                record: {
                    ...state.record,
                    [name]: [newRecord]
                },
                errors: newErrors
            })
        }

    }

    const handleTagsSelect = (values, name) => {
        const newValues = values.map(value => {
            return state.record[name].find(tag => tag.name === value);
        });
        setState({
            ...state,
            record: {
                ...state.record,
                [name]: newValues
            }
        });
    }

    const getAllRecordsWithNameContains = async (nameContains, viewId,fieldId) => {
        return getAllRecordsWithNameContainsHandler(nameContains, viewId).then(response => {
            if (response.ok) {
                if(fieldId){
                    return response.data?.filter(record=>!state.record[fieldId]?.find(r=>r.id===record.value));
                } else{
                    return response.data;
                }
            }
        })
    }

    const promiseOptions = (inputValue, fieldVid,fieldId) =>
        new Promise(resolve => {
            resolve(getAllRecordsWithNameContains(inputValue, fieldVid,fieldId));
        });

    const saveRecord = () => {
        let newRecord = {...state.record}
        props.fields?.forEach(field => {
            // if(state.record && field.type === "Record" && field.displayOption === "FormLink" && state.linkRecordToForm[field.id] === false) {
            //     newRecord[field.id] = null
            // }
            if (state.record && field.type === RECORD_FIELD_TYPE.BOOLEAN && field.mode === RECORD_FIELD_MODE.WRITE && !!!state.record[field.id]) {
                newRecord[field.id] = false
            } else if (state.record && field.id === "createdBy" || field.id === "createdDate" || field.id === "modifiedBy" || field.id === "modifiedDate") {
                delete newRecord[field.id];
            }
            if(state.record&& field.type===RECORD_FIELD_TYPE.IMAGE && state.selectedFiles[field.id]){
                newRecord[field.id]="";
            } else if(state.record&& field.type===RECORD_FIELD_TYPE.FILE && state.selectedFiles[field.id]){
                newRecord[field.id]={
                    fileName:"",
                    originalFileName:""
                };
            } else if(state.record&& field.type===RECORD_FIELD_TYPE.FILE_LIST && state.selectedFiles[field.id] && !state.record[field.id]?.length){
                newRecord[field.id]=[{
                    fileName:"",
                    originalFileName:""
                }];
            }
        });
        const [newErrors, existErrors] = Validations.addMemberValidations(props.fields, newRecord,state.selectedFiles, {...state.errors});
        setState({
            ...state,
            errors: newErrors
        });
        if (existErrors) {
            let options=getNotifyErrorOptions("bc");
            props.notificationAlertRef.current.notificationAlert(options);

            return;
        }
        props.setSaveDisabled(true);
        let selectedFilesObj = {};

        for (const property in state.selectedFiles) {
            if(Array.isArray(state.selectedFiles[property])){
                selectedFilesObj[property] = state.selectedFiles[property].map(file=>file.file);
            } else{
                selectedFilesObj[property] = state.selectedFiles[property].file;
            }
        }
        props.saveRecord(newRecord, selectedFilesObj);
    }

    return (
        <Container fluid>
            <Row>
                <Col>
                    <CrossaPageTemplate
                        title={""}
                        hasBackBtn={true}
                        buttonVariant="success"
                        saveDisabled={props.saveDisabled}
                        actionButton={saveRecord}

                    >
                        <Cropper
                            aspectRatio={state.isProfileImage ? CROPPER_ASPECT_RATIO.SQUARE : CROPPER_ASPECT_RATIO.RECTANGULAR}
                            show={state.cropperShow}
                            onHide={() => setState({...state,cropperShow:false})}
                            image={state.selectedImageForCropper?.picture}
                            setCroppedImage={setCroppedImage}
                        />
                        {
                            props.fields.map((field, index) => {
                                return fieldModeToFormInput(field, index, props.action);
                            })
                        }
                    </CrossaPageTemplate>

                    <Footer
                        saveDisabled={props.saveDisabled}
                        save={saveRecord}
                        cancel={() => history.goBack()}
                        isEnable={true} />
                </Col>
            </Row>
        </Container>
    );
}

export default SaveMemberForm;
