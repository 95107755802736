import AllRecordsTypesPage from "views/workspaceAdmin/recordType/AllRecordTypesPage.js";
import SaveRecordTypePage from "views/workspaceAdmin/recordType/SaveRecordTypePage.js"
import RulesRecordTypePage from "views/workspaceAdmin/recordType/RulesRecordTypePage";
import AddRuleRecordTypePage from "views/workspaceAdmin/recordType/AddRuleRecordTypePage";
import EditRuleRecordTypePage from "views/workspaceAdmin/recordType/EditRuleRecordTypePage";

import AllViewsPage from "views/workspaceAdmin/view/AllViewsPage.js";
import AllMenusPage from "views/workspaceAdmin/menu/AllMenusPage.js";
import SaveMenuPage from "views/workspaceAdmin/menu/SaveMenuPage.js"
import AllWorkspaceRoles from "views/workspaceAdmin/workspaceRole/AllWorkspaceRoles.js";
import AllMembersPage from "views/workspaceAdmin/members/AllMembersPage.js";
import SaveMember from "views/workspaceAdmin/members/SaveMember.js";
import LoginPage from "views/LoginPage.js";
import Home from "views/workspaceAdmin/Home.js";
import PaymentDetails from "views/workspaceAdmin/settings/PaymentDetails.js";
import {FormattedMessage} from "react-intl";
import SettingsPage from "../views/workspaceAdmin/settings/SettingsPage";

const workspaceAdminRoutes = [
    {
        path: "/:wid/home",
        name: "Home",
        icon: null,
        component: Home,
        layout: "/workspace-admin",
    },

    {
        path: "/:wid/record-types",
        name: <FormattedMessage id="Sidebar.recordTypes"/>,
        icon: null,

        component: AllRecordsTypesPage,
        layout: "/workspace-admin",
        isVisible: true,
    },
    {
        path: "/:wid/record-types/add",
        name: "SaveRecordTypePage",
        icon: null,
        component: SaveRecordTypePage,
        layout: "/workspace-admin",
    },
    {
        path: "/:wid/record-types/:rid/edit",
        name: "EditRecordType",
        icon: null,
        component: SaveRecordTypePage,
        layout: "/workspace-admin",
    },
    {
        path: "/:wid/record-types/:rid/rules",
        name: "RulesRecordTypePage",
        icon: null,
        component: RulesRecordTypePage,
        layout: "/workspace-admin",
    },
    {
        path: "/:wid/record-types/:rid/rules/add",
        name: "AddRuleRecordTypePage",
        icon: null,
        component: AddRuleRecordTypePage,
        layout: "/workspace-admin",
    },
    {
        path: "/:wid/record-types/:rid/rules/:ruleid/edit",
        name: "EditRuleRecordTypePage",
        icon: null,
        component: EditRuleRecordTypePage,
        layout: "/workspace-admin",
    },
    {
        path: "/:wid/views",
        name: <FormattedMessage id="Sidebar.views"/>,
        icon: null,
        component: AllViewsPage,
        layout: "/workspace-admin",
        isVisible: true,
    },
    {
        path: "/:wid/menus",
        name: <FormattedMessage id="Sidebar.menus"/>,
        icon: null,
        component: AllMenusPage,
        layout: "/workspace-admin",
        isVisible: true,
    },
    {
        path: "/:wid/menus/add",
        name: "AddMenuPage",
        icon: null,
        component: SaveMenuPage,
        layout: "/workspace-admin",
    },
    {
        path: "/:wid/menus/:mid/edit",
        name: "EditMenuPage",
        icon: null,
        component: SaveMenuPage,
        layout: "/workspace-admin",
    },
    {
        path: "/:wid/members",
        name: <FormattedMessage id="Sidebar.members"/>,
        icon: null,
        component: AllMembersPage,
        layout: "/workspace-admin",
        isVisible: true,
    },
    {
        path: "/:wid/members/add",
        name: "AddMember",
        icon: null,
        component: SaveMember,
        layout: "/workspace-admin",
    },

    {
        path: "/:wid/members/:mid/edit",
        name: "EditMember",
        icon: null,
        component: SaveMember,
        layout: "/workspace-admin",
    },

    {
        path: "/:wid/roles",
        name: <FormattedMessage id="Sidebar.roles"/>,
        icon: null,
        component: AllWorkspaceRoles,
        layout: "/workspace-admin",
        isVisible: true,
    },
    {
        path: "/:wid/settings",
        name: <FormattedMessage id="Sidebar.settings"/>,
        component: SettingsPage,
        layout: "/workspace-admin",
        isVisible: true,
        icon: null
    },
    {
        collapse: true,
        path: "/pages",
        name: "Pages",
        state: "openPages",
        icon: null,
        views: [
            {
                path: "/login-page",
                layout: "/auth",
                name: "Login Page",
                mini: "LP",
                component: LoginPage,
            }
        ],
    },
];

export default workspaceAdminRoutes;
