import React from "react";
import {useHistory} from "react-router-dom";
import {Badge, Button, Card, Col, OverlayTrigger, Row, Tooltip,} from "react-bootstrap";
import {FormattedMessage} from 'react-intl';
import {editIcon, trashIcon} from "../../../../components/Icons";

function MenuCard(props) {
    const history = useHistory();

    const deleteMenu = () => {
        props.deleteMenu(props.menu.id);
    }

    const canNotDeleteMenu = () => {
        props.canNotDeleteMenu();
    }

    const updateMenu = () => {
        props.updateMenu(props.menu.id);
    }

    const gotoAddRolePage = () => {
        history.push({
            pathname: "/workspace-admin/" + props.wid + "/roles",
            state: {
                "mid": props.menu.id,
                "formCurrentStyle": "open"
            },
        });


    }
    return (
        <>
            {props.menu.role != null ?
                <Card className="menu-card col-md-5">
                    <Card.Body>
                        <Card.Title as="h3">{props.menu.name}</Card.Title>
                        {props.menu.role.length === 0 ?
                            <div>
                                <Card.Title as="h4"><FormattedMessage id={"Validation.NoRoles"}/></Card.Title>
                            </div>
                            :
                            <div className="badge-container">
                                <Card.Subtitle className="badge-label inline" as="h6"><FormattedMessage id="Roles"/>: </Card.Subtitle>
                                <Badge variant="secondary">{props.menu.role.name}&nbsp;</Badge>
                            </div>
                        }
                        <div className="row actions hidden">

                                <OverlayTrigger
                                    onClick={(e) => e.preventDefault()}
                                    overlay={
                                        <Tooltip>
                                            <FormattedMessage id="Button.editMenu" />
                                        </Tooltip>
                                    }
                                >
                                    <Button
                                        size="sm"
                                        className="text-success btn-link"
                                        onClick={updateMenu}
                                    >
                                        <span>{editIcon}</span>
                                    </Button>
                                </OverlayTrigger>



                                <OverlayTrigger
                                    onClick={(e) => e.preventDefault()}
                                    overlay={
                                        <Tooltip>
                                            <FormattedMessage id="Button.delete"/>
                                        </Tooltip>
                                    }
                                >
                                    <Button
                                        variant="danger"
                                        size="sm"
                                        className="btn-link remove text-danger"
                                        onClick={canNotDeleteMenu}
                                    >
                                        <i>{trashIcon}</i>

                                    </Button>
                                </OverlayTrigger>

                        </div>
                    </Card.Body>
                </Card>
                :
                <>
                    <Card className="menu-card col-md-5">
                        <Card.Body>
                            <Card.Title as="h3">{props.menu.name}</Card.Title>
                            <Row>
                                <Col>
                                    <Card.Title as="h5"><FormattedMessage id="Validation.assignRoleToMenu"/>
                                    </Card.Title>

                                </Col>
                            </Row>
                            <Row>
                                <Col className="mt-1">
                                    <Button
                                        variant="default"
                                        onClick={gotoAddRolePage}>
                                        <FormattedMessage id="Button.addRole" />
                                        <i className="fa fa-arrow-right" aria-hidden="true"></i>
                                    </Button>
                                </Col>
                            </Row>
                            <div className="row actions hidden">

                                    <OverlayTrigger
                                        onClick={(e) => e.preventDefault()}
                                        overlay={
                                            <Tooltip>
                                                <FormattedMessage id="Button.editNoParams"/>
                                            </Tooltip>
                                        }
                                    >
                                        <Button
                                            variant="warning"
                                            size="sm"
                                            className="text-success btn-link"
                                            onClick={updateMenu}
                                        >
                                            <span>{editIcon}</span>
                                        </Button>
                                    </OverlayTrigger>


                                    <OverlayTrigger
                                        onClick={(e) => e.preventDefault()}
                                        overlay={
                                            <Tooltip>
                                                <FormattedMessage id="Button.delete"/>
                                            </Tooltip>
                                        }
                                    >
                                        <Button
                                            variant="danger"
                                            size="sm"
                                            className="btn-link remove text-danger"
                                            onClick={deleteMenu}
                                        >
                                            <span>{trashIcon}</span>
                                        </Button>
                                    </OverlayTrigger>

                            </div>
                        </Card.Body>
                    </Card>
                </>
            }
        </>
    );
}

export default MenuCard;
