import React, { useEffect, useRef, useState } from "react";
import Select from "react-select";
import TagsInput from "views/workspaceMember/menuDisplay/components/TagsInput.js";
import CrossaPageTemplate from "components/CrossaPageTemplate.js";
import * as Validations from "validations/workspaceAdmin/recordType/RecordTypeValidations.js";
import { FormattedMessage } from 'react-intl';
import { Button, Col, Form, Row, } from "react-bootstrap";
import currencies from "currency-codes";
import ColorPickerIcon from '../../../assets/img/ColorPicker.png';
import NonColorIcon from '../../../assets/img/NonColor.png';
import { addIconFullBg } from "../../../components/Icons";
import { getAllViewsInfoHandler } from "handlers/workspaceAdminHandlers/recordTypeHandlers/SaveRecordTypeHandlers";
import {getNotifyErrorOptions} from "../../../util/Util";
import {RECORD_FIELD_TYPE, RECORD_TYPE_TYPE, VALIDATIONS} from "../../../util/Constants";

function RecordFieldForm(props) {

    const [state, setState] = useState({
        options: [],
        fieldTypes: [],
        singleSelect: { "value": "", "label": "" },
        recordField: props.recordField,//{"name": "", "type": "", "validation":{"mandatory": false}});
        errors: {
            "name": false,
            "unavailableName": false,
            "type": false,
        },
        recordViews: [],
        recordViewsOptions: [],
        displayRecordView: null,
        isRecordOptionSelected: false,
        optionsColors: [],
        colourPopupIsVisible: false,
        optionPickedForColourChange: null
    });

    const allCurrencies = currencies.codes().map(currency => {
        return {
            label: currency,
            value: currency
        }
    });

    const colours = [
        "#E4437D",
        "#87C860",
        "#E89D41",
        "#735CB0",
        "#0093D7",
        "#625B71"
    ];


    const currentStyleRef = useRef(null);
    const fieldTypesRef = useRef(null);
    const recordFieldRef = useRef(null);

    /**
 * props.currentStyle change => clear form's inputs
 */

    useEffect(async () => {

        let newState = { ...state };

        if (currentStyleRef.current !== props.currentStyle) {
            if (props.currentStyle && props.currentStyle.width === "0px") {
                newState = {
                    ...newState,
                    recordField: { "name": "", "type": "", "validation": { "mandatory": false } },
                    singleSelect: { "label": "", "value": "" },
                    isRecordOptionSelected: false,
                    displayRecordView: null,
                    options: [],
                    optionsColors: []
                };
            }
            currentStyleRef.current = props.currentStyle;
        }

        if (fieldTypesRef !== props.fieldTypes || recordFieldRef !== props.recordField) {
            let fieldTypes = [...props.fieldTypes];

            // if (!props.recordField.name) {
            //     newState = {
            //         ...newState,
            //         fieldTypes: fieldTypes
            //     };
            //     return;
            // }

            const currentOption = props.fieldTypes.find(option => option.value === props.recordField.type);
            let options;
            let optionsColors;

            if (props.recordField.type === RECORD_FIELD_TYPE.SINGLE_CHOICE) {
                options = props.recordField.options;
                optionsColors = props.recordField.optionsColors;
            } else if (props.recordField.type === RECORD_FIELD_TYPE.MULTIPLE_CHOICE) {
                options = props.recordField.options;
                optionsColors=[];
            } else {
                options = []
                optionsColors = [];
            }

            let showViewSelect = false;
            let allViewsInfo = {};

            if (props.recordField.type === RECORD_FIELD_TYPE.RECORD || props.recordField.type === RECORD_FIELD_TYPE.RECORD_LIST) {
                showViewSelect = true;
                allViewsInfo = await getAllViewsInfo();
            }

            newState = {
                ...newState,
                fieldTypes: fieldTypes,
                singleSelect: { "label": currentOption?.label, "value": currentOption?.value },
                recordField: props.recordField,
                isRecordOptionSelected: showViewSelect,
                options: options,
                optionsColors: optionsColors,
                errors: {
                    "name": false,
                    "unavailableName": false,
                    "type": false,
                },
                ...allViewsInfo
            };

            fieldTypesRef.current = props.fieldTypes;
            recordFieldRef.current = props.recordField;
        }

        setState(newState);
    }, [props.currentStyle, props.fieldTypes, props.recordField]);

    const handleInput = event => {
        let newErrors = { ...state.errors };

        if (event.target.name === "name" && state.errors["unavailableName"] === true) {
            newErrors = {
                ...newErrors,
                "unavailableName": false,
            };
        }
        if (!!state.errors[event.target.name]) {
            newErrors = {
                ...newErrors,
                [event.target.name]: false,
            };
        }
        setState({
            ...state,
            recordField: {
                ...state.recordField,
                [event.target.name]: event.target.value
            },
            errors: newErrors
        });
    }

    const handleColourChange = (index, colour, e) => {
        e.stopPropagation();
        let newOptionsColors = [...state.optionsColors];
        newOptionsColors[index] = colour;

        let options = toggleColourPopupVisibility(index);
        setState({
            ...state,
            optionsColors: newOptionsColors,
            recordField: {
                ...state.recordField,
                optionsColors: newOptionsColors
            },
            ...options
        });
    }

    const toggleColourPopupVisibility = (index) => {
        if (state.optionPickedForColourChange === null) {
            return {
                colourPopupIsVisible: !state.colourPopupIsVisible,
                optionPickedForColourChange: index
            }
        }
        else if (state.optionPickedForColourChange === index) {
            return {
                colourPopupIsVisible: !state.colourPopupIsVisible,
                optionPickedForColourChange: null
            }
        }
        else {
            return {
                optionPickedForColourChange: index
            }
        }
    }

    const addRecordField = () => {
        let newRecordField = { ...state.recordField }
        if (!!!newRecordField.displayOption) {
            delete newRecordField.displayOption;
        }
        if (state.displayRecordView) {
            newRecordField = {
                ...newRecordField,
                "vid": state.displayRecordView.value
            };
        }

        const [newErrors, existErrors] = Validations.addRecordFieldValidations(newRecordField, props.allRecordFields, { ...state.errors });
        if (existErrors) {
            let options=getNotifyErrorOptions("bc");
            props.notificationAlertRef.current.notificationAlert(options);

            setState({
                ...state,
                errors: newErrors
            });
            return;
        }

        // return;
        props.addRecordField(newRecordField);
        setState({
            ...state,
            recordField: { "name": "", "type": "", "validation": { "mandatory": false } },
            singleSelect: { "label": "", "value": "" },
            isRecordOptionSelected: false,
            displayRecordView: null,
            options: [],
            optionsColors: []
        });
    }

    const getDummyComponentByName = (name, recordFieldType) => {
        switch (name) {
            case "ComboBox":
                return (
                    <div style={{ width: "200px", marginLeft: "20px", marginTop: "5px", marginBottom: "20px" }}>
                        <Select
                            key={1}
                            defaultValue={{ value: 1, label: <FormattedMessage id="Example" values={{param:1}} />}}
                            options={[{ value: 1, label: <FormattedMessage id="Example" values={{param:1}} /> }, { value: 2, label: <FormattedMessage id="Example" values={{param:2}} /> }]}
                            isDisabled={true}
                        />
                    </div>
                )
            case "RadioButtonGroup":
                return (
                    <div style={{ width: "200px", marginLeft: "20px", marginTop: "5px", marginBottom: "20px" }}>
                        <Form.Check
                            key={2}
                            type="radio"
                            label=<FormattedMessage id="Example" values={{param:1}} />
                            disabled={true}
                        />
                        <div style={{ width: "10px" }} />
                        <Form.Check
                            key={3}
                            type="radio"
                            label=<FormattedMessage id="Example" values={{param:2}} />
                            disabled={true}
                        />
                        <Form.Check
                            key={4}
                            type="radio"
                            label=<FormattedMessage id="Example" values={{param:3}} />
                            disabled={true}
                        />
                    </div>
                )
            case "SearchSelect":
                return (
                    <div style={{ width: "200px", marginLeft: "20px", marginTop: "5px", marginBottom: "20px" }}>
                        <FormattedMessage id="Placeholder.select">
                            {placeholder=>
                                <Select
                                    key={4}
                                    placeholder={placeholder}
                                    options={[{ value: 1, label: <FormattedMessage id="Example" values={{param:1}} /> }, { value: 2, label: <FormattedMessage id="Example" values={{param:1}} /> }]}
                                    isDisabled={true}
                                />
                            }
                        </FormattedMessage>
                        <TagsInput
                            key={5}
                            value={recordFieldType === RECORD_FIELD_TYPE.RECORD || recordFieldType === RECORD_FIELD_TYPE.SINGLE_CHOICE ? [<FormattedMessage id="Example" values={{param:1}} />] : [<FormattedMessage id="Example" values={{param:1}} />, <FormattedMessage id="Example" values={{param:2}} />]}
                            tagProps={{ className: "react-tagsinput-tag tag-azure" }}
                            onChange={() => { }}
                        />
                    </div>
                )
            case "CheckboxGroup":
                return (
                    <Form.Group key={6}>
                        {
                            [1, 2, 3].map((element, index) => {
                                return (
                                    <Form.Check key={index}>
                                        <Form.Check.Label>
                                            <Form.Check.Input
                                                defaultValue="option1"
                                                type="checkbox"
                                                disabled={true}
                                            ></Form.Check.Input>
                                            <span className="form-check-sign"></span>{<FormattedMessage id="Example" values={{param:element}} />}
                                        </Form.Check.Label>
                                    </Form.Check>
                                )
                            })
                        }
                    </Form.Group>
                )
            case "MultipleChoiceList":
                return (
                    <div style={{ width: "200px", marginLeft: "20px", marginTop: "5px", marginBottom: "20px" }}>
                        <Select
                            key={7}
                            options={[{ value: 1, label: <FormattedMessage id="Example" values={{param:1}} /> }, { value: 2, label: <FormattedMessage id="Example" values={{param:2}} /> }]}
                            isMulti
                            defaultValue={[{ value: 1, label: <FormattedMessage id="Example" values={{param:1}} /> }]}
                            isDisabled={true}
                        />
                    </div>
                );
        }
    }

    const displayRadioButton = (option, index) => {
        const isChecked = state.recordField.displayOption === option.value;
        switch (state.singleSelect.value) {
            case RECORD_FIELD_TYPE.RECORD_LIST:
            case RECORD_FIELD_TYPE.RECORD:
                return (
                    <div>
                        <Form.Check
                            key={index + option.value}
                            type="radio"
                            id={index}
                            label={option.label}
                            checked={isChecked}
                            onChange={() => {
                                setState({
                                    ...state,
                                    recordField: {
                                        ...state.recordField,
                                        "displayOption": option.value,
                                    }
                                });
                            }}
                        />
                        <div>
                            {
                                getDummyComponentByName(option.value, state.singleSelect.value)
                            }
                        </div>
                    </div>
                )
            default:
                return (
                    <Form.Check
                        key={index + option.value}
                        type="radio"
                        id={index}
                        label={option.label}
                        checked={isChecked}
                        onChange={() => {
                            setState({
                                ...state,
                                recordField: {
                                    ...state.recordField,
                                    "displayOption": option.value,
                                }
                            });
                        }}
                    />
                )
        }
    }

    const getAllViewsInfo = async () => {
        return getAllViewsInfoHandler(props.wid).then(response => {
            if (response.ok) {
                return response.data;
            } else {
                return {};
            }
        });
    }

    const handleTypeChange = async option => {
        let options = [];
        let optionsColors = [];
        let isRecordOptionSelected = false;
        let viewsInfo = {};
        let displayRecordView = null;
        if (option.value === RECORD_FIELD_TYPE.RECORD || option.value === RECORD_FIELD_TYPE.RECORD_LIST) {
            isRecordOptionSelected = true;
            if (state.recordViewsOptions.length === 0) {
                viewsInfo = await getAllViewsInfo();
            }
        } else if (option.value === RECORD_FIELD_TYPE.SINGLE_CHOICE || option.value === RECORD_FIELD_TYPE.MULTIPLE_CHOICE) {
            isRecordOptionSelected = false;
            displayRecordView = null;
        }
        setState({
            ...state,
            recordField: {
                ...state.recordField,
                "displayOption": "",
                "type": option.value,
                "currency": null,
                "options": null
            },
            singleSelect: option,
            errors: {
                ...state.errors.name,
                "type": false
            },
            options: options,
            optionsColors: optionsColors,
            isRecordOptionSelected: isRecordOptionSelected,
            displayRecordView: displayRecordView,
            ...viewsInfo
        });
    }

    const handleViewChange = viewOption => {

        setState({
            ...state,
            displayRecordView: viewOption,
            recordField: {
                ...state.recordField,
                "vid": viewOption,
            },
            errors: {
                ...state.errors,
                "view": false
            }
        });
    }

    const toggleMandatoryValidation = () => {
        if (!!!state.recordField.validation) {
            setState({
                ...state,
                recordField: {
                    ...state.recordField,
                    "validation": {
                        "mandatory": true,
                    }
                }
            });
            return;
        }

        setState({
            ...state,
            recordField: {
                ...state.recordField,
                "validation": {
                    "mandatory": !state.recordField.validation.mandatory,
                }
            }
        });
    }

    const addOption = () => {
        let newOptions = state.options ? [...state.options] : [];
        let newOptionsColors = state.optionsColors ? [...state.optionsColors] : [];
        newOptions.push("");
        newOptionsColors.push("");
        setState({
            ...state,
            options: newOptions,
            optionsColors: newOptionsColors,
            recordField: {
                ...state.recordField,
                optionsColors: newOptionsColors
            }
        });
    }

    const removeOption = (index) => {
        let newOptions = [...state.options];
        let newOptionsColors = [...state.optionsColors];
        newOptions.splice(index, 1);
        newOptionsColors.splice(index, 1);
        setState({
            ...state,
            options: newOptions,
            optionsColors: newOptionsColors,
            recordField: {
                ...state.recordField,
                options: newOptions,
                optionsColors: newOptionsColors
            }
        });
    }

    const handleOptionChange = (e, index) => {
        let newOptions = [...state.options];
        newOptions[index] = e.target.value;
        setState({
            ...state,
            options: newOptions,
            recordField: {
                ...state.recordField,
                options: newOptions
            }
        });
    }

    const handleClosePopup = () => {
        setState({
            ...state,
            colourPopupIsVisible: !state.colourPopupIsVisible,
            optionPickedForColourChange: null
        });
    }

    return (
        <div className="record-field-form" onClick={() => state.colourPopupIsVisible ? handleClosePopup() : {}}>
            <CrossaPageTemplate
                hasFooter={true}
                closeSlidingPanel={props.closeSlidingPanel}
                saveChanges={addRecordField}
            >


                <Form action="#" method="#">
                    <Form.Group>
                        <Form.Label><FormattedMessage id="Name" /></Form.Label>
                        <FormattedMessage id="Name" >
                        {placeholder =>
                            <Form.Control
                                type="text"
                                name="name"
                                placeholder={placeholder}
                                value={state.recordField.name || ""}
                                onChange={handleInput}
                                className={state.errors.name || state.errors.unavailableName ? "has-errors" : ""}
                                isInvalid={state.errors.name || state.errors.unavailableName}
                            />
                        }
                        </FormattedMessage>
                        <Form.Control.Feedback type="invalid">
                            {
                                (state.errors.name && <FormattedMessage id={"Validation.cannotBeEmptyAndParamMinParamMax"} values={{paramMin:VALIDATIONS.MIN_LENGTH_DEFAULT, paramMax:VALIDATIONS.MAX_LENGTH_DEFAULT}}/>) || (state.errors.unavailableName && <FormattedMessage id={"Validation.nameUnavailable"}/>)
                            }

                        </Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group>
                        <Form.Label><FormattedMessage id="Type" /></Form.Label>
                        <FormattedMessage id="Placeholder.select" >
                        {placeholder =>
                            <Select
                                className={state.errors.type ? "react-select primary has-errors" : "react-select primary"}
                                classNamePrefix="react-select"
                                name="type"
                                value={state.singleSelect.label?state.singleSelect:""}
                                onChange={value => handleTypeChange(value)}
                                options={state.fieldTypes.map(option => {
                                    return {
                                        "label": option.label,
                                        "value": option.value,
                                    }
                                })}
                                isDisabled={!props.editType}
                                placeholder={placeholder}
                                isInvalid={state.errors.type}
                            />
                        }
                        </FormattedMessage>
                        {
                            state.errors.type && <div className={"error-message"}>
                                <FormattedMessage id={"Validation.selectOneOption"}/>
                            </div>
                        }
                    </Form.Group>
                    {
                        state.recordField && state.recordField.type === RECORD_FIELD_TYPE.CURRENCY &&
                        <Form.Group>
                            <Form.Label><FormattedMessage id="RecordField.currency" /></Form.Label>
                            <FormattedMessage id={"Placeholder.select"} >
                                {placeholder =>
                                    <Select
                                        className="react-select primary"
                                        classNamePrefix="react-select"
                                        name="view"
                                        value={state.recordField.currency?{
                                            "label": state.recordField.currency,
                                            "value": state.recordField.currency
                                        }:""}
                                        placeholder={placeholder}
                                        options={allCurrencies}
                                        onChange={event =>
                                            setState({
                                                ...state,
                                                recordField: {
                                                    ...state.recordField,
                                                    currency: event.value
                                                }
                                            })
                                        }
                                    />
                                }
                            </FormattedMessage>
                        </Form.Group>
                    }

                    {
                        state.recordField && state.recordField.type === RECORD_FIELD_TYPE.DATE &&
                        <Form.Group>
                            <Row>
                                <Col>
                                    <p><FormattedMessage id="FieldType.time" /></p>
                                </Col>
                                <Col className="inline-switch-col">
                                    <Form.Check
                                        type="switch"
                                        id="crossa-time-field-switch"
                                        className="ml-3"
                                        checked={state.recordField.timeField}
                                        onChange={event =>
                                            setState({
                                                ...state,
                                                recordField: {
                                                    ...state.recordField,
                                                    timeField: event.target.checked
                                                }
                                            })
                                        }
                                    />
                                </Col>
                            </Row>
                        </Form.Group>
                    }

                    {
                        state.isRecordOptionSelected &&
                        <Form.Group>
                            <Form.Label><FormattedMessage id="View" /></Form.Label>
                            <FormattedMessage id={"Placeholder.select"} >
                                {placeholder =>
                                    <Select
                                        className={state.errors.view ? "react-select primary has-errors" : "react-select primary"}
                                        classNamePrefix="react-select"
                                        name="view"
                                        value={state.recordField && state.recordField.vid ? state.recordViewsOptions.find(recordView => recordView.value === state.recordField.vid) : null}
                                        // value= trebuie pus value in caz ca se doreste sa se faca edit
                                        options={state.recordViewsOptions}
                                        onChange={handleViewChange}
                                        isInvalid={state.errors.view}
                                        placeholder={placeholder}
                                    />
                                }
                            </FormattedMessage>
                            {
                                state.errors.view && <div className={"error-message"}>
                                    <FormattedMessage id={"Validation.selectOneOption"}/>
                                </div>
                            }
                        </Form.Group>
                    }

                    {
                        state.recordField && state.recordField.type === RECORD_FIELD_TYPE.SINGLE_CHOICE &&
                        <Form.Group className="smaller">
                            <Form.Label><FormattedMessage id="Options" /></Form.Label>
                            {
                                state.options?.map((option, index) => {
                                    return <Form.Group key={index} className="colour-picker-form-group">

                                        <div className="row colour-option-row m-0 align-items-center">
                                            <FormattedMessage id="Option" >
                                                {placeholder =>
                                                    <Form.Control
                                                        type="text"
                                                        name="name"
                                                        placeholder={placeholder}
                                                        value={option || ""}
                                                        onChange={(e) => handleOptionChange(e, index)}
                                                    />
                                                }
                                            </FormattedMessage>
                                            {
                                                state.optionsColors[index] === "" ?
                                                    <span onClick={() => {
                                                        setState({
                                                            ...state,
                                                            ...toggleColourPopupVisibility(index)
                                                        });
                                                    }} className="colour-picker">
                                                        <img src={ColorPickerIcon} alt="color-picker"></img>
                                                    </span>
                                                    :

                                                    <span style={{ backgroundColor: state.optionsColors[index] }} className="colour selected-colour" onClick={() => {
                                                        setState({
                                                            ...state,
                                                            ...toggleColourPopupVisibility(index)
                                                        });
                                                    }}></span>
                                            }

                                            <a
                                                className="delete-btn-container text-danger"
                                                onClick={() => removeOption(index)}
                                            >
                                                <i className="fa fa-trash" />
                                            </a>
                                        </div>
                                        {state.optionPickedForColourChange === index && state.colourPopupIsVisible && <div className="colour-popup">
                                            {
                                                colours.map((colour,indexColor) => {
                                                    return <span key={indexColor} style={{ backgroundColor: colour }} className="colour" onClick={(event) => handleColourChange(index, colour, event)}>

                                                    </span>
                                                })
                                            }
                                            <img src={NonColorIcon} alt="non-color" className="colour cancel-colour" onClick={(event) => handleColourChange(index, '', event)}></img>
                                        </div>}
                                    </Form.Group>
                                })
                            }
                            <div>

                                <Button onClick={addOption}>
                                    <span className="icon">
                                        {addIconFullBg}
                                    </span>
                                    <FormattedMessage id="Button.addOption"/>
                                </Button>
                            </div>

                        </Form.Group>
                    }

                    {
                        state.recordField && state.recordField.type === RECORD_FIELD_TYPE.MULTIPLE_CHOICE &&
                        <Form.Group className="smaller">
                            <Form.Label><FormattedMessage id="Options" /></Form.Label>
                            {
                                state.options?.map((option, index) => {
                                    return <Form.Group key={index}>
                                        <div className="row m-0 align-items-center">
                                            <FormattedMessage id="Option" >
                                                {placeholder =>
                                                    <Form.Control
                                                        type="text"
                                                        name="name"
                                                        placeholder={placeholder}
                                                        value={option || ""}
                                                        onChange={(e) => handleOptionChange(e, index)}
                                                    />
                                                }
                                            </FormattedMessage>
                                            <a
                                                className="ml-3 text-danger"
                                                onClick={() => removeOption(index)}
                                            >
                                                <i className="fa fa-trash" />
                                            </a>
                                        </div>
                                    </Form.Group>
                                })
                            }
                            <div>
                                <Button onClick={addOption}>
                                    <span className="icon">
                                        {addIconFullBg}
                                    </span>
                                    <FormattedMessage id="Button.addOption"/>
                                </Button>
                            </div>
                        </Form.Group>
                    }

                    <Form.Group>
                        {
                            (state.singleSelect && props.displayOptions[state.singleSelect.value]) &&
                            (!!!state.recordField.vid ||
                                state.recordViews?.find(view => view.id === state.recordField.vid.value || view.id === state.recordField.vid)?.rtType !== RECORD_TYPE_TYPE.FORM) &&
                            props.displayOptions[state.singleSelect.value].map((option, index) => displayRadioButton(option, index + 1))
                            /**
                             * index + 1 because button radio with index === 0 is disabled
                             */
                        }
                    </Form.Group>

                    <Form.Group>
                        <Row>
                            <Col>
                                <label className="d-flex align-items-center">
                                    <span className="switch-label mr-5"><FormattedMessage id="Mandatory" /></span>

                                </label>


                            </Col>
                            <Col className="inline-switch-col">
                                <Form.Check
                                    className="ml-3"
                                    type="switch"
                                    id="validation-mandatory-switch"
                                    checked={state.recordField?.validation?.mandatory || false}
                                    onChange={toggleMandatoryValidation}
                                />
                            </Col>
                        </Row>

                    </Form.Group>
                </Form>
            </CrossaPageTemplate>
        </div >
    );
}

export default RecordFieldForm;
