import * as MenuService from "service/MenuService";

const getAllMenusHandler = async (wid) => {
    let menus = [];
    const response = await MenuService.findAllMenus(wid);

    if (response.ok) {
        menus = response.data;
    }

    return {
        data: {
            menus: menus,
        },
        ok: response.ok
    }
}


const deleteMenuHandler = async (menuId,wid) => {

    let menus = [];
    let ok = true;

    const responseDelete = await MenuService.deleteMenu(menuId);
    ok = ok && responseDelete.ok;
    if (responseDelete.ok) {
        const responseFindAll = await MenuService.findAllMenus(wid);
        ok = ok && responseFindAll.ok;
        if (responseFindAll.ok) {
            menus = responseFindAll.data;
        }
    }
    return {
        data: {
            menus: menus,
        },
        ok: ok
    }
}

const changeOrderHandler=async (menuId,newIndex)=>{
    let menus=[];
    const response=await MenuService.changeOrder(menuId,newIndex);
    if(response.ok){
        menus = response.data;
    }
    return{
        data: {
            menus: menus,
        },
        ok:response.ok
    };
}
export {
    getAllMenusHandler,
    deleteMenuHandler,
    changeOrderHandler
}
