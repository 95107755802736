import React from "react";
import {FormattedMessage} from 'react-intl';

const EmptyViewTableRow = () => {
    return (

        <div className="info-message neutral">
            <FormattedMessage id="Validation.noInformation"/>
        </div>

    )
}

export default EmptyViewTableRow;