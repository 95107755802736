import React, {useEffect, useState} from "react";
import {useHistory} from "react-router-dom";
import {Col, Form, Row,} from "react-bootstrap";
import * as WorkspaceService from "service/WorkspaceService.js";
import * as RecordService from "service/RecordService.js";

import Select from "react-select";
import {FormattedMessage} from 'react-intl';
import EXCEPTION_MESSAGES from "../../../../../util/Constants";
import * as Util from "../../../../../util/Util";
import AsyncSelect from "react-select/async";
import {iconX} from "../../../../../components/Icons";
import DateTimePicker from "react-datetime-picker";

const RuleEditor = props => {
    const history = useHistory();


    const [state, setState] = useState({
        select1: [],
        select2: [],
        type: "",
        recordTypeMemberFields: [],
        operator: null,
        select3Options: [],
        valueFromOptions: [],
        valueFrom: null
    });

    useEffect(() => {
        let select2Operators = [];
        let stateOperator = null;
        let valueFromOptions = [];
        let valueFrom = null;
        if(props.rule.type) {
            select2Operators = operators[props.rule.type];
            if(props.rule.operator) {
                switch (props.rule.type) {
                    case "text":
                        for(let operator in textOperators) {
                            if(textOperators[operator].find(op => op.value === props.rule.operator)) {
                                valueFromOptions = textOperators[operator];
                                valueFrom = textOperators[operator].find(op => op.value === props.rule.operator);
                                stateOperator = operators[props.rule.type].find(op => op.value === operator);
                                break
                            }
                        }
                        break;
                    case "integer":
                        for(let operator in integerOperators) {
                            if(integerOperators[operator].find(op => op.value === props.rule.operator)) {
                                valueFromOptions = integerOperators[operator];
                                valueFrom = integerOperators[operator].find(op => op.value === props.rule.operator);
                                stateOperator = operators[props.rule.type].find(op => op.value === operator);
                                break
                            }
                        }
                        break;
                    case "decimal":
                    case "currency":
                        for(let operator in decimalOperators) {
                            if(decimalOperators[operator].find(op => op.value === props.rule.operator)) {
                                valueFromOptions = decimalOperators[operator];
                                valueFrom = decimalOperators[operator].find(op => op.value === props.rule.operator);
                                stateOperator = operators[props.rule.type].find(op => op.value === operator);
                                break
                            }
                        }
                        break;
                    case "date":
                        for(let operator in dateOperators) {
                            if(dateOperators[operator].find(op => op.value === props.rule.operator)) {
                                valueFromOptions = dateOperators[operator];
                                valueFrom = dateOperators[operator].find(op => op.value === props.rule.operator);
                                stateOperator = operators[props.rule.type].find(op => op.value === operator);
                                break
                            }
                        }
                        break;
                    case "singlechoice":
                    case "multiplechoice":
                        for(let operator in singleMultiOperators) {
                            if(singleMultiOperators[operator].find(op => op.value === props.rule.operator)) {
                                valueFromOptions = singleMultiOperators[operator];
                                valueFrom = singleMultiOperators[operator].find(op => op.value === props.rule.operator);
                                stateOperator = operators[props.rule.type].find(op => op.value === operator);
                                break
                            }
                        }
                        break;
                    case "boolean":
                        for(let operator in booleanOperators) {
                            if(booleanOperators[operator].find(op => op.value === props.rule.operator)) {
                                valueFromOptions = booleanOperators[operator];
                                valueFrom = booleanOperators[operator].find(op => op.value === props.rule.operator);
                                stateOperator = operators[props.rule.type].find(op => op.value === operator);
                                break
                            }
                        }
                        break;
                    case "record":
                    case "recordlist":
                        for(let operator in recordOperators) {
                            if(recordOperators[operator].find(op => op.value === props.rule.operator)) {
                                valueFromOptions = recordOperators[operator];
                                valueFrom = recordOperators[operator].find(op => op.value === props.rule.operator);
                                stateOperator = operators[props.rule.type].find(op => op.value === operator);
                                break
                            }
                        }
                        break;
                }
            } else if(props.rule.operatorValue) {
                stateOperator = props.rule.operatorValue;
                switch (props.rule.type) {
                    case "text":
                        valueFromOptions = textOperators[stateOperator.value];
                        break;
                    case "integer":
                        valueFromOptions = integerOperators[stateOperator.value];
                        break;
                    case "decimal":
                    case "currency":
                        valueFromOptions = decimalOperators[stateOperator.value];
                        break;
                    case "date":
                        valueFromOptions = dateOperators[stateOperator.value];
                        break;
                    case "singlechoice":
                    case "multiplechoice":
                        valueFromOptions = singleMultiOperators[stateOperator.value];
                        break;
                    case "boolean":
                        valueFromOptions = booleanOperators[stateOperator.value];
                        break;
                    case "record":
                    case "recordlist":
                        valueFromOptions = recordOperators[stateOperator.value];
                        break;
                }
            }
        }

        setState({
            ...state,
            select1: props.recordFields.filter(recordField => recordField.type.toLocaleLowerCase() !== "groupheader" &&
                recordField.type.toLocaleLowerCase() !== "notifications" && recordField.type.toLocaleLowerCase() !== "workspaceuser" &&
                recordField.type.toLocaleLowerCase() !== "image" &&
                recordField.type.toLocaleLowerCase() !== "roleslist").map(recordField => {
                return {
                    "label": recordField.name,
                    "type": recordField.type.toLowerCase(),
                    "value": recordField.id,
                    "valueOptions": recordField.options,
                    "rtid": recordField.rtid,
                }
            }),
            select2: select2Operators,
            operatorValue: stateOperator,
            valueFromOptions: valueFromOptions,
            valueFrom: valueFrom,
            type: props.rule.type,
            recordTypeMemberFields: [],
            select3Options: props.rule.type === "boolean" ? [{value: "true", label: "true"}, {value: "false", label: "false"}] :
                props.recordFields.find(recordField => recordField.id === props.rule.operand?.value)?.options?.map(option => ({
                "value": option,
                "label": option
            }))
        });
    }, [props]);

    const getRecordTypeAndRecordViewByWorkspaceId = async (wid) => {
        const response = await WorkspaceService.findMemberRecordTypeRecordView(wid);
        if (response.ok) {
            setState({
                ...state,
                recordTypeMemberFields: response.data.recordType.fields.map(field => {
                    return {
                        "value": field.id,
                        "label": field.name,
                        "type": field.type,
                        "rtid": field.rtid,
                    }
                })
            });
        } else {
            if (!!response.data.message && response.data.message === EXCEPTION_MESSAGES.INVALID_REFRESH_TOKEN) {
                Util.logout(history);
            }
        }
    }

    const getAllRecordsWithNameContains = async (nameContains, recordTypeId) => {
        const response = await RecordService.findAllRecordsInRecordTypeWithNameContains(nameContains, recordTypeId);
        if (response.ok) {
            return response.data.map(element => {
                return {
                    "label": element.name,
                    "value": element.id,
                }
            })
        } else {
            if (!!response.data.message && response.data.message === EXCEPTION_MESSAGES.INVALID_REFRESH_TOKEN) {
                Util.logout(history);
            }
        }
    }

    const handleOperatorChange = e => {
        let newErrors={...props.errors};
        newErrors={
            ...newErrors,
            [props.index]:{
                ...newErrors?.[props.index],
                "operatorValue":false,
                "operator":false,
                "select3":false
            }
        };

        props.changeRule({
            ...props.rule,
            "operatorValue": e,
            "operator": "",
            "select3": props.rule.type === "boolean" ? false : ""
        }, props.index,newErrors);
    }

    const handleValueFromChange = e => {
        let newErrors={...props.errors};
        newErrors={
            ...newErrors,
            [props.index]:{
                ...newErrors?.[props.index],
                "operator":false,
                "select3":false
            }
        };
        props.changeRule({
            ...props.rule,
            "operator": e.value,
            "select3": props.rule.type === "boolean" ? false : ""
        }, props.index,newErrors);
    }

    const handleSelect3Change = e => {
        let newErrors = {...props.errors};
        if (newErrors?.[props.index]?.select3) {
            newErrors = {
                ...newErrors,
                [props.index]: {
                    ...newErrors?.[props.index],
                    select3: false
                }
            };
        }
        props.changeRule({
            ...props.rule,
            "select3": state.type === "boolean" ? e.value === "true" :
                (state.type === "singlechoice" || state.type === "multiplechoice") ? e.value : e.target.value,
        }, props.index,newErrors);
    }

    const handleSelect3DateChange = value => {
        let newErrors={...props.errors};
        if(newErrors?.[props.index]?.select3){
            newErrors={
                ...newErrors,
                [props.index]:{
                    ...newErrors?.[props.index],
                    select3:false
                }
            };
        }
        if(value) {
            let userTimezoneOffset = new Date(value).getTimezoneOffset() * 60000;
            props.changeRule({
                ...props.rule,
                "select3": new Date(value.getTime() - userTimezoneOffset),
            }, props.index,newErrors);
        } else {
            props.changeRule({
                ...props.rule,
                "select3": null,
            }, props.index,newErrors);
        }
    }

    const handleFieldChange = e => {
        let newErrors={...props.errors};
        newErrors={
            ...newErrors,
            [props.index]:{
                ...newErrors?.[props.index],
                "operand":false,
                "operator":false,
                "operatorValue":false,
                "select3":false
            }
        };

        props.changeRule({
            ...props.rule,
            "type": e.type,
            "eqRecord": e.type === "record" || e.type === "recordlist",
            "operand": {
                "value": e.value,
                "label": e.label,
                "rtid":e.rtid
            },
            "operator": null,
            "operatorValue": null,
            "select3": e.type === "boolean" ? false : ""
        }, props.index,newErrors);

        const newState = {
            ...state,
            type: e.type
        };

        newState.select2 = operators[e.type];

        if (e.type === "singlechoice" || e.type === "multiplechoice") {
            newState.select3Options = e.valueOptions?.map(valueOption => {
                return {
                    "value": valueOption,
                    "label": valueOption
                }
            });
        }

        setState(newState);
    }

    const getSelect3InputField = () => {
        let result = "";
        if (props.rule.operator === "EqParam" || props.rule.operator === "GtParam"
            || props.rule.operator === "GteParam" || props.rule.operator === "LtParam" || props.rule.operator === "LteParam") {
            if (state.recordTypeMemberFields.length === 0) {
                const wid = history.location.pathname.split("/")[2]
                getRecordTypeAndRecordViewByWorkspaceId(wid);
            }
            return (<>
                    <FormattedMessage id="Field">
                        {placeholder =>
                            <Select
                                className={"react-select primary " + (props.errors?.[props.index]?.select3 ? "has-errors" : "")}
                                classNamePrefix="react-select"
                                name="type"
                                placeholder={placeholder}
                                options={state.recordTypeMemberFields.filter(field =>
                                    ((props.rule.type === "record" || props.rule.type === "recordlist") && field.type.toLocaleLowerCase() === "record" && field.rtid === props.rule.operand?.rtid) ||
                                    (props.rule.type !== "record" && props.rule.type !== "recordlist" && field.type.toLocaleLowerCase() === props.rule.type))}
                                value={props.rule.select3 ?
                                    {
                                        "value": props.rule.select3.substring(14),
                                        "label": state.recordTypeMemberFields.find(field => field.value === props.rule.select3.substring(14))?.label
                                    } : ""}
                                onChange={e => {
                                    let newErrors = {...props.errors};
                                    if (newErrors?.[props.index]?.select3) {
                                        newErrors = {
                                            ...newErrors,
                                            [props.index]: {
                                                ...newErrors?.[props.index],
                                                select3: false
                                            }
                                        };
                                    }
                                    props.changeRule({
                                        ...props.rule,
                                        "select3": "currentMember." + e.value,
                                    }, props.index, newErrors);
                                }}
                            />
                        }
                    </FormattedMessage>
                    {props.errors?.[props.index]?.select3 &&
                        <div className={"error-message"}>
                            <FormattedMessage id={"Validation.selectOneOption"}/>
                        </div>
                    }
                </>
            );
        } else if (props.rule.select3 !== "currentDate" && props.rule.type === "date" && (props.rule.operator === "EqParamDate" ||props.rule.operator === "NeParamDate" ||
            props.rule.operator === "GtParamDate" || props.rule.operator === "GteParamDate" || props.rule.operator === "LtParamDate" ||
            props.rule.operator === "LteParamDate")) {
            let newErrors = {...props.errors};
            if (newErrors?.[props.index]?.select3) {
                newErrors = {
                    ...newErrors,
                    [props.index]: {
                        ...newErrors?.[props.index],
                        select3: false
                    }
                };
            }
            props.changeRule({
                ...props.rule,
                "select3": "currentDate",
            }, props.index,newErrors);
            return null;
        }

        switch (state.type) {
            case "text":
                result = <>
                    <FormattedMessage id="Value">
                        {placeholder =>
                            <Form.Control className={props.errors?.[props.index]?.select3 ? "has-errors" : ""}
                                          placeholder={placeholder}
                                          type="text"
                                          onChange={handleSelect3Change}
                                          value={props.rule.select3}
                                          isInvalid={props.errors?.[props.index]?.select3}/>
                        }
                    </FormattedMessage>
                    <Form.Control.Feedback type="invalid">
                        <FormattedMessage id={"Validation.cannotBeEmpty"}/>
                    </Form.Control.Feedback>
                    </>
                break;
            case "integer":
                result = <>
                    <FormattedMessage id="Value">
                        {placeholder =>
                            <Form.Control className={props.errors?.[props.index]?.select3 ? "has-errors" : ""}
                                          placeholder={placeholder}
                                          type="number"
                                          onChange={handleSelect3Change}
                                          value={props.rule.select3}
                                          isInvalid={props.errors?.[props.index]?.select3}/>
                        }
                    </FormattedMessage>
                    <Form.Control.Feedback type="invalid">
                        <FormattedMessage id={"Validation.cannotBeEmpty"}/>
                    </Form.Control.Feedback>
                    </>
                break;
            case "decimal":
                result = <>
                    <FormattedMessage id="Value">
                        {placeholder =>
                            <Form.Control className={props.errors?.[props.index]?.select3 ? "has-errors" : ""}
                                          placeholder={placeholder}
                                          type="number"
                                          onChange={handleSelect3Change}
                                          value={props.rule.select3}
                                          isInvalid={props.errors?.[props.index]?.select3}/>
                        }
                    </FormattedMessage>
                    <Form.Control.Feedback type="invalid">
                        <FormattedMessage id={"Validation.cannotBeEmpty"}/>
                    </Form.Control.Feedback>
                    </>
                break;
            case "currency":
                result = <>
                    <FormattedMessage id="Value">
                        {placeholder =>
                            <Form.Control className={props.errors?.[props.index]?.select3 ? "has-errors" : ""}
                                          placeholder={placeholder}
                                          type="number"
                                          onChange={handleSelect3Change}
                                          value={props.rule.select3}
                                          isInvalid={props.errors?.[props.index]?.select3}/>
                        }
                    </FormattedMessage>
                    <Form.Control.Feedback type="invalid">
                        <FormattedMessage id={"Validation.cannotBeEmpty"}/>
                    </Form.Control.Feedback>
                    </>
                break;
            case "date":
                if (props.rule.select3 !== "currentDate") {
                    result = <>
                        <DateTimePicker
                            className={props.errors?.[props.index]?.select3 ? "has-errors" : ""}
                            maxDate={new Date("9999-12-31")}
                            onChange={handleSelect3DateChange}
                            format={"y-MM-dd"}
                            value={props.rule && props.rule.select3 ? new Date(props.rule.select3) : ""}
                        />
                        {
                            props.errors?.[props.index]?.select3 && <div className={"error-message"}>
                                <FormattedMessage id={"Validation.selectADate"}/>
                            </div>
                        }
                        </>
                } else {
                    result = null
                }
                break;
            case "boolean":
                result = <Select
                    className="react-select primary"
                    classNamePrefix="react-select"
                    onChange={handleSelect3Change}
                    options={state.select3Options}
                    value={
                        props.rule.select3 == null ?
                            null :
                            {
                                "value": props.rule.select3.toString(),
                                "label": props.rule.select3.toString()
                            }
                    }
                />
                break;
            case "singlechoice":
            case "multiplechoice":
                result = <>
                    <Select
                        className={"react-select primary " + (props.errors?.[props.index]?.select3 ? "has-errors" : "")}
                        classNamePrefix="react-select"
                        onChange={handleSelect3Change}
                        options={state.select3Options}
                        value={{
                            "value": props.rule.select3,
                            "label": props.rule.select3
                        }}
                    />
                    {
                        props.errors?.[props.index]?.select3 && <div className={"error-message"}>
                            <FormattedMessage id={"Validation.selectOneOption"}/>
                        </div>
                    }
                    </>
                break;
            case "record":
            case "recordlist":
                return <>
                    <AsyncSelect
                        className={"react-select primary " + (props.errors?.[props.index]?.select3 ? "has-errors" : "")}
                        classNamePrefix="react-select"
                        placeholder={<FormattedMessage id={"Search"}/>}
                        noOptionsMessage={({inputValue}) => !inputValue ?
                            <FormattedMessage id={"SearchIn"} values={{param: props.rule.operand.label}}/> :
                            <FormattedMessage id={"Placeholder.noResults"}/>}
                        onChange={event => {
                            let newErrors = {...props.errors};
                            if (newErrors?.[props.index]?.select3) {
                                newErrors = {
                                    ...newErrors,
                                    [props.index]: {
                                        ...newErrors?.[props.index],
                                        select3: false
                                    }
                                };
                            }
                            props.changeRule({
                                ...props.rule,
                                "select3": event.value,
                                "select3Name": event.label
                            }, props.index,newErrors);
                        }}
                        value={props.rule?.select3 && !!!props.rule?.select3Name ?
                            findRecordById(props.rule.select3, props.recordFields.find(recField => recField.id === props.rule.operand.value).rtid) :
                            props.rule.select3 ?
                                {
                                    "value": props.rule.select3,
                                    "label": props.rule.select3Name
                                } : false
                        }
                        loadOptions={(inputValue) => promiseOptions(inputValue, props.recordFields.find(recField => recField.id === props.rule.operand.value).rtid)}
                    />
                    {
                        props.errors?.[props.index]?.select3 && <div className={"error-message"}>
                            <FormattedMessage id={"Validation.selectOneOption"}/>
                        </div>
                    }
                    </>
            default:
                result = null;
        }

        return result;
    }

    const findRecordById = async (recordId, rtid) => {
        const response = await RecordService.findRecordInRecordType(recordId, rtid);
        if (response.ok) {
            props.changeRule({
                ...props.rule,
                "select3Name": response.data.name,
            }, props.index,props.errors);
        } else {
            if (!!response.data.message && response.data.message === EXCEPTION_MESSAGES.INVALID_REFRESH_TOKEN) {
                Util.logout(history);
            }
        }
    }

    const promiseOptions = (inputValue, rtid) =>
        new Promise(resolve => {
            resolve(getAllRecordsWithNameContains(inputValue, rtid));
        });

    return (
        <div className="rule-editor">
                <Row>
                    <Col className="type-col">
                        <Form.Group>
                            <FormattedMessage id="Field">
                                {placeholder =>
                                    <Select
                                        className={"react-select primary " + (props.errors?.[props.index]?.operand ? "has-errors" : "")}
                                        classNamePrefix="react-select"
                                        name="type"
                                        value={props.rule.operand ? props.rule.operand : null}
                                        options={state.select1}
                                        placeholder={placeholder}
                                        onChange={handleFieldChange}
                                        isInvalid={props.errors?.[props.index]?.operand}
                                    />
                                }
                            </FormattedMessage>
                            {
                                props.errors?.[props.index]?.operand && <div className={"error-message"}>
                                    <FormattedMessage id={"Validation.selectOneOption"}/>
                                </div>
                            }
                        </Form.Group>
                    </Col>
                    <Col className="operator-col">
                        <Form.Group>
                            <FormattedMessage id="Operator">
                                {placeholder =>
                                    <Select
                                        className={"react-select primary " + (props.errors?.[props.index]?.operatorValue ? "has-errors" : "")}
                                        classNamePrefix="react-select"
                                        name="type"
                                        options={state.select2}
                                        value={state.operatorValue}
                                        onChange={handleOperatorChange}
                                        placeholder={placeholder}
                                        isInvalid={props.errors?.[props.index]?.operatorValue}
                                    />
                                }
                            </FormattedMessage>
                            {
                                props.errors?.[props.index]?.operatorValue && <div className={"error-message"}>
                                    <FormattedMessage id={"Validation.selectOneOption"}/>
                                </div>
                            }
                        </Form.Group>
                    </Col>
                    <Col className="operator-col">
                        <Form.Group>
                            <FormattedMessage id="Operator">
                                {placeholder =>
                                    <Select
                                        className={"react-select primary " + (props.errors?.[props.index]?.operator ? "has-errors" : "")}
                                        classNamePrefix="react-select"
                                        name="valueCol"
                                        options={state.valueFromOptions}
                                        onChange={handleValueFromChange}
                                        value={state.valueFrom}
                                        placeholder={placeholder}
                                        isInvalid={props.errors?.[props.index]?.operator}
                                    />
                                }
                            </FormattedMessage>
                            {
                                props.errors?.[props.index]?.operator && <div className={"error-message"}>
                                    <FormattedMessage id={"Validation.selectOneOption"}/>
                                </div>
                            }
                        </Form.Group>
                    </Col>
                    {
                        (props.rule.operator === "EqParam" || props.rule.operator === "GtParam"
                            || props.rule.operator === "GteParam" || props.rule.operator === "LtParam" || props.rule.operator === "LteParam") &&
                        <Col className="eqParam-col">
                            <Form.Group>
                                <FormattedMessage id="CurrentMember">
                                    {value =>
                                        <Form.Control
                                            type="text"
                                            value={value}
                                            readOnly
                                        />
                                    }
                                </FormattedMessage>
                            </Form.Group>
                        </Col>
                    }
                    {
                        (props.rule.type === "date" && (props.rule.operator === "EqParamDate"||props.rule.operator === "NeParamDate"||props.rule.operator === "GtParamDate" || props.rule.operator === "GteParamDate" ||
                            props.rule.operator === "LtParamDate" || props.rule.operator === "LteParamDate")) &&
                        <Col className="eqParam-col">
                            <Form.Group>
                                <FormattedMessage id="CurrentDate">
                                    {value =>
                                        <Form.Control
                                            type="text"
                                            value={value}
                                            readOnly
                                        />
                                    }
                                </FormattedMessage>
                            </Form.Group>
                        </Col>
                    }
                    {state.type && <Col className={state.type === "date" && props.rule.select3 !== "currentDate" ? "value-col bigger" : "value-col"}>
                        <Form.Group>
                            {getSelect3InputField()}
                        </Form.Group>
                    </Col>}

                    <Col className="buttons-col rule-editor-actions">
                            <span onClick={() => props.deleteRule(props.index)} className="icon-delete">{iconX}</span>
                    </Col>

                </Row>
        </div>
    )
}

export default RuleEditor;

const operators = {
    "text": [
        {"label": <FormattedMessage id="Operators.is" />, "value": "Eq"},
        {"label": <FormattedMessage id="Operators.isNot" />, "value": "Ne"},
        {"label": <FormattedMessage id="Operators.contains" />, "value": "Contains"},
        {"label": <FormattedMessage id="Operators.notContains" />, "value": "NotContains"},
    ],
    "integer": [
        {"label": <FormattedMessage id="Operators.is" />, "value": "Eq"},
        {"label": <FormattedMessage id="Operators.isNot" />, "value": "Ne"},
        {"label": <FormattedMessage id="Operators.gt" />, "value": "Gt"},
        {"label": <FormattedMessage id="Operators.gte" />, "value": "Gte"},
        {"label": <FormattedMessage id="Operators.lt" />, "value": "Lt"},
        {"label": <FormattedMessage id="Operators.lte" />, "value": "Lte"},
    ],
    "decimal": [
        {"label": <FormattedMessage id="Operators.is" />, "value": "Eq"},
        {"label": <FormattedMessage id="Operators.isNot" />, "value": "Ne"},
        {"label": <FormattedMessage id="Operators.gt" />, "value": "Gt"},
        {"label": <FormattedMessage id="Operators.gte" />, "value": "Gte"},
        {"label": <FormattedMessage id="Operators.lt" />, "value": "Lt"},
        {"label": <FormattedMessage id="Operators.lte" />, "value": "Lte"},
    ],
    "boolean": [
        {"label": <FormattedMessage id="Operators.is" />, "value": "Eq"},
    ],
    "date": [
        {"label": <FormattedMessage id="Operators.is" />, "value": "Eq"},
        {"label": <FormattedMessage id="Operators.isNot" />, "value": "Ne"},
        {"label": <FormattedMessage id="Operators.after" />, "value": "Gt"},
        {"label": <FormattedMessage id="Operators.afterOrSame" />, "value": "Gte"},
        {"label": <FormattedMessage id="Operators.before" />, "value": "Lt"},
        {"label": <FormattedMessage id="Operators.beforeOrSame" />, "value": "Lte"},
    ],
    "currency": [
        {"label": <FormattedMessage id="Operators.is" />, "value": "Eq"},
        {"label": <FormattedMessage id="Operators.isNot" />, "value": "Ne"},
        {"label": <FormattedMessage id="Operators.gt" />, "value": "Gt"},
        {"label": <FormattedMessage id="Operators.gte" />, "value": "Gte"},
        {"label": <FormattedMessage id="Operators.lt" />, "value": "Lt"},
        {"label": <FormattedMessage id="Operators.lte" />, "value": "Lte"},
    ],
    "record": [
        {"label": <FormattedMessage id="Operators.is" />, "value": "Eq"},
        {"label": <FormattedMessage id="Operators.isNot" />, "value": "Ne"},
    ],
    "recordlist": [
        {"label": <FormattedMessage id="Operators.contains" />, "value": "Eq"},
        {"label": <FormattedMessage id="Operators.notContains" />, "value": "Ne"},
    ],
    "singlechoice": [
        {"label": <FormattedMessage id="Operators.is" />, "value": "Eq"},
        {"label": <FormattedMessage id="Operators.isNot" />, "value": "Ne"},
    ],
    "multiplechoice": [
        {"label": <FormattedMessage id="Operators.contains" />, "value": "Eq"},
        {"label": <FormattedMessage id="Operators.notContains" />, "value": "Ne"},
    ]
}

const textOperators = {
    "Eq": [{"label": <FormattedMessage id="Operators.value" />, "value": "EqString"}, {"label": <FormattedMessage id="Operators.fromVariableField" />, "value": "EqParam"}],
    "Ne": [{"label": <FormattedMessage id="Operators.value" />, "value": "NeString"}, {"label": <FormattedMessage id="Operators.fromVariableField" />, "value": "NeParam"}],
    "Contains": [{"label": <FormattedMessage id="Operators.value" />, "value": "Contains"}],
    "NotContains": [{"label": <FormattedMessage id="Operators.value" />, "value": "NotContains"}]
}

const integerOperators = {
    "Eq": [{"label": <FormattedMessage id="Operators.value" />, "value": "EqLong"}, {"label": <FormattedMessage id="Operators.fromVariableField" />, "value": "EqParam"}],
    "Ne": [{"label": <FormattedMessage id="Operators.value" />, "value": "NeLong"}, {"label": <FormattedMessage id="Operators.fromVariableField" />, "value": "NeParam"}],
    "Gt": [{"label": <FormattedMessage id="Operators.value" />, "value": "GtLong"}, {"label": <FormattedMessage id="Operators.fromVariableField" />, "value": "GtParam"}],
    "Gte": [{"label": <FormattedMessage id="Operators.value" />, "value": "GteLong"}, {"label": <FormattedMessage id="Operators.fromVariableField" />, "value": "GteParam"}],
    "Lt": [{"label": <FormattedMessage id="Operators.value" />, "value": "LtLong"}, {"label": <FormattedMessage id="Operators.fromVariableField" />, "value": "LtParam"}],
    "Lte": [{"label": <FormattedMessage id="Operators.value" />, "value": "LteLong"}, {"label": <FormattedMessage id="Operators.fromVariableField" />, "value": "LteParam"}],
}

const decimalOperators = {
    "Eq": [{"label": <FormattedMessage id="Operators.value" />, "value": "EqDouble"}, {"label": <FormattedMessage id="Operators.fromVariableField" />, "value": "EqParam"}],
    "Ne": [{"label": <FormattedMessage id="Operators.value" />, "value": "NeDouble"}, {"label": <FormattedMessage id="Operators.fromVariableField" />, "value": "NeParam"}],
    "Gt": [{"label": <FormattedMessage id="Operators.value" />, "value": "GtDouble"}, {"label": <FormattedMessage id="Operators.fromVariableField" />, "value": "GtParam"}],
    "Gte": [{"label": <FormattedMessage id="Operators.value" />, "value": "GteDouble"}, {"label": <FormattedMessage id="Operators.fromVariableField" />, "value": "GteParam"}],
    "Lt": [{"label": <FormattedMessage id="Operators.value" />, "value": "LtDouble"}, {"label": <FormattedMessage id="Operators.fromVariableField" />, "value": "LtParam"}],
    "Lte": [{"label": <FormattedMessage id="Operators.value" />, "value": "LteDouble"}, {"label": <FormattedMessage id="Operators.fromVariableField" />, "value": "LteParam"}],
}

const booleanOperators = {
    "Eq": [{"label": <FormattedMessage id="Operators.value" />, "value": "EqBoolean"}, {"label": <FormattedMessage id="Operators.fromVariableField" />, "value": "EqParam"}],
}

const dateOperators = {
    "Eq": [{"label": <FormattedMessage id="Operators.value" />, "value": "EqDate"}, {"label": <FormattedMessage id="Operators.fromVariableField" />, "value": "EqParam"},{"label": <FormattedMessage id="Operators.fromVariable" />,"value": "EqParamDate","toCurrentDate": true}],
    "Ne": [{"label": <FormattedMessage id="Operators.value" />, "value": "NeDate"}, {"label": <FormattedMessage id="Operators.fromVariableField" />, "value": "NeParam"},{"label": <FormattedMessage id="Operators.fromVariable" />,"value": "NeParamDate","toCurrentDate": true}],
    "Gt": [{"label": <FormattedMessage id="Operators.value" />, "value": "GtDate"}, {"label": <FormattedMessage id="Operators.fromVariableField" />, "value": "GtParam"},{"label": <FormattedMessage id="Operators.fromVariable" />,"value": "GtParamDate","toCurrentDate": true}],
    "Gte": [{"label": <FormattedMessage id="Operators.value" />, "value": "GteDate"}, {"label": <FormattedMessage id="Operators.fromVariableField" />, "value": "GteParam"},{"label": <FormattedMessage id="Operators.fromVariable" />,"value": "GteParamDate","toCurrentDate": true}],
    "Lt": [{"label": <FormattedMessage id="Operators.value" />, "value": "LtDate"}, {"label": <FormattedMessage id="Operators.fromVariableField" />, "value": "LtParam"},{"label": <FormattedMessage id="Operators.fromVariable" />,"value": "LtParamDate","toCurrentDate": true}],
    "Lte": [{"label": <FormattedMessage id="Operators.value" />, "value": "LteDate"}, {"label": <FormattedMessage id="Operators.fromVariableField" />, "value": "LteParam"},{"label": <FormattedMessage id="Operators.fromVariable" />,"value": "LteParamDate","toCurrentDate": true}],
}

const recordOperators = {
    "Eq": [{"label": <FormattedMessage id="Operators.value" />, "value": "EqRecord"}, {"label": <FormattedMessage id="Operators.fromVariableField" />, "value": "EqParam"}],
    "Ne": [{"label": <FormattedMessage id="Operators.value" />, "value": "NeRecord"}, {"label": <FormattedMessage id="Operators.fromVariableField" />, "value": "NeParam"}],
}

const singleMultiOperators = {
    "Eq": [{"label": <FormattedMessage id="Operators.value" />, "value": "EqString"}, {"label": <FormattedMessage id="Operators.fromVariableField" />, "value": "EqParam"}],
    "Ne": [{"label": <FormattedMessage id="Operators.value" />, "value": "NeString"}, {"label": <FormattedMessage id="Operators.fromVariableField" />, "value": "NeParam"}],
}
