import React from "react";
import TabContent from "views/workspaceAdmin/menu/component/TabContent.js"
import {useTabs, useTabsUpdate} from "views/workspaceAdmin/menu/context/TabsContext.js";
import {FormattedMessage} from 'react-intl';

import {
    Button,
    Col,
    Row,
    Card,
    Tab,
    Nav
} from "react-bootstrap";
import {addIconFullBg} from "../../../../components/Icons";
import {checkIfError} from "validations/validations";
import {DETAILED_VIEW_MODE, RECORDS_DISPLAY_MODE} from "../../../../util/Constants";
import {DragDropContext, Draggable, Droppable} from "react-beautiful-dnd";

function TabsComponent(props) {
    const tabs = useTabs();
    const changeTabs = useTabsUpdate();

    const handleAddNewTab=()=> {
        changeTabs([...tabs, {
            "name": "Untitled tab " + (tabs.length + 1),
            "displayMode": RECORDS_DISPLAY_MODE.TABLE,
            "detailedViewMode": DETAILED_VIEW_MODE.SIDE_PANEL,
            "vid": 0,
            "displayFields": []
        }]);
    }

    const onDragTab=(result)=>{
        if(!result.destination){
            return;
        }

        const newData = Array.from(tabs);
        const [removed] = newData.splice(result.source.index, 1);
        newData.splice(result.destination.index, 0, removed);

        let newErrors={...props.errors};
        let newTabErrors= newErrors.pages?.[props.pageIndex]?.tabs;

        if(newTabErrors && result.source.index<result.destination.index) {
            let tabErrorsSourceIndex=newTabErrors[result.source.index];
            for (let index = result.source.index; index <result.destination.index; index++){
                newTabErrors[index]=newTabErrors[index+1];
            }
            newTabErrors[result.destination.index]=tabErrorsSourceIndex;
        }

        if(newTabErrors &&  result.source.index>result.destination.index) {
            let tabErrorsSourceIndex=newTabErrors[result.source.index];
            for (let index = result.source.index; index >result.destination.index; index--){
                newTabErrors[index]=newTabErrors[index-1];
            }
            newTabErrors[result.destination.index]=tabErrorsSourceIndex;
        }

        newErrors={
            ...newErrors,
            pages: {
                ...newErrors.pages,
                [props.pageIndex]:{
                        ...newErrors.pages?.[props.pageIndex],
                        tabs: newTabErrors
                    }
            }
        }

        let newCurrentTabIndex=props.tabIndex;
        if(result.source.index===props.tabIndex){
            newCurrentTabIndex=result.destination.index;
        } else if(result.source.index<newCurrentTabIndex && newCurrentTabIndex<=result.destination.index){
            newCurrentTabIndex--;
        } else if(result.destination.index<=newCurrentTabIndex && newCurrentTabIndex<result.source.index){
            newCurrentTabIndex++;
        }

        changeTabs(newData,newErrors,newCurrentTabIndex);
    }

    return (
        <div className="tab-component">
            <Row>
                <Col md={12}>
                    <Card.Header className="row m-0">
                        <Card.Title as="h3">
                            <FormattedMessage id="Tabs"/>
                        </Card.Title>
                        <Button className="ml-auto"
                                onClick={handleAddNewTab}
                        >
                            <span className="icon">
                            {addIconFullBg}
                            </span>
                            <FormattedMessage id="Button.newTab" />
                        </Button>
                    </Card.Header>
                </Col>
            </Row>
            <Card.Body>
                <div className="crossa-tabs-container">
                    <DragDropContext onDragEnd={onDragTab}>
                    <Droppable droppableId="droppable" direction="horizontal">
                        {(provided) => (
                            <Nav className="crossa-tabs crossa-scrollable-tabs" variant={"tabs"}
                                 activeKey={props.tabIndex} ref={provided.innerRef}
                                 {...provided.droppableProps}>
                                {tabs.map((tab, index) => {
                                    return <Draggable key={index} draggableId={index + "drag"} index={index}>
                                        {(provided) => (
                                            <Nav.Link ref={provided.innerRef}
                                                      {...provided.draggableProps}
                                                      {...provided.dragHandleProps}
                                                      eventKey={index}
                                                      active={index == props.tabIndex}
                                                      className={checkIfError(props.errors.pages?.[props.pageIndex]?.tabs?.[index]) ? "has-errors" : ""}
                                                      onSelect={props.setTabIndex2}>{tab.name}</Nav.Link>
                                        )}
                                    </Draggable>
                                })}
                                {provided.placeholder}
                            </Nav>
                        )}</Droppable>
                    </DragDropContext>
                    <Tab.Content>
                        {tabs.map((tab, index) => {
                            return (
                                <Tab.Pane
                                    className="crossa-tab-pane"
                                    eventKey={index}
                                    active={index === props.tabIndex}
                                    key={index}>
                                    <TabContent
                                        allViewOptions={props.allViewOptions}
                                        tabIndex={index}
                                        pageIndex={props.pageIndex}
                                        tabInfo={tab}
                                        viewId={tab.vid}
                                        displayMode={tab.displayMode}
                                        detailedViewMode={tab.detailedViewMode}
                                        tabsLenght={tabs.length}
                                        errors={props.errors}
                                    />
                                </Tab.Pane>
                            )
                        })
                        }
                    </Tab.Content>
                </div>
            </Card.Body>
        </div>
    )
}

export default TabsComponent;
