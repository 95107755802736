import {Button, Form, OverlayTrigger, Tooltip} from "react-bootstrap";
import {FormattedMessage} from 'react-intl';
import {addIconWithoutBg, trashIcon} from "../../../../components/Icons";
import {DragDropContext, Draggable, Droppable} from "react-beautiful-dnd";
import {checkIfError} from "validations/validations";
import {VALIDATIONS} from "../../../../util/Constants";
import React from "react";

function LeftComponent(props) {

    const onDragPageEnd=(result)=>{
        if(!result.destination){
            return;
        }
        const newPages = Array.from(props.pages);
        const [removed] = newPages.splice(result.source.index, 1);
        newPages.splice(result.destination.index, 0, removed);
        props.setNewOrderForPages(newPages,result.source.index,result.destination.index)
    }

    return (
        <>
            <div className="simple-menu-container">
                <div className="simple-menu-container-body">
                    <Form.Group>
                        <FormattedMessage id="MenuName"/>
                        <Form.Control
                            className={props.errors.name?"has-errors":""}
                            type="text"
                            onChange={props.handleNameChange}
                            value={props.name}
                            isInvalid={props.errors.name}
                        />
                        <Form.Control.Feedback type={"invalid"}>
                            <FormattedMessage id={"Validation.cannotBeEmptyAndParamMinParamMax"} values={{paramMin:VALIDATIONS.MIN_LENGTH_DEFAULT, paramMax:VALIDATIONS.MAX_LENGTH_DEFAULT}}/>
                        </Form.Control.Feedback>
                    </Form.Group>
                    <div className="menu-pages">
                        <div className="simple-menu">
                            <div className="label">
                                <FormattedMessage id="MenuPages"/>
                            </div>
                            <>
                                <div className="tree-item">
                                    <div className={"row " + (props.dashboardActive ? "selected" : "")+(checkIfError(props.errors.dashboard)?" has-errors":"")} onClick={props.selectDashboardPage}>
                                        <div className="menuDiv">
                                            <p>{props.dashboard.name}</p>
                                        </div>
                                    </div>
                                </div>
                                <DragDropContext onDragEnd={onDragPageEnd} >
                                    <Droppable droppableId="droppable">
                                        {(provided) => (
                                            <div
                                                {...provided.droppableProps}
                                                ref={provided.innerRef}
                                            >
                                                {props.pages.map((page, index) => {
                                                    return (
                                                    <Draggable
                                                        key={index}
                                                        draggableId={"drag"+index}
                                                        index={index} >
                                                        {(provided, snapshot) => (
                                                    <div

                                                        className={snapshot.isDragging? "tree-item dragging" :"tree-item"} key={index}  ref={provided.innerRef}
                                                        {...provided.draggableProps}
                                                        {...provided.dragHandleProps}>

                                                        <div className={"row " + (index === props.activePage ? "selected" : "")+(checkIfError(props.errors.pages?.[index])?" has-errors":"")} onClick={() => {
                                                            props.selectPage(index)
                                                        }}>
                                                            <div className="menuDiv">
                                                                <p>{page.name}</p>
                                                            </div>
                                                            <div className="actions">
                                                                <OverlayTrigger
                                                                    onClick={(e) => e.preventDefault()}
                                                                    overlay={
                                                                        <Tooltip>
                                                                            <FormattedMessage id="Button.delete"/>
                                                                        </Tooltip>
                                                                    }
                                                                >
                                                                    <Button
                                                                        variant="danger"
                                                                        size="xxl"
                                                                        className="btn-link text-danger"
                                                                        onClick={(e) => {
                                                                            e.stopPropagation()
                                                                            if (props.pages.length === 1) {
                                                                                props.showAlert("Validation.pageMustExist")
                                                                            } else {
                                                                                props.deletePageByIndex(index)
                                                                            }
                                                                        }
                                                                        }
                                                                    >
                                                                        <span>{trashIcon}</span>
                                                                    </Button>
                                                                </OverlayTrigger>
                                                            </div>
                                                        </div>
                                                        {props.alert}
                                                    </div>)}
                                                    </Draggable>
                                                    )})
                                                }
                                                {provided.placeholder}
                                            </div>
                                        )}
                                    </Droppable>
                                </DragDropContext>
                            </>
                            <Button variant="link add-btn" onClick={props.btnAction}>
                            <span className="icon-cont">
                                {addIconWithoutBg}
                            </span>
                                <FormattedMessage id="Button.addMenuPage"  />
                            </Button>
                        </div>
                    </div>
                </div>
            </div>

        </>
    )
}

export default LeftComponent;
