import * as ClientConfig from "config/ClientConfig.js";
import * as Auth from "util/auth/auth"
import * as StorageService from "service/StorageService.js"
import EXCEPTION_MESSAGES from "util/Constants"

const handleResponse = async (callback) => {
    const response = await callback();
    return response.json()
        .then(async responseJson => {
            return {
                ok: response.ok,
                data: responseJson,
            }
        })
        .catch(async error => {
            if (response.status === 401) {
                const tokenResponse = await useRefreshToken();
                if (tokenResponse.ok) {
                    const secondResponse = await callback();
                    return secondResponse.json()
                        .then(async responseJson => {
                            return {
                                ok: secondResponse.ok,
                                data: responseJson,
                            }
                        });
                } else {
                    return {
                        ok: false,
                        status: tokenResponse.status,
                        data: tokenResponse,
                    }
                }
            }

        });
}

const handleResponseFile = async (callback) => {
    const response = await callback();
    return response.blob()
        .then(async responseBlob => {
            return {
                ok: response.ok,
                data: responseBlob,
            }
        })
        .catch(async error => {
            if (response.status === 401) {
                const tokenResponse = await useRefreshToken();
                if (tokenResponse.ok) {
                    const secondResponse = await callback();
                    return secondResponse.blob()
                        .then(async responseBlob => {
                            return {
                                ok: secondResponse.ok,
                                data: responseBlob,
                            }
                        });
                } else {
                    return {
                        ok: false,
                        status: tokenResponse.status,
                        data: tokenResponse,
                    }
                }
            }

        });
}

const handleAuthFileResponse = async (callback) => {
    const response = await callback();
    if(response.status === 401){
        const tokenResponse = await refreshToken();
        if (tokenResponse.ok) {
            const response2 = await callback();
            if(response2 == 200){
                return response2.blob().then(async responseBlob => {
                    return {
                        ok: true,
                        data:URL.createObjectURL(responseBlob)
                    };
                });
            }else  {
                return response2.json().then(async response2Json => {
                    return {
                        ok: false,
                        data: response2Json
                    }
                });
            }
        }else{
            return tokenResponse;
        }
    }else if(response.status === 200){
        return response.blob().then(async responseBlob => {
            return {
                ok: true,
                data:URL.createObjectURL(responseBlob)
            };
        });
    }else {
        return response.json().then(async responseJson => {
            return {
                ok: false,
                data: responseJson
            };
        });
    }
}

async function useRefreshToken() {
    const finalURL = ClientConfig.URL_LOGIN + ClientConfig.URL_GET_TOKEN;

    const params = {
        grant_type: 'refresh_token',
        refresh_token: StorageService.getRefreshToken()
    };

    let formBody = Auth.createBodyforLogin(params);

    return fetch(finalURL, {
        method: "POST",
        body: formBody,
        headers: {
            "Content-Type": "application/x-www-form-urlencoded",
            "Authorization": Auth.getAuthorization()
        }
    })
        .then(async (response) => {
            if (response.ok) {
                return response.json().then(
                    responseJson => {
                        StorageService.setAccessToken(responseJson.access_token);
                        StorageService.setRefreshToken(responseJson.refresh_token);
                        return {
                            ok: true,
                            ...responseJson
                        };
                    }
                )
            } else {
                StorageService.clearStorage();
                window.location = "/";
                return {
                    ok: false,
                    message: EXCEPTION_MESSAGES.INVALID_REFRESH_TOKEN,
                }
            }
        })
}

export {
    handleResponse,
    handleAuthFileResponse,
    handleResponseFile
}
