import * as MenuService from "../../../service/MenuService";
import * as RecordService from "../../../service/RecordService";
import {RECORD_FIELD_TYPE} from "../../../util/Constants";
import currencies from "currency-codes";

const getDataForDashboardHandler = async (mid) => {
    let dashboard = {};
    let ok = true;

    const responseMenu = await MenuService.findById(mid);
    ok = ok && responseMenu.ok;
    if (responseMenu.ok) {
        dashboard= responseMenu.data.dashboard
    }

    return{
        data:{
            dashboard:dashboard
        },
        ok:ok
    };
}

const getAllRecordsInViewHandler=async (vid,sort,size)=>{

    let records = {};
    let recordTypeFields = [];
    let fieldsTypeById = {};
    let fieldsOptionsColorsById = {};

    const response = await RecordService.findAllRecordsInView(vid, sort, size);

    let helperObj = {};
    if (response.ok) {

        response.data.recordType.fields.forEach(field => {

            helperObj = {
                ...helperObj,
                [field.id]: field.type,
            }
            if (field.type === "SingleChoice" && field.optionsColors) {
                let fieldOptionsColorsAux = {};
                field.optionsColors.map((optionColor, index) => {
                    fieldOptionsColorsAux = {...fieldOptionsColorsAux, [field.options[index]]: optionColor}
                });
                fieldsOptionsColorsById = {
                    ...fieldsOptionsColorsById,
                    [field.id]: fieldOptionsColorsAux
                }
            }

            let fieldMode = response.data.recordView.fieldModes.find(fieldMode => fieldMode.id === field.id);
            if (field.type === "Record") {
                let recordTypesInfo = response.data.recordTypesInfo;
                let recordType = recordTypesInfo?.find(recordType => recordType.id === field.rtid);
                if (recordType && recordType.type === "Form") {
                    field.displayOption = "FormLink";
                }
            }
            if(field.type==="Record"||field.type==="RecordList"){
                let recordView=response.data.recordViewsInfo?.find(recordView=>recordView.id===field.vid);
                if(recordView){
                    field.useRecordsAsTags=recordView.useRecordsAsTags;
                }
            }
            recordTypeFields.push({
                ...fieldMode,
                ...field
            })
        });
        fieldsTypeById = {...helperObj};
        records = {
            "values": response.data.records.map(record=>{
                let newRecord={...record};
                for (let recordKey in record) {
                    if(fieldsTypeById[recordKey]===RECORD_FIELD_TYPE.CURRENCY){
                        let currency = newRecord.currency ? currencies.code(newRecord.currency) : currencies.code("EUR");
                        let price=(record[recordKey]/Math.pow(10,currency.digits)).toFixed(currency.digits);
                        newRecord[recordKey]=price;
                    }
                }
                return newRecord;
            }),
            ...helperObj
        };
        return {
            data: {
                records: records,
                recordTypeFields: recordTypeFields,
                fieldsOptionsColorsById: fieldsOptionsColorsById,
                fieldsTypeById:fieldsTypeById
            },
            ok: true
        };
    }
    return {
        data: {
            error: response.data,
            records: [],
            recordTypeFields: [],
            fieldsOptionsColorsById: {},
            fieldsTypeById: {}
        },
        ok: false
    };
}

export{
    getDataForDashboardHandler,
    getAllRecordsInViewHandler
}
