
import * as MemberService from "service/MemberService";
import* as RecordService from "service/RecordService";

const getDataForNotificationsHandler=async (wid)=>{
    let memberId=null;
    let selectedTags=[];
    let pushNotifications=false;
    let emailNotifications=false;
    let recordViewsTags=[];
    let ok=true;
    const response = await MemberService.findMemberMe(wid);
    ok=ok&&response.ok;
    if (response.ok) {
        memberId=response.data.id;
        if(response.data.notifications) {
            selectedTags=response.data.notifications?.tags;
            pushNotifications=response.data.notifications?.pushNotifications;
            emailNotifications=response.data.notifications?.emailNotifications;
        }
    }

    const responseFindAll = await RecordService.findAllRecordsInTagsViews(wid);
    ok=ok&&responseFindAll.ok;
    if (responseFindAll.ok) {
        recordViewsTags=responseFindAll.data;
    }

    selectedTags = selectedTags.filter(selectedTag => recordViewsTags.find(recordViewsTags => recordViewsTags.recordView.id === selectedTag.vid)?.records.find(record => record.id === selectedTag.rid))

    return{
        data:{
            memberId:memberId,
            selectedTags:selectedTags,
            pushNotifications:pushNotifications,
            emailNotifications:emailNotifications,
            recordViewsTags:recordViewsTags
        },
        ok:ok
    };
}

const updateSelfMemberTagsHandler=async(wid,memberId,settings)=>{
    let response = await MemberService.updateSelfMemberTags(wid, memberId, settings);
    return{
        ok:response.ok
    };
}
export{
    getDataForNotificationsHandler,
    updateSelfMemberTagsHandler
};
