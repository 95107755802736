import React from "react";
import { useHistory } from "react-router-dom";
import manWithTelescopeImg from '../assets/img/man_with_telescope.png';
import * as StorageService from "../service/StorageService";
import {getAllWorkspacesHandler} from "../handlers/adminHandlers/workspaceHandlers/AllWorkspacesHandlers";
import {FormattedMessage} from "react-intl";


const Page404 = () => {

    const history = useHistory();

    const toLoginPage = () => {
        if (StorageService.getAccessToken()) {
            if (StorageService.getIsSuperAdmin() === "true") {
                history.replace("/admin/workspaces");
            } else {
                getAllWorkspacesHandler().then(response => {
                    if (response.ok) {
                        if (response.data.workspaces.length > 0) {
                            history.replace("/workspace-member/" + response.data.workspaces[0].id + "/home");
                        }else{
                            history.replace("/");
                        }
                    }
                })
            }
        }else{
            history.replace("/");
        }
    }

    return (
        <div className="page-404">
            <div className="logo-container">
                <img height="85" className="logo-img" src={require("assets/img/logo_with_text_transparent.svg").default} alt="crossa logo icon" />
            </div>
            <div className="row content-row">
                <div className="col left-col">
                    <img src={manWithTelescopeImg} alt={"."}/>
                </div>
                <div className="col right-col">
                    <div className="col title-col">
                        <h1>404</h1>
                    </div>
                    <div className="col text-col">
                        <p className="bigger"><FormattedMessage id="Page404.removedPage" /></p>
                        <p className="smaller"><FormattedMessage id="Page404.recommendReturn" /></p>
                        <button onClick={() => toLoginPage()} className="btn-wd btn btn-primary"><FormattedMessage id="Page404.returnCrossa" /></button>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Page404;
