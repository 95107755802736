import * as WorkspaceService from "service/WorkspaceService";

const getAllWorkspacesHandler = async () => {
    const response = await WorkspaceService.findUserWorkspaces();

    let allWorkspaces=[];
    if (response.ok) {
        allWorkspaces = response.data;
    }
    return {
        data:{
            workspaces:allWorkspaces,
        },
        ok:response.ok
    }
}

const deleteWorkspaceHandler = async (id) => {

    let allWorkspaces = [];

    let ok = true;
    const responseDelete = await WorkspaceService.deleteWorkspaceById(id);
    ok = ok && responseDelete.ok;
    if (responseDelete.ok) {
        const responseFindAll = await WorkspaceService.findUserWorkspaces();
        ok = ok && responseFindAll.ok;
        if (responseFindAll.ok) {
            allWorkspaces = responseFindAll.data;
        }
    }
    return {
        data: {
            workspaces: allWorkspaces,
        },
        ok: ok
    }
}

export{
    getAllWorkspacesHandler,
    deleteWorkspaceHandler
}
