import React, {useContext, useEffect, useState} from "react";
import * as ViewService from "service/ViewsService.js";
import EXCEPTION_MESSAGES from "util/Constants";
import * as Util from "util/Util.js";

const ViewsContext = React.createContext();

export const useAllViews = () => useContext(ViewsContext);

function ViewProvider(props) {
    const [allViews, setAllViews] = useState([]);

    useEffect(() => {
        getAllViews();
    }, []);

    const getAllViews = async () => {
        const response = await ViewService.findAllViews(props.wid);
        if (response.ok) {
            setAllViews(response.data);
        } else {
            if (!!response.data.message && response.data.message === EXCEPTION_MESSAGES.INVALID_REFRESH_TOKEN) {
                Util.logout(history);
            }
        }
    }

    return (
        <ViewsContext.Provider value={allViews}>
            {props.children}
        </ViewsContext.Provider>
    );
}

export default ViewProvider;
