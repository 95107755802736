
import React, {useEffect, useRef, useState} from "react";
import {useHistory } from "react-router-dom";
import Footer from "components/Footer.js"

import { FormattedMessage } from 'react-intl';
import { Button, Card, Col, Row, } from "react-bootstrap";
import ConditionEditor from "./components/conditions/ConditionEditor";
import ActionEditor from "./components/actions/ActionEditor";
import * as QueryBuilder from "util/query/queryBuilder.js"
import { getRecordTypeByIdHandler } from "handlers/workspaceAdminHandlers/recordTypeHandlers/AddRuleRecordTypeHandlers";
import { updateRecordTypeHandler } from "handlers/workspaceAdminHandlers/recordTypeHandlers/AddRuleRecordTypeHandlers";
import AdminNavbar from "components/AdminNavbar";
import CrossaPageTemplate from "components/CrossaPageTemplate";
import {addIconWithoutBg} from "../../../components/Icons";
import {addRuleValidations} from "../../../validations/workspaceAdmin/recordType/RuleValidations";
import {getNotifyErrorOptions} from "../../../util/Util";
import NotificationAlert from "react-notification-alert";
import OperatorComponent from "./components/OperatorComponent";

function AddRuleRecordTypePage(props) {
    const history = useHistory();
    const recordTypeId = props.match.params.rid;

    const [state, setState] = useState({
        conditions: [{}],
        actions: [{}],
        recordFields: [],
        recordType: {},
        errors:{
            conditions:{},
            actions:{},
        },
    });

    useEffect(() => {
        getPageContent();
    }, [props.match.params.rid]);

    const getPageContent = async () => {
        if (recordTypeId) {
            getRecordTypeByIdHandler(recordTypeId).then(response => {
                setState({
                    ...state,
                    ...response.data
                });
            });
        }
    }

    const addCondition = () => {
        let newConditions;
        let newCondition = {};
        if (state.conditions.length > 0) {
            const operator = { "operator": "And" };
            newConditions = [...state.conditions, operator, newCondition];
        } else {
            newConditions = [...state.conditions, newCondition];
        }
        setState({
            ...state,
            conditions: newConditions
        });
    }

    const addAction = () => {
        let newAction = {};
        setState({
            ...state,
            actions: [...state.actions,
                newAction],
        });
    }

    const deleteCondition = id => {
        const newConditions = state.conditions.filter((condition, index) => index !== id && (id === 0 ? index !== (id + 1) : index !== (id - 1)));
        if (newConditions.length === 0) {
            newConditions.push({});
        }
        setState({
            ...state,
            conditions: newConditions
        });
    }

    const deleteAction = id => {
        const newActions = state.actions.filter((action, index) => index !== id);
        setState({
            ...state,
            actions: newActions
        });
    }

    const changeCondition = (condition, index,errors) => {
        let newErrors={...state.errors};
        if(errors){
            newErrors={
                ...newErrors,
                conditions: {
                    ...newErrors.conditions,
                    [index]:errors
                }
            }
        }
        setState({
            ...state,
            conditions: [...state.conditions.slice(0, index), condition, ...state.conditions.slice(index + 1)],
            errors: newErrors
        });
    }

    const changeAction = (action, index,errors) => {
        let newErrors={...state.errors};
        if(errors){
            newErrors={
                ...newErrors,
                actions: {
                    ...newErrors.actions,
                    [index]:errors
                }
            }
        }
        setState({
            ...state,
            actions: [...state.actions.slice(0, index), action, ...state.actions.slice(index + 1)],
            errors: newErrors
        });
    }

    const renderConditions = () => {
        return state.conditions.map((condition, index) =>
            index % 2 === 0 ?
                <React.Fragment key={index}>
                <ConditionEditor
                    key={index}
                    noConditions={state.conditions.length}
                    index={index}
                    condition={condition}
                    conditionOptions={conditionOptions}
                    deleteCondition={deleteCondition}
                    addCondition={addCondition}
                    changeCondition={changeCondition}
                    recordFields={state.recordFields}
                    errors={state.errors.conditions[index] ||{}}
                />
                    {
                        (index + 1) !== state.conditions.length &&
                        <OperatorComponent key={index + 1} operator={state.conditions[index + 1].operator}
                                           index={index + 1} changeCondition={changeCondition}/>
                    }
                </React.Fragment>

                :
                <React.Fragment key={index} />)
    }

    const renderActions = () => {
        return state.actions.map((action, index) =>
            <ActionEditor
                key={index}
                noActions={state.actions.length}
                index={index}
                action={action}
                actionOptions={actionOptions}
                deleteAction={deleteAction}
                addAction={addAction}
                changeAction={changeAction}
                recordFields={state.recordFields}
                lastAction={index + 1 === state.actions.length}
                operatorComponent={(index + 1) !== state.actions.length ? state.actions[index + 1] : null}
                errors={state.errors.actions[index] ||{}}
            />
        );
    }

    const saveRule = async () => {
        const [newErrors, existsErrors] = addRuleValidations({
            condition: state.conditions,
            actions: state.actions
        }, {...state.errors});

        setState({
            ...state,
            errors: newErrors
        });
        if (existsErrors) {
            let options=getNotifyErrorOptions("bc");
            notificationAlertRef.current.notificationAlert(options);
            return;
        }

        let newRecordType = {
            id: state.recordType.id,
            rules: [...state.recordType.rules, {
                "condition": JSON.stringify(QueryBuilder.conditionsToQuery(state.conditions)),
                "actions": JSON.stringify(QueryBuilder.actionsToQuery(state.actions)),
                "isActive": true
            }]
        };

        updateRecordTypeHandler(newRecordType).then(response => {
            if (response.ok) {
                history.goBack();
            }
        });

    }

    const conditionOptions = [
        { label: <FormattedMessage id="IsNew" values={{param:state.recordType?.name}}/>, value: "IsNew" },
        { label: <FormattedMessage id="IsChanged" />, value: "IsChanged" },
    ];

    const actionOptions = [
        { label: <FormattedMessage id="Action.show" />, value: "Show" },
        { label: <FormattedMessage id="Action.hide" />, value: "Hide" },
        { label: <FormattedMessage id="Action.makeReadonly" />, value: "MakeReadonly" },
        { label: <FormattedMessage id="Action.makeEditable" />, value: "MakeEditable" },
        { label: <FormattedMessage id="Action.makeMandatory" />, value: "MakeMandatory" },
        { label: <FormattedMessage id="Action.removeMandatory" />, value: "RemoveMandatory" },
        { label: <FormattedMessage id="Action.setValueOf" />, value: "SetValue" },
        { label: <FormattedMessage id="Action.clearValueOf" />, value: "Clear" }
    ];

    const notificationAlertRef=useRef(null);
    return (
        <>
            <div className="rna-container">
                <NotificationAlert ref={notificationAlertRef} />
            </div>
            <AdminNavbar
                title={<FormattedMessage id="NewRule" />}
                hasBackBtn={true}
            />
            <CrossaPageTemplate>
                <Row>
                    <Col md={12}>
                        <Card.Header>
                            <Card.Title >
                                <FormattedMessage id="Conditions" />
                            </Card.Title>
                        </Card.Header>
                        <Card.Body className="rule-record-type-page pt-0">
                            {
                                renderConditions()
                            }
                                <Button variant="link" onClick={addCondition}>
                            <span className="icon-cont">
                                {addIconWithoutBg}
                            </span>
                                    <FormattedMessage id="NewCondition" />
                                </Button>
                        </Card.Body>
                        <hr></hr>
                        <Card.Header>
                            <Card.Title>
                                <FormattedMessage id="Actions" />
                            </Card.Title>
                        </Card.Header>
                        <Card.Body className="rule-record-type-page pt-0">
                            {
                                renderActions()
                            }
                            <Button variant="link" onClick={addAction}>
                            <span className="icon-cont">
                                {addIconWithoutBg}
                            </span>
                                <FormattedMessage id="NewAction" />
                            </Button>
                        </Card.Body>
                    </Col>
                </Row>

                <Footer
                    save={saveRule}
                    cancel={() => history.goBack()}
                    isEnable={true} />

            </CrossaPageTemplate>
        </>
    )

}


export default AddRuleRecordTypePage;
