import React, {useMemo} from "react";
import {FormattedMessage} from "react-intl";
import {useTable} from 'react-table';
import {useSticky} from "react-table-sticky";
import {editIcon, ruleIcon, trashIcon} from "./Icons";
import {Button, OverlayTrigger, Tooltip} from "react-bootstrap";
import {RECORD_TYPE_TYPE} from "../util/Constants";

function CrossaTable(props) {

    const columns = useMemo(() => props.columns ? props.columns : [], [props]);
    const data = useMemo(() => props.data ? props.data : [], [props]);

    const {
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow,
    } = useTable({
            columns,
            data,
        },
        useSticky)



    return (

        <div className="table-responsive">
            <div className={"table table-fixed sticky"}>
                <div className="header">
                    {headerGroups.map(headerGroup => (
                        <div className="tr"
                             {...headerGroup.getHeaderGroupProps()}>
                            {headerGroup.headers.map(column => (

                                <div className="th"
                                     {...column.getHeaderProps()}>
                                    <div className={"d-inline-flex"}>
                                        {column.render('Header')}
                                    </div>
                                </div>
                            ))}
                        </div>
                    ))}
                </div>
                <div className="body" {...getTableBodyProps()}>
                    {
                        rows.map((row) => {
                            prepareRow(row)
                            return (
                                <div className="tr" id={row.original.id} {...row.getRowProps()}>
                                    {
                                        row.cells.map((cell, index) => {
                                            if (cell.column.id === "actions") {
                                                return <div className="td" key={index} {...cell.getCellProps()}>
                                                    <div className="actions-right">
                                                        <OverlayTrigger
                                                            onClick={(e) => e.preventDefault()}
                                                            overlay={
                                                                <Tooltip>
                                                                    <FormattedMessage id="Button.editNoParams"/>
                                                                </Tooltip>
                                                            }
                                                        >
                                                            <Button
                                                                variant="success"
                                                                size="sm"
                                                                className="btn-link edit text-success"
                                                                onClick={() => props.handleEdit(row.original.id)}
                                                            >
                                                                <span className="edit-btn">{editIcon}</span>
                                                            </Button>
                                                        </OverlayTrigger>
                                                        {props.handleEditRules &&  <OverlayTrigger
                                                            onClick={(e) => e.preventDefault()}
                                                            overlay={
                                                                <Tooltip>
                                                                    <FormattedMessage id="Button.editRules" />
                                                                </Tooltip>
                                                            }
                                                        >
                                                            <Button
                                                                size="sm"
                                                                className={row.original.type?.toLocaleLowerCase() !== "form" ? "btn-link edit" : "btn-hidden btn-link edit"}
                                                                onClick={() => props.handleEditRules(row.original.id)}
                                                            >
                                                                <span className="tag-icon">{ruleIcon}</span>
                                                            </Button>
                                                        </OverlayTrigger>}
                                                        {row.original.type!== RECORD_TYPE_TYPE.FORM && row.original.type !== RECORD_TYPE_TYPE.MEMBER && row.original.type!== RECORD_TYPE_TYPE.PAYMENT && <OverlayTrigger
                                                            onClick={(e) => e.preventDefault()}
                                                            overlay={
                                                                <Tooltip>
                                                                    <FormattedMessage id="Button.delete"/>
                                                                </Tooltip>
                                                            }
                                                        >
                                                            <Button
                                                                variant="info"
                                                                size="sm"
                                                                className="btn-link remove text-danger"
                                                                onClick={() => props.handleDelete(row.original.id)}
                                                            >
                                                                <span className="trash-btn">{trashIcon}</span>
                                                            </Button>
                                                        </OverlayTrigger>}
                                                    </div>
                                                </div>
                                            } else {
                                                return <div className="td" key={index} {...cell.getCellProps()}>
                                                    <p> {cell.render('Cell')}</p>
                                                </div>
                                                    }
                                        })}
                                </div>
                            )
                        })}
                </div>
            </div>
            {!!!data.length && <h4 className="text-center"><FormattedMessage id="Validation.noInformation"/></h4>}
        </div>

    );
}


export default CrossaTable;
