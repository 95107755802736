import {WIDGET_OPERATOR, WIDGET_TYPE} from "../../../../util/Constants";
import {FormattedMessage} from "react-intl";
import React from "react";

export const widgetTypeOptions = [
    {
        "value": WIDGET_TYPE.WELCOME,
        "label": <FormattedMessage id="Widget.welcomeWidget"/>,
    },
    {
        "value": WIDGET_TYPE.TABLE,
        "label": <FormattedMessage id="Widget.table"/>,
    },
    {
        "value": WIDGET_TYPE.BAR_CHART,
        "label": <FormattedMessage id="Widget.barChart"/>,
    },
    {
        "value": WIDGET_TYPE.COLUMN_CHART,
        "label": <FormattedMessage id="Widget.columnChart"/>,
    },
    {
        "value": WIDGET_TYPE.PIE_CHART,
        "label": <FormattedMessage id="Widget.pieChart"/>,
    }
    ];

export const widgetOperatorOptions = [
    {
        "value": WIDGET_OPERATOR.ROW_COUNT,
        "label": <FormattedMessage id="WidgetOperator.rowCount"/>,
    },
    {
        "value": WIDGET_OPERATOR.SUM,
        "label": <FormattedMessage id="WidgetOperator.sum"/>,
    },
    {
        "value": WIDGET_OPERATOR.AVERAGE,
        "label": <FormattedMessage id="WidgetOperator.average"/>,
    },
    {
        "value": WIDGET_OPERATOR.MIN,
        "label": <FormattedMessage id="WidgetOperator.min"/>,
    },
    {
        "value": WIDGET_OPERATOR.MAX,
        "label": <FormattedMessage id="WidgetOperator.max"/>,
    }];

export const widgetRowsOptions=[
    {
        "value":5,
        "label":5
    },
    {
        "value":10,
        "label":10
    },
    {
        "value":15,
        "label":15
    },
]

