
import * as RecordTypeService from "service/RecordTypeService";

const getAllRecordTypesHandler=async(wid)=>{
    let recordTypes=[];
    const response = await RecordTypeService.findAllRecordTypes(wid);
    if (response.ok) {
        recordTypes=response.data;
    }

    return{
        data:{
            recordTypes:recordTypes
        },
        ok:response.ok
    }
}

const deleteRecordTypeHandler=async(id,wid)=>{
    let recordTypes=[];
    let ok=true;
    const responseDelete = await RecordTypeService.deleteRecordType(id);
    ok=ok&&responseDelete.ok;
    if (responseDelete.ok) {
        const responseFindAll = await RecordTypeService.findAllRecordTypes(wid);
        ok=ok&&responseFindAll.ok;
        if (responseFindAll.ok) {
            recordTypes=responseFindAll.data;
        }
    }

    return {
        data:{
            recordTypes:recordTypes,
        },
        error:responseDelete.data.error,
        ok:ok
    }
}
export{
    getAllRecordTypesHandler,
    deleteRecordTypeHandler
}
