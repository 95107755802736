import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { Button, Card, Col, Container, Form, } from "react-bootstrap";
import * as StorageService from "../service/StorageService.js"
import { FormattedMessage } from 'react-intl';
import * as Validations from "../validations/auth/LoginValidations.js";
import {getMemberMenuHandler, loginHandler} from "../handlers/LoginHandlers";
import {getAllWorkspacesHandler} from "../handlers/adminHandlers/workspaceHandlers/AllWorkspacesHandlers";
import FooterForLogin from "../components/FooterForLogin";
import UeLogos from "../components/UeLogos";

const LoginPage = () => {

    const history = useHistory();

    const [state, setState] = useState({
        user: {
            "username": "",
            "password": "",
        },
        errors: {
            "username": false,
            "password": false,
            "errorMessage": null
        },
    });

    const gotoMenuPage = async (wid) => {
        if(StorageService.getIsActive(wid)) {
            const response = await getMemberMenuHandler(wid);
            if (response.ok) {
                let firstMenu = response.data.menus?.[0];
                if (firstMenu) {
                    if (firstMenu.dashboard && firstMenu.dashboard.visible) {

                        history.push({
                            pathname: "/workspace-member/" + wid + "/menus/" + firstMenu.id + "/dashboard",
                            state: {
                                "loggedIn": true
                            },
                        });
                    } else {
                        history.replace("/workspace-member/" + wid + "/menus/" + firstMenu.id + "/page/0/tab/0");
                    }
                } else {// does not have a menu yet associated go to standard menu
                    history.replace("/workspace-member/" + wid + "/home");
                }
            }
        }else {
            history.replace("/workspace-member/" + wid + "/home");
        }
    }
    const [cardClasses,setCardClasses]=useState("hidden");
    useEffect(async () => {

        if (StorageService.getAccessToken()) {
            if (StorageService.getIsSuperAdmin() === "true") {
                history.replace("/admin/workspaces");
                return;
            } else {
                const response = await getAllWorkspacesHandler();
                if (response.ok) {
                    if (response.data.workspaces.length > 0) {
                        StorageService.setItem("workspace_name", response.data.workspaces[0].name);
                        StorageService.setItem("workspace_id", response.data.workspaces[0].id);
                        await gotoMenuPage(response.data.workspaces[0].id)
                        return;
                    }
                }
            }
        }

        let redirect = StorageService.getItem("redirect");
        StorageService.clearStorage();
        if(redirect) {
            StorageService.setItem("redirect", redirect)
        }
        setTimeout(function () {
            setCardClasses("");
        }, 1000);
    }, []);

    const login = async () => {
        const [newErrors, existErrors] = Validations.addLoginValidations(state.user, { ...state.errors });

        if (existErrors) {
            setState({
                ...state,
                errors: newErrors
            });
            return;
        }

        loginHandler(state.user).then(response => {
            if (response.ok) {
                setState({
                    ...state,
                    errors: newErrors
                });
                let redirect = StorageService.getItem("redirect");
                if(redirect) {
                    StorageService.removeItem("redirect");
                    history.replace(redirect.substring(1));
                    return;
                }
                if (StorageService.getIsSuperAdmin() !== null) {
                    if (StorageService.getIsSuperAdmin() === "true") {
                        history.replace("/admin/workspaces");
                    } else {
                        if(response.data.userWorkspaces.workspaceId){
                            StorageService.setItem("workspace_name", response.data.userWorkspaces.workspaceName);
                            StorageService.setItem("workspace_id", response.data.userWorkspaces.workspaceId);

                            gotoMenuPage(response.data.userWorkspaces.workspaceId);
                        }else{
                            setState({
                                ...state,
                                errors: {
                                    ...newErrors,
                                    "errorMessage": "LoginError.NoWorkspacesAvailable",
                                }
                            });
                        }
                        // history.replace("/workspace-member/" + response.data.userWorkspaces.workspaceId + "/home");
                    }
                } else {
                    console.log("Session storage Roles are Null");
                }
            } else {
                setState({
                    ...state,
                    errors: {
                        ...newErrors,
                        "errorMessage": response.message,
                    }
                });
            }
        });
    }

    const handlePasswordInput = event => {
        let newErrors = { ...state.errors };

        if (!!state.errors[event.target.name]) {
            newErrors = {
                ...newErrors,
                [event.target.name]: false,
            };
        }

        setState({
            ...state,
            user: {
                ...state.user,
                [event.target.name]: event.target.value.trim()
            },
            errors: newErrors
        });
    }

    const handleUsernameInput = event => {
        let newErrors = { ...state.errors };

        if (!!state.errors[event.target.name]) {
            newErrors = {
                ...newErrors,
                [event.target.name]: false,
            };
        }

        setState({
            ...state,
            user: {
                ...state.user,
                [event.target.name]: event.target.value.trim().toLowerCase()
            },
            errors: newErrors
        });
    }

    const handleSubmit = event => {
        event.preventDefault();
    }


    return (
        <>
            <UeLogos/>
            <div
                className="login-container"
            >
                <div className="content d-flex align-items-center p-0">
                    <Container>
                        <Col className={"login-card-container mx-auto " + cardClasses} lg="5" md="8">
                            <div className="logo-container">
                                <img height="85" className="logo-img" src={require("assets/img/logo_with_text_transparent.svg").default} alt="crossa logo icon" />
                            </div>
                            <Form action="" className="form" method="" onSubmit={handleSubmit}>
                                <Card className={"card-login"}>
                                    <Card.Header>
                                        <h3 className="header text-center"><FormattedMessage id="Login.title" /></h3>
                                    </Card.Header>
                                    <Card.Body>
                                        <Card.Body>
                                            <Form.Group>
                                                <label><FormattedMessage id="Login.username" /></label>
                                                <FormattedMessage id="Login.username">
                                                    {placeholder =>
                                                        <Form.Control
                                                            placeholder={placeholder}
                                                            name="username"
                                                            type="text"
                                                            onChange={handleUsernameInput}
                                                            className={state.errors.username ? "has-errors" : ""}
                                                            isInvalid={state.errors.username}
                                                        ></Form.Control>
                                                    }
                                                </FormattedMessage>
                                                <Form.Control.Feedback type="invalid">
                                                    <FormattedMessage id={"Validation.usernameInvalid"}/>
                                                </Form.Control.Feedback>
                                            </Form.Group>
                                            <Form.Group>
                                                <label><FormattedMessage id="Login.password" /></label>
                                                <FormattedMessage id="Login.password">
                                                    {placeholder =>
                                                        <Form.Control
                                                            placeholder={placeholder}
                                                            name="password"
                                                            type="password"
                                                            onChange={handlePasswordInput}
                                                            className={state.errors.password ? "has-errors" : ""}
                                                            isInvalid={state.errors.password}
                                                        ></Form.Control>
                                                    }
                                                </FormattedMessage>
                                                <Form.Control.Feedback type="invalid">
                                                    <FormattedMessage id={"Validation.passwordInvalid"}/>
                                                </Form.Control.Feedback>
                                            </Form.Group>
                                        </Card.Body>
                                    </Card.Body>
                                    <Card.Footer className="ml-auto mr-auto">
                                        <Button
                                            className="btn-wd"
                                            type="submit"
                                            variant="primary"
                                            onClick={login}
                                        >
                                            <FormattedMessage id="Login.loginBtn" />
                                        </Button>


                                        {state.errors.errorMessage != null ?
                                            <div className='errorMsg text-center text-danger'>
                                                <i className="fas fa-exclamation-triangle"></i>
                                                <FormattedMessage id={state.errors.errorMessage}/>

                                            </div>
                                            : null}
                                    </Card.Footer>
                                </Card>
                            </Form>
                        </Col>
                    </Container>
                    <FooterForLogin/>
                </div>

            </div>
        </>
    );
}

export default LoginPage;
