import React, {useEffect} from "react";
import {Route, Switch} from "react-router-dom";
import Sidebar from "components/Sidebar.js";
import routes from "routes/WorkspaceMemberRoutes.js"
import {CONTEXT} from "util/Constants";

function WorkspaceMemberLayout(props) {

    const getRoutes = (routes) => {
        return routes.map((prop, key) => {
            if (prop.collapse) {
                return getRoutes(prop.views);
            }
            if (prop.layout === "/workspace-member") {
                return (
                    <Route
                        path={prop.layout + prop.path}
                        key={key}
                        component={prop.component}
                        exact
                    />
                );
            } else {
                return null;
            }
        });
    };


    return (

        <div className="wrapper">
            <Sidebar
                isVisible={true}
                routes={routes}
                context={CONTEXT.WORKSPACE_MEMBER}
            />
            <div className="main-panel">
                <div className="content">
                    <Switch>{getRoutes(routes)}</Switch>
                </div>
                <div
                    className="close-layer"
                    onClick={() =>
                        document.documentElement.classList.toggle("nav-open")
                    }
                />
            </div>
        </div>
    );
}


export default WorkspaceMemberLayout;
