import React, {useEffect, useRef, useState} from "react";
import {useHistory} from "react-router-dom";
import Footer from "components/Footer.js"
import TreeMenu from "views/workspaceAdmin/view/components/TreeMenu/TreeMenu.js";
import ViewTableRow from "views/workspaceAdmin/view/components/ViewTableRow.js";
import EmptyViewTableRow from "views/workspaceAdmin/view/components/EmptyViewTableRow.js";
import RuleEditor from "views/workspaceAdmin/view/components/Rule/RuleEditor.js";
import EmptyRuleComponent from "views/workspaceAdmin/view/components/Rule/EmptyRuleComponent.js";
import * as QueryBuilder from "util/query/queryBuilder.js";
import SweetAlert from 'react-bootstrap-sweetalert';
import {FormattedMessage} from 'react-intl';
import {Button, Card, Col, Form, Row,} from "react-bootstrap";
import {getRecordsTypeAndViewsHandler} from "handlers/workspaceAdminHandlers/viewHandlers/AllViewsHandlers";
import {saveViewHandler} from "handlers/workspaceAdminHandlers/viewHandlers/AllViewsHandlers";
import {deleteViewHandler} from "handlers/workspaceAdminHandlers/viewHandlers/AllViewsHandlers";
import * as Validations from "validations/workspaceAdmin/view/ViewValidations";
import {addIconWithoutBg} from "../../../components/Icons";
import CrossaPageTemplate from "../../../components/CrossaPageTemplate";
import AdminNavbar from "../../../components/AdminNavbar";
import {getNotifyErrorOptions, getNotifySuccessOptions} from "util/Util";
import NotificationAlert from "react-notification-alert";
import OperatorComponent from "./components/Rule/OperatorComponent";
import {RECORD_FIELD_TYPE, RECORD_TYPE_TYPE} from "../../../util/Constants";
import {getRecordTypesHandler} from "handlers/workspaceAdminHandlers/viewHandlers/AddViewHandlers";

function AllViewsPage(props) {
    const myRef = useRef(null);
    const wid = props.match.params.wid;
    const [state, setState] = useState({
        displayView: {
            "id": "",
            "name": "",
            "wid": "",
            "type": "",
            "recordFields": [],
            "isRecordView": false,
            "useRecordsAsTags": false,
        },
        rules: [],
        treeItems: [],
        selectedRecordView: false,
        alert: null,
        errors: {
            "name": false,
            rules:{},
        }
    });

    const [saveDisabled, setSaveDisabled] = useState(false);

    const history = useHistory();

    useEffect(() => {
        getPageContent();
    }, []);


    const getPageContent = async () => {
        getRecordsTypeAndViewsHandler(wid).then(response => {
            if (response.ok) {
                setState({
                    ...state,
                    ...response.data
                });
            }
        })
    }

    const handleNameChange = event => {
        let newErrors = {...state.errors};

        if (!!state.errors["name"]) {
            newErrors = {
                ...newErrors,
                ["name"]: false,
            };
        }
        setState({
            ...state,
            displayView: {
                ...state.displayView,
                "name": event.target.value,
            },
            errors: newErrors
        });
    }

    const handleUseRecordsAsTagsChange = () => {
        setState({
            ...state,
            displayView: {
                ...state.displayView,
                "useRecordsAsTags": !state.displayView.useRecordsAsTags,
            }
        });
    }

    const handlePaymentAvailableChange = () => {
        setState({
            ...state,
            displayView: {
                ...state.displayView,
                "paymentAvailable": !state.displayView.paymentAvailable,
            }
        });
    }

    const prepareData = data => {
        return data.map(item => {
            return {
                ...item,
                "selectItem": () => {
                },
                "addItem": addRecordView,
                "views": item.views.map(view => {
                    return {
                        ...view,
                        "selectItem": selectRecordView,
                        "addItem":() => {
                        },
                    }
                })
            }
        })
    }

    const addRule = () => {
        let newRules;
        let newRule = {}
        if (state.rules.length > 0) {
            const operator = {"operator": "And"}
            newRules = [...state.rules, operator, newRule];
        } else {
            newRules = [...state.rules, newRule];
        }
        setState({
            ...state,
            rules: newRules
        });
    }

    const changeRule = (rule, index,newRuleErrors) => {
        setState({
            ...state,
            rules: [...state.rules.slice(0, index), rule, ...state.rules.slice(index + 1)],
            errors: {
                ...state.errors,
                rules:newRuleErrors
            }
        });
    }

    const saveChanges = async () => {
        const [newErrors, existErrors] = Validations.addViewValidations(state.displayView,state.rules, {...state.errors});
        setState({
            ...state,
            errors: newErrors
        });
        if (existErrors) {
            let options=getNotifyErrorOptions("bc");
            notificationAlertRef.current.notificationAlert(options);
            return;
        }

        setSaveDisabled(true);

        const newView = {
            "name": state.displayView.name,
            "id": state.displayView.id,
            "ns": state.displayView.ns,
            "fieldModes": state.displayView.recordFields.map(recordField => {
                return {
                    "id": recordField.id,
                    "mode": recordField.mode,
                }
            }),
            "rtid": state.displayView.rtid,
            "wid": state.displayView.wid,
            "useRecordsAsTags": state.displayView.useRecordsAsTags,
        }
        if(state.displayView.type===RECORD_TYPE_TYPE.PAYMENT){
            newView["paymentAvailable"]=state.displayView.paymentAvailable;
        }
        if (state.rules.length > 0) {
            newView["query"] = JSON.stringify(QueryBuilder.rulesToQuery(JSON.parse(JSON.stringify(state.rules))));
        } else{
            newView["query"]="";
        }
        saveViewHandler(newView).then(response => {
            let newState={...state};
            if (response.ok) {
                let newTreeItems=JSON.parse(JSON.stringify(state.treeItems));

                newTreeItems=newTreeItems.map(item=>{
                    if(item.id===state.displayView.rtid){
                        item.views=item.views.map(view=>{
                            if(view.id===state.displayView.id){
                                if(response.data?.id){
                                    view.id=response.data.id;
                                }
                                view.name=newView.name;
                                view.fieldModes=view.fieldModes.map(fieldMode=>{
                                    fieldMode.recordViewFieldMode={...newView.fieldModes.find(newFieldMode=>newFieldMode.id===fieldMode.recordTypeField.id)};
                                    return fieldMode;
                                });
                                view.useRecordsAsTags=newView.useRecordsAsTags;
                                view.paymentAvailable=newView.paymentAvailable;
                                view.query=newView.query;
                            }
                            return view;
                        });
                    }
                    return item;
                });

                newState={
                    ...newState,
                    treeItems: newTreeItems,
                };
                if(response.data?.id){
                    newState={
                        ...newState,
                        displayView: {
                            ...newState.displayView,
                            "id": response.data.id,
                        }
                    }
                }
                setState(newState);
                let options=getNotifySuccessOptions("bc");
                notificationAlertRef.current.notificationAlert(options);
            }
            setSaveDisabled(false);
        });
    }

    const cancelChanges = () => {
        history.push("/workspace-admin/temporary/");
        history.goBack();
    }

    const deleteAlert = (id) => {
        setState({
            ...state,
            alert: <SweetAlert
                type="danger"
                title=""
                style={{display: "block", marginTop: "-100px", fontSize: "13px"}}
                btnSize="md"
                confirmBtnBsStyle="danger"
                cancelBtnBsStyle="default"
                showCancel
                cancelBtnText={<FormattedMessage id="Button.cancel"/>}
                confirmBtnText={<FormattedMessage id="Button.delete"/>}
                openAnim="false"
                onConfirm={() => deleteView(id)}
                onCancel={() => hideAlert()}
                allowEscape
                closeOnClickOutside
                customClass="crossa-alert"

            > <FormattedMessage id="Alert.deleteView" />
            </SweetAlert>
        });
    }

    const hideAlert = () => {
        setState({
            ...state,
            alert: null
        });
    }

    const deleteView = async id => {
        if(id===-1){
            const newTreeItems = [...state.treeItems.map(item => {
                return {
                    ...item,
                    "views": item.views.map(view => {
                        if(view.id!==-1)
                            return {
                                ...view,
                                "selected": false,
                            }
                        else return null;
                    }).filter(view=>view),
                    "selected": false,
                }
            })];
            setState({
                ...state,
                treeItems: newTreeItems,
                selectedRecordView: false
            });
        } else {
            deleteViewHandler(id).then(response => {
                if (response.ok) {
                    hideAlert();
                    history.go(0);
                }
            });
        }
    }

    const deleteRule = id => {
        const newRules = state.rules.filter((rule, index) => {
            if (id === 0) return index > 1;
            else return index !== id && index + 1 !== id;
        });
        setState({
            ...state,
            rules: newRules,
            errors: {
                ...state.errors,
                rules: {}
            }
        });
    }

    const handleSelectChange = (mode, id) => {
        const newRecordFields = [...state.displayView.recordFields];
        let index = newRecordFields.findIndex(field => field.id === id);
        newRecordFields[index].mode = mode;
        if (newRecordFields[index].type === RECORD_FIELD_TYPE.GROUP_HEADER) {
            for (let i = index + 1; i < newRecordFields.length; i++) {
                if (newRecordFields[i].type === RECORD_FIELD_TYPE.GROUP_HEADER) {
                    break;
                } else {
                    newRecordFields[i].mode = mode;
                }
            }
        }
        setState({
            ...state,
            displayView: {
                ...state.displayView,
                newRecordFields
            }
        });
    }

    const selectRecordView = (prop) => {
        const parentIndex = state.treeItems.findIndex(item => item.name === prop.parentName);

        const newTreeItems = [...state.treeItems.map(item => {
            return {
                ...item,
                "views": item.views.map(view => {
                    if(view.id!==-1)
                        return {
                            ...view,
                            "selected": false,
                        }
                    else return null;
                }).filter(view=>view),
                "selected": false,
            }
        })];
        newTreeItems[parentIndex].views[prop.index].selected = true;

        const tempRecordView = state.treeItems[parentIndex].views[prop.index];
        // const parentNodeFields = [...treeItems[parentIndex].fieldModes];
        const fieldsMap = {};
        for (let i = 0; i < tempRecordView.fieldModes.length; ++i) {
            fieldsMap[tempRecordView.fieldModes[i].recordViewFieldMode.id] =
                {
                    ...tempRecordView.fieldModes[i].recordViewFieldMode,
                    ...tempRecordView.fieldModes[i].recordTypeField
                };
        }

        const newRules = tempRecordView.query ? QueryBuilder.queryToRules([JSON.parse(tempRecordView.query)], fieldsMap, null, JSON.parse(tempRecordView.query).type === "Or", true) : [];
        setState({
            ...state,
            treeItems: newTreeItems,
            rules: newRules,
            displayView: {
                "id": tempRecordView.id,
                "name": tempRecordView.name,
                "rtid": state.treeItems[parentIndex].id,
                "ns": state.treeItems[parentIndex].ns,
                "recordFields": tempRecordView.fieldModes.map((field) => {
                    return {
                        ...field.recordViewFieldMode,
                        ...field.recordTypeField
                    }
                }),
                "wid": state.treeItems[parentIndex].wid,
                "isRecordView": true,
                "type": tempRecordView.type,
                "delete": tempRecordView.delete,
                "useRecordsAsTags": tempRecordView.useRecordsAsTags ? tempRecordView.useRecordsAsTags : false,
                "paymentAvailable":tempRecordView.paymentAvailable ? tempRecordView.paymentAvailable : false,
            },
            errors: {
                ...state.errors,
                name: false
            },
            selectedRecordView: true
        });
    }

    const addRecordView = (index,rtid) => {
        const newTreeItems = [...state.treeItems.map(item => {
            return {
                ...item,
                "views": item.views.map(view => {
                    if(view.id!==-1)
                        return {
                            ...view,
                            "selected": false,
                        }
                    else return null;
                }).filter(view=>view),
                "selected": false,
            }
        })];

        getRecordTypesHandler(props.match.params.wid,rtid).then(response => {
            if (response.ok) {
                newTreeItems[index].views.push({
                    selected:true,
                    delete:true,
                    name: "",
                    useRecordsAsTags: false,
                    paymentAvailable:false,
                    id:-1,
                    fieldModes:response.data.recordFields
                });
                setState({
                    ...state,
                    treeItems: newTreeItems,
                    rules: [],
                    displayView: {
                        "id":-1,
                        "name": "",
                        "rtid": rtid,
                        "ns": state.treeItems[index].ns,
                        "recordFields": response.data.recordFields?.map((field) => {
                            return {
                                ...field.recordViewFieldMode,
                                ...field.recordTypeField
                            }
                        }),
                        "wid": props.match.params.wid,
                        "isRecordView": true,
                        "delete": true,
                        "useRecordsAsTags": false,
                        "paymentAvailable":false,
                    },
                    errors: {
                        ...state.errors,
                        name: false
                    },
                    selectedRecordView: true
                });
            }
        })
    }

    const renderRules = () => {
        if (state.rules.length === 0) {
            return <EmptyRuleComponent/>
        }
        return state.rules.map((rule, index) => {
                return index % 2 === 0 ?
                    <React.Fragment key={index}>
                        <RuleEditor
                            key={index}
                            index={index}
                            rule={rule}
                            recordFields={state.displayView.recordFields}
                            errors={state.errors.rules}
                            deleteRule={deleteRule}
                            changeRule={changeRule}
                        />
                        {
                            (index + 1) !== state.rules.length &&
                            <OperatorComponent key={index + 1}
                                               operator={state.rules[index + 1].operator}
                                               index={index + 1} changeRule={changeRule}
                            />
                        }
                    </React.Fragment>

                    : <React.Fragment key={index}/>
            }
        )
    }
    const notificationAlertRef = React.useRef(null);

    return (
        <>
            <div className="rna-container">
                <NotificationAlert ref={notificationAlertRef} />
            </div>
            {state.alert}
            <AdminNavbar
                title={<FormattedMessage id="Views"/>}
            />
            <CrossaPageTemplate>
                <div className="row">
                    {state.treeItems.length > 0 ?
                        <Col md={4}>
                            <div className="tree-menu-container">
                                <TreeMenu
                                    propRef={myRef}
                                    data={prepareData(state.treeItems)}
                                    deleteView={deleteAlert}
                                />
                            </div>
                        </Col>
                        : null}
                    {

                        <Col md={8}>
                            {
                                state.selectedRecordView ?
                                    (
                                        <>
                                            <div>
                                                <Row className="top-row">
                                                    <Col className="title">
                                                        <Card.Header>
                                                            <Card.Title as="h3"><FormattedMessage
                                                                id="Name"/></Card.Title>
                                                        </Card.Header>
                                                    </Col>

                                                </Row>
                                                <Card.Body>
                                                    <Form>
                                                        <Form.Group>
                                                            <FormattedMessage id="Name">
                                                                {placeholder =>
                                                                    <Form.Control
                                                                        name="name"
                                                                        placeholder={placeholder}
                                                                        type="text"
                                                                        value={state.displayView.name || ""}
                                                                        onChange={handleNameChange}
                                                                        isInvalid={state.errors.name}
                                                                        className={state.errors.name && "has-errors"}
                                                                    />
                                                                }
                                                            </FormattedMessage>
                                                            <Form.Control.Feedback type="invalid">
                                                                <FormattedMessage id={"Validation.cannotBeEmpty"}/>
                                                            </Form.Control.Feedback>
                                                        </Form.Group>

                                                    </Form>
                                                </Card.Body>
                                            </div>
                                            <div>
                                                <Row>
                                                    <Col md={12}>
                                                        <Card.Header>
                                                            <Card.Title as="h3"><FormattedMessage
                                                                id="Fields"/></Card.Title>
                                                        </Card.Header>
                                                    </Col>
                                                </Row>
                                                <Card.Body>
                                                    <table id="table"
                                                           className="table table-with-group-headers table-fixed">
                                                        <thead>
                                                        <tr className="no-borders">
                                                            <th>
                                                                <Form.Group className="mb-0">
                                                                    <Form.Label>{<FormattedMessage
                                                                        id="Name"/>}</Form.Label>
                                                                </Form.Group>
                                                            </th>
                                                            <th>
                                                                <Form.Group className="mb-0">
                                                                    <Form.Label>{<FormattedMessage
                                                                        id="Type"/>}</Form.Label>
                                                                </Form.Group>
                                                            </th>
                                                            <th>
                                                                <Form.Group className="mb-0">
                                                                    <Form.Label>{<FormattedMessage
                                                                        id="Operator"/>}</Form.Label>
                                                                </Form.Group>
                                                            </th>
                                                        </tr>
                                                        </thead>
                                                        <tbody>


                                                        {
                                                            state.displayView.recordFields.length === 0 ?
                                                                <EmptyViewTableRow/>
                                                                :
                                                                state.displayView.recordFields.filter(field => field.type !== RECORD_FIELD_TYPE.NOTIFICATIONS && field.type !== RECORD_FIELD_TYPE.WORKSPACE_USER).map((recordField, index) => {
                                                                    let hasBorders = false;
                                                                    for (let i = 0; i <= index; i++) {
                                                                        if (state.displayView.recordFields[i].type === RECORD_FIELD_TYPE.GROUP_HEADER) {
                                                                            hasBorders = true;
                                                                            break;
                                                                        }
                                                                    }
                                                                    return (
                                                                        <React.Fragment key={index}>
                                                                            {recordField.type === RECORD_FIELD_TYPE.GROUP_HEADER &&
                                                                                <tr className="no-borders"
                                                                                    style={{height: "20px"}}/>}
                                                                            <ViewTableRow
                                                                                index={index}
                                                                                id={recordField.id}
                                                                                name={recordField.name}
                                                                                type={recordField.type}
                                                                                handleSelectChange={handleSelectChange}
                                                                                mode={recordField.mode}
                                                                                borders={hasBorders}
                                                                            />
                                                                        </React.Fragment>
                                                                    )
                                                                })
                                                        }
                                                        </tbody>
                                                    </table>
                                                </Card.Body>
                                            </div>

                                            <div className="rule-record-type-page">
                                                <Row>
                                                    <Col md={12}>

                                                        <Card.Header>
                                                            <Card.Title as="h3"><FormattedMessage
                                                                id="FilterRules"/></Card.Title>
                                                        </Card.Header>
                                                    </Col>
                                                </Row>
                                                <Card.Body>

                                                    {
                                                        renderRules()
                                                    }
                                                    <Button variant="link" onClick={addRule}>
                                                        <span className="icon-cont">
                                                            {addIconWithoutBg}
                                                        </span>
                                                        <FormattedMessage id="Button.addRule"/>
                                                    </Button>
                                                </Card.Body>
                                            </div>
                                            <Col md={12}>
                                                <Card.Body className="pl-0">
                                                    <label className="d-flex align-items-center">
                                                        <span
                                                            className="switch-label mr-5"><FormattedMessage id="UseViewAsTags"/></span>
                                                        <Form.Check
                                                            type="switch"
                                                            id="crossa-pre-selected-option-switch"
                                                            checked={state.displayView.useRecordsAsTags}
                                                            onChange={handleUseRecordsAsTagsChange}
                                                        />
                                                    </label>
                                                </Card.Body>
                                            </Col>
                                            {state.displayView.type===RECORD_TYPE_TYPE.PAYMENT && <Col md={12}>
                                                <Card.Body className="pl-0">
                                                    <label className="d-flex align-items-center">
                                                        <span
                                                            className="switch-label mr-5"><FormattedMessage id="PaymentAvailable"/></span>
                                                        <Form.Check
                                                            type="switch"
                                                            id="crossa-pre-selected-option-switch-payment"
                                                            checked={state.displayView.paymentAvailable}
                                                            onChange={handlePaymentAvailableChange}
                                                        />
                                                    </label>
                                                </Card.Body>
                                            </Col>}
                                        </>)
                                    :
                                    (
                                        <div className="msg-container">
                                            <Card.Body>

                                                {state.treeItems.length === 0 ?
                                                    <h4><FormattedMessage id="Validation.noInformation"/></h4>

                                                    :
                                                    <h4><FormattedMessage id="Hint.SelectAviewForDetails"/></h4>

                                                }
                                            </Card.Body>
                                        </div>
                                    )
                            }
                        </Col>

                    }
                </div>
            </CrossaPageTemplate>
            <Footer
                save={saveChanges}
                saveDisabled={saveDisabled}
                cancel={cancelChanges}
                isEnable={state.displayView.isRecordView}/>
        </>
    );
}

export default AllViewsPage;
