import * as Validations from "validations/validations.js"
import {VALIDATIONS, WIDGET_OPERATOR, WIDGET_TYPE} from "../../../util/Constants";

const addMenuValidations = (newMenuName, errors) => {
    if (newMenuName.length > VALIDATIONS.MAX_LENGTH_DEFAULT|| newMenuName.length < VALIDATIONS.MIN_LENGTH_DEFAULT) {
        errors = {
            ...errors,
            "name": true,
        }
    }

    const existErrors = Validations.checkIfError(errors);

    return [errors, existErrors];
}

const addMenuWithPagesValidations=(menu,errors)=>{
        if (!!!menu.name || !!!menu.wid || menu.name.length > VALIDATIONS.MAX_LENGTH_DEFAULT || menu.name.length < VALIDATIONS.MIN_LENGTH_DEFAULT) {
            errors = {
                ...errors,
                "name": true,
            }
        }
        for (let [index,page] of menu.pages.entries()) {
            if (!!!page.name) {
                errors = {
                    ...errors,
                    pages:{
                        ...errors.pages,
                        [index]:{
                            ...errors.pages?.[index],
                            "name":true,
                        }
                    }
                }
            }

            if (page.enableAddAction && !!!page.addActionName) {
                errors = {
                    ...errors,
                    pages:{
                        ...errors.pages,
                        [index]:{
                            ...errors.pages?.[index],
                            "addActionName":true,
                        }
                    }
                }
            }
            if(page.enableAddAction && !!!page.addActionVid){
                errors = {
                    ...errors,
                    pages:{
                        ...errors.pages,
                        [index]:{
                            ...errors.pages?.[index],
                            "addActionVid":true,
                        }
                    }
                }
            }

            for (let [indexTab,tab] of page.tabs.entries()) {
                if(!!!tab.name){
                    errors = {
                        ...errors,
                        pages:{
                            ...errors.pages,
                            [index]:{
                                ...errors.pages?.[index],
                                "tabs":{
                                    ...errors.pages?.[index]?.tabs,
                                    [indexTab]:{
                                        ...errors.pages?.[index]?.tabs?.[indexTab],
                                        "name":true
                                    }
                                }
                            }
                        }
                    }
                }
                if(!!!tab.displayMode){
                    errors = {
                        ...errors,
                        pages:{
                            ...errors.pages,
                            [index]:{
                                ...errors.pages?.[index],
                                "tabs":{
                                    ...errors.pages?.[index]?.tabs,
                                    [indexTab]:{
                                        ...errors.pages?.[index]?.tabs?.[indexTab],
                                        "displayMode":true
                                    }
                                }
                            }
                        }
                    }
                }
                if(!!!tab.detailedViewMode){
                    errors = {
                        ...errors,
                        pages:{
                            ...errors.pages,
                            [index]:{
                                ...errors.pages?.[index],
                                "tabs":{
                                    ...errors.pages?.[index]?.tabs,
                                    [indexTab]:{
                                        ...errors.pages?.[index]?.tabs?.[indexTab],
                                        "detailedViewMode":true
                                    }
                                }
                            }
                        }
                    }
                }
                if(!!!tab.vid){
                    errors = {
                        ...errors,
                        pages:{
                            ...errors.pages,
                            [index]:{
                                ...errors.pages?.[index],
                                "tabs":{
                                    ...errors.pages?.[index]?.tabs,
                                    [indexTab]:{
                                        ...errors.pages?.[index]?.tabs?.[indexTab],
                                        "vid":true
                                    }
                                }
                            }
                        }
                    }
                }
                if(!!!tab.displayFields.length){
                    errors = {
                        ...errors,
                        pages:{
                            ...errors.pages,
                            [index]:{
                                ...errors.pages?.[index],
                                "tabs":{
                                    ...errors.pages?.[index]?.tabs,
                                    [indexTab]:{
                                        ...errors.pages?.[index]?.tabs?.[indexTab],
                                        "displayFields":true
                                    }
                                }
                            }
                        }
                    }
                }
                if(tab.enableDefaultSorting && !!!tab.sortingField){
                    errors = {
                        ...errors,
                        pages:{
                            ...errors.pages,
                            [index]:{
                                ...errors.pages?.[index],
                                "tabs":{
                                    ...errors.pages?.[index]?.tabs,
                                    [indexTab]:{
                                        ...errors.pages?.[index]?.tabs?.[indexTab],
                                        "sortingField":true
                                    }
                                }
                            }
                        }
                    }
                }
                if(tab.enableMessages && !!!tab.messageBtnName){
                    errors = {
                        ...errors,
                        pages:{
                            ...errors.pages,
                            [index]:{
                                ...errors.pages?.[index],
                                "tabs":{
                                    ...errors.pages?.[index]?.tabs,
                                    [indexTab]:{
                                        ...errors.pages?.[index]?.tabs?.[indexTab],
                                        "messageBtnName":true
                                    }
                                }
                            }
                        }
                    }
                }
                if(tab.enableMessages && !!!tab.messageEmailField){
                    errors = {
                        ...errors,
                        pages:{
                            ...errors.pages,
                            [index]:{
                                ...errors.pages?.[index],
                                "tabs":{
                                    ...errors.pages?.[index]?.tabs,
                                    [indexTab]:{
                                        ...errors.pages?.[index]?.tabs?.[indexTab],
                                        "messageEmailField":true
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    for (let [indexWidget,widget] of menu.dashboard.widgets.entries()) {
        if(!widget.title){
            errors = {
                ...errors,
                dashboard:{
                    ...errors.dashboard,
                    "widgets":{
                        ...errors.dashboard?.widgets,
                        [indexWidget]:{
                            ...errors.dashboard?.widgets?.[indexWidget],
                            "title":true
                        }
                    }
                }
            }
        }
        if(!widget.description){
            errors = {
                ...errors,
                dashboard:{
                    ...errors.dashboard,
                    "widgets":{
                        ...errors.dashboard?.widgets,
                        [indexWidget]:{
                            ...errors.dashboard?.widgets?.[indexWidget],
                            "description":true
                        }
                    }
                }
            }
        }
        if(widget.type!==WIDGET_TYPE.WELCOME && !widget.vid){
            errors = {
                ...errors,
                dashboard:{
                    ...errors.dashboard,
                    "widgets":{
                        ...errors.dashboard?.widgets,
                        [indexWidget]:{
                            ...errors.dashboard?.widgets?.[indexWidget],
                            "vid":true
                        }
                    }
                }
            }
        }
        if(widget.type===WIDGET_TYPE.TABLE && !widget.rows){
            errors = {
                ...errors,
                dashboard:{
                    ...errors.dashboard,
                    "widgets":{
                        ...errors.dashboard?.widgets,
                        [indexWidget]:{
                            ...errors.dashboard?.widgets?.[indexWidget],
                            "rows":true
                        }
                    }
                }
            }
        }
        if(widget.type===WIDGET_TYPE.TABLE && !widget.displayFields?.length){
            errors = {
                ...errors,
                dashboard:{
                    ...errors.dashboard,
                    "widgets":{
                        ...errors.dashboard?.widgets,
                        [indexWidget]:{
                            ...errors.dashboard?.widgets?.[indexWidget],
                            "displayFields":true
                        }
                    }
                }
            }
        }
        if(widget.type===WIDGET_TYPE.TABLE && widget.defaultSorting && !widget.sortingField){
            errors = {
                ...errors,
                dashboard:{
                    ...errors.dashboard,
                    "widgets":{
                        ...errors.dashboard?.widgets,
                        [indexWidget]:{
                            ...errors.dashboard?.widgets?.[indexWidget],
                            "sortingField":true
                        }
                    }
                }
            }
        }
        if((widget.type===WIDGET_TYPE.COLUMN_CHART || widget.type===WIDGET_TYPE.BAR_CHART || widget.type===WIDGET_TYPE.PIE_CHART) && !widget.groupingField){
            errors = {
                ...errors,
                dashboard:{
                    ...errors.dashboard,
                    "widgets":{
                        ...errors.dashboard?.widgets,
                        [indexWidget]:{
                            ...errors.dashboard?.widgets?.[indexWidget],
                            "groupingField":true
                        }
                    }
                }
            }
        }
        if((widget.type===WIDGET_TYPE.COLUMN_CHART || widget.type===WIDGET_TYPE.BAR_CHART) && !widget.operator){
            errors = {
                ...errors,
                dashboard:{
                    ...errors.dashboard,
                    "widgets":{
                        ...errors.dashboard?.widgets,
                        [indexWidget]:{
                            ...errors.dashboard?.widgets?.[indexWidget],
                            "operator":true
                        }
                    }
                }
            }
        }
        if((widget.type===WIDGET_TYPE.COLUMN_CHART || widget.type===WIDGET_TYPE.BAR_CHART) && widget.operator!==WIDGET_OPERATOR.ROW_COUNT && !widget.operatorField){
            errors = {
                ...errors,
                dashboard:{
                    ...errors.dashboard,
                    "widgets":{
                        ...errors.dashboard?.widgets,
                        [indexWidget]:{
                            ...errors.dashboard?.widgets?.[indexWidget],
                            "operatorField":true
                        }
                    }
                }
            }
        }
    }
    const existErrors = Validations.checkIfError(errors);

    return [errors, existErrors];
}

export {
    addMenuValidations,
    addMenuWithPagesValidations
}
