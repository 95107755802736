import * as MemberService from "service/MemberService";
import * as MenuService from "service/MenuService";
import * as RecordService from "service/RecordService";
import * as ViewService from "service/ViewsService";
import * as FormService from "service/FormService";
import * as PaymentService from "service/PaymentService";
import * as MessageService from "service/MessageService";
import EXCEPTION_MESSAGES, {RECORD_FIELD_TYPE, RECORD_TYPE_TYPE} from "util/Constants";
import * as WorkspaceRoleService from "../../../service/WorkspaceRoleService";
import currencies from "currency-codes";

const findMemberAndWorkspaceRolesHandler = async (wid) => {
    let memberTags = [];
    let currentMember={};
    let workspaceRoles=[];

    const response = await MemberService.findMemberMe(wid);
    if (response.ok) {
        if (response.data.notifications?.tags) {
            memberTags = response.data.notifications.tags;
        }
        currentMember=response.data;
    }
    const responseAllWorkspaceRole=await WorkspaceRoleService.findAllWorkspaceRoles(wid);
    if(responseAllWorkspaceRole.ok){
        workspaceRoles=responseAllWorkspaceRole.data;
    }

    return {
        data: {
            memberTags: memberTags,
            currentMember:currentMember,
            workspaceRoles:workspaceRoles
        },
        ok: response.ok
    };
}

const getRecordsByViewId = async (viewId,enableEditAction) => {
    let records = {};
    let recordTypeFields = [];
    let fieldsTypeById = {};
    let fieldsOptionsColorsById = {};
    let script = null;

    const response = await RecordService.findAllRecordsInView(viewId);
    let helperObj = {};
    if (response.ok) {
        response.data.recordType.fields.forEach(field => {
            helperObj = {
                ...helperObj,
                [field.id]: field.type,
            }
            if (field.type === "SingleChoice" && field.optionsColors) {
                let fieldOptionsColorsAux = {};
                field.optionsColors.map((optionColor, index) => {
                    fieldOptionsColorsAux = {...fieldOptionsColorsAux, [field.options[index]]: optionColor}
                });
                fieldsOptionsColorsById = {
                    ...fieldsOptionsColorsById,
                    [field.id]: fieldOptionsColorsAux
                }
            }

            let fieldMode = response.data.recordView.fieldModes.find(fieldMode => fieldMode.id === field.id);
            if (field.type === "Record") {
                let recordTypesInfo = response.data.recordTypesInfo;
                let recordType = recordTypesInfo?.find(recordType => recordType.id === field.rtid);
                if (recordType && recordType.type === "Form") {
                    field.displayOption = "FormLink";
                }
            }
            if(field.type==="Record"||field.type==="RecordList"){
                let recordView=response.data.recordViewsInfo?.find(recordView=>recordView.id===field.vid);
                if(recordView){
                    field.useRecordsAsTags=recordView.useRecordsAsTags;
                }
            }
            if (enableEditAction===false) {
                recordTypeFields.push({
                    ...fieldMode,
                    mode: fieldMode ? (fieldMode.mode === "WRITE" ? "READ" : fieldMode.mode) : "HIDE",
                    ...field
                })
            } else {
                recordTypeFields.push({
                    ...fieldMode,
                    ...field
                })
            }
        });
        records = {
            "values": response.data.records,
            ...helperObj
        };
        fieldsTypeById = {...helperObj};
        script = response.data.recordType?.script;
    }

    return {
        data: {
            initRecordTypeFields: recordTypeFields,
            records: records,
            recordTypeFields: recordTypeFields,
            fieldsOptionsColorsById: fieldsOptionsColorsById,
            fieldsTypeById: fieldsTypeById,
            script: script,
        },
        ok: response.ok
    };
}

const getFieldsByViewId = async (viewId) => {
    let fields = [];

    const response = await ViewService.findViewById(viewId);
    if (response.ok) {
        response.data.fieldModes.map(field => fields.push(field.recordViewFieldMode));
    }

    return {
        data: fields,
        ok: response.ok
    };
}

const getAddActionRecordView = async viewId => {
    let recordFields = [];
    const response = await ViewService.findViewById(viewId);
    if (response.ok) {
        for (let i = 0; i < response.data.fieldModes.length; i++) {
            let field = response.data.fieldModes[i];
            let recordTypeField = field.recordTypeField;
            if (field.recordTypeField.type === "Record" && field.rtType === "Form") {
                recordTypeField.displayOption = "FormLink";
            }
            recordFields.push({
                ...field.recordViewFieldMode,
                ...recordTypeField
            })
        }
    }
    return {
        data: {
            initInsertRecordFields: recordFields,
            insertRecordFields: recordFields,
        },
        ok: response.ok
    };
}

const getDataForMenuHandler = async (menuId, tabid, pageIndex, menuIdFromAllPages, allPagesFromState,request) => {

    let data = {};
    let ok = true;
    if (menuIdFromAllPages !== menuId) {
        const responseMenu = await MenuService.findById(menuId);
        ok = ok && responseMenu.ok;
        if (responseMenu.ok) {
            data.allPages = {
                mid: menuId,
                pages: [...responseMenu.data.pages]
            }
            request.pageable={
            ...request.pageable,
                    sort:data.allPages.pages[pageIndex]?.tabs[tabid].enableDefaultSorting?[data.allPages.pages[pageIndex]?.tabs[tabid].sortingField+","+data.allPages.pages[pageIndex]?.tabs[tabid].sortDirection]:null
            }

            if (data.allPages?.pages.length && data.allPages.pages[pageIndex]?.tabs[tabid]?.vid && data.allPages.pages[pageIndex].tabs[tabid]?.vid !== 0) {
                const responseRecords = await getAllRecordsFilteredInViewHandler(data.allPages.pages[pageIndex]?.tabs[tabid]?.vid,menuId,pageIndex,tabid,request,data.allPages?.pages[pageIndex]?.tabs[tabid]?.enableEditAction);
                ok = ok && responseRecords.ok;
                if (responseRecords.ok) {
                    data = {
                        ...data,
                        ...responseRecords.data,
                        pageable:{
                            ...data.pageable,
                            sort:data.allPages.pages[pageIndex]?.tabs[tabid].enableDefaultSorting?[data.allPages.pages[pageIndex]?.tabs[tabid].sortingField+","+data.allPages.pages[pageIndex]?.tabs[tabid].sortDirection]:null
                        }
                    };
                    if (data.allPages.pages[pageIndex].addActionVid) {
                        const responseAddAction = await getAddActionRecordView(data.allPages.pages[pageIndex].addActionVid);
                        ok = ok && responseAddAction.ok;
                        if (responseAddAction.ok) {
                            data = {
                                ...data,
                                ...responseAddAction.data
                            };
                        }
                    }
                }
            }
        }
    } else {
        if (allPagesFromState?.pages.length && allPagesFromState.pages[pageIndex]?.tabs[tabid]?.vid && allPagesFromState.pages[pageIndex].tabs[tabid]?.vid !== 0) {
            request.pageable={
                ...request.pageable,
                sort:allPagesFromState.pages[pageIndex]?.tabs[tabid].enableDefaultSorting?[allPagesFromState.pages[pageIndex]?.tabs[tabid].sortingField+","+allPagesFromState.pages[pageIndex]?.tabs[tabid].sortDirection]:null
            }
            const responseRecords = await getAllRecordsFilteredInViewHandler(allPagesFromState.pages[pageIndex]?.tabs[tabid]?.vid,menuId,pageIndex,tabid,request,allPagesFromState?.pages[pageIndex]?.tabs[tabid]?.enableEditAction);
            ok = ok && responseRecords.ok;
            if (responseRecords.ok) {
                data = {
                    ...data,
                    ...responseRecords.data,
                    pageable:{
                        ...data.pageable,
                        sort:allPagesFromState.pages[pageIndex]?.tabs[tabid].enableDefaultSorting?[allPagesFromState.pages[pageIndex]?.tabs[tabid].sortingField+","+allPagesFromState.pages[pageIndex]?.tabs[tabid].sortDirection]:null
                    }
                };
                if (allPagesFromState.pages[pageIndex].addActionVid) {
                    const responseAddAction = await getAddActionRecordView(allPagesFromState.pages[pageIndex].addActionVid);
                    ok = ok && responseAddAction.ok;
                    if (responseAddAction.ok) {
                        data = {
                            ...data,
                            ...responseAddAction.data
                        };
                    }
                }
            }
        }
    }
    return {
        data: data,
        ok: ok
    };
}

const deleteRecordHandler = async (recordId, vid,mid,pid,tabid,request,enableEditAction) => {
    let ok = true;
    let data = {};
    const response = await RecordService.deleteRecordByViewId(recordId, vid);
    ok = ok && response.ok;
    if (response.ok) {
        let finalResponse = await getAllRecordsFilteredInViewHandler(vid,mid,pid,tabid,request,enableEditAction);
        ok = ok && finalResponse.ok;
        data = {
            ...data,
            ...finalResponse.data
        };
    }

    return {
        data: data,
        ok: ok
    };
}

const uploadImages = async (recordId, selectedFiles, vid) => {
    let fieldsIds = [];
    let files = [];
    for (const key in selectedFiles) {
        if(Array.isArray(selectedFiles[key])){
            for(let i=0; i<selectedFiles[key].length; i++){
                fieldsIds.push(key);
                files.push(selectedFiles[key][i]);
            }
        } else {
            fieldsIds.push(key);
            files.push(selectedFiles[key]);
        }
    }
    const response = await RecordService.updateRecordFiles(recordId, vid, files, fieldsIds);
    return response;
}

const insertRecordHandler = async (record, files, addActionVid, vid,mid,pid,tabid,request,enableEditAction) => {
    let data = {};
    let ok = true;
    const response = await RecordService.insertRecordByViewId(record, addActionVid);
    ok = ok && response.ok;
    if (response.ok) {
        if (files && Object.keys(files).length > 0) {
            await uploadImages(response.data.id, files, vid);
        }
        let finalResponse = await getAllRecordsFilteredInViewHandler(vid,mid,pid,tabid,request,enableEditAction);
        ok = ok && finalResponse.ok;
        if (finalResponse.ok) {
            data = {
                ...data,
                ...finalResponse.data,
                record: null
            };
        }
    } else {
        if (!!response.data.message && response.data.message !== EXCEPTION_MESSAGES.INVALID_REFRESH_TOKEN) {
            data = {
                ...data,
                message: response.data.error
            };
        }
    }

    return {
        data: data,
        errorCode:response.data.code,
        ok: ok
    };
}

const updateRecordHandler = async (newRecord, vid, files,mid,pid,tabid,request,enableEditAction) => {
    let data = {};
    let ok = true;
    const response = await RecordService.updateRecordByViewId(newRecord, vid);
    ok = ok && response.ok;
    if (response.ok) {
        if (files && Object.keys(files).length > 0) {
            await uploadImages(response.data.id, files, vid);
        }
        let finalResponse = await getAllRecordsFilteredInViewHandler(vid,mid,pid,tabid,request,enableEditAction);
        ok = ok && finalResponse.ok;
        if (finalResponse.ok) {
            data = {
                ...data,
                ...finalResponse.data,
            };
        }
    } else {
        if (!!response.data.message && response.data.message !== EXCEPTION_MESSAGES.INVALID_REFRESH_TOKEN) {
            data = {
                ...data,
                message: response.data.error
            };
        }
    }

    return {
        data: data,
        ok: ok
    };
}

const findRecordByIdHandler = async (recordId, rtid) => {
    let data = {};
    const response = await RecordService.findRecordInRecordType(recordId, rtid);
    if (response.ok) {
        data = response.data;
    }

    return {
        data: data,
        ok: response.ok
    };
}

const getFormIdHashHandler = async (wid, rid) => {
    let data = {};
    const response = await FormService.getFormIdHash(wid, rid);
    if (response.ok) {
        data = response.data;
    }

    return {
        data: data,
        ok: response.ok
    };
}

const saveFormBuilderHandler = async (record, formFields, formId, addActionVid, vid, formVid) => {
    let data = {};
    let ok = true;
    if (formId) {
        const response = await RecordService.updateRecordByViewId(record, formVid ? formVid : vid);
        ok = ok && response.ok;
        if (response.ok) {
            if (formVid) {
                data.setFormLink = {
                    "formId": response.data.id,
                    "formName": response.data.name
                }
            }
            const r1 = await FormService.findRecordType(response.data.rtid);
            ok = ok && r1.ok;
            if (r1.ok) {
                let recordType = r1.data;
                let createdByField = recordType.fields.find(field => field.id === "createdBy");
                let createdDateField = recordType.fields.find(field => field.id === "createdDate");
                recordType.fields = formFields;
                recordType.fields.push(createdByField);
                recordType.fields.push(createdDateField);
                const r = await FormService.updateRecordType(recordType);
                ok = ok && r.ok;
                if (r.ok) {
                    let responseRecords = await getRecordsByViewId(vid);
                    ok = ok && responseRecords.ok;
                    if (responseRecords.ok) {
                        data = {
                            ...data,
                            ...responseRecords.data,
                            showFormBuilder: false
                        }
                    }
                }
            }
        }
    } else {
        const response = await RecordService.insertRecordByViewId(record, formVid ? formVid : addActionVid);
        ok = ok && response.ok;
        if (response.ok) {
            if (formVid) {
                data.setFormLink = {
                    "formId": response.data.id,
                    "formName": response.data.name
                }
            }
            const r1 = await FormService.findRecordType(response.data.rtid);
            ok = ok && r1.ok;
            if (r1.ok) {
                let recordType = r1.data;
                let createdByField = recordType.fields.find(field => field.id === "createdBy");
                let createdDateField = recordType.fields.find(field => field.id === "createdDate");
                recordType.fields = formFields;
                recordType.fields.push(createdByField);
                recordType.fields.push(createdDateField);
                const r = await FormService.updateRecordType(recordType);
                ok = ok && r.ok;
                if (r.ok) {
                    let responseRecords = await getRecordsByViewId(vid);
                    ok = ok && responseRecords.ok;
                    if (responseRecords.ok) {
                        data = {
                            ...data,
                            ...responseRecords.data,
                            showFormBuilder: false
                        };
                    }
                }
            }
        }
    }

    return {
        data: data,
        ok: ok
    };
}

const getAllRecordsWithNameContainsHandler = async (nameContains, viewId, mid, pageIndex, tabIndex) => {
    let records = [];
    const response = await RecordService.findAllRecordsInViewWithNameContains(nameContains, viewId, mid, pageIndex, tabIndex);
    if (response.ok) {
        records = response.data.map(element => {
            return {
                "label": element.name,
                "value": element.id,
            }
        });
    }
    return {
        data: {
            records: records
        },
        ok: response.ok
    };
}

const getAllRecordsFilteredInViewHandler=async (vid,mid,pageIndex,tabIndex,request,enableEditAction)=>{

    let records = {};
    let recordTypeFields = [];
    let fieldsTypeById = {};
    let fieldsOptionsColorsById = {};
    let script = null;
    let paymentAvailable=false;
    let workspacePaymentSetup={};

    const response = await RecordService.findAllRecordsFilteredInView(vid, mid, pageIndex, tabIndex, request);

    let helperObj = {};
    if (response.ok) {
        response.data.recordType.fields.forEach(field => {
            helperObj = {
                ...helperObj,
                [field.id]: field.type,
            }
            if (field.type === "SingleChoice" && field.optionsColors) {
                let fieldOptionsColorsAux = {};
                field.optionsColors.map((optionColor, index) => {
                    fieldOptionsColorsAux = {...fieldOptionsColorsAux, [field.options[index]]: optionColor}
                });
                fieldsOptionsColorsById = {
                    ...fieldsOptionsColorsById,
                    [field.id]: fieldOptionsColorsAux
                }
            }

            let fieldMode = response.data.recordView.fieldModes.find(fieldMode => fieldMode.id === field.id);
            if (field.type === "Record") {
                let recordTypesInfo = response.data.recordTypesInfo;
                let recordType = recordTypesInfo?.find(recordType => recordType.id === field.rtid);
                if (recordType && recordType.type === "Form") {
                    field.displayOption = "FormLink";
                }
            }
            if(field.type==="Record"||field.type==="RecordList"){
                let recordView=response.data.recordViewsInfo?.find(recordView=>recordView.id===field.vid);
                if(recordView){
                    field.useRecordsAsTags=recordView.useRecordsAsTags;
                }
            }
            if (enableEditAction===false) {
                recordTypeFields.push({
                    ...fieldMode,
                    mode: fieldMode ? (fieldMode.mode === "WRITE" ? "READ" : fieldMode.mode) : "HIDE",
                    ...field
                })
            } else {
                recordTypeFields.push({
                    ...fieldMode,
                    ...field
                })
            }
        });
        fieldsTypeById = {...helperObj};
        records = {
            "values": response.data.records.map(record=>{
                let newRecord={...record};
                for (let recordKey in record) {
                    if(fieldsTypeById[recordKey]===RECORD_FIELD_TYPE.CURRENCY){
                        let currency = newRecord.currency ? currencies.code(newRecord.currency) : currencies.code("EUR");
                        let price=(record[recordKey]/Math.pow(10,currency.digits)).toFixed(currency.digits);
                        newRecord[recordKey]=price;
                    }
                }
                return newRecord;
            }),
            ...helperObj
        };
        paymentAvailable=response.data.recordView?.paymentAvailable;
        script = response.data.recordType?.script;
        workspacePaymentSetup=response.data.workspace?.paymentSetup
    }
    return {
        data: {
            initRecordTypeFields: recordTypeFields,
            records: records,
            recordTypeFields: recordTypeFields,
            fieldsOptionsColorsById: fieldsOptionsColorsById,
            fieldsTypeById: fieldsTypeById,
            script: script,
            paymentAvailable:paymentAvailable,
            workspacePaymentSetup:workspacePaymentSetup
        },
        ok: true
    };
}
const createPaymentLinkHandler = async (vid,paymentRecordId)=>{
    let data = {};
    const response = await PaymentService.createPaymentLink(vid, paymentRecordId);
    if (response.ok) {
        data = response.data;
    }

    return {
        data: data.link,
        ok: response.ok
    };
}

const getInvoiceHandler = async (wid,paymentRecordId)=>{
    let data={};
    const response=await  PaymentService.getInvoice(wid,paymentRecordId);

    if(response.ok){
        data=response.data;
    }

    return{
        data:data,
        ok:response.ok
    };
}

const sendMessageHandler = async (message,wid,vid)=>{
    let data={};
    const response = await MessageService.sendMessage(message,wid,vid);
    if(response.ok){
        data=response.data;
    }
    return{
        data:data,
        ok:response.ok
    };
}

const getMemberTabFiltersHandler = async (mid,pageName,tabName)=>{
    let data={};
    const response = await MenuService.findMemberTabFilters(mid,pageName,tabName);
    if(response.ok){
        data=response.data;
    }
    return{
        data:data,
        errorCode:response.data.code,
        ok:response.ok
    };
}
const upsertMemberTabFiltersHandler = async (mid,pageName,tabName,filter)=>{
    let data={};
    const response = await MenuService.upsertMemberTabFilters(mid,pageName,tabName,filter);
    if(response.ok){
        data=response.data;
    }
    return{
        data:data,
        errorCode:response.data.code,
        ok:response.ok
    };
}

const exportsAllRecordsFilteredInViewHandler = async (vid,mid,pageIndex,tabIndex,request)=>{
    let data={};
    const response=await RecordService.exportsAllRecordsFilteredInView(vid,mid,pageIndex,tabIndex,request)

    if(response.ok){
        data=response.data;
    }

    return{
        data:data,
        ok:response.ok
    };
}


export {
    findMemberAndWorkspaceRolesHandler,
    getFieldsByViewId,
    getDataForMenuHandler,
    deleteRecordHandler,
    insertRecordHandler,
    updateRecordHandler,
    findRecordByIdHandler,
    saveFormBuilderHandler,
    getAllRecordsWithNameContainsHandler,
    getAllRecordsFilteredInViewHandler,
    getFormIdHashHandler,
    createPaymentLinkHandler,
    getInvoiceHandler,
    sendMessageHandler,
    getMemberTabFiltersHandler,
    upsertMemberTabFiltersHandler,
    exportsAllRecordsFilteredInViewHandler
};
