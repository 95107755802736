import CrossaPageTemplate from "../../../../components/CrossaPageTemplate";
import {FormattedMessage} from "react-intl";
import {Col, Form, Row} from "react-bootstrap";
import {useState} from "react";
import TextareaAutosize from "react-textarea-autosize";
import {infoMessage} from "../../../../components/Icons";
import {sendMessageHandler} from "../../../../handlers/workspaceMemberHandlers/menuDisplayHandlers/DisplayMenuHandlers";


function MessageComponent(props) {

    const [state,setState]=useState({
        errors:{},
        subject:"",
        body:"",
        sendByEmail:false,
        sendBySms:false,
        sendAppNotification:false,
    });
    const sendMessage = async ()=>{
        let message={
            subject:state.subject,
            body:state.body,
            sendEmail:state.sendByEmail,
            sendSms:state.sendBySms,
            emailField:props.emailField,
            sendPushNotification:state.sendAppNotification,
            ids:props.selectedRecords
        };


        const response = await sendMessageHandler(message,props.wid,props.vid);
        if(response.ok){
            setState({
                ...state,
                subject: "",
                body:"",
                sendByEmail: false,
                sendBySms: false,
                sendAppNotification: false
            });
            props.closeSlidingPanel(true);
        } else{
            props.handleErrorSendMessage();
        }
    }

    const handleInputChange=(e)=>{

        let newState={...state};
        if(state.errors[e.target.name]){
            newState={
                ...newState,
                errors: {
                    ...newState.errors,
                    [e.target.name]:e.target.value,
                }
            }
        }

        newState={
            ...newState,
            [e.target.name]:e.target.value,
        }

        setState(newState);
    }

    const handleSwitchChange=(e)=>{
        let newState={...state};
        newState={
            ...newState,
            [e.target.name]:!newState[e.target.name],
        }
        setState(newState);
    }

    const closeSlidingPanel = () => {
        setState({
            errors: {},
            subject:"",
            body:"",
            sendByEmail:false,
            sendBySms:false,
            sendAppNotification:false,
        });
        props.closeSlidingPanel();
    }

    return <div className="message-component">
        <CrossaPageTemplate
            hasFooter={true}
            saveChanges={sendMessage}
            saveChangesBtnText={<FormattedMessage id="Button.send"/>}
            cancelBtnText={<FormattedMessage id="Button.cancel"/>}
            closeSlidingPanel={closeSlidingPanel}
        >
            <div>
                <Form.Group>
                    <Form.Label>  <FormattedMessage id="Message.subject" /></Form.Label>
                    <FormattedMessage id="Message.subject">
                        {placeholder=>
                            <Form.Control
                                className={state.errors.subject ? " has-errors" : ""}
                                onChange={handleInputChange}
                                value={state.subject}
                                type="text"
                                isInvalid={state.errors.subject}
                                placeholder={placeholder}
                                name={"subject"}/>
                        }
                    </FormattedMessage>

                    <Form.Control.Feedback type="invalid">
                            <FormattedMessage id={"Validation.cannotBeEmpty"}/>
                    </Form.Control.Feedback>
                </Form.Group>
                <Form.Group className="text-area-form-group with-autosize">
                    <Form.Label><FormattedMessage id="Message.body" /></Form.Label>
                    <div className="textarea">
                        <FormattedMessage id="Message.body">
                            {placeholder=>
                        <TextareaAutosize
                            minRows={5}
                            className={"form-control " +(state.errors.body ? " has-errors" : "")}
                            placeholder={placeholder}
                            as="textarea"
                            style={{height: '100px'}}
                            onChange={handleInputChange}
                            value={state.body}
                            name={"body"}
                        />
                            }
                        </FormattedMessage>
                    </div>
                    <Form.Control.Feedback type="invalid">
                            <FormattedMessage id={"Validation.cannotBeEmpty"}/>
                    </Form.Control.Feedback>
                </Form.Group>
                <Form.Group>
                    <Row>
                        <Col md={8}>
                            <label className="d-flex align-items-center">
                                <span className="switch-label mr-5"><FormattedMessage id="Message.sendByEmail" /></span>
                            </label>
                        </Col>
                        <Col className="inline-switch-col">
                            <Form.Check
                                className="ml-3"
                                type="switch"
                                id={"sendByEmail-switch"}
                                name={"sendByEmail"}
                                checked={state.sendByEmail}
                                onChange={handleSwitchChange}
                            />
                        </Col>
                    </Row>
                </Form.Group>
                <Form.Group>
                    <Row>
                        <Col md={8}>
                            <label className="d-flex align-items-center">
                                <span className="switch-label mr-5"><FormattedMessage id="Message.sendBySms" /></span>
                            </label>
                        </Col>
                        <Col className="inline-switch-col">
                            <Form.Check
                                className="ml-3"
                                type="switch"
                                id={"sendBySms-switch"}
                                name={"sendBySms"}
                                checked={state.sendBySms}
                                onChange={handleSwitchChange}
                            />
                        </Col>
                    </Row>
                </Form.Group>
                <Form.Group>
                    <Row>
                        <Col md={8}>
                            <label className="d-flex align-items-center">
                                <span className="switch-label mr-5"><FormattedMessage id="Message.sendAppNotification" /></span>
                            </label>
                        </Col>
                        <Col className="inline-switch-col">
                            <Form.Check
                                className="ml-3"
                                type="switch"
                                id={"sendAppNotification-switch"}
                                name={"sendAppNotification"}
                                checked={state.sendAppNotification}
                                onChange={handleSwitchChange}
                            />
                        </Col>
                    </Row>
                </Form.Group>
                <Form.Group>
                    <i className="mr-2">{infoMessage}</i>
                    <Form.Label className="info-text">
                        <FormattedMessage id="Message.infoMessage"/>
                    </Form.Label>
                </Form.Group>
            </div>
        </CrossaPageTemplate>
    </div>
}

export default  MessageComponent;