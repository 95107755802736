import React from "react";



export const closeIcon = <svg className="icon icon-close" width="14"
                       height="14" viewBox="0 0 14 14" fill="none"
                       xmlns="http://www.w3.org/2000/svg">
    <path
        d="M14 1.41L12.59 0L7 5.59L1.41 0L0 1.41L5.59 7L0 12.59L1.41 14L7 8.41L12.59 14L14 12.59L8.41 7L14 1.41Z"
        fill="#4D5062"/>
</svg>


export const uploadImageIcon = <svg className="icon icon-upload-image" width="42" height="42" viewBox="0 0 42 42" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M4 0C2.93913 0 1.92172 0.421427 1.17157 1.17157C0.421427 1.92172 0 2.93913 0 4V32C0 33.0609 0.421427 34.0783 1.17157 34.8284C1.92172 35.5786 2.93913 36 4 36H22.18C22.06 35.34 22 34.68 22 34C22 32.64 22.24 31.28 22.7 30H4L11 21L16 27L23 18L27.46 23.94C29.4 22.68 31.68 22 34 22C34.68 22 35.34 22.06 36 22.18V4C36 1.78 34.2 0 32 0H4ZM32 26V32H26V36H32V42H36V36H42V32H36V26H32Z" fill="#979AAE"/>
</svg>

export const uploadFileIcon = <svg className="icon icon-upload-file" width="38" height="40" viewBox="0 0 38 42" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M20 0H4C1.78 0 0 1.78 0 4V36C0 38.22 1.78 40 4 40H19.62C18.56 38.18 18 36.1 18 34C18 33.34 18.06 32.66 18.16 32H4V28H19.62C20.54 26.4 21.82 25 23.36 24H4V20H28V22.16C28.66 22.06 29.34 22 30 22C30.66 22 31.34 22.06 32 22.16V12L20 0ZM18 14V3L29 14H18ZM28 26V32H22V36H28V42H32V36H38V32H32V26H28Z" fill="#0093D7"/>
</svg>

export const fileIcon = <svg className="icon icon-file" width="32" height="40" viewBox="0 0 32 40" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M18 14V3L29 14M4 0C1.78 0 0 1.78 0 4V36C0 37.0609 0.421427 38.0783 1.17157 38.8284C1.92172 39.5786 2.93913 40 4 40H28C29.0609 40 30.0783 39.5786 30.8284 38.8284C31.5786 38.0783 32 37.0609 32 36V12L20 0H4Z" fill="#625B71"/>
</svg>


export const noImageIcon = <svg className="icon no-image-icon" width="100" height="100" viewBox="0 0 100 100" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M30.5556 58.3333L44.4444 75L63.8889 50L88.8889 83.3333H11.1111M100 88.8889V11.1111C100 4.94444 95 0 88.8889 0H11.1111C8.16426 0 5.33811 1.17063 3.25437 3.25437C1.17063 5.33811 0 8.16426 0 11.1111V88.8889C0 91.8357 1.17063 94.6619 3.25437 96.7456C5.33811 98.8294 8.16426 100 11.1111 100H88.8889C91.8357 100 94.6619 98.8294 96.7456 96.7456C98.8294 94.6619 100 91.8357 100 88.8889Z" fill="#979AAE"/>
</svg>


export const viewIcon = <svg className="icon view-icon" width="18" height="17" viewBox="0 0 18 17" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M2.33329 0.5H15.6666C16.1087 0.5 16.5326 0.675595 16.8451 0.988155C17.1577 1.30072 17.3333 1.72464 17.3333 2.16667V14.6667C17.3333 15.1087 17.1577 15.5326 16.8451 15.8452C16.5326 16.1577 16.1087 16.3333 15.6666 16.3333H2.33329C1.89127 16.3333 1.46734 16.1577 1.15478 15.8452C0.842221 15.5326 0.666626 15.1087 0.666626 14.6667V2.16667C0.666626 1.72464 0.842221 1.30072 1.15478 0.988155C1.46734 0.675595 1.89127 0.5 2.33329 0.5V0.5ZM2.33329 3.83333V6.33333H5.66663V3.83333H2.33329ZM7.33329 3.83333V6.33333H10.6666V3.83333H7.33329ZM15.6666 6.33333V3.83333H12.3333V6.33333H15.6666ZM2.33329 8V10.5H5.66663V8H2.33329ZM2.33329 14.6667H5.66663V12.1667H2.33329V14.6667ZM7.33329 8V10.5H10.6666V8H7.33329ZM7.33329 14.6667H10.6666V12.1667H7.33329V14.6667ZM15.6666 14.6667V12.1667H12.3333V14.6667H15.6666ZM15.6666 8H12.3333V10.5H15.6666V8Z" fill="#625B71"/>
</svg>
export const caretIconClosed = <svg  className="icon caret-icon-closed" width="14" height="6" viewBox="0 0 14 6" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M7.02326 6L0.981218 0L13.0653 0L7.02326 6Z" fill="#625B71"/>
</svg>

export const caretIconOpened = <svg  className="icon caret-icon-opened" width="14" height="6" viewBox="0 0 14 6" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M7.02313 0L13.0652 6H0.981093L7.02313 0Z" fill="#625B71"/>
</svg>


export const addIconFullBg = <svg className="icon icon-add" width="19" height="20" viewBox="0 0 19 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect y="0.5" width="19" height="19" rx="9.5" fill="#FDFFFF"/>
    <rect x="8.75659" y="4.29996" width="1.48696" height="11.4" fill="#0093D7"/>
    <rect x="15.2002" y="9.25647" width="1.48696" height="11.4" transform="rotate(90 15.2002 9.25647)" fill="#0093D7"/>
</svg>

export const addIconWithoutBg = <svg className="icon icon-add-no-bg" width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect x="0.76" y="0.76" width="17.48" height="17.48" rx="8.74" fill="#FDFFFF"/>
    <rect x="8.75684" y="3.79999" width="1.48696" height="11.4" fill="#0093D7"/>
    <rect x="15.2002" y="8.75653" width="1.48696" height="11.4" transform="rotate(90 15.2002 8.75653)" fill="#0093D7"/>
    <rect x="0.76" y="0.76" width="17.48" height="17.48" rx="8.74" stroke="#0093D7" strokeWidth="1.52"/>
</svg>

export const iconX = <svg className="icon icon-x" width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M16 2.91L13.09 0L8 5.09L2.91 0L0 2.91L5.09 8L0 13.09L2.91 16L8 10.91L13.09 16L16 13.09L10.91 8L16 2.91Z" fill="#E4437D"/>
</svg>

export const iconXthinner = <svg className="icon icon-x" width="15" height="15" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M9.66857 8L16 14.3314V16H14.3314L8 9.66857L1.66857 16H0V14.3314L6.33143 8L0 1.66857V0H1.66857L8 6.33143L14.3314 0H16V1.66857L9.66857 8Z" fill="#625B71"/>
</svg>


export const leftArrow = <svg className="icon left-arrow" width="10" height="16" viewBox="0 0 10 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M9.87976 14.1067L3.77309 8L9.87976 1.88L7.99976 0L-0.000244141 8L7.99976 16L9.87976 14.1067Z" fill="#625B71"/>
</svg>

export const tagIcon = <svg className="icon tag-icon" width="25" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" clipRule="evenodd" d="M4.58332 7.50004C4.91484 7.50004 5.23279 7.36834 5.46721 7.13392C5.70163 6.8995 5.83332 6.58156 5.83332 6.25004C5.83332 5.91852 5.70163 5.60058 5.46721 5.36616C5.23279 5.13174 4.91484 5.00004 4.58332 5.00004C4.2518 5.00004 3.93386 5.13174 3.69944 5.36616C3.46502 5.60058 3.33332 5.91852 3.33332 6.25004C3.33332 6.58156 3.46502 6.8995 3.69944 7.13392C3.93386 7.36834 4.2518 7.50004 4.58332 7.50004ZM14.5083 9.65004C14.8083 9.95004 15 10.3667 15 10.8334C15 11.2917 14.8167 11.7084 14.5083 12.0084L10.3417 16.175C10.0417 16.475 9.62499 16.6667 9.16666 16.6667C9.05293 16.6667 8.94177 16.6554 8.83419 16.6337C9.04821 16.1321 9.16666 15.5799 9.16666 15C9.16666 12.6989 7.30118 10.8334 4.99999 10.8334C4.31534 10.8334 3.66925 10.9985 3.09939 11.2911L2.15832 10.35C1.84999 10.0417 1.66666 9.62504 1.66666 9.16671V5.00004C1.66666 4.07504 2.40832 3.33337 3.33332 3.33337H7.49999C7.95832 3.33337 8.37499 3.51671 8.67499 3.81671L14.5083 9.65004ZM11.2833 4.75837L12.1167 3.92504L17.8417 9.65004C18.15 9.95004 18.3333 10.375 18.3333 10.8334C18.3333 11.2917 18.15 11.7084 17.85 12.0084L13.3667 16.4917L12.5333 15.6584L17.2917 10.8334L11.2833 4.75837Z" fill="#735CB0"/>
    <path d="M4.13194 17.3079L1.875 15.051L2.65625 14.2697L4.13194 15.7454L7.30903 12.5684L8.09028 13.3496L4.13194 17.3079Z" fill="#735CB0"/>
</svg>

export const ruleIcon = <svg className="icon rule-icon" width="20" height="18" viewBox="0 0 21 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M8.3051 4.68749H14.3396V6.65699L20.0066 3.38474L14.3396 0.112488V2.06099H5.3696C6.52685 2.70899 7.4621 3.69149 8.30585 4.68599L8.3051 4.68749ZM14.3396 12.4905C13.8761 12.3442 13.2843 11.9632 12.6221 11.3002C11.2796 9.98324 9.83735 7.79399 8.3231 5.87999C6.7856 4.01549 5.2031 2.15099 2.7356 2.06249H0.110596V4.68749H2.7356C3.22685 4.66649 3.9971 5.05124 4.8941 5.94899C6.2351 7.26374 7.6781 9.45449 9.1916 11.3685C10.6331 13.116 12.1226 14.8485 14.3396 15.1305V17.157L20.0066 13.8855L14.3396 10.614V12.4905Z" fill="#343A40"/>
</svg>

export const editIcon = <svg className="icon edit-icon"  width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M17.71 5.04006C18.1 4.65006 18.1 4.00006 17.71 3.63006L15.37 1.29006C15 0.900059 14.35 0.900059 13.96 1.29006L12.12 3.12006L15.87 6.87006L17.71 5.04006ZM0 15.2501V19.0001H3.75L14.81 7.93006L11.06 4.18006L0 15.2501Z" fill="#343A40"/>
</svg>

export const trashIcon = <svg className="icon trash-icon" width="14" height="18" viewBox="0 0 14 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M14 1H10.5L9.5 0H4.5L3.5 1H0V3H14V1ZM1 16C1 16.5304 1.21071 17.0391 1.58579 17.4142C1.96086 17.7893 2.46957 18 3 18H11C11.5304 18 12.0391 17.7893 12.4142 17.4142C12.7893 17.0391 13 16.5304 13 16V4H1V16Z" fill="#E4437D"/>
</svg>

export const iconForMenus = <svg className="icon icon-for-menus" width="18" height="11" viewBox="0 0 18 11" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M0.288 9.712C0.479333 9.904 0.716667 10 1 10C1.28333 10 1.521 9.904 1.713 9.712C1.90433 9.52067 2 9.28333 2 9C2 8.71667 1.90433 8.479 1.713 8.287C1.521 8.09567 1.28333 8 1 8C0.716667 8 0.479333 8.09567 0.288 8.287C0.0960001 8.479 0 8.71667 0 9C0 9.28333 0.0960001 9.52067 0.288 9.712Z" fill="#625B71"/>
    <path d="M0.288 5.712C0.479333 5.904 0.716667 6 1 6C1.28333 6 1.521 5.904 1.713 5.712C1.90433 5.52067 2 5.28333 2 5C2 4.71667 1.90433 4.479 1.713 4.287C1.521 4.09567 1.28333 4 1 4C0.716667 4 0.479333 4.09567 0.288 4.287C0.0960001 4.479 0 4.71667 0 5C0 5.28333 0.0960001 5.52067 0.288 5.712Z" fill="#625B71"/>
    <path d="M0.288 1.712C0.479333 1.904 0.716667 2 1 2C1.28333 2 1.521 1.904 1.713 1.712C1.90433 1.52067 2 1.28333 2 1C2 0.716667 1.90433 0.479 1.713 0.287C1.521 0.0956668 1.28333 0 1 0C0.716667 0 0.479333 0.0956668 0.288 0.287C0.0960001 0.479 0 0.716667 0 1C0 1.28333 0.0960001 1.52067 0.288 1.712Z" fill="#625B71"/>
    <path d="M10 8H4V10H12L10 8Z" fill="#625B71"/>
    <path d="M4 4V6H18V4H4Z" fill="#625B71"/>
    <path d="M4 0V2H18V0H4Z" fill="#625B71"/>
    <path d="M15 11L12 8H18L15 11Z" fill="#625B71"/>
</svg>

export const filterIcon = <svg className="icon filter-icon" width="18" height="17" viewBox="0 0 18 17" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M7.99961 16.6998C7.66628 16.6998 7.38294 16.5831 7.14961 16.3498C6.91628 16.1165 6.79961 15.8331 6.79961 15.4998V9.5748L1.04961 2.2248C0.732943 1.8248 0.682943 1.4038 0.899609 0.961805C1.11628 0.520471 1.48294 0.299805 1.99961 0.299805H15.9996C16.5163 0.299805 16.8829 0.520471 17.0996 0.961805C17.3163 1.4038 17.2663 1.8248 16.9496 2.2248L11.1996 9.5748V15.5748C11.1996 15.8915 11.0913 16.1581 10.8746 16.3748C10.6579 16.5915 10.3913 16.6998 10.0746 16.6998H7.99961Z" fill="#625B71"/>
</svg>

export const arrowDownwardIcon = <svg className="icon arrow-downward-icon" width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M8 16L0 8L1.4 6.575L7 12.175V0H9V12.175L14.6 6.575L16 8L8 16Z" fill="#625B71"/>
</svg>

export const arrowUpwardIcon = <svg className="icon arrow-upward-icon" width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M7 16V3.825L1.4 9.425L0 8L8 0L16 8L14.6 9.425L9 3.825V16H7Z" fill="#625B71"/>
</svg>

export const searchIcon = <svg className="icon search-icon" width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M17.6 19.225L11.25 12.875C10.75 13.2583 10.175 13.5666 9.525 13.8C8.875 14.0333 8.18333 14.15 7.45 14.15C5.6 14.15 4.03333 13.504 2.75 12.212C1.46666 10.9206 0.824997 9.34998 0.824997 7.49998C0.824997 5.64998 1.46666 4.07898 2.75 2.78698C4.03333 1.49564 5.6 0.849976 7.45 0.849976C9.3 0.849976 10.871 1.49564 12.163 2.78698C13.4543 4.07898 14.1 5.64998 14.1 7.49998C14.1 8.23331 13.9877 8.92498 13.763 9.57498C13.5377 10.225 13.2333 10.7916 12.85 11.275L19.175 17.625L17.6 19.225ZM7.45 11.85C8.66666 11.85 9.7 11.429 10.55 10.587C11.4 9.74564 11.825 8.71664 11.825 7.49998C11.825 6.28331 11.4 5.25398 10.55 4.41198C9.7 3.57064 8.66666 3.14998 7.45 3.14998C6.23333 3.14998 5.20433 3.57064 4.363 4.41198C3.521 5.25398 3.1 6.28331 3.1 7.49998C3.1 8.71664 3.521 9.74564 4.363 10.587C5.20433 11.429 6.23333 11.85 7.45 11.85Z" fill="#625B71"/>
</svg>

export const sortDownIcon = <svg className="icon sort-down-icon" width="12" height="6" viewBox="0 0 12 6" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M0 0L6 6L12 2.09815e-06L0 0Z" fill="#735CB0"/>
</svg>

export const sortUpIcon = <svg className="icon sort-up-icon" width="12" height="6" viewBox="0 0 12 6" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M12 6L6 0L0 6L12 6Z" fill="#735CB0"/>
</svg>

export const bookmarkOutlineIcon = <svg className="icon bookmark-outline-icon" width="15" height="18" viewBox="0 0 15 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M12.5 15L7.5 12.82L2.5 15V2H12.5V15ZM12.5 0H2.5C1.96957 0 1.46086 0.210714 1.08579 0.585786C0.710714 0.960859 0.5 1.46957 0.5 2V18L7.5 15L14.5 18V2C14.5 0.89 13.6 0 12.5 0Z" fill="#625B71"/>
</svg>

export const bookmarkIcon = <svg className="icon bookmark-icon" width="15" height="18" viewBox="0 0 15 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M12.5 15L7.5 12.82L2.5 15V2H12.5V15ZM12.5 0H2.5C1.96957 0 1.46086 0.210714 1.08579 0.585786C0.710714 0.960859 0.5 1.46957 0.5 2V18L7.5 15L14.5 18V2C14.5 0.89 13.6 0 12.5 0Z" fill="#625B71"/>
</svg>

export const errorExclamationIcon = <svg className="icon error-exclamation-icon" width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M8 12C8.22667 12 8.4168 11.9232 8.5704 11.7696C8.72347 11.6165 8.8 11.4267 8.8 11.2C8.8 10.9733 8.72347 10.7832 8.5704 10.6296C8.4168 10.4765 8.22667 10.4 8 10.4C7.77333 10.4 7.58347 10.4765 7.4304 10.6296C7.2768 10.7832 7.2 10.9733 7.2 11.2C7.2 11.4267 7.2768 11.6165 7.4304 11.7696C7.58347 11.9232 7.77333 12 8 12ZM7.2 8.8H8.8V4H7.2V8.8ZM8 16C6.89333 16 5.85333 15.7899 4.88 15.3696C3.90667 14.9499 3.06 14.38 2.34 13.66C1.62 12.94 1.05013 12.0933 0.6304 11.12C0.210133 10.1467 0 9.10667 0 8C0 6.89333 0.210133 5.85333 0.6304 4.88C1.05013 3.90667 1.62 3.06 2.34 2.34C3.06 1.62 3.90667 1.04987 4.88 0.6296C5.85333 0.209867 6.89333 0 8 0C9.10667 0 10.1467 0.209867 11.12 0.6296C12.0933 1.04987 12.94 1.62 13.66 2.34C14.38 3.06 14.9499 3.90667 15.3696 4.88C15.7899 5.85333 16 6.89333 16 8C16 9.10667 15.7899 10.1467 15.3696 11.12C14.9499 12.0933 14.38 12.94 13.66 13.66C12.94 14.38 12.0933 14.9499 11.12 15.3696C10.1467 15.7899 9.10667 16 8 16Z" fill="#E4437D"/>
</svg>

export const workspaceMemberIcon = <svg className="icon workspace-member-icon" width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M12 4.00635C13.0609 4.00635 14.0783 4.42777 14.8284 5.17792C15.5786 5.92807 16 6.94548 16 8.00635C16 9.06721 15.5786 10.0846 14.8284 10.8348C14.0783 11.5849 13.0609 12.0063 12 12.0063C10.9391 12.0063 9.92172 11.5849 9.17157 10.8348C8.42143 10.0846 8 9.06721 8 8.00635C8 6.94548 8.42143 5.92807 9.17157 5.17792C9.92172 4.42777 10.9391 4.00635 12 4.00635M12 14.0063C16.42 14.0063 20 15.7963 20 18.0063V20.0063H4V18.0063C4 15.7963 7.58 14.0063 12 14.0063Z" fill="#625B71"/>
</svg>

export const workspaceAdminIcon = <svg className="icon workspace-admin-icon" width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M20 16.8063V20.0063H16.8V17.6063H14.4V15.2063H12L10.192 13.3983C9.752 13.5343 9.288 13.6063 8.8 13.6063C7.52696 13.6063 6.30606 13.1006 5.40589 12.2005C4.50571 11.3003 4 10.0794 4 8.80635C4 7.53331 4.50571 6.31241 5.40589 5.41224C6.30606 4.51206 7.52696 4.00635 8.8 4.00635C10.073 4.00635 11.2939 4.51206 12.1941 5.41224C13.0943 6.31241 13.6 7.53331 13.6 8.80635C13.6 9.29435 13.528 9.75835 13.392 10.1983L20 16.8063ZM8 6.40635C7.57565 6.40635 7.16869 6.57492 6.86863 6.87498C6.56857 7.17503 6.4 7.582 6.4 8.00635C6.4 8.43069 6.56857 8.83766 6.86863 9.13772C7.16869 9.43778 7.57565 9.60635 8 9.60635C8.42435 9.60635 8.83131 9.43778 9.13137 9.13772C9.43143 8.83766 9.6 8.43069 9.6 8.00635C9.6 7.582 9.43143 7.17503 9.13137 6.87498C8.83131 6.57492 8.42435 6.40635 8 6.40635Z" fill="#625B71"/>
</svg>

export const notificationIcon = <svg className="icon icon-notifications" width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M20 6.80635C20 8.35035 18.744 9.60635 17.2 9.60635C15.656 9.60635 14.4 8.35035 14.4 6.80635C14.4 5.26235 15.656 4.00635 17.2 4.00635C18.744 4.00635 20 5.26235 20 6.80635ZM17.2 11.2063C14.776 11.2063 12.8 9.23035 12.8 6.80635C12.8 6.40635 12.864 6.00635 12.968 5.60635H5.6C4.712 5.60635 4 6.31835 4 7.20635V18.4063C4 19.2943 4.72 20.0063 5.6 20.0063H16.8C17.688 20.0063 18.4 19.2943 18.4 18.4063V11.0383C18 11.1423 17.6 11.2063 17.2 11.2063Z" fill="#625B71"/>
</svg>

export const lockIcon = <svg className="icon icon-notifications" width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M18 8.00635C18.5304 8.00635 19.0391 8.21706 19.4142 8.59213C19.7893 8.96721 20 9.47591 20 10.0063V20.0063C20 20.5368 19.7893 21.0455 19.4142 21.4206C19.0391 21.7956 18.5304 22.0063 18 22.0063H6C4.89 22.0063 4 21.1063 4 20.0063V10.0063C4 9.47591 4.21071 8.96721 4.58579 8.59213C4.96086 8.21706 5.46957 8.00635 6 8.00635H15V6.00635C15 5.2107 14.6839 4.44764 14.1213 3.88503C13.5587 3.32242 12.7956 3.00635 12 3.00635C11.2044 3.00635 10.4413 3.32242 9.87868 3.88503C9.31607 4.44764 9 5.2107 9 6.00635H7C7 4.68027 7.52678 3.4085 8.46447 2.47081C9.40215 1.53313 10.6739 1.00635 12 1.00635C12.6566 1.00635 13.3068 1.13568 13.9134 1.38695C14.52 1.63822 15.0712 2.00652 15.5355 2.47081C15.9998 2.93511 16.3681 3.4863 16.6194 4.09293C16.8707 4.69956 17 5.34974 17 6.00635V8.00635H18ZM12 17.0063C12.5304 17.0063 13.0391 16.7956 13.4142 16.4206C13.7893 16.0455 14 15.5368 14 15.0063C14 14.4759 13.7893 13.9672 13.4142 13.5921C13.0391 13.2171 12.5304 13.0063 12 13.0063C11.4696 13.0063 10.9609 13.2171 10.5858 13.5921C10.2107 13.9672 10 14.4759 10 15.0063C10 15.5368 10.2107 16.0455 10.5858 16.4206C10.9609 16.7956 11.4696 17.0063 12 17.0063Z" fill="#625B71"/>
</svg>

export const powerIcon = <svg className="icon icon-power" width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M16.56 5.44635L15.11 6.89635C16.84 7.94635 18 9.83635 18 12.0063C18 13.5976 17.3679 15.1238 16.2426 16.249C15.1174 17.3742 13.5913 18.0063 12 18.0063C10.4087 18.0063 8.88258 17.3742 7.75736 16.249C6.63214 15.1238 6 13.5976 6 12.0063C6 9.83635 7.16 7.94635 8.88 6.88635L7.44 5.44635C5.36 6.88635 4 9.28635 4 12.0063C4 14.1281 4.84285 16.1629 6.34315 17.6632C7.84344 19.1635 9.87827 20.0063 12 20.0063C14.1217 20.0063 16.1566 19.1635 17.6569 17.6632C19.1571 16.1629 20 14.1281 20 12.0063C20 9.28635 18.64 6.88635 16.56 5.44635ZM13 3.00635H11V13.0063H13" fill="#625B71"/>
</svg>

export const workspacePaymentSettingsIcon = <svg className="icon icon-workspace-payment-settings" width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M0.588 18.913C0.979333 19.3043 1.45 19.5 2 19.5H10.8464C9.41273 18.3077 8.5 16.5104 8.5 14.5C8.5 10.9101 11.4101 8 15 8C17.0104 8 18.8077 8.91273 20 10.3464V6.5C20 5.95 19.8043 5.47933 19.413 5.088C19.021 4.696 18.55 4.5 18 4.5H14V2.5C14 1.95 13.8043 1.479 13.413 1.087C13.021 0.695667 12.55 0.5 12 0.5H8C7.45 0.5 6.97933 0.695667 6.588 1.087C6.196 1.479 6 1.95 6 2.5V4.5H2C1.45 4.5 0.979333 4.696 0.588 5.088C0.196 5.47933 0 5.95 0 6.5V17.5C0 18.05 0.196 18.521 0.588 18.913ZM12 4.5H8V2.5H12V4.5Z" fill="#625B71"/>
    <path d="M13.6318 19.5L13.4328 17.9C13.325 17.8583 13.2235 17.8083 13.1284 17.75C13.0328 17.6917 12.9395 17.6292 12.8483 17.5625L11.3682 18.1875L10 15.8125L11.2811 14.8375C11.2728 14.7792 11.2687 14.7228 11.2687 14.6685V14.331C11.2687 14.277 11.2728 14.2208 11.2811 14.1625L10 13.1875L11.3682 10.8125L12.8483 11.4375C12.9395 11.3708 13.0348 11.3083 13.1343 11.25C13.2338 11.1917 13.3333 11.1417 13.4328 11.1L13.6318 9.5H16.3682L16.5672 11.1C16.675 11.1417 16.7766 11.1917 16.8721 11.25C16.9673 11.3083 17.0605 11.3708 17.1517 11.4375L18.6318 10.8125L20 13.1875L18.7189 14.1625C18.7272 14.2208 18.7313 14.277 18.7313 14.331V14.6685C18.7313 14.7228 18.7231 14.7792 18.7065 14.8375L19.9876 15.8125L18.6194 18.1875L17.1517 17.5625C17.0605 17.6292 16.9652 17.6917 16.8657 17.75C16.7662 17.8083 16.6667 17.8583 16.5672 17.9L16.3682 19.5H13.6318ZM15.0249 16.25C15.5058 16.25 15.9163 16.0792 16.2562 15.7375C16.5962 15.3958 16.7662 14.9833 16.7662 14.5C16.7662 14.0167 16.5962 13.6042 16.2562 13.2625C15.9163 12.9208 15.5058 12.75 15.0249 12.75C14.5357 12.75 14.1231 12.9208 13.7871 13.2625C13.4514 13.6042 13.2836 14.0167 13.2836 14.5C13.2836 14.9833 13.4514 15.3958 13.7871 15.7375C14.1231 16.0792 14.5357 16.25 15.0249 16.25Z" fill="#625B71"/>
</svg>

export const paymentSettingsIcon = <svg className="icon icon-payment-settings" width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M9.1 17H10.85V15.75C11.6833 15.6 12.4 15.275 13 14.775C13.6 14.275 13.9 13.5333 13.9 12.55C13.9 11.85 13.7 11.2083 13.3 10.625C12.9 10.0417 12.1 9.53333 10.9 9.1C9.9 8.76667 9.20833 8.475 8.825 8.225C8.44167 7.975 8.25 7.63333 8.25 7.2C8.25 6.76667 8.40433 6.425 8.713 6.175C9.021 5.925 9.46667 5.8 10.05 5.8C10.5833 5.8 11 5.92933 11.3 6.188C11.6 6.446 11.8167 6.76667 11.95 7.15L13.55 6.5C13.3667 5.91667 13.0293 5.40833 12.538 4.975C12.046 4.54167 11.5 4.3 10.9 4.25V3H9.15V4.25C8.31667 4.43333 7.66667 4.8 7.2 5.35C6.73333 5.9 6.5 6.51667 6.5 7.2C6.5 7.98333 6.72933 8.61667 7.188 9.1C7.646 9.58333 8.36667 10 9.35 10.35C10.4 10.7333 11.1293 11.075 11.538 11.375C11.946 11.675 12.15 12.0667 12.15 12.55C12.15 13.1 11.954 13.504 11.562 13.762C11.1707 14.0207 10.7 14.15 10.15 14.15C9.6 14.15 9.11267 13.979 8.688 13.637C8.26267 13.2957 7.95 12.7833 7.75 12.1L6.1 12.75C6.33333 13.55 6.696 14.196 7.188 14.688C7.67933 15.1793 8.31667 15.5167 9.1 15.7V17ZM10 20C8.61667 20 7.31667 19.7373 6.1 19.212C4.88333 18.6873 3.825 17.975 2.925 17.075C2.025 16.175 1.31267 15.1167 0.788 13.9C0.262667 12.6833 0 11.3833 0 10C0 8.61667 0.262667 7.31667 0.788 6.1C1.31267 4.88333 2.025 3.825 2.925 2.925C3.825 2.025 4.88333 1.31233 6.1 0.787C7.31667 0.262333 8.61667 0 10 0C11.3833 0 12.6833 0.262333 13.9 0.787C15.1167 1.31233 16.175 2.025 17.075 2.925C17.975 3.825 18.6873 4.88333 19.212 6.1C19.7373 7.31667 20 8.61667 20 10C20 11.3833 19.7373 12.6833 19.212 13.9C18.6873 15.1167 17.975 16.175 17.075 17.075C16.175 17.975 15.1167 18.6873 13.9 19.212C12.6833 19.7373 11.3833 20 10 20Z" fill="#625B71"/>
</svg>

export const formPaymentSettingsIcon = <svg className="icon icon-form-payment-settings" width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M6.5 4.5H0.5V10.5H6.5V4.5Z" fill="#625B71"/>
    <path d="M18.5 0.5H0.5V2.5H18.5V0.5Z" fill="#625B71"/>
    <path d="M18.5 6.5V4.5H8.5V6.5H18.5Z" fill="#625B71"/>
    <path d="M6.57086 12.5C6.52417 12.8267 6.5 13.1604 6.5 13.5C6.5 13.8396 6.52417 14.1733 6.57086 14.5H0.5V12.5H6.57086Z" fill="#625B71"/>
    <path d="M7.17365 16.5C7.52972 17.2495 8.01514 17.9258 8.60101 18.5H0.5V16.5H7.17365Z" fill="#625B71"/>
    <path d="M12.96 17.7H14.01V16.95C14.51 16.86 14.94 16.665 15.3 16.365C15.66 16.065 15.84 15.62 15.84 15.03C15.84 14.61 15.72 14.225 15.48 13.875C15.24 13.525 14.76 13.22 14.04 12.96C13.44 12.76 13.025 12.585 12.795 12.435C12.565 12.285 12.45 12.08 12.45 11.82C12.45 11.56 12.5426 11.355 12.7278 11.205C12.9126 11.055 13.18 10.98 13.53 10.98C13.85 10.98 14.1 11.0576 14.28 11.2128C14.46 11.3676 14.59 11.56 14.67 11.79L15.63 11.4C15.52 11.05 15.3176 10.745 15.0228 10.485C14.7276 10.225 14.4 10.08 14.04 10.05V9.3H12.99V10.05C12.49 10.16 12.1 10.38 11.82 10.71C11.54 11.04 11.4 11.41 11.4 11.82C11.4 12.29 11.5376 12.67 11.8128 12.96C12.0876 13.25 12.52 13.5 13.11 13.71C13.74 13.94 14.1776 14.145 14.4228 14.325C14.6676 14.505 14.79 14.74 14.79 15.03C14.79 15.36 14.6724 15.6024 14.4372 15.7572C14.2024 15.9124 13.92 15.99 13.59 15.99C13.26 15.99 12.9676 15.8874 12.7128 15.6822C12.4576 15.4774 12.27 15.17 12.15 14.76L11.16 15.15C11.3 15.63 11.5176 16.0176 11.8128 16.3128C12.1076 16.6076 12.49 16.81 12.96 16.92V17.7ZM13.5 19.5C12.67 19.5 11.89 19.3424 11.16 19.0272C10.43 18.7124 9.795 18.285 9.255 17.745C8.715 17.205 8.2876 16.57 7.9728 15.84C7.6576 15.11 7.5 14.33 7.5 13.5C7.5 12.67 7.6576 11.89 7.9728 11.16C8.2876 10.43 8.715 9.795 9.255 9.255C9.795 8.715 10.43 8.2874 11.16 7.9722C11.89 7.6574 12.67 7.5 13.5 7.5C14.33 7.5 15.11 7.6574 15.84 7.9722C16.57 8.2874 17.205 8.715 17.745 9.255C18.285 9.795 18.7124 10.43 19.0272 11.16C19.3424 11.89 19.5 12.67 19.5 13.5C19.5 14.33 19.3424 15.11 19.0272 15.84C18.7124 16.57 18.285 17.205 17.745 17.745C17.205 18.285 16.57 18.7124 15.84 19.0272C15.11 19.3424 14.33 19.5 13.5 19.5Z" fill="#625B71"/>
</svg>

export const notificationsIconIndeterminate = <svg className="icon icon-notifications-indeterminate" width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect x="1" y="1" width="18" height="18" rx="2" fill="#0093D7"/>
    <rect x="4" y="8" width="12" height="4" fill="white"/>
    <rect x="1" y="1" width="18" height="18" rx="2" stroke="#0093D7" strokeWidth="2"/>
</svg>

export const notificationsIconChecked = <svg className="icon icon-notifications-checked" width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect x="1" y="1" width="18" height="18" rx="2" fill="#0093D7"/>
    <path d="M7.59514 16L3 11.2046L5.09408 9.0193L7.59514 11.6371L14.9059 4L17 6.18533L7.59514 16Z" fill="#FDFFFF"/>
    <rect x="1" y="1" width="18" height="18" rx="2" stroke="#0093D7" strokeWidth="2"/>
</svg>

export const notificationsIconUnchecked = <svg className="icon icon-notifications-unchecked" width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect x="1" y="1" width="18" height="18" rx="2" fill="#FDFFFF"/>
    <rect x="4" y="8" width="12" height="4" fill="white"/>
    <rect x="1" y="1" width="18" height="18" rx="2" stroke="#0093D7" strokeWidth="2"/>
</svg>

export const infoMessage = <svg className="icon icon-info-message" width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M11 7H9V5H11M11 15H9V9H11M10 0C8.68678 0 7.38642 0.258658 6.17317 0.761205C4.95991 1.26375 3.85752 2.00035 2.92893 2.92893C1.05357 4.8043 0 7.34784 0 10C0 12.6522 1.05357 15.1957 2.92893 17.0711C3.85752 17.9997 4.95991 18.7362 6.17317 19.2388C7.38642 19.7413 8.68678 20 10 20C12.6522 20 15.1957 18.9464 17.0711 17.0711C18.9464 15.1957 20 12.6522 20 10C20 8.68678 19.7413 7.38642 19.2388 6.17317C18.7362 4.95991 17.9997 3.85752 17.0711 2.92893C16.1425 2.00035 15.0401 1.26375 13.8268 0.761205C12.6136 0.258658 11.3132 0 10 0Z" fill="#979AAE"/>
</svg>

export const save = <svg className="icon icon-save" width="16" height="16" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M12 6H2V2H12M9 16C8.20435 16 7.44129 15.6839 6.87868 15.1213C6.31607 14.5587 6 13.7956 6 13C6 12.2044 6.31607 11.4413 6.87868 10.8787C7.44129 10.3161 8.20435 10 9 10C9.79565 10 10.5587 10.3161 11.1213 10.8787C11.6839 11.4413 12 12.2044 12 13C12 13.7956 11.6839 14.5587 11.1213 15.1213C10.5587 15.6839 9.79565 16 9 16ZM14 0H2C0.89 0 0 0.9 0 2V16C0 16.5304 0.210714 17.0391 0.585786 17.4142C0.960859 17.7893 1.46957 18 2 18H16C16.5304 18 17.0391 17.7893 17.4142 17.4142C17.7893 17.0391 18 16.5304 18 16V4L14 0Z" fill="#625B71"/>
</svg>

export const hideIcon = <svg className="icon icon-hide" width="22" height="19" viewBox="0 0 22 19" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M10.83 6L14 9.16C14 9.11 14 9.05 14 9C14 8.20435 13.6839 7.44129 13.1213 6.87868C12.5587 6.31607 11.7956 6 11 6C10.94 6 10.89 6 10.83 6ZM6.53 6.8L8.08 8.35C8.03 8.56 8 8.77 8 9C8 9.79565 8.31607 10.5587 8.87868 11.1213C9.44129 11.6839 10.2044 12 11 12C11.22 12 11.44 11.97 11.65 11.92L13.2 13.47C12.53 13.8 11.79 14 11 14C9.67392 14 8.40215 13.4732 7.46447 12.5355C6.52678 11.5979 6 10.3261 6 9C6 8.21 6.2 7.47 6.53 6.8ZM1 1.27L3.28 3.55L3.73 4C2.08 5.3 0.78 7 0 9C1.73 13.39 6 16.5 11 16.5C12.55 16.5 14.03 16.2 15.38 15.66L15.81 16.08L18.73 19L20 17.73L2.27 0M11 4C12.3261 4 13.5979 4.52678 14.5355 5.46447C15.4732 6.40215 16 7.67392 16 9C16 9.64 15.87 10.26 15.64 10.82L18.57 13.75C20.07 12.5 21.27 10.86 22 9C20.27 4.61 16 1.5 11 1.5C9.6 1.5 8.26 1.75 7 2.2L9.17 4.35C9.74 4.13 10.35 4 11 4Z" fill="#979AAE"/>
</svg>

export const exportIcon = <svg className="icon icon-export" width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M10 0C15.5 0 20 4.5 20 10C20 15.5 15.5 20 10 20C4.5 20 0 15.5 0 10C0 4.5 4.5 0 10 0ZM6 15H14V13H6V15ZM14 8H11.5V4H8.5V8H6L10 12L14 8Z" fill="#625B71"/>
</svg>





