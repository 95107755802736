import * as Auth from "./auth/auth"
import React from "react";
import {FormattedMessage} from "react-intl";

const capitalizerFirstLetter = (string) => {
    if (string === "" || string === null) {
        return "";
    }
    return string.charAt(0).toUpperCase() + string.slice(1);
}

const logout = (history) => {
    Auth.logout();
    history.push("/auth/login-page");
}

const dateIsValid = (date) => {
    return !Number.isNaN(new Date(date).getTime());
}

const getNotifySuccessOptions = (place,message) => {
    let options = {
        place: place,
        message: (
            <span>
                {message?message:<FormattedMessage id="Notification.savedChanges"/>}
            </span>
        ),

        icon: "fa fa-save",
        autoDismiss: 5,
    };
   return options;
};

const getNotifyErrorOptions = (place,message) => {
    let options = {
        place: place,
        type:'danger',
        message: (
            <span>
                {message?message:<FormattedMessage id="Notification.error"/>}
            </span>
        ),

        icon: "fa fa-exclamation-circle",
        autoDismiss: 5,
    };
    return options;
};

const getLocate=()=>{
    let language =
        (navigator.languages && navigator.languages[0]) ||
        navigator.language ||
        navigator.userLanguage;
    return language.replace("-","_");
}

export {
    capitalizerFirstLetter,
    logout,
    dateIsValid,
    getNotifySuccessOptions,
    getNotifyErrorOptions,
    getLocate
}

