import * as RecordTypeService from "service/RecordTypeService";
import * as RecordService from "service/RecordService";
import * as ViewService from "service/ViewsService";

const getRecordTypeByIdHandler=async(recordTypeId)=>{

    let recordFields=[];
    let recordType={};
    let hiddenRecordFields=[];

    const responseFindRecordType = await RecordTypeService.findRecordTypeById(recordTypeId);
    let ok = responseFindRecordType.ok
    if (responseFindRecordType.ok) {
        recordType={
            "id": recordTypeId,
            "name": responseFindRecordType.data.name,
            "description": responseFindRecordType.data.description,
            "type": responseFindRecordType.data.type,
        };
        recordFields=responseFindRecordType.data.fields.map(field=>{
            if(field.id==="notifications" || field.id==="wuid"){
                hiddenRecordFields.push(field);
                return null;
            } else{
                return field;
            }
        }).filter(field=>field!==null)

        const responseExistRecords = await RecordService.existRecordsByNamespace(responseFindRecordType.data.ns)
        ok = ok && responseExistRecords.ok;
        if(responseExistRecords.ok) {
            recordType.hasRecords = responseExistRecords.data
        }
    }

    return{
        data:{
            recordType:recordType,
            recordFields:recordFields,
            hiddenRecordFields:hiddenRecordFields,
        },
        ok:ok
    };
}

const saveRecordTypeHandler=async(recordTypeId,newRecordType)=>{
    let response;
    if (recordTypeId) {
        response = await RecordTypeService.updateRecordType(newRecordType);
    } else {
        response = await RecordTypeService.addRecordType(newRecordType);
    }

    return{
        ok:response.ok
    };
}

const getAllViewsInfoHandler=async(wid)=>{
    let recordViews=[];
    let recordViewsOptions=[];
    const response = await ViewService.findAllInfo(wid);
    if (response.ok) {
        recordViews=response.data;
        recordViewsOptions=response.data.map(recordView => {
            return {
                "label": recordView.name,
                "value": recordView.id,
            }
        });
    }
    return{
        data:{
            recordViews:recordViews,
            recordViewsOptions:recordViewsOptions
        },
        ok:response.ok
    };

}

export {
    getRecordTypeByIdHandler,
    saveRecordTypeHandler,
    getAllViewsInfoHandler
};
