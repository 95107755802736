import React, {useState, useEffect} from "react";
import Select from "react-select";
import {useAllViews} from "views/workspaceAdmin/menu/context/ViewProvider.js";
import {FormattedMessage} from 'react-intl';
import {
    Row,
    Col,
    Form,
    Card
} from "react-bootstrap";

function AddActionComponent(props) {

    const [currentOption, setCurrentOption] = useState(props.viewId);
    const allViews = useAllViews();
    const allViewOptions = allViews.map(view => {
        return {
            "label": view.name,
            "value": view.name,
            "id": view.id,
        }
    });

    useEffect(() => {
        const currentView = allViewOptions.find(view => view.id === props.viewId);
        if (!!!currentView) {
            setCurrentOption(false);
            return;
        }
        setCurrentOption(allViewOptions.find(view => view.id === props.viewId) || allViewOptions[0]);
    }, [props.viewId, allViews.length]);

    return (
        <div className="add-action-component">
            <Card.Body>
            <Form.Group>
                <Row>
                    <Col>
                        <Card.Title as="h3"><FormattedMessage id="EnableAddButton"/></Card.Title>
                    </Col>
                    <Col className="inline-switch-col">
                        <Form.Check
                            className="ml-3"
                            type="switch"
                            id="crossa-pre-selected-option-switch"
                            checked={props.enableAddAction}
                            onChange={props.handleEnableEditChange}
                        />
                    </Col>
                </Row>

            </Form.Group>

                    {
                        props.enableAddAction && <>
                        <Form.Group>
                            <Form.Label>
                                <FormattedMessage id="ButtonName"/>
                            </Form.Label>
                            <FormattedMessage id="ButtonName">
                                {placeholder =>
                                    <Form.Control
                                        placeholder={placeholder}
                                        type="text"
                                        value={props.buttonName || ""}
                                        onChange={props.handleButtonName}
                                        className={(props.enableAddAction ? "" : "disabled") + (props.errors?.pages?.[props.pageIndex]?.addActionName ? " has-errors" : "")}
                                        isInvalid={props.errors?.pages?.[props.pageIndex]?.addActionName}
                                    />
                                }
                            </FormattedMessage>
                            <Form.Control.Feedback type={"invalid"}>
                                <FormattedMessage id={"Validation.cannotBeEmpty"}/>
                            </Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group>
                            <Form.Label>
                                <FormattedMessage id="View"/>
                            </Form.Label>
                            <FormattedMessage id="Placeholder.select">
                                {placeholder =>
                                    <Select
                                        className={(props.enableAddAction ? "react-select primary" : "react-select primary disabled") + (props.errors?.pages?.[props.pageIndex]?.addActionVid ? " has-errors" : "")}
                                        classNamePrefix="react-select"
                                        options={allViewOptions}
                                        onChange={props.handleViewChange}
                                        value={currentOption}
                                        placeholder={placeholder}
                                    />
                                }
                            </FormattedMessage>
                            {props.errors?.pages?.[props.pageIndex]?.addActionVid && <div className={"error-message"}>
                                <FormattedMessage id={"Validation.selectOneOption"}/>
                            </div>}
                        </Form.Group>
                    </>}
                </Card.Body>

        </div>
    )
}

export default AddActionComponent;
