import * as RecordTypeService from "service/RecordTypeService";
import * as QueryBuilder from "util/query/queryBuilder.js";
import {FormattedMessage} from "react-intl";
import React from "react";


const actionOptions = [
    { label: <FormattedMessage id="Action.show" />, value: "Show" },
    { label: <FormattedMessage id="Action.hide" />, value: "Hide" },
    { label: <FormattedMessage id="Action.makeReadonly" />, value: "MakeReadonly" },
    { label: <FormattedMessage id="Action.makeEditable" />, value: "MakeEditable" },
    { label: <FormattedMessage id="Action.makeMandatory" />, value: "MakeMandatory" },
    { label: <FormattedMessage id="Action.removeMandatory" />, value: "RemoveMandatory" },
    { label: <FormattedMessage id="Action.setValueOf" />, value: "SetValue" },
    { label: <FormattedMessage id="Action.clearValueOf" />, value: "Clear" }
];

const getRecordTypeByIdHandler = async (recordTypeId,ruleIndex) => {
    let recordType={};
    let conditions=[];
    let actions=[];
    let isActive=false;
    let isDefault=false;
    let recordFields=[];

    const response = await RecordTypeService.findRecordTypeById(recordTypeId);
    if (response.ok) {
        let fieldsMap = [];
        for (let i = 0; i < response.data.fields.length; ++i) {
            fieldsMap[response.data.fields[i].id] =
            {
                ...response.data.fields[i]
            };
        }

        recordType={
            "id": recordTypeId,
            "name": response.data.name,
            "rules": response.data.rules || [],
        };
        const conditionOptions = [
            { label: <FormattedMessage id="IsNew" values={{param:response.data.name}}/>, value: "IsNew" },
            { label: <FormattedMessage id="IsChanged" />, value: "IsChanged" },
        ];

        if (ruleIndex && response.data.rules && response.data.rules[ruleIndex]) {
            conditions = QueryBuilder.queryToConditions([JSON.parse(response.data.rules[ruleIndex].condition)], fieldsMap, null,JSON.parse(response.data.rules[ruleIndex].condition)?.type === "Or", true);
            actions = QueryBuilder.queryToActions(JSON.parse(response.data.rules[ruleIndex].actions), fieldsMap, null);

            conditions.map((condition, index) => {
                if (index % 2 === 0)
                    condition.selectedCondition.label = conditionOptions.find(conditionOption => conditionOption.value === condition.selectedCondition.value).label;
            })

            actions.map(action => {
                action.selectedAction.label = actionOptions.find(actionOption => actionOption.value === action.selectedAction.value).label;
            });

            isActive=response.data.rules[ruleIndex].isActive==undefined?true:response.data.rules[ruleIndex].isActive;
            isDefault=response.data.rules[ruleIndex].isDefault;
        }
        recordFields=response.data.fields;
    }
    return{
        data:{
            recordType:recordType,
            conditions:conditions,
            actions:actions,
            recordFields:recordFields,
            isActive:isActive,
            isDefault:isDefault,
        },
        ok:response.ok
    }
}

const saveRuleHandler=async(recordType)=>{
    const response = await RecordTypeService.updateRecordType(recordType);

    return{
        ok:response.ok,
    }
}

export {
    getRecordTypeByIdHandler,
    saveRuleHandler
}
