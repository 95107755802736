import React from "react";
import {FormattedMessage} from 'react-intl';
import {Button, Container} from "react-bootstrap";

function CrossaPageTemplate(props) {

    return (
        <Container fluid className="crossa-page-template">
                <div className="crossa-template-body">
                    {
                        props.children
                    }
                </div>

            {props.hasFooter &&
                <footer>

                        {props.closeSlidingPanel != null && <Button
                            className="sliding-panel-btn"
                            variant="default"
                            onClick={() => props.closeSlidingPanel()}>
                            {props.cancelBtnText ? props.cancelBtnText :
                            <FormattedMessage id="Button.cancel"/>}
                        </Button>}
                        {props.saveChanges != null && <Button
                            className="sliding-panel-btn"
                            variant="primary"
                            disabled={props.saveDisabled}
                            onClick={() => props.saveChanges()}>
                            {props.saveChangesBtnText ? props.saveChangesBtnText :
                            <FormattedMessage id="Button.save"/>}
                        </Button>}

                </footer>
            }
        </Container>
    );
}

export default CrossaPageTemplate;
