const EXCEPTION_MESSAGES = {
    NOT_FOUND_DB: "User not in DB",
    CONFLICT: "Duplicate user",
    USER_NOT_FOUND: "User not found",
    UNAUTHORIZED: "Invalid password",
    INVALID_REFRESH_TOKEN: "Invalid refresh token",
    DUPLICATED_PAGE: "Duplicated page",
    DUPLICATED_TAB: "Duplicated tab"
}

export const LAYOUT = {
    AUTH: "auth",
    ADMIN: "admin",
    WORKSPACE_MEMBER: "workspace-member"
}

export const RECORD_TYPE_TYPE = {
    REGULAR: "Regular",
    MEMBER: "Member",
    FORM: "Form",
    FORM_DATA: "FormData",
    PAYMENT:"Payment",
}

export const RECORD_FIELD_TYPE={
    RECORD_LIST:"RecordList",
    ROLES_LIST:"RolesList",
    RECORD:"Record",
    MULTIPLE_CHOICE:"MultipleChoice",
    SINGLE_CHOICE:"SingleChoice",
    DATE:"Date",
    BOOLEAN:"Boolean",
    TEXT:"Text",
    INTEGER:"Integer",
    DECIMAL:"Decimal",
    GROUP_HEADER:"GroupHeader",
    CURRENCY:"Currency",
    IMAGE:"Image",
    NOTIFICATIONS:"Notifications",
    WORKSPACE_USER:"WorkspaceUser",
    PAYMENT:"Payment",
    FILE:"File",
    FILE_LIST:"FileList",
}

export const RECORD_FIELD_TEXT_DISPLAY_OPTION= {
    SINGLE_LINE: "SingleLine",
    TEXT_AREA: "TextArea",
    HYPER_LINK: "HyperLink",
}

export const RECORD_FIELD_RECORD_LIST_DISPLAY_OPTION= {
    CHECKBOX_GROUP:"CheckboxGroup",
    MULTIPLE_CHOICE_LIST:"MultipleChoiceList",
    SEARCH_SELECT:"SearchSelect"
}

export const RECORD_FIELD_RECORD_DISPLAY_OPTION={
    COMBO_BOX:"ComboBox",
    RADIO_BUTTON_GROUP:"RadioButtonGroup",
    SEARCH_SELECT:"SearchSelect",
    FORM_LINK:"FormLink"
}

export const RECORD_FIELD_MODE={
    WRITE:"WRITE",
    HIDE:"HIDE",
    READ:"READ"
}

export const FORM_ACTION = {
    INSERT: "INSERT",
    EDIT: "EDIT",
    UPDATE:"UPDATE",
}

export const FORM_FIELD_TYPE={
    PAYMENT:"Payment",
    TEXT:"Text",
    DATE: "Date",
    SINGLE_CHOICE: "SingleChoice",
    MULTIPLE_CHOICE: "MultipleChoice",
    GROUP_HEADER: "GroupHeader"
}

export const FORM_FIELD_TEXT_DISPLAY_OPTION= {
    SINGLE_LINE: "SingleLine",
    TEXT_AREA: "TextArea",
    EMAIL: "Email",
    PHONE: "Phone",
}

export const FORM_FIELD_SINGLE_CHOICE_DISPLAY_OPTION= {
    DROPDOWN:"Dropdown",
    RADIO_BUTTON_GROUP:"RadioButtonGroup"
}


export const CONTEXT = {
    SUPER_ADMIN: "SuperAdmin",
    WORKSPACE_ADMIN: "WorkspaceAdmin",
    WORKSPACE_MEMBER: "WorkspaceMember"
}

export const VALIDATIONS = {
    MIN_LENGTH_DEFAULT: 3,
    MAX_LENGTH_DEFAULT: 50,

}

export const RECORDS_DISPLAY_MODE={
    TILES:"Tiles",
    TABLE:"Table"
}

export const DETAILED_VIEW_MODE={
    FULL_PAGE:"FullPage",
    SIDE_PANEL:"SidePanel"
}

export const SIDEBAR_STYLE={
    OPEN:"open",
    CLOSED:"closed"
}

export const FORM_SIZE = {
    SMALL: "small",
    BIG: "big"
}

export const SORT_DIRECTION={
    ASC:"ASC",
    DESC:"DESC"
}

export const CODE_ERROR={
    DUPLICATE_USER:1,
    USER_NOT_FOUND:2,
    UNAUTHORIZED:3,
    MISSING_DEFAULT_FIELDS:4,
    INVALID_WORKSPACE:5,
    INVALID_RECORD_TYPE:6,
    INVALID_VIEW:7,
    INVALID_RECORD:8,
    INVALID_MENU:9,
    INVALID_FILTER:10,
    INVALID_ROLE:11,
    INVALID_USER:12,
    INVALID_WORKSPACE_USER:13,
    DUPLICATED_TAB:14,
    DUPLICATED_PAGE:15,
    CANNOT_DELETE_MEMBER_RT:16,
    CANNOT_DELETE_FORM_RT:17,
    CANNOT_DELETE_RT:18,
    CANNOT_DELETE_MEMBER_RV:19,
    CANNOT_DELETE_FORM_RV:20,
    FORM_MAX_NR_OF_REG_REACHED:21,
    FORM_INACTIVE:22,
    FORM_EXPIRED:23,
    FORM_REQUIRED_LOGGED_USER:24,
    FORM_INVALID_CAPTCHA:25,
    FORM_INVALID_HASH:26,
    PAYLIKE_INVALID_TRANSACTION:27,
    PAYLIKE_INVALID_MERCHANT:28,
    PAYLIKE_INVALID_CAPTURE:29,
    PAYLIKE_INVALID_CREATE_LINK:30,

    PAYLIKE_TRANSACTION_ERROR:31,
    PAYLIKE_TRANSACTION_CAPTURE_ERROR:32,
    INVALID_MEMBER_TAB_FILTER:33,

}

export const CROPPER_ASPECT_RATIO = {
    SQUARE: 1,
    RECTANGULAR: 16/9
}

export const INDETERMINATE_CHECKBOX={
    CHECKED:1,
    UNCHECKED:2,
    INDETERMINATE:-1
}

export const PAYMENT_STATUS={
    UNPAID:"UNPAID",
    COMPLETE:"COMPLETE",
    PENDING:"PENDING",
    REFUND:"REFUND",
    CANCELED:"CANCELED",
    FRAUD:"FRAUD",
}

export const FILTERS={
    SAVED_FILTERS:"savedFilters",
    ALL_FILTERS:"allFilters",
}

export const WIDGET_TYPE={
    WELCOME:"WELCOME",
    TABLE:"TABLE",
    BAR_CHART:"BAR_CHART",
    COLUMN_CHART:"COLUMN_CHART",
    PIE_CHART:"PIE_CHART"
}

export const WIDGET_OPERATOR={
    ROW_COUNT:"ROW_COUNT",
    SUM:"SUM",
    AVERAGE:"AVERAGE",
    MIN:"MIN",
    MAX:"MAX"
}

export default EXCEPTION_MESSAGES;
