import React from "react";
import {WIDGET_TYPE} from "../../../../util/Constants";
import {Card, Col, Form, Row} from "react-bootstrap";
import {FormattedMessage} from "react-intl";
import welcomeImg from "../../../../assets/img/home-avatar.svg";
import barChartPreviewImg from "../../../../assets/img/bar-chart-preview.svg";
import columnChartPreviewImg from "../../../../assets/img/column-chart-preview.svg";
import pieChartPreviewImg from "../../../../assets/img/pie-chart-preview.svg";
import table5PreviewImg from "../../../../assets/img/table-5-preview.svg";
import table10PreviewImg from "../../../../assets/img/table-10-preview.svg";
import table15PreviewImg from "../../../../assets/img/table-15-preview.svg";
function PreviewComponent(props){

    return(
        <Form.Group>
            <Form.Label><FormattedMessage id="Preview"/></Form.Label>
            {props.widgetInfo.type === WIDGET_TYPE.WELCOME &&
                <Card className="welcome-card">
                    <Card.Body className="row">
                        <Col className="text-col">
                            <h2>{props.widgetInfo.title || <FormattedMessage id="WelcomePlaceholder"/>}</h2>
                            <p>{props.widgetInfo.description || <FormattedMessage id="CheckDashboard" />}</p>
                        </Col>
                        <Col>
                            <img src={welcomeImg} alt={"."}/>
                        </Col>
                    </Card.Body>
                </Card>
            }
            {props.widgetInfo.type === WIDGET_TYPE.BAR_CHART &&
                <img className="widget-preview-image" src={barChartPreviewImg} alt={"."}/>
            }
            {props.widgetInfo.type === WIDGET_TYPE.COLUMN_CHART &&
                <img className="widget-preview-image" src={columnChartPreviewImg} alt={"."}/>
            }
            {props.widgetInfo.type === WIDGET_TYPE.PIE_CHART &&
                <img className="widget-preview-image" src={pieChartPreviewImg} alt={"."}/>
            }
            {props.widgetInfo.type === WIDGET_TYPE.TABLE && props.widgetInfo.rows===5 &&
                <img className="widget-preview-image" src={table5PreviewImg} alt={"."}/>
            }
            {props.widgetInfo.type === WIDGET_TYPE.TABLE && props.widgetInfo.rows===10 &&
                <img className="widget-preview-image" src={table10PreviewImg} alt={"."}/>
            }
            {props.widgetInfo.type === WIDGET_TYPE.TABLE && props.widgetInfo.rows===15 &&
                <img className="widget-preview-image" src={table15PreviewImg} alt={"."}/>
            }
        </Form.Group>
    )
}

export default PreviewComponent;