import * as UserService from "service/UserService.js"
import { FORM_ACTION } from "util/Constants.js";


const getUserByIdHandler = async (userId) => {

    let user = null;

    const response = await UserService.findUser(userId);

    if (response.ok) {
        user = response.data;
    }

    return {
        data: {
            user: user,
        },
        ok: response.ok
    }
}

const saveUserHandler = async (user, action) => {
    let response = "";
    if (action === FORM_ACTION.INSERT) {
        response = await UserService.createUser(user);
    } else if (action === FORM_ACTION.EDIT) {
        response = await UserService.updateUser(user);
    }

    return {
        ok: response.ok,
        errorCode:response.data.code
    }
}

export {
    getUserByIdHandler,
    saveUserHandler

}
