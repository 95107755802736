import React, {useEffect, useState} from "react";
import {Col, Form, Row,} from "react-bootstrap";
import Select from "react-select";
import {FormattedMessage} from "react-intl";
import AsyncSelect from "react-select/async";
import {
    getAllRecordsWithNameContainsHandler
} from "handlers/workspaceAdminHandlers/recordTypeHandlers/AddRuleRecordTypeHandlers";
import {findRecordByIdHandler} from "handlers/workspaceAdminHandlers/recordTypeHandlers/AddRuleRecordTypeHandlers";
import DateTimePicker from "react-datetime-picker";
import {iconX} from "../../../../../components/Icons";

function ConditionEditor(props) {

    const [state, setState] = useState({
        fieldOptions: [],
        operatorOptions: [],
        type: "",
        operator: null,
        valueFromOptions: [],
        valueFrom: null,
    });

    useEffect(() => {
        let operatorOptions = [];
        let stateOperator = null;
        let valueFromOptions = [];
        let valueFrom = null;
        if (props.condition?.type) {
            operatorOptions = operators[props.condition?.type];
            if (props.condition?.operator) {
                switch (props.condition?.type) {
                    case "text":
                        for (let operator in textOperators) {
                            if (textOperators[operator].find(op => op.value === props?.condition?.operator)) {

                                valueFromOptions = textOperators[operator];
                                valueFrom = textOperators[operator].find(op => op.value === props?.condition?.operator);
                                stateOperator = operators[props?.condition?.type].find(op => op.value === operator && (typeof op.isEmpty !== "undefined" ? op.isEmpty === props.condition?.inputValue : true));
                                break;
                            }
                        }
                        break;
                    case "integer":
                        for (let operator in integerOperators) {
                            if (integerOperators[operator].find(op => op.value === props.condition.operator)) {
                                valueFromOptions = integerOperators[operator];
                                valueFrom = integerOperators[operator].find(op => op.value === props.condition.operator);
                                stateOperator = operators[props.condition.type].find(op => op.value === operator && (typeof op.isEmpty !== "undefined" ? op.isEmpty === props.condition?.inputValue : true));
                                break;
                            }
                        }
                        break;
                    case "decimal":
                    case "currency":
                        for (let operator in decimalOperators) {
                            if (decimalOperators[operator].find(op => op.value === props.condition.operator)) {
                                valueFromOptions = decimalOperators[operator];
                                valueFrom = decimalOperators[operator].find(op => op.value === props.condition.operator);
                                stateOperator = operators[props.condition.type].find(op => op.value === operator && (typeof op.isEmpty !== "undefined" ? op.isEmpty === props.condition?.inputValue : true));
                                break;
                            }
                        }
                        break;
                    case "date":
                        for (let operator in dateOperators) {
                            if (dateOperators[operator].find(op => op.value === props.condition.operator)) {
                                valueFromOptions = dateOperators[operator];
                                valueFrom = dateOperators[operator].find(op => op.value === props.condition.operator);
                                stateOperator = operators[props.condition.type].find(op => op.value === operator && (typeof op.isEmpty !== "undefined" ? op.isEmpty === props.condition?.inputValue : true));
                                break;
                            }
                        }
                        break;
                    case "singlechoice":
                        for (let operator in singleOperators) {
                            if (singleOperators[operator].find(op => op.value === props.condition.operator)) {
                                valueFromOptions = singleOperators[operator];
                                valueFrom = singleOperators[operator].find(op => op.value === props.condition.operator);
                                stateOperator = operators[props.condition.type].find(op => op.value === operator && (typeof op.isEmpty !== "undefined" ? op.isEmpty === props.condition?.inputValue : true));
                                break;
                            }
                        }
                        break;
                    case "multiplechoice":
                        for (let operator in multiOperators) {
                            if (multiOperators[operator].find(op => op.value === props.condition.operator)) {
                                valueFromOptions = multiOperators[operator];
                                valueFrom = multiOperators[operator].find(op => op.value === props.condition.operator);
                                stateOperator = operators[props.condition.type].find(op => op.value === operator && (typeof op.isEmpty !== "undefined" ? op.isEmpty === props.condition?.inputValue : true));
                                break;
                            }
                        }
                        break;
                    case "boolean":
                        for (let operator in booleanOperators) {
                            if (booleanOperators[operator].find(op => op.value === props.condition.operator)) {
                                valueFromOptions = booleanOperators[operator];
                                valueFrom = booleanOperators[operator].find(op => op.value === props.condition.operator);
                                stateOperator = operators[props.condition.type].find(op => op.value === operator && (typeof op.isEmpty !== "undefined" ? op.isEmpty === props.condition?.inputValue : true));
                                break;
                            }
                        }
                        break;
                    case "record":
                        for (let operator in recordOperators) {
                            if (recordOperators[operator].find(op => op.value === props.condition.operator)) {
                                valueFromOptions = recordOperators[operator];
                                valueFrom = recordOperators[operator].find(op => op.value === props.condition.operator);
                                stateOperator = operators[props.condition.type].find(op => op.value === operator && (typeof op.isEmpty !== "undefined" ? op.isEmpty === props.condition?.inputValue : true));
                                break;
                            }
                        }
                        break;
                    case "recordlist":
                        for (let operator in recordListOperators) {
                            if (recordListOperators[operator].find(op => op.value === props.condition.operator)) {
                                valueFromOptions = recordListOperators[operator];
                                valueFrom = recordListOperators[operator].find(op => op.value === props.condition.operator);
                                stateOperator = operators[props.condition.type].find(op => op.value === operator && (typeof op.isEmpty !== "undefined" ? op.isEmpty === props.condition?.inputValue : true));
                                break;
                            }
                        }
                        break;
                }
            } else if (props.condition?.operatorValue) {
                stateOperator = props.condition?.operatorValue;
                switch (props.condition?.type) {
                    case "text":
                        valueFromOptions = textOperators[stateOperator.value];
                        break;
                    case "integer":
                        valueFromOptions = integerOperators[stateOperator.value];
                        break;
                    case "decimal":
                    case "currency":
                        valueFromOptions = decimalOperators[stateOperator.value];
                        break;
                    case "date":
                        valueFromOptions = dateOperators[stateOperator.value];
                        break;
                    case "singlechoice":
                        valueFromOptions = singleOperators[stateOperator.value];
                        break;
                    case "multiplechoice":
                        valueFromOptions = multiOperators[stateOperator.value];
                        break;
                    case "boolean":
                        valueFromOptions = booleanOperators[stateOperator.value];
                        break;
                    case "record":
                        valueFromOptions = recordOperators[stateOperator.value];
                        break;
                    case "recordlist":
                        valueFromOptions = recordListOperators[stateOperator.value];
                        break;

                }
            }
        }
        let fieldOptions = [];
        switch (props.condition?.selectedCondition?.value) {
            case "IsNew":
                fieldOptions = [];
                break;
            case "IsChanged":
                fieldOptions = props.recordFields.filter(recordField => recordField.type.toLocaleLowerCase() !== "groupheader" && recordField.type.toLocaleLowerCase() !== "workspaceuser" && recordField.type.toLocaleLowerCase() !== "notifications" &&
                    recordField.type.toLocaleLowerCase() !== "image" && recordField.type.toLocaleLowerCase() !== "roleslist" && recordField.type.toLocaleLowerCase() !== "file" && recordField.type.toLocaleLowerCase() !== "filelist").map(recordField => {
                    return {
                        "label": recordField.name,
                        "type": recordField.type.toLowerCase(),
                        "value": recordField.id,
                        "valueOptions": recordField.options,
                        "vid": recordField.vid
                    }
                });
                break;
        }
        setState({
            ...state,
            operatorOptions: operatorOptions,
            operator: stateOperator,
            valueFromOptions: valueFromOptions,
            valueFrom: valueFrom,
            type: props.condition.type,
            valueOptions: props.condition.type === "boolean"?[{value: "true", label: "true"}, {value: "false", label: "false"}]:
                props.recordFields.find(recordField => recordField.id === props.condition.operand?.value)?.options?.map(option => ({
                "value": option,
                "label": option
            })),
            fieldOptions: fieldOptions,
        });
    }, [props]);

    const handleConditionChange = e => {
        let operator = "";
        if (e.value === "IsNew") {
            operator = e.value;
        }
        let newErrors = {
            ...props.errors,
            "operand": false,
            "operator": false,
            "operatorValue": false,
            "inputValue": false
        };
        if (!!props.errors["selectedCondition"]) {
            newErrors = {
                ...newErrors,
                "selectedCondition": false,
            };
        }
        props.changeCondition({
            ...props.condition,
            "selectedCondition": {
                "value": e.value,
                "label": e.label,
            },
            "operator": operator
        }, props.index, newErrors);
    }

    const handleFieldChange = e => {
        let newErrors = {...props.errors, "operator": false, "operatorValue": false, "inputValue": false};
        if (!!props.errors["operand"]) {
            newErrors = {
                ...newErrors,
                "operand": false,
            };
        }
        props.changeCondition({
            ...props.condition,
            "type": e.type,
            "eqRecord": e.type === "record" || e.type === "recordlist",
            "operand": {      ///selected field
                "value": e.value,
                "label": e.label
            },
            "operator": null,
            "operatorValue": null,
            "inputValue": e.type === "boolean" ? false : ""
        }, props.index, newErrors);

    }

    const handleOperatorChange = e => {
        let newErrors = {...props.errors, "operator": false, "inputValue": false};
        if (!!props.errors["operatorValue"]) {
            newErrors = {
                ...newErrors,
                "operatorValue": false,
            };
        }
        let operator = "";
        let inputValue = "";
        if (typeof e.isEmpty !== "undefined") {
            inputValue = e.isEmpty;

            switch (props.condition?.type) {
                case "text":
                    operator = textOperators[e.value][0]?.value;
                    break;
                case "integer":
                    operator = integerOperators[e.value][0]?.value;
                    break;
                case "decimal":
                case "currency":
                    operator = decimalOperators[e.value][0]?.value;
                    break;
                case "date":
                    operator = dateOperators[e.value][0]?.value;
                    break;
                case "singlechoice":
                    operator = singleOperators[e.value][0]?.value;
                    break;
                case "multiplechoice":
                    operator = multiOperators[e.value][0]?.value;
                    break;
                case "boolean":
                    operator = booleanOperators[e.value][0]?.value;
                    break;
                case "record":
                    operator = recordOperators[e.value][0]?.value;
                    break;
                case "recordlist":
                    operator = recordListOperators[e.value][0]?.value;
                    break;

            }
        }
        if (props.condition.type === "boolean") {
            inputValue = false;
        }
        props.changeCondition({
            ...props.condition,
            "operatorValue": e,
            "operator": operator,
            "inputValue": inputValue
        }, props.index, newErrors);

    }

    const handleValueFromChange = e => {
        let newErrors = {...props.errors, "inputValue": false};
        if (!!props.errors["operator"]) {
            newErrors = {
                ...newErrors,
                "operator": false,
            };
        }
        props.changeCondition({
            ...props.condition,
            "operator": e.value,
        }, props.index, newErrors);
    }

    const handleDateInputValueChange = value => {
        let newErrors = {...props.errors};
        if (!!props.errors["inputValue"]) {
            newErrors = {
                ...newErrors,
                "inputValue": false,
            };
        }
        if(value) {
            let userTimezoneOffset = new Date(value).getTimezoneOffset() * 60000;
            props.changeCondition({
                ...props.condition,
                "inputValue": new Date(value.getTime() - userTimezoneOffset),
            }, props.index, newErrors)
        } else {
            props.changeCondition({
                ...props.condition,
                "inputValue": null,
            }, props.index, newErrors)
        }
    }

    const handleValueChange = e => {
        let newErrors = {...props.errors};
        if (!!props.errors["inputValue"]) {
            newErrors = {
                ...newErrors,
                "inputValue": false,
            };
        }
        props.changeCondition({
            ...props.condition,
            "inputValue": state.type === "boolean" ? e.value === "true" :
                (state.type === "singlechoice" || state.type === "multiplechoice") ? e.value : e.target.value,
        }, props.index, newErrors);
    }

    const getValueInputField = () => {
        let result = "";
        switch (state.type) {
            case "text":
                result = <>
                    <FormattedMessage id="Value" >
                        {placeholder =>
                            <Form.Control className={props.errors?.inputValue ? "has-errors" : ""}
                                          placeholder={placeholder}
                                          type="text"
                                          onChange={handleValueChange}
                                          isInvalid={props.errors?.inputValue}
                                          value={props.condition.inputValue}
                            />
                        }
                    </FormattedMessage>
                    <Form.Control.Feedback type="invalid">
                        <FormattedMessage id={"Validation.cannotBeEmpty"}/>
                    </Form.Control.Feedback></>
                break;
            case "integer":
                result = <>
                    <FormattedMessage id="Value" >
                        {placeholder =>
                            <Form.Control className={props.errors?.inputValue ? "has-errors" : ""}
                                          placeholder={placeholder}
                                          type="number"
                                          onChange={handleValueChange}
                                          isInvalid={props.errors?.inputValue}
                                          value={props.condition.inputValue}/>
                        }
                    </FormattedMessage>
                    <Form.Control.Feedback type="invalid">
                        <FormattedMessage id={"Validation.cannotBeEmpty"}/>
                    </Form.Control.Feedback></>
                break;
            case "decimal":
                result = <>
                    <FormattedMessage id="Value" >
                        {placeholder =>
                            <Form.Control className={props.errors?.inputValue ? "has-errors" : ""}
                                          placeholder={placeholder}
                                          type="number"
                                          onChange={handleValueChange}
                                          isInvalid={props.errors?.inputValue}
                                          value={props.condition.inputValue}/>
                        }
                    </FormattedMessage>
                    <Form.Control.Feedback type="invalid">
                        <FormattedMessage id={"Validation.cannotBeEmpty"}/>
                    </Form.Control.Feedback></>
                break;
            case "currency":
                result = <>
                    <FormattedMessage id="Value" >
                        {placeholder =>
                            <Form.Control className={props.errors?.inputValue ? "has-errors" : ""}
                                          placeholder={placeholder}
                                          type="number"
                                          onChange={handleValueChange}
                                          isInvalid={props.errors?.inputValue}
                                          value={props.condition.inputValue}/>
                        }
                    </FormattedMessage>
                    <Form.Control.Feedback type="invalid">
                        <FormattedMessage id={"Validation.cannotBeEmpty"}/>
                    </Form.Control.Feedback></>
                break;
            case "date":
                if (props.condition.value !== "currentDate") {
                    result = <>
                        <DateTimePicker
                            maxDate={new Date("9999-12-31")}
                            className={props.errors?.inputValue ? "has-errors" : ""}
                            onChange={handleDateInputValueChange}
                            format={"y-MM-dd"}
                            value={props.condition.inputValue ? new Date(props.condition.inputValue) : ""}
                        />
                        {
                            props.errors?.inputValue && <div className="error-message">
                                <FormattedMessage id={"Validation.cannotBeEmpty"}/>
                            </div>
                        }
                    </>
                } else {
                    result = null
                }
                break;
            case "boolean":
                result = <Select
                    className="react-select primary"
                    classNamePrefix="react-select"
                    onChange={handleValueChange}
                    options={state.valueOptions}
                    value={
                        props.condition.inputValue == null ?
                            null :
                            {
                                "value":  props.condition.inputValue.toString(),
                                "label":  props.condition.inputValue.toString()
                            }
                    }
                />
                break;
            case "singlechoice":
                result = <><Select
                    className={"react-select primary " + (props.errors?.inputValue ? "has-errors" : "")}
                    classNamePrefix="react-select"
                    onChange={handleValueChange}
                    options={state.valueOptions}
                    value={{
                        "value": props.condition.inputValue,
                        "label": props.condition.inputValue
                    }}
                    isInvalid={props.errors?.inputValue}
                />{
                    props.errors?.inputValue && <div className={"error-message"}>
                        <FormattedMessage id={"Validation.selectOneOption"}/>
                    </div>
                }</>
                break;
            case "multiplechoice":
                result = <><Select
                    className={"react-select primary " + (props.errors?.inputValue ? "has-errors" : "")}
                    classNamePrefix="react-select"
                    onChange={handleValueChange}
                    options={state.valueOptions}
                    value={{
                        "value": props.condition.inputValue,
                        "label": props.condition.inputValue
                    }}
                    isInvalid={props.errors?.inputValue}
                />{
                    props.errors?.inputValue && <div className={"error-message"}>
                        <FormattedMessage id={"Validation.selectAtLeastOneOption"}/>
                    </div>
                }</>
                break;
            case "record":
                return <><AsyncSelect
                    className={"react-select primary " + (props.errors?.inputValue ? "has-errors" : "")}
                    classNamePrefix="react-select"
                    placeholder={<FormattedMessage id={"Search"}/>}
                    noOptionsMessage={({inputValue}) => !inputValue ?
                        <FormattedMessage id={"SearchInRecords"} /> :
                        <FormattedMessage id={"Placeholder.noResults"}/>}
                    onChange={event => {
                        let newErrors = {...props.errors};
                        if (!!props.errors["inputValue"]) {
                            newErrors = {
                                ...newErrors,
                                "inputValue": false,
                            };
                        }
                        props.changeCondition({
                            ...props.condition,
                            "inputValue": event.value,
                            "inputNameValue": event.label
                        }, props.index, newErrors)
                    }
                    }
                    value={props.condition?.inputValue && !!!props.condition?.inputNameValue ?
                        props.recordFields.length && props.condition?.operatorValue?.value !== "IsEmpty" && findRecordById(props.condition.inputValue, props.recordFields.find(recField => recField.id === props.condition.operand.value)?.rtid) :
                        props.condition?.inputValue ? {
                            "value": props.condition.inputValue,
                            "label": props.condition.inputNameValue
                        } : false}
                    loadOptions={(inputValue) => promiseOptions(inputValue, props.recordFields.find(recField => recField.id === props.condition.operand.value)?.rtid)}
                    isInvalid={props.errors?.inputValue}
                />{
                    props.errors?.inputValue && <div className={"error-message"}>
                        <FormattedMessage id={"Validation.selectOneOption"}/>
                    </div>
                }</>
            case "recordlist":
                return <><AsyncSelect
                    className={"react-select primary " + (props.errors?.inputValue ? "has-errors" : "")}
                    classNamePrefix="react-select"
                    placeholder={<FormattedMessage id={"Search"}/>}
                    noOptionsMessage={({inputValue}) => !inputValue ?
                        <FormattedMessage id={"SearchInRecords"} /> :
                        <FormattedMessage id={"Placeholder.noResults"}/>}
                    onChange={event => {
                        let newErrors = {...props.errors};
                        if (!!props.errors["inputValue"]) {
                            newErrors = {
                                ...newErrors,
                                "inputValue": false,
                            };
                        }
                        props.changeCondition({
                            ...props.condition,
                            "inputValue": event.value,
                            "inputNameValue": event.label
                        }, props.index, newErrors)
                    }
                    }
                    value={props.condition?.inputValue && !!!props.condition?.inputNameValue ?
                        props.recordFields.length && props.condition?.operatorValue?.value !== "IsEmpty" && findRecordById(props.condition.inputValue, props.recordFields.find(recField => recField.id === props.condition.operand.value)?.rtid) :
                        props.condition?.inputValue ? {
                            "value": props.condition.inputValue,
                            "label": props.condition.inputNameValue
                        } : false}
                    loadOptions={(inputValue) => promiseOptions(inputValue, props.recordFields.find(recField => recField.id === props.condition.operand.value)?.rtid)}
                    isInvalid={props.errors?.inputValue}
                />{
                    props.errors?.inputValue && <div className={"error-message"}>
                        <FormattedMessage id={"Validation.selectAtLeastOneOption"}/>
                    </div>
                }</>
            default:
                result = null;
        }

        return result;
    }

    const promiseOptions = (inputValue, rtid) =>
        new Promise(resolve => {
            resolve(getAllRecordsWithNameContains(inputValue, rtid));
        });

    const getAllRecordsWithNameContains = async (nameContains, recordTypeId) => {
        return getAllRecordsWithNameContainsHandler(nameContains, recordTypeId).then(response => {
            if (response.ok) {
                return response.data.records;
            }
        });
    }

    const findRecordById = async (recordId, rtid) => {
        findRecordByIdHandler(recordId, rtid).then(response => {
            if (response.ok) {
                props.changeCondition({
                    ...props.condition,
                    "inputNameValue": response.data.name,
                }, props.index)
            }
        });
    }
    return (
        <div className="condition-editor">
            <Row>
                <Col className="type-col">
                    <Form.Group>
                        <FormattedMessage id="Condition">
                            {placeholder =>
                                <Select
                                    className={"react-select primary condition-select " + (props.errors?.selectedCondition ? "has-errors" : "")}
                                    classNamePrefix="react-select"
                                    name="type"
                                    value={props.condition?.selectedCondition ? props.condition?.selectedCondition : null}
                                    options={props.conditionOptions}
                                    placeholder={placeholder}
                                    onChange={handleConditionChange}
                                    isInvalid={props.errors?.selectedCondition}
                                />
                            }
                        </FormattedMessage>
                        {
                            props.errors?.selectedCondition && <div className={"error-message"}>
                                <FormattedMessage id={"Validation.selectOneOption"}/>
                            </div>
                        }
                    </Form.Group>
                </Col>
                {props.condition?.selectedCondition?.value === "IsChanged" && <Col className="type-col">
                    <Form.Group>
                        <FormattedMessage id="Field" >
                            {placeholder =>
                                <Select
                                    className={"react-select primary " + (props.errors?.operand ? "has-errors" : "")}
                                    classNamePrefix="react-select"
                                    name="type"
                                    options={state.fieldOptions}
                                    value={props.condition?.operand ? props.condition?.operand : null}
                                    onChange={handleFieldChange}
                                    placeholder={placeholder}
                                    isInvalid={props.errors?.operand}
                                />
                            }
                        </FormattedMessage>
                        {
                            props.errors?.operand && <div className={"error-message"}>
                                <FormattedMessage id={"Validation.selectOneOption"}/>
                            </div>
                        }
                    </Form.Group>
                </Col>}
                {props.condition?.selectedCondition?.value === "IsChanged" && props.condition?.operand &&
                    <Col className="operator-col">
                        <Form.Group>
                            <FormattedMessage id="Operator" >
                                {placeholder =>
                                    <Select
                                        className={"react-select primary text-warning " + (props.errors?.operatorValue ? "has-errors" : "")}
                                        classNamePrefix="react-select"
                                        name="type"
                                        options={state.operatorOptions}
                                        value={state.operator}
                                        onChange={handleOperatorChange}
                                        placeholder={placeholder}
                                        isInvalid={props.errors?.operatorValue}
                                    />
                                }
                            </FormattedMessage>
                            {
                                props.errors?.operatorValue && <div className={"error-message"}>
                                    <FormattedMessage id={"Validation.selectOneOption"}/>
                                </div>
                            }
                        </Form.Group>
                    </Col>}
                {props.condition?.selectedCondition?.value === "IsChanged" && state.operator && state.operator?.value !== "IsEmpty" &&
                    <Col className="operator-col">
                        <Form.Group>
                            <FormattedMessage id="Operator" >
                                {placeholder =>
                                    <Select
                                        className={"react-select primary " + (props.errors?.operator ? "has-errors" : "")}
                                        classNamePrefix="react-select"
                                        name="valueCol"
                                        options={state.valueFromOptions}
                                        onChange={handleValueFromChange}
                                        value={state.valueFrom}
                                        placeholder={placeholder}
                                        isInvalid={props.errors?.operator}
                                    />
                                }
                            </FormattedMessage>
                            {
                                props.errors?.operator && <div className={"error-message"}>
                                    <FormattedMessage id={"Validation.selectOneOption"}/>
                                </div>
                            }
                        </Form.Group>
                    </Col>}
                {props.condition?.selectedCondition?.value === "IsChanged" && state.valueFrom && state.operator?.value !== "IsEmpty" &&
                    <Col className={state.type === "date" && props.condition.value !== "currentDate" ? "value-col bigger" : "value-col"}>
                        <Form.Group>
                            {getValueInputField()}
                        </Form.Group>
                    </Col>}

                {props.noConditions > 1 &&
                    <Col className="buttons-col rule-editor-actions">
                        <span onClick={() => props.deleteCondition(props.index)} className="icon-delete">{iconX}</span>
                    </Col>
                }
            </Row>
        </div>
    )

}

const operators = {
    "text": [
        {"label": <FormattedMessage id="Operators.is" />, "value": "Eq"},
        {"label": <FormattedMessage id="Operators.isNot" />, "value": "Ne"},
        {"label": <FormattedMessage id="Operators.contains" />, "value": "Contains"},
        {"label": <FormattedMessage id="Operators.notContains" />, "value": "NotContains"},
        {"label": <FormattedMessage id="Operators.isEmpty" />, "value": "IsEmpty", "isEmpty": true},
        {"label": <FormattedMessage id="Operators.isNotEmpty" />, "value": "IsEmpty", "isEmpty": false}
    ],
    "integer": [
        {"label": <FormattedMessage id="Operators.is" />, "value": "Eq"},
        {"label": <FormattedMessage id="Operators.isNot" />, "value": "Ne"},
        {"label": <FormattedMessage id="Operators.gt" />, "value": "Gt"},
        {"label": <FormattedMessage id="Operators.gte" />, "value": "Gte"},
        {"label": <FormattedMessage id="Operators.lt" />, "value": "Lt"},
        {"label": <FormattedMessage id="Operators.lte" />, "value": "Lte"},
        {"label": <FormattedMessage id="Operators.isEmpty" />, "value": "IsEmpty", "isEmpty": true},
        {"label": <FormattedMessage id="Operators.isNotEmpty" />, "value": "IsEmpty", "isEmpty": false}
    ],
    "decimal": [
        {"label": <FormattedMessage id="Operators.is" />, "value": "Eq"},
        {"label": <FormattedMessage id="Operators.isNot" />, "value": "Ne"},
        {"label": <FormattedMessage id="Operators.gt" />, "value": "Gt"},
        {"label": <FormattedMessage id="Operators.gte" />, "value": "Gte"},
        {"label": <FormattedMessage id="Operators.lt" />, "value": "Lt"},
        {"label": <FormattedMessage id="Operators.lte" />, "value": "Lte"},
        {"label": <FormattedMessage id="Operators.isEmpty" />, "value": "IsEmpty", "isEmpty": true},
        {"label": <FormattedMessage id="Operators.isNotEmpty" />, "value": "IsEmpty", "isEmpty": false}
    ],
    "boolean": [
        {"label": <FormattedMessage id="Operators.is" />, "value": "Eq"},
    ],
    "date": [
        {"label": <FormattedMessage id="Operators.is" />, "value": "Eq"},
        {"label": <FormattedMessage id="Operators.isNot" />, "value": "Ne"},
        {"label": <FormattedMessage id="Operators.after" />, "value": "Gt"},
        {"label": <FormattedMessage id="Operators.afterOrSame" />, "value": "Gte"},
        {"label": <FormattedMessage id="Operators.before" />, "value": "Lt"},
        {"label": <FormattedMessage id="Operators.beforeOrSame" />, "value": "Lte"},
        {"label": <FormattedMessage id="Operators.isEmpty" />, "value": "IsEmpty", "isEmpty": true},
        {"label": <FormattedMessage id="Operators.isNotEmpty" />, "value": "IsEmpty", "isEmpty": false}
    ],
    "currency": [
        {"label": <FormattedMessage id="Operators.is" />, "value": "Eq"},
        {"label": <FormattedMessage id="Operators.isNot" />, "value": "Ne"},
        {"label": <FormattedMessage id="Operators.gt" />, "value": "Gt"},
        {"label": <FormattedMessage id="Operators.gte" />, "value": "Gte"},
        {"label": <FormattedMessage id="Operators.lt" />, "value": "Lt"},
        {"label": <FormattedMessage id="Operators.lte" />, "value": "Lte"},
        {"label": <FormattedMessage id="Operators.isEmpty" />, "value": "IsEmpty", "isEmpty": true},
        {"label": <FormattedMessage id="Operators.isNotEmpty" />, "value": "IsEmpty", "isEmpty": false}
    ],
    "record": [
        {"label": <FormattedMessage id="Operators.is" />, "value": "Eq"},
        {"label": <FormattedMessage id="Operators.isNot" />, "value": "Ne"},
        {"label": <FormattedMessage id="Operators.isEmpty" />, "value": "IsEmpty", "isEmpty": true},
        {"label": <FormattedMessage id="Operators.isNotEmpty" />, "value": "IsEmpty", "isEmpty": false}
    ],
    "recordlist": [
        {"label": <FormattedMessage id="Operators.contains" />, "value": "Eq"},
        {"label": <FormattedMessage id="Operators.notContains" />, "value": "Ne"},
        {"label": <FormattedMessage id="Operators.isEmpty" />, "value": "IsEmpty", "isEmpty": true},
        {"label": <FormattedMessage id="Operators.isNotEmpty" />, "value": "IsEmpty", "isEmpty": false}
    ],
    "singlechoice": [
        {"label": <FormattedMessage id="Operators.is" />, "value": "Eq"},
        {"label": <FormattedMessage id="Operators.isNot" />, "value": "Ne"},
        {"label": <FormattedMessage id="Operators.isEmpty" />, "value": "IsEmpty", "isEmpty": true},
        {"label": <FormattedMessage id="Operators.isNotEmpty" />, "value": "IsEmpty", "isEmpty": false}
    ],
    "multiplechoice": [
        {"label": <FormattedMessage id="Operators.contains" />, "value": "Eq"},
        {"label": <FormattedMessage id="Operators.notContains" />, "value": "Ne"},
        {"label": <FormattedMessage id="Operators.isEmpty" />, "value": "IsEmpty", "isEmpty": true},
        {"label": <FormattedMessage id="Operators.isNotEmpty" />, "value": "IsEmpty", "isEmpty": false}
    ]
};
const textOperators = {
    "Eq": [{"label": <FormattedMessage id="Operators.value" />, "value": "EqString"}],
    "Ne": [{"label": <FormattedMessage id="Operators.value" />, "value": "NeString"}],
    "Contains": [{"label": <FormattedMessage id="Operators.value" />, "value": "Contains"}],
    "NotContains": [{"label": <FormattedMessage id="Operators.value" />, "value": "NotContains"}],
    "IsEmpty": [{"label": <FormattedMessage id="Operators.value" />, "value": "IsEmptyString"}]
};

const integerOperators = {
    "Eq": [{"label": <FormattedMessage id="Operators.value" />, "value": "EqLong"}],
    "Ne": [{"label": <FormattedMessage id="Operators.value" />, "value": "NeLong"}],
    "Gt": [{"label": <FormattedMessage id="Operators.value" />, "value": "GtLong"}],
    "Gte": [{"label": <FormattedMessage id="Operators.value" />, "value": "GteLong"}],
    "Lt": [{"label": <FormattedMessage id="Operators.value" />, "value": "LtLong"}],
    "Lte": [{"label": <FormattedMessage id="Operators.value" />, "value": "LteLong"}],
    "IsEmpty": [{"label": <FormattedMessage id="Operators.value" />, "value": "IsEmptyLong"}]
};

const decimalOperators = {
    "Eq": [{"label": <FormattedMessage id="Operators.value" />, "value": "EqDouble"}],
    "Ne": [{"label": <FormattedMessage id="Operators.value" />, "value": "NeDouble"}],
    "Gt": [{"label": <FormattedMessage id="Operators.value" />, "value": "GtDouble"}],
    "Gte": [{"label": <FormattedMessage id="Operators.value" />, "value": "GteDouble"}],
    "Lt": [{"label": <FormattedMessage id="Operators.value" />, "value": "LtDouble"}],
    "Lte": [{"label": <FormattedMessage id="Operators.value" />, "value": "LteDouble"}],
    "IsEmpty": [{"label": <FormattedMessage id="Operators.value" />, "value": "IsEmptyDouble"}]
};

const booleanOperators = {
    "Eq": [{"label": <FormattedMessage id="Operators.value" />, "value": "EqBoolean"}],
};

const dateOperators = {
    "Eq": [{"label": <FormattedMessage id="Operators.value" />, "value": "EqDate"}],
    "Ne": [{"label": <FormattedMessage id="Operators.value" />, "value": "NeDate"}],
    "Gt": [{"label": <FormattedMessage id="Operators.value" />, "value": "GtDate"}],
    "Gte": [{"label": <FormattedMessage id="Operators.value" />, "value": "GteDate"}],
    "Lt": [{"label": <FormattedMessage id="Operators.value" />, "value": "LtDate"}],
    "Lte": [{"label": <FormattedMessage id="Operators.value" />, "value": "LteDate"}],
    "IsEmpty": [{"label": <FormattedMessage id="Operators.value" />, "value": "IsEmptyDate"}]
};

const recordOperators = {
    "Eq": [{"label": <FormattedMessage id="Operators.value" />, "value": "EqRecord"}],
    "Ne": [{"label": <FormattedMessage id="Operators.value" />, "value": "NeRecord"}],
    "IsEmpty": [{"label": <FormattedMessage id="Operators.value" />, "value": "IsEmptyRecord"}]
};

const recordListOperators = {
    "Eq": [{"label": <FormattedMessage id="Operators.value" />, "value": "EqRecord"}],
    "Ne": [{"label": <FormattedMessage id="Operators.value" />, "value": "NeRecord"}],
    "IsEmpty": [{"label": <FormattedMessage id="Operators.value" />, "value": "IsEmptyList"}]
};

const singleOperators = {
    "Eq": [{"label": <FormattedMessage id="Operators.value" />, "value": "EqString"}],
    "Ne": [{"label": <FormattedMessage id="Operators.value" />, "value": "NeString"}],
    "IsEmpty": [{"label": <FormattedMessage id="Operators.value" />, "value": "IsEmptyString"}]
};

const multiOperators = {
    "Eq": [{"label": <FormattedMessage id="Operators.value" />, "value": "Contains"}],
    "Ne": [{"label": <FormattedMessage id="Operators.value" />, "value": "NotContains"}],
    "IsEmpty": [{"label": <FormattedMessage id="Operators.value" />, "value": "IsEmptyList"}]
};


export default ConditionEditor;
