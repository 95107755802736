import * as Validations from "validations/validations.js"
import {VALIDATIONS} from "../../../util/Constants";

const addRoleValidations = (role, errors) => {
    if (role.name.length > VALIDATIONS.MAX_LENGTH_DEFAULT || role.name.length < VALIDATIONS.MIN_LENGTH_DEFAULT) {
        errors = {
            ...errors,
            "name": true,
        }
    }
    if (role.description.length < VALIDATIONS.MIN_LENGTH_DEFAULT) {
        errors = {
            ...errors,
            "description": true,
        }
    }
    if(role.menuName.length<1){
        errors = {
            ...errors,
            "menuName": true,
        }
    }

    const existErrors = Validations.checkIfError(errors);

    return [errors, existErrors];
}

export {
    addRoleValidations
}
