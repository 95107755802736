import React, {useEffect, useRef, useState} from "react";
import {Button, Container, Form, Row} from "react-bootstrap";
import Select from "react-select";
import {DragDropContext} from "react-beautiful-dnd";
import Column from "./Column";
import FormBuilderSettings from "./FormBuilderSettings";
import FormBuilderPreview from "./FormBuilderPreview";
import * as Validations from "validations/form/FormValidations.js";
import SweetAlert from "react-bootstrap-sweetalert";
import {FormattedMessage} from "react-intl";
import SlidingPanel from "../../../components/SlidingPanel";
import {getDataForFormBuilderHandler} from "handlers/workspaceMemberHandlers/formHandlers/FormBuilderHandlers";
import DateTimePicker from "react-datetime-picker";
import {getNotifyErrorOptions} from "../../../util/Util";
import NotificationAlert from "react-notification-alert";
import {useIntl} from 'react-intl'
import {
    FORM_FIELD_SINGLE_CHOICE_DISPLAY_OPTION,
    FORM_FIELD_TEXT_DISPLAY_OPTION,
    FORM_FIELD_TYPE,
    SIDEBAR_STYLE
} from "../../../util/Constants";

function FormBuilder(props) {

    const intl = useIntl()

    const getDefaultFieldName = (type,displayOption) => {
        if (type === FORM_FIELD_TYPE.TEXT && displayOption === FORM_FIELD_TEXT_DISPLAY_OPTION.SINGLE_LINE) {
            return intl.formatMessage({id: "Form.text"});
        } else if (type === FORM_FIELD_TYPE.TEXT && displayOption === FORM_FIELD_TEXT_DISPLAY_OPTION.TEXT_AREA) {
            return intl.formatMessage({id: "Form.textArea"});
        } else if (type === FORM_FIELD_TYPE.TEXT && displayOption === FORM_FIELD_TEXT_DISPLAY_OPTION.EMAIL) {
            return intl.formatMessage({id: "Form.email"});
        } else if (type === FORM_FIELD_TYPE.DATE) {
            return intl.formatMessage({id: "Form.date"});
        } else if (type === FORM_FIELD_TYPE.TEXT && displayOption === FORM_FIELD_TEXT_DISPLAY_OPTION.PHONE) {
            return intl.formatMessage({id: "Form.phoneNumber"});
        } else if (type === FORM_FIELD_TYPE.SINGLE_CHOICE && displayOption === FORM_FIELD_SINGLE_CHOICE_DISPLAY_OPTION.DROPDOWN) {
            return intl.formatMessage({id: "Form.dropdownList"});
        } else if (type === FORM_FIELD_TYPE.SINGLE_CHOICE && displayOption === FORM_FIELD_SINGLE_CHOICE_DISPLAY_OPTION.RADIO_BUTTON_GROUP) {
            return intl.formatMessage({id: "Form.singleChoice"});
        } else if (type === FORM_FIELD_TYPE.MULTIPLE_CHOICE) {
            return intl.formatMessage({id: "Form.multipleChoice"});
        } else if (type === FORM_FIELD_TYPE.GROUP_HEADER) {
            return intl.formatMessage({id: "Form.output"});
        } else if (type === FORM_FIELD_TYPE.PAYMENT) {
            return intl.formatMessage({id: "Form.payment"});
        }
    }

    const [state, setState] = useState({
        alert: null,
        errors: {
            "name": false,
            "description": false,
            "startDate": false,
            "endDate": false
        },
        currency: {},
        merchantId: null,
        secretApiKey: null,
        formSettings: {
            name: intl.formatMessage({id: "Form.formTitle"}),
            description: "",
            startDate: "",
            endDate: "",
            maxNrOfReg: "",
            theme: "",
            captcha: false,
            payment: false,
            sendEmailAfterSubmission: false,
            onlyLoggedUser: false
        },
        initFormSettings: {},
        isEditorVisible: true,
        isSettingsPageVisible: false,
        isPreviewVisible: false,
        isPhoneViewVisible: false,
        isDesktopViewVisible: false,
        formTitleIsEditable: false,
        currentStyle: SIDEBAR_STYLE.CLOSED,
        selectedFieldType: null,
        selectedId: null,
        selectedDraggableId: null,
        initFormFields: [],
        leftAndCenterColFields: [
            {
                groupName: "All Fields",
                formFields: [{
                    draggableId: "Def-1",
                    type: FORM_FIELD_TYPE.TEXT,
                    displayOption: FORM_FIELD_TEXT_DISPLAY_OPTION.SINGLE_LINE,
                    name: getDefaultFieldName(FORM_FIELD_TYPE.TEXT,FORM_FIELD_TEXT_DISPLAY_OPTION.SINGLE_LINE),
                },
                    {
                        draggableId: "Def-2",
                        type: FORM_FIELD_TYPE.TEXT,
                        displayOption: FORM_FIELD_TEXT_DISPLAY_OPTION.TEXT_AREA,
                        name: getDefaultFieldName(FORM_FIELD_TYPE.TEXT,FORM_FIELD_TEXT_DISPLAY_OPTION.TEXT_AREA),
                    },
                    {
                        draggableId: "Def-3",
                        type: FORM_FIELD_TYPE.TEXT,
                        displayOption: FORM_FIELD_TEXT_DISPLAY_OPTION.EMAIL,
                        name: getDefaultFieldName(FORM_FIELD_TYPE.TEXT,FORM_FIELD_TEXT_DISPLAY_OPTION.EMAIL),
                    },
                    {
                        draggableId: "Def-4",
                        type: FORM_FIELD_TYPE.DATE,
                        name: getDefaultFieldName(FORM_FIELD_TYPE.DATE)
                    },
                    {
                        draggableId: "Def-5",
                        type: FORM_FIELD_TYPE.TEXT,
                        displayOption: FORM_FIELD_TEXT_DISPLAY_OPTION.PHONE,
                        name: getDefaultFieldName(FORM_FIELD_TYPE.TEXT,FORM_FIELD_TEXT_DISPLAY_OPTION.PHONE)
                    },
                    {
                        draggableId: "Def-6",
                        type: FORM_FIELD_TYPE.SINGLE_CHOICE,
                        displayOption: FORM_FIELD_SINGLE_CHOICE_DISPLAY_OPTION.DROPDOWN,
                        options: [intl.formatMessage({id: "Form.optionParam"}, {param: 1}), intl.formatMessage({id: "Form.optionParam"}, {param: 2}), intl.formatMessage({id: "Form.optionParam"}, {param: 3})],
                        name: getDefaultFieldName(FORM_FIELD_TYPE.SINGLE_CHOICE,FORM_FIELD_SINGLE_CHOICE_DISPLAY_OPTION.DROPDOWN),
                    },
                    {
                        draggableId: "Def-7",
                        type: FORM_FIELD_TYPE.SINGLE_CHOICE,
                        displayOption: FORM_FIELD_SINGLE_CHOICE_DISPLAY_OPTION.RADIO_BUTTON_GROUP,
                        options: [intl.formatMessage({id: "Form.optionParam"}, {param: 1}), intl.formatMessage({id: "Form.optionParam"}, {param: 2}), intl.formatMessage({id: "Form.optionParam"}, {param: 3})],
                        name: getDefaultFieldName(FORM_FIELD_TYPE.SINGLE_CHOICE,FORM_FIELD_SINGLE_CHOICE_DISPLAY_OPTION.RADIO_BUTTON_GROUP),
                    },
                    {
                        draggableId: "Def-8",
                        type: FORM_FIELD_TYPE.MULTIPLE_CHOICE,
                        options: [intl.formatMessage({id: "Form.optionParam"}, {param: 1}), intl.formatMessage({id: "Form.optionParam"}, {param: 2}), intl.formatMessage({id: "Form.optionParam"}, {param: 3})],
                        name: getDefaultFieldName(FORM_FIELD_TYPE.MULTIPLE_CHOICE),
                    },
                    {
                        draggableId: "Def-9",
                        type: FORM_FIELD_TYPE.GROUP_HEADER,
                        name: getDefaultFieldName(FORM_FIELD_TYPE.GROUP_HEADER)
                    }]
            },
            {
                groupName: "Selected Fields",
                formFields: []
            }],

        defaultDateSwitch: false,
        timeFieldSwitch: false,
        requiredFieldSwitch: false,
        characterLimitSwitch: false,
        confirmEmailSwitch: false,
        enableOtherFieldSwitch: false,
        preSelectedOptionSwitch: false,
        otherPayments: false,
        hideSwitch:false,


        fieldName: "",
        fieldDescription: "",
        placeholderText: "",
        defaultDate: "",
        characterLimit: "",
        options: [],
        paymentOptions: [],
        preSelectedOption: "",
        otherPaymentsLabel: ""
    });

    useEffect(() => {
        getPageContent();
    }, []);

    const getPageContent = () => {
        getDataForFormBuilderHandler(props.wid, props.formId, state.leftAndCenterColFields).then(response => {
            if (response.ok) {
                let newState = {...state}
                if (props.showOnlyPreview) {
                    newState = {
                        ...newState,
                        isPreviewVisible: true,
                        isPhoneViewVisible: true
                    }
                }

                //if the form has payment enabled and payment settings are not completed, payment will be disabled
                if (!props.showOnlyPreview && (!response.data.currency?.digits || !response.data.merchantId || !response.data.secretApiKey) && response.data.formSettings?.payment) {
                    response.data.leftAndCenterColFields[1].formFields = response.data.leftAndCenterColFields[1].formFields.filter(formField => formField.type !== FORM_FIELD_TYPE.PAYMENT)
                    response.data.initFormFields = response.data.initFormFields.filter(formField => formField.type !== FORM_FIELD_TYPE.PAYMENT)
                    response.data = {
                        ...response.data,
                        formSettings: {
                            ...response.data.formSettings,
                        },
                        initFormSettings: response.data.initFormSettings,
                        initFormFields: response.data.initFormFields,
                    }
                }
                setState({
                    ...newState,
                    ...response.data
                });
            }
        });
    }

    const handleOnChangeDate = (value, input) => {
        if (input === "defaultDate") {
            let newErrors = {...state.errors};
            if (newErrors["defaultDateField"]) {
                newErrors["defaultDateField"] = false;
            }
            setState({
                ...state,
                defaultDate: value ? new Date(value) : "",
                errors: newErrors
            });
        }
    }

    const handleOnChange = (e, input) => {
        if (input === "formTitle") {
            e.preventDefault();
            setState({
                ...state,
                formSettings: {
                    ...state.formSettings,
                    "name": e.target.value
                }
            });
        } else if (input === "defaultDateSwitch") {
            let newErrors = {...state.errors};
            if (newErrors["defaultDateField"]) {
                newErrors["defaultDateField"] = false;
            }
            setState({
                ...state,
                defaultDateSwitch: !state.defaultDateSwitch,
                errors: newErrors
            });
        } else if (input === "timeFieldSwitch") {
            setState({
                ...state,
                timeFieldSwitch: !state.timeFieldSwitch
            });
        } else if (input === "requiredFieldSwitch") {
            setState({
                ...state,
                requiredFieldSwitch: !state.requiredFieldSwitch
            });
        } else if (input === "characterLimitSwitch") {
            let newErrors = {...state.errors};
            if (newErrors["characterLimitField"]) {
                newErrors["characterLimitField"] = false;
            }
            setState({
                ...state,
                characterLimitSwitch: !state.characterLimitSwitch,
                errors: newErrors
            });
        } else if (input === "confirmEmailSwitch") {
            setState({
                ...state,
                confirmEmailSwitch: !state.confirmEmailSwitch
            });
        } else if (input === "enableOtherFieldSwitch") {
            setState({
                ...state,
                enableOtherFieldSwitch: !state.enableOtherFieldSwitch
            });
        } else if (input === "hideSwitch") {
            setState({
                ...state,
                hideSwitch: !state.hideSwitch
            });
        } else if (input === "preSelectedOptionSwitch") {
            let newErrors = {...state.errors};
            if (newErrors["preSelectedOptionField"]) {
                newErrors["preSelectedOptionField"] = false;
            }
            setState({
                ...state,
                preSelectedOptionSwitch: !state.preSelectedOptionSwitch,
                errors: newErrors,
            });
        } else if (input === "fieldName") {
            setState({
                ...state,
                fieldName: e.target.value
            });
        } else if (input === "fieldDescription") {
            setState({
                ...state,
                fieldDescription: e.target.value
            });
        } else if (input === "placeholderText") {
            setState({
                ...state,
                placeholderText: e.target.value
            });
        } else if (input === "characterLimit") {
            let newErrors = {...state.errors};
            if (newErrors["characterLimitField"]) {
                newErrors["characterLimitField"] = false;
            }
            setState({
                ...state,
                characterLimit: e.target.value,
                errors: newErrors
            });
        } else if (input === "preSelectedOption") {
            let newErrors = {...state.errors};
            if (newErrors["preSelectedOptionField"]) {
                newErrors["preSelectedOptionField"] = false;
            }
            setState({
                ...state,
                preSelectedOption: e.value,
                errors: newErrors
            });
        } else if (input === "otherPayments") {
            setState({
                ...state,
                otherPayments: !state.otherPayments
            });
        } else if (input === "otherPaymentsLabel") {
            let newErrors = {...state.errors};
            if (newErrors["otherPaymentsField"]) {
                newErrors["otherPaymentsField"] = false;
            }
            setState({
                ...state,
                otherPaymentsLabel: e.target.value,
                errors: newErrors
            });
        }
    }

    function handleFormFieldDelete(e, props) {
        e.stopPropagation();
        setState({
            ...state,
            alert: <SweetAlert
                type="danger"
                title=""
                style={{display: "block", marginTop: "-100px", fontSize: "13px"}}
                btnSize="md"
                confirmBtnBsStyle="danger"
                cancelBtnBsStyle="default"
                showCancel
                cancelBtnText={<FormattedMessage id="Button.cancel"/>}
                confirmBtnText={<FormattedMessage id="Button.delete"/>}
                openAnim="false"
                onConfirm={() => deleteFormField(props)}
                onCancel={() => hideAlert()}
                allowEscape
                closeOnClickOutside
                customClass="crossa-alert"

            > <FormattedMessage id="Alert.deleteItem" values={{param: <FormattedMessage id="Field"/>}}/>
            </SweetAlert>
        });
    }

    function handleFormFieldClick(props) {

        setState({
            ...state,
            isEditorVisible: true,
            isSettingsPageVisible: false,
            currentStyle: SIDEBAR_STYLE.OPEN,
            selectedId: props.id,
            selectedFieldType: ((props.type === FORM_FIELD_TYPE.TEXT && props.displayOption !== FORM_FIELD_TEXT_DISPLAY_OPTION.SINGLE_LINE) ||
                (props.type === FORM_FIELD_TYPE.SINGLE_CHOICE && props.displayOption !== FORM_FIELD_SINGLE_CHOICE_DISPLAY_OPTION.RADIO_BUTTON_GROUP)) ? props.displayOption : props.type,
            selectedDraggableId: props.draggableId,
            defaultDateSwitch: !!props.defaultDate,
            timeFieldSwitch: props.timeField ? props.timeField : false,
            requiredFieldSwitch: props.validation?.mandatory ? props.validation.mandatory : false,
            characterLimitSwitch: props.validation?.characterLimit ? props.validation.characterLimit : false,
            characterLimit: props.validation?.limit ? props.validation.limit : "",
            confirmEmailSwitch: props.validation?.confirmEmail ? props.validation.confirmEmail : false,
            enableOtherFieldSwitch: props.enableOtherField ? props.enableOtherField : false,
            preSelectedOptionSwitch: !!props.defaultOption,
            hideSwitch: props.hide ? props.hide : false,
            fieldName: props.name ? props.name : "",
            fieldDescription: props.description ? props.description : "",
            placeholderText: props.placeholder ? props.placeholder : "",
            defaultDate: props.defaultDate ? props.defaultDate : "",
            options: props.options ? props.options : [],
            preSelectedOption: props.defaultOption ? props.defaultOption : "",
            otherPayments: props.otherPayments ? props.otherPayments : false,
            otherPaymentsLabel: props.otherPaymentsLabel ? props.otherPaymentsLabel : "",
            paymentOptions: props.paymentOptions ? props.paymentOptions : [],
            errors: {
                ...state.errors,
                options: {},
                characterLimitField: false,
                defaultDateField: false,
                preSelectedOptionField: false,
                otherPaymentsField: false,
                paymentOptions: false
            }
        });
    }

    const deleteFormField = (props) => {
        let formFields = state.leftAndCenterColFields[1].formFields.filter(formField => formField.draggableId !== props.draggableId);
        let newLeftAndCenterColFields = state.leftAndCenterColFields;
        newLeftAndCenterColFields[1].formFields = formFields;
        setState({
            ...state,
            currentStyle: SIDEBAR_STYLE.CLOSED,
            leftAndCenterColFields: newLeftAndCenterColFields,
            alert: null,
        });
    }

    const hideAlert = () => {
        setState({
            ...state,
            alert: null
        });
    }

    const saveFormField = () => {
        let formField = {
            draggableId: state.selectedDraggableId,
            id: state.selectedId,
            type: state.selectedFieldType === FORM_FIELD_TEXT_DISPLAY_OPTION.TEXT_AREA ||
            state.selectedFieldType === FORM_FIELD_TEXT_DISPLAY_OPTION.EMAIL || state.selectedFieldType === FORM_FIELD_TEXT_DISPLAY_OPTION.PHONE
                ? FORM_FIELD_TYPE.TEXT :
                (state.selectedFieldType === FORM_FIELD_SINGLE_CHOICE_DISPLAY_OPTION.DROPDOWN ? FORM_FIELD_TYPE.SINGLE_CHOICE : state.selectedFieldType),
            name: state.fieldName,
            description: state.fieldDescription,
            validation: {
                mandatory: state.requiredFieldSwitch
            },
            hide:state.hideSwitch,
            handleFormFieldDelete: handleFormFieldDelete
        };
        let formFieldValidations = {};
        let formFields = state.leftAndCenterColFields[1].formFields;
        let newLeftAndCenterColFields = state.leftAndCenterColFields;
        if (state.selectedFieldType === FORM_FIELD_TYPE.TEXT) {
            formField = {
                ...formField,
                placeholder: state.placeholderText,
                validation: {
                    ...formField.validation,
                    characterLimit: state.characterLimitSwitch,
                    limit: state.characterLimit
                },
                displayOption: FORM_FIELD_TEXT_DISPLAY_OPTION.SINGLE_LINE
            };
        } else if (state.selectedFieldType === FORM_FIELD_TEXT_DISPLAY_OPTION.TEXT_AREA) {
            formField = {
                ...formField,
                placeholder: state.placeholderText,
                validation: {
                    ...formField.validation,
                    characterLimit: state.characterLimitSwitch,
                    limit: state.characterLimit
                },
                displayOption: FORM_FIELD_TEXT_DISPLAY_OPTION.TEXT_AREA
            };
        } else if (state.selectedFieldType === FORM_FIELD_TEXT_DISPLAY_OPTION.EMAIL) {
            formField = {
                ...formField,
                placeholder: state.placeholderText,
                validation: {
                    ...formField.validation,
                    confirmEmail: state.confirmEmailSwitch
                },
                displayOption: FORM_FIELD_TEXT_DISPLAY_OPTION.EMAIL
            };
        } else if (state.selectedFieldType === FORM_FIELD_TYPE.DATE) {
            formField = {
                ...formField,
                defaultDate: state.defaultDateSwitch ? state.defaultDate : null,
                timeField: state.timeFieldSwitch
            };
            formFieldValidations = {
                ...formFieldValidations,
                defaultDateSwitch: state.defaultDateSwitch
            };
        } else if (state.selectedFieldType === FORM_FIELD_TEXT_DISPLAY_OPTION.PHONE) {
            formField = {
                ...formField,
                placeholder: state.placeholderText,
                displayOption: FORM_FIELD_TEXT_DISPLAY_OPTION.PHONE
            };
        } else if (state.selectedFieldType === FORM_FIELD_SINGLE_CHOICE_DISPLAY_OPTION.DROPDOWN) {
            formField = {
                ...formField,
                placeholder: state.placeholderText,
                options: state.options,
                defaultOption: state.preSelectedOptionSwitch ? state.preSelectedOption : "",
                displayOption: FORM_FIELD_SINGLE_CHOICE_DISPLAY_OPTION.DROPDOWN
            };
            formFieldValidations = {
                ...formFieldValidations,
                preSelectedOptionSwitch: state.preSelectedOptionSwitch
            }
        } else if (state.selectedFieldType === FORM_FIELD_TYPE.SINGLE_CHOICE) {
            formField = {
                ...formField,
                options: state.options,
                enableOtherField: state.enableOtherFieldSwitch,
                displayOption: FORM_FIELD_SINGLE_CHOICE_DISPLAY_OPTION.RADIO_BUTTON_GROUP
            };
        } else if (state.selectedFieldType === FORM_FIELD_TYPE.MULTIPLE_CHOICE) {
            formField = {
                ...formField,
                options: state.options,
                enableOtherField: state.enableOtherFieldSwitch
            };
        } else if (state.selectedFieldType === FORM_FIELD_TYPE.PAYMENT) {
            formField = {
                ...formField,
                otherPayments: state.otherPayments,
                otherPaymentsLabel: state.otherPaymentsLabel,
                paymentOptions: state.paymentOptions
            };
        }
        const [newErrors, existErrors] = Validations.saveFormFieldValidations(state.selectedFieldType, formField, formFieldValidations, {});

        if (existErrors) {
            let options = getNotifyErrorOptions("bc");
            notificationAlertRef.current.notificationAlert(options);
            setState({
                ...state,
                errors: newErrors
            });
            return;
        }

        let objIndex = formFields.findIndex((obj => obj.draggableId === state.selectedDraggableId));
        formFields[objIndex] = formField;
        newLeftAndCenterColFields[1].formFields = formFields;
        setState({
            ...state,
            currentStyle: SIDEBAR_STYLE.CLOSED,
            leftAndCenterColFields: newLeftAndCenterColFields,
            errors: newErrors
        });
    }

    const saveForm = () => {
        const [newErrors, existErrors] = Validations.saveFormValidations(state.formSettings, state.leftAndCenterColFields[1].formFields, {...state.errors});

        let isEditorVisible = state.isEditorVisible;
        let isSettingsPageVisible = state.isSettingsPageVisible;
        if (existErrors) {
            if (newErrors.paymentField) {
                isEditorVisible = true;
                isSettingsPageVisible = false;
            } else {
                isEditorVisible = false;
                isSettingsPageVisible = true;
            }
            setState({
                ...state,
                isEditorVisible: isEditorVisible,
                isSettingsPageVisible: isSettingsPageVisible,
                errors: newErrors,
            });
            return;
        }
        setState({
            ...state,
            errors: newErrors
        });
        props.saveFormBuilder(state.formSettings, state.leftAndCenterColFields[1].formFields);
    }

    function onDragEnd(val) {
        // Your version
        // let result = helper.reorder(val.source, val.destination, taskList);
        // setTasks(result);

        /// A different way!
        const {draggableId, source, destination} = val;
        if (!!!destination) {
            return;
        }

        const [sourceGroup] = state.leftAndCenterColFields.filter(
            column => column.groupName === source.droppableId
        );

        // Destination might be `null`: when a task is
        // dropped outside any drop area. In this case the
        // task reamins in the same column so `destination` is same as `source`
        const [destinationGroup] = destination
            ? state.leftAndCenterColFields.filter(column => column.groupName === destination.droppableId)
            : {...sourceGroup};

        // We save the task we are moving
        const [movingTask] = sourceGroup.formFields.filter(t => t.draggableId === draggableId);
        let newId = source.droppableId !== destination.droppableId ? "dest" + Date.now() : movingTask.draggableId
        const newTask = {
            ...movingTask,
            draggableId: newId,
            handleFormFieldDelete: handleFormFieldDelete
        };

        // const newSourceGroupTasks = sourceGroup.tasks.splice(source.index, 1);
        let newDestinationGroupTasks;
        if (source.droppableId !== destination.droppableId) {
            newDestinationGroupTasks = destinationGroup.formFields.splice(
                destination.index,
                0,
                newTask);
        } else if (source.droppableId === "Selected Fields") {
            newDestinationGroupTasks = destinationGroup.formFields;
            newDestinationGroupTasks.splice(source.index, 1);
            newDestinationGroupTasks.splice(
                destination.index,
                0,
                newTask);
        } else {
            newDestinationGroupTasks = destinationGroup.formFields;
        }

        // Mapping over the task lists means that you can easily
        // add new columns
        const newLeftAndCenterColFields = state.leftAndCenterColFields.map(column => {
            if (column.groupName === destination.groupName) {
                return {
                    groupName: column.groupName,
                    formFields: newDestinationGroupTasks
                };
            }
            return column;
        });
        setState({
            ...state,
            leftAndCenterColFields: newLeftAndCenterColFields
        });
    }

    const showEditor = () => {
        setState({
            ...state,
            isEditorVisible: true,
            isSettingsPageVisible: false
        });
    }
    const showSettings = () => {
        setState({
            ...state,
            isEditorVisible: false,
            isSettingsPageVisible: true
        });
    }

    const showPreview = () => {
        setState({
            ...state,
            isPreviewVisible: true,
            isPhoneViewVisible: true,
            isDesktopViewVisible: false
        });
    }

    const hidePreview = () => {
        setState({
            ...state,
            isPreviewVisible: false
        });
    }

    const showPhoneView = () => {
        setState({
            ...state,
            isPhoneViewVisible: true,
            isDesktopViewVisible: false
        });
    }
    const showDesktopView = () => {
        setState({
            ...state,
            isPhoneViewVisible: false,
            isDesktopViewVisible: true
        });
    }

    const toggleEditableInput = () => {
        setState({
            ...state,
            formTitleIsEditable: !state.formTitleIsEditable
        });
    }


    const getFieldPlaceholder = () => {
        switch (state.selectedFieldType) {
            case FORM_FIELD_TYPE.SINGLE_CHOICE:
                return <FormattedMessage id="Form.fieldParam"
                                         values={{param: <FormattedMessage id="Form.singleChoice"/>}}/>;
            case FORM_FIELD_TYPE.MULTIPLE_CHOICE:
                return <FormattedMessage id="Form.fieldParam"
                                         values={{param: <FormattedMessage id="Form.multipleChoice"/>}}/>;
            case FORM_FIELD_TYPE.GROUP_HEADER:
                return <FormattedMessage id="Form.fieldParam" values={{param: <FormattedMessage id="Form.output"/>}}/>;
            default:
                let fieldInt = state.selectedFieldType?<FormattedMessage
                    id={"Form." + (state.selectedFieldType.charAt(0).toLowerCase() + state.selectedFieldType?.slice(1))}/>:""
                return <FormattedMessage id="Form.fieldParam" values={{param: fieldInt}}/>;
        }
    }

    const changeFormSettings = (field, value) => {
        let formSettings = {
            ...state.formSettings,
            [field]: value
        };
        let newErrors = {...state.errors};

        if (!!state.errors[field]) {
            newErrors = {
                ...newErrors,
                [field]: false
            };
        }
        if (field === "payment") {
            if (value) {
                let newPaymentField = {
                    name:getDefaultFieldName(FORM_FIELD_TYPE.PAYMENT),
                    draggableId: "dest" + Date.now(),
                    id: "payment",
                    type: FORM_FIELD_TYPE.PAYMENT,
                };
                let formFields = state.leftAndCenterColFields[1].formFields;
                formFields.push(newPaymentField);
                let newLeftAndCenterColFields = state.leftAndCenterColFields;
                newLeftAndCenterColFields[1].formFields = formFields;
                setState({
                    ...state,
                    leftAndCenterColFields: newLeftAndCenterColFields,
                    errors: newErrors,
                    formSettings: formSettings
                });
            } else {
                let formFields = state.leftAndCenterColFields[1].formFields.filter(formField => formField.type !== FORM_FIELD_TYPE.PAYMENT);
                let newLeftAndCenterColFields = state.leftAndCenterColFields;
                newLeftAndCenterColFields[1].formFields = formFields;
                setState({
                    ...state,
                    leftAndCenterColFields: newLeftAndCenterColFields,
                    errors: newErrors,
                    formSettings: formSettings
                });
            }
        } else {
            setState({
                ...state,
                errors: newErrors,
                formSettings: formSettings
            });
        }
    }

    const previewIcon = <svg className="preview-icon" width="22" height="16" viewBox="0 0 22 16" fill="none"
                             xmlns="http://www.w3.org/2000/svg">
        <path
            d="M11 5C10.2044 5 9.44129 5.31607 8.87868 5.87868C8.31607 6.44129 8 7.20435 8 8C8 8.79565 8.31607 9.55871 8.87868 10.1213C9.44129 10.6839 10.2044 11 11 11C11.7957 11 12.5587 10.6839 13.1213 10.1213C13.6839 9.55871 14 8.79565 14 8C14 7.20435 13.6839 6.44129 13.1213 5.87868C12.5587 5.31607 11.7957 5 11 5ZM11 13C9.67392 13 8.40215 12.4732 7.46447 11.5355C6.52678 10.5979 6 9.32608 6 8C6 6.67392 6.52678 5.40215 7.46447 4.46447C8.40215 3.52678 9.67392 3 11 3C12.3261 3 13.5979 3.52678 14.5355 4.46447C15.4732 5.40215 16 6.67392 16 8C16 9.32608 15.4732 10.5979 14.5355 11.5355C13.5979 12.4732 12.3261 13 11 13ZM11 0.5C6 0.5 1.73 3.61 0 8C1.73 12.39 6 15.5 11 15.5C16 15.5 20.27 12.39 22 8C20.27 3.61 16 0.5 11 0.5Z"
            fill="#0093D7"/>
    </svg>

    const formBuilderIcon = <svg className="mr-2" width="18" height="18" viewBox="0 0 18 18" fill="none"
                                 xmlns="http://www.w3.org/2000/svg">
        <path d="M0 0H18V2H0V0ZM0 4H12V6H0V4ZM0 8H18V10H0V8ZM0 12H12V14H0V12ZM0 16H18V18H0V16Z" fill="#0093D7"/>
    </svg>

    const addOption = () => {
        let newOptions = [...state.options];
        newOptions.push("");
        setState({
            ...state,
            options: newOptions
        });
    }

    const removeOption = (index) => {
        let newErrors = {...state.errors};
        if (newErrors["options"]?.[index]) {
            newErrors["options"][index] = false;
        }
        let newOptions = [...state.options];
        newOptions.splice(index, 1);
        setState({
            ...state,
            options: newOptions,
            errors: newErrors
        });
    }

    const handleOptionChange = (e, index) => {
        let newErrors = {...state.errors};
        if (newErrors["options"]?.[index]) {
            newErrors["options"][index] = false;
        }
        let newOptions = [...state.options];
        newOptions[index] = e.target.value;
        setState({
            ...state,
            options: newOptions,
            errors: newErrors
        });
    }

    const addPaymentOption = () => {
        let newOptions = [...state.paymentOptions];
        newOptions.push({
            name: "",
            price: 0
        });
        setState({
            ...state,
            paymentOptions: newOptions
        });
    }

    const removePaymentOption = (index) => {
        let newErrors = {...state.errors};
        if (newErrors["paymentOptions"]?.[index]?.name || newErrors["paymentOptions"]?.[index]?.price) {
            newErrors["paymentOptions"][index] = {
                name: false,
                price: false
            };
        }
        let newOptions = [...state.paymentOptions];
        newOptions.splice(index, 1);
        setState({
            ...state,
            paymentOptions: newOptions,
            errors: newErrors
        });
    }

    const handlePaymentOptionNameChange = (e, index) => {
        let newErrors = {...state.errors};
        if (newErrors["paymentOptions"]?.[index]?.name) {
            newErrors["paymentOptions"][index] = {
                ...newErrors["paymentOptions"][index],
                name: false,
            };
        }
        let newOptions = [...state.paymentOptions];
        newOptions[index].name = e.target.value;
        setState({
            ...state,
            paymentOptions: newOptions,
            errors: newErrors
        });
    }

    const handlePaymentOptionPriceChange = (e, index) => {
        let newErrors = {...state.errors};
        if (newErrors["paymentOptions"]?.[index]?.price) {
            newErrors["paymentOptions"][index] = {
                ...newErrors["paymentOptions"][index],
                price: false,
            };
        }
        let newOptions = [...state.paymentOptions];
        newOptions[index].price = Math.round(e.target.value * Math.pow(10, state.currency.digits));
        setState({
            ...state,
            paymentOptions: newOptions,
            errors: newErrors
        });
    }

    const closeSlidingPanel = () => {
        setState({
            ...state,
            currentStyle: SIDEBAR_STYLE.CLOSED
        });
    }

    const exitFormBuilder = () => {
        if ((!state.currency?.digits || !state.merchantId || !state.secretApiKey) && state.formSettings.payment === true) {
            props.exitFormBuilder(state.initFormSettings, state.initFormFields, true);
        } else {
            props.exitFormBuilder(state.initFormSettings, state.initFormFields, false);
        }
    }
    const notificationAlertRef = useRef(null);
    return (
        <Container fluid className="form-builder">
            <div className="rna-container">
                <NotificationAlert ref={notificationAlertRef}/>
            </div>
            {state.alert}
            <Row className="top-row">

                <div className="col exit-col">
                    {props.showOnlyPreview ?
                        <a className="btn-link-primary" onClick={props.exitPreview}><FormattedMessage
                            id="Form.exitPreview"/>
                        </a>
                        :
                        !state.isPreviewVisible &&
                        <a className="btn-link-primary" onClick={exitFormBuilder}><FormattedMessage
                            id="Form.exitFormBuilder"/>
                        </a>
                    }

                </div>
                <div className="col tab-navigation-col">
                    <ul>
                        {!props.showOnlyPreview && !state.isPreviewVisible &&
                        <>
                            <a onClick={() => showEditor()}
                               className={state.isEditorVisible ? "tab active" : "tab"}><FormattedMessage
                                id="Form.editor"/></a>
                            <a onClick={() => showSettings()}
                               className={state.isSettingsPageVisible ? "tab active" : "tab"}><FormattedMessage
                                id="Form.settings"/></a>
                        </>}

                        {state.isPreviewVisible &&
                        <> <a onClick={() => showPhoneView()}
                              className={state.isPhoneViewVisible ? "tab active" : "tab"}><FormattedMessage
                            id="Form.phone"/></a>
                            <a onClick={() => showDesktopView()}
                               className={state.isDesktopViewVisible ? "tab active" : "tab"}><FormattedMessage
                                id="Form.desktop"/></a>
                        </>}
                    </ul>
                </div>

                <div className="col actions-col">
                    {!props.showOnlyPreview && <>
                        {!state.isPreviewVisible &&
                        <>
                            <a onClick={() => showPreview()}
                               className="btn-link-primary preview">{previewIcon} <FormattedMessage id="Form.preview"/></a>
                            <a onClick={() => saveForm()}
                               className="btn-link-success save"><FormattedMessage id="Button.save"/></a>
                        </>}

                        {state.isPreviewVisible &&
                        <>
                            <a onClick={() => hidePreview()}
                               className="btn-link-primary hide-preview">{formBuilderIcon} <FormattedMessage
                                id="Form.formBuilder"/></a>
                        </>}</>}
                </div>
            </Row>


            {!props.showOnlyPreview && !state.isPreviewVisible && !state.isSettingsPageVisible &&
            <div className="form-builder-body">
                <DragDropContext onDragEnd={onDragEnd}>
                    <div className="col left-col">
                        <div className="title">
                            <p><FormattedMessage id="Form.formFields"/></p>
                        </div>
                        <div className="draggable-form-groups">
                            <Column
                                className="column"
                                droppableId="All Fields"
                                list={state.leftAndCenterColFields[0].formFields}
                                type="TASK"
                                currency={state.currency}
                                handleFormFieldClick={handleFormFieldClick}
                                handleFormFieldDelete={handleFormFieldDelete}
                            />
                        </div>
                    </div>
                    <div className="col center-col">
                        <div className="title">
                            <Form.Group
                                draggable={true}
                                className={state.formTitleIsEditable ? "editable" : "not-editable"}>
                                <Form.Label><FormattedMessage id="Title"/></Form.Label>
                                <Form.Control
                                    type="text"
                                    value={state.formSettings.name}
                                    onFocus={() => toggleEditableInput()}
                                    onBlur={() => toggleEditableInput()}
                                    onChange={(e) => handleOnChange(e, "formTitle")}
                                />
                            </Form.Group>
                        </div>

                        <div className="fields-wrapper full-width">

                            <Column
                                className="column"
                                droppableId="Selected Fields"
                                list={state.leftAndCenterColFields[1].formFields}
                                type="TASK"
                                currency={state.currency}
                                errors={state.errors}
                                handleFormFieldClick={handleFormFieldClick}
                                selectedDraggableId={state.selectedDraggableId}
                                rightPanelOpened={state.currentStyle === SIDEBAR_STYLE.OPEN}
                                selectedId={state.selectedId}
                                handleFormFieldDelete={handleFormFieldDelete}
                            />

                        </div>

                    </div>
                </DragDropContext>
                <div className="col right-col">
                    <SlidingPanel
                        title={<FormattedMessage id="Form.settingsParam" values={{param: getFieldPlaceholder()}}/>}
                        currentStyle={state.currentStyle}
                        closeSlidingPanel={closeSlidingPanel}
                        content={<>
                            <div className="sliding-panel-form">
                                <Form.Group>
                                    <Form.Label><FormattedMessage id="Form.fieldName"/></Form.Label>
                                    <FormattedMessage id="Form.text">
                                        {placeholder =>
                                            <Form.Control
                                                placeholder={placeholder}
                                                type="text"
                                                onChange={(e) => handleOnChange(e, "fieldName")}
                                                value={state.fieldName}
                                            />
                                        }
                                    </FormattedMessage>
                                </Form.Group>
                                <Form.Group>
                                    <Form.Label><FormattedMessage id="Form.fieldDescription"/></Form.Label>
                                    <FormattedMessage id="Form.descriptionOpt">
                                        {placeholder =>
                                            <Form.Control
                                                type="text"
                                                placeholder={placeholder}
                                                onChange={(e) => handleOnChange(e, "fieldDescription")}
                                                value={state.fieldDescription}
                                            />
                                        }
                                    </FormattedMessage>
                                </Form.Group>
                                {(state.selectedFieldType === FORM_FIELD_TYPE.TEXT ||
                                    state.selectedFieldType === FORM_FIELD_TEXT_DISPLAY_OPTION.TEXT_AREA ||
                                    state.selectedFieldType === FORM_FIELD_TEXT_DISPLAY_OPTION.EMAIL ||
                                    state.selectedFieldType === FORM_FIELD_TEXT_DISPLAY_OPTION.PHONE ||
                                    state.selectedFieldType === FORM_FIELD_SINGLE_CHOICE_DISPLAY_OPTION.DROPDOWN) &&
                                <Form.Group>
                                    <Form.Label><FormattedMessage id="Form.placeholderText"/></Form.Label>
                                    <FormattedMessage id="Form.placeholderText">
                                        {placeholder =>
                                            <Form.Control
                                                type="text"
                                                placeholder={placeholder}
                                                onChange={(e) => handleOnChange(e, "placeholderText")}
                                                value={state.placeholderText}
                                            />
                                        }
                                    </FormattedMessage>
                                </Form.Group>}

                                {(state.selectedFieldType === FORM_FIELD_SINGLE_CHOICE_DISPLAY_OPTION.DROPDOWN || state.selectedFieldType === FORM_FIELD_TYPE.MULTIPLE_CHOICE
                                    || state.selectedFieldType === FORM_FIELD_TYPE.SINGLE_CHOICE) &&
                                <Form.Group className="smaller">
                                    <Form.Label><FormattedMessage id="Form.options"/></Form.Label>
                                    {
                                        state.options?.map((option, index) => {
                                            return <Form.Group key={index}>
                                                <div className="form-description"><FormattedMessage
                                                    id="Form.optionParam" values={{param: index + 1}}/></div>
                                                <div className="row m-0 align-items-center">
                                                    <FormattedMessage id="Form.optionParam" values={{param: index + 1}}>
                                                        {placeholder =>
                                                            <Form.Control
                                                                className={state.errors.options?.[index] ? "has-errors" : ""}
                                                                name="name"
                                                                placeholder={placeholder}
                                                                value={option || ""}
                                                                onChange={(e) => handleOptionChange(e, index)}
                                                                isInvalid={state.errors.options?.[index]}
                                                            />
                                                        }
                                                    </FormattedMessage>
                                                    <a
                                                        className="ml-3 text-danger"
                                                        onClick={() => removeOption(index)}
                                                    >
                                                        <i className="fa fa-trash"/>
                                                    </a>
                                                    <Form.Control.Feedback type="invalid">
                                                        <FormattedMessage id={"Validation.cannotBeEmpty"}/>
                                                    </Form.Control.Feedback>
                                                </div>
                                            </Form.Group>
                                        })
                                    }
                                    <div>
                                        <Button onClick={addOption}>
                                            <FormattedMessage id="Button.addOption"/>
                                        </Button>
                                    </div>

                                </Form.Group>}

                                {state.selectedFieldType === FORM_FIELD_SINGLE_CHOICE_DISPLAY_OPTION.DROPDOWN &&
                                <label className="d-flex align-items-center">
                                    <span className="switch-label mr-auto">  <FormattedMessage
                                        id="Form.preSelectedOption"/></span>
                                    <Form.Check
                                        onChange={() => handleOnChange(null, "preSelectedOptionSwitch")}
                                        type="switch"
                                        id="crossa-pre-selected-option-switch"
                                        checked={state.preSelectedOptionSwitch}
                                    />
                                </label>}

                                {state.selectedFieldType === FORM_FIELD_SINGLE_CHOICE_DISPLAY_OPTION.DROPDOWN && state.preSelectedOptionSwitch &&
                                <Form.Group>
                                    <Select
                                        onChange={(e) => handleOnChange(e, "preSelectedOption")}
                                        className={"react-select primary " + (state.errors.preSelectedOptionField ? "has-errors" : "")}
                                        classNamePrefix="react-select"
                                        options={state.options?.map(option => {
                                            return {
                                                "label": option,
                                                "value": option,
                                            }
                                        })}
                                        value={{
                                            "label": state.preSelectedOption,
                                            "value": state.preSelectedOption
                                        }}
                                    />
                                    {state.errors.preSelectedOptionField && <div className={"error-message"}>
                                        <FormattedMessage id={"Validation.selectOneOption"}/>
                                    </div>}
                                </Form.Group>
                                }

                                {state.selectedFieldType === FORM_FIELD_TYPE.DATE &&
                                <label className="d-flex align-items-center">

                                    <span className="switch-label mr-auto"><FormattedMessage
                                        id="Form.timeField"/></span>
                                    <Form.Check
                                        type="switch"
                                        id="crossa-time-field-switch"
                                        onChange={() => handleOnChange(null, "timeFieldSwitch")}
                                        checked={state.timeFieldSwitch}
                                    />
                                </label>}

                                {state.selectedFieldType === FORM_FIELD_TYPE.DATE &&
                                <label className="d-flex align-items-center">
                                    <span className="switch-label mr-auto"><FormattedMessage
                                        id="Form.defaultDate"/></span>
                                    <Form.Check
                                        type="switch"
                                        id="crossa-default-date-switch"
                                        onChange={() => handleOnChange(null, "defaultDateSwitch")}
                                        checked={state.defaultDateSwitch}
                                    />
                                </label>}

                                {state.selectedFieldType === FORM_FIELD_TYPE.DATE && state.defaultDateSwitch &&
                                <Form.Group>
                                    <DateTimePicker
                                        className={state.errors.defaultDateField ? "has-errors" : ""}
                                        maxDate={new Date("9999-12-31")}
                                        disableClock={true}
                                        onChange={value => handleOnChangeDate(value, "defaultDate")}
                                        format={state.timeFieldSwitch ? "y-MM-dd HH:mm" : "y-MM-dd"}
                                        value={state.defaultDate ? new Date(state.defaultDate) : ""}
                                    />
                                    {state.errors.defaultDateField && <div className={"error-message"}>
                                        <FormattedMessage id={"Validation.cannotBeEmpty"}/>
                                    </div>}
                                </Form.Group>
                                }

                                {state.selectedFieldType !== FORM_FIELD_TYPE.PAYMENT && state.selectedFieldType !== FORM_FIELD_TYPE.GROUP_HEADER &&
                                <label className="d-flex align-items-center">
                                    <span className="switch-label mr-auto"><FormattedMessage
                                        id="Form.requireField"/></span>
                                    <Form.Check
                                        onChange={() => handleOnChange(null, "requiredFieldSwitch")}
                                        type="switch"
                                        id="crossa-required-field-switch"
                                        checked={state.requiredFieldSwitch}
                                    />
                                </label>}

                                {(state.selectedFieldType === FORM_FIELD_TYPE.MULTIPLE_CHOICE || state.selectedFieldType === FORM_FIELD_TYPE.SINGLE_CHOICE) &&
                                <label className="d-flex align-items-center">
                                    <span className="switch-label mr-auto"><FormattedMessage
                                        id="Form.enableOther"/></span>
                                    <Form.Check
                                        onChange={() => handleOnChange(null, "enableOtherFieldSwitch")}
                                        type="switch"
                                        id="crossa-enable-other-switch"
                                        checked={state.enableOtherFieldSwitch}
                                    />
                                </label>}

                                {(state.selectedFieldType === FORM_FIELD_TYPE.TEXT || state.selectedFieldType === FORM_FIELD_TEXT_DISPLAY_OPTION.TEXT_AREA)
                                && <label className="d-flex align-items-center">
                                    <span className="switch-label mr-auto"><FormattedMessage id="Form.characterLimit"/></span>
                                    <Form.Check
                                        onChange={() => handleOnChange(null, "characterLimitSwitch")}
                                        type="switch"
                                        id="crossa-character-switch"
                                        checked={state.characterLimitSwitch}
                                    />
                                </label>}

                                {(state.selectedFieldType === FORM_FIELD_TYPE.TEXT || state.selectedFieldType === FORM_FIELD_TEXT_DISPLAY_OPTION.TEXT_AREA)
                                && state.characterLimitSwitch && <Form.Group>
                                    <FormattedMessage id="Form.characterLimitPlaceholder">
                                        {placeholder =>
                                            <Form.Control
                                                className={state.errors.characterLimitField ? "has-errors" : ""}
                                                type="number"
                                                placeholder={placeholder}
                                                onChange={(e) => handleOnChange(e, "characterLimit")}
                                                value={state.characterLimit}
                                                isInvalid={state.errors.characterLimitField}
                                            />
                                        }
                                    </FormattedMessage>

                                    <Form.Control.Feedback type="invalid">
                                        <FormattedMessage id={"Validation.cannotBeEmpty"}/>
                                    </Form.Control.Feedback>
                                </Form.Group>
                                }

                                {state.selectedFieldType === FORM_FIELD_TEXT_DISPLAY_OPTION.EMAIL &&
                                <label className="d-flex align-items-center">
                                    <span className="switch-label mr-auto"><FormattedMessage
                                        id="Form.confirmEmail"/></span>
                                    <Form.Check
                                        onChange={() => handleOnChange(null, "confirmEmailSwitch")}
                                        type="switch"
                                        id="crossa-confirm-email-switch"
                                        checked={state.confirmEmailSwitch}
                                    />
                                </label>}

                                {state.selectedFieldType === FORM_FIELD_TYPE.PAYMENT &&
                                <label className="d-flex align-items-center">
                                    <span className="switch-label mr-auto"><FormattedMessage
                                        id="Form.otherPayment"/></span>
                                    <Form.Check
                                        onChange={() => handleOnChange(null, "otherPayments")}
                                        type="switch"
                                        id="crossa-other-payments-switch"
                                        checked={state.otherPayments}
                                    />
                                </label>
                                }

                                {state.selectedFieldType !== FORM_FIELD_TYPE.PAYMENT &&
                                    <label className="d-flex align-items-center">
                                    <span className="switch-label mr-auto">  <FormattedMessage
                                        id="Form.hide"/></span>
                                        <Form.Check
                                            onChange={() => handleOnChange(null, "hideSwitch")}
                                            type="switch"
                                            id="crossa-hide-switch"
                                            checked={state.hideSwitch}
                                        />
                                    </label>}

                                {state.selectedFieldType === FORM_FIELD_TYPE.PAYMENT && state.otherPayments &&
                                <Form.Group>
                                    <Form.Control
                                        className={state.errors.otherPaymentsField ? "has-errors" : ""}
                                        type="text"
                                        onChange={(e) => handleOnChange(e, "otherPaymentsLabel")}
                                        value={state.otherPaymentsLabel}
                                        isInvalid={state.errors.otherPaymentsField}
                                    />
                                    <Form.Control.Feedback type={"invalid"}>
                                        <FormattedMessage id={"Validation.cannotBeEmpty"}/>
                                    </Form.Control.Feedback>
                                </Form.Group>
                                }

                                {state.selectedFieldType === FORM_FIELD_TYPE.PAYMENT && state.paymentOptions &&
                                <Form.Group className="smaller">
                                    <Form.Label><FormattedMessage id="Form.options"/></Form.Label>
                                    {
                                        state.paymentOptions?.map((option, index) => {
                                            return <Form.Group key={index}>
                                                <div className="form-description"><FormattedMessage
                                                    id="Form.optionParam" values={{param: index + 1}}/></div>
                                                <div className="row m-0 flex-nowrap  align-items-center">
                                                    <FormattedMessage id="Form.name">
                                                        {placeholder =>
                                                            <Form.Control
                                                                className={"mr-2" + (state.errors.paymentOptions?.[index]?.name ? " has-errors" : "")}
                                                                type="text"
                                                                name="name"
                                                                placeholder={placeholder}
                                                                value={(option && option.name) || ""}
                                                                onChange={(e) => handlePaymentOptionNameChange(e, index)}
                                                                isInvalid={state.errors.paymentOptions?.[index]?.name}
                                                            />
                                                        }
                                                    </FormattedMessage>
                                                    <FormattedMessage id="Form.price">
                                                        {placeholder =>
                                                            <Form.Control
                                                                className={state.errors.paymentOptions?.[index]?.price ? "has-errors" : ""}
                                                                type="number"
                                                                name="name"
                                                                placeholder={placeholder}
                                                                value={(option && option.price) ? option.price / Math.pow(10, state.currency.digits) : ""}
                                                                onChange={(e) => handlePaymentOptionPriceChange(e, index)}
                                                                isInvalid={state.errors.paymentOptions?.[index]?.price}
                                                            />
                                                        }
                                                    </FormattedMessage>
                                                    <a
                                                        className="ml-3 text-danger"
                                                        onClick={() => removePaymentOption(index)}
                                                    >
                                                        <i className="fa fa-trash"/>
                                                    </a>

                                                </div>
                                                <div className="row flex-nowrap m-0 ">
                                                    {state.errors.paymentOptions?.[index]?.name &&
                                                    <div className="error-message mr-1">
                                                        <FormattedMessage id="Validation.cannotBeEmpty"/>
                                                    </div>}
                                                    {state.errors.paymentOptions?.[index]?.price &&
                                                    <div className="error-message mr-3">
                                                        <FormattedMessage id="Validation.paymentInvalidPrice"/>
                                                    </div>}
                                                </div>

                                            </Form.Group>
                                        })
                                    }
                                    <div>
                                        <Button onClick={addPaymentOption}>
                                            <FormattedMessage id="Button.addOption"/>
                                        </Button>
                                    </div>

                                </Form.Group>
                                }

                            </div>
                            <div className="section row buttons-row">
                                <button
                                    onClick={() => setState({
                                        ...state,
                                        currentStyle: SIDEBAR_STYLE.CLOSED
                                    })}
                                    className="btn btn-default"><FormattedMessage id="Button.cancel"/>
                                </button>
                                <button onClick={() => saveFormField()} className="btn btn-success"><FormattedMessage
                                    id="Button.save"/></button>
                            </div>
                        </>}>

                    </SlidingPanel>

                </div>

            </div>}
            {state.isSettingsPageVisible && !state.isPreviewVisible &&
            <FormBuilderSettings
                errors={state.errors}
                formSettings={state.formSettings}
                currency={state.currency}
                merchantId={state.merchantId}
                secretApiKey={state.secretApiKey}
                changeFormSettings={changeFormSettings}
            />}
            {state.isPreviewVisible &&
            <FormBuilderPreview
                leftAndCenterColFields={state.leftAndCenterColFields}
                formTitle={state.formSettings.name}
                hidePreview={hidePreview}
                isPhoneViewVisible={state.isPhoneViewVisible}
                isDesktopViewVisible={state.isDesktopViewVisible}
                formSettings={state.formSettings}
                currency={state.currency}
            />}
        </Container>
    );
}

export default FormBuilder;
