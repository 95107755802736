import React from "react";
import {Route, Switch} from "react-router-dom";
import publicRoutes from "routes/public/publicRoutes.js";
import FooterLogin from "../components/FooterForLogin";

function PublicLayout(props) {
    const getRoutes = (routes) => {
        return routes.map((prop, key) => {
            if (prop.layout === "/auth" || prop.layout === "/404") {
                return (
                    <Route
                        path={prop.layout + prop.path}
                        key={key}
                        component={prop.component}
                    />
                );
            } else {
                return null;
            }
        });
    };
    return (
        <>
            <div className="wrapper">
                <Switch>{getRoutes(publicRoutes)}</Switch>
                {props.hasFooter === true && <FooterLogin/>}
            </div>
        </>
    );
}

export default PublicLayout;
