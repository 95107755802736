import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import {Col, Row} from "react-bootstrap";
import MenuTitleComponent from "views/workspaceAdmin/menu/component/MenuTitleComponent.js";
import AddActionComponent from "views/workspaceAdmin/menu/component/AddActionComponent.js";
import TabsComponent from "views/workspaceAdmin/menu/component/TabsComponent.js";
import LeftComponent from "views/workspaceAdmin/menu/component/LeftComponent.js";
import ViewProvider from "views/workspaceAdmin/menu/context/ViewProvider.js"
import TabsContext from "views/workspaceAdmin/menu/context/TabsContext.js";
import Footer from "components/Footer.js"
import EXCEPTION_MESSAGES, {DETAILED_VIEW_MODE, RECORDS_DISPLAY_MODE} from "util/Constants";
import { FormattedMessage } from 'react-intl';
import SweetAlert from "react-bootstrap-sweetalert";
import { getDataForMenuHandler } from "handlers/workspaceAdminHandlers/menuHandlers/SaveMenuHandlers";
import { saveMenuHandler } from "handlers/workspaceAdminHandlers/menuHandlers/SaveMenuHandlers";
import CrossaPageTemplate from "../../../components/CrossaPageTemplate";
import AdminNavbar from "../../../components/AdminNavbar";
import {getNotifyErrorOptions, getNotifySuccessOptions} from "util/Util";
import NotificationAlert from "react-notification-alert";
import {addMenuWithPagesValidations} from "../../../validations/workspaceAdmin/menus/MenuValidations";
import WidgetsComponent from "./component/WidgetsComponent";
import WidgetsContext from "./context/WidgetsContext";
import WidgetContext from "./context/WidgetsContext";

function SaveMenuPage(props) {
    const history = useHistory();

    const [state, setState] = useState({
        alert: null,
        menu: {
            "name": props.location.state?.newMenuName || "",
            "role": "",
            "wid": props.match.params.wid
        },
        dashboard:{
            "name": "Dashboard",
            "widgets": []
        },
        dashboardActive:true,
        allPages: [],
        currentPageIndex: null,
        tabIndex: null,
        widgetIndex:null,
        errors:{},
    })

    const wid = props.match.params.wid;
    const mid = props.match.params.mid;

    useEffect(async () => {
        getPageContent();
    }, []);

    const getPageContent = async () => {
        if (mid) {
            getDataForMenuHandler(mid).then(response => {
                if (response.ok) {
                    setState({
                        ...state,
                        ...response.data
                    });
                }
            })
        } else {
            setState({
                ...state,
                menu: {
                    ...state.menu,
                }
            })
        }
    }

    const deletePageByIndex = pageIndex => {

        let currentPageIndex = state.currentPageIndex;
        let tabIndex = state.tabIndex;
        if (pageIndex === 0 && state.currentPageIndex === 0 && state.allPages.length > 1) {
            currentPageIndex = 0;
            tabIndex = 0;
        } else if (state.currentPageIndex >= pageIndex) {
            currentPageIndex = currentPageIndex - 1;
            tabIndex = 0;
        }
        const newPages = state.allPages.filter((page, index) => index !== pageIndex);
        let newErrors={...state.errors};
        if(newErrors.pages?.[pageIndex]){
            newErrors.pages[pageIndex]=null;
        }
        setState({
            ...state,
            allPages: newPages,
            currentPageIndex: currentPageIndex,
            tabIndex: tabIndex,
            errors: newErrors,
        });
    }

    const showAlert = (message) => {
        setState({
            ...state,
            alert: <SweetAlert
                type="danger"
                title=""
                style={{ display: "block", marginTop: "-100px", fontSize: "13px" }}
                btnSize="md"
                confirmBtnBsStyle="default"
                confirmBtnText={<FormattedMessage id="Button.ok" />}
                openAnim="false"
                onCancel={() => hideAlert()}
                onConfirm={() => hideAlert()}
                allowEscape
                closeOnClickOutside
                customClass="crossa-alert"
            >  <FormattedMessage id={message}/>
            </SweetAlert>
        });
    }

    const hideAlert = () => {
        setState({
            ...state,
            alert: null
        });
    }

    const changeCurrentPage = index => {
        setState({
            ...state,
            currentPageIndex: index,
            tabIndex: 0,
            dashboardActive: false,
        });
    }

    const selectDashboardPage = () => {
        setState({
            ...state,
            currentPageIndex: null,
            tabIndex: null,
            dashboardActive: true
        });
    }

    const handleMenuNameChange = e => {
        let newErrors={...state.errors};
        if(newErrors.name){
            newErrors.name=false;
        }
        setState({
            ...state,
            menu: {
                ...state.menu,
                "name": e.target.value
            },
            errors: newErrors
        });
    }

    const handleNameChange = e => {
        const newPages = [...state.allPages];
        const newPage = { ...state.allPages[state.currentPageIndex] };
        newPage.name = e.target.value;
        newPages[state.currentPageIndex] = newPage;
        let newErrors={...state.errors};
        if(newErrors.pages?.[state.currentPageIndex]?.name){
            newErrors.pages[state.currentPageIndex].name=false;
        }
        setState({
            ...state,
            allPages: newPages,
            errors: newErrors
        });
    }

    const handleDashboardNameChange = e => {
        const newPages = [...state.allPages];
        const newPage = { ...state.allPages[state.currentPageIndex] };
        newPage.name = e.target.value;
        newPages[state.currentPageIndex] = newPage;
        let newErrors={...state.errors};
        if(newErrors.dashboard?.name){
            newErrors.dashboard.name=false;
        }
        setState({
            ...state,
            dashboard: {
                ...state.dashboard,
                name:e.target.value,
            },
            errors: newErrors
        });
    }

    const handleActionNameButton = e => {
        const newPages = [...state.allPages];
        const newPage = { ...state.allPages[state.currentPageIndex] };
        newPage.addActionName = e.target.value;
        newPages[state.currentPageIndex] = newPage;
        let newErrors={...state.errors};
        if(newErrors.pages?.[state.currentPageIndex]?.addActionName){
            newErrors.pages[state.currentPageIndex].addActionName=false;
        }
        setState({
            ...state,
            allPages: newPages,
            errors: newErrors,
        });
    }

    const handleEnableEditChange = e => {
        const newPages = [...state.allPages];
        const newPage = { ...state.allPages[state.currentPageIndex] };
        newPage.enableAddAction = e.target.checked;
        newPages[state.currentPageIndex] = newPage;
        let newErrors={...state.errors};
        if(newErrors.pages?.[state.currentPageIndex]?.addActionName){
            newErrors.pages[state.currentPageIndex].addActionName=false;
        }
        if(newErrors.pages?.[state.currentPageIndex]?.addActionVid){
            newErrors.pages[state.currentPageIndex].addActionVid=false;
        }
        setState({
            ...state,
            allPages: newPages,
            errors: newErrors
        });
    }

    const handleViewChange = e => {
        const newPages = [...state.allPages];
        const newPage = { ...state.allPages[state.currentPageIndex] };
        newPage.addActionVid = e.id;
        newPages[state.currentPageIndex] = newPage;
        let newErrors={...state.errors};
        if(newErrors.pages?.[state.currentPageIndex]?.addActionVid){
            newErrors.pages[state.currentPageIndex].addActionVid=false;
        }
        setState({
            ...state,
            allPages: newPages,
            errors: newErrors
        });
    }

    const setTabs2 = (newTabs,newErrors,newIndex) => {
        const newPages = [...state.allPages];
        const newPage = { ...state.allPages[state.currentPageIndex] };
        newPage.tabs = [...newTabs];
        newPages[state.currentPageIndex] = newPage;

        let newTabIndex=typeof newIndex!=="undefined"?newIndex:state.tabIndex;
        return setState({
            ...state,
            allPages: newPages,
            errors: {...state.errors,...newErrors},
            tabIndex: newTabIndex
        });
    }

    const setWidgets = (newWidgets,newErrors,newIndex) => {

        let newWidgetIndex=typeof newIndex!=="undefined"?newIndex:state.widgetIndex;
        return setState({
            ...state,
            dashboard: {
                ...state.dashboard,
                widgets: newWidgets
            },
            errors: {...state.errors,...newErrors},
            widgetIndex: newWidgetIndex
        });
    }

    const saveMenu = async () => {
        const finalMenu = {
            ...state.menu,
            "pages": [...state.allPages],
            "dashboard": state.dashboard
        }
        const [newErrors, existsErrors] = addMenuWithPagesValidations(finalMenu,state.errors);
        for (let page of finalMenu.pages) {
            if (!page.enableAddAction) {
                delete page.addActionVid;
                delete page.addActionName;
            }
        }
        if(existsErrors){
            setState({
                ...state,
                errors: newErrors
            });
            let options=getNotifyErrorOptions("bc");
            notificationAlertRef.current.notificationAlert(options);
            return;
        }
        saveMenuHandler(mid, finalMenu).then(response => {
            if (response.ok) {
                if(mid){
                    let options=getNotifySuccessOptions("bc");
                    notificationAlertRef.current.notificationAlert(options);
                } else {
                    history.push("/workspace-admin/" + wid + "/menus");
                }
            } else {
                if (!!response.data.message && response.data.message === EXCEPTION_MESSAGES.DUPLICATED_PAGE) {
                    showAlert("Validation.duplicatedPage");
                } else if (!!response.data.message && response.data.message === EXCEPTION_MESSAGES.DUPLICATED_TAB) {
                    showAlert("Validation.duplicatedTab");
                }
            }
        });
    }

    const createMenuPage = () => {
        let newIndex = state.allPages.length;
        setState({
            ...state,
            allPages: [...state.allPages, {
                "name": "Untitled",
                "addActionName": "",
                "enableAddAction": false,
                "addActionVid": null,
                "tabs": [{ "name": "Untitled tab 1", "displayMode": RECORDS_DISPLAY_MODE.TABLE, "detailedViewMode": DETAILED_VIEW_MODE.SIDE_PANEL, "vid": null, "displayFields": [] }]
            }],
            currentPageIndex: newIndex,
            tabIndex: 0,
            dashboardActive: false,
        });
    }

    const setNewOrderForPages=(newPages,oldPageIndex,newPageIndex)=>{
        let newCurrentPageIndex=state.currentPageIndex;
        if(oldPageIndex===newCurrentPageIndex){
            newCurrentPageIndex=newPageIndex;
        } else if(oldPageIndex<newCurrentPageIndex && newCurrentPageIndex<=newPageIndex){
            newCurrentPageIndex--;
        } else if(newPageIndex<=newCurrentPageIndex && newCurrentPageIndex<oldPageIndex){
            newCurrentPageIndex++;
        }

        let newPageErrors={...state.errors.pages};
        if(newPageErrors && oldPageIndex<newPageIndex) {
            let pageErrorsSourceIndex=newPageErrors[oldPageIndex];
            for (let index = oldPageIndex; index <newPageIndex; index++){
                newPageErrors[index]=newPageErrors[index+1];
            }
            newPageErrors[newPageIndex]=pageErrorsSourceIndex;
        }

        if(newPageErrors &&  oldPageIndex>newPageIndex) {
            let pageErrorsSourceIndex=newPageErrors[oldPageIndex];
            for (let index = oldPageIndex; index >newPageIndex; index--){
                newPageErrors[index]=newPageErrors[index-1];
            }
            newPageErrors[newPageIndex]=pageErrorsSourceIndex;
        }

        setState({
            ...state,
            allPages: newPages,
            currentPageIndex: newCurrentPageIndex,
            errors: {
                ...state.errors,
                pages:newPageErrors
            },
        });
    }

    const setTabIndex=(e)=>{
            let newPages=[...state.allPages];
            let newErrors={...state.errors};
            if(newPages[state.currentPageIndex]?.tabs?.[state.tabIndex] && !newPages[state.currentPageIndex].tabs[state.tabIndex]?.name){
                newPages[state.currentPageIndex].tabs[state.tabIndex].name="Untitled tab "+(parseInt(state.tabIndex,10)+1);
                if(newErrors.pages?.[state.currentPageIndex]?.tabs?.[state.tabIndex]?.name){
                    newErrors.pages[state.currentPageIndex].tabs[state.tabIndex].name=false;
                }
            }
            setState({
                ...state,
                tabIndex: parseInt(e,10),
                allPages: newPages,
                errors: newErrors,
            });
    }

    const setWidgetIndex=(e)=>{
        let dashboard={...state.dashboard};
        let newErrors={...state.errors};
        if(dashboard.widgets?.[state.widgetIndex] && !dashboard.widgets[state.widgetIndex]?.name){
            dashboard.widgets[state.widgetIndex].name="Untitled widget "+(parseInt(state.widgetIndex,10)+1);
            if(newErrors.dashboard?.widgets?.[state.widgetIndex]?.name){
                newErrors.dashboard.widgets[state.widgetIndex].name=false;
            }
        }
        setState({
            ...state,
            widgetIndex: parseInt(e,10),
            dashboard: dashboard,
            errors: newErrors,
        });
    }

    const handleDashboardVisibleChange = e => {
        let newErrors={...state.errors};
        if(newErrors.dashboard?.name){
            newErrors.dashboard.name=false;
        }
        setState({
            ...state,
            dashboard: {
                ...state.dashboard,
                visible:e.target.checked,
            },
            errors: newErrors
        });
    }

    const notificationAlertRef = React.useRef(null);

    return (
        <>
            <div className="rna-container">
                <NotificationAlert ref={notificationAlertRef} />
            </div>
            <AdminNavbar
                title={<FormattedMessage id="EditMenuParam" values={{param: state.menu.name}}/>}
                hasBackBtn={true}
            />
        <CrossaPageTemplate

        >
            <Row>
                <Col className="left-component" md={4}>
                    <LeftComponent
                        pages={state.allPages}
                        dashboard={state.dashboard}
                        dashboardActive={state.dashboardActive}
                        selectPage={changeCurrentPage}
                        selectDashboardPage={selectDashboardPage}
                        name={state.menu.name}
                        handleNameChange={handleMenuNameChange}
                        alert={state.alert}
                        showAlert={showAlert}
                        deletePageByIndex={deletePageByIndex}
                        activePage={state.currentPageIndex}
                        btnAction={createMenuPage}
                        setNewOrderForPages={setNewOrderForPages}
                        errors={state.errors}
                    />

                </Col>
                {state.dashboardActive && <Col className="menu-page-forms-container" md={8}>
                    <MenuTitleComponent
                        name={state.dashboard?.name}
                        visible={state.dashboard?.visible}
                        handleNameChange={handleDashboardNameChange}
                        handleVisibleChange={handleDashboardVisibleChange}
                        errors={state.errors}
                        pageIndex={state.currentPageIndex}
                        dashboard={true}
                    />
                    <ViewProvider wid={wid}>
                    <WidgetContext widgets={state.dashboard?.widgets} setWidgets={setWidgets} errors={state.errors} >
                        <WidgetsComponent
                            widgetIndex={state.widgetIndex}
                            setWidgetIndex={setWidgetIndex}
                            errors={state.errors}
                        />
                    </WidgetContext>
                    </ViewProvider>
                </Col>}
                {(state.currentPageIndex || state.currentPageIndex===0 )&& <Col className="menu-page-forms-container" md={8}>
                    <MenuTitleComponent
                        name={state.allPages[state.currentPageIndex]?.name}
                        handleNameChange={handleNameChange}
                        errors={state.errors}
                        pageIndex={state.currentPageIndex}
                    />
                    <ViewProvider wid={wid}>
                        <AddActionComponent
                            handleButtonName={handleActionNameButton}
                            buttonName={state.allPages[state.currentPageIndex].addActionName}
                            handleEnableEditChange={handleEnableEditChange}
                            enableAddAction={state.allPages[state.currentPageIndex].enableAddAction}
                            handleViewChange={handleViewChange}
                            viewId={state.allPages[state.currentPageIndex].addActionVid}
                            errors={state.errors}
                            pageIndex={state.currentPageIndex}
                        />
                        <TabsContext tabs={state.allPages[state.currentPageIndex].tabs} setTabs2={setTabs2} errors={state.errors} >
                            <TabsComponent
                                tabIndex={state.tabIndex}
                                pageIndex={state.currentPageIndex}
                                setTabIndex2={setTabIndex}
                                tabs={state.allPages[state.currentPageIndex].tabs}
                                activePage={state.currentPageIndex}
                                errors={state.errors}
                            />
                        </TabsContext>
                    </ViewProvider>
                </Col>}
            </Row>
            <Footer
                save={saveMenu}
                cancel={() => history.goBack()}
                isEnable={true} />
        </CrossaPageTemplate>
        </>
    );
}

export default SaveMenuPage;
