import * as WorkspaceRoleService from "service/WorkspaceRoleService";
import * as MenuService from "../../../service/MenuService";

const getAllWorkspaceRoleHandler = async (wid) => {
    let workspaceRoles = [];
    let menuOptions = [];
    let ok = true;

    const response = await WorkspaceRoleService.findAllWorkspaceRoles(wid);
    ok = ok && response.ok;
    if (response.ok) {
        workspaceRoles = response.data;
    }

    const responseFindAllMenus = await MenuService.findAllMenus(wid);
    ok = ok && responseFindAllMenus.ok;
    if (responseFindAllMenus.ok) {
        const availableMenus = responseFindAllMenus.data.filter(menu => menu.role == null);
        menuOptions = availableMenus.map(menu => {
            return {
                "label": menu.name,
                "value": menu.id,
            }
        });
    }

    return {
        data: {
            workspaceRoles: workspaceRoles,
            menuOptions: menuOptions
        },
        ok: ok
    };
}

const deleteWorkspaceRoleHandler = async (id, wid) => {
    let menuOptions = [];
    let workspaceRoles = [];
    let ok = true;
    const responseDelete = await WorkspaceRoleService.deleteWorkspaceRoleById(id);
    ok = ok && responseDelete.ok;
    if (responseDelete.ok) {
        const responseFindAll = await WorkspaceRoleService.findAllWorkspaceRoles(wid);
        ok = ok && responseFindAll.ok;
        if (responseFindAll.ok) {
            workspaceRoles = responseFindAll.data;
        }
        const responseFindAllMenus = await MenuService.findAllMenus(wid);
        ok = ok && responseFindAllMenus.ok;
        if (responseFindAllMenus.ok) {
            const availableMenus = responseFindAllMenus.data.filter(menu => menu.role == null);
            menuOptions = availableMenus.map(menu => {
                return {
                    "label": menu.name,
                    "value": menu.id,
                }
            });
        }
    }

    return {
        data: {
            workspaceRoles: workspaceRoles,
            menuOptions: menuOptions
        },
        ok: ok
    }
}

const saveWorkspaceRoleHandler = async (roleId, finalRoleOnUpdatePage, finalRole, wid) => {
    let response;
    let workspaceRoles = [];
    let menuOptions = [];
    let ok = true;

    if (roleId) {
        response = await WorkspaceRoleService.updateWorkspaceRole(finalRoleOnUpdatePage);
    } else {
        response = await WorkspaceRoleService.addWorkspaceRole(finalRole);
    }
    ok = ok && response.ok;

    response = await WorkspaceRoleService.findAllWorkspaceRoles(wid);
    ok = ok && response.ok;
    if (response.ok) {
        workspaceRoles = response.data;
    }

    const responseFindAllMenus = await MenuService.findAllMenus(wid);
    ok = ok && responseFindAllMenus.ok;
    if (responseFindAllMenus.ok) {
        const availableMenus = responseFindAllMenus.data.filter(menu => menu.role == null);
        menuOptions = availableMenus.map(menu => {
            return {
                "label": menu.name,
                "value": menu.id,
            }
        });
    }

    return {
        data: {
            workspaceRoles: workspaceRoles,
            menuOptions: menuOptions
        },
        ok: ok
    };
}

export {
    getAllWorkspaceRoleHandler,
    deleteWorkspaceRoleHandler,
    saveWorkspaceRoleHandler
}
