import React from "react";
import {Button, ButtonGroup} from "react-bootstrap";
import {FormattedMessage} from "react-intl";

const OperatorComponent = props => {
    return (
        <ButtonGroup>
            <Button
                onClick={() => props.changeRule({"operator": "And"}, props.index)}
                className={(props.operator === "And" ? "active" : "")+" text-uppercase"}
                type="button"
                variant="transparent"
            >
                <FormattedMessage id="Operator.and" />
            </Button>
            <Button
                onClick={() => props.changeRule({"operator": "Or"}, props.index)}
                className={(props.operator === "Or" ? "active" : "")+" text-uppercase"}
                type="button"
                variant="transparent"
            >
                <FormattedMessage id="Operator.or" />
            </Button>

        </ButtonGroup>
    )
}

export default OperatorComponent;