import React, {useState, useEffect, useMemo} from "react";

import Select from "react-select";
import DisplayFieldsTable from "views/workspaceAdmin/menu/component/DisplayFieldsTable.js";
import {useAllViews} from "views/workspaceAdmin/menu/context/ViewProvider.js";
import {useTabChange, useTabs} from "views/workspaceAdmin/menu/context/TabsContext.js";
import {FormattedMessage} from 'react-intl';
import {arrowDownwardIcon, arrowUpwardIcon} from "../../../../components/Icons";
import {
    Row,
    Col,
    Form, Button, Card, OverlayTrigger, Tooltip
} from "react-bootstrap";
import {
    DETAILED_VIEW_MODE,
    RECORD_FIELD_MODE,
    RECORD_FIELD_TYPE,
    RECORDS_DISPLAY_MODE,
    SORT_DIRECTION
} from "../../../../util/Constants";

function TabContent(props) {

    const tabs = useTabs();
    const [currentViewOption, setCurrentViewOption] = useState(props.viewId);
    const [currentDisplayModeOption, setCurrentDisplayModeOption] = useState(props.displayMode);
    const [currentDetailedViewMode, setCurrentDetailedViewMode] = useState(props.detailedViewMode);
    const tabErrors = useMemo(() => props.errors?.pages?.[props.pageIndex]?.tabs?.[props.tabIndex], [props.errors,props.pageIndex,props.tabIndex])
    const tabChange = useTabChange();
    const allViews = useAllViews();
    const allViewOptions = allViews.map((view, index) => {
        return {
            "label": view.name,
            "value": index,
            "id": view.id
        }
    });
    const [viewIndex, setViewIndex] = useState(allViewOptions.findIndex(view => view.id === props.viewId) || -1);

    useEffect(() => {
        const currentView = allViewOptions.find(view => view.id === props.viewId);
        if (!!!currentView) {
            setCurrentViewOption(false);
            return;
        }
        setViewIndex(allViewOptions.findIndex(view => view.id === props.viewId));
        setCurrentViewOption(currentView);
    }, [props.viewId, allViews.length]);

    useEffect(() => {
        const currentDisplayMode = displayModeOptions.find(displayMode => displayMode.value === props.displayMode);
        if (!!!currentDisplayMode) {
            setCurrentDisplayModeOption(false);
            return;
        }
        setCurrentDisplayModeOption(currentDisplayMode);
    }, [props.displayMode]);

    useEffect(() => {
        const currentDetailedViewMode = detailedViewModeOptions.find(viewMode => viewMode.value === props.detailedViewMode);
        if (!!!currentDetailedViewMode) {
            setCurrentDetailedViewMode(false);
            return;
        }
        setCurrentDetailedViewMode(currentDetailedViewMode);
    }, [props.detailedViewMode]);

    const displayModeOptions = [
        {
            "value": RECORDS_DISPLAY_MODE.TABLE,
            "label": <FormattedMessage id={RECORDS_DISPLAY_MODE.TABLE}/>,
        }, {
            "value": RECORDS_DISPLAY_MODE.TILES,
            "label": <FormattedMessage id={RECORDS_DISPLAY_MODE.TILES}/>,
        }]

    const detailedViewModeOptions = [
        {
            "value": DETAILED_VIEW_MODE.FULL_PAGE,
            "label": <FormattedMessage id="FullPage"/>,
        },
        {
            "value": DETAILED_VIEW_MODE.SIDE_PANEL,
            "label": <FormattedMessage id="SidePanel"/>,
        }]

    const modeOptions = [
        {
            "label": <FormattedMessage id="Read"/>,
            "value": RECORD_FIELD_MODE.READ,
        },
        {
            "label": <FormattedMessage id="Write"/>,
            "value": RECORD_FIELD_MODE.WRITE,
        },
        {
            "label": <FormattedMessage id="Hide"/>,
            "value": RECORD_FIELD_MODE.HIDE,
        }];

    const handleDisplayModeChange = event => {
        tabChange.changeTabDisplayMode(props.tabIndex, event.value,props.pageIndex);
    }

    const handleDetailedViewModeChange = event => {
        tabChange.changeTabDetailedViewMode(props.tabIndex, event.value,props.pageIndex);
    }

    const handleNameInputChange = event => {
        tabChange.changeTabName(props.tabIndex, event.target.value,props.pageIndex);
    }

    const handleViewChange = event => {
        setViewIndex(event.value);
        tabChange.changeTabView(props.tabIndex, event.id,props.pageIndex);
    }

    const handleEnableEditChange = event => {
        tabChange.changeTabEnableEdit(props.tabIndex, event.target.checked);
    }

    const handleEnableFilters = event => {
        tabChange.changeTabEnableFilters(props.tabIndex, event.target.checked);
    }

    const handleEnableDefaultSorting = event => {
        tabChange.changeTabEnableDefaultSorting(props.tabIndex, event.target.checked,props.pageIndex);
    }

    const handleSortingField = event => {
        tabChange.changeTabSortingField(props.tabIndex, event.value,props.pageIndex);
    }

    const handleSortDirection = () => {
        if(props.tabInfo.sortDirection === SORT_DIRECTION.DESC) {
            tabChange.changeTabSortDirection(props.tabIndex, SORT_DIRECTION.ASC);
        } else {
            tabChange.changeTabSortDirection(props.tabIndex, SORT_DIRECTION.DESC);
        }
    }

    const handleEnableExport = event => {
        tabChange.changeTabEnableExport(props.tabIndex, event.target.checked);
    }

    const handleEnableMessageComponent = event => {
        tabChange.changeTabMessageComponent(props.tabIndex, event.target.checked,props.pageIndex);
    }

    const handleButtonMessageNameChange = event => {
        tabChange.changeTabButtonMessageName(props.tabIndex, event.target.value, props.pageIndex);
    }

    const handleMessageEmailFieldChange = event => {
        tabChange.changeTabMessageEmailField(props.tabIndex, event.value,props.pageIndex);
    }

    const handleDeleteTab = () => {
        tabChange.deleteTab(props.tabIndex,props.pageIndex)
    }

    const columns = [
        {
            Header: <FormattedMessage id="Name"/>,
            accessor: "name",
            headerClassName: "crossATableColumn"
        },
        {
            Header: <FormattedMessage id="Type"/>,
            accessor: "type",
            headerClassName: "crossATableColumn"
        },
        {
            Header: <FormattedMessage id="Display"/>,
            accessor: "display",
            headerClassName: "crossATableColumn"
        },
        {
            Header: <FormattedMessage id="Operator"/>,
            accessor: "mode",
            headerClassName: "crossATableColumn"
        },
    ];


    return (
        <div>
            <Form.Group>
                <Form.Label><FormattedMessage id="Name"/></Form.Label>
                <FormattedMessage id="Name">
                    {placeholder =>
                        <Form.Control
                            className={tabErrors?.name ? "has-errors" : ""}
                            onChange={handleNameInputChange}
                            placeholder={placeholder}
                            type="text"
                            value={"" || props.tabInfo.name}
                            isInvalid={tabErrors?.name}
                        />
                    }
                </FormattedMessage>
                <Form.Control.Feedback type={"invalid"}>
                    <FormattedMessage id={"Validation.cannotBeEmpty"}/>
                </Form.Control.Feedback>
            </Form.Group>
            <Form.Group>
                <Form.Label><FormattedMessage id="DisplayMode"/></Form.Label>
                <Select
                    className={"react-select primary" + (tabErrors?.displayMode?" has-errors":"")}
                    classNamePrefix="react-select"
                    onChange={handleDisplayModeChange}
                    options={displayModeOptions}
                    value={currentDisplayModeOption}
                />
                {tabErrors?.displayMode && <div className={"error-message"}>
                    <FormattedMessage id={"Validation.selectOneOption"}/>
                </div> }
            </Form.Group>
            <Form.Group>
                <Form.Label><FormattedMessage id="DetailedViewMode"/></Form.Label>
                <Select
                    className={"react-select primary"+ (tabErrors?.detailedViewMode?" has-errors":"")}
                    classNamePrefix="react-select"
                    onChange={handleDetailedViewModeChange}
                    options={detailedViewModeOptions}
                    value={currentDetailedViewMode}
                />
                {tabErrors?.detailedViewMode && <div className={"error-message"}>
                    <FormattedMessage id={"Validation.selectOneOption"}/>
                </div> }
            </Form.Group>
            <Form.Group>
                <Form.Label><FormattedMessage id="View"/></Form.Label>
                <FormattedMessage id="Placeholder.select">
                    {placeholder =>
                        <Select
                        className={"react-select primary"+ (tabErrors?.vid?" has-errors":"")}
                        classNamePrefix="react-select"
                        options={allViewOptions}
                        onChange={handleViewChange}
                        value={currentViewOption}
                        placeholder={placeholder}
                        />
                    }
                </FormattedMessage>
                {tabErrors?.vid && <div className={"error-message"}>
                    <FormattedMessage id={"Validation.selectOneOption"}/>
                </div> }
            </Form.Group>

            <Form.Group className="display-fields-container">
                    {
                            viewIndex !== -1 && allViews.length &&
                            <DisplayFieldsTable
                                data={allViews[viewIndex].fieldModes?.filter(field => field.recordTypeField.type !== RECORD_FIELD_TYPE.NOTIFICATIONS).map(field => {
                                    return {
                                        "name": {
                                            "id": field.recordTypeField.id,
                                            "name": field.recordTypeField.name,
                                            "type": "value"
                                        },
                                        "type": {
                                            "name": field.recordTypeField.type,
                                            "type": "type"
                                        },
                                        "mode": {
                                            "name": modeOptions.find(mode=>mode.value===field.recordViewFieldMode.mode)?.label,
                                            "type": "type"
                                        }
                                    }
                                })}
                                columns={columns}
                                displayFields={tabs[props.tabIndex].displayFields}
                                changeDisplayFields={(displayFieldId)=>tabChange.changeDisplayFields(props.tabIndex,displayFieldId,props.pageIndex)}
                                error={tabErrors?.displayFields}
                            />
                    }
            </Form.Group>

            <Row>
                <Card.Header>
                    <Card.Title as="h3"><FormattedMessage id="TabActions"/></Card.Title>
                </Card.Header>

            </Row>

            <Form.Group>
                <Row>
                    <Col>
                        <FormattedMessage id="EnableEdit"/>
                    </Col>
                    <Col className="inline-switch-col">
                        <Form.Check
                            className="ml-3"
                            type="switch"
                            id={"enable-edit-switch"+props.tabIndex}
                            checked={props.tabInfo.enableEditAction || false}
                            onChange={handleEnableEditChange}
                        />
                    </Col>
                </Row>
            </Form.Group>

            <Form.Group>
                <Row>
                    <Col>
                        <FormattedMessage id="EnableSearchingAndEditing"/>
                    </Col>
                    <Col className="inline-switch-col">
                        <Form.Check
                            className="ml-3"
                            type="switch"
                            id={"enable-filter-switch"+props.tabIndex}
                            checked={props.tabInfo.enableFilters || false}
                            onChange={handleEnableFilters}
                        />
                    </Col>

                </Row>
            </Form.Group>

            <Form.Group>
                <Row>
                    <Col>
                        <FormattedMessage id="DefaultSorting"/>
                    </Col>
                    <Col className="inline-switch-col">
                        <Form.Check
                            className="ml-3"
                            type="switch"
                            id={"enable-default-sorting-switch" + props.tabIndex}
                            checked={props.tabInfo.enableDefaultSorting || false}
                            onChange={handleEnableDefaultSorting}
                        />
                    </Col>
                </Row>
                {
                    props.tabInfo.enableDefaultSorting &&
                    <>
                        <Form.Label><FormattedMessage id="SortingField"/></Form.Label>
                        <Row>
                            <Col>
                                <FormattedMessage id="Placeholder.select">
                                    {placeholder =>
                                        <Select
                                            className={"react-select primary" + (tabErrors?.sortingField ? " has-errors" : "")}
                                            classNamePrefix="react-select"
                                            options={allViews[viewIndex]?.fieldModes?.filter(field => field.recordTypeField.type !== RECORD_FIELD_TYPE.NOTIFICATIONS).map(field => {
                                                return {
                                                    "label": field.recordTypeField.name,
                                                    "value": field.recordTypeField.id
                                                }
                                            })}
                                            onChange={handleSortingField}
                                            value={allViews[viewIndex]?.fieldModes?.find(field => field.recordTypeField.id === props.tabInfo.sortingField)?.recordTypeField.name ? {
                                                "label": allViews[viewIndex]?.fieldModes?.find(field => field.recordTypeField.id === props.tabInfo.sortingField)?.recordTypeField.name,
                                                "value": allViews[viewIndex]?.fieldModes?.find(field => field.recordTypeField.id === props.tabInfo.sortingField)?.recordTypeField.id
                                            } : ""}
                                            placeholder={placeholder}
                                        />
                                    }
                                </FormattedMessage>
                                {tabErrors?.sortingField && <div className={"error-message"}>
                                    <FormattedMessage id={"Validation.selectOneOption"}/>
                                </div> }
                            </Col>

                            <div className="text-right d-flex align-items-center" onClick={handleSortDirection}>
                                <OverlayTrigger
                                    onClick={(e) => e.preventDefault()}
                                    overlay={
                                        <Tooltip>
                                            <FormattedMessage id={props.tabInfo.sortDirection === SORT_DIRECTION.DESC ? "Descending" : "Ascending"}/>
                                        </Tooltip>
                                    }
                                >
                                    {props.tabInfo.sortDirection === SORT_DIRECTION.DESC ? arrowDownwardIcon : arrowUpwardIcon}
                                </OverlayTrigger>
                            </div>
                        </Row>
                    </>
                }
            </Form.Group>

            <Form.Group>
                <Row>
                    <Col>
                        <FormattedMessage id="EnableExport"/>
                    </Col>
                    <Col className="inline-switch-col">
                        <Form.Check
                            className="ml-3"
                            type="switch"
                            id={"enable-export-switch"+props.tabIndex}
                            checked={props.tabInfo.enableExport || false}
                            onChange={handleEnableExport}
                        />
                    </Col>

                </Row>
            </Form.Group>

            <Form.Group>
                <Row>
                    <Col>
                        <FormattedMessage id="MessageComponent"/>
                    </Col>
                    <Col className="inline-switch-col">
                        <Form.Check
                            className="ml-3"
                            type="switch"
                            id={"enable-message-component-switch" + props.tabIndex}
                            checked={props.tabInfo.enableMessages || false}
                            onChange={handleEnableMessageComponent}
                        />
                    </Col>
                </Row>
                {
                    props.tabInfo.enableMessages &&
                    <>
                        <Form.Label>
                            <FormattedMessage id="ButtonName"/>
                        </Form.Label>
                        <FormattedMessage id="ButtonName">
                            {placeholder =>
                                <Form.Control
                                    placeholder={placeholder}
                                    type="text"
                                    value={props.tabInfo.messageBtnName || ""}
                                    onChange={handleButtonMessageNameChange}
                                    className={tabErrors?.messageBtnName  ? " has-errors" : ""}
                                    isInvalid={tabErrors?.messageBtnName }
                                />
                            }
                        </FormattedMessage>
                        <Form.Control.Feedback type={"invalid"}>
                            <FormattedMessage id={"Validation.cannotBeEmpty"}/>
                        </Form.Control.Feedback>

                        <Form.Label><FormattedMessage id="EmailField"/></Form.Label>
                        <Row>
                            <Col>
                                <FormattedMessage id="Placeholder.select">
                                    {placeholder =>
                                        <Select
                                            className={"react-select primary" + (tabErrors?.messageEmailField ? " has-errors" : "")}
                                            classNamePrefix="react-select"
                                            options={allViews[viewIndex]?.fieldModes?.filter(field => field.recordTypeField.type === RECORD_FIELD_TYPE.TEXT).map(field => {
                                                return {
                                                    "label": field.recordTypeField.name,
                                                    "value": field.recordTypeField.id
                                                }
                                            })}
                                            onChange={handleMessageEmailFieldChange}
                                            value={allViews[viewIndex]?.fieldModes?.find(field => field.recordTypeField.id === props.tabInfo.messageEmailField)?.recordTypeField.name ? {
                                                "label": allViews[viewIndex]?.fieldModes?.find(field => field.recordTypeField.id === props.tabInfo.messageEmailField)?.recordTypeField.name,
                                                "value": allViews[viewIndex]?.fieldModes?.find(field => field.recordTypeField.id === props.tabInfo.messageEmailField)?.recordTypeField.id
                                            } : ""}
                                            placeholder={placeholder}
                                        />
                                    }
                                </FormattedMessage>
                                {tabErrors?.messageEmailField && <div className={"error-message"}>
                                    <FormattedMessage id={"Validation.selectOneOption"}/>
                                </div> }
                            </Col>
                        </Row>
                    </>
                }
            </Form.Group>

            {
                props.tabsLenght > 1 && <Button
                    variant="danger"
                    className="mt-3"
                    onClick={() => handleDeleteTab()}
                >
                    <i className="fa fa-trash"/>
                    <FormattedMessage id="DeleteTab"/>
                </Button>
            }
        </div>
    )
}

export default TabContent;
